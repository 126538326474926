define("herer-web/components/other-report", ["exports", "herer-web/mixins/titan-email", "herer-web/mixins/taskable", "herer-web/constants", "bootbox", "moment"], function (_exports, _titanEmail, _taskable, _constants, _bootbox, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_titanEmail.default, _taskable.default, {
    header: null,
    message: null,
    endPoint: null,
    downloader: Ember.inject.service(),
    http: Ember.inject.service(),
    isSubmitDisabled: false,
    startDate: (0, _moment.default)(),
    endDate: (0, _moment.default)(),
    reportExported: function reportExported() {
      return _bootbox.default.alert('Report submitted! You will receive an email when the report is complete.');
    },
    reportExportFailed: function reportExportFailed() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to run your report. " + "If you still have this issue, chat Support.");
    },
    actions: {
      submitReport: function submitReport() {
        var _this = this;

        var http = Ember.get(this, 'http');
        var timezone = this.get('authManager.currentLocation.timezone');
        var startDate = (0, _moment.default)(this.get('startDate')).startOf('day');
        var endDate = (0, _moment.default)(this.get('endDate')).endOf('day');
        this.set('isSubmitDisabled', true);
        var params = {
          start_date: startDate.tz(timezone, true).toISOString(),
          end_date: endDate.tz(timezone, true).toISOString()
        };

        if (!Ember.isEmpty(this.get('titanEmail'))) {
          params.email_to = this.get('titanEmail');
        }

        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(this.get('endPoint')), params);
        return http.get(url).then(function (response) {
          _this.set('isSubmitDisabled', false);

          _this.set('task', response.data.task);

          _this.get('taskManager').poll();

          _this.pollTask();
        }).catch(function () {
          _this.set('isSubmitDisabled', false);

          return _this.reportExportFailed();
        });
      },
      clear: function clear() {
        this.clear();
      },
      download: function download() {
        this.get('downloader').downloadFile(this.get('task.result.url'));
        this.get('taskManager').claim(this.get('task.id'));
        this.clear();
      },
      setStartDate: function setStartDate(date) {
        this.set('startDate', date);
      },
      setEndDate: function setEndDate(date) {
        this.set('endDate', date);
      }
    },
    clear: function clear() {
      this.clearTaskTimer();
      this.set('task', null);
    }
  });

  _exports.default = _default;
});