define("herer-web/components/packages/package-adjuster", ["exports", "herer-web/mixins/adjustment-reasons", "herer-web/models/weight", "herer-web/constants", "herer-web/mixins/lot-number-formatter", "bootbox", "herer-web/utils/validation", "herer-web/components/base-modal", "yup"], function (_exports, _adjustmentReasons, _weight, _constants, _lotNumberFormatter, _bootbox, _validation, _baseModal, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var componentMixins = [_adjustmentReasons.default, _lotNumberFormatter.default];

  var _default = _baseModal.default.extend.apply(_baseModal.default, componentMixins.concat([{
    authManager: Ember.inject.service(),
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    modalClassName: '.package-adjust-modal',
    note: null,
    reason: null,
    totalQuantity: null,
    manifest: null,
    validations: (0, _validation.validations)({
      reason: (0, _yup.string)().nullable().required(),
      note: (0, _yup.string)().nullable().required(),
      totalQuantity: (0, _yup.number)().nullable().transform(function (_value, totalQuantity) {
        return parseInt(totalQuantity.get('value'));
      }).moreThan(-1, 'must be greater than or equal to 0')
    }),
    isValidating: false,
    inventoryItems: Ember.computed.map('package.inventoryItems', function (inventoryItem) {
      return Ember.ObjectProxy.create({
        content: inventoryItem,
        quantityOnHand: inventoryItem.get('quantity').copy(),
        adjustmentQuantity: _weight.default.create({
          value: 0,
          unit: inventoryItem.get('quantity.unit')
        })
      });
    }),
    totalQuantityOnHand: function totalQuantityOnHand() {
      var _this = this;

      var quantity = _weight.default.create({
        value: 0,
        unit: this.get('package.quantity.unit')
      });

      return this.get('inventoryItems').reduce(function (previousQuantity, inventoryItem) {
        var quantityOnHand = inventoryItem.get('quantityOnHand');

        if (quantityOnHand.get('isUnit')) {
          if (_this.get('package.quantity.isUnit')) {
            var traceableAmount = inventoryItem.get('traceable_amount.value') || 1;
            quantityOnHand = quantityOnHand.times(traceableAmount);
          } else {
            quantityOnHand = inventoryItem.get('weight').times(quantityOnHand.get('value'));
          }
        }

        return previousQuantity.plus(quantityOnHand);
      }, quantity);
    },
    resetProperties: function resetProperties() {
      this.set('isConfirming', false);
      this.set('isValidating', false);
      this.set('note', null);
      this.set('reason', null);
      this.set('totalQuantity', this.get('package.quantity'));
      this.set('validations.fieldErrors.note', null);
      this.set('validations.fieldErrors.reason', null);
      this.set('errorMessages', null);
      this.set('manifest', this.get('store').createRecord('manifest', {
        manifest_type: _constants.default.manifestType.adjustment
      }));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        this.resetProperties();
        modal.modal({
          backdrop: 'static'
        });
      }
    },
    showErrorModal: function showErrorModal() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to save this adjustment.\n      If you still have this issue, chat Support.");
    },
    buildManifestParams: function buildManifestParams() {
      var _this2 = this;

      this.get('manifest.lot_adjustments').forEach(function (lotAdjustment) {
        if (Ember.isPresent(lotAdjustment)) {
          lotAdjustment.rollbackAttributes();
        }
      });
      this.get('inventoryItems').forEach(function (inventoryItem) {
        var lotAdjustment = _this2.get('store').createRecord('lot_adjustment', {
          inventory_item_id: inventoryItem.get('content').get('id'),
          adjustment_type: _constants.default.lotAdjustmentType.adjustment,
          adjustment_reason: _this2.get('reason'),
          adjustment_reason_id: _this2.get('reason'),
          note: _this2.get('note'),
          quantity: inventoryItem.get('adjustmentQuantity')
        });

        _this2.get('manifest.lot_adjustments').pushObject(lotAdjustment);
      });
      this.set('manifest.charged_on', new Date());
    },
    actions: {
      verifyAndConfirm: function verifyAndConfirm() {
        var _this3 = this;

        var http = Ember.get(this, 'http');
        this.set('isValidating', true);
        this.set('errorMessages', null);
        this.get("validations").validate().then(function () {
          _this3.buildManifestParams();
        }).then(function () {
          return http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/packages/").concat(_this3.get('package.id'), "/adjust"), {
            manifest: _this3.get('manifest').serialize(),
            only_validate: true
          });
        }).then(function () {
          _this3.set('isConfirming', true);
        }).catch(function (response) {
          var errorStatus = response.status;

          if (errorStatus) {
            if (errorStatus === 422) {
              _this3.set('errorMessages', response.data.errors);

              _this3.set('isConfirming', true);
            } else {
              _this3.showErrorModal();
            }
          }
        }).finally(function () {
          _this3.set('isValidating', false);
        });
      },
      unconfirm: function unconfirm() {
        this.set('isConfirming', false);
      },
      saveAdjustment: function saveAdjustment() {
        var _this4 = this;

        this.set('isSaving', true);
        Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/packages/").concat(this.get('package.id'), "/adjust"), {
          manifest: this.get('manifest').serialize(),
          only_validate: false
        }).then(function () {
          return _this4.get('package').reload().then(function () {
            _this4.onCancel && _this4.onCancel();
          });
        }).catch(function (response) {
          if (response.status === 422) {
            _this4.set('errorMessages', response.data.errors);
          } else {
            _this4.showErrorModal();
          }
        }).finally(function () {
          _this4.set('isSaving', false);
        });
      },
      updateTotalQuantity: function updateTotalQuantity() {
        this.set('totalQuantity', this.totalQuantityOnHand());
      }
    }
  }]));

  _exports.default = _default;
});