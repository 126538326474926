define("herer-web/services/cookies", ["exports", "js-cookie"], function (_exports, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    config: Ember.inject.service(),
    _cookieOptions: Ember.computed({
      get: function get() {
        var config = Ember.get(this, "config");
        var domain = config.getConfig("APP.cookieDomain");

        if (!domain) {
          return null;
        }

        return {
          domain: domain,
          secure: config.getConfig("APP.ssl")
        };
      }
    }),
    getCookie: function getCookie(key) {
      return _jsCookie.default.get(key);
    },
    setCookie: function setCookie(key, value, options) {
      _jsCookie.default.set(key, value, options || Ember.get(this, "_cookieOptions"));
    },
    removeCookie: function removeCookie(key) {
      _jsCookie.default.remove(key, Ember.get(this, "_cookieOptions"));
    }
  });

  _exports.default = _default;
});