define("herer-web/mixins/quantity-sync", ["exports", "herer-web/mixins/context", "herer-web/mixins/titan-email"], function (_exports, _context, _titanEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_context.default, _titanEmail.default, {
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    canSync: Ember.computed('currentLocation.active', 'titanEmail', function () {
      return Ember.isPresent(this.get('titanEmail'));
    }),
    canSyncMetrc: Ember.computed('canSync', function () {
      return this.canSyncWithTraceabilitySystem('metrc');
    }),
    canReconcileBatchWithMetrc: Ember.computed('canSyncMetrc', function () {
      return this.get('launchDarkly').can('ability-to-reconcile-entire-back-office-with-metrc', false);
    }),
    canSyncBiotrack: Ember.computed('canSync', function () {
      return this.canSyncWithTraceabilitySystem('biotrack');
    }),
    canSyncWithTraceabilitySystem: function canSyncWithTraceabilitySystem(system) {
      return this.get('canSync') && this.get('featureManager').can(system);
    }
  });

  _exports.default = _default;
});