define("herer-web/controllers/report", ["exports", "herer-web/mixins/context", "herer-web/constants", "lodash/filter", "lodash/find"], function (_exports, _context, _constants, _filter, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    queryParams: ['column', 'endDate', 'groupBy', 'interval', 'measure', 'refresh', 'report', 'row', 'sortBy', 'sortDirection', 'startDate'],
    launchDarkly: Ember.inject.service(),
    reportStore: Ember.inject.service(),
    report: null,
    row: null,
    startDate: null,
    endDate: null,
    interval: null,
    column: null,
    groupBy: null,
    sortBy: null,
    sortDirection: null,
    refresh: false,
    measure: 'dollars',
    validMeasures: Ember.computed('column', function () {
      if (['sales', 'null', null].includes(this.get('column'))) {
        return (0, _filter.default)(_constants.default.validMeasures, function (measure) {
          return measure !== 'sales';
        });
      }

      return _constants.default.validMeasures;
    }),
    resetQueryParams: function resetQueryParams() {
      var _this = this;

      var queryParams = this.get('queryParams');
      queryParams.forEach(function (param) {
        _this.set(param, null);
      });
      this.set('startDate', null);
      this.set('endDate', null);
      this.set('interval', null);
      this.set('sortBy', null);
      this.set('sortDirection', null);
      this.set('measure', 'dollars');
    },
    table: Ember.computed.alias('model.table'),
    reportExportFilename: Ember.computed(function () {
      return 'report_export.csv';
    }),
    tableColumn: Ember.computed('table.filters.column', function () {
      return this.findColumn(_constants.default.reportColumns, this.get('table.filters.column'));
    }),
    tableGroupBy: Ember.computed('table.filters.group_by', function () {
      return this.findColumn(_constants.default.reportGroupBys, this.get('table.filters.groupBy'));
    }),
    columns: Ember.computed('report', function () {
      return this.filterColumns(this.addLocationToOptions(_constants.default.reportColumns));
    }),
    groupBys: Ember.computed('report', function () {
      return this.filterColumns(this.addLocationToOptions(_constants.default.reportGroupBys));
    }),
    reportTypes: Ember.computed('report', function () {
      return this.addLocationToOptions(_constants.default.reportTypes);
    }),
    addLocationToOptions: function addLocationToOptions(options) {
      if (this.get('launchDarkly').can('account-level-reports', true)) {
        options = options.concat([{
          value: 'location',
          name: 'Location'
        }]);
      }

      return options;
    },
    findColumn: function findColumn(columns, value) {
      var reportColumn = (0, _find.default)(columns, function (column) {
        return column.value === value;
      });
      return Ember.isPresent(reportColumn) ? reportColumn.name : null;
    },
    filterColumns: function filterColumns(columns) {
      var _this2 = this;

      var currentLocation = this.get('currentLocation');
      var employeeTracking = currentLocation.get('employee_tracking');
      var medical = currentLocation.get('medical');
      var recreational = currentLocation.get('recreational');
      return (0, _filter.default)(columns, function (column) {
        if (column.value === _this2.get('report')) {
          return false;
        }

        if (column.value === 'employee' && !employeeTracking) {
          return false;
        }

        if (column.value === 'patient' && !medical) {
          return false;
        }

        if (column.value === 'customer' && !recreational) {
          return false;
        }

        return true;
      });
    },
    actions: {
      exportReport: function exportReport() {
        this.get('reportStore').exportReport(this.get('table'), this.get('reportExportFilename'));
      },
      updateDatesFromInterval: function updateDatesFromInterval(interval) {
        this.set('interval', interval);

        if (Ember.isPresent(this.get('interval'))) {
          this.set('startDate', null);
          this.set('endDate', null);
        }
      }
    }
  });

  _exports.default = _default;
});