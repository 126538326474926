define("herer-web/components/account-reports/daily-customers", ["exports", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    loading: false,
    failed: false,
    filterTypes: _constants.default.customerFilterTypes,
    _data: null,
    data: Ember.computed('reportData', 'sortedLocations', function () {
      if (Ember.isPresent(this.get('reportData'))) {
        return this.get('reportData');
      }

      return this.get('sortedLocations').map(function (location) {
        return {
          name: location.get('name'),
          customers: [],
          color: location.get('color')
        };
      });
    }),
    canShowChart: Ember.computed('loading', 'failed', function () {
      return !this.get('loading') && !this.get('failed');
    }),
    onResize: Ember.computed(function () {
      var _this = this;

      var func = function func() {
        _this._setHeight();
      };

      $.proxy(func, this);
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      this.onLoadReport();
      this.set('timer', setInterval(function () {
        _this2.onLoadReport(true);
      }, 30000));
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('timer'));
      this.set('timer', null);
    },
    actions: {
      changeFilterType: function changeFilterType(filterType) {
        this.onFilterChange(filterType);
        this.onLoadReport();
      }
    }
  });

  _exports.default = _default;
});