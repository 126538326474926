define("herer-web/templates/qs-reports/reorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LPeGs56c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Reorder Suggestions\",\"This report helps you estimate how long stock levels will last and when to reorder.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886995631891-Reorder-Suggestions-Estimate-Stock-Levels-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"10\",\"reorder_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"ReorderReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ReorderReport\",\"reorder_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/reorder.hbs"
    }
  });

  _exports.default = _default;
});