define("herer-web/templates/components/account-sales-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Np+iY8x3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"account-sales-summary-section\",null,[[\"title\",\"isLoading\",\"table\",\"sortBy\",\"sortDirection\",\"first\",\"data-test-group\"],[\"Today\",[24,[\"todayLoading\"]],[24,[\"todayTable\"]],[24,[\"todaySortBy\"]],[24,[\"todaySortDirection\"]],true,\"sales_summary_today\"]]],false],[0,\"\\n\"],[1,[28,\"account-sales-summary-section\",null,[[\"title\",\"isLoading\",\"table\",\"sortBy\",\"sortDirection\",\"data-test-group\"],[\"Yesterday\",[24,[\"yesterdayLoading\"]],[24,[\"yesterdayTable\"]],[24,[\"yesterdaySortBy\"]],[24,[\"yesterdaySortDirection\"]],\"sales_summary_yesterday\"]]],false],[0,\"\\n\"],[1,[28,\"account-sales-summary-section\",null,[[\"title\",\"isLoading\",\"table\",\"sortBy\",\"sortDirection\",\"dateFormat\",\"data-test-group\"],[\"Month\",[24,[\"monthLoading\"]],[24,[\"monthTable\"]],[24,[\"monthSortBy\"]],[24,[\"monthSortDirection\"]],\"MMM YYYY\",\"sales_summary_month\"]]],false],[0,\"\\n\"],[1,[28,\"account-sales-summary-section\",null,[[\"title\",\"isLoading\",\"table\",\"sortBy\",\"sortDirection\",\"dateFormat\",\"data-test-group\"],[\"Daily Average\",[24,[\"monthLoading\"]],[24,[\"dailyAverageTable\"]],[24,[\"dailyAverageSortBy\"]],[24,[\"dailyAverageSortDirection\"]],\"MMM YYYY\",\"sales_summary_daily-average\"]]],false],[0,\"\\n\"],[1,[28,\"account-sales-summary-section\",null,[[\"title\",\"isLoading\",\"table\",\"sortBy\",\"sortDirection\",\"dateFormat\",\"data-test-group\"],[\"Projected Total\",[24,[\"monthLoading\"]],[24,[\"projectedTotalTable\"]],[24,[\"projectedTotalSortBy\"]],[24,[\"projectedTotalSortDirection\"]],\"MMM YYYY\",\"sales_summary_projected-today\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/account-sales-summary.hbs"
    }
  });

  _exports.default = _default;
});