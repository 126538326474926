define("herer-web/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    disabled: false,
    errorMessage: null,
    allowedFormats: null,
    maxFileSize: null,
    classNames: ['file-input'],
    classNameBindings: ['disabled'],
    actions: {
      chooseFile: function chooseFile() {
        this.$('input[type=file]').trigger('click');
      },
      removeFile: function removeFile() {
        this.removeFile();
        this.onRemove && this.onRemove();
      }
    },
    acceptableFileTypes: Ember.computed('allowedFormats', function () {
      var allowed = this.get('allowedFormats');

      if (Ember.isEmpty(allowed) || !Ember.isArray(allowed)) {
        return null;
      }

      return allowed.join(', ');
    }),
    removeFile: function removeFile() {
      this.set('contentType', null);
      this.set('name', null);
      this.set('file', null);
      this.set('errorMessage', null);
      return this.$('input[type=file]').val('');
    },
    change: function change(e) {
      var _this = this;

      this.set('errorMessage', null);

      if (e.target.files.length === 0) {
        return;
      }

      var file = e.target.files[0];
      var fileType = file.type;

      if (!this.validateFile(file)) {
        return;
      }

      if (Ember.isEmpty(fileType)) {
        fileType = 'text/plain';
      }

      this.set('contentType', fileType);
      this.set('name', file.name);
      var reader = new FileReader();

      reader.onload = function (e) {
        _this.set('file', e.target.result);

        if (_this.get('fileSelected')) {
          _this.fileSelected(_this.get('file'));
        }
      };

      reader.readAsDataURL(file);
    },
    validateFile: function validateFile(file) {
      return this.validateFileType(file.type) && this.validateFileSize(file.size);
    },
    validateFileType: function validateFileType(fileType) {
      var allowedFormats = this.get('allowedFormats');

      if (Ember.isPresent(allowedFormats) && !allowedFormats.includes(fileType)) {
        this.removeFile();
        this.set('errorMessage', this.formatFileTypeError(fileType, allowedFormats));
        return false;
      }

      return true;
    },
    validateFileSize: function validateFileSize(size) {
      var maxFileSize = this.get('maxFileSize');

      if (Ember.isPresent(maxFileSize) && maxFileSize < size) {
        this.removeFile();
        this.set('errorMessage', 'File is too big!');
        return false;
      }

      return true;
    },
    formatFileTypeError: function formatFileTypeError(fileFormat, allowedFormats) {
      var allowed = allowedFormats.map(this.extractFileType);
      var lastFormat = allowed.pop();
      var allowedFileFormats = allowed.join(', ');

      if (allowedFileFormats.length > 0) {
        allowedFileFormats += ' or ';
      }

      allowedFileFormats += lastFormat;
      return "Wrong format (".concat(this.extractFileType(fileFormat), ")! Please select a ").concat(allowedFileFormats, " file.");
    },
    extractFileType: function extractFileType(fileFormat) {
      if (Ember.isEmpty(fileFormat)) {
        return;
      }

      var _fileFormat$split = fileFormat.split('/'),
          _fileFormat$split2 = _slicedToArray(_fileFormat$split, 2),
          _media = _fileFormat$split2[0],
          type = _fileFormat$split2[1];

      return type && type.toUpperCase();
    }
  });

  _exports.default = _default;
});