define("herer-web/mixins/product-filters", ["exports", "herer-web/constants", "herer-web/models/filter-value", "herer-web/mixins/lot-number-formatter", "lodash/clone", "lodash/find", "herer-web/mixins/context"], function (_exports, _constants, _filterValue, _lotNumberFormatter, _clone, _find, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_lotNumberFormatter.default, _context.default, {
    productTypes: null,
    places: Ember.computed.alias('currentLocation.places'),
    placesEnabled: Ember.computed.alias('currentLocation.places_enabled'),
    allTags: null,
    launchDarkly: Ember.inject.service(),
    mjFilter: null,
    unitBasedFilter: false,
    weightBasedFilter: false,
    filterValues: [],
    _filters: {},
    filtersChanged: null,
    availableFilters: Ember.computed('productTypes', 'allTags', 'places', 'placesEnabled', function () {
      var _this = this;

      var volumeBasedInventory = this.get('featureManager').can('volume-based-inventory');
      var filters = [{
        label: 'Product Type',
        type: 'select',
        subtype: 'uuid',
        query: 'by_product_type',
        options: this.get('productTypes'),
        optionLabel: function optionLabel(option) {
          return option.get('name');
        },
        optionValue: function optionValue(option) {
          return option.get('id');
        },
        prompt: 'All product types',
        testId: 'product-product-type-select'
      }, {
        label: 'Flower Type',
        type: 'select',
        query: 'by_flower_type',
        options: _constants.default.flowerTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All flower types',
        testId: 'product-flower-type-select'
      }, {
        label: 'Concentrate Type',
        type: 'select',
        query: 'by_concentrate_type',
        options: _constants.default.concentrateTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All concentrate types',
        testId: 'product-concentrate-type-select'
      }, {
        label: 'Name',
        type: 'text',
        query: 'by_name',
        defaultFilter: true,
        defaultValue: '',
        testId: 'product-name-input'
      }, {
        label: 'Vendor',
        type: 'text',
        query: 'by_vendor',
        testId: 'product-vendor-input'
      }, {
        label: 'Sell Price',
        type: 'money',
        query: 'by_sell_price',
        subquery: 'sell_price',
        testId: 'product-sell-price-input'
      }, {
        label: 'Quantity',
        type: 'numeric',
        query: 'by_quantity',
        subquery: 'quantity',
        testId: 'product-quantity-input'
      }, {
        label: 'Tag',
        type: 'tag',
        query: 'by_tags',
        allTags: this.get('allTags'),
        testId: 'product-tag-input'
      }, {
        label: 'Barcode (SKU)',
        type: 'text',
        query: 'by_barcode',
        testId: 'product-barcode-input'
      }, {
        label: 'SKU',
        type: 'text',
        query: 'by_sku',
        show: false,
        testId: 'product-sku-input'
      }, {
        label: this.get('lotNumberLabel'),
        type: 'text',
        query: 'by_lot_number',
        testId: 'product-lot-number-input'
      }, {
        label: 'Brand',
        type: 'text',
        query: 'by_brand',
        testId: 'product-brand-input'
      }, {
        label: 'Strain',
        type: 'text',
        query: 'by_strain',
        testId: 'product-strain-input'
      }, {
        label: 'THC %',
        type: 'numeric',
        percent: true,
        query: 'by_test_results_thc',
        subquery: 'test_results_thc',
        testId: 'product-thc-percent-input'
      }, {
        label: 'CBD %',
        type: 'numeric',
        percent: true,
        query: 'by_test_results_cbd',
        subquery: 'test_results_cbd',
        testId: 'product-cbd-percent-input'
      }, {
        label: 'Discountable',
        type: 'select',
        query: 'by_discountable',
        subtype: 'bool',
        options: [{
          label: 'Yes',
          value: true
        }, {
          label: 'No',
          value: false
        }],
        optionLabel: function optionLabel(option) {
          return option.label;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All products',
        testId: 'product-discountable-select'
      }, {
        label: 'Unit of Measure',
        type: 'select',
        query: 'by_unit_of_measure',
        options: volumeBasedInventory ? _constants.default.weightUnits : _constants.default.nonVolumeUnits,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.type;
        },
        prompt: 'All products',
        show: function show(context) {
          return context.get('featureManager').can('weight-based-inventory');
        },
        testId: 'product-uom-select'
      }, {
        label: 'Place',
        type: 'select',
        query: 'by_place',
        subtype: 'uuid',
        options: this.get('currentLocation.places'),
        optionLabel: function optionLabel(option) {
          return option.get('name');
        },
        optionValue: function optionValue(option) {
          return option.get('id');
        },
        defaultFilter: true,
        prompt: 'All places',
        show: function show(context) {
          return context.placesEnabled;
        },
        testId: 'product-place-select'
      }, {
        label: 'Product Status',
        type: 'select',
        query: 'by_status',
        options: _constants.default.productStatuses,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All products',
        defaultFilter: !this.isQuickAudit,
        defaultValue: this.isQuickAudit ? undefined : _constants.default.productStatus.forSale,
        testId: 'product-status-select'
      }].filter(function (filter) {
        return !filter.show || filter.show(_this);
      });
      return filters;
    }),
    filterParamsChanged: function filterParamsChanged(filterParams) {
      var params = (0, _clone.default)(filterParams); // Must delete previous mjFilters before applying new ones on change

      delete params['mj'];
      delete params['para'];
      var mjFilter = this.get('mjFilter');

      if (mjFilter !== null) {
        params[mjFilter] = true;
      }

      if (this.get('unitBasedFilter')) {
        params.unit_based = true;
      } else {
        delete params.unit_based;
      }

      if (this.get('weightBasedFilter')) {
        params.weight_based = true;
      } else {
        delete params.weight_based;
      }

      this.set('_filters', params);
      this.set('products.filters', params);

      this._handleFiltersChanged();
    },
    actions: {
      filterParamsChanged: function filterParamsChanged(filterParams) {
        this.filterParamsChanged(filterParams);
      },
      filterValuesChanged: function filterValuesChanged(filterValues) {
        this.set('filterValues', filterValues);

        this._handleFiltersChanged();
      },
      filterByTag: function filterByTag(tag) {
        var filterValues = this.get('filterValues');
        var tagFilterValue = (0, _find.default)(filterValues, function (filterValue) {
          return filterValue.get('filter.label') === 'Tag';
        });

        if (!tagFilterValue) {
          var tagFilter = this.get('availableFilters').find(function (filter) {
            return filter.label === 'Tag';
          });
          tagFilterValue = filterValues[filterValues.length - 1];
          tagFilterValue.set('filter', tagFilter);
          filterValues.pushObject(_filterValue.default.create());
        }

        var tags = tagFilterValue.get('tags');

        if (!tags.includes(tag)) {
          tagFilterValue.set('tags', tags.concat([tag]));
        }

        var filterParams = {};
        filterValues.forEach(function (filterValue) {
          if (filterValue.get('filter') !== null && !Ember.isEmpty(filterValue.get('queryValue'))) {
            filterParams[filterValue.get('filter.query')] = filterValue.get('queryValue');
          }
        });
        this.filterParamsChanged(filterParams);
        return false;
      }
    },
    _handleFiltersChanged: function _handleFiltersChanged() {
      if (this.filtersChanged) {
        this.filtersChanged(this.get('_filters'), this.get('filterValues'));
      }
    }
  });

  _exports.default = _default;
});