define("herer-web/components/duplicate-sku-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showModal: false,
    openManifestScreen: null,
    onClose: null,
    actions: {
      viewManifest: function viewManifest() {
        this.openManifestScreen();
      },
      onClose: function onClose() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});