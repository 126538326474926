define("herer-web/templates/qs-reports/inventory-adjustment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nb/Uhtkx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Inventory Adjustment Report\",\"Shows you a list of adjustments made in Greenbits so you can correct adjustment mistakes.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886963686419-Inventory-Adjustment-Report-Investigate-Adjustments\\\" target=\\\"_blank\\\">Learn more</a>\",\"10\",\"inventory_adjustment_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"InventoryAdjustmentReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"InventoryAdjustmentReport\",\"inventory_adjustment_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/inventory-adjustment.hbs"
    }
  });

  _exports.default = _default;
});