define("herer-web/components/sku-input", ["exports", "herer-web/constants", "herer-web/components/products/utils/sku"], function (_exports, _constants, _sku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    http: Ember.inject.service(),
    sku: null,
    productId: null,
    inventoryItemId: null,
    disabled: false,
    onGenerateSku: null,
    onFindSku: null,
    onFindSkuInFlight: null,
    isGeneratingSku: false,
    // Finding SKU is done asynchronously, during that operation this component
    // might be disposed. Invoking callbacks on destroyed component raises errors.
    // This property is used to determine if component is still usable.
    willBeRemoved: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('skuProvider', new _sku.default(this.store, this.featureManager, this.launchDarkly));
    },
    disableInput: Ember.computed('disabled', 'isGeneratingSku', function () {
      return this.get('disabled') || this.get('isGeneratingSku');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      // For workflow reasons, favor generation of SKU by default.
      // Either way customer has to either type/generate/edit one,
      // but this offers a better experience in blank-slate scenarios.
      if (this.get('sku') === null) {
        this.generateSku();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set('willBeRemoved', true);

      this._super.apply(this, arguments);
    },
    findSku: function findSku(sku) {
      var _this = this;

      this.onFindSkuInFlight && this.onFindSkuInFlight(true);
      var productId = this.get('productId');
      var inventoryItemId = this.get('inventoryItemId');
      this.skuProvider.find(sku).then(function (inventoryItems) {
        var matchingItems = _this.skuProvider.filterBySku(inventoryItems, sku, productId, inventoryItemId);

        if (!_this.get('willBeRemoved')) {
          _this.onFindSkuInFlight && _this.onFindSkuInFlight(false);

          _this.onFindSku(!Ember.isEmpty(matchingItems));
        }
      });
    },
    generateSku: function generateSku() {
      var _this2 = this;

      this.set('isGeneratingSku', true);
      Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/inventory_items/sku")).then(function (response) {
        var sku = response.data.sku;

        _this2.onGenerateSku(sku);

        _this2.findSku(sku);
      }).finally(function () {
        !_this2.isDestroyed && _this2.set('isGeneratingSku', false);
      });
    },
    actions: {
      generateSku: function generateSku() {
        this.generateSku();
      },
      skuFocusOut: function skuFocusOut() {
        this.findSku(this.get('sku'));
      },
      skuFocusIn: function skuFocusIn() {
        this.onFindSkuInFlight && this.onFindSkuInFlight(true);
      },
      convertToUpperCase: function convertToUpperCase() {
        this.set('sku', this.get('sku').toUpperCase());
      }
    }
  });

  _exports.default = _default;
});