define("herer-web/templates/customers-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kW12QhXQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"side-nav-header\"],[8],[0,\"\\n  Customers\\n\"],[9],[4,\"if\",[[28,\"or\",[[28,\"or\",[[28,\"has-permission\",[\"view_customers\"],null],[28,\"has-permission\",[\"view_patients\"],null]],null],[28,\"has-permission\",[\"view_employees\"],null]],null]],null,{\"statements\":[[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"nav nav-pills nav-stacked\"],[8],[0,\"\\n    \"],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"icon\"],[\"customers\",\"Customer List\",\"fa-users\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"icon\"],[\"queue\",\"Customer Queue\",\"fa-list-ol\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/customers-nav.hbs"
    }
  });

  _exports.default = _default;
});