define("herer-web/models/user", ["exports", "@ember-data/model", "herer-web/models/soft-deleteable", "ember-data-model-fragments", "herer-web/utils/validation", "yup"], function (_exports, _model, _softDeleteable, _emberDataModelFragments, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _softDeleteable.default.extend({
    featureManager: Ember.inject.service(),
    validations: (0, _validation.validations)({
      hasDriversLicense: (0, _yup.bool)(),
      isMetrcDeliveryEnabled: (0, _yup.bool)(),
      drivers_license_number: (0, _yup.string)().nullable().max(32, "Driver's License must be at most 32 characters"),
      car_make: (0, _yup.string)().nullable().max(50, "Car Make must be at most 50 characters"),
      car_model: (0, _yup.string)().nullable().max(50, "Car Model must be at most 50 characters"),
      car_license_plate_number: (0, _yup.string)().nullable().max(10, "Car License Plate must be at most 10 characters"),
      license_number: (0, _yup.string)().nullable().when(['hasDriversLicense', 'isMetrcDeliveryEnabled'], {
        is: true,
        then: (0, _yup.string)().max(32, "Employee License Number must be at most 32 characters").required(),
        otherwise: (0, _yup.string)().nullable().max(32, "Employee License Number must be at most 32 characters")
      })
    }),
    hasDriversLicense: Ember.computed.notEmpty('drivers_license_number'),
    username: (0, _model.attr)('username'),
    email: (0, _model.attr)('string'),
    email_settings: _emberDataModelFragments.default.fragment('email-setting'),
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    apiName: (0, _model.attr)('string'),
    // Name is delivered by the API, but we need to indicate if it's deleted
    name: Ember.computed('first_name', 'last_name', 'is_deleted', 'apiName', function () {
      var apiName = this.get('apiName');
      var combinedName = apiName ? [apiName] : [this.get('first_name'), this.get('last_name')];

      if (this.get('is_deleted')) {
        combinedName.push('(deleted)');
      }

      return combinedName.filter(String).join(' ');
    }),
    password: null,
    current_password: null,
    license_number: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    role_id: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    intercom_user_hash: (0, _model.attr)('string'),
    pin: (0, _model.attr)('string'),
    salesforce_id: (0, _model.attr)('string', {
      readOnly: true
    }),
    accessible_location_ids: (0, _model.attr)(),
    state_credentials: (0, _model.attr)(),
    drivers_license_number: (0, _model.attr)('string'),
    car_make: (0, _model.attr)('string'),
    car_model: (0, _model.attr)('string'),
    car_license_plate_number: (0, _model.attr)('string'),
    role: (0, _model.belongsTo)('role', {
      async: false
    }),
    account_id: (0, _model.attr)('string'),
    role_name: Ember.computed.alias('role.name'),
    pendoized: Ember.computed('id', function () {
      return {
        id: this.get('id'),
        email: this.get('email'),
        role_name: this.get('role_name'),
        name: this.get('name'),
        is_deleted: this.get('is_deleted'),
        created_at: this.get('created_at'),
        salesforce_id: this.get('salesforce_id'),
        account_id: this.get('account_id')
      };
    }),
    isMetrcDeliveryEnabled: Ember.computed('currentLocation', function () {
      return this.featureManager.can('metrc_delivery');
    }),
    hasAccessibleLocations: Ember.computed('isNew', 'is_deleted', 'accessible_location_ids', function () {
      if (this.get('isNew') || this.get('is_deleted')) {
        return true;
      }

      var locations = this.get('accessible_location_ids');
      return Boolean(locations && locations.length);
    })
  });

  _exports.default = _default;
});