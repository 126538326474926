define("herer-web/components/places-toggle", ["exports", "herer-web/mixins/context", "bootbox"], function (_exports, _context, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    placesEnabled: false,
    dialogPrompt: "\n    <h2>You Are Enabling Places</h2>\n\n    <p>\n      By default, all of your inventory items that are For Sale will be\n      on the Sales Floor and inventory items that are Not For Sale will be in Back Stock.\n\n      Archived items will be Out Of Store.\n    </p>\n\n    <p>You can change an inventory item's place at any time in the Inventory section.</p>\n  ",
    actions: {
      showEnablePlacesDialog: function showEnablePlacesDialog() {
        var _this = this;

        _bootbox.default.confirm({
          message: this.get('dialogPrompt'),
          buttons: {
            confirm: {
              label: 'Enable Places'
            }
          },
          callback: function callback(enable) {
            if (enable) {
              _this.set('currentLocation.places_enabled', true);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});