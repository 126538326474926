define("herer-web/labels/default_label_filter", ["exports", "herer-web/labels/rules/packager_defaults"], function (_exports, _packager_defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // pass through filter
    apply: function apply(labelType, data) {
      if (Ember.isPresent(data)) {
        var myData = Object.assign({}, data); // apply global rules

        _packager_defaults.default.apply(labelType, myData); // no action, pass through


        return myData;
      }

      return data;
    }
  });

  _exports.default = _default;
});