define("herer-web/templates/components/image-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9i3aHXv9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"cropper-container\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"image\"]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/image-cropper.hbs"
    }
  });

  _exports.default = _default;
});