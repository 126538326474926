define("herer-web/models/discount-plan", ["exports", "@ember-data/model", "herer-web/mixins/taggable", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _model, _taggable, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_taggable.default, {
    validations: (0, _validation.validations)({
      name: (0, _yup.string)().required("Name can't be blank."),
      isNone: (0, _yup.bool)(),
      isPercent: (0, _yup.bool)(),
      isLoyaltyProgram: (0, _yup.bool)(),
      autoApply: (0, _yup.bool)().when('isNone', {
        is: true,
        then: (0, _yup.bool)().oneOf([false], 'Auto Apply cannot be used with the "Enter at Register" discount type.'),
        otherwise: (0, _yup.bool)().oneOf([true, false])
      }),
      loyaltyPoints: (0, _yup.number)().when('isLoyaltyProgram', {
        is: true,
        then: (0, _yup.number)().typeError('Loyalty points must be a valid number.').positive('Loyalty points must be a positive number.'),
        otherwise: (0, _yup.number)().nullable()
      }),
      calculationValue: (0, _yup.number)().when('isNone', {
        is: true,
        then: (0, _yup.number)().nullable(),
        otherwise: (0, _yup.number)().typeError('Discount must be a valid number.').min(0, 'Discount must be a positive number or 0.').required("Discount can't be blank.")
      }).when('isPercent', {
        is: true,
        then: (0, _yup.number)().typeError('Discount must be a valid number.').min(0, 'Discount must be a positive number or 0.').max(100, 'Discount must be less than or equal to 100.'),
        otherwise: (0, _yup.number)().nullable()
      }),
      calculationType: (0, _yup.number)().when('isNone', {
        is: true,
        then: (0, _yup.number)().nullable(),
        otherwise: (0, _yup.number)().required("Discount type can't be blank.").typeError('Discount type must be present.')
      })
    }),
    name: (0, _model.attr)('string'),
    sku: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    enabled: (0, _model.attr)('boolean'),
    calculationType: (0, _model.attr)('number'),
    calculationValue: (0, _model.attr)('number'),
    roundToNearestDollar: (0, _model.attr)('boolean'),
    autoApply: (0, _model.attr)('boolean'),
    afterTaxes: (0, _model.attr)('boolean'),
    accessibleRoleIds: (0, _model.attr)(),
    conditions: (0, _model.hasMany)('discountCondition', {
      async: false,
      embedded: 'always'
    }),
    isNone: Ember.computed.equal('calculationType', _constants.default.discountCalculationType.none),
    isPercent: Ember.computed.equal('calculationType', _constants.default.discountCalculationType.percent),
    isToAmount: Ember.computed.equal('calculationType', _constants.default.discountCalculationType.toAmount),
    conditionErrors: Ember.computed('errors.@each', function () {
      if (this.get('errors')) {
        return this.get('errors.content').filter(function (error) {
          return error.attribute === 'conditions';
        });
      }
    }),
    isNotRegisterBased: Ember.computed('calculationType', function () {
      if (this.get('calculationType') === 0) {
        this.set('conditions', []);
        return false;
      } else {
        return true;
      }
    }),
    loyaltyPoints: Ember.computed('conditions.@each.{key,value,values}', function () {
      var pointsCondition = this.get('conditions').find(function (c) {
        return c.get('key') === 'points';
      });

      if (pointsCondition) {
        return pointsCondition.get('values')[0];
      } else {
        return null;
      }
    }),
    shouldUpdateConditions: function shouldUpdateConditions(newConditions) {
      var conditions = this.get('conditions');

      if (conditions.length !== newConditions.length) {
        return true;
      }

      var a = conditions.map(function (c) {
        return c.get('identifier');
      });
      a = a.sort().join();
      var b = newConditions.map(function (c) {
        return c.get('identifier');
      });
      b = b.sort().join();
      return a !== b;
    },
    shouldUpdateAccessibleRoleIds: function shouldUpdateAccessibleRoleIds(newIds) {
      var ids = this.get('accessibleRoleIds');

      if (ids.length !== newIds.length) {
        return true;
      }

      return ids.sort().join() !== newIds.sort().join();
    }
  });

  _exports.default = _default;
});