define("herer-web/templates/components/nav-link/inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DGIqLoZS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"data-test-text\",\"nav_link_label\"],[10,\"class\",\"nav-inner\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"icon fa fa-exclamation-triangle marigold-800-text\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"textPrefix\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"nav-badge \",[22,\"textPrefix\"]]]],[8],[1,[22,\"textPrefix\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"icon fa hidden-md \",[22,\"icon\"]]]],[8],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/nav-link/inner.hbs"
    }
  });

  _exports.default = _default;
});