define("herer-web/controllers/account", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/mixins/accessible-locations"], function (_exports, _alertUnsavedController, _context, _accessibleLocations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, _accessibleLocations.default, {
    errors: Ember.computed.alias('model.errors'),
    successMessage: '',
    featureManager: Ember.inject.service(),
    initialStateCredentialsAttrs: [],
    stateCredentialsAttrs: Ember.computed.map('proxiedLocations.@each.{api_key,checked}', function (location) {
      return [location.get('api_key'), location.get('checked')];
    }),
    setInitialStateCredentialsAttrs: function setInitialStateCredentialsAttrs() {
      this.set('initialStateCredentialsAttrs', Ember.copy(this.get('stateCredentialsAttrs')));
    },
    updateProxiedLocations: function updateProxiedLocations() {
      this._super();

      this.setInitialStateCredentialsAttrs();
    },
    cannotSaveUser: Ember.computed('model.username', 'model.first_name', 'model.last_name', 'model.email', 'model.password', 'model.current_password', 'model.pin', 'model.license_number', 'model.drivers_license_number', 'model.car_make', 'model.car_model', 'model.car_license_plate_number', 'proxiedLocations.@each.{api_key,checked}', function () {
      var changed_attributes = Object.keys(this.get('model').changedAttributes());
      var attrs = Ember.String.w("email first_name last_name username pin license_number drivers_license_number\n                     car_make car_model car_license_plate_number");
      var fields_changed = !!attrs.find(function (attr) {
        return changed_attributes.includes(attr);
      });
      var state_credentials_changed = Ember.compare(this.get('stateCredentialsAttrs'), this.get('initialStateCredentialsAttrs')) !== 0;
      fields_changed = fields_changed || state_credentials_changed || !Ember.isEmpty(this.get('model.password'));
      return fields_changed && !this.get('model.current_password') || !fields_changed;
    }),
    actions: {
      saveUser: function saveUser() {
        var _this = this;

        this.get('errors').clear();
        this.updateStateCredentials();
        var model = this.get('model');
        model.validations.validate().then(function () {
          return model.save();
        }).then(function (model) {
          model.set('current_password', null);
          model.set('password', null);
          model.reload();

          _this.set('successMessage', 'User saved');

          _this.setInitialStateCredentialsAttrs();
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});