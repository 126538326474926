define("herer-web/controllers/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    successMessage: '',
    tagLine: null,
    queryParams: ['tagLine'],
    cannotSaveCustomer: Ember.computed('model.email', 'model.phone', function () {
      var model = this.get('model');
      return Ember.isEmpty(model.get('email')) && Ember.isEmpty(model.get('phone'));
    }),
    showSuccessMessage: function showSuccessMessage() {
      this.set('successMessage', 'Thanks for signing up');
      this.send('reset');
      Ember.run.later(this, this.hideSuccessMessage, 3 * 1000);
    },
    hideSuccessMessage: function hideSuccessMessage() {
      this.set('successMessage', '');
    },
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.get('errors').clear();
        model.save().then(function () {
          _this.showSuccessMessage();
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});