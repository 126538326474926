define("herer-web/components/menu-feeds/feed-preview/group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    menuFeed: null,
    menuGroup: null,
    menuItem: null,
    classNames: ['row', 'table-row'],
    classNameBindings: ['missingPrice:hidden', 'menuItem.hasErrors:bg-danger'],
    headers: Ember.computed('menuGroup.priceColumns', function () {
      var priceColumns = this.get('menuGroup.priceColumns');
      var width = this.get('menuGroup.priceColumnWidth');

      if (Ember.isEmpty(priceColumns)) {
        return [];
      }

      return priceColumns.map(function (priceWeight) {
        return {
          name: priceWeight.get('isUnit') ? 'Each' : priceWeight.toString(),
          style: Ember.String.htmlSafe("width: ".concat(width, "%; float: left;"))
        };
      });
    }),
    missingPrice: Ember.computed('hasPriceForColumns', 'hasPrice', function () {
      return !this.get('hasPriceForColumns') && !this.get('hasPrice');
    }),
    hasPriceForColumns: Ember.computed('menuGroup.priceColumns', 'menuItem.prices.[]', function () {
      var menuItem = this.get('menuItem');
      var priceColumns = this.get('menuGroup.priceColumns');

      if (Ember.isEmpty(priceColumns)) {
        return false;
      }

      return priceColumns.reduce(function (prev, weight) {
        return prev || Ember.isPresent(menuItem.priceForWeight(weight));
      }, false);
    }),
    hasPrice: Ember.computed.notEmpty('menuItem.prices'),
    priceDisplayData: Ember.computed('menuGroup.priceColumns', 'menuGroup.priceColumnWidth', 'menuItem.prices.[]', function () {
      var menuItem = this.get('menuItem');
      var priceColumns = this.get('menuGroup.priceColumns');

      if (Ember.isEmpty(priceColumns)) {
        return [];
      }

      var width = this.get('menuGroup.priceColumnWidth');
      return priceColumns.map(function (weight) {
        var price = menuItem.priceForWeight(weight);
        return {
          show: Ember.isPresent(price),
          price: price,
          style: Ember.String.htmlSafe("width: ".concat(width, "%; float: left;"))
        };
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('hasPrice') && !this.get('hasPriceForColumns')) {
        var errors = this.get('menuItem.errors');
        errors.price = "Missing price that fits into listing service's price columns";
        var menuItem = this.get('menuItem');
        menuItem.set('errors', errors);
        menuItem.notifyPropertyChange('errors');
      }
    }
  });

  _exports.default = _default;
});