define("herer-web/routes/roles", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'settings-nav',
    permissionKey: 'view_roles',
    model: function model() {
      return this.get('store').findAll('role');
    }
  });

  _exports.default = _default;
});