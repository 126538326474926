define("herer-web/templates/product-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iFJzjgPD",
    "block": "{\"symbols\":[\"crud\"],\"statements\":[[5,\"crud-list-form\",[[12,\"data-test-group\",\"product_types\"]],[[\"@models\",\"@header\",\"@subheader\",\"@modelName\",\"@modelDisplayName\",\"@modelIconClass\",\"@onCreate\",\"@canUpdate\",\"@canCreate\",\"@canDelete\"],[[23,0,[\"model\"]],\"Product Types\",\"Product Type determines whether to show or hide certain attributes, e.g., the \\\"weight\\\"\",\"product-type\",\"Product Type\",\"fa-archive\",[28,\"fn\",[[23,0,[\"onCreate\"]]],null],[28,\"has-permission\",[\"update_product_types\"],null],[28,\"has-permission\",[\"create_product_types\"],null],[28,\"has-permission\",[\"destroy_product_types\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"formSection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"drawer-scroll-content\"],[8],[0,\"\\n      \"],[5,\"product-type-form\",[],[[\"@model\"],[[23,1,[\"model\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"rowSection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"ellipsis\\n        \",[28,\"if\",[[23,1,[\"model\",\"hasDirtyAttributes\"]],\"col-xs-8\",\"col-xs-12\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"color-cube \",[23,1,[\"model\",\"colorClass\"]]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"model\",\"name\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"         \\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"model\",\"hasDirtyAttributes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-xs-4 text-right\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"label label-default\"],[8],[0,\"\\n          Unsaved\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/product-types.hbs"
    }
  });

  _exports.default = _default;
});