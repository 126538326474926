define("herer-web/components/weekly-sales-report", ["exports", "herer-web/mixins/resize-handler", "herer-web/pivot-table/table", "herer-web/constants", "moment"], function (_exports, _resizeHandler, _table, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resizeHandler.default, {
    http: Ember.inject.service(),
    table: null,
    week: null,
    isLoading: true,
    failed: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadReport();
    },
    canShowChart: Ember.computed('isLoading', 'failed', function () {
      return !this.get('isLoading') && !this.get('failed');
    }),
    onResize: Ember.computed(function () {
      var func = function func(event) {
        return this.setHeight();
      };

      return $.proxy(func, this);
    }),
    setHeight: function setHeight() {
      var width = this.$().width();
      var height = Math.round(width * 0.289) + 102;
      return this.$().height(height);
    },
    loadReport: function loadReport() {
      var _this = this;

      var http = Ember.get(this, 'http');
      this.setHeight();
      var startOfWeek = (0, _moment.default)().startOf('week').format('YYYY-MM-DD');
      this.set('week', (0, _moment.default)(startOfWeek).format('M/D'));
      this.set('isLoading', true);
      this.set('failed', false);
      var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/weekly_sales"), {
        start_week: startOfWeek,
        end_week: startOfWeek,
        value: 'sales'
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return http.get(url).then(function (response) {
          return resolve(response.data);
        }).catch(function (response) {
          return reject(response.data);
        });
      }).then(function (data) {
        _this.set('isLoading', false);

        return _this.set('table', _table.default.create({
          columnName: data.column_name,
          rowName: data.row_name,
          valueName: data.value_name,
          sourceData: data.source_data,
          showRowTotals: true
        }));
      }).catch(function (error) {
        _this.set('isLoading', false);

        return _this.set('failed', true);
      });
    }
  });

  _exports.default = _default;
});