define("herer-web/components/ignore-traceability-notification-modal", ["exports", "herer-web/components/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    modalClassName: '.ignore-traceability-notification-modal',
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (Ember.isEmpty(modal)) {
        return;
      }

      var showModal = this.get('show');

      if (showModal) {
        //modal.modal({ backdrop: 'static' });
        modal.modal();
      } else {
        modal.modal('hide');
      }
    },
    modalTitle: Ember.computed('type', 'state', function () {
      var type = this.get('type');
      var title;
      var state = this.get('state');

      if (type == 'package-creation') {
        title = "Skip Reporting Event to ".concat(state, "?");
      } else {
        title = "Skip Reporting Sales to ".concat(state, "?");
      }

      return title;
    }),
    modalButton: Ember.computed('type', function () {
      var type = this.get('type');
      var sales = this.get('sales');
      var button;

      if (type == 'package-creation') {
        button = "Skip Reporting";
      } else {
        button = "Skip Reporting of ".concat(sales, " Sales");
      }

      return button;
    }),
    actions: {
      onClick: function onClick() {
        this.onConfirm && this.onConfirm();
      }
    }
  });

  _exports.default = _default;
});