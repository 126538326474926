define("herer-web/components/checkbox-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    message: null,
    checked: null,
    indeterminate: null,
    disabled: false,
    onChange: null,
    beta: false,
    premium: false,
    newFeature: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('checkboxGuid', Ember.guidFor(this));
    },
    actions: {
      onStateChange: function onStateChange(newState) {
        var _this = this;

        var action = this.onChange && this.onChange(newState);

        if (action && action.then) {
          action.then(function () {
            Ember.run.scheduleOnce('afterRender', function () {
              _this.element.querySelector("#".concat(_this.get('checkboxGuid'))).checked = _this.get('checked');
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});