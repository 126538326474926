define("herer-web/models/shift", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    device_id: (0, _model.attr)('string'),
    device_name: (0, _model.attr)('string'),
    opened_at: (0, _model.attr)('date'),
    closed_at: (0, _model.attr)('date'),
    locked: (0, _model.attr)('boolean'),
    receipt_id: (0, _model.attr)('string'),
    employee: (0, _model.belongsTo)('user', {
      async: true
    }),
    open_amount: (0, _model.attr)('number'),
    close_drawer_amount: (0, _model.attr)('number'),
    close_drop_amount: (0, _model.attr)('number'),
    close_note: (0, _model.attr)('string'),
    missingClosingAmounts: Ember.computed('closed_at', 'close_drop_amount', function () {
      return this.get('closed_at') && Ember.isEmpty(this.get('close_drop_amount'));
    }),
    employee_name: Ember.computed.readOnly('employee.name')
  });

  _exports.default = _default;
});