define("herer-web/templates/components/payment-method-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J481gJQa",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"checkbox-setting\",null,[[\"checked\",\"onChange\",\"title\",\"disabled\"],[[24,[\"paymentMethod\",\"active\"]],[28,\"action\",[[23,0,[]],\"updateActive\"],null],[24,[\"paymentMethod\",\"name\"]],[28,\"not\",[[24,[\"canUpdateActive\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/payment-method-toggle.hbs"
    }
  });

  _exports.default = _default;
});