define("herer-web/templates/components/packages/package-reconciler/unable-to-reconcile-converted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HDp7wHEh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"data-test-text\",\"modal_title\"],[8],[0,\"\\n    \"],[7,\"b\",true],[8],[0,\"Temporarily Unable to Reconcile\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"We're still working on the ability to Resolve Packages that have been converted into Inventory for multiple Products.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Thank you for your patience.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer align-left\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"data-test-action\",\"cancel\"],[3,\"action\",[[23,0,[]],[24,[\"onCancel\"]]]],[8],[0,\"\\n    OK\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package-reconciler/unable-to-reconcile-converted.hbs"
    }
  });

  _exports.default = _default;
});