define("herer-web/components/budtender-performance-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants"], function (_exports, _dimpleChart, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    width: Ember.computed(function () {
      return this.$().width();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var height = Math.round(this.get('width') * 0.5);
      this.$().height(height);
    },
    remap: function remap(data) {
      var newData = Ember.A();
      data.get('groups').forEach(function (group) {
        group.get('sortedRows').forEach(function (row) {
          var rowData = {
            'Employee': row.header
          };
          row.get('cells').forEach(function (cell) {
            rowData[cell.get('column')] = cell.get('value');
          });
          newData.pushObject(rowData);
        });
      });
      return newData;
    },
    customizeChart: function customizeChart(chart) {
      var width = this.get('width');
      var w = (width - 320) / (1110 - 320);
      var iX = d3.interpolateNumber(15, 5);
      var iY = d3.interpolateNumber(10, 5);
      var iWidth = d3.interpolateNumber(80, 90);
      var iHeight = d3.interpolateNumber(70, 75);
      var iTicks = d3.interpolateNumber(3, 10);
      chart.setBounds("".concat(iX(w), "%"), "".concat(iY(w), "%"), "".concat(iWidth(w), "%"), "".concat(iHeight(w), "%"));
      chart.ease = 'bounce';
      chart.staggerDraw = true;
      chart.defaultColors = _constants.default.chartColors;
      var y = chart.addMeasureAxis('y', 'Average Per Sale');
      y.showGridlines = false;
      y.fontSize = '12px';
      y.ticks = parseInt(iTicks(w));
      y.tickFormat = '$,';
      var x = chart.addMeasureAxis('x', 'Number of Sales');
      x.showGridlines = false;
      x.fontSize = '12px';
      var z = chart.addMeasureAxis('z', 'Total');
      z.tickFormat = '$,.2f';
      chart.addSeries('Employee', dimple.plot.bubble);
      return chart;
    }
  });

  _exports.default = _default;
});