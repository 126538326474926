define("herer-web/routes/customers", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, _pageableRoute.default, {
    sideNavTemplateName: 'customers-nav',
    mixpanelEvent: 'CustomerList.show',
    modelName: 'person',
    sortBy: 'name',
    sortDirection: 'asc',
    store: Ember.inject.service(),
    queryParams: {
      type: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        return _this.get('permissionsManager').verify('view_customers').catch(function () {
          return _this.get('permissionsManager').verify('view_patients');
        });
      });
    },
    model: function model(params) {
      var pagedArray = this._super.apply(this, arguments);

      var modelHash = Ember.RSVP.hash({
        personGroups: this.get('store').findAll('personGroup'),
        model: pagedArray
      });
      var type = parseInt(params.type);

      if (!isNaN(type)) {
        modelHash.filters = {
          by_type: type
        };
        pagedArray.set('filters', modelHash.filters);
      }

      return modelHash;
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.model);
      controller.set('personGroups', hash.personGroups);

      if (!hash.filters) {
        hash.model.set('filters', controller.get('filters'));
      } else {
        controller.set('advancedFilters', true);
      }
    },
    resetController: function resetController(controller) {
      controller.set('searchText', null);
      controller.set('advancedFilters', false);
      controller.set('filters', null);
      controller.set('type', null);
    },
    actions: {
      didTransition: function didTransition() {
        var self = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          var peopleToCreate = self.get('store').peekAll('id-scan').filterBy('needsToCreatePerson', true);

          if (peopleToCreate && peopleToCreate[0]) {
            var idScanPerson = peopleToCreate[0];
            idScanPerson.set('needsToCreatePerson', false);
            self.controllerFor('customers').send('createPersonFromScan', idScanPerson);
          }
        });
      }
    }
  });

  _exports.default = _default;
});