define("herer-web/mixins/infinite-scroll", ["exports", "lodash/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    offset: 2000,
    handler: null,
    scrollElement: window,
    contentElement: document,
    scrolledPastFold: false,
    didInsertElement: function didInsertElement() {
      this._super();

      this.set('handler', this.get('onScroll').bind(this));
      $(this.get('scrollElement')).on('scroll', this.get('handler'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      $(this.get('scrollElement')).off('scroll', this.get('handler'));
    },
    onScroll: (0, _debounce.default)(function () {
      var scrollElement = $(this.get('scrollElement'));
      var scrollTop = scrollElement.scrollTop();
      var winHeight = scrollElement.height();
      var docHeight = $(this.get('contentElement')).height();
      var scrollOffset = docHeight - winHeight - scrollTop;

      if (scrollOffset < this.get('offset')) {
        this.send('loadMore');
      }

      this.set('scrolledPastFold', scrollTop >= 300);
    }, 200)
  });

  _exports.default = _default;
});