define("herer-web/mixins/reports-comparable", ["exports", "lodash", "herer-web/utils/error-reporter"], function (_exports, _lodash, _errorReporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ReportsComparable = Ember.Mixin.create({
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    authManager: Ember.inject.service(),
    comparisonEnabled: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('compare-sales-report');
    }),
    compareReport: function compareReport(name, data, url, params) {
      var _this = this;

      if (!this.get('comparisonEnabled') || Ember.isPresent(params.use_new_report)) {
        return;
      }

      var http = this.get('http');
      var newUrl = http.createUrl(url, _objectSpread(_objectSpread({}, params), {}, {
        use_new_report: 'salesReport'
      }));
      http.get(newUrl).then(function (response) {
        if (!(0, _lodash.isEqual)(data, response.data)) {
          var location = _this.get('authManager.currentLocation');

          (0, _errorReporter.eventReporter)('reportsComparison', {
            severity: 'info',
            reportName: name,
            params: params,
            locationId: location.get('id')
          });
        }
      });
    }
  });
  var _default = ReportsComparable;
  _exports.default = _default;
});