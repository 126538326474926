define("herer-web/routes/id-scan", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    modelName: 'id-scan'
  });

  _exports.default = _default;
});