define("herer-web/controllers/location", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context"], function (_exports, _alertUnsavedController, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    successMessage: '',
    actions: {
      save: function save(model) {
        var _this = this;

        this.set('successMessage', '');
        model.save().then(function () {
          _this.set('successMessage', 'Location saved');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});