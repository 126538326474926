define("herer-web/components/inventory-value/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'table-row', 'product'],
    product: null,
    onSelectTag: null
  });

  _exports.default = _default;
});