define("herer-web/templates/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LfsDg9zJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"spectrum-color-picker\",null,[[\"color\",\"palette\",\"showPaletteOnly\",\"clickoutFiresChange\",\"hideAfterPaletteSelect\",\"preferredFormat\",\"onChange\",\"data-test-input\"],[[24,[\"selectedColor\"]],[24,[\"palette\"]],true,true,true,\"hex\",[28,\"action\",[[23,0,[]],\"changeColor\"],null],\"color_picker\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/color-picker.hbs"
    }
  });

  _exports.default = _default;
});