define("herer-web/templates/components/packages/close-adjustment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2xPhTPvD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"data-test-value\",\"sku\"],[8],[0,\"\\n  \"],[1,[24,[\"inventoryItem\",\"sku\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"data-test-value\",\"quantity\"],[8],[0,\"\\n  \"],[1,[22,\"quantity\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/close-adjustment.hbs"
    }
  });

  _exports.default = _default;
});