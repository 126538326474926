define("herer-web/components/packages/package-adjustment", ["exports", "herer-web/mixins/inventory-weights"], function (_exports, _inventoryWeights) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inventoryWeights.default, {
    featureManager: Ember.inject.service(),
    tagName: 'tr',
    inventoryItem: null,
    onUpdate: null,
    itemUOM: Ember.computed.alias('inventoryItem.product.unit_of_measure'),
    setAdjustmentQuantity: function setAdjustmentQuantity() {
      var adj = this.get('inventoryItem.quantityOnHand').minus(this.get('inventoryItem.quantity'));
      this.set('inventoryItem.adjustmentQuantity', adj);
      this.onUpdate();
    },
    actions: {
      setQuantityOnHandValue: function setQuantityOnHandValue(quantityValue) {
        this.set('inventoryItem.quantityOnHand.value', quantityValue);
        this.setAdjustmentQuantity();
      },
      setQuantityValue: function setQuantityValue(value) {
        this.set('inventoryItem.quantityOnHand', value);
        this.setAdjustmentQuantity();
      }
    }
  });

  _exports.default = _default;
});