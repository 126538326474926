define("herer-web/models/device", ["exports", "@ember-data/model", "herer-web/constants", "lodash/find"], function (_exports, _model, _constants, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var deviceType = _constants.default.deviceType,
      deviceTypes = _constants.default.deviceTypes;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    identifier: (0, _model.attr)('string'),
    drawer_status: (0, _model.attr)('number'),
    created_at: (0, _model.attr)('date'),
    version: (0, _model.attr)('string'),
    beta: (0, _model.attr)('boolean'),
    device_type: (0, _model.attr)('number'),
    device_transaction_type: (0, _model.attr)('number'),
    registration_code: (0, _model.attr)('string'),
    hardware_profile: (0, _model.attr)(),
    payment_terminal: (0, _model.attr)(),
    linked: Ember.computed('identifier', 'registration_code', function () {
      return Ember.isPresent(this.get('identifier')) && Ember.isEmpty(this.get('registration_code'));
    }),
    drawerOpen: Ember.computed.equal('drawer_status', 1),
    isRegister: Ember.computed.equal('device_type', deviceType.register),
    isWeighStation: Ember.computed.equal('device_type', deviceType.weighStation),
    isMigration: Ember.computed.equal('device_type', deviceType.migration),
    isTVDisplay: Ember.computed.equal('device_type', deviceType.tvDisplay),
    deviceTypeName: Ember.computed('device_type', function () {
      var type = this.get('device_type');

      if (Ember.isEmpty(type)) {
        return;
      }

      return (0, _find.default)(deviceTypes, function (d) {
        return d.value === type;
      }).name;
    })
  });

  _exports.default = _default;
});