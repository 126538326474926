define("herer-web/routes/convert", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/label-preference", "herer-web/mixins/receipt-preference"], function (_exports, _authenticatedSideNav, _labelPreference, _receiptPreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_labelPreference.default, _receiptPreference.default, {
    sideNavTemplateName: 'inventoryNav',
    permissionKey: 'update_inventory',
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        inventoryItem: store.findRecord('inventory_item', params.id),
        labelPreference: this.findLabelPreference(store),
        receiptPreference: this.findReceiptPreference(store),
        productTypes: store.findAll('product_type'),
        strains: store.findAll('strain'),
        tags: store.findAll('tag'),
        taxItems: store.findAll('tax_item'),
        tieredPricingModels: store.findAll('tiered-pricing-model'),
        places: store.findAll('place')
      });
    },
    actions: {
      transitionToProduct: function transitionToProduct(product) {
        this.transitionTo('products.show', product.id);
      }
    }
  });

  _exports.default = _default;
});