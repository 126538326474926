define("herer-web/mixins/inventory-product-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reassignProductMJFilter: Ember.computed('inventoryItemToReassign', function () {
      var isMarijuana = this.get('inventoryItemToReassign.product.product_type.is_marijuana');
      return isMarijuana ? 'mj' : 'para';
    }),
    confirmInventoryChange: function confirmInventoryChange(currentProduct, product, fn) {
      var _this = this;

      setTimeout(function () {
        var message = _this.confirmInventoryChangeMessage(currentProduct, product);

        _this.confirmChange(message, fn, function () {});
      }, 750);
    },
    confirmInventoryChangeMessage: function confirmInventoryChangeMessage(currentProduct, product) {
      var currentProductName = Ember.Handlebars.Utils.escapeExpression(currentProduct.get('name'));
      var newProductName = Ember.Handlebars.Utils.escapeExpression(product.get('name'));
      return "You are reassigning this inventory from <strong>".concat(currentProductName, "</strong> to\n      <strong>").concat(newProductName, "</strong>. This will alter any historical sales or product data\n      associated with this inventory.");
    }
  });

  _exports.default = _default;
});