define("herer-web/models/email-setting", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    notify_par: (0, _model.attr)('boolean'),
    notify_monthly_tax: (0, _model.attr)('boolean'),
    notify_auto_closed_inventory: (0, _model.attr)('boolean'),
    reporting_failures: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});