define("herer-web/components/menu-feeds/feed-preview/group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    menuFeed: null,
    menuGroup: null,
    headers: Ember.computed('menuGroup.priceColumns', function () {
      var priceColumns = this.get('menuGroup.priceColumns');
      var width = this.get('menuGroup.priceColumnWidth');

      if (Ember.isEmpty(priceColumns)) {
        return [];
      }

      return priceColumns.map(function (priceWeight) {
        return {
          name: priceWeight.get('isUnit') ? 'Each' : priceWeight.toString(),
          style: Ember.String.htmlSafe("width: ".concat(width, "%; float: left;"))
        };
      });
    })
  });

  _exports.default = _default;
});