define("herer-web/routes/transaction-limits", ["exports", "herer-web/routes/authenticated-side-nav"], function (_exports, _authenticatedSideNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    featureManager: Ember.inject.service(),
    sideNavTemplateName: 'settings-nav',
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        return _this.get('permissionsManager').verify('view_company_settings');
      }).then(function () {
        if (_this.get('featureManager').can('configurable_transaction_limits')) {
          return Ember.RSVP.resolve();
        } else {
          var unauthorizedError = new Error('Location does not have permission to access this.');
          unauthorizedError.status = 403;
          return Ember.RSVP.reject(unauthorizedError);
        }
      });
    },
    model: function model() {
      return this.get('store').findAll('transaction_limit');
    }
  });

  _exports.default = _default;
});