define("herer-web/templates/components/input-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZSmabL/",
    "block": "{\"symbols\":[\"&default\",\"@buttonOnly\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"inputSection\"],[true]]]]],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"input-group-addon help-addon \",[28,\"if\",[[23,2,[]],\"button-only\"],null],\" \",[28,\"if\",[[23,0,[\"isExpanded\"]],\"expanded\"],null]]]],[12,\"data-test-action\",\"toggle_help\"],[3,\"on\",[\"click\",[23,0,[\"toggleHelp\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"help-well-container\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"well help-well \",[28,\"if\",[[23,2,[]],\"button-only\"],null]]]],[12,\"data-test-group\",\"help_content\"],[3,\"did-insert\",[[23,0,[\"setWellElement\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"content\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,0,[\"content\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,1,[[28,\"hash\",null,[[\"contentSection\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/input-help.hbs"
    }
  });

  _exports.default = _default;
});