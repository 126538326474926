define("herer-web/components/print-count-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isPrinting: false,
    isBlindCount: false,
    isStacked: false,
    disabled: false,
    onClick: null,
    classNameBindings: ['isStacked:btn', 'isStacked:btn-default', 'isStacked:btn-block'],
    tagName: 'a',
    attributeBindings: ['href'],
    href: '#',
    click: function click(e) {
      e.preventDefault();
      this.onClick(this.get('isBlindCount'));
    }
  });

  _exports.default = _default;
});