define("herer-web/components/no-whitespace-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    type: 'text',
    keyPress: function keyPress(event) {
      var character = String.fromCharCode(event.which);
      var regex = /\s/;
      var whitespace = regex.test(character);

      if (whitespace) {
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});