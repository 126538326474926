define("herer-web/models/role", ["exports", "@ember-data/model", "herer-web/utils/validation", "yup"], function (_exports, _model, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      name: (0, _yup.string)().nullable().required()
    }),
    name: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user', {
      async: false
    }),
    permissions: (0, _model.attr)()
  });

  _exports.default = _default;
});