define("herer-web/components/customer-care-info", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    supportNumber: _constants.default.supportNumber,
    supportNumberUrl: Ember.computed('supportNumber', function () {
      var justNumber = this.get('supportNumber').replace(/\D/g, '');
      return "tel:+1".concat(justNumber);
    })
  });

  _exports.default = _default;
});