define("herer-web/components/packages/metrc-reconciler/row", ["exports", "herer-web/constants", "herer-web/mixins/taskable", "herer-web/models/weight", "herer-web/components/packages/package-reconciler/reconcile"], function (_exports, _constants, _taskable, _weight, _reconcile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEOUT_ERR = {
    ok: false,
    status: 408
  };

  var _default = _reconcile.default.extend(_taskable.default, {
    http: Ember.inject.service(),
    onTraceabilityChange: null,
    onNotSynchronized: null,
    package: null,
    traceabilityStatus: null,
    sourceOfTruth: null,
    stepName: null,
    canReconcile: false,
    problem: undefined,
    uuid: Ember.computed.alias('package.id'),
    remoteQuantity: Ember.computed('traceabilityStatus', function () {
      if (this.get('canReconcile')) {
        return _weight.default.create(this.traceabilityStatus.remote.quantity);
      }

      return undefined;
    }),
    localQuantity: Ember.computed('traceabilityStatus', function () {
      if (this.get('canReconcile')) {
        return _weight.default.create(this.traceabilityStatus.local.quantity);
      }

      return undefined;
    }),
    fetchTimeout: function fetchTimeout(request) {
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30000;
      return new Promise(function (resolve, reject) {
        request().then(resolve, reject);
        setTimeout(reject.bind(null, TIMEOUT_ERR), timeout);
      });
    },
    getTraceabilityStatus: function getTraceabilityStatus() {
      var _this = this;

      var uuid = this.get('uuid');

      var getRequest = function getRequest() {
        return _this.get('http').get("".concat(_constants.default.apiHost, "/api/v2/packages/").concat(uuid, "/traceability_status"));
      };

      this.fetchTimeout(getRequest, 30000).then(function (response) {
        _this.handleTraceabilityStatus(response.data);
      }).catch(function (_error) {
        _this.safeSet('canReconcile', false);
      });
    },
    handleTraceabilityStatus: function handleTraceabilityStatus(traceabilityStatus) {
      var problem = traceabilityStatus.problem,
          synchronized = traceabilityStatus.synchronized;
      this.safeSet('traceabilityStatus', traceabilityStatus);

      if (!synchronized && problem === null) {
        this.safeSet('canReconcile', true);
        this.onNotSynchronized();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();

      this.fetchTraceabilityStatus();
    },
    safeSet: function safeSet(field, value) {
      var caller = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this;

      if (!(caller.get('isDestroyed') || caller.get('isDestroying'))) {
        caller.set(field, value);
      }
    },
    // Override callback from BaseModal which is called on willDestroyElement()
    hideModalOnDestroy: function hideModalOnDestroy() {},
    fetchTraceabilityStatus: function fetchTraceabilityStatus() {
      this.safeSet('sourceOfTruth', null);
      this.safeSet('traceabilityStatus', null);
      this.getTraceabilityStatus();
    },
    emmitSourceOfTruthChange: function emmitSourceOfTruthChange() {
      var packageTraceabilityPayload = {
        uuid: this.get('uuid'),
        sourceOfTruth: this.get('sourceOfTruth'),
        traceabilityStatus: this.get('traceabilityStatus')
      };
      this.get('onTraceabilityChange')(packageTraceabilityPayload);
    },
    actions: {
      pickSourceOfTruth: function pickSourceOfTruth(source) {
        var currentSource = this.get('sourceOfTruth');
        this.safeSet('sourceOfTruth', currentSource === source ? null : source);
        this.emmitSourceOfTruthChange();
      }
    }
  });

  _exports.default = _default;
});