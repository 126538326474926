define("herer-web/routes/menu-feeds/new", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/config/environment"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'marketing-nav',
    permissionKey: 'create_menu_feeds',
    model: function model() {
      var store = this.get('store');
      var webhookIntegratorParams = {
        beta: false
      };

      if (_environment.default.environment !== 'production' || _environment.default.environment !== 'preproduction') {
        delete webhookIntegratorParams.beta;
      } // TODO: remove default type when more integrations are available


      return Ember.RSVP.hash({
        productTypes: store.findAll('productType'),
        tags: store.findAll('tag'),
        webhookIntegrators: store.query('webhookIntegrator', webhookIntegratorParams),
        menuFeed: store.createRecord('menu-feed', {
          location: this.get('authManager.currentLocation')
        })
      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.menuFeed);
      controller.set('productTypes', hash.productTypes.sortBy('name'));
      controller.set('allTags', hash.tags);
      controller.set('webhookIntegrators', hash.webhookIntegrators.sortBy('name'));
    }
  });

  _exports.default = _default;
});