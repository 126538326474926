define("herer-web/components/id-detection/id-scanner-modal", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    actions: {
      onClick: function onClick() {
        this.onIconClick && this.onIconClick();
      }
    }
  });

  _exports.default = _default;
});