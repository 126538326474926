define("herer-web/controllers/weekly-sales", ["exports", "herer-web/mixins/context", "herer-web/constants", "moment"], function (_exports, _context, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    launchDarkly: Ember.inject.service(),
    queryParams: ['startWeek', 'endWeek', 'measure'],
    startWeek: (0, _moment.default)().subtract(1, 'w').startOf('week').format('YYYY-MM-DD'),
    endWeek: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    sameWeek: Ember.computed.equal('startWeek', 'endWeek'),
    startMaxDate: (0, _moment.default)().startOf('week').subtract(1, 'w').format('YYYY-MM-DD'),
    endMaxDate: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    measure: 'dollars',
    validMeasures: _constants.default.validMeasures.concat(['average sale']),
    showChart: true,
    table: Ember.computed.alias('model.table'),
    actions: {
      showChart: function showChart() {
        this.set('showChart', true);
      },
      showTable: function showTable() {
        this.set('showChart', false);
      },
      sortBy: function sortBy(column) {
        this.get('table').updateSort(column);
      },
      changeDate: function changeDate(property, date) {
        this.set(property, date);

        if (this.get('launchDarkly').can('romulan-deprecation-new-weekly-sales-report')) {
          if (property === 'startWeek') {
            this.set('endWeek', (0, _moment.default)(date).add(1, 'w').format('YYYY-MM-DD'));
          }

          if (property === 'endWeek') {
            this.set('startWeek', (0, _moment.default)(date).subtract(1, 'w').format('YYYY-MM-DD'));
          }
        }
      }
    }
  });

  _exports.default = _default;
});