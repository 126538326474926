define("herer-web/controllers/index", ["exports", "herer-web/mixins/context", "herer-web/mixins/titan-email"], function (_exports, _context, _titanEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_context.default, _titanEmail.default, {
    config: Ember.inject.service(),
    isActive: Ember.computed.readOnly('authManager.currentLocation.active'),
    newHomepageEnabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get("config").getConfig("APP.enablePendo")) {
        return;
      }

      var visitor = this.get('currentUser.pendoized');

      var enrichedVisitor = _objectSpread(_objectSpread({}, visitor), {}, {
        location_id: this.get('currentLocation.id')
      });

      var account = this.get('currentAccount.pendoized');
      var location = this.get('currentLocation.pendoized');
      pendo.initialize({
        visitor: enrichedVisitor,
        account: _objectSpread(_objectSpread({}, account), location)
      });
    },
    canSeeNewHomepage: Ember.computed('currentUser', function () {
      return this.get('permissionsManager').verifyForced('analyze_sales_on_home_page');
    }),
    devices: Ember.computed('model.[]', function () {
      return this.get('model').toArray().filter(function (el) {
        var isLinked = el.get('linked');
        var isRegister = el.get('deviceTypeName');
        return isLinked && isRegister === 'Register';
      });
    }),
    actions: {
      enableNewHomepage: function enableNewHomepage() {
        this.set('newHomepageEnabled', true);
      }
    }
  });

  _exports.default = _default;
});