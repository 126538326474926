define("herer-web/components/inventory-form", ["exports", "herer-web/mixins/context", "herer-web/models/weight", "herer-web/constants", "herer-web/mixins/confirm-dialog", "herer-web/mixins/inventory-printing", "herer-web/mixins/inventory-product-change", "herer-web/mixins/inventory-weights", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/product-selector", "herer-web/mixins/location-features", "ember-inflector", "herer-web/components/products/utils/sku"], function (_exports, _context, _weight, _constants, _confirmDialog, _inventoryPrinting, _inventoryProductChange, _inventoryWeights, _lotNumberFormatter, _productSelector, _locationFeatures, _emberInflector, _sku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component;

  var componentMixins = [_confirmDialog.default, _context.default, _inventoryPrinting.default, _inventoryProductChange.default, _inventoryWeights.default, _lotNumberFormatter.default, _productSelector.default, _locationFeatures.default];

  var _default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, componentMixins.concat([{
    inventoryItem: null,
    productTypes: [],
    tieredPricingModels: [],
    tags: [],
    onSave: null,
    onCancel: null,
    placesEnabled: null,
    toast: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    store: Ember.inject.service(),
    skuTaken: false,
    skuFindInFlight: false,
    medicalSubtypeOptions: _constants.default.medicalSubtypes,
    sampleTypes: _constants.default.sampleTypes,
    inventoryItemToReassign: Ember.computed.alias('inventoryItem'),
    isFlower: Ember.computed.alias('inventoryItem.isFlower'),
    isMarijuana: Ember.computed.alias('inventoryItem.isMarijuana'),
    inventoryItemPlacesWithNonZeroQuantityOrLocked: Ember.computed.alias('inventoryItem.inventoryItemPlacesWithNonZeroQuantityOrLocked'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('skuProvider', new _sku.default(this.store, this.featureManager, this.launchDarkly));
    },
    canEditLotNumber: Ember.computed('currentLocation', 'isMarijuana', function () {
      return this.get('featureManager').can('edit-lot-numbers') && this.get('isMarijuana');
    }),
    inventoryItemDisplayQuantity: Ember.computed('inventoryItem.product.unit_of_measure', 'inventoryItem.quantity.value', function () {
      var value = this.get('inventoryItem.quantity.value');
      var unitLabel = this.get('inventoryItem.quantity.unitName');

      if (value !== 1) {
        unitLabel = (0, _emberInflector.pluralize)(unitLabel);
      }

      return "".concat(value, " ").concat(unitLabel);
    }),
    productHeader: Ember.computed('inventoryItem.product.name', function () {
      return "Product / ".concat(this.get('inventoryItem.product.name'));
    }),
    showMoveOption: Ember.computed('placesEnabled', 'inventoryItem', function () {
      return this.get('placesEnabled') && this.get('permissionsManager').verifyForced('manage_places') && this.get('inventoryItem.hasPositiveQuantityInUnlockedPlaces');
    }),
    showMedicalSubtype: Ember.computed('currentLocation.medical', 'inventoryItem.medical', function () {
      var featureManager = this.get('featureManager');
      return featureManager.can('medical') && featureManager.can('medical-subtype') && this.get('currentLocation.medical') && this.get('inventoryItem.medical');
    }),
    saveDisabled: Ember.computed('inventoryItem.{product,sku}', 'skuFindInFlight', 'skuTaken', 'externalInventoryIdRequired', function () {
      return Ember.isEmpty(this.get('inventoryItem.product')) || Ember.isEmpty(this.get('inventoryItem.sku')) || this.get('skuFindInFlight') || this.get('skuTaken') || this.get('externalInventoryIdRequired');
    }),
    externalInventoryIdRequired: Ember.computed('inventoryItem.{product,traceable,external_inventory_id}', 'supportsCCRS', function () {
      return this.get('supportsCCRS') && Ember.isEmpty(this.get('inventoryItem.external_inventory_id')) && (this.get('inventoryItem.traceable') || this.get('inventoryItem.product.product_type.isTraceable'));
    }),
    showLotNumber: Ember.computed('inventoryItem.lot_number', 'currentLocation', function () {
      return this.get('inventoryItem.lot_number') && (this.get('featureManager').can('traceability') || this.get('featureManager').can('create-traceable-mj-manifest')) && !this.get('featureManager').can('create-untraceable-mj-manifest');
    }),
    supportsCCRS: Ember.computed(function () {
      return this.get('currentLocation.isWashington') && this.get('launchDarkly').can('enable-ccrs');
    }),
    allowUseByDateField: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('eng-61070-metrc-use-by-date');
    }),
    showSkuError: function showSkuError(skuTaken) {
      this.set('skuTaken', skuTaken);
    },
    actions: {
      showSkuError: function showSkuError(skuTaken) {
        this.showSkuError(skuTaken);
      },
      skuFindInFlight: function skuFindInFlight(value) {
        this.set('skuFindInFlight', value);
      },
      setField: function setField(inventoryItem, field, value) {
        inventoryItem.set(field, value);
      },
      showProductSelector: function showProductSelector() {
        this.showProductSelector();
      },
      setWeightField: function setWeightField(field, value) {
        this.get('inventoryItem').set(field, _weight.default.create({
          value: value,
          unit: _constants.default.weightUnit.gram
        }));
      },
      selectProduct: function selectProduct(product) {
        var _this = this;

        var currentProduct = this.get('inventoryItem.product');

        if (currentProduct.get('id') === product.get('id')) {
          return;
        }

        this.confirmInventoryChange(currentProduct, product, function () {
          _this.set('inventoryItem.product', product);

          _this.get('inventoryItem').save();

          if (_this.get('inventoryItem.sku')) {
            var sku = _this.get('inventoryItem.sku');

            var productId = _this.get('inventoryItem.product.id');

            _this.skuProvider.find(sku).then(function (inventoryItems) {
              var matchingItems = _this.skuProvider.filterBySku(inventoryItems, sku, productId, undefined);

              _this.showSkuError(!Ember.isEmpty(matchingItems));
            });
          } // needed to update/reload counts


          currentProduct.reload();
          product.reload();
        });
      }
    }
  }]));

  _exports.default = _default;
});