define("herer-web/controllers/tax-item", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/constants", "ember-uuid/utils/uuid-generator", "bootbox", "moment"], function (_exports, _alertUnsavedController, _constants, _uuidGenerator, _bootbox, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, {
    featureManager: Ember.inject.service(),
    taxesController: Ember.inject.controller('taxes'),
    launchDarkly: Ember.inject.service(),
    showConflictMessage: false,
    checkAllProductTypes: false,
    booleanTypes: _constants.default.booleanTypes,
    metrcTaxTypes: _constants.default.metrcTaxTypes,
    compoundingOptions: [{
      value: true,
      name: 'Compound tax'
    }, {
      value: false,
      name: 'Standard tax'
    }],
    isPersonGroupEnabled: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('bw-person-group');
    }),
    isCompoundTaxEnabled: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('bw-compound-tax-redux');
    }),
    isSaving: Ember.computed('model', function () {
      return this.get('model.isSaving');
    }),
    findExclusionsByProductId: function findExclusionsByProductId(id) {
      var model = this.get('model');

      if (this.get('isPersonGroupEnabled')) {
        return model.get('applicable_tax_configurations').toArray().filterBy('product_type_id', id);
      } else {
        return model.get('applicable_product_types').toArray().findBy('product_type_id', id);
      }
    },
    showExcludePatients: Ember.computed(function () {
      if (this.get('isPersonGroupEnabled')) {
        return false;
      } else {
        var featureManager = this.get('featureManager');
        return featureManager.can('medical') && featureManager.can('recreational');
      }
    }),
    sortedSchedules: [],
    sortSchedules: function sortSchedules() {
      return this.get('model.tax_item_schedules').toArray().sort(function (a, b) {
        var effectiveStartA = a.get('effective_start');
        var effectiveStartB = b.get('effective_start');

        if (!Ember.isPresent(effectiveStartA) && Ember.isPresent(effectiveStartB)) {
          return -1;
        }

        if (Ember.isPresent(effectiveStartA) && !Ember.isPresent(effectiveStartB)) {
          return 1;
        }

        return Ember.compare(effectiveStartB, effectiveStartA);
      });
    },
    proxiedProductTypes: Ember.computed.map('taxesController.productTypes', function (productType) {
      return Ember.ObjectProxy.create({
        content: productType,
        checked: false,
        excludePatients: false
      });
    }),
    displayedPersonGroups: Ember.computed('taxesController.personGroups', function () {
      return this.get('taxesController.personGroups');
    }),
    taxConfigurations: Ember.computed('taxesController.{productTypes,personGroups}', function () {
      var _this = this;

      var productTypes = this.get('taxesController.productTypes');
      return productTypes.map(function (productType) {
        var personGroups = _this.get('taxesController.personGroups').map(function (personGroup) {
          return Ember.ObjectProxy.create({
            content: personGroup,
            checked: true,
            disabled: false
          });
        });

        return Ember.ObjectProxy.create({
          content: productType,
          personGroups: personGroups
        });
      });
    }),
    modelDidChange: function modelDidChange() {
      var model = this.get('model');
      this.set('sortedSchedules', this.sortSchedules());
      var isNewModel = model.get('isNew');

      if (this.get('isPersonGroupEnabled')) {
        if (isNewModel) {
          this.checkDefaultTaxConfigurations();
        } else {
          this.setTaxConfigurationsValues();
        }
      } else {
        if (isNewModel) {
          this.changeCheckAllProductTypes(true);
        } else {
          this.setProxiedProductTypeValues();
        }
      }
    },
    checkDefaultTaxConfigurations: function checkDefaultTaxConfigurations() {
      this.get('taxConfigurations').forEach(function (productType) {
        return productType.get('personGroups').forEach(function (personGroup) {
          return personGroup.set('checked', !personGroup.get('medical'));
        });
      });
    },
    setTaxConfigurationsValues: function setTaxConfigurationsValues() {
      var _this2 = this;

      this.get('taxConfigurations').forEach(function (productType) {
        var checkedTaxConfigurations = _this2.findExclusionsByProductId(productType.get('id'));

        productType.get('personGroups').forEach(function (personGroup) {
          var group = checkedTaxConfigurations.findBy('person_group_id', personGroup.get('id'));
          var isIncluded = Ember.isPresent(group) && !group.get('excluded');
          personGroup.set('checked', isIncluded);
        });
      });
    },
    // TODO remove when bw-person-group feature flag is removed
    setProxiedProductTypeValues: function setProxiedProductTypeValues() {
      var _this3 = this;

      this.get('proxiedProductTypes').forEach(function (productType) {
        var productConfiguration = _this3.findExclusionsByProductId(productType.get('id'));

        var isPresent = isPresent(productConfiguration);
        productType.set('checked', isPresent);
        productType.set('excludePatients', isPresent && productConfiguration.get('exclude_patients'));
      });
    },
    validateSchedule: function validateSchedule(schedule) {
      var start = schedule.get('effective_start');
      var end = schedule.get('effective_end');
      var errors = schedule.errors;

      if (!Ember.isPresent(start)) {
        errors.add('effective_start', "can't be blank");
      }

      if (Ember.isPresent(start) && Ember.isPresent(end) && end < start) {
        errors.add('effective_end', 'must be after start date');
      }

      if (!Ember.isPresent(schedule.get('rate'))) {
        errors.add('rate', "can't be blank");
      }

      if (schedule.get('rate') <= 0) {
        errors.add('rate', "Tax Rates cannot be 0%. Enter a Tax Rate that is higher than 0.");
      }

      if (this.get('model.compounding') && schedule.get('inclusive')) {
        errors.add('inclusive', "can't compound an inclusive tax");
      }

      return errors;
    },
    validatePresenceOfProducts: function validatePresenceOfProducts() {
      var model = this.get('model');
      var products, errorKey;

      if (this.get('isPersonGroupEnabled')) {
        products = this.taxConfigurationsToArray();
        errorKey = 'applicable_tax_configurations';
      } else {
        products = this.checkedProductTypesToArray();
        errorKey = 'applicable_product_types';
      }

      if (Ember.isEmpty(products)) {
        model.errors.add(errorKey, "can't be blank");
      }
    },
    validate: function validate() {
      var _this4 = this;

      this.resetErrorMessages();

      if (Ember.isEmpty(this.get('model.mj_only'))) {
        this.get('model.errors').add('mj_only', "can't be blank");
      }

      this.get('model.tax_item_schedules').forEach(function (s) {
        return _this4.validateSchedule(s);
      });
      this.validatePresenceOfProducts();
      var schedulesValid = this.get('model.tax_item_schedules').every(function (schedule) {
        return schedule.get('errors.isEmpty');
      });
      var valid = this.get('model.errors.isEmpty') && schedulesValid;
      return valid;
    },
    resetErrorMessages: function resetErrorMessages() {
      this.set('showConflictMessage', false);
      this.get('model.errors').clear();
      this.get('model.tax_item_schedules').forEach(function (schedule) {
        schedule.get('errors').clear();
      });
    },
    // TODO remove with bw-person-group feature flag
    checkedProductTypesToArray: function checkedProductTypesToArray() {
      var store = this.get('store');
      return this.get('proxiedProductTypes').filterBy('checked', true).map(function (productType) {
        return store.createFragment('applicable_product_type', {
          product_type_id: productType.get('id'),
          exclude_patients: productType.get('excludePatients')
        });
      });
    },
    taxConfigurationsToArray: function taxConfigurationsToArray() {
      var store = this.get('store');
      return this.get('taxConfigurations').map(function (productType) {
        return productType.get('personGroups').map(function (personGroup) {
          return store.createFragment('applicable_tax_configuration', {
            excluded: !personGroup.get('checked'),
            product_type_id: productType.get('id'),
            person_group_id: personGroup.get('id')
          });
        });
      }).reduce(function (acc, curr) {
        return acc.concat(curr);
      }, []);
    },
    setNextAvailableRank: function setNextAvailableRank() {
      var rank = 0;
      var taxItems = this.get('taxesController.compoundTaxes');

      if (Ember.isPresent(taxItems)) {
        rank = taxItems.mapBy('rank').pop() + 1;
      }

      this.set('model.rank', rank);
    },
    save: function save(resolve, reject) {
      var _this5 = this;

      var model = this.get('model');

      if (this.get('isPersonGroupEnabled')) {
        model.set('applicable_tax_configurations', this.taxConfigurationsToArray());
      } else {
        model.set('applicable_product_types', this.checkedProductTypesToArray());
      }

      if (model.get('isNew') && this.get('isCompoundTaxEnabled')) {
        this.setNextAvailableRank();
      }

      return model.save().then(function () {
        _this5.get('model.tax_item_schedules').forEach(function (schedule) {
          schedule._internalModel.send('willCommit');

          schedule._internalModel._attributes = {};
          schedule._internalModel._changedAttributes = {};

          schedule._internalModel.send('didCommit');
        });

        resolve(model);
      }).catch(function () {
        if (Ember.isPresent(model.get('errors.tax_item_schedules'))) {
          _this5.set('showConflictMessage', true);
        }

        reject();
      });
    },
    taxChangeMessage: function taxChangeMessage() {
      var newScheduleItem = this.get('model.tax_item_schedules.lastObject');
      var newScheduleDate = (0, _moment.default)(newScheduleItem.get('effective_start')).format('MMMM Do YYYY');
      var message = "These changes will take effect on ".concat(newScheduleDate, ", and will\n      change the Sell Price of <b>all taxed products.</b> If this is not your intention,\n      please select Cancel and contact Greenbits Support for further assistance.");
      return message;
    },
    saveTaxItem: function saveTaxItem(success, reject) {
      var _this6 = this;

      if (this.get('model.hasDirtyAttributes') && this.get('model.tax_item_schedules.length') > 1) {
        _bootbox.default.confirm({
          message: this.taxChangeMessage(),
          buttons: {
            confirm: {
              label: 'Save Tax and Trigger Sell Price Changes'
            }
          },
          callback: function callback(confirmed) {
            if (confirmed) {
              _this6.save(success, reject);
            } else {
              reject();
            }
          }
        });
      } else {
        this.save(success, reject);
      }
    },
    actions: {
      confirmSave: function confirmSave(success, reject) {
        if (!this.validate()) {
          return reject();
        }

        this.saveTaxItem(success, reject);
      },
      cancel: function cancel() {
        var _this7 = this;

        var model = this.get('model');
        this.set('showConflictMessage', false);

        if (model.get('isNew')) {
          model.deleteRecord();
        } else {
          model.rollbackAttributes();
          model.get('tax_item_schedules').forEach(function (schedule) {
            if (schedule.get('isNew')) {
              schedule.deleteRecord();
            } else {
              schedule.rollbackAttributes();
            }
          });
        }

        Ember.run.next(function () {
          _this7.set('model', null);
        });
      },
      addSchedule: function addSchedule() {
        var schedule = this.store.createRecord('tax_item_schedule', {
          id: _uuidGenerator.default.v4(),
          inclusive: true
        });
        this.get('model.tax_item_schedules').pushObject(schedule);
        this.set('sortedSchedules', this.sortSchedules());
      },
      removeSchedule: function removeSchedule(schedule) {
        this.get('model.tax_item_schedules').removeObject(schedule);
        schedule.deleteRecord();
        this.set('sortedSchedules', this.sortSchedules());
      },
      updateExcludePatients: function updateExcludePatients(excludePatients) {
        this.set('model.exclude_patients', excludePatients);
      },
      updateMjOnly: function updateMjOnly(mjOnly) {
        this.set('model.mj_only', mjOnly);
      },
      updateCompounding: function updateCompounding(value) {
        if (!this.get('model.isNew')) {
          return this.get('model.errors').add('compounding', "can't edit compounding");
        }

        this.set('model.compounding', value);
      },
      changeCheckAllProductTypes: function changeCheckAllProductTypes(value) {
        this.changeCheckAllProductTypes(value);
      }
    },
    changeCheckAllProductTypes: function changeCheckAllProductTypes(value) {
      this.set('checkAllProductTypes', value);
      this.get('proxiedProductTypes').setEach('checked', this.get('checkAllProductTypes'));
    }
  });

  _exports.default = _default;
});