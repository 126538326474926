define("herer-web/components/account-reports/daily-sales/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    backgroundStyles: Ember.computed('row.color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.get('row.color'));
    }),
    textStyles: Ember.computed('row.textColor', function () {
      return Ember.String.htmlSafe('color: ' + this.get('row.textColor'));
    })
  });

  _exports.default = _default;
});