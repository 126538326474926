define("herer-web/components/format-deleted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    isDeleted: Ember.computed.alias('model.is_deleted'),
    name: Ember.computed.alias('model.name')
  });

  _exports.default = _default;
});