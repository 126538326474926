define("herer-web/components/id-detection/id-detection-input", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    launchDarkly: Ember.inject.service(),
    swipeSuccess: false,
    swipeError: false,
    onIconClick: null,
    statusClass: Ember.computed('swipeError', 'swipeSuccess', function () {
      if (this.get('swipeSuccess')) {
        return 'success';
      } else if (this.get('swipeError')) {
        return 'error';
      }

      return;
    }),
    hasScan: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('bw-id-scan');
    }),
    hasSwipe: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('express-check-in');
    }),
    hasBothIdDetectionMethods: Ember.computed.and('hasScan', 'hasSwipe'),
    hasOnlySwipe: Ember.computed('hasScan', 'hasSwipe', function () {
      return this.get('hasSwipe') && !this.get('hasScan');
    }),
    hasOnlyScan: Ember.computed('hasScan', 'hasSwipe', function () {
      return !this.get('hasSwipe') && this.get('hasScan');
    }),
    actions: {
      onClick: function onClick() {
        this.onIconClick();
      },
      onChange: function onChange(newValue) {
        if (this.get('onChange')) {
          this.get('onChange')(newValue);
        }
      }
    }
  });

  _exports.default = _default;
});