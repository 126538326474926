define("herer-web/models/untracked-migration", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    location_id: (0, _model.attr)('string'),
    location: (0, _model.attr)(),
    group_by_strain: (0, _model.attr)('boolean'),
    start_time: (0, _model.attr)('date'),
    launch_date: (0, _model.attr)('date'),
    updated_at: (0, _model.attr)('date'),
    first_tag: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    last_tag: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    api_key: (0, _model.attr)('string'),
    license_number: (0, _model.attr)('string'),
    license_type: (0, _model.attr)('number'),
    notification_email: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    dry_run: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    take_inventory_backup: (0, _model.attr)('boolean', {
      default: false
    }),
    available_tags: (0, _model.attr)('string'),
    task: (0, _model.attr)(),
    queued: Ember.computed.equal('task.status', _constants.default.taskStatus.queued),
    processing: Ember.computed.equal('task.status', _constants.default.taskStatus.processing),
    status: Ember.computed('task.status', function () {
      switch (this.get('task.status')) {
        case _constants.default.taskStatus.queued:
          return 'Queued';

        case _constants.default.taskStatus.processing:
          return 'Processing';

        case _constants.default.taskStatus.success:
        case _constants.default.taskStatus.claimed:
          return 'Finished';

        case _constants.default.taskStatus.error:
          return 'Error';

        case _constants.default.taskStatus.cancelled:
          return 'Cancelled';
      }
    }),
    run_type: Ember.computed('this.dry_run', function () {
      return this.get('dry_run') ? 'Dry Run' : 'Go Live';
    })
  });

  _exports.default = _default;
});