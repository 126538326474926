define("herer-web/components/weekly-performance-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants", "moment"], function (_exports, _dimpleChart, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    width: Ember.computed(function () {
      return this.$().width();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var height = Math.round(this.get('width') * 0.3);
      this.$().height(height);
    },
    remap: function remap(data) {
      var _this = this;

      var newData = [];
      data.oneWeekAgo.source_data.forEach(function (row, index) {
        newData.push(_this._configureRowForSeries(row, index, 'This Week'));
      });
      data.twoWeeksAgo.source_data.forEach(function (row, index) {
        newData.push(_this._configureRowForSeries(row, index, 'Last Week'));
      });
      return newData;
    },
    customizeChart: function customizeChart(chart) {
      var width = this.get('width');
      var w = (width - 320) / (1110 - 320);
      var iX = d3.interpolateNumber(15, 5);
      var iY = d3.interpolateNumber(10, 5);
      var iWidth = d3.interpolateNumber(80, 90);
      var iHeight = d3.interpolateNumber(70, 75);
      var iLegend = d3.interpolateNumber(70, 25);
      var iTicks = d3.interpolateNumber(3, 10);
      chart.setBounds("".concat(iX(w), "%"), "".concat(iY(w), "%"), "".concat(iWidth(w), "%"), "".concat(iHeight(w), "%"));
      chart.addLegend("".concat(95 - iLegend(w), "%"), 0, "".concat(iLegend(w), "%"), 20, 'right');
      chart.ease = 'bounce';
      chart.staggerDraw = true;
      chart.defaultColors = _constants.default.chartColors;
      var y = chart.addMeasureAxis('y', 'Sales');
      y.showGridlines = false;
      y.title = '';
      y.fontSize = '12px';
      y.ticks = parseInt(iTicks(w));
      y.tickFormat = '$,.2s';
      var dayField = width > 909 ? 'Day of Week' : 'Day Abbreviation';
      var x = chart.addCategoryAxis('x', [dayField, 'Series']);
      x.addOrderRule(function (row1, row2) {
        if (row1.Series === 'This Week' && row2.Series === 'Last Week') {
          return -1;
        }

        if (row1.Series === 'Last Week' && row2.Series === 'This Week') {
          return 1;
        }

        return 0;
      });
      x.addOrderRule(function (row1, row2) {
        return row1.index[0] - row2.index[0];
      });
      x.showGridlines = false;
      x.title = '';
      x.fontSize = '12px';
      chart.addSeries('Series', dimple.plot.bar);
      return chart;
    },
    _configureRowForSeries: function _configureRowForSeries(row, index, seriesName) {
      row.Series = seriesName;
      row.index = index;
      row.Sales = row.sales;
      var day = (0, _moment.default)(row.day);
      row['Day of Week'] = day.format('dddd');
      row['Day Abbreviation'] = day.format('dd');
      return row;
    }
  });

  _exports.default = _default;
});