define("herer-web/controllers/adjustment", ["exports", "ember-uuid/utils/uuid-generator", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/adjustment-reasons", "herer-web/mixins/inventory-weights", "herer-web/mixins/context", "herer-web/constants", "bootbox"], function (_exports, _uuidGenerator, _alertUnsavedController, _adjustmentReasons, _inventoryWeights, _context, _constants, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller;

  var controllerMixin = [_adjustmentReasons.default, _alertUnsavedController.default, _context.default, _inventoryWeights.default];

  var _default = (_Ember$Controller = Ember.Controller).extend.apply(_Ember$Controller, controllerMixin.concat([{
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    inventoryItem: null,
    isConfirming: false,
    quantity: null,
    parentController: null,
    placesEnabled: Ember.computed.alias('currentLocation.places_enabled'),
    places: [],
    _hasValidated: false,
    validate: function validate() {
      this.set('_hasValidated', true);
      return !this.get('reasonError') && !this.get('placeError') && !this.get('noteError') && Ember.isEmpty(this.get('quantityError'));
    },
    reasonError: Ember.computed('_hasValidated', 'lotAdjustment.adjustment_reason', function () {
      var reason = this.get('lotAdjustment.adjustment_reason');

      if (this.get('_hasValidated') && (!reason || !reason.toString().trim().length)) {
        return ['Reason cannot be blank'];
      }
    }),
    placeError: Ember.computed('_hasValidated', 'lotAdjustment.place', function () {
      var place = this.get('lotAdjustment.place');

      if (this.get('placesEnabled') && this.get('_hasValidated') && (!place || !place.toString().trim().length)) {
        return ['Place cannot be blank'];
      }
    }),
    adjustablePlaces: Ember.computed('places', 'inventoryItem', function () {
      var _this$get;

      return ((_this$get = this.get('inventoryItem.inventory_item_places')) === null || _this$get === void 0 ? void 0 : _this$get.toArray().filter(function (iip) {
        return iip.get('quantity').eq(iip.get('unlocked_quantity'));
      }).map(function (iip) {
        return iip.get('place');
      })) || [];
    }),
    maxQuantity: Ember.computed('inventoryItem', 'quantity.unit', function () {
      var inventoryItemQuantity = this.get('inventoryItem.quantity');

      if (!this.get('placesEnabled')) {
        return inventoryItemQuantity;
      }

      return this.get('inventoryItem.unlockedInventoryItemPlacesWithPositiveQuantity').reduce(function (sum, iip) {
        return sum.plus(iip.get('unlocked_quantity'));
      }, inventoryItemQuantity.zero());
    }),
    noteError: Ember.computed('_hasValidated', 'lotAdjustment.note', function () {
      var note = this.get('lotAdjustment.note');

      if (!this.get('_hasValidated')) {
        return;
      } else if (!note || !note.toString().trim().length) {
        return ["Note cannot be blank"];
      } else if (note.length > _adjustmentReasons.maxAdjustmentNoteLength) {
        return ["Note is too long (maximum is ".concat(_adjustmentReasons.maxAdjustmentNoteLength, " characters)")];
      }
    }),
    quantityError: Ember.computed('_hasValidated', 'quantity.value', 'placesEnabled', function () {
      if (!this.get('_hasValidated')) {
        return [];
      }

      var quantity = this.get('quantity');
      var maxQuantity = this.get('maxQuantity');

      if (quantity.get('value').lt(0)) {
        return ['Quantity must be greater than or equal to 0'];
      }

      if (this.get('launchDarkly').can('cptf-764-enable-max-quantity-validation-for-adjustment')) {
        if (this.get('placesEnabled') && quantity.gt(maxQuantity)) {
          return ["The adjustment quantity is more than is available to be adjusted (".concat(maxQuantity, ")")];
        }
      }

      return [];
    }),
    showMovePrompt: Ember.computed('placesEnabled', 'isConfirming', function () {
      return this.get('placesEnabled') && !this.isConfirming;
    }),
    modal: Ember.computed('model', function () {
      return $('#adjustment-modal');
    }),
    itemUOM: Ember.computed.alias('inventoryItem.product.unit_of_measure'),
    lotAdjustment: Ember.computed.alias('model.firstLotAdjustment'),
    onInventoryItemChanged: function onInventoryItemChanged() {
      this.set('isConfirming', false);
      this.set('_hasValidated', false);
      this.set('quantity', this.get('maxQuantity').copy());
      this.set('model', this.get('store').createRecord('manifest', {
        id: _uuidGenerator.default.v4(),
        manifest_type: _constants.default.manifestType.adjustment
      }));
      var lotAdjustment = this.get('store').createRecord('lot_adjustment', {
        inventory_item_id: this.get('inventoryItem.id'),
        adjustment_type: _constants.default.lotAdjustmentType.adjustment
      });
      this.get('model.lot_adjustments').pushObject(lotAdjustment);
      this.set('lotAdjustment', lotAdjustment);
      this.get('modal').on('shown.bs.modal', function () {
        $('.quantity-input').focus();
        return $('.quantity-input').select();
      });
      this.get('modal').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    actions: {
      confirmAdjustment: function confirmAdjustment() {
        if (!this.validate()) {
          return;
        } else {
          this.set('isConfirming', true);
        }
      },
      unconfirmAdjustment: function unconfirmAdjustment() {
        return this.set('isConfirming', false);
      },
      saveAdjustment: function saveAdjustment() {
        var _this = this;

        var adjustmentQuantity = this.get('quantity').minus(this.get('inventoryItem.quantity'));
        this.set('lotAdjustment.quantity', adjustmentQuantity);
        this.set('lotAdjustment.adjustment_reason_id', this.get('lotAdjustment.adjustment_reason'));
        this.set('model.charged_on', new Date());
        this.get('model').save().then(function (inventoryItem) {
          if (!_this.get('placesEnabled')) {
            // for !placesEnabled, product qty is set async in the backend; this cheats to update FE
            var productQuantity = _this.get('inventoryItem.product.quantity');

            _this.set('inventoryItem.product.quantity', productQuantity.plus(adjustmentQuantity));
          }

          _this.set('inventoryItem.quantity', _this.get('quantity'));

          _this.set('inventoryItem', null);

          _this.hideModal();

          if (Ember.isPresent(_this.get('parentController'))) {
            _this.get('parentController').send('onInventoryItemAdjustment', inventoryItem);
          }
        }).catch(function (e) {
          console.error(e);

          _this.set('isConfirming', false);

          _this.showErrorModal();
        });
      },
      cancelAdjustment: function cancelAdjustment() {
        this.set('inventoryItem', null);
        this.get('lotAdjustment').deleteRecord();
        this.get('model').deleteRecord();
        this.hideModal();
      },
      setQuantityValue: function setQuantityValue(value) {
        this.set('quantity.value', value);
      }
    },
    hideModal: function hideModal() {
      this.get('modal').modal('hide');
    },
    showErrorModal: function showErrorModal() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to save this adjustment.\n      If you still have this issue, chat Support.");
    }
  }]));

  _exports.default = _default;
});