define("herer-web/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isMenuExpanded: false,
    launchDarkly: Ember.inject.service(),
    reportsLinkRoute: Ember.computed('currentRole.permissions.[]', function () {
      var permissionsManager = this.get('permissionsManager');

      if (permissionsManager.verifyForced('view_sales_summary_report')) {
        return 'sales-summary-report';
      } else if (permissionsManager.verifyForced('view_tax_reports')) {
        return 'monthly-tax-report';
      } else if (permissionsManager.verifyForced('view_sales_by_reports')) {
        return 'weekly-sales';
      } else {
        return 'reports.other';
      }
    }),
    inventoryLinkRoute: Ember.computed('currentRole.permissions.[]', function () {
      var permissionsManager = this.get('permissionsManager');

      if (permissionsManager.verifyForced('view_products')) {
        return 'products';
      } else if (permissionsManager.verifyForced('view_product_types')) {
        return 'product-types';
      } else if (permissionsManager.verifyForced('receive_inventory')) {
        return 'manifests';
      } else if (permissionsManager.verifyForced('return_inventory')) {
        return 'return_inventory';
      }

      return null;
    }),
    settingsLinkRoute: Ember.computed('currentRole.permissions.[]', function () {
      var permissionsManager = this.get('permissionsManager');

      if (permissionsManager.verifyForced('view_company_settings')) {
        return 'location';
      } else {
        return 'account';
      }
    }),
    actions: {
      closeMenu: function closeMenu() {
        this.set('isMenuExpanded', false);
      },
      toggleMenu: function toggleMenu() {
        var isExpanded = this.get('isMenuExpanded');
        this.set('isMenuExpanded', !isExpanded);
      },
      switchLocation: function switchLocation(location) {
        this.get('authManager').changeLocation(location);
      },
      logout: function logout() {
        this.logout();
      }
    }
  });

  _exports.default = _default;
});