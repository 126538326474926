define("herer-web/components/loyalty-points", ["exports", "lodash/isNumber"], function (_exports, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    person: null,
    loyaltyPointTransaction: null,
    newPointTotal: null,
    isConfirming: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // TODO: refactor: DDAU when @tracked will be available


      if (this.get('componentIsVisible')) {
        this.show();
      }
    },
    show: function show() {
      var person = this.get('person');
      var store = this.get('store');
      var loyaltyPointTransaction = store.createRecord('loyalty-point-transaction', {
        person_id: person.get('id')
      });
      this.set('loyaltyPointTransaction', loyaltyPointTransaction);
      this.set('isConfirming', false);
    },
    hide: function hide() {
      // TODO: refactor: DDAU when @tracked will be available
      this.set('componentIsVisible', false);
    },
    reset: function reset() {
      this.set('newPointTotal', null);
      this.set('loyaltyPointTransaction', null);
      this.set('isConfirming', false);
      this.hide();
    },
    actions: {
      confirmLoyaltyPointTransaction: function confirmLoyaltyPointTransaction(loyaltyPointTransaction) {
        var _this = this;

        loyaltyPointTransaction.validations.validate().then(function () {
          _this.set('isConfirming', true);
        }).catch(function () {});
      },
      saveLoyaltyPointTransaction: function saveLoyaltyPointTransaction(loyaltyPointTransaction) {
        var _this2 = this;

        loyaltyPointTransaction.save().then(function () {
          var person = _this2.get('person');

          var newPoints = person.get('loyaltyPoints');

          if ((0, _isNumber.default)(newPoints)) {
            newPoints = newPoints + loyaltyPointTransaction.get('loyaltyPoints');
          } else {
            newPoints = loyaltyPointTransaction.get('loyaltyPoints');
          } // loyalty points is denormalized from transactions so we don't want
          // this change to trigger hasDirtyAttribute on the person model


          var isDirty = person.get('hasDirtyAttributes');
          person.set('loyaltyPoints', newPoints);

          if (!isDirty) {
            person.set('hasDirtyAttributes', false);
          }

          _this2.reset();
        });
      },
      cancelLoyaltyPointTransaction: function cancelLoyaltyPointTransaction(loyaltyPointTransaction) {
        loyaltyPointTransaction.rollbackAttributes();
        this.reset();
      },
      setLoyaltyPoints: function setLoyaltyPoints(loyaltyPointTransaction, newPointTotal) {
        this.set('newPointTotal', newPointTotal);
        var currentPoints = this.get('person.loyaltyPoints');
        var adjustmentAmount = currentPoints ? newPointTotal - currentPoints : newPointTotal;
        loyaltyPointTransaction.set('loyaltyPoints', adjustmentAmount);
      }
    }
  });

  _exports.default = _default;
});