define("herer-web/components/tax-preview", ["exports", "herer-web/helpers/format-percent"], function (_exports, _formatPercent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseTaxes: [],
    compoundTaxes: [],
    inclusivePrice: 1000,
    inclusivePriceWithoutCents: Ember.computed('inclusivePrice', function () {
      var price = this.get('inclusivePrice');
      return Math.round(price / 100);
    }),
    totalTax: Ember.computed('formattedBaseTaxes', 'formattedCompoundTaxes', function () {
      var taxes = this.get('formattedBaseTaxes').concat(this.get('formattedCompoundTaxes'));
      return taxes.reduce(function (acc, item) {
        return acc + item.amount;
      }, this.get('inclusivePrice'));
    }),
    compoundValues: Ember.computed('activeCompoundTaxes', 'inclusivePrice', function () {
      var inclusivePrice = this.get('inclusivePrice');
      var sum = inclusivePrice;
      return this.get('activeCompoundTaxes').map(function (item) {
        var value = item.get('rate') * sum;
        sum += value;
        return (value / 100).toFixed(2).replace('.00', '');
      });
    }),
    formattedBaseTaxes: Ember.computed('baseTaxes', function () {
      return this.formatTaxes(this.get('baseTaxes'));
    }),
    activeCompoundTaxes: Ember.computed('compoundTaxes', 'compoundTaxes.@each.hasEffectiveSchedule', function () {
      return this.get('compoundTaxes').filterBy('hasEffectiveSchedule', true);
    }),
    formattedCompoundTaxes: Ember.computed('activeCompoundTaxes', function () {
      return this.formatTaxes(this.get('activeCompoundTaxes'));
    }),
    formatTaxes: function formatTaxes(taxes) {
      var inclusivePrice = this.get('inclusivePriceWithoutCents');
      var compoundValues = this.get('compoundValues');
      return taxes.map(function (item, i) {
        var rate = parseFloat(item.get('rate'));
        var isCompounding = item.get('compounding');
        var amount = inclusivePrice * rate;
        var calculation = "".concat(inclusivePrice, " x ").concat(rate);

        if (isCompounding) {
          var previousTaxes = compoundValues.slice(0, i);

          if (previousTaxes.length) {
            var compoundRates = "(".concat(inclusivePrice, " + ").concat(previousTaxes.join(' + '), ")");
            calculation = calculation.replace(inclusivePrice, compoundRates);
          }

          amount = parseFloat(compoundValues[i]);
        }

        return {
          name: "".concat(item.get('name'), " (").concat((0, _formatPercent.formatPercent)(rate), ")"),
          calculation: calculation,
          amount: amount * 100
        };
      });
    }
  });

  _exports.default = _default;
});