define("herer-web/components/datetime-input", ["exports", "moment", "lodash/isString"], function (_exports, _moment, _isString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MOMENT_DATETIME_FORMATS = {
    'm/d/Y G:i K': 'MM/DD/YYYY hh:mm A',
    'G:i K': 'hh:mm A'
  };
  var MASK_DATETIME_FORMATS = {
    'm/d/Y G:i K': 'mm/dd/yyyy hh:MM TT',
    'G:i K': 'hh:MM TT'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    enableTime: true,
    timeZone: Ember.computed.reads("authManager.currentLocation.timezone"),
    altFormat: Ember.computed('dateFormat', function () {
      var dateFormat = this.get('dateFormat');
      return dateFormat ? dateFormat : 'm/d/Y G:i K';
    }),
    minimumDate: Ember.computed('minDate', function () {
      var minDate = this.get('minDate');
      return minDate && this.parseDateToDefaultTimezone(minDate);
    }),
    inputMaskFormat: Ember.computed('altFormat', function () {
      return MASK_DATETIME_FORMATS[this.altFormat];
    }),
    placeholderValue: Ember.computed('placeholder', 'noCalender', function () {
      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.isTimeInput) {
        return 'hh:MM TM';
      }

      return 'mm/dd/yyyy hh:MM TM';
    }),
    maximumDate: Ember.computed('maxDate', function () {
      var maxDate = this.get('maxDate');
      return maxDate && this.parseDateToDefaultTimezone(maxDate);
    }),
    isoDate: Ember.computed('datetime', function () {
      var datetime = this.get('datetime');

      if (datetime && !(0, _isString.default)(datetime)) {
        return datetime.toISOString().replace("Z", "+00:00");
      } else {
        return datetime;
      }
    }),
    _value: Ember.computed('stringDate', {
      get: function get() {
        return this.stringDate;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    stringDate: Ember.computed('datetime', function () {
      var datetime = this.datetime;

      if (!datetime) {
        return undefined;
      }

      return (0, _moment.default)(datetime).format(MOMENT_DATETIME_FORMATS[this.altFormat]);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'inputMaskOption', {
        inputFormat: this.inputMaskFormat,
        outputFormat: this.inputMaskFormat,
        clearIncomplete: true
      });
      Ember.set(this, 'randomId', Ember.guidFor(this));
    },
    handleOnChange: function handleOnChange(date) {
      this.onChange && this.onChange(date);
    },
    actions: {
      changeDate: function changeDate(dates, dateString) {
        if (dateString === "") {
          return this.handleOnChange(dateString);
        }

        var date = dates[0];

        var formatedDate = _moment.default.tz(date, this.get('timeZone')).locale('en-GB');

        this.handleOnChange(new Date(formatedDate));
      },
      parseDate: function parseDate(dateString) {
        return this.parseDateToDefaultTimezone(dateString);
      },
      flatpickrReady: function flatpickrReady(_selectedDates, _dateString, flatpickrInstance) {
        flatpickrInstance.showTimeInput = this.enableTime;
        Ember.set(this, 'flatpickrInstance', flatpickrInstance);
      },
      openPicker: function openPicker(event) {
        this.flatpickrInstance.open(event, document.querySelector("#".concat(this.randomId)));

        if (this.isTimeInput) {
          this.flatpickrInstance.hourElement.focus();
        }
      },
      updateInputMask: function updateInputMask(value) {
        if (value === '') {
          this.flatpickrInstance.clear();
        }

        Ember.set(this, '_value', value);
      },
      handleBlur: function handleBlur(event) {
        return this.handleBlur(event);
      },
      handleEnter: function handleEnter(event) {
        if (event.keyCode === 13) {
          this.handleBlur(event);
          this.flatpickrInstance.close();
        }
      }
    },
    changeDatetime: function changeDatetime(value) {
      if (this.isTimeInput) {
        var today = (0, _moment.default)().format('YYYY-MM-DD');
        var datetime = "".concat(today, " ").concat(value);
        this.handleOnChange(new Date(datetime));
      } else {
        this.handleOnChange(new Date(value));
      }
    },
    parseDateToDefaultTimezone: function parseDateToDefaultTimezone(date) {
      var timezonedDate = (0, _moment.default)(date);
      return new Date(timezonedDate.year(), timezonedDate.month(), timezonedDate.date(), timezonedDate.hour(), timezonedDate.minute(), timezonedDate.second());
    },
    isValidDate: function isValidDate(dateString) {
      return (0, _moment.default)(dateString, MOMENT_DATETIME_FORMATS[this.altFormat], true).isValid();
    },
    isCalendarElement: function isCalendarElement(event) {
      var _event$relatedTarget, _event$relatedTarget$;

      return (_event$relatedTarget = event.relatedTarget) === null || _event$relatedTarget === void 0 ? void 0 : (_event$relatedTarget$ = _event$relatedTarget.className) === null || _event$relatedTarget$ === void 0 ? void 0 : _event$relatedTarget$.includes('flatpickr');
    },
    handleBlur: function handleBlur(event) {
      if (this.isCalendarElement(event)) {
        return;
      }

      if (this._value && this.isValidDate(this._value)) {
        this.changeDatetime(this._value);
      } else {
        Ember.set(this, '_value', this.stringDate);
      }
    }
  });

  _exports.default = _default;
});