define("herer-web/models/package-history-event", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)('string'),
    eventType: (0, _model.attr)('string'),
    totalQuantity: (0, _model.attr)('weight'),
    diffQuantity: (0, _model.attr)('weight'),
    metadata: (0, _model.attr)(),
    occurredAt: (0, _model.attr)('date'),
    statusUpdatedAt: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    note: (0, _model.attr)('string'),
    package: (0, _model.belongsTo)('package', {
      inverse: 'packageHistoryEvents'
    }),
    user: (0, _model.belongsTo)('user'),
    eventName: Ember.computed('eventType', function () {
      return _constants.default.packageHistoryEvents.findBy('value', this.get('eventType')).name;
    }),
    userName: Ember.computed('user', function () {
      var userName = this.get('user.name');
      return Ember.isEmpty(userName) ? _constants.default.defaultPackageHistoryUser : userName;
    })
  });

  _exports.default = _default;
});