define("herer-web/models/account", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    demo_account: (0, _model.attr)('boolean'),
    qs_enabled: (0, _model.attr)('boolean'),
    owner_id: (0, _model.attr)('string'),
    salesforce_id: (0, _model.attr)('string', {
      readOnly: true
    }),
    account_number: (0, _model.attr)('string', {
      readOnly: true
    }),
    pendoized: Ember.computed('id', function () {
      return {
        account_id: this.get('id'),
        account_name: this.get('name'),
        account_created_at: this.get('created_at'),
        demo_account: this.get('demo_account'),
        reports: this.get('qs_enabled')
      };
    })
  });

  _exports.default = _default;
});