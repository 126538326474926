define("herer-web/utils/color", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHexColorFromString = getHexColorFromString;
  _exports.getSchemeColorFromString = getSchemeColorFromString;

  /* jshint bitwise: false */
  function getHashFromStr(str) {
    var hash = 0;

    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hash;
  }

  function getHexColorFromString(str) {
    var color = '#';
    var hash = getHashFromStr(str);

    for (var i = 0; i < 3; i++) {
      var value = hash >> i * 8 & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
  }

  function getSchemeColorFromString(str) {
    var hash = getHashFromStr(str) % _constants.default.schemeColors.length;

    return _constants.default.schemeColors[Math.abs(hash)];
  }
});