define("herer-web/templates/components/account-sales-summary-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xnO1Dhv8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[11,\"class\",[29,[\"table-section-header \",[28,\"if\",[[24,[\"first\"]],\"top\"],null]]]],[10,\"data-test-text\",\"sales_summary_section_header\"],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\": \"],[1,[28,\"format-date\",[[24,[\"table\",\"filters\",\"startDate\"]]],[[\"dateFormat\"],[[24,[\"dateFormat\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"table\",\"empty\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    No data.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"report-table\",null,[[\"table\",\"sortBy\",\"sortDirection\",\"tableHeadingWidth\"],[[24,[\"table\"]],[24,[\"sortBy\"]],[24,[\"sortDirection\"]],\"20%\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/account-sales-summary-section.hbs"
    }
  });

  _exports.default = _default;
});