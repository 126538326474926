define("herer-web/templates/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYzqo/Zx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"successMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 errors\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"alert alert-success\"],[10,\"data-test-text\",\"success_message\"],[8],[0,\"\\n        \"],[1,[22,\"successMessage\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"data-test-ux\"],[\"Location Settings\",\"These are settings for your current location.\",\"location_settings_header\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"locations/location-form\",null,[[\"model\",\"onSave\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/location.hbs"
    }
  });

  _exports.default = _default;
});