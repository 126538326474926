define("herer-web/components/packages/package-reconciler/reconcile", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    traceabilityStatus: null,
    package: null,
    onUseRemoteAsSource: null,
    onUseLocalAsSource: null,
    onCancel: null,
    remoteQuantity: Ember.computed('traceabilityStatus', function () {
      return _weight.default.create(this.traceabilityStatus.remote.quantity);
    }),
    localQuantity: Ember.computed('traceabilityStatus', function () {
      return _weight.default.create(this.traceabilityStatus.local.quantity);
    }),
    remoteStatus: Ember.computed('traceabilityStatus', function () {
      return this.getStatusName(this.traceabilityStatus.remote.status);
    }),
    localStatus: Ember.computed('traceabilityStatus', function () {
      return this.getStatusName(this.traceabilityStatus.local.status);
    }),
    localStatusToCssClass: Ember.computed('localStatus', function () {
      return this.get('localStatus').dasherize();
    }),
    remoteStatusToCssClass: Ember.computed('remoteStatus', function () {
      return this.get('remoteStatus').dasherize();
    }),
    canUseLocalAsSource: Ember.computed('traceabilityStatus', function () {
      return this.traceabilityStatus.source_of_truth !== 'remote';
    }),
    canUseRemoteAsSource: Ember.computed('traceabilityStatus', function () {
      return this.traceabilityStatus.source_of_truth !== 'local';
    }),
    getStatusName: function getStatusName(status) {
      var statusData = _constants.default.packageStatuses.findBy('value', status);

      return statusData.name;
    }
  });

  _exports.default = _default;
});