define("herer-web/templates/components/menu-feeds/feed-preview/group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/AqToT/O",
    "block": "{\"symbols\":[\"header\"],\"statements\":[[4,\"each\",[[24,[\"headers\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"style\",[23,1,[\"style\"]]],[10,\"class\",\"hidden-xs hidden-sm text-right\"],[8],[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/menu-feeds/feed-preview/group-header.hbs"
    }
  });

  _exports.default = _default;
});