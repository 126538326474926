define("herer-web/initializers/ember-array", ["exports", "lodash/isNumber", "lodash/isNaN"], function (_exports, _isNumber, _isNaN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  var initialize = function initialize() {
    return Ember.Array.reopen({
      sum: function sum(property) {
        return this.reduce(function (sum, item) {
          if (sum == null) {
            sum = 0;
          }

          var value = item.get(property);

          if ((0, _isNumber.default)(value) && !(0, _isNaN.default)(value)) {
            sum += value;
          }

          return sum;
        });
      }
    });
  };

  _exports.initialize = initialize;
  var EmberArrayInitializer = {
    name: 'ember-array',
    initialize: initialize
  };
  var _default = EmberArrayInitializer;
  _exports.default = _default;
});