define("herer-web/controllers/manifest", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/constants", "herer-web/models/weight", "herer-web/mixins/product-selector", "herer-web/mixins/context", "herer-web/utils/error-reporter", "bootbox", "lodash/isNumber", "lodash/isNaN", "lodash/debounce", "herer-web/components/products/utils/sku"], function (_exports, _alertUnsavedController, _constants, _weight, _productSelector, _context, _errorReporter, _bootbox, _isNumber, _isNaN, _debounce, _sku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _productSelector.default, _context.default, {
    productStore: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    supportNumber: _constants.default.supportNumber,
    pendingManifest: false,
    isAccepting: false,
    isRejecting: false,
    selectedLot: null,
    formattedErrors: [],
    newProduct: null,
    findProduct: true,
    isSavingProduct: false,
    labelPreference: null,
    receiptPreference: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('skuProvider', new _sku.default(this.store, this.featureManager));
    },
    showUnsavedAlert: Ember.computed('hasDirtyAttributes', 'model.lots.[].hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.lots').any(function (lot) {
        return lot.get('hasDirtyAttributes');
      });
    }),
    transferred: Ember.computed('pendingManifest', 'model.{accepted,rejected}', function () {
      return !this.get('pendingManifest') && (this.get('model.accepted') || this.get('model.rejected'));
    }),
    editable: Ember.computed.or('pendingManifest', 'model.accepted'),
    isSaving: Ember.computed.or('isAccepting', 'isRejecting'),
    isManualMjManifest: Ember.computed('model.{hasMjLots,isNew}', 'nonTraceableManifest', 'currentLocation', function () {
      var nonTraceableManifest = this.get('nonTraceableManifest');
      var hasMjLots = this.get('model.hasMjLots');
      var featureManager = this.get('featureManager');
      var hasFeature = !featureManager.can('traceability') || featureManager.can('create-traceable-mj-manifest');
      return hasFeature && hasMjLots && nonTraceableManifest;
    }),
    isNewManualMjManifest: Ember.computed.and('model.isNew', 'isManualMjManifest'),
    isManualParaManifest: Ember.computed('model.hasMjLots', 'nonTraceableManifest', function () {
      return this.get('nonTraceableManifest') && !this.get('model.hasMjLots');
    }),
    editableMetaWashington: Ember.computed('supportsCCRS', 'pendingRemoteManifest', 'model.accepted', 'remoteManifest', function () {
      return this.get('supportsCCRS') && (this.get('pendingRemoteManifest') || this.get('remoteManifest.pending') || this.get('model.accepted'));
    }),
    canCreateManualMjManifest: Ember.computed('currentLocation', function () {
      var featureManager = this.get('featureManager');
      return featureManager.can('create-traceable-mj-manifest') || featureManager.can('create-untraceable-mj-manifest');
    }),
    canEditVendorLicenseNumber: Ember.computed.or('isNewManualMjManifest', 'isManualParaManifest', 'editableMetaWashington'),
    canEditTransferDate: Ember.computed.or('isNewManualMjManifest', 'isManualParaManifest', 'editableMetaWashington'),
    canEditShipmentId: Ember.computed.or('isNewManualMjManifest', 'isManualParaManifest', 'editableMetaWashington'),
    canEditVendor: Ember.computed.or('nonTraceableManifest', 'editableMetaWashington'),
    // The user should not be allowed to add to or remove lots from a traceable manifest (a manifest
    // imported from a traceability system). These manifests should remain 1:1 with what is in the
    // state traceability system.
    canAddLots: Ember.computed.readOnly('nonTraceableManifest'),
    summedPurchasePrice: Ember.computed('model.lots.@each.{purchase_price,quantityValue}', function () {
      return this.get('model.lots').reduce(function (sum, lot) {
        var purchasePrice = lot.get('purchase_price');
        var quantity = parseFloat(lot.get('quantityValue'));

        if ((0, _isNumber.default)(purchasePrice) && (0, _isNumber.default)(quantity)) {
          var cost = purchasePrice * quantity;

          if (!(0, _isNaN.default)(cost)) {
            sum += cost;
          }
        }

        return sum;
      }, 0);
    }),
    allUnit: Ember.computed('model.lots.@each.quantityUnit', function () {
      return this.get('model.lots').every(function (lot) {
        return lot.get('quantityUnit') === _constants.default.weightUnit.unit;
      });
    }),
    totalQuantity: Ember.computed('model.lots.@each.quantityValue', function () {
      return this.get('model.lots').reduce(function (sum, lot) {
        var quantity = parseInt(lot.get('quantityValue'));

        if ((0, _isNumber.default)(quantity)) {
          sum += quantity;
        }

        return sum;
      }, 0);
    }),
    totalTaxPaid: Ember.computed('model.lots.@each.{paid_excise_tax,quantityValue}', function () {
      return this.get('model.lots').reduce(function (sum, lot) {
        var tax = parseInt(lot.get('paid_excise_tax'));
        var quantity = parseInt(lot.get('quantityValue'));

        if ((0, _isNumber.default)(tax) && !(0, _isNaN.default)(tax)) {
          sum += tax * (quantity || 0);
        }

        return sum;
      }, 0);
    }),
    expectedQuantity: Ember.computed('model.lots.@each.expectedQuantityValue', function () {
      return this.get('model.lots').reduce(function (sum, lot) {
        var expectedQuantity = parseInt(lot.get('expectedQuantityValue'));

        if ((0, _isNumber.default)(expectedQuantity)) {
          sum += expectedQuantity;
        }

        return sum;
      }, 0);
    }),
    supportsCCRS: Ember.computed(function () {
      return this.get('currentLocation.isWashington') && this.get('launchDarkly').can('enable-ccrs');
    }),
    manifestSizeForAsyncCreation: Ember.computed(function () {
      return this.get('launchDarkly').can('manifest-inventory-size-for-async-creation', 70);
    }),
    traceable: Ember.computed(function () {
      return this.get('featureManager').can('traceability');
    }),
    traceableManifest: Ember.computed('traceable', 'model.remote_manifest_id', 'remoteManifest', function () {
      return this.get('traceable') && (Ember.isPresent(this.get('model.remote_manifest_id')) || Ember.isPresent(this.get('remoteManifest')));
    }),
    nonTraceableManifest: Ember.computed.not('traceableManifest'),
    pendingRemoteManifest: Ember.computed.and('remoteManifest', 'pendingManifest'),
    rejectedManifest: Ember.computed('remoteManifest', 'model', function () {
      return this.get('model') === this.get('remoteManifest');
    }),
    mjFilter: Ember.computed('currentLocation', 'traceable', 'traceableManifest', function () {
      var showAllProducts = !this.get('traceable') || this.get('nonTraceableManifest') && this.get('canCreateManualMjManifest');

      if (showAllProducts) {
        return null;
      }

      return this.get('traceableManifest') ? 'mj' : 'para';
    }),
    selectedLotIsUnit: Ember.computed('selectedLot', 'selectedLot.quantity.isUnit', function () {
      var selectedLot = this.get('selectedLot');

      if (!Ember.isPresent(selectedLot)) {
        return false;
      }

      return selectedLot.get('quantity.isUnit');
    }),
    unitBasedFilter: Ember.computed.alias('selectedLotIsUnit'),
    weightBasedFilter: Ember.computed('currentLocation', 'traceableManifest', 'selectedLotIsUnit', function () {
      var selectedLotIsUnit = this.get('selectedLotIsUnit');
      var featureManager = this.get('featureManager');
      var weightBasedInventory = featureManager.can('weight-based-inventory');

      if (!weightBasedInventory) {
        return false;
      }

      if (this.get('traceableManifest') || this.get('canCreateManualMjManifest')) {
        return !selectedLotIsUnit;
      }

      return false;
    }),
    unitOfMeasureOptions: Ember.computed('traceable', 'selectedLotIsUnit', function () {
      var volBased = this.get('featureManager').can('volume-based-inventory');

      if (!this.get('traceable')) {
        return volBased ? _constants.default.weightUnits : _constants.default.nonVolumeUnits;
      }

      if (this.get('selectedLotIsUnit')) {
        return _constants.default.onlyUnit;
      }

      return volBased ? _constants.default.nonUnit : _constants.default.onlyWeightUnits;
    }),
    showClearButton: Ember.computed('selectedLot', 'pendingManifest', 'selectedLot.product', function () {
      return this.get('pendingManifest') && Ember.isPresent(this.get('selectedLot')) && Ember.isPresent(this.get('selectedLot.product'));
    }),
    selectedLotProductType: Ember.computed('selectedLot', function () {
      var remoteLot = this.get('selectedLot.remote_lot');

      if (Ember.isEmpty(remoteLot)) {
        return;
      }

      return this.get('productTypes').find(function (productType) {
        return productType.get('traceability_type') === remoteLot.get('product_type');
      });
    }),
    applicableProductTypes: Ember.computed('productTypes', 'traceable', 'traceableManifest', function () {
      var _this = this;

      var featureManager = this.get('featureManager');
      var canCreateMjManifest = this.get('nonTraceableManifest') && featureManager.can('create-traceable-mj-manifest');
      return this.get('productTypes').filter(function (productType) {
        if (!_this.get('traceable') || canCreateMjManifest) {
          return true;
        }

        return productType.get('is_marijuana') === _this.get('traceableManifest');
      });
    }),
    processAcceptManifest: function processAcceptManifest() {
      var _this2 = this;

      if (!this.validateManifest()) {
        return;
      }

      var message = 'Are you sure you want to accept this manifest? This action cannot be undone.';

      _bootbox.default.confirm(message, (0, _debounce.default)(function (result) {
        if (result) {
          _this2.acceptManifest();
        }
      }));
    },
    acceptManifest: function acceptManifest() {
      var _this3 = this;

      if (this.canFullyAccept()) {
        this.chargeManifest();
      } else {
        this.showReasonModal(_constants.default.remoteManifestStatus.accepted, function (reason) {
          _this3.set('model.note', reason);

          _this3.chargeManifest();
        });
      }
    },
    nonTraceableLot: function nonTraceableLot(lot) {
      return lot.get('inventory_item.traceable') === false || lot.get('product.product_type.isTraceable') === false;
    },
    skipLotValidation: function skipLotValidation(lot) {
      return this.nonTraceableLot(lot) || this.get('launchDarkly').can('allow-barcode-sku-reuse-in-for-all-cannabis-products', false);
    },
    canFullyAccept: function canFullyAccept() {
      return this.get('model.lots').every(function (lot) {
        return Ember.isPresent(lot.get('quantity')) && lot.get('expected_quantity').eq(lot.get('quantity'));
      });
    },
    chargeManifest: function chargeManifest() {
      var _this4 = this;

      this.get('model.lots').forEach(function (lot) {
        var hasSku = lot.get('sku') || lot.get('remote_lot.sku');

        if (!hasSku && Ember.isEmpty(lot.get('product')) && Ember.isEmpty(lot.get('quantity'))) {
          lot.deleteRecord();
        }
      });

      if (!this.validateManifest()) {
        return;
      }

      this.set('isAccepting', true);
      this.get('model.lots').forEach(function (lot) {
        if (Ember.isEmpty(lot.get('sku')) && Ember.isPresent(lot.get('remote_lot'))) {
          lot.set('sku', lot.get('remote_lot.sku'));
        }

        if (lot.get('hasMjProduct')) {
          lot.get('inventory_item').set('traceable', true);
        }
      });

      if (this.get('pendingManifest')) {
        this.set('model.charged_on', new Date());
      }

      this.get('model').save().then(function () {
        if (_this4.get('model.id') === null && _this4.get('model.lots.length') >= _this4.get('manifestSizeForAsyncCreation')) {
          _bootbox.default.alert("This manifest has a lot of inventory.\n          Greenbits will process it in the background.\n          It might take couple of minutes.\n          The task page will indicate when the processing is complete.");
        } else {
          _this4.get('model.lots').filterBy('id', null).forEach(function (lot) {
            if (Ember.isPresent(lot)) {
              lot.send('becameInvalid');
              lot.unloadRecord();
            }
          });
        }

        _this4.stopSaving();

        Ember.run.next(_this4, function () {
          this.send('transitionWithoutAlert', 'manifests');
        });
      }).catch(function (e) {
        if (_this4.get('pendingManifest')) {
          _this4.set('model.charged_on', null);
        }

        _this4.handleError(e);
      });
    },
    manifestHasAllLotNumbers: function manifestHasAllLotNumbers() {
      var anyLotNumbersMissing = this.get('model.lot_adjustments').any(function (la) {
        return Ember.isEmpty(la.get('lot_number'));
      });

      if (anyLotNumbersMissing) {
        var message = "This manifest cannot be saved due to missing lot numbers.\n        If you still have this issue, chat Support.";

        _bootbox.default.alert(message);

        return false;
      }

      return true;
    },
    manifestHasChangesToCost: function manifestHasChangesToCost() {
      var anyCostUpdates = this.get('model.lot_adjustments').any(function (la) {
        var sales = la.get('has_sales') || la.get('has_divergent_inventory');
        var purchasePrice = !Ember.isEmpty(la.changedAttributes().purchase_price);
        return sales && purchasePrice;
      });
      return anyCostUpdates;
    },
    stopSaving: function stopSaving() {
      this.set('isAccepting', false);
      this.set('isRejecting', false);
    },
    showReasonModal: function showReasonModal(status, _callback) {
      var action;

      if (status === _constants.default.remoteManifestStatus.accepted) {
        action = 'partially accepting';
      } else {
        action = 'rejecting';
      }

      _bootbox.default.prompt({
        title: "Please provide a reason for ".concat(action, " this manifest."),
        maxlength: 255,
        callback: function callback(reason) {
          if (Ember.isPresent(reason) && reason.trim().length > 0) {
            _callback(reason);
          }
        }
      });
    },
    showValidationErrorsModal: function showValidationErrorsModal() {
      var _this5 = this;

      this.set('formattedErrors', []);
      this.get('model.errors.content').forEach(function (error) {
        var attribute = _this5.cleanAttribute(error.attribute);

        _this5.get('formattedErrors').push("".concat(attribute, " ").concat(error.message));
      });
      $('#validation-errors').modal();
    },
    cleanAttribute: function cleanAttribute(attribute) {
      var cleanAttribute = attribute;
      cleanAttribute = cleanAttribute.replace('base', '');
      cleanAttribute = cleanAttribute.replace('lot_adjustments', 'lot');
      cleanAttribute = cleanAttribute.replace('inventory_item.', '');
      cleanAttribute = cleanAttribute.replace(/[\._]/, ' ');
      cleanAttribute = cleanAttribute.charAt(0).toUpperCase() + cleanAttribute.slice(1);
      return cleanAttribute;
    },
    validateLotNumberExistence: function validateLotNumberExistence(lot) {
      var featureManager = this.get('featureManager');
      var requiresLotNumber = featureManager.can('traceability') || featureManager.can('create-traceable-mj-manifest');

      if (!lot.get('hasMjProduct')) {
        // EXPL: Paraphernalia inventory does not require a lot number,
        // so it's valid regardless of the presence of a lot number.
        return true;
      } else if (!requiresLotNumber) {
        // EXPL: This location does not require a lot number,
        // so it's valid regardless of the presence of a lot number.
        return true;
      } else {
        // EXPL: This inventory requires a lot number, does it have one?
        return lot.get('hasLotNumber');
      }
    },
    washingtonMjInventoryLotNumberValid: function washingtonMjInventoryLotNumberValid(lot) {
      if (!lot.get('hasMjProduct') || !this.get('currentLocation.isWashington')) {
        return true;
      }

      var lotNumber = lot.get('lot_number');

      if (this.get('supportsCCRS')) {
        return true;
      }

      return Ember.isPresent(lotNumber);
    },
    validateManifest: function validateManifest() {
      var _this6 = this;

      var model = this.get('model');
      var requiresUniqueSkus = !this.get('launchDarkly').can('allow-barcode-sku-reuse-in-for-all-cannabis-products', false);
      model.get('errors').clear();
      model.get('lots').forEach(function (lot) {
        var dupSkuError = false;
        var uniquenessError = 'has already been taken';

        if (requiresUniqueSkus && Ember.isPresent(lot.get('errors.sku'))) {
          dupSkuError = lot.get('errors.sku').mapBy('message').includes(uniquenessError);
        }

        lot.get('errors').clear();

        if (Ember.isPresent(lot.get('inventory_item'))) {
          lot.get('inventory_item.errors').clear();
        }

        if (dupSkuError) {
          lot.errors.add('sku', uniquenessError);
        }
      });
      ['vendor', 'shipment_id', 'transfer_date'].forEach(function (field) {
        if (Ember.isEmpty(model.get(field))) {
          model.errors.add(field, "can't be blank");
        }
      });
      var requireVendorLicenseNumber = this.get('isNewManualMjManifest') && this.get('featureManager').can('traceability') && Ember.isEmpty(this.get('model.vendor_license_number'));

      if (requireVendorLicenseNumber) {
        model.errors.add('vendor_license_number', "can't be blank");
      }

      model.get('lots').filter(function (lot) {
        return !lot.get('inventory_transferred_out');
      }).forEach(function (lot) {
        var lotErrors = lot.errors;
        lot.send('willCommit');

        if (Ember.isPresent(lot.get('sku'))) {
          if (requiresUniqueSkus && !_this6.skipLotValidation(lot)) {
            var otherLots = model.get('lots').filter(function (otherLot) {
              return otherLot !== lot;
            });
            var otherSkus = otherLots.mapBy('sku');

            if (otherSkus.includes(lot.get('sku'))) {
              lotErrors.add('sku', 'must be unique');
            }
          } else {
            var sku = lot.get('sku');
            var productId = lot.get('product.id');

            if (Ember.isEmpty(productId)) {
              return;
            }

            var otherLotsWithProducts = model.get('lots').filter(function (otherLot) {
              return otherLot !== lot && Ember.isPresent(otherLot.get('product.id'));
            });
            var productsBySkus = otherLotsWithProducts.reduce(function (mapped, current) {
              var _current$get;

              mapped[_current$get = current.get('sku')] || (mapped[_current$get] = []);
              mapped[current.get('sku')].push(current.get('product.id'));
              return mapped;
            }, {});

            if (productsBySkus[sku]) {
              var otherProductsWithSameSku = productsBySkus[sku].filter(function (product) {
                return product != productId;
              });

              if (otherProductsWithSameSku.length !== 0) {
                lot.get('errors').add('sku', 'has already been taken');
              }
            }
          }
        }

        if (Ember.isEmpty(lot.get('quantity'))) {
          lotErrors.add('quantity', "can't be blank");
        }

        if (_this6.get('nonTraceableManifest')) {
          if (Ember.isEmpty(lot.get('product'))) {
            lotErrors.add('product', 'product is required');
          } else {
            var productUnitBased = lot.get('product.unitBased');
            var lotUnitBased = lot.get('quantity.isUnit');

            if (productUnitBased && !lotUnitBased) {
              lotErrors.add('quantity', "can't enter quantity as weight for a unit-based product");
            } else if (!productUnitBased && lotUnitBased) {
              lotErrors.add('quantity', "can't enter quantity as units for a weight-based product");
            }

            if (!_this6.validateLotNumberExistence(lot) && !_this6.get('supportsCCRS')) {
              lotErrors.add('lot_number', 'required for marijuana product');
            }

            if (!_this6.washingtonMjInventoryLotNumberValid(lot)) {
              lotErrors.add('lot_number', 'must be present');
            }
          }

          if (Ember.isEmpty(lot.get('sku'))) {
            lotErrors.add('sku', "can't be blank");
          }
        } else {
          var lotExpectedQuantity = lot.get('expected_quantity');
          var quantity = lot.get('quantity');

          if (Ember.isPresent(lotExpectedQuantity) && Ember.isPresent(quantity) && quantity.gt(lotExpectedQuantity)) {
            lotErrors.add('quantity', "can't be more than ".concat(lotExpectedQuantity));
          }
        }

        if (_this6.get('supportsCCRS') && !_this6.nonTraceableLot(lot) && Ember.isEmpty(lot.get('inventory_item.external_inventory_id'))) {
          lot.get('inventory_item').send('willCommit');
          lot.get('inventory_item.errors').add('external_inventory_id', 'cannot be blank');
        }
      });
      var validManifest = model.get('errors.isEmpty');
      var validLots = model.get('lots').every(function (lot) {
        return lot.get('errors.isEmpty');
      });
      var validInventoryItems = model.get('lots').every(function (lot) {
        return Ember.isEmpty(lot.get('inventory_item')) || lot.get('inventory_item.errors.isEmpty');
      });
      var valid = validManifest && validLots && validInventoryItems;

      if (!valid) {
        _bootbox.default.alert('Please fix the red errors below before saving.');
      }

      return valid;
    },
    handleError: function handleError(error) {
      this.stopSaving();

      if (this.get('model.errors.isEmpty')) {
        (0, _errorReporter.errorReporter)(error);
        this.send('error', error);
      } else {
        this.showValidationErrorsModal();
      }
    },
    // For Washington manual MJ manifests, don't use SKU from product since
    // new inventory are prebarcoded with lot number. We'll set the SKU when the user
    // manually enters the lot number for a manual MJ manifest.
    defaultToLatestProductSku: Ember.computed('currentLocation', 'nonTraceableManifest', 'selectedLot.hasMjProduct', function () {
      var isWashington = this.get('currentLocation.isWashington');
      var nonTraceableManifest = this.get('nonTraceableManifest');
      var hasMjProduct = this.get('selectedLot.hasMjProduct');
      return !isWashington || !nonTraceableManifest || !hasMjProduct;
    }),
    confirmSaveUpdateCost: function confirmSaveUpdateCost() {
      var _this7 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var message = _this7.buildConfirmText();

        _bootbox.default.dialog({
          message: message,
          buttons: {
            cancel: {
              label: 'Cancel',
              className: 'btn-default'
            },
            success: {
              label: 'Update Cost',
              className: 'btn-success',
              callback: resolve
            }
          }
        });
      });
    },
    buildConfirmText: function buildConfirmText() {
      return "<b>Update Cost?</b><br>\n            This will update the cost of this SKU on:\n            <ul>\n              <li>Previous sales</li>\n              <li>All reports that include cost</li>\n            </ul>\n            These updates also apply to inventory split or converted from this SKU.";
    },
    setLotExciseTax: function setLotExciseTax(lot) {
      if (!this.get('launchDarkly').can('bw-AL-Tax')) {
        return;
      }

      var purchasePrice = lot.get('purchase_price');
      var markupRate = this.get('model.markup_rate');
      var exciseTaxRate = this.get('model.excise_tax');
      var salePrice = purchasePrice + purchasePrice * markupRate;
      var paidExciseTax = salePrice * exciseTaxRate;
      lot.set('paid_excise_tax', paidExciseTax);
    },
    setAllLotsExciseTax: function setAllLotsExciseTax() {
      Ember.run.throttle(this, this._setAllLotsExciseTax, 200, false);
    },
    _setAllLotsExciseTax: function _setAllLotsExciseTax() {
      var _this8 = this;

      this.get('model.lots').forEach(function (lot) {
        return _this8.setLotExciseTax(lot);
      });
    },
    setProduct: function setProduct(lot, product) {
      var inventoryItem = lot.get('inventory_item');

      if (Ember.isEmpty(inventoryItem)) {
        inventoryItem = lot.set('inventory_item', this.get('store').createRecord('inventory-item'));
      }

      inventoryItem.set('product', product);
    },
    actions: {
      acceptManifest: function acceptManifest() {
        this.processAcceptManifest();
      },
      rejectManifest: function rejectManifest() {
        var _this9 = this;

        this.showReasonModal(_constants.default.remoteManifestStatus.rejected, function (reason) {
          _this9.set('remoteManifest.reason', reason);

          _this9.set('remoteManifest.status', _constants.default.remoteManifestStatus.rejected);

          _this9.set('remoteManifest.charged_on', new Date());

          _this9.get('remoteManifest').save().then(function () {
            _this9.stopSaving();

            _this9.send('transitionWithoutAlert', 'manifests');
          }).catch(function (e) {
            _this9.handleError(e);
          });
        });
      },
      showProductSelector: function showProductSelector(lot) {
        this.set('selectedLot', lot);
        this.showProductSelector();
      },
      selectProduct: function selectProduct(product) {
        var _this10 = this;

        var isMedicalLot = this.get('selectedLot.medical');
        this.set('selectedLot.product', product);
        this.set('selectedLot.medical', isMedicalLot);
        this.get('selectedLot.errors').remove('product');
        var setSkuFromProduct = this.get('nonTraceableManifest') && this.get('defaultToLatestProductSku') && !this.get('selectedLot.sku');

        if (setSkuFromProduct) {
          this.set('selectedLot.sku', product.get('latest_sku'));
        }

        if (this.get('selectedLot.sku')) {
          var sku = this.get('selectedLot.sku');
          var productId = this.get('selectedLot.product.id');
          var inventoryItemId = this.get('selectedLot.inventory_item_id');
          this.skuProvider.find(sku).then(function (inventoryItems) {
            _this10.setProduct(_this10.get('selectedLot'), product);

            var matchingItems = _this10.skuProvider.filterBySku(inventoryItems, sku, productId, inventoryItemId);

            if (!Ember.isEmpty(matchingItems) && !_this10.skipLotValidation(_this10.get('selectedLot'))) {
              _this10.get('selectedLot.errors').add('sku', 'has already been taken');
            }
          });
        } else {
          this.setProduct(this.get('selectedLot'), product);
        }
      },
      saveManifest: function saveManifest() {
        if (this.get('traceableManifest') && !this.manifestHasAllLotNumbers()) {
          return;
        }

        if (this.manifestHasChangesToCost()) {
          this.confirmSaveUpdateCost().then(this.chargeManifest.bind(this));
        } else {
          this.chargeManifest();
        }
      },
      addLot: function addLot() {
        var lot = this.get('store').createRecord('lot_adjustment', {
          adjustment_type: _constants.default.lotAdjustmentType.newInventory,
          quantity: _weight.default.create({
            value: 0,
            unit: _constants.default.weightUnit.unit
          }),
          sample: false,
          sample_type: _constants.default.sampleType.none,
          medical: false,
          inventory_item: this.get('store').createRecord('inventory-item', {
            manifest_lot_status: 0
          })
        });
        this.get('model.lots').pushObject(lot);
      },
      removeLot: function removeLot(lot) {
        lot.send('becameInvalid');
        lot.unloadRecord();
      },
      createSkus: function createSkus() {
        this.get('model.lots').forEach(function (lot) {
          lot.set('sku', null);
        });
      },
      cancel: function cancel() {
        this.send('transitionWithoutAlert', 'manifests');
      },
      setVirtualAttribute: function setVirtualAttribute(key, value) {
        this.set(key, value);
      },
      setTransferDate: function setTransferDate(value) {
        this.get('model').set('transfer_date', value);
      },
      setMarkupRate: function setMarkupRate(value) {
        this.get('model').set('markup_rate', Number(value));
        this.setAllLotsExciseTax();
      },
      setExciseTaxRate: function setExciseTaxRate(value) {
        this.get('model').set('excise_tax', Number(value));
        this.setAllLotsExciseTax();
      },
      setLotPurchasePrice: function setLotPurchasePrice(lot, value) {
        lot.set('purchase_price', value);
        this.setLotExciseTax(lot);
      },
      setLotQuantityValue: function setLotQuantityValue(lot, value) {
        lot.set('quantity.value', value);
        this.setLotExciseTax(lot);
      }
    }
  });

  _exports.default = _default;
});