define("herer-web/templates/components/animation-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHZTanIt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"animation-request\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"dot\"],[8],[9],[7,\"span\",true],[10,\"class\",\"dot\"],[8],[9],[7,\"span\",true],[10,\"class\",\"dot\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/animation-request.hbs"
    }
  });

  _exports.default = _default;
});