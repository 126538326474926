define("herer-web/serializers/inventory-audit", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      inventory_audit_results: {
        embedded: 'always'
      },
      manifest: {
        deserialize: 'records',
        serialize: 'ids'
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      var record = snapshot.record;

      if (!Ember.isEmpty(record.get('audit_results'))) {
        json.audit_results = record.get('audit_results');
      }

      return json;
    }
  });

  _exports.default = _default;
});