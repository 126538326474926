define("herer-web/components/weight-input", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROUND_PRECISION = 4;
  var ROUNDING_FACTOR = Math.pow(10, ROUND_PRECISION);

  var _default = Ember.Component.extend({
    classNames: ['weight-input'],
    classNameBindings: ['showUnitDropdown:input-group', 'isValid::has-error'],
    onUpdate: null,
    convertOnUnitChange: false,
    shortLabel: false,
    allowPartialUnits: false,
    appendUnit: false,
    isValid: true,
    _weight: null,
    weight: Ember.computed('_weight', {
      get: function get() {
        return this.get('_weight') || _weight.default.create({
          value: 0,
          unit: _constants.default.weightUnit.unit
        });
      },
      set: function set(key, value) {
        this.set('_weight', value);
        return value;
      }
    }),
    disabled: false,
    placeholder: null,
    weightValue: Ember.computed('weight.value', {
      get: function get() {
        var weight = this.get('weight.value');
        return weight ? Math.round(weight * ROUNDING_FACTOR) / ROUNDING_FACTOR : 0;
      },
      set: function set(key, value) {
        if (Ember.isPresent(value) && this.get('showUnitDropdown')) {
          this.ensureWeightExists();
        }

        if (Ember.isPresent(this.get('weight'))) {
          this.set('weight', this.get('weight').copy());
          this.set('weight.value', value);
          this.emitUpdate();
        }

        return value;
      }
    }),
    _weightUnits: null,
    weightUnits: Ember.computed({
      get: function get() {
        return this.get('_weightUnits') || _constants.default.weightUnits;
      },
      set: function set(key, value) {
        this.set('_weightUnits', value);
        return value;
      }
    }),
    validWeightUnits: Ember.computed('weightUnits', function () {
      if (Ember.isEmpty(this.get('_weightUnits'))) {
        return this.get('weightUnits').slice(0, 2);
      } else {
        return this.get('weightUnits');
      }
    }),
    showUnitDropdown: Ember.computed('validWeightUnits', 'disabled', function () {
      return !this.get('disabled') && !(this.get('validWeightUnits').length === 1 && this.get('validWeightUnits.firstObject').type === _constants.default.weightUnit.unit);
    }),
    ensureWeightExists: function ensureWeightExists() {
      if (Ember.isPresent(this.get('weight'))) {
        return;
      }

      this.set('weight', _weight.default.create({
        value: 0,
        unit: _constants.default.weightUnit.gram
      }));
    },
    limitToWholeNumbers: Ember.computed('allowPartialUnits', 'weight.isUnit', function () {
      return this.get('weight.isUnit') && !this.get('allowPartialUnits');
    }),
    changeUnitWithoutConversion: function changeUnitWithoutConversion(unit) {
      this.set('weight', this.get('weight').copy());
      this.set('weight.unit', unit);

      if (this.get('limitToWholeNumbers')) {
        this.set('weight.value', parseInt(this.get('weight.value')));
      }
    },
    changeUnitAndConvert: function changeUnitAndConvert(unit) {
      var converted = this.get('weight').to(unit);
      this.set('weight', converted);
    },
    emitUpdate: function emitUpdate() {
      this.onUpdate && this.onUpdate(this.get('weight'));
    },
    actions: {
      setWeightUnit: function setWeightUnit(unit) {
        this.ensureWeightExists();

        if (this.get('convertOnUnitChange')) {
          this.changeUnitAndConvert(unit);
        } else {
          this.changeUnitWithoutConversion(unit);
        }

        this.emitUpdate();
      },
      setWeightValue: function setWeightValue(value) {
        this.set('weightValue', value);
      }
    }
  });

  _exports.default = _default;
});