define("herer-web/pivot-table/group", ["exports", "herer-web/pivot-table/cell", "herer-web/pivot-table/row", "herer-web/pivot-table/total-row"], function (_exports, _cell, _row, _totalRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    header: null,
    content: null,
    columns: null,
    units: null,
    sortBy: null,
    sortAscending: true,
    averageTotal: false,
    sortedRows: Ember.computed('content', 'sortBy', 'sortAscending', function () {
      var sortBy = this.get('sortBy');
      var content = this.get('content');
      var columns = this.get('columns');

      if (Ember.isEmpty(sortBy) || Ember.isEmpty(columns.find(function (col) {
        return col === sortBy;
      }))) {
        return content;
      } else {
        var sortAscending = this.get('sortAscending');
        var direction = sortAscending ? 1 : -1;
        return content.sort(function (rowA, rowB) {
          var cellA = rowA.get('cells')[rowA.indexOfCellForColumn(sortBy)];
          var cellB = rowB.get('cells')[rowB.indexOfCellForColumn(sortBy)];

          if (cellA.get('value').gt(cellB.get('value'))) {
            return 1 * direction;
          } else if (cellA.get('value').lt(cellB.get('value'))) {
            return -1 * direction;
          } else {
            return 0;
          }
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('content', Ember.A());
    },
    add: function add(row) {
      return this.get('content').push(row);
    },
    empty: Ember.computed.empty('content'),
    rowFor: function rowFor(cell) {
      var _this = this;

      var row = this.get('content').findBy('header', cell.get('row'));

      if (Ember.isEmpty(row)) {
        row = _row.default.create({
          header: cell.get('row')
        });
        this.get('columns').forEach(function (column, index) {
          row.add(_cell.default.create({
            column: column,
            row: cell.row,
            unit: _this.get('units')[index]
          }));
        });
        this.add(row);
      }

      return row;
    },
    totalRow: Ember.computed('averageTotal', 'content.[]', function () {
      var totals = _totalRow.default.create({
        header: 'Total',
        averageTotal: this.get('averageTotal')
      });

      totals.addRows(this.get('content'));
      return totals;
    })
  });

  _exports.default = _default;
});