define("herer-web/models/driver", ["exports", "@ember-data/model", "ember-data-model-fragments", "moment", "herer-web/utils/validation", "yup"], function (_exports, _model, _emberDataModelFragments, _moment, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: (0, _model.attr)('string'),
    employee_id: (0, _model.attr)('string'),
    dob_month: (0, _model.attr)('string', {
      defaultValue: null
    }),
    dob_day: (0, _model.attr)('string', {
      defaultValue: null
    }),
    dob_year: (0, _model.attr)('string', {
      defaultValue: null
    }),
    dob: Ember.computed('dob_month', 'dob_day', 'dob_year', function () {
      var dobMonth = this.get('dob_month');
      var dobDay = this.get('dob_day');
      var dobYear = this.get('dob_year');

      if (dobMonth && dobDay && dobYear) {
        return (0, _moment.default)("".concat(dobMonth, "/").concat(dobDay, "/").concat(dobYear), 'M/D/YYYY').toDate();
      } else {
        return null;
      }
    }),
    validations: (0, _validation.validations)({
      employee_id: (0, _yup.string)().required(),
      name: (0, _yup.string)().required(),
      dob_month: (0, _yup.number)().required(),
      dob_day: (0, _yup.number)().required(),
      dob_year: (0, _yup.number)().required()
    })
  });

  _exports.default = _default;
});