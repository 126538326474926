define("herer-web/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'checked', 'disabled'],
    type: 'radio',
    disabled: false,
    checked: Ember.computed('value', 'name', function () {
      return this.get('value') === this.get('name');
    }),
    click: function click() {
      this.set('name', this.get('value'));
    }
  });

  _exports.default = _default;
});