define("herer-web/routes/manifest", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route", "herer-web/mixins/label-preference"], function (_exports, _authenticatedFullPage, _alertUnsavedRoute, _labelPreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mixins = [_alertUnsavedRoute.default, _labelPreference.default];

  var _default = _authenticatedFullPage.default.extend.apply(_authenticatedFullPage.default, mixins.concat([{
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        taxItems: store.findAll('tax_item'),
        manifest: store.findRecord('manifest', params.id),
        productTypes: store.findAll('product_type'),
        tags: store.findAll('tag'),
        tieredPricingModels: store.findAll('tiered-pricing-model'),
        labelPreference: this.findLabelPreference(store)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.manifest);

      controller.set('taxItems', model.taxItems);
      controller.set('productTypes', model.productTypes);
      controller.set('tags', model.tags);
      controller.set('tieredPricingModels', model.tieredPricingModels);
      controller.set('pendingManifest', false);
      controller.set('labelPreference', model.labelPreference);
      controller.set('markupRate', 0);
      controller.set('exciseTaxRate', 0);
    },
    deactivate: function deactivate() {
      var manifest = this.modelFor('manifest').manifest;
      manifest.get('lot_adjustments').forEach(function (lot) {
        if (Ember.isPresent(lot)) {
          lot.rollbackAttributes();
        }
      });
      manifest.rollbackAttributes();
    }
  }]));

  _exports.default = _default;
});