define("herer-web/templates/components/manifest/lot-row/lot-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/4hkFQG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"supportsCCRS\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"form-group \",[28,\"if\",[[24,[\"lot\",\"inventory_item\",\"errors\",\"external_inventory_id\"]],\"has-error\"],null]]]],[10,\"data-test-group\",\"lot_number\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"External Inventory Identifier\"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"maxlength\",\"class\",\"data-test-input\"],[[24,[\"lot\",\"inventory_item\",\"external_inventory_id\"]],100,\"form-control\",\"external_inventory_id\"]]],false],[1,[28,\"error-messages\",null,[[\"errors\"],[[24,[\"lot\",\"inventory_item\",\"errors\",\"external_inventory_id\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"location-can-use\",[\"create-traceable-mj-manifest\"],null],[24,[\"canEditLotNumber\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"form-group \",[28,\"if\",[[24,[\"lot\",\"errors\",\"lot_number\"]],\"has-error\"],null]]]],[10,\"data-test-group\",\"lot_number\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"\\n      \"],[1,[22,\"lotNumberLabel\"],false],[0,\"\\n    \"],[9],[1,[28,\"no-whitespace-input\",null,[[\"value\",\"placeholder\",\"maxlength\",\"class\",\"data-test-input\"],[[24,[\"lot\",\"lot_number\"]],\"\",30,\"form-control\",\"lot_number\"]]],false],[1,[28,\"error-messages\",null,[[\"errors\"],[[24,[\"lot\",\"errors\",\"lot_number\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"lotNumberLabel\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"data-test-value\",\"lot_number\"],[8],[0,\"\\n    \"],[1,[24,[\"lot\",\"lotNumberFormatted\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/manifest/lot-row/lot-number.hbs"
    }
  });

  _exports.default = _default;
});