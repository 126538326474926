define("herer-web/routes/tasks", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/models/paged-array"], function (_exports, _authenticatedFullPage, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    model: function model() {
      var pagedArray = _pagedArray.TimePagedArray.create({
        store: this.get('store'),
        model: 'task',
        loadRelationships: function loadRelationships(tasks) {
          tasks.filter(function (task) {
            return task.get('location_id');
          }).map(function (task) {
            var locationRef = task.belongsTo('location');

            if (!locationRef.value()) {
              return locationRef.store.findRecord('location', task.location_id).catch(function (error) {
                if (!error.status === 404) {
                  throw error;
                }

                return error;
              });
            }
          });
        }
      });

      return pagedArray.fetchObjects();
    }
  });

  _exports.default = _default;
});