define("herer-web/components/gb-percent-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AWD4g9S8",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"input\",false],[12,\"class\",\"gb-number-input\"],[12,\"value\",[23,0,[\"formattedValue\"]]],[13,1],[12,\"type\",\"number\"],[3,\"on\",[\"input\",[28,\"fn\",[[23,0,[\"setValue\"]]],null]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/gb-percent-input/template.hbs"
    }
  });

  _exports.default = _default;
});