define("herer-web/mixins/context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.computed.alias('authManager.currentUser'),
    currentRole: Ember.computed.alias('authManager.currentRole'),
    currentLocation: Ember.computed.alias('authManager.currentLocation'),
    sortedLocations: Ember.computed.alias('authManager.sortedLocations'),
    currentAccount: Ember.computed.alias('authManager.currentAccount')
  });

  _exports.default = _default;
});