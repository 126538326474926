define("herer-web/templates/lots/lot/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5qbjgi07",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"packages/package/package-history\",null,[[\"package\",\"model\",\"users\",\"onExit\"],[[24,[\"package\"]],[24,[\"model\"]],[24,[\"users\"]],[28,\"route-action\",[\"exit\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/lots/lot/history.hbs"
    }
  });

  _exports.default = _default;
});