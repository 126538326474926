define("herer-web/templates/components/hourly-performance-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6QEuXqoJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel panel-default weekly-performance-panel\"],[10,\"data-test-container\",\"hourly_performance_panel\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[10,\"data-test-text\",\"hourly_performance_header\"],[8],[0,\"\\n        Hourly Performance\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body padded\"],[10,\"data-test-container\",\"hourly_performance_body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canShowChart\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"hourly-performance-chart\",null,[[\"data\"],[[24,[\"data\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[0,\"\\n            No data to show.\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/hourly-performance-report.hbs"
    }
  });

  _exports.default = _default;
});