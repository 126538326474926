define("herer-web/templates/qs-reports/expiring-inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SYfwtk0J",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Expiring Inventory\",\"Shows you the total value of your inventory for the date you select.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886994842003-Inventory-on-Hand-Analyze-your-inventory-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"expiring_inventory_report_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ExpiringInventoryReport\",\"expiring_inventory_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/expiring-inventory.hbs"
    }
  });

  _exports.default = _default;
});