define("herer-web/components/base-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: null,
    modalClassName: '.modal.fade',
    onCancel: null,
    onConfirm: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.$(this.get('modalClassName'));
      this.set('modal', modal);
      modal.on('hide.bs.modal', function () {
        _this.onCancel && _this.onCancel();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('modal').off('hidden.bs.modal');
      this.hideModalOnDestroy();
    },
    hideModalOnDestroy: function hideModalOnDestroy() {
      this.hideModal();
    },
    hideModal: function hideModal() {
      this.get('modal').modal('hide');
    }
  });

  _exports.default = _default;
});