define("herer-web/templates/components/full-page-container-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FL1FnHhl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"style\",true],[10,\"type\",\"text/css\"],[8],[0,\"\\n  /*\\n   To make QuickSight Reports full-page width this is the \\n   most straightforward way, as in Bootstrap the overall app width \\n   is set on the \\\"container\\\" element, which is well above the \\n   scope of these templates.\\n\\n   We include this only on QuickSight reports.\\n  */\\n  .container {\\n    width: 100%;\\n  }\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/full-page-container-style.hbs"
    }
  });

  _exports.default = _default;
});