define("herer-web/templates/components/system-status-banners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbuoHQVj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"system-status\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can-use\",[\"show_device_version_warning\"],null]],null,{\"statements\":[[0,\"    \"],[1,[22,\"device-check\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"system-status\",null,[[\"model\"],[[24,[\"systemStatus\"]]]]],false],[0,\"\\n  \"],[1,[22,\"app-version-reloader\"],false],[0,\"\\n\"],[4,\"if\",[[28,\"can-use\",[\"state-credential-failure-notification\"],null]],null,{\"statements\":[[0,\"    \"],[1,[22,\"app-credential-notification\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[22,\"location-status\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/system-status-banners.hbs"
    }
  });

  _exports.default = _default;
});