define("herer-web/models/menu-item", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    brand: (0, _model.attr)('string'),
    strain: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    prices: _emberDataModelFragments.default.fragmentArray('menu-item-price'),
    menuGroup: (0, _model.belongsTo)('menu-group', {
      async: false
    }),
    errors: (0, _model.attr)(),
    hasErrors: Ember.computed('errors', function () {
      return Ember.isPresent(Object.keys(this.get('errors')));
    }),
    displayableErrors: Ember.computed('errors', function () {
      var errors = this.get('errors');
      return Object.keys(errors).map(function (key) {
        return errors[key];
      });
    }),
    priceForWeight: function priceForWeight(weight) {
      var roundedWeight = weight.round(1);
      var flooredWeight = weight.floor();
      var prices = this.get('prices');
      var relevantPrices;

      if (weight.get('isUnit')) {
        relevantPrices = prices.filterBy('type', 'ea');
      } else {
        relevantPrices = prices.filter(function (price) {
          return price.get('type') !== 'ea';
        });
      }

      var foundPrice = relevantPrices.find(function (price) {
        var priceWeight = price.toWeight();
        return priceWeight.eq(weight) || priceWeight.eq(roundedWeight) || priceWeight.eq(flooredWeight);
      });

      if (Ember.isPresent(foundPrice)) {
        return foundPrice.get('price');
      }
    }
  });

  _exports.default = _default;
});