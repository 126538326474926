define("herer-web/models/remote-manifest", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    shipping_license_number: (0, _model.attr)('string'),
    shipping_trade_name: (0, _model.attr)('string'),
    transfer_date: (0, _model.attr)('date'),
    shipment_id: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    charged_on: (0, _model.attr)('date'),
    reason: (0, _model.attr)('string'),
    manifest_id: (0, _model.attr)('string'),
    remote_lots: (0, _model.hasMany)('remote_lot', {
      async: false
    }),
    pending: Ember.computed('status', function () {
      return this.statusEquals('pending');
    }),
    accepted: Ember.computed('status', function () {
      return this.statusEquals('accepted');
    }),
    rejected: Ember.computed('status', function () {
      return this.statusEquals('rejected');
    }),
    voided: Ember.computed('status', function () {
      return this.statusEquals('voided');
    }),
    vendor: Ember.computed.alias('shipping_trade_name'),
    note: Ember.computed.alias('reason'),
    lots: Ember.computed.alias('remote_lots'),
    statusEquals: function statusEquals(statusName) {
      return this.get('status') === _constants.default.remoteManifestStatus[statusName];
    }
  });

  _exports.default = _default;
});