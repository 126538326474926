define("herer-web/components/account-reports/daily-customers/location-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    styles: Ember.computed('location.{color,textColor}', function () {
      return Ember.String.htmlSafe('background-color: ' + this.get('location.color') + '; color: ' + this.get('location.textColor'));
    })
  });

  _exports.default = _default;
});