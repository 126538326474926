define("herer-web/mixins/label-preference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findLabelPreference: function findLabelPreference(store) {
      return store.findAll('label-preference').then(function (labelPreferences) {
        return labelPreferences.get('firstObject');
      });
    }
  });

  _exports.default = _default;
});