define("herer-web/routes/packages/package/reconcile", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    templateName: 'packages/package/reconcile',
    resetScroll: false,
    renderTemplate: function renderTemplate() {
      this._super();

      return this.render({
        into: 'side-nav-layout',
        outlet: 'modalContent'
      });
    },
    model: function model() {
      return this.modelFor('packages/package');
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('packages');
      },
      finish: function finish() {
        this.transitionTo('packages');
        this.send('showFlashMessage', Ember.String.htmlSafe("<h3>Reconciliation success</h3>\n         <p>Details for Package ".concat(this.model().get('lotNumber'), " now should match between Greenbits and Metrc.</p>")));
      },
      dismissReconciliation: function dismissReconciliation() {
        this.transitionTo('tasks');
        this.send('showFlashMessage', Ember.String.htmlSafe("<h3>Reconciliation in progress</h3>\n        <p>We\u2019re working on your request. Check back here to view the results.</p>"));
      }
    }
  });

  _exports.default = _default;
});