define("herer-web/utils/humanize", ["exports", "lodash/capitalize", "lodash/trim", "lodash/snakeCase"], function (_exports, _capitalize, _trim, _snakeCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanize = humanize;

  function humanize(str) {
    return (0, _capitalize.default)((0, _trim.default)((0, _snakeCase.default)(str).replace(/_id$/, '').replace(/_/g, ' ')));
  }
});