define("herer-web/templates/components/print-count-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fl3m2Tq2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"printing-text\",null,[[\"isPrinting\"],[[24,[\"isPrinting\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"isBlindCount\"]],\"fa-eye-slash\",\"fa-eye\"],null]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isBlindCount\"]]],null,{\"statements\":[[0,\"    Print Blind Count\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Print Count with Quantities\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/print-count-button.hbs"
    }
  });

  _exports.default = _default;
});