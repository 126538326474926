define("herer-web/controllers/return-manifest-new", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants", "bootbox", "lodash/range", "moment"], function (_exports, _alertUnsavedController, _context, _constants, _bootbox, _range, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    addLotDisabled: false,
    addLotError: '',
    lotNumber: '',
    errors: Ember.computed.alias('model.errors'),
    years: Ember.computed(function () {
      return (0, _range.default)(1915, (0, _moment.default)().year() + 1).reverse();
    }),
    carYears: Ember.computed(function () {
      return [(0, _moment.default)().year() + 1].concat(this.get('years'));
    }),
    dobMonths: _constants.default.months,
    dobYears: Ember.computed(function () {
      return this.get('years').slice(20);
    }),
    dobDays: Ember.computed(function () {
      return (0, _range.default)(1, 32);
    }),
    minDepartureDate: Ember.computed(function () {
      return (0, _moment.default)();
    }),
    splitPackageHCLink: Ember.computed('currentLocation.isWashington', function () {
      var article = this.get('currentLocation.isWashington') ? '12887464916883-Washington-Create-a-manifest-for-a-vendor-return-or-internal-transfer' : '12887486145939-Washington-How-to-split-a-Lot';
      return "https://support.dutchie.com/hc/en-us/articles/".concat(article);
    }),
    supportNumber: _constants.default.supportNumber,
    returnManifestTypes: _constants.default.returnManifestTypes,
    actions: {
      addLot: function addLot() {
        this.set('addLotError', '');
        this.set('addLotDisabled', true);
        this.findValidPackage();
      },
      removeLot: function removeLot(lot) {
        var foundLot = this.get('model.lots').find(function (l) {
          return l.get('lot_number') === lot.get('lot_number');
        });

        if (foundLot) {
          this.get('model.lots').removeFragment(foundLot);
        }
      },
      save: function save() {
        var _this = this;

        this.validate().then(function () {
          _this.transformReturnLotQuantities();

          return _this.get('model').save();
        }).then(function () {
          _this.showSuccessModal();
        }).catch(function () {
          if (_this.get('errors.isEmpty')) {
            _this.showErrorModal();
          } else {
            _this.showInvalidModal();
          }
        });
      },
      setDepartureDate: function setDepartureDate(date) {
        this.model.set("departure_date", date);
      }
    },
    transformReturnLotQuantities: function transformReturnLotQuantities() {
      this.get('model.lots').forEach(function (lot) {
        if (Ember.isPresent(lot.get('quantity.value'))) {
          lot.set('quantity', lot.get('quantity.value'));
        }
      });
    },
    findValidPackage: function findValidPackage() {
      var _this2 = this;

      var params = {
        by_lot_number: this.get('lotNumber')
      };
      this.get('store').query('package', params).then(function (packages) {
        var valid = true;
        var foundItem = packages.find(function (aPackage) {
          return aPackage.get('lotNumber') === _this2.get('lotNumber');
        });

        if (!foundItem) {
          _this2.set('addLotError', 'Could not find a lot with this lot number.');

          _this2.set('addLotDisabled', false);

          return;
        }

        var duplicate = _this2.get('model.lots').find(function (lot) {
          return lot.get('lot_number') === foundItem.get('lotNumber');
        });

        if (valid && duplicate) {
          _this2.set('addLotError', 'This lot has already been added.');

          valid = false;
        }

        if (valid && foundItem.get('quantity') <= 0) {
          _this2.set('addLotError', 'Lot quantity must be greater than zero.');

          valid = false;
        }

        if (!valid) {
          _this2.set('addLotDisabled', false);

          return;
        }

        _this2.findExistingReturnManifests(foundItem);
      });
    },
    findExistingReturnManifests: function findExistingReturnManifests(aPackage) {
      var _this3 = this;

      var params = {
        by_lot_number: aPackage.get('lotNumber'),
        transferred: false
      };
      this.get('store').query('return_manifest', params).then(function (returnManifests) {
        _this3.set('addLotDisabled', false);

        if (returnManifests.get('length') > 0) {
          _this3.set('addLotError', 'This lot is already on another return manifest.');

          return;
        }

        _this3.addLot(aPackage);
      });
    },
    addLot: function addLot(aPackage) {
      var lot_fragment = this.get('store').createFragment('return_lot', {
        lot_number: aPackage.get('lotNumber'),
        quantity: aPackage.get('quantity')
      });
      this.get('model.lots').insertAt(0, lot_fragment);
      this.set('lotNumber', '');
      Ember.run.next(function () {
        $('.add-lot-input').focus();
      });
    },
    showSuccessModal: function showSuccessModal() {
      var _this4 = this;

      var email = this.get('currentLocation.primary_email');
      var message = 'Your return manifest has been submitted for processing. You will receive an';
      message += ' email';

      if (email) {
        message += " at ".concat(email);
      }

      message += ' when it has finished processing.';

      _bootbox.default.dialog({
        message: Ember.Handlebars.Utils.escapeExpression(message),
        buttons: {
          ok: {
            label: 'OK',
            className: 'btn-default',
            callback: function callback() {
              _this4.transitionToRoute('return-manifests');
            }
          }
        }
      });
    },
    manifestValidations: Ember.computed.alias('model.validations'),
    // return_manifest.employee is driver fragment
    driverValidations: Ember.computed.alias('model.employee.validations'),
    vehicleValidations: Ember.computed.alias('model.vehicle.validations'),
    validators: Ember.computed('model.pickup', function () {
      if (this.get('model.pickup')) {
        return [this.get('manifestValidations'), this.get('driverValidations'), this.get('vehicleValidations')];
      }

      return [this.get('manifestValidations')];
    }),
    validate: function validate() {
      var _this5 = this;

      if (!this.get('model.lots.length')) {
        _bootbox.default.alert('You must add at least one lot.');

        return Promise.reject();
      }

      return Promise.allSettled(this.get('validators').map(function (v) {
        return v.validate();
      })).then(function () {
        return new Promise(function (resolve, reject) {
          return _this5.get('validators').every(function (v) {
            return v.isValid;
          }) ? resolve() : reject();
        });
      });
    },
    validatePresence: function validatePresence(key, value) {
      if (!value || !value.toString().trim().length) {
        this.get('errors').add(key, 'can\'t be blank');
      }
    },
    showErrorModal: function showErrorModal() {
      var message = 'Sorry, something went wrong when trying to save this return manifest. ';
      message += 'If you still have this issue, chat Support.';

      _bootbox.default.alert(message);
    },
    showInvalidModal: function showInvalidModal() {
      _bootbox.default.alert('Please fix the red errors below before saving.');
    }
  });

  _exports.default = _default;
});