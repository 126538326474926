define("herer-web/routes/register-settings", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    beforeModel: function beforeModel() {
      this.transitionTo('store-options');
    }
  });

  _exports.default = _default;
});