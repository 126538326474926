define("herer-web/controllers/receipt", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _alertUnsavedController, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    launchDarkly: Ember.inject.service(),
    toast: Ember.inject.service(),
    allowShowLoyaltyPoints: Ember.computed(function () {
      return Ember.get(this, 'launchDarkly').can('eng-32292-allow-show-loyalty-points-on-receipt', false);
    }),
    allowShowQrCode: Ember.computed('launchDarkly.isReady', function () {
      return Ember.get(this, 'launchDarkly').can('eng-43978-allow-show-qr-code-on-receipt', false);
    }),
    validFileLength: function validFileLength(name) {
      var file = this.get("model.".concat(name));

      if (Ember.isEmpty(file)) {
        return true;
      }

      return file.length <= _constants.default.maxFileSize;
    },
    actions: {
      saveReceiptPreference: function saveReceiptPreference() {
        var _this = this;

        var model = this.get('model');
        var errors = model.get('errors');
        errors.clear();
        ['logo', 'qr_code'].forEach(function (name) {
          if (!_this.validFileLength(name)) {
            errors.add(name, 'This file is too large.');
          }
        });

        if (Ember.isPresent(errors)) {
          return;
        }

        model.save().then(function () {
          _this.get('toast').success('Receipt preferences saved!');
        }).catch(function () {});
      },
      removeAttachment: function removeAttachment(name) {
        this.set("model.".concat(name, "_url"), null);
      }
    }
  });

  _exports.default = _default;
});