define("herer-web/routes/state-link/auto", ["exports", "herer-web/routes/authenticated-side-nav"], function (_exports, _authenticatedSideNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    sideNavTemplateName: 'inventory-nav',
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        if (_this.get('authManager.currentLocation.user_managed_compliance')) {
          _this.replaceWith('state-link.manual');
        }
      });
    },
    model: function model() {
      return this.store.findAll('traceability-notification');
    }
  });

  _exports.default = _default;
});