define("herer-web/routes/reorder-report", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        productTypes: store.findAll('product-type'),
        tags: store.findAll('tag'),
        tieredPricingModels: store.findAll('tiered-pricing-model')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, []);

      controller.set('productTypes', model.productTypes.sortBy('name'));
      controller.set('allTags', model.tags);
      controller.set('tieredPricingModels', model.tieredPricingModels);
    }
  });

  _exports.default = _default;
});