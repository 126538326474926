define("herer-web/components/person-row", ["exports", "herer-web/mixins/context", "herer-web/utils/people"], function (_exports, _context, _people) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    featureManager: Ember.inject.service(),
    person: null,
    disabledCheckInReason: Ember.computed('currentLocation.queue', 'personTypeMatchesLocation', 'person.isNew', function () {
      return (0, _people.disabledCheckInReason)(this.get('currentLocation'), this.get('person'), this.get('featureManager'));
    }),
    canCheckIn: Ember.computed('currentLocation.queue', 'personTypeMatchesLocation', 'person.isNew', function () {
      return (0, _people.canCheckIn)(this.get('currentLocation'), this.get('person'), this.get('featureManager'));
    }),
    personTypeMatchesLocation: Ember.computed('currentLocation.{medical,recreational}', 'person', function () {
      return (0, _people.personTypeMatchesLocation)(this.get('currentLocation'), this.get('person'), this.get('featureManager'));
    })
  });

  _exports.default = _default;
});