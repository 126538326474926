define("herer-web/components/manage-subscription", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authToken: Ember.computed.alias('authManager.currentUser.token'),
    formAction: "".concat(_constants.default.apiHost, "/saml/auth")
  });

  _exports.default = _default;
});