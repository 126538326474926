define("herer-web/components/time-since", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    time: null,
    timeSince: null,
    timer: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.updateTime();
      var timer = setInterval(function () {
        _this.updateTime();
      }, 10000);
      this.set('timer', timer);
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('timer'));
    },
    updateTime: function updateTime() {
      var now = (0, _moment.default)();
      var time = (0, _moment.default)(this.get('time'));
      this.set('timeSince', time.from(now, true));
    }
  });

  _exports.default = _default;
});