define("herer-web/components/image-cropper", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _imageCropper.default.extend({
    //override default options of cropper
    aspectRatio: null,
    minContainerWidth: '868',
    minContainerHeight: '500',
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    croppedImage: null,
    didInsertElement: function didInsertElement() {
      var container = this.$(this.get('cropperContainer'));
      this.set('cropper', container);
    }
  });

  _exports.default = _default;
});