define("herer-web/controllers/packages", ["exports", "herer-web/mixins/context", "herer-web/mixins/export", "herer-web/constants", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/quantity-sync", "bootbox"], function (_exports, _context, _export, _constants, _lotNumberFormatter, _quantitySync, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller;

  var mixins = [_context.default, _export.default, _lotNumberFormatter.default, _quantitySync.default];

  var _default = (_Ember$Controller = Ember.Controller).extend.apply(_Ember$Controller, mixins.concat([{
    featureManager: Ember.inject.service(),
    isPackageMergerOpen: false,
    selectedPackages: [],
    packagesToMerge: [],
    unitTypeToMerge: null,
    validatePackages: function validatePackages() {
      var selectedPackages = this.get('selectedPackages').slice(0);
      var nonActivePackages = selectedPackages.reject(function (aPackage) {
        return aPackage.get('status') === _constants.default.packageStatus.active;
      });
      var zeroQuantityPackages = selectedPackages.filter(function (aPackage) {
        return parseFloat(aPackage.get('quantity.value').toFixed(2).toString()) === 0;
      });
      var packagesUnits = selectedPackages.map(function (aPackage) {
        return aPackage.get('quantity.unit');
      });
      var rejectedLotNumbers;
      var errorMessage = "Cannot merge the following ".concat(this.get('lotOrPackage'), "s because");

      if (nonActivePackages.length !== 0) {
        rejectedLotNumbers = nonActivePackages.map(function (aPackage) {
          return "".concat(aPackage.get('lotNumber'), " (").concat(aPackage.get('statusName'), ")");
        });
        errorMessage += " they are not active:<br/><br/>".concat(rejectedLotNumbers.join('<br/>'));
      } else if (zeroQuantityPackages.length !== 0) {
        rejectedLotNumbers = zeroQuantityPackages.map(function (aPackage) {
          return aPackage.get('lotNumber');
        });
        errorMessage += " they have zero quantity:<br/><br/>".concat(rejectedLotNumbers.join('<br/>'));
      } else if (packagesUnits.uniq().length !== 1) {
        rejectedLotNumbers = selectedPackages.map(function (aPackage) {
          return "".concat(aPackage.get('lotNumber'), "\n          (").concat(_constants.default.weightUnitShortLabels[aPackage.get('quantity.unit')], ")");
        });
        errorMessage += " they are all not weight-based or unit-based:<br/><br/>\n        ".concat(rejectedLotNumbers.join('<br/>'));
      }

      if (!rejectedLotNumbers) {
        this.set('unitTypeToMerge', selectedPackages[0].get('quantity.unitName'));
        return Ember.RSVP.Promise.resolve(selectedPackages);
      }

      return Ember.RSVP.Promise.reject({
        lotNumbers: rejectedLotNumbers,
        errorMessage: errorMessage
      });
    },
    actions: {
      reload: function reload() {
        this.reload();
      },
      selectedPackagesChange: function selectedPackagesChange(selectedPackages) {
        this.set('selectedPackages', selectedPackages);
      },
      mergeSelectedPackages: function mergeSelectedPackages() {
        var _this = this;

        this.validatePackages().then(function (packages) {
          _this.set('packagesToMerge', packages);

          _this.set('isPackageMergerOpen', true);
        }).catch(function (errorData) {
          _bootbox.default.alert(errorData.errorMessage);
        });
      },
      hideMerger: function hideMerger() {
        this.set('packagesToMerge', []);
        this.set('isPackageMergerOpen', false);
      },
      mergeComplete: function mergeComplete() {
        this.set('packagesToMerge', []);
        this.set('isPackageMergerOpen', false);
        this.reload();
      },
      exportProductList: function exportProductList() {
        this.exportProductList();
      },
      exportPackageList: function exportPackageList() {
        this.exportPackageList();
      },
      exportInventoryItemList: function exportInventoryItemList() {
        this.exportInventoryItemList();
      },
      handleExportLlxList: function handleExportLlxList() {
        this.exportLlxList();
      },
      openDrawer: function openDrawer(aPackage) {
        this.transitionToRoute('packages.package.history', aPackage.get('id'));
      }
    },
    reload: function reload() {
      this.get('model').fetchObjects();
    }
  }]));

  _exports.default = _default;
});