define("herer-web/templates/components/page-header-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQqmisgM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"col-md-\",[28,\"if\",[[24,[\"width\"]],[24,[\"width\"]],\"5\"],null],\" controls\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"btn-group pull-right\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/page-header-actions.hbs"
    }
  });

  _exports.default = _default;
});