define("herer-web/templates/components/package-closed-before-reporting-sale-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAG1Ziiv",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[7,\"strong\",true],[8],[0,\"Sales\"],[9],[0,\" failed to report because package is closed\"],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://support.dutchie.com/hc/en-us/articles/12886836308371-How-to-Fix-Sales-that-Failed-to-Report-on-Closed-Packages\"],[10,\"target\",\"_blank\"],[10,\"data-test-link\",\"help\"],[8],[0,\"Learn how to fix it…\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row header-row table-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"Package ID\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-2 hidden-sm hidden-xs\"],[8],[0,\"Unreported Count\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-2 hidden-sm hidden-xs\"],[8],[0,\"Unreported Quantity\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-2 hidden-sm hidden-xs\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notification\",\"items\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"package-closed-before-reporting-sale-notification-row\",null,[[\"notification\",\"data-test-item\"],[[23,1,[]],\"notification\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/package-closed-before-reporting-sale-notifications.hbs"
    }
  });

  _exports.default = _default;
});