define("herer-web/templates/components/format-deleted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2/G8v6Gu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"isDeleted\"]],\"deleted-label\"],null]],[8],[0,\"\\n  \"],[1,[22,\"name\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/format-deleted.hbs"
    }
  });

  _exports.default = _default;
});