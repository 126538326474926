define("herer-web/models/state-credential", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _model, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var authenticationField = function authenticationField(field) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        then = _ref.then,
        otherwise = _ref.otherwise;

    return (0, _yup.string)().when('authentication_fields', {
      is: function is(authenticationFields) {
        return Boolean(authenticationFields && authenticationFields.includes(field));
      },
      then: then,
      otherwise: otherwise
    }).nullable();
  };

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      authentication_fields: (0, _yup.array)().of((0, _yup.string)()).nullable(),
      isLeafData: (0, _yup.bool)().nullable(),
      ccrs_automatic_upload: (0, _yup.bool)().nullable(),
      license_number: (0, _yup.string)().nullable().when('isLeafData', {
        is: true,
        then: (0, _yup.string)().nullable().required('MME code is required'),
        otherwise: (0, _yup.string)().nullable().required('License number is required')
      }),
      email: authenticationField('email', {
        then: (0, _yup.string)().when('ccrs_automatic_upload', {
          is: true,
          then: (0, _yup.string)().email().required(),
          otherwise: (0, _yup.string)()
        }),
        otherwise: (0, _yup.string)()
      }),
      api_key: (0, _yup.string)().nullable().when('isLeafData', {
        is: false,
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)().nullable()
      }),
      business_identifier: authenticationField('business_identifier', {
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)()
      }),
      password: authenticationField('password', {
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)()
      }),
      username: authenticationField('username', {
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)()
      })
    }),
    type: (0, _model.attr)('number'),
    email: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    business_identifier: (0, _model.attr)('string'),
    license_number: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    api_key: (0, _model.attr)('string'),
    active_at: (0, _model.attr)('date'),
    expires_at: (0, _model.attr)('date'),
    state: (0, _model.attr)('number'),
    ccrs_automatic_upload: (0, _model.attr)('boolean'),
    authentication_fields: (0, _model.attr)('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    humanReadableType: Ember.computed('type', function () {
      var _this = this;

      var stateCredentialType = _constants.default.stateCredentialTypeHumanReadable.find(function (v) {
        return v.value === _this.get('type');
      });

      if (stateCredentialType.value === 2) {
        stateCredentialType.name = "CCRS";
      }

      return stateCredentialType && stateCredentialType.name;
    }),
    isBiotrack: Ember.computed('type', function () {
      return this.get('type') === _constants.default.stateCredentialType.biotrack;
    }),
    isMetrc: Ember.computed('type', function () {
      return this.get('type') === _constants.default.stateCredentialType.metrc;
    }),
    isLeafData: Ember.computed('type', function () {
      return this.get('type') === _constants.default.stateCredentialType.leafData;
    }),
    isValid: Ember.computed('state', function () {
      return this.get('state') === _constants.default.stateCredentialState.valid;
    }),
    isInvalid: Ember.computed('state', function () {
      return this.get('state') === _constants.default.stateCredentialState.invalid;
    }),
    getTraceabilityProviderName: function getTraceabilityProviderName(location) {
      if (location.get('isWashington') && this.get('isBiotrack')) {
        return 'WSLCB Contingency Plan';
      } else {
        return this.get('humanReadableType');
      }
    },
    getTraceabilityProviderURL: function getTraceabilityProviderURL(location) {
      var _GB$stateCredentialTy = _constants.default.stateCredentialType,
          biotrack = _GB$stateCredentialTy.biotrack,
          metrc = _GB$stateCredentialTy.metrc,
          leafData = _GB$stateCredentialTy.leafData;

      switch (this.get('type')) {
        case biotrack:
          return this.biotrackURL(location);

        case metrc:
          return this.metrcURL(location);

        case leafData:
          return null;
      }

      return null;
    },
    biotrackURL: function biotrackURL(location) {
      if (location.get('isWashington')) {
        return 'https://lcb.wa.gov/mjtrace/traceabilitycontingencyplan';
      }

      return null;
    },
    metrcURL: function metrcURL(location) {
      return "https://".concat(location.get('stateAbbreviation'), ".metrc.com");
    },
    leafDataURL: function leafDataURL(location) {
      return "https://traceability.lcb.".concat(location.get('stateAbbreviation'), ".gov");
    },
    clearSensitiveAttributes: function clearSensitiveAttributes() {
      this.set('password', null);
      this.set('api_key', null);
    }
  });

  _exports.default = _default;
});