define("herer-web/helpers/is-readonly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return params[0] ? 'readonly' : null;
    }
  });

  _exports.default = _default;
});