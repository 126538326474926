define("herer-web/controllers/store-options", ["exports", "herer-web/constants", "herer-web/mixins/context", "herer-web/mixins/confirm-dialog", "herer-web/services/feature-manager", "bootbox"], function (_exports, _constants, _context, _confirmDialog, _featureManager, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_confirmDialog.default, _context.default, {
    cashManagementChoices: [{
      title: 'One Till Per Cashier',
      message: 'Choose this option if each cashier has responsibility for reconciling their own ' + 'till. Cashiers should pull their till when they move to a new register or go on ' + 'break. This approach provides strictest cash management.',
      value: 'one-till'
    }, {
      title: 'Shared Tills + Cashier Tracking',
      message: 'Choose this option if multiple budtenders can use the same register and share ' + 'the drawer, but you want cashiers to login so register transactions can be ' + 'attributed to specific employees. Store Managers would be responsible for ' + 'reconciling cash.',
      value: 'shared-tills-cashier'
    }, {
      title: 'Shared Tills - No Cashier Tracking',
      message: 'Choose this option if any cashier can process transactions on any cash drawer ' + 'without logging in. Multiple cashiers can use the same register and share the ' + 'till, and Store Managers would be responsible for reconciling cash.',
      value: 'shared-tills-no-cashier'
    }],
    defaultPricingTierStrategy: _constants.default.combinePricingTiersStrategy.sameTierOnly,
    combinePricingTiersChoices: [{
      title: 'Standard Pricing',
      message: Ember.String.htmlSafe("<span class=\"light-yellow\">All products of the same tier</span> will combine weights to\n        offer tiered pricing discounts."),
      value: _constants.default.combinePricingTiersStrategy.sameTierOnly
    }, {
      title: 'Identical-Product Tiered Pricing',
      message: Ember.String.htmlSafe("<span class=\"light-yellow\">Identical products of the same tier</span> will combine weights\n        to offer tiered pricing discounts."),
      value: _constants.default.combinePricingTiersStrategy.sameProductOnly
    }, {
      title: 'Combined Pricing',
      message: Ember.String.htmlSafe("<span class=\"light-yellow\">Products across all tiers</span> will combine weights to offer\n        tiered pricing discounts."),
      value: _constants.default.combinePricingTiersStrategy.all
    }],
    verifyAgeChoice: null,
    enableMedicalTransactions: null,
    skipPatientsLimitLookup: null,
    sharedTillsCashier: Ember.computed.equal('model.currentStrategy', 'shared-tills-cashier'),
    sharedTillsNoCashier: Ember.computed.equal('model.currentStrategy', 'shared-tills-no-cashier'),
    disableTierPricingDiscounts: Ember.computed.equal('model.combine_pricing_tiers_strategy', _constants.default.combinePricingTiersStrategy.disabled),
    showSkipTaxPeriodValidation: Ember.computed.equal('model.license.active_traceability_system', 'biotrack'),
    selected: Ember.computed('disableTierPricingDiscounts', 'model.combine_pricing_tiers_strategy', function () {
      return this.findValidSelection(this.get('model.combine_pricing_tiers_strategy'));
    }),
    disableChangesToAutoCloseInventory: Ember.computed('currentLocation.places_enabled', function () {
      // TODO: CPC - 1072 should remove this
      return !this.get('permissionsManager').verifyForced('update_company_settings') || this.get('currentLocation.places_enabled');
    }),
    showSaveFailedMessage: function showSaveFailedMessage(settingName) {
      var topic = "Sorry, there was a problem changing your ".concat(settingName, " setting. ");
      var supportMessage = "If you still have this issue, chat Support.";

      _bootbox.default.alert(Ember.Handlebars.Utils.escapeExpression(topic + supportMessage));
    },
    updateCashManagementSettings: function updateCashManagementSettings(sharedTills, employeeTracking, sessionTimeout) {
      var model = this.get('model');
      model.set('shared_tills', sharedTills);
      model.set('employee_tracking', employeeTracking);
      model.set('session_timeout', sessionTimeout);
    },
    buildMedicalTransactionsMessage: function buildMedicalTransactionsMessage(enable) {
      return "Are you sure you want to ".concat(enable ? 'enable' : 'disable', " medical transactions?");
    },
    buildSkipPatientsLimitLookupMessage: function buildSkipPatientsLimitLookupMessage(enable) {
      var message = '';

      if (enable) {
        message += "You are temporarily turning off the feature that looks up patients and their\n        sales limits at the Register. You will need to verify patient sales limits manually in\n        Metrc while it is off. It will automatically be restored at midnight. ";
      }

      message += "Are you sure you want to ".concat(enable ? 'disable' : 'enable', " patients limits look up?");
      return message;
    },
    updateCombinePricingTiers: function updateCombinePricingTiers(combinePricingTiersStrategy) {
      var _this = this;

      var model = this.get('model');

      if (model.get('combine_pricing_tiers_strategy') === combinePricingTiersStrategy) {
        return;
      }

      var message = 'Are you sure you want to change your combine pricing tiers setting?';
      return this.confirmChange(message, function () {
        model.set('combine_pricing_tiers_strategy', combinePricingTiersStrategy);
        model.save().catch(function () {
          _this.showSaveFailedMessage('combine pricing tiers');

          model.rollbackAttributes();
        });
      }, function () {});
    },
    findValidSelection: function findValidSelection(currentChoice) {
      if (this.get('disableTierPricingDiscounts')) {
        return _constants.default.combinePricingTiersStrategy.disabled;
      }

      if (Ember.isEmpty(currentChoice)) {
        return _constants.default.combinePricingTiersStrategy.sameProductOnly;
      }

      return currentChoice;
    },
    actions: {
      updateCashManagementStrategy: function updateCashManagementStrategy(strategy) {
        var _this2 = this;

        var model = this.get('model');

        if (model.get('currentStrategy') === strategy) {
          return;
        }

        return this.confirmChange('Are you sure you want to change your cash management?', function () {
          switch (strategy) {
            case 'one-till':
              _this2.updateCashManagementSettings(false, true, _constants.default.sessionTimeout.never);

              break;

            case 'shared-tills-cashier':
              _this2.updateCashManagementSettings(true, true, _constants.default.sessionTimeout.everySale);

              break;

            case 'shared-tills-no-cashier':
              _this2.updateCashManagementSettings(true, false, _constants.default.sessionTimeout.never);

              model.set('auto_logout', false);
              break;
          }

          model.save().catch(function () {
            _this2.showSaveFailedMessage('cash management');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateQueue: function updateQueue(queue) {
        var _this3 = this;

        var model = this.get('model');

        if (model.get('queue') === queue) {
          return;
        }

        return this.confirmChange('Are you sure you want to change your customer queue setting?', function () {
          model.set('queue', queue);
          model.save().catch(function () {
            _this3.showSaveFailedMessage('customer queue');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateRequireCustomer: function updateRequireCustomer(requireCustomer) {
        var _this4 = this;

        var model = this.get('model');

        if (model.get('require_customer') === requireCustomer) {
          return;
        }

        var message = 'Are you sure you want to change your require customer information setting?';
        return this.confirmChange(message, function () {
          model.set('require_customer', requireCustomer);
          model.save().catch(function () {
            _this4.showSaveFailedMessage('require customer information');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateVerifyAge: function updateVerifyAge(verifyAge) {
        var _this5 = this;

        var model = this.get('model');

        if (model.get('verify_age') === verifyAge) {
          return;
        }

        return this.confirmChange('Are you sure you want to change your age verification setting?', function () {
          model.set('verify_age', verifyAge);
          model.save().catch(function () {
            _this5.showSaveFailedMessage('age verification');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateAutoCloseInventory: function updateAutoCloseInventory(autoCloseInventory) {
        var _this6 = this;

        var model = this.get('model');

        if (model.get('auto_close_inventory') === autoCloseInventory) {
          return;
        }

        return this.confirmChange('Are you sure you want to change your auto close lots setting?', function () {
          model.set('auto_close_inventory', autoCloseInventory);
          model.save().catch(function () {
            _this6.showSaveFailedMessage('auto close lots');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateAutoPrintReceipts: function updateAutoPrintReceipts(autoPrintReceipts) {
        var _this7 = this;

        var model = this.get('model');

        if (model.get('auto_print_receipts') === autoPrintReceipts) {
          return;
        }

        var message = 'Are you sure you want to change your auto print receipts setting?';
        return this.confirmChange(message, function () {
          model.set('auto_print_receipts', autoPrintReceipts);
          model.save().catch(function () {
            _this7.showSaveFailedMessage('auto print receipts');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateBelowCostWarning: function updateBelowCostWarning(belowCostWarning) {
        var _this8 = this;

        var model = this.get('model');

        if (model.get('below_cost_warning') === belowCostWarning) {
          return;
        }

        var message = 'Are you sure you want to change your below cost warning setting?';
        return this.confirmChange(message, function () {
          model.set('below_cost_warning', belowCostWarning);
          model.save().catch(function () {
            _this8.showSaveFailedMessage('below cost warning');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateRequireReferralSource: function updateRequireReferralSource(requireReferralSource) {
        var _this9 = this;

        var model = this.get('model');

        if (model.get('require_referral_source') === requireReferralSource) {
          return;
        }

        var message = 'Are you sure you want to change your referral source requirement setting?';
        return this.confirmChange(message, function () {
          model.set('require_referral_source', requireReferralSource);
          model.save().catch(function () {
            _this9.showSaveFailedMessage('require referral source');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateWeighStationEnabled: function updateWeighStationEnabled(weighStationEnabled) {
        var _this10 = this;

        var model = this.get('model');

        if (model.get('weigh_station_enabled') === weighStationEnabled) {
          return;
        }

        var message = 'Are you sure you want to change your weigh station enabled setting?';
        return this.confirmChange(message, function () {
          model.set('weigh_station_enabled', weighStationEnabled);
          model.save().catch(function () {
            _this10.showSaveFailedMessage('weigh station enabled');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateCombinePricingTiers: function updateCombinePricingTiers(combinePricingTiersStrategy) {
        this.updateCombinePricingTiers(combinePricingTiersStrategy);
      },
      updateEnableMedicalTransactions: function updateEnableMedicalTransactions(enableMedicalTransactions) {
        var _this11 = this;

        var model = this.get('model');

        if (model.get('medical') === enableMedicalTransactions) {
          return;
        }

        return this.confirmChange(this.buildMedicalTransactionsMessage(enableMedicalTransactions), function () {
          model.set('medical', enableMedicalTransactions);
          model.save().catch(function () {
            _this11.showSaveFailedMessage('enable medical transactions');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateSkipTaxPeriodValidationChoice: function updateSkipTaxPeriodValidationChoice(skipTaxPeriodValidation) {
        var _this12 = this;

        var model = this.get('model');

        if (model.get('skip_tax_period_validation') === skipTaxPeriodValidation) {
          return;
        }

        var message = 'Are you sure you want to change your skip tax period validation setting?';
        return this.confirmChange(message, function () {
          model.set('skip_tax_period_validation', skipTaxPeriodValidation);
          model.save().catch(function () {
            _this12.showSaveFailedMessage('skip tax period validation');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateAutoLogout: function updateAutoLogout(autoLogout) {
        var _this13 = this;

        var model = this.get('model');

        if (model.get('auto_logout') === autoLogout) {
          return;
        }

        var message = 'Are you sure you want to change your sign out after every sale setting?';
        return this.confirmChange(message, function () {
          model.set('auto_logout', autoLogout);
          model.save().catch(function () {
            _this13.showSaveFailedMessage('auto sign out');

            model.rollbackAttributes();
          });
        }, function () {});
      },
      updateSessionTimeout: function updateSessionTimeout(sessionTimeout) {
        var _this14 = this;

        var model = this.get('model');
        model.set('session_timeout', sessionTimeout);
        model.save().catch(function () {
          _this14.showSaveFailedMessage('inactivity timeout');

          model.rollbackAttributes();
        });
      },
      updateTiersEnabled: function updateTiersEnabled(checked) {
        var selected;

        if (checked) {
          selected = this.get('defaultPricingTierStrategy');
        } else {
          selected = _constants.default.combinePricingTiersStrategy.disabled;
        }

        return this.updateCombinePricingTiers(selected);
      },
      updateTestResultsDisplay: function updateTestResultsDisplay(testResultsDisplay) {
        var _this15 = this;

        var model = this.get('model');
        model.set('test_results_display', testResultsDisplay);
        model.save().catch(function () {
          _this15.showSaveFailedMessage('test results display');

          model.rollbackAttributes();
        });
      }
    }
  });

  _exports.default = _default;
});