define("herer-web/controllers/id-scan", ["exports", "herer-web/mixins/context", "herer-web/mixins/alert-unsaved-controller"], function (_exports, _context, _alertUnsavedController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {});

  _exports.default = _default;
});