define("herer-web/templates/components/order-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HZcFjdsd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-2\"],[10,\"data-test-value\",\"charged_on\"],[8],[0,\"\\n  \"],[1,[28,\"format-date\",[[24,[\"order\",\"charged_on\"]]],[[\"shortDateTime\"],[true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[10,\"data-test-value\",\"receipt_id\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"label label-default order-label \",[24,[\"order\",\"orderTypeClass\"]]]]],[8],[0,\"\\n    \"],[1,[24,[\"order\",\"receipt_id\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ellipsis\\n    \",[28,\"if\",[[24,[\"currentLocation\",\"employee_tracking\"]],\"col-md-2\",\"col-md-3\"],null]]]],[10,\"data-test-value\",\"device_name\"],[8],[0,\"\\n  \"],[1,[24,[\"order\",\"device\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ellipsis\\n    \",[28,\"if\",[[24,[\"currentLocation\",\"employee_tracking\"]],\"col-md-2\",\"col-md-3\"],null]]]],[10,\"data-test-value\",\"order_customer\"],[8],[0,\"\\n  \"],[1,[28,\"format-deleted\",null,[[\"model\"],[[24,[\"order\",\"person\"]]]]],false],[0,\"\\n\"],[9],[4,\"if\",[[24,[\"currentLocation\",\"employee_tracking\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-2 ellipsis\"],[10,\"data-test-value\",\"order_employee\"],[8],[0,\"\\n    \"],[1,[28,\"format-deleted\",null,[[\"model\"],[[24,[\"order\",\"employee\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[10,\"data-test-value\",\"order_total\"],[8],[0,\"\\n  \"],[1,[28,\"format-unit\",[[24,[\"order\",\"total\"]]],[[\"unit\"],[\"dollar\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/order-row.hbs"
    }
  });

  _exports.default = _default;
});