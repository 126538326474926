define("herer-web/models/tax-exemption", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    total: (0, _model.attr)('number'),
    tax_item: (0, _model.belongsTo)('tax_item', {
      async: true
    }),
    line_item: (0, _model.belongsTo)('line_item', {
      async: false
    })
  });

  _exports.default = _default;
});