define("herer-web/controllers/devices", ["exports", "ember-uuid/utils/uuid-generator", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _uuidGenerator, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    device: null,
    waiting: false,
    success: true,
    http: Ember.inject.service(),
    pushManager: Ember.inject.service(),
    devicesUsed: Ember.computed.alias('authManager.currentLocation.devices_used'),
    deviceLimit: Ember.computed.alias('authManager.currentLocation.device_limit'),
    sortedDevices: Ember.computed('model.[]', function () {
      return this.sortDevices(true).concat(this.sortDevices(false));
    }),
    sortDevices: function sortDevices(linked) {
      return this.get('model').filterBy('linked', linked).toArray().sort(function (a, b) {
        return Ember.compare(b.created_at, a.created_at);
      });
    },
    relinkDevice: function relinkDevice() {
      var _this = this;

      this.set('waiting', true);
      var device = this.get('device');
      var deviceId = this.get('device.id');
      this.get('http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/devices/").concat(deviceId, "/regenerate")).then(function () {
        device.reload();

        _this.waitForLink(device);
      });
    },
    waitForLink: function waitForLink(device) {
      var _this2 = this;

      this.setDevice(device);
      this.set('waiting', true);

      var linkCallback = function linkCallback(data) {
        if (data.id === _this2.get('device.id')) {
          _this2.get('device').reload();

          _this2.set('success', true);

          _this2.get('pushManager').unbind('link', linkCallback);
        }
      };

      this.set('linkCallback', linkCallback);
      this.get('pushManager').bind('link', linkCallback);
    },
    stopWaiting: function stopWaiting() {
      this.get('pushManager').unbind('link', this.get('linkCallback'));
    },
    setDevice: function setDevice(device) {
      this.set('waiting', false);
      this.set('success', false);
      this.set('device', device);
    },
    actions: {
      linkDevice: function linkDevice() {
        var newDevice = this.get('store').createRecord('device', {
          identifier: _uuidGenerator.default.v4(),
          id: _uuidGenerator.default.v4()
        });
        this.setDevice(newDevice);
      },
      cancelLinking: function cancelLinking() {
        this.stopWaiting();
        var device = this.get('device');

        if (Ember.isPresent(device)) {
          device.rollbackAttributes();
        }

        this.setDevice(null);
      },
      relinkDevice: function relinkDevice(device) {
        this.setDevice(device);
        this.relinkDevice();
      },
      saveDevice: function saveDevice() {
        var _this3 = this;

        this.get('device').save().then(function (device) {
          _this3.waitForLink(device);
        });
      }
    }
  });

  _exports.default = _default;
});