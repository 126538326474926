define("herer-web/routes/lots/lot/history", ["exports", "herer-web/routes/packages/package/history"], function (_exports, _history) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _history.default.extend({
    parentModel: function parentModel() {
      return this.modelFor('lots/lot');
    },
    actions: {
      exit: function exit() {
        this.transitionTo('lots');
      }
    }
  });

  _exports.default = _default;
});