define("herer-web/templates/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wa569i9x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container outlet-container\"],[10,\"data-test-container\",\"unauthorized\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 text-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"unauthorized\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-exclamation-circle\"],[10,\"data-test-ux\",\"exclamation_triangle\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h2\",true],[10,\"data-test-text\",\"unauthorized_message\"],[8],[0,\"\\n        You are not authorized to do that.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});