define("herer-web/controllers/reports/other", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    launchDarkly: Ember.inject.service(),
    profitReportEndpoint: Ember.computed('launchDarkly.isReady', function () {
      var newProfitReport = this.get('launchDarkly').can('profit-report-update', false);
      return newProfitReport ? 'reports/profit_report_by_sku' : 'reports/profit_report';
    })
  });

  _exports.default = _default;
});