define("herer-web/controllers/income-statement", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['startDate', 'endDate'],
    startDate: (0, _moment.default)().format('YYYY-MM-DD'),
    endDate: (0, _moment.default)().format('YYYY-MM-DD'),
    table: Ember.computed.alias('model.table'),
    actions: {
      changeDate: function changeDate(property, date) {
        this.set(property, date);
      }
    }
  });

  _exports.default = _default;
});