define("herer-web/components/product-selector", ["exports", "herer-web/constants", "herer-web/models/weight", "herer-web/mixins/inventory-weights"], function (_exports, _constants, _weight, _inventoryWeights) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inventoryWeights.default, {
    productTypes: [],
    tieredPricingModels: [],
    tags: [],
    taxItems: [],
    mjFilter: null,
    weightBasedFilter: null,
    unitBasedFilter: null,
    onSelectProduct: null,
    source: null,
    vendorName: null,
    brandName: null,
    strainName: null,
    productType: null,
    testResultsThc: null,
    testResultsCbd: null,
    weight: null,
    store: Ember.inject.service(),
    productStore: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    newProduct: null,
    findProduct: true,
    showNewProduct: Ember.computed.not('findProduct'),
    isSavingProduct: false,
    newBrandName: null,
    newVendorName: null,
    newStrainName: null,
    sourceDidChange: Ember.observer('source', function () {
      this.reset();
    }),
    createNewProduct: function createNewProduct() {
      this.set('newBrandName', this.get('brandName'));
      this.set('newVendorName', this.get('vendorName'));
      this.set('newStrainName', this.get('strainName'));
      var product = this.get('store').createRecord('product', {
        active: true,
        sell_price: 0,
        discountable: true,
        product_type: this.get('productType'),
        test_results_thc: this.get('testResultsThc'),
        test_results_cbd: this.get('testResultsCbd'),
        weight: this.get('weight')
      });

      if (!this.get('featureManager').can('weight-based-inventory')) {
        product.set('unit_of_measure', _constants.default.weightUnit.unit);

        if (this.get('productType.can_edit_weight') && Ember.isEmpty(this.get('weight'))) {
          product.set('weight', _weight.default.create({
            value: 0,
            unit: _constants.default.weightUnit.gram
          }));
        }
      }

      this.set('newProduct', product);
    },
    closeModal: function closeModal() {
      $('.products-modal').modal('hide');
      this.reset();
    },
    reset: function reset() {
      this.set('findProduct', true);

      if (this.get('newProduct.isNew')) {
        this.get('newProduct').rollbackAttributes();
      }

      this.set('newProduct', null);
    },
    // This method re-applies the modal-open class to the body after calling .hide() to prevent
    // the document from locking because Bootstrap 3 does not support multiple modals,
    // and we don't want to modify any vendor files to resolve this.
    bootstrapMultipleModalBodyClassFix: function bootstrapMultipleModalBodyClassFix() {
      $('.products-modal').on('hidden.bs.modal', function () {
        if ($('.modal:visible').length) {
          $('body').addClass('modal-open');
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.bootstrapMultipleModalBodyClassFix();
    },
    selectProduct: function selectProduct(product) {
      this.onSelectProduct(product);
      this.closeModal();
    },
    actions: {
      selectProduct: function selectProduct(product) {
        this.selectProduct(product);
      },
      saveProduct: function saveProduct() {
        var _this = this;

        this.set('isSavingProduct', true);
        var names = {
          brand: this.get('newBrandName'),
          strain: this.get('newStrainName'),
          vendor: this.get('newVendorName')
        };
        var newProduct = this.get('newProduct');
        this.get('productStore').saveAssociationsByName(newProduct, names).then(function () {
          return newProduct.save();
        }).then(function (newProduct) {
          _this.selectProduct(newProduct);
        }).catch(function () {}).finally(function () {
          _this.set('isSavingProduct', false);
        });
      },
      findProduct: function findProduct() {
        this.set('findProduct', true);
      },
      addProduct: function addProduct() {
        this.set('findProduct', false);

        if (Ember.isEmpty(this.get('newProduct'))) {
          this.createNewProduct();
        }
      },
      closeModal: function closeModal() {
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});