define("herer-web/models/product", ["exports", "@ember-data/model", "herer-web/mixins/taggable", "herer-web/mixins/test-results", "herer-web/constants", "ember-data-model-fragments", "herer-web/utils/validation", "yup", "herer-web/models/weight"], function (_exports, _model, _taggable, _testResults, _constants, _emberDataModelFragments, _validation, _yup, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TEST_RESULT_PRECISION_DIGITS = 4;

  var _default = _model.default.extend(_testResults.default, _taggable.default, {
    validations: (0, _validation.validations)({
      weight: (0, _yup.number)().nullable().min(0, "Weight must be greater than or equal to 0")
    }),
    name: (0, _model.attr)('string'),
    sku: (0, _model.attr)('string'),
    sell_price: (0, _model.attr)('number'),
    patient_sell_price: (0, _model.attr)('number'),
    patient_price_enabled: (0, _model.attr)('boolean', {
      default: false
    }),
    member_sell_price: (0, _model.attr)('number'),
    member_price_enabled: (0, _model.attr)('boolean', {
      default: false
    }),
    wholesale_value: (0, _model.attr)('number'),
    quantity: (0, _model.attr)('weight'),
    pricing_type: (0, _model.attr)('number'),
    status: (0, _model.attr)('number'),
    latest_sku: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    internal_notes: (0, _model.attr)('string'),
    flower_type: (0, _model.attr)('number'),
    concentrate_type: (0, _model.attr)('number'),
    unit_of_measure: (0, _model.attr)('number'),
    price_at_register: (0, _model.attr)('boolean', {
      default: false
    }),
    par: (0, _model.attr)('weight'),
    discountable: (0, _model.attr)('boolean', {
      default: true
    }),
    rawPhoto: (0, _model.attr)('string'),
    croppedPhoto: (0, _model.attr)('string'),
    image_id: (0, _model.attr)('string'),
    image_file_name: (0, _model.attr)('string'),
    image_url: (0, _model.attr)('string'),
    image_created_at: (0, _model.attr)('string'),
    weight: (0, _model.attr)('weight'),
    concentrateWeight: (0, _model.attr)('weight'),
    product_type: (0, _model.belongsTo)('product_type', {
      async: true
    }),
    brand: (0, _model.belongsTo)('brand', {
      async: true
    }),
    inventory_items: (0, _model.hasMany)('inventory-item', {
      async: false
    }),
    inventory_item_places: (0, _model.hasMany)('inventory_item_place', {
      async: false,
      embedded: 'always'
    }),
    quantities_by_place: _emberDataModelFragments.default.fragmentArray('place-quantity'),
    strain: (0, _model.belongsTo)('strain', {
      async: true
    }),
    vendor: (0, _model.belongsTo)('vendor', {
      async: true
    }),
    tiered_pricing_model: (0, _model.belongsTo)('tiered-pricing-model', {
      async: true
    }),
    tier_price: (0, _model.attr)('string'),
    launchDarkly: Ember.inject.service(),
    productType: Ember.computed.reads('product_type.content'),
    inventoryItemsWithQuantityInUnlockedPlaces: Ember.computed.filterBy("inventory_items", 'hasPositiveQuantityInUnlockedPlaces', true),
    nonZeroPlaceQuantities: Ember.computed.filterBy('quantities_by_place', 'hasNonZeroQuantity', true),
    onlyLockedPlaceQuantities: Ember.computed.filterBy('quantities_by_place', 'isLocked', true),
    nonZeroPlaceQuantitiesOrLocked: Ember.computed.union('nonZeroPlaceQuantities', 'onlyLockedPlaceQuantities'),
    inventoryItemPlacesWithNonZeroQuantityOrLocked: Ember.computed.union('inventoryItemPlacesWithNonZeroQuantity', 'onlyLockedInventoryItemPlaces'),
    mergedErrors: Ember.computed('errors.[]', 'validations.fieldErrors', function () {
      var modelErrors = this.errors ? this.errors.toArray() : [];
      var validationErrors = this.validations.fieldErrors || {};
      return (0, _validation.mergeErrors)(validationErrors, modelErrors);
    }),
    active: Ember.computed('status', function () {
      return this.get('status') === _constants.default.productStatus.forSale;
    }),
    unitBased: Ember.computed('unit_of_measure', function () {
      return this.get('unit_of_measure') === _constants.default.weightUnit.unit;
    }),
    unitString: Ember.computed('unit_of_measure', function () {
      return _constants.default.weightUnitAbbreviations[this.get('unit_of_measure')];
    }),
    retailValue: Ember.computed('sell_price', 'quantity', function () {
      var quantity = this.get('quantity.value');

      if (quantity < 0) {
        return 0;
      }

      return quantity * this.get('sell_price');
    }),
    testResultsConverted: function testResultsConverted(cannabinoid) {
      var results = this.get("test_results_".concat(cannabinoid));
      var newFormatEnabled = this.get('launchDarkly').can('ENG-17376-new_test_results_format');
      return newFormatEnabled ? results : this.convert(results);
    },

    /**
     * Converts testResult from mg to % for convertible productTypes, if it not applies, returns testResult
     * example (100 / 0.5) / 10 = 20, then it is shall be displayed 20%
     * @param {Number} testResult
     * @return {Number}
     */
    convert: function convert(testResult) {
      if (testResult && this.get('product_type.isConvertible')) {
        var unit = Ember.isPresent(this.get('weight.unit')) ? this.get('weight.unit') : _constants.default.weightUnit.gram;
        var weight = this.get('weight.value') > 0 ? this.get('weight') : _weight.default.create({
          unit: unit,
          value: 1
        });
        var weightInGrams = weight.toGram().get('value');
        var convertedTestResult = testResult / weightInGrams / 10;
        return Number(convertedTestResult.toFixed(TEST_RESULT_PRECISION_DIGITS));
      }

      return testResult;
    }
  });

  _exports.default = _default;
});