define("herer-web/models/menu-item-price", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/models/weight", "herer-web/constants"], function (_exports, _model, _emberDataModelFragments, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    type: (0, _model.attr)('string'),
    unit: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    toWeight: function toWeight() {
      var weightUnit = _constants.default.weightUnitAbbreviationAliases[this.get('type')];

      return _weight.default.create({
        value: this.get('unit'),
        unit: weightUnit,
        street: true
      });
    }
  });

  _exports.default = _default;
});