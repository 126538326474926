define("herer-web/components/table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['sortable:table-header'],
    prompt: null,
    sortDirection: null,
    onSortChange: null,
    hideSort: false,
    onFilterChange: null,
    hideFilter: true,
    filterOptions: null,
    tooltip: null,
    isAscending: Ember.computed.equal('sortDirection', 'asc'),
    sortable: Ember.computed('onSortChange', 'hideSort', function () {
      return Ember.isPresent(this.get('onSortChange')) && !this.get('hideSort');
    }),
    filterable: Ember.computed('onFilterChange', 'hideFilter', function () {
      return Ember.isPresent(this.get('onFilterChange')) && !this.get('hideFilter');
    }),
    click: function click() {
      if (!this.get('sortable')) {
        return;
      }

      var direction = this.get('isAscending') ? 'desc' : 'asc';
      this.onSortChange && this.onSortChange(direction);
    },
    useTooltip: Ember.computed('tooltip', function () {
      return !!this.get('tooltip');
    }),
    actions: {
      changeFilter: function changeFilter(value) {
        this.onFilterChange && this.onFilterChange(value);
      }
    }
  });

  _exports.default = _default;
});