define("herer-web/components/tag-input", ["exports", "bootstrap-tagsinput"], function (_exports, _bootstrapTagsinput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bloodhound: null,
    taggable: null,
    allTags: [],
    disabled: false,
    store: Ember.inject.service(),
    allTagNames: Ember.computed('allTags', 'allTags.length', function () {
      if (this.get('allTags')) {
        return this.get('allTags').mapBy('name');
      }

      return [];
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      var bloodhound = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.get('allTagNames')
      });
      this.set('bloodhound', bloodhound);
      var select = this.$('select');
      select.tagsinput({
        trimValue: true,
        tagClass: 'tag label label-default',
        typeaheadjs: {
          source: bloodhound
        }
      });
      select.on('itemRemoved', function (event) {
        if (!_this.get('taggable')) {
          return;
        }

        var tag = event.item;

        _this.onRemoveTag(tag);
      });
      select.on('itemAdded', function (event) {
        var tag = event.item;

        var taggable = _this.get('taggable');

        if (taggable) {
          _this.onAddTag(tag);
        }

        if (!_this.get('allTagNames').includes(tag)) {
          _this.get('store').createRecord('tag', {
            name: tag
          });

          _this.resetBloodhound(tag);
        }
      }); // This hack is needed because of a bug in tagsinput
      // https://github.com/timschlechter/bootstrap-tagsinput/issues/386

      this.$('input').focusout(function () {
        Ember.run.next(function () {
          _this.$('.tt-input').val('');
        });
      });
    },
    resetBloodhound: function resetBloodhound(tag) {
      var bloodhound = this.get('bloodhound');
      var tagNames = this.get('allTagNames').concat(tag);
      bloodhound.clear();
      bloodhound.local = tagNames;
      bloodhound.initialize(true);
    }
  });

  _exports.default = _default;
});