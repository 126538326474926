define("herer-web/controllers/customers", ["exports", "herer-web/mixins/titan-email", "herer-web/constants", "herer-web/mixins/context", "herer-web/mixins/pageable", "herer-web/mixins/queueable", "herer-web/mixins/scanable", "herer-web/mixins/slide-out-drawer", "herer-web/mixins/alert-unsaved-controller", "ember-uuid/utils/uuid-generator", "herer-web/utils/people", "lodash/clone"], function (_exports, _titanEmail, _constants, _context, _pageable, _queueable, _scanable, _slideOutDrawer, _alertUnsavedController, _uuidGenerator, _people, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _titanEmail.default, _alertUnsavedController.default, _pageable.default, _queueable.default, _scanable.default, _slideOutDrawer.default, {
    queryParams: ['type'],
    cardParsers: ['id'],
    exportDisabled: false,
    peopleFilters: undefined,
    personMergeComponent: null,
    loyaltyPointsComponent: null,
    searchText: '',
    selectedCustomer: null,
    showLoyaltyComponent: false,
    showMergeComponent: false,
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    toast: Ember.inject.service(),
    http: Ember.inject.service(),
    router: Ember.inject.service(),
    scannableControllerType: 'Customers',
    propagateCustomerFromCard: function propagateCustomerFromCard(idCard, customer) {
      if (idCard.type) {
        customer.set('type', idCard.type);
      }

      for (var key in idCard.parsedData) {
        var hasKey = customer.get(key);

        if (!hasKey) {
          var value = idCard.parsedData[key];

          if (value) {
            customer.set(key, value);
          }
        }
      }
    },
    filterCustomerFromCard: function filterCustomerFromCard(person) {
      var idNumber;

      if (person.driver_license_number) {
        idNumber = person.driver_license_number;
      } else if (person.id_number) {
        idNumber = person.id_number;
      }

      this.updateSearchText(idNumber);
    },
    typeFromQueryParam: Ember.computed('type', function () {
      var type = parseInt(this.get('type'));

      if (isNaN(type)) {
        type = null;
      }

      return type;
    }),
    enqueueSuccessMessage: 'Click here to view the queue.',
    enqueueSuccessSubMessage: 'Person was added to the queue.',
    disableCheckIn: Ember.computed.not('canCheckIn'),
    canCheckIn: Ember.computed('currentLocation.queue', 'personTypeMatchesLocation', 'selectedCustomer.isNew', function () {
      return (0, _people.canCheckIn)(this.get('currentLocation'), this.get('selectedCustomer'), this.get('featureManager'));
    }),
    disabledCheckInReason: Ember.computed('currentLocation.queue', 'personTypeMatchesLocation', 'selectedCustomer.{isNew,isUnderage,hasExpiredLicense,isPastExpirationDate}', function () {
      return (0, _people.disabledCheckInReason)(this.get('currentLocation'), this.get('selectedCustomer'), this.get('featureManager'));
    }),
    personTypeMatchesLocation: Ember.computed('currentLocation.{medical,recreational}', 'selectedCustomer', function () {
      return (0, _people.personTypeMatchesLocation)(this.get('currentLocation'), this.get('selectedCustomer'), this.get('featureManager'));
    }),
    // Remove this code once person type has been removed from the api
    availablePersonTypes: Ember.computed('currentLocation.{medical,recreational}', 'selectedCustomer', function () {
      return (0, _people.availablePersonTypes)(this.get('currentLocation'), this.get('selectedCustomer'), this.get('featureManager'));
    }),
    availableCustomerGroups: Ember.computed('currentLocation.{medical,recreational}', 'selectedCustomer', function () {
      return (0, _people.availableCustomerGroups)(this.get('currentLocation'), this.personGroups);
    }),
    availableFilters: Ember.computed('currentLocation', 'type', function () {
      var type = this.get('typeFromQueryParam'); // Remove this code once person type has been removed from the api

      var pgFlag = this.get('launchDarkly').can('bw-person-group');
      var customerGroupFilter = {
        label: 'Customer Group',
        type: 'select',
        subtype: 'uuid',
        query: 'by_person_group_uuid',
        options: this.get('availableCustomerGroups'),
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: true,
        defaultValue: type,
        prompt: 'All customer groups'
      };
      var personTypeFilter = {
        label: 'Person Type',
        type: 'select',
        subtype: 'uuid',
        query: 'by_type',
        options: this.get('availablePersonTypes'),
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: true,
        defaultValue: type,
        prompt: 'All people types'
      };
      var personSearchFilter = pgFlag ? customerGroupFilter : personTypeFilter;
      var filters = [personSearchFilter, {
        label: 'Email',
        type: 'text',
        query: 'by_email',
        defaultValue: ''
      }, {
        label: 'Phone',
        type: 'text',
        query: 'by_phone',
        defaultValue: ''
      }, {
        label: 'ID Number',
        type: 'text',
        query: 'by_id_number',
        defaultValue: ''
      }, {
        label: 'First Name',
        type: 'text',
        query: 'by_first_name',
        defaultValue: ''
      }, {
        label: 'Last Name',
        type: 'text',
        query: 'by_last_name',
        defaultValue: ''
      }, {
        label: 'Caregiver ID Number',
        type: 'text',
        query: 'by_caregiver_id_number',
        defaultValue: ''
      }, {
        label: 'Birth Date',
        type: 'date',
        query: 'by_birth_date',
        subquery: 'date'
      }, {
        label: 'Expiration Date',
        type: 'date',
        query: 'by_expiration_date',
        subquery: 'date'
      }, {
        label: 'Hire Date',
        type: 'date',
        query: 'by_hire_date',
        subquery: 'date'
      }, {
        label: 'Created At',
        type: 'date',
        query: 'by_created_at',
        subquery: 'date'
      }];
      return filters;
    }),
    registerUrl: Ember.computed('currentLocation', function () {
      return this.router.generate('register', {
        location_id: this.get('currentLocation.id')
      });
    }),
    subheader: Ember.computed(function () {
      return "Customers can also use <a href='".concat(this.get('registerUrl'), "' target=\"_blank\">this") + ' store-specific registration page</a> to sign up. Note that this link will sign you out' + ' of the back office.';
    }),
    canUpdate: Ember.computed('selectedCustomer.type', function () {
      var permissionsManager = this.get('permissionsManager');
      var selectedCustomer = this.get('selectedCustomer');

      if (Ember.isEmpty(selectedCustomer)) {
        return false;
      }

      if (selectedCustomer.get('isNew')) {
        return this.get('canCreate');
      }

      switch (selectedCustomer.get('type')) {
        case _constants.default.personType.employee:
          return permissionsManager.verifyForced('update_employees');

        case _constants.default.personType.patient:
          return permissionsManager.verifyForced('update_patients');

        default:
          return permissionsManager.verifyForced('update_customers');
      }
    }),
    canCreate: Ember.computed('selectedCustomer.type', function () {
      var permissionsManager = this.get('permissionsManager');
      var selectedCustomer = this.get('selectedCustomer');

      if (Ember.isEmpty(selectedCustomer)) {
        return true;
      }

      switch (selectedCustomer.get('type')) {
        case _constants.default.personType.employee:
          return permissionsManager.verifyForced('create_employees');

        case _constants.default.personType.patient:
          return permissionsManager.verifyForced('create_patients');

        default:
          return true;
      }
    }),
    canDelete: Ember.computed('selectedCustomer.type', function () {
      var permissionsManager = this.get('permissionsManager');
      var selectedCustomer = this.get('selectedCustomer');

      if (Ember.isEmpty(selectedCustomer)) {
        return false;
      }

      switch (selectedCustomer.get('type')) {
        case _constants.default.personType.employee:
        case _constants.default.personType.patient:
          return false;

        default:
          return permissionsManager.verifyForced('destroy_customers');
      }
    }),
    exportPeopleList: function exportPeopleList() {
      var _this = this;

      this.set('exportDisabled', true);
      var params = this.get('peopleFilters');

      if (Ember.isEmpty(params)) {
        params = {};
      }

      if (!Ember.isEmpty(this.get('titanEmail'))) {
        params.email_to = this.get('titanEmail');
      }

      Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/people/export"), params).then(function () {
        _this.set('exportDisabled', false);

        _this.get('toast').success("We'll notify you when it's ready.", 'Processing customer export...');
      }).catch(function () {
        _this.set('exportDisabled', false);

        _this.get('toast').error('Failed to export customer list.');
      });
    },
    updateSearchText: function updateSearchText(value) {
      this.set('searchText', value);
      var filters = {};

      if (Ember.isPresent(value)) {
        filters['by_query'] = value;
      }

      this.set('peopleFilters', filters);
      this.set('model.filters', filters);
    },
    actions: {
      export: function _export() {
        this.exportPeopleList();
      },
      savePerson: function savePerson(person) {
        return (0, _people.savePerson)(person);
      },
      onCreate: function onCreate(person) {
        var currentLocation = this.get('currentLocation');

        person._internalModel.setId(_uuidGenerator.default.v4());

        person.set('location_id', currentLocation.get('id'));

        if (Ember.isBlank(person.get('type'))) {
          if (currentLocation.get('medicalOnly')) {
            person.set('type', _constants.default.personType.patient);
          } else {
            person.set('type', _constants.default.personType.customer);
          }
        }
      },
      onEdit: function onEdit(person) {
        person.pinPersonGroups();
      },
      adjustLoyaltyPoints: function adjustLoyaltyPoints() {
        this.set('showLoyaltyComponent', true);
      },
      cancelAdjustLoyaltyPoints: function cancelAdjustLoyaltyPoints() {
        this.set('showLoyaltyComponent', false);
      },
      showMergePerson: function showMergePerson() {
        this.set('showMergeComponent', true);
      },
      hideMergePerson: function hideMergePerson() {
        this.set('showMergeComponent', false);
      },
      mergeSuccess: function mergeSuccess(fromPerson, toPerson) {
        var _this2 = this;

        var selectedCustomerId = toPerson.get('id'); // Clear the list on screen

        this.set('model.objects', []);
        this.set('selectedCustomer', null);
        this.hideDrawer(); // Have to find customer from store directly because...
        // - toPerson no longer exists when we refresh the elements on screen
        // - We might have scrolled down the screen before a merge
        //
        // Doing it this way ensures we highlight the proper element

        var store = this.get('store');
        store.findRecord(toPerson.get('modelTypeString'), selectedCustomerId).then(function (customer) {
          _this2.fetchObjects().then(function () {
            // Trigger resizing event on window, so drawer can calculate it properly with new data
            _this2.set('selectedCustomer', customer);

            _this2.showDrawer();
          });
        });
      },
      filterParamsChanged: function filterParamsChanged(filterParams) {
        var params = (0, _clone.default)(filterParams);
        this.set('peopleFilters', params);
        this.set('model.filters', params);
      },
      filterValuesChanged: function filterValuesChanged() {// NOOP
      },
      toggleAdvancedFilters: function toggleAdvancedFilters() {
        var current = this.get('advancedFilters');

        if (current) {
          this.updateSearchText(null);
        } else {
          this.set('model.filters', {});
        }

        this.set('advancedFilters', !current);
      },
      onCardReadError: function onCardReadError() {
        var _this3 = this;

        this.set('swipeError', true);
        setTimeout(function () {
          return _this3.set('swipeError', false);
        }, 500);
      },
      onCardRead: function onCardRead(idCard) {
        var _this4 = this;

        this.set('swipeSuccess', true);
        setTimeout(function () {
          return _this4.set('swipeSuccess', false);
        }, 500);
        var customer = this.get('selectedCustomer');

        if (customer) {
          this.propagateCustomerFromCard(idCard, customer);
        }

        this.filterCustomerFromCard(idCard);
      },
      updateSearchText: function updateSearchText(value) {
        this.updateSearchText(value);
      },
      showConfirmationAlert: function showConfirmationAlert(callbacks) {
        this.send("confirmTransition", callbacks);
      }
    }
  });

  _exports.default = _default;
});