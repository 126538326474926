define("herer-web/models/tax-item-schedule", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    rate: (0, _model.attr)('string'),
    effective_start: (0, _model.attr)('day'),
    effective_end: (0, _model.attr)('day'),
    exlusive: Ember.computed.not('inclusive'),
    inclusive: (0, _model.attr)('boolean'),
    calculate_with_subtotal: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});