define("herer-web/templates/auth-leafly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+CoDMc0w",
    "block": "{\"symbols\":[\"xs\",\"leaflyStore\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[10,\"data-test-text\",\"auth_leafly\"],[8],[0,\"\\n      Select which Leafly store to publish\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-8 col-md-offset-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-10\"],[8],[0,\"\\n      \"],[5,\"x-select\",[],[[\"@value\",\"@class\",\"@data-test-input\",\"@onChange\"],[[23,0,[\"selectedStoreId\"]],\"form-control\",\"store_select\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedStoreId\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"option\"]],[],[[],[]],{\"statements\":[[0,\"\\n          Select a store\\n        \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[24,[\"leaflyStores\"]]],null,{\"statements\":[[0,\"          \"],[6,[23,1,[\"option\"]],[],[[\"@value\",\"@data-test-item\"],[[23,2,[\"id\"]],\"leafly_store\"]],{\"statements\":[[0,\"\\n            \"],[1,[23,2,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n      \"],[1,[28,\"save-button\",null,[[\"onSave\",\"prompt\"],[[28,\"action\",[[23,0,[]],\"selectStoreAndPublish\",[24,[\"model\"]],[24,[\"selectedStoreId\"]]],null],\"Confirm\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/auth-leafly.hbs"
    }
  });

  _exports.default = _default;
});