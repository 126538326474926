define("herer-web/components/user-info-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'table-row'],
    classNameBindings: ['user.is_deleted:deleted-row', 'user.hasAccessibleLocations::warn-row'],
    user: null,
    roles: null,
    isSelected: Ember.computed.equal('user', 'selected'),
    role: Ember.computed('user', 'user.role_id', 'roles', function () {
      var user = this.get('user');
      var roles = this.get('roles');

      if (Ember.isEmpty(user) || Ember.isEmpty(roles)) {
        return;
      }

      var roleId = user.get('role_id');
      return roles.find(function (role) {
        return role.get('id') === roleId;
      });
    }),
    isCurrentUser: Ember.computed('user.id', function () {
      return this.get('user.id') === this.get('authManager.currentUser.id');
    }),
    click: function click() {
      this.onSelect && this.onSelect(this.get('user'));
    }
  });

  _exports.default = _default;
});