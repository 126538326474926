define("herer-web/models/tax-breakdown", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    total: (0, _model.attr)('number'),
    tax_item: (0, _model.belongsTo)('tax_item', {
      async: true
    }),
    inclusive: Ember.computed.alias('_effectiveSchedule.inclusive'),
    line_item: (0, _model.belongsTo)('line_item', {
      async: false
    }),
    order: Ember.computed.alias('line_item.order'),
    _effectiveSchedule: Ember.computed('tax_item', 'order', 'tax_item.tax_item_schedules.@each.{effective_start,effective_end}', function () {
      var now = this.get('order').get('charged_on');
      var schedules = this.get('tax_item').get('tax_item_schedules').filter(function (schedule) {
        var end = schedule.get('effective_end');
        return !Ember.isPresent(end) || end >= now;
      });
      return schedules.find(function (schedule) {
        return schedule.get('effective_start') <= now;
      });
    })
  });

  _exports.default = _default;
});