define("herer-web/services/auth-manager", ["exports", "herer-web/constants", "herer-web/mixins/titan-email", "lodash/omit"], function (_exports, _constants, _titanEmail, _omit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _tokenHeader = function _tokenHeader(token) {
    return "Token token=\"".concat(token, "\"");
  };

  var _default = Ember.Service.extend(_titanEmail.default, {
    store: Ember.inject.service(),
    zendesk: Ember.inject.service(),
    http: Ember.inject.service(),
    cookies: Ember.inject.service(),
    lastAuth: null,
    currentUser: null,
    currentLocation: null,
    currentRole: null,
    currentAccount: null,
    currentLocations: null,
    onAuthenticate: function onAuthenticate() {},
    sortedLocations: Ember.computed('currentUser', function () {
      var locations = this.get('store').peekAll('location');
      return locations.toArray().sort(function (l1, l2) {
        // active sort items first
        var activeSort = 0;
        var l1Active = l1.get('active');
        var l2Active = l2.get('active');

        if (!(l1Active && l2Active)) {
          activeSort = l1Active ? -2 : 2;
        } // then alpha sort


        var alphaSort = 0;
        var l1Name = l1.get('name').toLowerCase();
        var l2Name = l2.get('name').toLowerCase();

        if (l1Name < l2Name) {
          alphaSort = -1;
        } else if (l1Name > l2Name) {
          alphaSort = 1;
        }

        var sortInt = alphaSort + activeSort;
        return sortInt;
      });
    }),
    isUserDataPresent: Ember.computed.and('currentUser', 'currentRole', 'currentAccount'),
    isAuthStale: function isAuthStale() {
      var lastAuth = this.get('lastAuth');

      if (Ember.isEmpty(lastAuth)) {
        return true;
      }

      return Date.now() - this.get('lastAuth') > 5 * 60 * 1000;
    },
    checkAuthentication: function checkAuthentication() {
      var useCache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var authToken = Ember.get(this, 'cookies').getCookie('atkn');

      if (!authToken) {
        return Ember.RSVP.Promise.reject();
      } // If we have any data just resolve the promise to keep things moving


      if (useCache && this._hasCredentials()) {
        if (this.isAuthStale()) {
          // Don't return the promise, just fetch in background
          this.authenticateWithToken(authToken);
        }

        return Ember.RSVP.Promise.resolve();
      }

      return this.authenticateWithToken(authToken);
    },
    authenticateWithPassword: function authenticateWithPassword(email, password) {
      var basicAuthToken = btoa("".concat(email, ":").concat(password));
      return this._authenticateWithHeader({
        'Authorization': "Basic ".concat(basicAuthToken)
      });
    },
    authenticateWithToken: function authenticateWithToken(token) {
      return this._authenticateWithHeader({
        'Authorization': _tokenHeader(token)
      });
    },
    reset: function reset() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.next(_this, function () {
          var cookies = Ember.get(_this, 'cookies');

          _this.set('currentUser', null);

          _this.set('currentLocation', null);

          _this.set('stateCredential', null);

          _this.get('store').unloadAll();

          cookies.removeCookie('atkn');
          cookies.removeCookie('gbc');
          Ember.get(_this, 'http').setHeaders({
            'Authorization': null,
            'X-GB-CompanyId': null
          });

          _this.get('zendesk').shutdown();

          resolve();
        });
      });
    },
    changeLocation: function changeLocation(location) {
      var cookies = Ember.get(this, 'cookies');
      var oldLocation = cookies.getCookie('gbc');
      this.set('currentLocation', location);
      cookies.setCookie('gbc', this.get('currentLocation.id'));
      Ember.get(this, 'http').setHeaders({
        'X-GB-CompanyId': this.get('currentLocation.id')
      });

      if (Ember.isPresent(oldLocation) && oldLocation !== this.get('currentLocation.id')) {
        window.location.reload(true);
      }
    },
    _authenticateWithHeader: function _authenticateWithHeader(headers) {
      var _this2 = this;

      return Ember.get(this, "http").get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/me"), headers).then(function (response) {
        return _this2._authenticateSuccess(response.data);
      }).catch(function (response) {
        _this2.reset();

        var errorMessage = '';

        if (response.status === 401) {
          errorMessage = 'E-mail or Password is incorrect.';
        } else if (response.status === 403) {
          errorMessage = 'You are not authorized to access this.';
        }

        throw new Error(errorMessage);
      });
    },
    _hasCredentials: function _hasCredentials() {
      return this.get('isUserDataPresent') && this._getLocationFromCookie();
    },
    _authenticateSuccess: function _authenticateSuccess(response) {
      var _this3 = this;

      Ember.get(this, 'http').setHeaders({
        'Authorization': _tokenHeader(response.user.token)
      });
      var store = this.get('store');
      return Ember.RSVP.hash({
        location: this._loadLocation(),
        currentRole: store.findRecord('role', response.user.role_id),
        currentAccount: store.findRecord('account', response.user.account_id)
      }).then(function (responses) {
        var user = store.push(store.normalize('user', response.user));

        _this3.setAuthenticationToken(user);

        _this3.set('currentUser', user);

        _this3.set('lastAuth', Date.now());

        _this3.setProperties((0, _omit.default)(responses, 'location'));

        _this3.changeLocation(responses.location);

        _this3._configureBugsnag(_this3.get('currentUser'), _this3.get('currentLocation'));

        _this3._configureMixpanel(response.user, _this3.get('currentLocation'), _this3.get('currentAccount'));

        _this3.onAuthenticate();
      });
    },
    _loadLocation: function _loadLocation() {
      var _this4 = this;

      var store = this.get('store');
      return Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/locations")).then(function (response) {
        if (response.data.locations.length < 1) {
          throw 'You do not have access to any locations.';
        }

        store.pushPayload('location', response.data);

        var location = _this4._getLocationFromCookie();

        Ember.run.next(function () {
          var ids = response.data.locations.map(function (location) {
            return location.id;
          });
          store.peekAll('location').forEach(function (location) {
            if (!ids.includes(location.id)) {
              location.deleteRecord();
            }
          });
        });
        return location;
      });
    },
    _getLocationFromCookie: function _getLocationFromCookie() {
      var location;
      var locationId = Ember.get(this, 'cookies').getCookie('gbc');

      if (Ember.isPresent(locationId)) {
        location = this.get('store').peekAll('location').findBy('id', locationId);
      }

      if (!Ember.isPresent(location)) {
        location = this.get('sortedLocations')[0];
      }

      return location;
    },
    _configureBugsnag: function _configureBugsnag(user, location) {
      if (window.bugsnagClient) {
        return window.bugsnagClient.user = {
          id: user.get('id'),
          email: user.get('email'),
          username: user.get('username'),
          location_id: location.get('id'),
          location_name: location.get('name')
        };
      }
    },
    _configureMixpanel: function _configureMixpanel(user, location, account) {
      if (!window.mixpanel) {
        return;
      }

      var superProps = {
        location_id: location.get('id'),
        location_name: location.get('name'),
        account_id: account.get('id'),
        account_name: account.get('name')
      };
      window.mixpanel.registerUser(user);
      window.mixpanel.updateSuperProps(superProps);
    },
    // If a retUrl cookie exists on *.greenbits.com it means we want to redirect
    // the user back to that url (e.g. marketing.greenbits.com) after login
    _checkRedirectCookie: function _checkRedirectCookie() {
      var cookies = Ember.get(this, 'cookies');
      var retUrl = cookies.getCookie('retUrl');

      if (!retUrl) {
        return;
      }

      cookies.removeCookie('retUrl');

      if (/^https?:\/\/([a-z0-9-]+\.)*greenbits\.com($|[?|/|#])/.test(retUrl)) {
        window.location.href = retUrl;
      }
    },
    setAuthenticationToken: function setAuthenticationToken(currentUser) {
      var cookies = this.get('cookies');
      cookies.setCookie('atkn', Ember.get(currentUser, 'token'));
    }
  });

  _exports.default = _default;
});