define("herer-web/components/weekly-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DISABLED_DAYS = {
    0: 'Sunday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    disabledDates: Ember.computed(function () {
      return [function (date) {
        return Object.keys(DISABLED_DAYS).includes(date.getDay().toString());
      }];
    })
  });

  _exports.default = _default;
});