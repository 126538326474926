define("herer-web/services/reconcilation", ["exports", "herer-web/constants", "herer-web/mixins/titan-email", "lodash/omit"], function (_exports, _constants, _titanEmail, _omit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    packagesTraceabilityStatuses: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      this.packagesTraceabilityStatuses = this.packagesTraceabilityStatuses || {};
      this.reset();
    },
    onTraceabilityChange: function onTraceabilityChange(uuid, sourceOfTruth, traceabilityStatus) {
      var statuses = this.get('packagesTraceabilityStatuses');

      if (sourceOfTruth) {
        statuses[uuid] = {
          sourceOfTruth: sourceOfTruth,
          traceabilityStatus: traceabilityStatus
        };
      } else {
        delete statuses[uuid];
      }

      this.set('packagesTraceabilityStatuses', statuses);
    },
    reset: function reset() {
      this.set('packagesTraceabilityStatuses', {});
    }
  });

  _exports.default = _default;
});