define("herer-web/templates/packages/package/reconcile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z5oDue7s",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"packages/package-reconciler\",null,[[\"package\",\"onClose\",\"onDismissReconciliation\",\"onFinish\"],[[24,[\"model\"]],[28,\"route-action\",[\"cancel\"],null],[28,\"route-action\",[\"dismissReconciliation\"],null],[28,\"route-action\",[\"finish\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/packages/package/reconcile.hbs"
    }
  });

  _exports.default = _default;
});