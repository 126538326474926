define("herer-web/utils/semver-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = semverSort;

  function semverSort(list) {
    return list.sort(function (a, b) {
      if (a == null && b != null) {
        return 1;
      }

      if (a != null && b == null) {
        return -1;
      }

      if (a == null && b == null) {
        return 0;
      }

      var pa = a.split('.');
      var pb = b.split('.');

      for (var i = 0; i < pa.length; i++) {
        var na = Number(pa[i]);
        var nb = Number(pb[i]);

        if (na > nb) {
          return 1;
        }

        if (nb > na) {
          return -1;
        }

        if (!isNaN(na) && isNaN(nb)) {
          return 1;
        }

        if (isNaN(na) && !isNaN(nb)) {
          return -1;
        }

        return 0;
      }
    });
  }
});