define("herer-web/services/product-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    findAssociationByName: function findAssociationByName(product, association, name) {
      var store = this.get('store');
      var promise;

      if (Ember.isEmpty(name)) {
        promise = Ember.RSVP.resolve(null);
      } else if (name === product.get(association + '.name')) {
        promise = Ember.RSVP.resolve(product.get(association));
      } else {
        promise = store.query(association, {
          by_name: name.trim()
        }).then(function (objects) {
          var object;

          if (!Ember.isEmpty(objects)) {
            var nameCompare = name.toUpperCase().trim();
            object = objects.find(function (object) {
              return object.get('name').toUpperCase().trim() === nameCompare;
            });
          }

          if (Ember.isEmpty(object)) {
            object = store.createRecord(association, {
              name: name
            });
          }

          return object;
        });
      }

      return promise;
    },
    saveAssociationsByName: function saveAssociationsByName(product, names) {
      var _this = this;

      var promises = {};
      ['brand', 'strain', 'vendor'].forEach(function (association) {
        var newName = names[association];
        promises[association] = _this.findAssociationByName(product, association, newName).then(function (foundAssociation) {
          product.set(association, foundAssociation);

          if (Ember.isPresent(foundAssociation) && foundAssociation.get('isNew')) {
            var canCreateEntity = _this.havePermissionToCreate(association);

            return canCreateEntity ? foundAssociation.save() : Ember.RSVP.reject({
              message: "You do not have permission to add ".concat(association, "s.") + ' Switch your role to one that does have it, or add this' + ' permission to your role here: https://secure.greenbits.com/roles'
            });
          } else {
            return Ember.RSVP.resolve();
          }
        });
      });
      return Ember.RSVP.hash(promises);
    },
    havePermissionToCreate: function havePermissionToCreate(association) {
      var permissionsManager = this.get('permissionsManager');
      var permission;

      if (association === 'vendor') {
        permission = 'create_suppliers';
      } else {
        permission = "create_".concat(association, "s");
      }

      return permissionsManager.verifyForced(permission);
    }
  });

  _exports.default = _default;
});