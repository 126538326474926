define("herer-web/routes/accept-manifest", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedFullPage, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_alertUnsavedRoute.default, {
    controllerName: 'manifest',
    templateName: 'manifest',
    launchDarkly: Ember.inject.service(),
    permissionKey: 'receive_inventory',
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        taxItems: store.findAll('tax_item'),
        productTypes: store.findAll('product_type'),
        tags: store.findAll('tag'),
        tieredPricingModels: store.findAll('tiered-pricing-model'),
        remoteManifest: store.findRecord('remote_manifest', params.id),
        manifest: store.createRecord('manifest')
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      return this.get('launchDarkly').waitForReady().then(function () {
        var useVendorSku = _this.get('launchDarkly').can('use-vendor-skus', false);

        model.manifest.fillWithRemoteManifest(model.remoteManifest, _this.get('store'), useVendorSku);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.manifest);

      controller.set('taxItems', model.taxItems);
      controller.set('productTypes', model.productTypes);
      controller.set('tags', model.tags);
      controller.set('remoteManifest', model.remoteManifest);
      controller.set('tieredPricingModels', model.tieredPricingModels);
      controller.set('pendingManifest', true);
    },
    deactivate: function deactivate() {
      var controller = this.get('controller');
      controller.set('remoteManifest', null);
      var manifest = controller.get('model');
      manifest.rollbackAttributes();
    }
  });

  _exports.default = _default;
});