define("herer-web/services/state-link-status-updater", ["exports", "herer-web/constants", "moment"], function (_exports, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Responsible for gathering the status of State Link
  // and providing it to other components.
  //
  // Provided as a service so we only fetch information once, then
  // can pass it into nav items and status update components.
  var _default = Ember.Service.extend({
    http: Ember.inject.service(),
    isLoading: true,
    lastIncomingInventorySyncInWords: null,
    lastIncomingInventorySync: null,
    actionsToReport: 0,
    actionsReportedLastDay: 0,
    resolvableReportingErrors: 0,
    timer: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var syncIntervalSeconds = 200;
      var jitter = Math.random() * 100;
      this.loadStatusReport();
      var timer = setInterval(function () {
        _this.loadStatusReport();
      }, (syncIntervalSeconds + jitter) * 1000);
      this.set('timer', timer);
    },
    loadStatusReport: function loadStatusReport() {
      var _this2 = this;

      this.set('isLoading', true);
      return Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/v2/state_link/status")).then(function (response) {
        var json = response.data;

        _this2.set('lastIncomingInventorySyncInWords', (0, _moment.default)(json.last_incoming_inventory_sync).fromNow());

        _this2.set('lastIncomingInventorySync', json.last_incoming_inventory_sync);

        _this2.set('actionsToReport', json.actions_to_report);

        _this2.set('actionsReportedLastDay', json.actions_reported_last_day);

        _this2.set('resolvableReportingErrors', json.resolvable_reporting_errors);

        _this2.set('isLoading', false);
      });
    }
  });

  _exports.default = _default;
});