define("herer-web/components/facility-cannot-sell-to-patients-notification-row", ["exports", "herer-web/constants", "herer-web/components/traceability-notification-row"], function (_exports, _constants, _traceabilityNotificationRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _traceabilityNotificationRow.default.extend({
    http: Ember.inject.service(),
    successMessage: 'Successfully reported sales.',
    resubmitConfirmationVisible: false,
    actions: {
      showResubmitConfirmation: function showResubmitConfirmation() {
        this.set('errors', []);
        this.set('resubmitConfirmationVisible', true);
      },
      closeResubmitConfirmation: function closeResubmitConfirmation() {
        this.set('resubmitConfirmationVisible', false);
      },
      save: function save() {
        var _this = this;

        var notification = this.get('notification');
        var rootPath = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/traceability_notifications");
        var fullPath = "".concat(rootPath, "/").concat(notification.get('id'), "/run_resolver");
        this.set('isSaving', true);
        Ember.get(this, 'http').post(fullPath).then(function () {
          _this.set('resubmitConfirmationVisible', false);

          _this.set('isSaving', false);

          notification.unloadRecord();

          _this.set('notification', null);

          _this.get('toast').success(_this.get('successMessage'));
        }).catch(function (response) {
          var message = response.data ? response.data.message : response.statusText;

          _this.set('isSaving', false);

          _this.set('errors', [message]);
        });
      }
    }
  });

  _exports.default = _default;
});