define("herer-web/serializers/id-scan", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.id_scans) {
        payload.id_scans.forEach(function (idScan) {
          _this.normalizeIdScan(idScan);
        });
      } else {
        this.normalizeIdScan(payload.id_scan);
      }

      return this._super.apply(this, arguments);
    },
    normalizeIdScan: function normalizeIdScan(idScan) {
      // WA-244: IdScan are temporary records that we use to validate against external API.
      // As such there is no valid UUID from the API; however since we use ember data, we need an ID
      // so that it doesn't barf.
      idScan['id'] = Ember.guidFor();
    }
  });

  _exports.default = _default;
});