define("herer-web/models/discount-condition", ["exports", "@ember-data/model", "lodash/reduce", "lodash/flatten"], function (_exports, _model, _reduce, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: (0, _model.attr)('string'),
    value: (0, _model.attr)(),
    values: (0, _model.attr)(),
    discountPlan: (0, _model.belongsTo)('discountPlan', {
      async: false,
      embedded: 'always'
    }),
    identifier: Ember.computed('key', 'value', 'values', function () {
      var key = this.get('key');
      var value = this.get('value');
      var values = this.get('values');
      return (0, _reduce.default)((0, _flatten.default)([key, value, values]), function (prev, i) {
        return "".concat(prev, "-").concat(i);
      }, '');
    })
  });

  _exports.default = _default;
});