define("herer-web/templates/qs-reports/shift-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFjDzmC6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Cash Drawer\",\"The Cash Drawer shows you a breakdown of the financials related to a drawer so you can reconcile sales and investigate discrepancies.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887022880275-Cash-Drawer-View-a-breakdown-of-all-changes-to-a-drawer-with-Actionable-Insights\\\" target\\\"_blank\\\">Learn more</a>\",\"10\",\"shift_summary_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"ShiftSummaryReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ShiftSummaryReport\",\"shift_summary_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/shift-summary.hbs"
    }
  });

  _exports.default = _default;
});