define("herer-web/components/invalid-split-or-merge-traceability-notification", ["exports", "herer-web/models/weight", "herer-web/components/traceability-notification-row"], function (_exports, _weight, _traceabilityNotificationRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _traceabilityNotificationRow.default.extend({
    successMessage: 'Successfully updated Destination Package ID.',
    sourcePackages: Ember.computed('notification', function () {
      return this.get('notification').get('metadata').source_packages.map(function (source) {
        return {
          lotNumber: source.lot_number,
          quantity: _weight.default.create({
            value: source.quantity.value,
            unit: source.quantity.unit
          })
        };
      });
    })
  });

  _exports.default = _default;
});