define("herer-web/components/packages/package/history-events/event-row", ["exports", "herer-web/helpers/format-date"], function (_exports, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    historyEvent: null,
    statusUpdatedAt: Ember.computed('historyEvent.statusUpdatedAt', function () {
      return (0, _formatDate.formatDate)(this.get('historyEvent.statusUpdatedAt'), {
        shortDateTime: true
      });
    }),
    occurredAt: Ember.computed('historyEvent.occurredAt', function () {
      return (0, _formatDate.formatDate)(this.get('historyEvent.occurredAt'), {
        shortDateTime: true
      });
    }),
    diffQuantity: Ember.computed('historyEvent.diffQuantity', function () {
      var quantity = this.get('historyEvent.diffQuantity');

      if (quantity === null) {
        return 'N/A';
      } else if (quantity.get('value').lt(0)) {
        return "( ".concat(quantity.times(-1), " )");
      } else {
        return quantity;
      }
    }),
    diffQuantityClass: Ember.computed('historyEvent.diffQuantity', function () {
      var quantity = this.get('historyEvent.diffQuantity');

      if (quantity === null) {
        return 'nan';
      } else if (quantity.get('value').lt(0)) {
        return 'negative';
      }
    }),
    statusIcon: Ember.computed('historyEvent.status', function () {
      switch (this.get('historyEvent.status')) {
        case 'pending':
          return 'fa-cloud-upload-alt';

        case 'blocked':
          return 'fa-ban';

        case 'completed':
          return 'fa-check-circle';

        case 'reassigned':
          return 'fa-share-square';

        default:
          break;
      }
    }),
    showStatusUpdatedAt: Ember.computed('historyEvent.status', function () {
      return this.get('historyEvent.status') === 'completed';
    })
  });

  _exports.default = _default;
});