define("herer-web/templates/qs-reports/product-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AurmIwsu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Product Performance\",\"The Product Performance report you more insights on your product and brand performance. <a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887017259795-Product-Performance-explore-your-most-and-least-successful-products-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"product_performance_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ProductPerformanceReport\",\"product_performance_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/product-performance.hbs"
    }
  });

  _exports.default = _default;
});