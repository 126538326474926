define("herer-web/templates/components/ignore-reporting-failure-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iJ40Qhhu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"ignoreReportingFailureModal\"],[10,\"aria-hidden\",\"false\"],[10,\"class\",\"modal fade ignore-reporting-failure-modal\"],[10,\"data-test-modal\",\"ignore_reporting_failure_modal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Close\"],[10,\"class\",\"close\"],[10,\"data-test-action\",\"close\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n            ×\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"modal-title\"],[8],[0,\"\\n          Skip Reporting Failure?\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[10,\"data-test-text\",\"info_text\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          Are you sure you want to clear this reporting failure?\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Cancel\"],[10,\"class\",\"btn btn-default\"],[10,\"data-test-action\",\"cancel\"],[8],[0,\"No\"],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[12,\"data-test-action\",\"ok\"],[3,\"action\",[[23,0,[]],\"onClick\"]],[8],[0,\"Yes\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/ignore-reporting-failure-modal.hbs"
    }
  });

  _exports.default = _default;
});