define("herer-web/models/task", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    completed_at: (0, _model.attr)('date'),
    created_at: (0, _model.attr)('date'),
    updated_at: (0, _model.attr)('date'),
    percent_complete: (0, _model.attr)('number'),
    options: (0, _model.attr)(),
    result: (0, _model.attr)(),
    queued: Ember.computed.equal('status', _constants.default.taskStatus.queued),
    processing: Ember.computed.equal('status', _constants.default.taskStatus.processing),
    success: Ember.computed.equal('status', _constants.default.taskStatus.success),
    error: Ember.computed.equal('status', _constants.default.taskStatus.error),
    claimed: Ember.computed.equal('status', _constants.default.taskStatus.claimed),
    location: (0, _model.belongsTo)('location', {
      async: false
    }),
    location_id: (0, _model.attr)('string'),
    hasFile: Ember.computed('result', function () {
      return Ember.isPresent(this.get('result.url'));
    }),
    hasOptions: Ember.computed('options', function () {
      return Object.keys(this.get('options')).length > 0;
    }),
    displayOptions: Ember.computed('hasOptions', 'name', function () {
      return this.get('hasOptions') && this.get('name') !== 'Tasks::PackageReconciliation';
    }),
    isRetryable: Ember.computed('options', function () {
      var options = this.get('options');

      if ('retryable' in options) {
        return !!options['retryable'];
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});