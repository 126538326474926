define("herer-web/routes/store-options", ["exports", "herer-web/routes/authenticated-side-nav"], function (_exports, _authenticatedSideNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    sideNavTemplateName: 'settings-nav',
    model: function model() {
      return this.get('authManager.currentLocation');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('verifyAgeChoice', model.get('verify_age'));
      controller.set('requireCustomerChoice', model.get('require_customer'));
      controller.set('autoCloseInventoryChoice', model.get('auto_close_inventory'));
      controller.set('autoPrintReceiptsChoice', model.get('auto_print_receipts'));
      controller.set('belowCostWarningChoice', model.get('below_cost_warning'));
      controller.set('combinePricingTiersChoice', model.get('combine_pricing_tiers_strategy'));
      controller.set('enableMedicalTransactions', model.get('medical'));
      controller.set('skipTaxPeriodValidationChoice', model.get('skip_tax_period_validation'));
      controller.set('autoLogoutChoice', model.get('auto_logout'));
      controller.set('queueChoice', model.get('queue'));
      controller.set('requireReferralSourceChoice', model.get('require_referral_source'));
      controller.set('weighStationEnabled', model.get('weigh_station_enabled'));
    }
  });

  _exports.default = _default;
});