define("herer-web/models/inventory-audit", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    receipt_id: (0, _model.attr)('string'),
    vendor: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    expected_completion_at: (0, _model.attr)('date', {
      defaultValue: null
    }),
    completed_at: (0, _model.attr)('date'),
    submitted_at: (0, _model.attr)('date'),
    notes: (0, _model.attr)('string'),
    audit_results_content_type: (0, _model.attr)('string'),
    audit_results_file_name: (0, _model.attr)('string'),
    audit_results_fingerprint: (0, _model.attr)('string'),
    processing: (0, _model.attr)('boolean'),
    adjustment_reason: (0, _model.attr)('string'),
    adjustment_reason_id: (0, _model.attr)('number'),
    adjustment_type: (0, _model.attr)('number'),
    product_type: (0, _model.belongsTo)('product-type', {
      async: false
    }),
    manifest: (0, _model.belongsTo)('manifest'),
    inventory_audit_results: (0, _model.hasMany)('inventory-audit-result', {
      async: false
    }),
    export: function _export() {
      var adapter = this.store.adapterFor("inventory-audit");
      return adapter.export(this);
    },
    audit_results: null
  });

  _exports.default = _default;
});