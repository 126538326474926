define("herer-web/templates/components/onboarding-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kiLNrPgj",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"unless\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"\\n        Welcome to Greenbits\\n      \"],[9],[4,\"if\",[[24,[\"hasIncompleteSteps\"]]],null,{\"statements\":[[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"\\n          You still need to complete a few chores before the cash starts flowing in…\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h2\",true],[8],[0,\"\\n          You're all set. Give us a call if you have questions about your upcoming activation.\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"valuemax\",[22,\"progressMax\"]],[11,\"style\",[29,[\"width: \",[22,\"currentProgress\"],\"%\"]]],[10,\"class\",\"progress-bar progress-bar-success\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"verifiedSteps\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[11,\"class\",[29,[\"step \",[28,\"if\",[[23,1,[\"isComplete\"]],\"done\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"link\"]],[28,\"not\",[[23,1,[\"isComplete\"]]],null]],null]],null,{\"statements\":[[0,\"              \"],[7,\"a\",true],[11,\"target\",[23,1,[\"linkTarget\"]]],[11,\"href\",[23,1,[\"link\"]]],[8],[0,\"\\n                \"],[1,[23,1,[\"prompt\"]],false],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[1,[23,1,[\"prompt\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/onboarding-steps.hbs"
    }
  });

  _exports.default = _default;
});