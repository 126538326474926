define("herer-web/templates/components/qs-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CggzV7KV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"dashboardContainer\"],[10,\"class\",\"row\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"loadingMessage\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-6 display-text-1 grey-400-text\"],[8],[0,\"\\n    Loading\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"full-page-container-style\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/qs-report.hbs"
    }
  });

  _exports.default = _default;
});