define("herer-web/templates/qs-reports/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z2AIj/bO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Business Snapshot\",\"The Business Snapshot report helps you track trends in your sales data over time.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886960819603-Sales-Activity-Profitability-Make-Better-Business-Decisions-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"10\",\"sales_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"SalesReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"SalesReport\",\"sales_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/sales.hbs"
    }
  });

  _exports.default = _default;
});