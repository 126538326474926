define("herer-web/components/packages/package-closer", ["exports", "herer-web/mixins/adjustment-reasons", "herer-web/components/base-modal", "herer-web/utils/validation", "yup"], function (_exports, _adjustmentReasons, _baseModal, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend(_adjustmentReasons.default, {
    modalClassName: '.package-close-modal',
    note: null,
    reason: null,
    authManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    onConfirm: null,
    validations: (0, _validation.validations)({
      reason: (0, _yup.string)().nullable().required(),
      note: (0, _yup.string)().nullable().required()
    }),
    needsAdjustment: Ember.computed('package', function () {
      if (Ember.isEmpty(this.get('package.inventoryItems'))) {
        return false;
      }

      return this.get('package.inventoryItems').any(function (item) {
        return !item.get('quantity.isZero');
      });
    }),
    hasFailed: Ember.computed('errorMessage', function () {
      return this.get('errorMessage') != null;
    }),
    isCloseAttemptFailed: Ember.computed.alias('hasFailed'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        this.resetProperties();
        modal.modal({
          backdrop: 'static'
        });
      }
    },
    resetFieldErrors: function resetFieldErrors() {
      this.set('validations.fieldErrors.note', null);
      this.set('validations.fieldErrors.reason', null);
    },
    resetProperties: function resetProperties() {
      if (this.get('isSaving') || this.get('hasFailed')) {
        return;
      }

      this.set('note', null);
      this.set('reason', null);
      this.resetFieldErrors();
    },
    actions: {
      verifyAndConfirm: function verifyAndConfirm() {
        var _this = this;

        this.get("validations").validate().then(function () {
          _this.set('isConfirming', true);
        }).catch(function () {});
      },
      unconfirm: function unconfirm() {
        this.set('isConfirming', false);
        this.resetFieldErrors();
      },
      cancel: function cancel() {
        this.resetFieldErrors();
        this.onCancel();
      },
      closePackage: function closePackage() {
        this.resetFieldErrors();
        var aPackage = this.get('package');
        var params = {
          reason: this.get('reason'),
          note: this.get('note')
        };
        this.onConfirm(aPackage, params);
      }
    }
  });

  _exports.default = _default;
});