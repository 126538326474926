define("herer-web/templates/full-page-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TuplhyeN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container outlet-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"root\"],[10,\"class\",\"full-page-container\"],[8],[0,\"\\n    \"],[1,[28,\"system-status-banners\",null,[[\"systemStatus\"],[[24,[\"systemStatus\"]]]]],false],[0,\"\\n    \"],[1,[28,\"outlet\",[\"content\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/full-page-layout.hbs"
    }
  });

  _exports.default = _default;
});