define("herer-web/routes/manifest-new", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route", "herer-web/constants"], function (_exports, _authenticatedFullPage, _alertUnsavedRoute, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_alertUnsavedRoute.default, {
    controllerName: 'manifest',
    templateName: 'manifest',
    permissionKey: 'receive_inventory',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        taxItems: store.findAll('tax_item'),
        productTypes: store.findAll('product_type'),
        tags: store.findAll('tag'),
        tieredPricingModels: store.findAll('tiered-pricing-model'),
        manifest: store.createRecord('manifest', {
          manifest_type: _constants.default.manifestType.newInventory
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.manifest);

      controller.set('taxItems', model.taxItems);
      controller.set('productTypes', model.productTypes);
      controller.set('tags', model.tags);
      controller.set('tieredPricingModels', model.tieredPricingModels);
      controller.set('pendingManifest', true);
      controller.set('markupRate', 0);
      controller.set('exciseTaxRate', 0);
    },
    deactivate: function deactivate() {
      this.modelFor('manifest-new').manifest.rollbackAttributes();
    }
  });

  _exports.default = _default;
});