define("herer-web/utils/error-reporter", ["exports", "herer-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorReporter = _exports.eventReporter = void 0;

  /**
   * Sends an error off to our error collection service
   * @param {Error} error Error Object to be reported
   * @param {Object} opts Options to pass with the error when reported.
   *                      Severity can be passed here as well.
   */
  var errorReporter = function errorReporter(error) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var severity = opts.severity;
    delete opts.severity;

    if (!severity) {
      severity = 'error';
    }

    if (_environment.default.environment !== 'production') {
      console.error(error, opts);
    }

    if (_environment.default.APP.enableBugsnag && Ember.isPresent(window.bugsnagClient)) {
      window.bugsnagClient.notify(error, function (event) {
        event.severity = severity;
        event.addMetadata('meta', opts);
      });
    }
  };
  /**
   * Sends an event to bugsnag with meta data attached
   * @param {string} name
   * @param {Object} meta
   */


  _exports.errorReporter = errorReporter;

  var eventReporter = function eventReporter(name) {
    var meta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (_environment.default.APP.enableBugsnag && Ember.isPresent(window.bugsnagClient)) {
      var eventObj = new Error(name);
      eventObj.name = 'CustomEvent';
      errorReporter(eventObj, meta);
    }
  };

  _exports.eventReporter = eventReporter;
});