define("herer-web/controllers/auth-leafly", ["exports", "bootbox"], function (_exports, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['code', 'state'],
    code: null,
    state: null,
    missingApiKey: Ember.computed.empty('model.apiKey'),
    missingRefreshToken: Ember.computed.empty('model.refreshToken'),
    actions: {
      selectStoreAndPublish: function selectStoreAndPublish(menuFeed, leaflyStoreId) {
        var _this = this;

        if (this.get('missingApiKey') || this.get('missingRefreshToken')) {
          var message = 'There was an autorization error, you will need to ' + 'reauthorize your menu feed.';

          _bootbox.default.confirm(message, function (result) {
            if (result) {
              _this.get('model').rollbackAttributes();

              _this.transitionToRoute('menu-feeds');
            }
          });

          return;
        }

        if (Ember.isEmpty(leaflyStoreId)) {
          _bootbox.default.alert('Please select a store.');

          return;
        }

        menuFeed.set('storeId', leaflyStoreId);
        menuFeed.set('published', true);
        menuFeed.save().then(function () {
          _this.transitionToRoute('menu-feeds');
        });
      }
    }
  });

  _exports.default = _default;
});