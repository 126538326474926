define("herer-web/routes/inventory", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/label-preference", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _labelPreference, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_labelPreference.default, _alertUnsavedRoute.default, {
    sideNavTemplateName: 'inventoryNav',
    permissionKey: 'update_inventory',
    sortBy: 'sku',
    sortDirection: 'asc',
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        inventoryItem: store.findRecord('inventory_item', params.id),
        labelPreference: this.findLabelPreference(store),
        places: store.findAll('place'),
        productTypes: store.findAll('product_type'),
        tags: store.findAll('tag'),
        taxItems: store.findAll('tax_item'),
        tieredPricingModels: store.findAll('tiered-pricing-model')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.inventoryItem);

      controller.set('places', model.places);
      controller.set('labelPreference', model.labelPreference);
      controller.set('productTypes', model.productTypes);
      controller.set('tags', model.tags);
      controller.set('taxItems', model.taxItems);
      controller.set('tieredPricingModels', model.tieredPricingModels);
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render('adjustment', {
        into: 'inventory',
        outlet: 'adjustment',
        controller: 'adjustment'
      });
      this.render('inventory-move', {
        into: 'inventory',
        outlet: 'inventory-move',
        controller: 'inventory-move'
      });
    },
    transitionBackToProduct: function transitionBackToProduct(inventoryItem) {
      var product = inventoryItem.get('product');
      this.transitionTo('products.show', product, {
        queryParams: {
          activeTab: 'inventory'
        }
      });
    },
    actions: {
      cancel: function cancel(inventoryItem) {
        inventoryItem.rollbackAttributes();
        this.transitionBackToProduct(inventoryItem);
      },
      save: function save(inventoryItem) {
        var _this = this;

        return inventoryItem.save().then(function () {
          _this.transitionBackToProduct(inventoryItem);
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});