define("herer-web/templates/components/manage-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFsEPk5C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"nav nav-pills nav-stacked\"],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"onclick\",\"document.getElementById('crm-form').submit()\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-credit-card hidden-md\"],[8],[9],[0,\"\\n      Subscription & Billing\\n    \"],[9],[0,\"\\n    \"],[7,\"form\",true],[10,\"id\",\"crm-form\"],[10,\"method\",\"POST\"],[11,\"action\",[22,\"formAction\"]],[10,\"target\",\"_blank\"],[8],[0,\"\\n      \"],[7,\"input\",true],[10,\"name\",\"token\"],[11,\"value\",[22,\"authToken\"]],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/manage-subscription.hbs"
    }
  });

  _exports.default = _default;
});