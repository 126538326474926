define("herer-web/controllers/enterprise-overview", ["exports", "big.js", "lodash/map", "lodash/min", "lodash/max", "herer-web/utils/date-parsing", "herer-web/utils/dates-intervals", "herer-web/mixins/context", "herer-web/constants", "moment"], function (_exports, _big, _map, _min, _max, _dateParsing, _datesIntervals, _context, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    queryParams: ['date'],
    reportStore: Ember.inject.service(),
    http: Ember.inject.service(),
    date: (0, _moment.default)(),
    customerFilterType: _constants.default.customerFilterType.none,
    interval: '30-days',
    groupBy: 'day',
    actions: {
      changeDate: function changeDate(date) {
        var stringDate = date;

        if (date) {
          stringDate = (0, _dateParsing.dateToString)(date, 'YYYY-MM-DD', {
            timezone: (0, _dateParsing.browserTimezone)()
          });
        } else {
          stringDate = null;
        }

        this.set('date', stringDate);
        this.loadSalesByLocationReport();
        this.loadDailySalesReport();
        this.loadDailyCustomersReport();
      },
      loadSalesByLocationReport: function loadSalesByLocationReport(hideLoading) {
        this.loadSalesByLocationReport(hideLoading);
      },
      loadDailySalesReport: function loadDailySalesReport(hideLoading) {
        this.loadDailySalesReport(hideLoading);
      },
      loadDailyCustomersReport: function loadDailyCustomersReport(hideLoading) {
        this.loadDailyCustomersReport(hideLoading);
      },
      changeInterval: function changeInterval(interval) {
        this.set('interval', interval);
      },
      changeGroupBy: function changeGroupBy(groupBy) {
        this.set('groupBy', groupBy);
      },
      changeCustomerFilterType: function changeCustomerFilterType(customerFilterType) {
        this.set('customerFilterType', customerFilterType);
      }
    },
    loadSalesByLocationReport: function loadSalesByLocationReport(hideLoading) {
      var _this = this;

      if (!hideLoading) {
        this.set('salesByLocationReportLoading', true);
        this.set('salesByLocationReportFailed', false);
      }

      var reportStore = this.get('reportStore');
      var dates = (0, _datesIntervals.datesFromInterval)(this.get('interval'), this.get('date'));
      Ember.RSVP.all(this.get('sortedLocations').map(function (location) {
        return reportStore.fetchReport({
          startDate: dates.startDate.format('YYYY-MM-DD'),
          endDate: dates.endDate.format('YYYY-MM-DD'),
          measure: 'dollars',
          report: 'location',
          groupBy: _this.get('groupBy'),
          locationId: location.get('id')
        });
      })).then(function (locationReports) {
        _this.set('salesByLocationData', locationReports.map(function (report) {
          return report.table;
        }));
      }).catch(function () {
        _this.set('salesByLocationReportFailed', true);
      }).finally(function () {
        if (!hideLoading) {
          _this.set('salesByLocationReportLoading', false);
        }
      });
    },
    loadDailySalesReport: function loadDailySalesReport(hideLoading) {
      var _this2 = this;

      if (!hideLoading) {
        this.set('dailySalesReportLoading', true);
        this.set('dailySalesReportFailed', false);
      }

      var reportStore = this.get('reportStore');
      var dates = (0, _datesIntervals.datesFromInterval)('today', this.get('date'));
      return reportStore.fetchReport({
        startDate: dates.startDate.format('YYYY-MM-DD'),
        endDate: dates.endDate.format('YYYY-MM-DD'),
        measure: 'dollars',
        report: 'location',
        byAccount: true
      }).then(function (report) {
        var sourceData = report.table.sourceData;

        var data = _this2.get('sortedLocations').map(function (location) {
          var row = sourceData.find(function (r) {
            return r.location === location.get('name');
          });
          var sales;

          if (Ember.isPresent(row)) {
            sales = new _big.default(row.sales);
          } else {
            sales = new _big.default(0);
          }

          return {
            name: location.get('name'),
            sales: sales,
            color: location.get('color')
          };
        });

        var sales = (0, _map.default)(data, function (r) {
          return parseFloat(r.sales);
        });
        var minSales = new _big.default((0, _min.default)(sales));
        var maxSales = new _big.default((0, _max.default)(sales));
        var range = maxSales.minus(minSales);
        var colorA = '#e9f2ec';
        data = data.map(function (location) {
          var amount;

          if (parseFloat(range) === 0) {
            amount = new _big.default(0);
          } else {
            amount = location.sales.minus(minSales).div(range);
          }

          location.color = _this2._lerpColor(colorA, location.color, amount);
          location.textColor = amount.gt(new _big.default(0.4)) ? '#ffffff' : '#555';
          return location;
        });

        _this2.set('dailySalesData', data);
      }).catch(function (e) {
        console.log('Loading report failed');
        console.log(e);

        _this2.set('dailySalesReportFailed', true);
      }).finally(function () {
        if (!hideLoading) {
          _this2.set('dailySalesReportLoading', false);
        }
      });
    },
    loadDailyCustomersReport: function loadDailyCustomersReport(hideLoading) {
      var _this3 = this;

      var http = Ember.get(this, 'http');

      if (!hideLoading) {
        this.set('dailyCustomersReportLoading', true);
        this.set('dailyCustomersReportFailed', false);
      }

      var dates = (0, _datesIntervals.datesFromInterval)('today', this.get('reportDate'));
      var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/customers"), {
        start_date: dates.startDate.toISOString(),
        end_date: dates.endDate.toISOString(),
        filter_type: this.get('customerFilterType')
      });
      return http.get(url).then(function (response) {
        var data = response.data;

        var _data = _this3.get('sortedLocations').map(function (location) {
          return {
            name: location.get('name'),
            customers: data[location.get('id')],
            color: location.get('color')
          };
        });

        var customerCounts = Object.values(data).map(function (c) {
          return c.length;
        });
        var minCustomers = new _big.default((0, _min.default)(customerCounts));
        var maxCustomers = new _big.default((0, _max.default)(customerCounts));
        var range = maxCustomers.minus(minCustomers);
        var colorA = '#e9f2ec';
        _data = _data.map(function (location) {
          var amount;

          if (parseFloat(range) === 0) {
            amount = new _big.default(0);
          } else {
            var customers = new _big.default(location.customers.length);
            amount = customers.minus(minCustomers).div(range);
          }

          location.color = _this3._lerpColor(colorA, location.color, amount);
          location.textColor = amount.gt(new _big.default(0.4)) ? '#fff' : '#555';
          return location;
        });

        _this3.set('dailyCustomersData', _data);
      }).catch(function (e) {
        console.log('Loading report failed');
        console.log(e);

        _this3.set('dailyCustomersReportFailed', true);
      }).finally(function () {
        if (!hideLoading) {
          _this3.set('dailyCustomersReportLoading', false);
        }
      });
    },
    _lerpColor: function _lerpColor(a, b, amount) {
      var ah = parseInt(a.replace(/#/g, ''), 16),
          ar = ah >> 16,
          ag = ah >> 8 & 0xff,
          ab = ah & 0xff,
          bh = parseInt(b.replace(/#/g, ''), 16),
          br = bh >> 16,
          bg = bh >> 8 & 0xff,
          bb = bh & 0xff,
          rr = ar + amount * (br - ar),
          rg = ag + amount * (bg - ag),
          rb = ab + amount * (bb - ab);
      return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
    }
  });

  _exports.default = _default;
});