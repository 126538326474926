define("herer-web/controllers/drawer-adjustments", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/context", "lodash/clone"], function (_exports, _pageable, _context, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageable.default, _context.default, {
    queryParams: ['receiptId', 'shiftId', 'type'],
    types: [{
      name: 'Paid In',
      value: 'in'
    }, {
      name: 'Paid Out',
      value: 'out'
    }],
    receiptId: null,
    shiftId: null,
    shift: null,
    type: null,
    showNewDrawerAdjustment: false,
    showNewDrawAdjustmentButton: Ember.computed('shift', 'permissionsManager', function () {
      var hasShift = !!this.get('shift');
      var hasPermission = this.get('permissionsManager').verifyForced('create_paid_in_and_out');
      return hasShift && hasPermission;
    }),
    addButtonText: Ember.computed('type', function () {
      return this.get('type') === 'out' ? 'Add Paid Out' : 'Add Paid In';
    }),
    pageName: Ember.computed('type', 'receiptId', function () {
      var typeString = this.get('type') === 'out' ? 'Paid Out' : 'Paid In';
      return [typeString, 'for Shift', this.get('receiptId')].join(' ');
    }),
    availableFilters: Ember.computed('types', 'type', function () {
      return [{
        label: 'Type',
        type: 'select',
        query: 'type',
        options: this.get('types'),
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: true,
        forbidRemove: true,
        defaultValue: this.get('type')
      }];
    }),
    shiftChanged: function shiftChanged() {
      console.info('currentShiftDidChange');
      var shift = this.get('shift');

      if (Ember.isPresent(shift)) {
        this.set('showNewDrawerAdjustment', false);
      }
    },
    actions: {
      createDrawerAdjustment: function createDrawerAdjustment(obj, cb) {
        var drawerAdjustment = this.store.createRecord('drawer-adjustment', {
          amount: obj.amount,
          note: obj.note,
          drawer_adjustment_type: obj.drawer_adjustment_type,
          receipt_id: obj.receipt_id,
          opened_at: obj.opened_at,
          shift_id: obj.shift_id
        });
        drawerAdjustment.save().then(cb, cb);
      },
      addNewDrawerAdjustment: function addNewDrawerAdjustment() {
        this.set('showNewDrawerAdjustment', true);
      },
      removeNewDrawerAdjustment: function removeNewDrawerAdjustment(refetch) {
        this.set('showNewDrawerAdjustment', false);

        if (refetch) {
          this.get('model').fetchObjects();
        }
      },
      filterParamsChanged: function filterParamsChanged(filterParams) {
        var filters = (0, _clone.default)(filterParams);
        filters['by_shift'] = this.get('shiftId');
        filters['type_id'] = this.get('types').findIndex(function (type) {
          return type.value === filters['type'];
        });
        filters['by_type'] = filters['type'];
        this.set('type', filters['type']);
        delete filters['type'];
        this.set('model.filters', filters);
      },
      refetch: function refetch() {
        this.get('model').fetchObjects();
      }
    }
  });

  _exports.default = _default;
});