define("herer-web/components/table-filter", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterValue: null,
    availableFilters: [],
    disabled: false,
    optionSelectedAction: null,
    valueChangedAction: null,
    removeFilterAction: null,
    defaultLabel: '',
    useButtonLabel: true,
    weightUnits: _constants.default.weightUnits,
    availableComparators: [{
      value: '<',
      label: 'Less than'
    }, {
      value: '<=',
      label: 'Less than or equal to'
    }, {
      value: '=',
      label: 'Equal to'
    }, {
      value: '>=',
      label: 'Greater than or equal to'
    }, {
      value: '>',
      label: 'Greater than'
    }, {
      value: 'between',
      label: 'Between'
    }],
    comparators: Ember.computed('filterValue.acceptedComparators', 'availableComparators', function () {
      var availableComparators = this.get('availableComparators');
      var acceptedComparators = this.get('filterValue.acceptedComparators');
      return availableComparators.filter(function (comparator) {
        return acceptedComparators.indexOf(comparator.value) >= 0;
      });
    }),
    selectedFilter: Ember.computed.alias('filterValue.filter'),
    focusFilterInput: function focusFilterInput() {
      var _this = this;

      Ember.run.next(function () {
        _this.$('.filter-input:first').focus();
      });
    },
    onFilterChanged: function onFilterChanged() {
      this.valueChangedAction && this.valueChangedAction();
    },
    useTooltip: Ember.computed('filterValue.filter.tooltip', function () {
      return !!this.get('filterValue.filter.tooltip');
    }),
    actions: {
      selectFilter: function selectFilter(filter) {
        if (this.get('disabled')) {
          return;
        }

        this.set('filterValue.value', null);
        this.set('filterValue.secondValue', null);
        this.set('filterValue.filter', filter);
        this.focusFilterInput();

        if (this.get('filterSelectedAction')) {
          this.filterSelectedAction();
        }
      },
      removeFilter: function removeFilter() {
        this.removeFilterAction(this.get('filterValue'));
      },
      changeComparator: function changeComparator(comparator) {
        if (this.get('disabled')) {
          return;
        }

        this.set('filterValue.comparator', comparator);
        this.focusFilterInput();
        this.onFilterChanged();
      },
      setFilterValue: function setFilterValue(value) {
        this.set('filterValue.value', value);
        this.onFilterChanged();
      },
      setFilterSecondValue: function setFilterSecondValue(value) {
        this.set('filterValue.secondValue', value);
        this.onFilterChanged();
      },
      setDateFilterValue: function setDateFilterValue(date) {
        this.set('filterValue.value', date);
        this.onFilterChanged();
      },
      setDateFilterSecondValue: function setDateFilterSecondValue(date) {
        this.set('filterValue.secondValue', date);
        this.onFilterChanged();
      },
      addTag: function addTag(tag) {
        var tags = this.get('filterValue.tags');
        this.set('filterValue.tags', tags.concat(tag));
        this.onFilterChanged();
      },
      removeTag: function removeTag(tag) {
        var tags = this.get('filterValue.tags');
        var filteredTags = tags.filter(function (item) {
          return item !== tag;
        });
        this.set('filterValue.tags', filteredTags);
        this.onFilterChanged();
      }
    }
  });

  _exports.default = _default;
});