define("herer-web/models/return-manifest", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _model, _emberDataModelFragments, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var returnManifestType = _constants.default.returnManifestType;

  var _default = _model.default.extend({
    return_manifest_type: (0, _model.attr)('number'),
    manifest_id: (0, _model.attr)('string'),
    arrival_date: (0, _model.attr)('date'),
    departure_date: (0, _model.attr)('date'),
    dest_address: (0, _model.attr)('string'),
    dest_license: (0, _model.attr)('string'),
    dest_name: (0, _model.attr)('string'),
    dest_phone: (0, _model.attr)('string'),
    dest_email: (0, _model.attr)('string'),
    courier_name: (0, _model.attr)('string'),
    courier_license: (0, _model.attr)('string'),
    directions: (0, _model.attr)('string'),
    licensee_address: (0, _model.attr)('string'),
    licensee_name: (0, _model.attr)('string'),
    licensee_phone: (0, _model.attr)('string'),
    licensee_ubi: (0, _model.attr)('string'),
    manifest_date: (0, _model.attr)('date'),
    notes: (0, _model.attr)('string'),
    transferred_at: (0, _model.attr)('date'),
    employee: _emberDataModelFragments.default.fragment('driver'),
    vehicle: _emberDataModelFragments.default.fragment('vehicle'),
    lots: _emberDataModelFragments.default.fragmentArray('return_lot', {
      defaultValue: []
    }),
    pickup: Ember.computed('return_manifest_type', function () {
      return this.get('return_manifest_type') === returnManifestType.pickup;
    }),
    courier: Ember.computed('return_manifest_type', function () {
      return this.get('return_manifest_type') === returnManifestType.courier;
    }),
    validations: (0, _validation.validations)({
      manifest_id: (0, _yup.string)().required(),
      return_manifest_type: (0, _yup.string)().required(),
      departure_date: (0, _yup.string)().required(),
      dest_name: (0, _yup.string)().required(),
      dest_license: (0, _yup.string)().required(),
      dest_address: (0, _yup.string)().required(),
      dest_phone: (0, _yup.string)().required(),
      dest_email: (0, _yup.string)().required().email(),
      courier_name: (0, _yup.string)().nullable().when('courier', {
        is: true,
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)().nullable()
      }),
      courier_license: (0, _yup.string)().nullable().when('courier', {
        is: true,
        then: (0, _yup.string)().required(),
        otherwise: (0, _yup.string)().nullable()
      })
    })
  });

  _exports.default = _default;
});