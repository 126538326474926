define("herer-web/controllers/labels", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    toast: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.get('errors').clear();
        model.save().then(function () {
          _this.get('toast').success('Label settings saved!');
        }).catch(function () {
          _this.get('toast').error('There was a problem saving your label settings.');
        });
      },
      removeLogo: function removeLogo() {
        this.set('model.logo_file_name', null);
        this.set('model.logo_url', null);
      }
    }
  });

  _exports.default = _default;
});