define("herer-web/helpers/format-percent", ["exports", "underscore.string/insert", "underscore.string/ltrim", "underscore.string/rtrim"], function (_exports, _insert, _ltrim, _rtrim) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatPercent = void 0;

  var formatPercent = function formatPercent(value) {
    value = value || 0;
    value = parseFloat(value).toString();
    var dotPosition = value.indexOf('.');
    value = value.replace('.', '');

    if (dotPosition > 0 && value.length > dotPosition + 2) {
      value = (0, _insert.default)(value, dotPosition + 2, '.');
    } else if (dotPosition == -1) {
      value += '00';
    } else {
      while (dotPosition + 2 > value.length) {
        value += '0';
      }
    }

    value = (0, _ltrim.default)(value, '0');
    if (value[0] === '.') value = '0' + value;
    value = (0, _rtrim.default)(value, '.');
    return "".concat(value, "%");
  };

  _exports.formatPercent = formatPercent;
  var FormatPercentHelper = Ember.Helper.helper(function (params) {
    return formatPercent(params[0]);
  });
  var _default = FormatPercentHelper;
  _exports.default = _default;
});