define("herer-web/templates/qs-reports/paidinout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c9KeAM9T",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Paid In/Out Report\",\"The Paid In/Out Report lists when cash was added or removed from your Registers.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887244014995-Manage-the-cash-in-your-drawer-using-Paid-In-and-Paid-Out\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"paid_in_out_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"PaidInOutReport\",\"paid_in_out_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/paidinout.hbs"
    }
  });

  _exports.default = _default;
});