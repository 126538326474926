define("herer-web/routes/shifts", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    sideNavTemplateName: 'reports-nav',
    modelName: 'shift',
    queryParams: {
      status: {
        refreshModel: false
      },
      register: {
        refreshModel: false
      },
      receipt_id: {
        refreshModel: false
      },
      employee: {
        refreshModel: false
      }
    }
  });

  _exports.default = _default;
});