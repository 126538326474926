define("herer-web/models/sales-report", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    sales: Ember.computed('mj_sales_total', 'p_sales_total', function () {
      return this.get('mj_sales_total') + this.get('p_sales_total');
    }),
    discounts: Ember.computed('mj_discounts_total', 'p_discounts_total', function () {
      return this.get('mj_discounts_total') + this.get('p_discounts_total');
    }),
    returns: Ember.computed('mj_returns_total', 'p_returns_total', function () {
      return this.get('mj_returns_total') + this.get('p_returns_total');
    }),
    net_sales: Ember.computed('sales', 'discounts', 'returns', function () {
      return this.get('sales') + this.get('discounts') + this.get('returns');
    }),
    cash_tendered: Ember.computed('payments', function () {
      return this.get('payments')[_constants.default.paymentType.cash] || 0;
    }),
    debit_tendered: Ember.computed('payments', function () {
      return this.get('payments')[_constants.default.paymentType.debit] || 0;
    }),
    cashless_atm_tendered: Ember.computed('payments', function () {
      return this.get('payments')[_constants.default.paymentType.cashlessAtm] || 0;
    }),
    gift_card_tendered: Ember.computed('payments', function () {
      return this.get('payments')[_constants.default.paymentType.giftCard] || 0;
    }),
    credit_tendered: Ember.computed('payments', function () {
      return this.get('payments')[_constants.default.paymentType.credit] || 0;
    }),
    mj_net_total: Ember.computed('mj_sales_total', 'mj_discounts_total', 'mj_returns_total', function () {
      return this.get('mj_sales_total') + this.get('mj_discounts_total') + this.get('mj_returns_total');
    }),
    p_net_total: Ember.computed('p_sales_total', 'p_discounts_total', 'p_returns_total', function () {
      return this.get('p_sales_total') + this.get('p_discounts_total') + this.get('p_returns_total');
    }),
    paid_out_flipped: Ember.computed('paid_out', function () {
      return this.get('paid_out') * -1;
    }),
    close_drop_flipped: Ember.computed('close_drop', function () {
      return this.get('close_drop') * -1;
    }),
    closing_drop: Ember.computed('close_drop', function () {
      return this.get('close_drop') * -1;
    }),
    excise_tax_flipped: Ember.computed('excise_tax', function () {
      return this.get('excise_tax') * -1;
    }),
    net_sales_minus_excise_tax: Ember.computed('net_sales', 'excise_tax', function () {
      return this.get('net_sales') - this.get('excise_tax');
    })
  });

  _exports.default = _default;
});