define("herer-web/templates/components/packages/package-reconciler/reconciliation-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cix+QZfP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"data-test-text\",\"modal_title\"],[8],[0,\"\\n    Package Reconciliation Failed\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Packages cannot be reconciled at this time.\\n  \"],[9],[0,\"\\n  This could be because of issues with your internet or with Metrc. Try again in a few minutes.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn-retry btn btn-default text-left petrol-green-700-background\"],[12,\"data-test-action\",\"retry\"],[3,\"action\",[[23,0,[]],[24,[\"onRetry\"]]]],[8],[0,\"\\n    Retry\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"data-test-action\",\"cancel\"],[3,\"action\",[[23,0,[]],[24,[\"onCancel\"]]]],[8],[0,\"\\n    Cancel\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package-reconciler/reconciliation-error.hbs"
    }
  });

  _exports.default = _default;
});