define("herer-web/models/leafly-menu-groups", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var halfGram = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 0.5,
    street: true
  });

  var oneGram = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 1,
    street: true
  });

  var twoGrams = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 2,
    street: true
  });

  var eighthOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 3.5,
    street: true
  });

  var quarterOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 7,
    street: true
  });

  var halfOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 14,
    street: true
  });

  var ounce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 28,
    street: true
  });

  var each = _weight.default.create({
    unit: _constants.default.weightUnit.unit,
    value: 1,
    street: true
  });

  var productName = '{{name}}';
  var _default = [{
    name: 'Flower',
    prices: [halfGram, oneGram, twoGrams, eighthOunce, quarterOunce, halfOunce, ounce],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: '{{strain}} by {{brand}}',
    productTypeName: 'Flower'
  }, {
    name: 'Clone',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Clone'
  }, {
    name: 'PreRoll',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Pre-Roll'
  }, {
    name: 'Seeds',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Edible',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Edible (Solid)'
  }, {
    name: 'Concentrate',
    prices: [halfGram, oneGram, twoGrams],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: '{{strain}} {{concentrate_type}} by {{brand}}',
    productTypeName: 'Concentrate'
  }, {
    name: 'Other',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }];
  _exports.default = _default;
});