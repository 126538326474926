define("herer-web/components/slide-out-drawer", ["exports", "lodash/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    show: false,
    // Callback to invoke when a user attempts to hide the drawer. Use this for
    // pre hide validations.  You need to explicitly invoke `hide` from within
    // this callback if you want to hide.
    onAttemptedToHideDrawer: undefined,
    // Callback invoked when a user successfully to hides the drawer.
    afterHideDrawer: undefined,
    // Set this so we can debounce
    resizeHandler: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.set('resizeHandler', this.get('handleResize').bind(this));
      $(window).on('resize', (0, _debounce.default)(this.get('resizeHandler'), 100));
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      $(window).off('resize', this.get('resizeHandler'));
    },
    // Resize content of drawer to be scrollable and allow
    // drawer header to remain static so buttons don't get hidden.
    // I think we can refactor this and the resize code away with some flexbox
    // on the drawer-scroll container
    handleResize: function handleResize() {
      var scrollContent = this.$('.drawer-scroll-content'); // noop if no scroll content

      if (!scrollContent || scrollContent.length === 0) {
        return;
      }

      var windowHeight = this.$(window).innerHeight();
      var drawerContainer = this.$('.drawer-content');
      drawerContainer.css({
        overflow: 'hidden'
      });
      drawerContainer.height(windowHeight);
      var scrollableContentTopOffset = scrollContent.filter(':visible').position().top;
      var scrollHeight = windowHeight - scrollableContentTopOffset;
      scrollContent.css({
        height: scrollHeight
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.get('show')) {
        this.showDrawer();
      } else {
        this.hideDrawer();
      }
    },
    showDrawer: function showDrawer() {
      $('body').addClass('show-details-panel');
      this.$('.table-details-panel').show().addClass('show-panel');
      Ember.run.scheduleOnce('afterRender', this, 'handleResize');
    },
    hideDrawer: function hideDrawer() {
      $('body').removeClass('show-details-panel');
      this.$('.table-details-panel').hide().removeClass('show-panel');
      this.afterHideDrawer && this.afterHideDrawer();
    },
    actions: {
      attemptedToHideDrawer: function attemptedToHideDrawer() {
        this.onAttemptedToHideDrawer && this.onAttemptedToHideDrawer();
      },
      showDrawer: function showDrawer() {
        this.showDrawer();
      },
      hideDrawer: function hideDrawer() {
        this.hideDrawer();
      }
    }
  });

  _exports.default = _default;
});