define("herer-web/controllers/manifests", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants", "herer-web/mixins/pageable", "bootbox", "moment"], function (_exports, _alertUnsavedController, _context, _constants, _pageable, _bootbox, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, _pageable.default, {
    featureManager: Ember.inject.service(),
    pushManager: Ember.inject.service(),
    stateLinkStatusUpdater: Ember.inject.service(),
    http: Ember.inject.service(),
    requestedManifestSyncAt: null,
    queryParams: ['status', 'sku', 'shipment_id', 'supplier'],
    status: 0,
    sku: '',
    shipment_id: '',
    supplier: '',
    lastIncomingInventorySync: Ember.computed.alias('stateLinkStatusUpdater.lastIncomingInventorySync'),
    lastIncomingInventorySyncInWords: Ember.computed.alias('stateLinkStatusUpdater.lastIncomingInventorySyncInWords'),
    canSync: Ember.computed('currentLocation.active', 'currentLocation.license', function () {
      var featureManager = this.get('featureManager');
      var currentLocation = this.get('currentLocation');
      return currentLocation.get('active') && featureManager.can('traceability');
    }),
    waitingForManifests: Ember.computed('lastIncomingInventorySync', 'requestedManifestSyncAt', function () {
      if (this.get('requestedManifestSyncAt') === null) {
        return false;
      }

      var requestedAt = (0, _moment.default)(this.get('requestedManifestSyncAt')); // Add a few seconds to account for local dev machine variations

      var lastSync = (0, _moment.default)(this.get('lastIncomingInventorySync')).add(10, 's');
      console.log(requestedAt);
      console.log(lastSync);
      return requestedAt.isAfter(lastSync);
    }),
    hasNoManifests: Ember.computed('isLoading', 'model.[]', function () {
      return !this.get('isLoading') && !this.get('model.length');
    }),
    manifestMessage: Ember.computed('canSync', 'waitingForManifests', 'lastIncomingInventorySyncInWords', 'model.[]', function () {
      var messages = [];

      if (!this.get('model.length')) {
        messages.push('No Manifests Found.');
      }

      if (this.get('canSync')) {
        if (this.get('waitingForManifests')) {
          messages.push('Checking State Traceability for new Manifests…');
        } else {
          messages.push("Last Manifest Sync was ".concat(this.get('lastIncomingInventorySyncInWords'), "."));
        }
      }

      return messages.join('\n');
    }),
    remoteManifestStatuses: Ember.computed(function () {
      var statuses = _constants.default.remoteManifestStatuses;

      if (!this.get('featureManager').can('accept-manifests')) {
        statuses = statuses.slice(1, statuses.length - 1);
      }

      return statuses;
    }),
    showTableMessages: Ember.computed('hasNoManifests', 'status', function () {
      return this.get('hasNoManifests') || this.get('canSync');
    }),
    routeForManifest: function routeForManifest(manifest) {
      if (manifest.get('pending')) {
        return 'accept-manifest';
      } else if (manifest.get('accepted')) {
        return 'manifest';
      } else {
        return 'remote-manifest';
      }
    },
    watchForNewRemoteManifests: function watchForNewRemoteManifests() {
      var _this = this;

      if (this.get('waitingForManifests') === true) {
        var timeoutSeconds = 5;
        setTimeout(function () {
          _this.watchForNewRemoteManifests();
        }, timeoutSeconds * 1000);
      } else {
        this.send('reload');
        return;
      }
    },
    setSupplier: function setSupplier(value) {
      this.set('supplier', value);
    },
    setShipmentId: function setShipmentId(value) {
      this.set('shipment_id', value);
    },
    actions: {
      reload: function reload() {
        this.get('model').fetchObjects();
      },
      viewManifest: function viewManifest(manifest) {
        this.transitionToRoute(this.routeForManifest(manifest), manifest.get('id'));
      },
      clearSku: function clearSku() {
        return this.set('sku', '');
      },
      setStatus: function setStatus(value) {
        this.set('status', value);
      },
      setSupplier: function setSupplier(value) {
        Ember.run.debounce(this, this.setSupplier, value, 400);
      },
      setShipmentId: function setShipmentId(value) {
        Ember.run.debounce(this, this.setShipmentId, value, 400);
      },
      showSkuModal: function showSkuModal() {
        var _this2 = this;

        return _bootbox.default.prompt({
          title: 'Find Accepted SKU',
          value: this.get('sku'),
          callback: function callback(sku) {
            if (Ember.isPresent(sku)) {
              _this2.set('sku', sku);
            }
          }
        });
      },
      sync: function sync() {
        var _this3 = this;

        this.set('requestedManifestSyncAt', new Date());
        this.get('http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/remote_manifests/refresh")).then(function () {
          return _this3.watchForNewRemoteManifests();
        });
        var message = "Please be patient while we check for new manifests in the state system. Once\n        a new manifest is found this page will automatically update.";
        return _bootbox.default.dialog({
          title: 'Syncing...',
          message: message,
          buttons: {
            ok: {
              label: 'OK',
              className: 'btn-default'
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});