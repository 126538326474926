define("herer-web/components/hourly-performance-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants", "moment"], function (_exports, _dimpleChart, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    width: Ember.computed(function () {
      return this.$().width();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var height = Math.round(this.get('width') * 0.3);
      this.$().height(height);
    },
    remap: function remap(data) {
      var _this = this;

      var newData = [];
      data.average.source_data.forEach(function (row, index) {
        var seriesName = "Average ".concat((0, _moment.default)(row.day).format('dddd'));
        newData.push(_this._configureRowForSeries(row, index, seriesName, 'sales_average'));
      });
      data.today.source_data.forEach(function (row, index) {
        newData.push(_this._configureRowForSeries(row, index, 'Today'));
      });
      return newData;
    },
    customizeChart: function customizeChart(chart) {
      var width = this.get('width');
      var w = (width - 320) / (1110 - 320);
      var iX = d3.interpolateNumber(15, 5);
      var iY = d3.interpolateNumber(10, 5);
      var iWidth = d3.interpolateNumber(80, 90);
      var iHeight = d3.interpolateNumber(70, 75);
      var iLegend = d3.interpolateNumber(70, 25);
      var iTicks = d3.interpolateNumber(3, 10);
      chart.setBounds("".concat(iX(w), "%"), "".concat(iY(w), "%"), "".concat(iWidth(w), "%"), "".concat(iHeight(w), "%"));
      chart.addLegend("".concat(95 - iLegend(w), "%"), 0, "".concat(iLegend(w), "%"), 20, 'right');
      chart.ease = 'bounce';
      chart.defaultColors = _constants.default.chartColors;
      var y = chart.addMeasureAxis('y', 'Sales');
      y.showGridlines = false;
      y.title = '';
      y.fontSize = '12px';
      y.ticks = parseInt(iTicks(w));
      y.tickFormat = '$,.2s';
      var x = chart.addTimeAxis('x', 'Hour', '%-I%p', '%-I%p');
      x.showGridlines = false;
      x.title = '';
      x.fontSize = '12px';
      var lastWeekSeries = chart.addSeries('Series', dimple.plot.area);
      lastWeekSeries.data = this.get('_data').filter(function (d) {
        return d.Series !== 'Today';
      });
      lastWeekSeries.interpolation = 'cardinal';
      lastWeekSeries.lineMarkers = true;
      var series = chart.addSeries('Series', dimple.plot.area);
      series.data = this.get('_data').filter(function (d) {
        return d.Series === 'Today';
      });
      series.interpolation = 'cardinal';
      series.lineMarkers = true;
      return chart;
    },
    _configureRowForSeries: function _configureRowForSeries(row, index, seriesName, fieldForSales) {
      if (!fieldForSales) {
        fieldForSales = 'sales';
      }

      row.Series = seriesName;
      row.index = index;
      row.Sales = row[fieldForSales];
      row.Hour = "".concat(row.hour, "m").toUpperCase();
      return row;
    }
  });

  _exports.default = _default;
});