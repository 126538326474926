define("herer-web/templates/components/missing-thc-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JkMn8VVc",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        These sales failed to report due to product missing THC value\\n\\n        \"],[5,\"tool-tip\",[],[[],[]],{\"statements\":[[0,\"\\n          \"],[7,\"span\",true],[10,\"data-toggle\",\"tooltip\"],[10,\"title\",\"The sale failed to submit to Metrc because some products in this sale did not have a THC value setup on the product. Fix the products to have a THC value and then the sale we attempt to repost.\"],[8],[0,\"\\n              \"],[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row header-row table-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"\\n          Failed Order\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"\\n          Product\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notification\",\"items\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"missing-thc-notification-row\",null,[[\"notification\",\"data-test-item\"],[[23,1,[]],\"notification\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/missing-thc-notification.hbs"
    }
  });

  _exports.default = _default;
});