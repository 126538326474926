define("herer-web/controllers/reorder-report", ["exports", "herer-web/mixins/taskable", "herer-web/constants", "bootbox"], function (_exports, _taskable, _constants, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_taskable.default, {
    downloader: Ember.inject.service(),
    http: Ember.inject.service(),
    startDate: null,
    endDate: null,
    filters: null,
    isExportDisabled: false,
    reportExportFailed: function reportExportFailed() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to run your report. " + "If you still have this issue, chat Support.");
    },
    actions: {
      exportReport: function exportReport(filetype) {
        var _this = this;

        var http = Ember.get(this, 'http');
        this.set('isExportDisabled', true);
        var params = {
          start_date: this.get('startDate'),
          end_date: this.get('endDate'),
          filetype: filetype,
          filters: this.get('filters')
        };
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/days_sales_inventory_report"), params);
        return http.get(url).then(function (response) {
          _this.set('isExportDisabled', false);

          _this.set('task', response.data.task);

          _this.get('taskManager').poll();

          _this.pollTask();
        }).catch(function () {
          _this.set('isSubmitDisabled', false);

          return _this.reportExportFailed();
        });
      },
      clear: function clear() {
        this.clearTaskTimer();
        this.set('task', null);
      },
      download: function download() {
        this.get('downloader').downloadFile(this.get('task.result.url'));
        this.get('taskManager').claim(this.get('task.id'));
        this.send('clear');
      },
      updateDates: function updateDates(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);
      },
      updateFilters: function updateFilters(filters) {
        this.set('filters', filters);
      }
    }
  });

  _exports.default = _default;
});