define("herer-web/services/downloader", ["exports", "lodash/isUndefined"], function (_exports, _isUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    downloadFile: function downloadFile(url) {
      var link = document.createElement('a');

      if (!(0, _isUndefined.default)(link.download)) {
        // Browsers that support the download attr
        link.setAttribute('href', url);
        link.setAttribute('download', this.filenameFromURL(url));
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(url);
      }
    },
    filenameFromURL: function filenameFromURL(url) {
      var parts = url.split('/');
      return parts[parts.length - 1];
    }
  });

  _exports.default = _default;
});