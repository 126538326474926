define("herer-web/components/task-progress-button", ["exports", "herer-web/mixins/taskable", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _taskable, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_taskable.default, _context.default, {
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    http: Ember.inject.service(),
    apiPath: null,
    taskName: null,
    title: null,
    icon: null,
    message: null,
    isLoading: false,
    progressBarWidth: Ember.computed('task.percent_complete', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('task.percent_complete'), "%;"));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'findExistingTask');
    },
    findExistingTask: function findExistingTask() {
      var _this = this;

      this.set('isLoading', true);
      this.get('store').findAll('task').then(function (tasks) {
        tasks.forEach(function (task) {
          var processing = task.get('queued') || task.get('processing');
          var taskLocationId = task.get('location_id');

          var currentLocationId = _this.get('currentLocation.id');

          var sameLocation = Ember.isEmpty(taskLocationId) || taskLocationId === currentLocationId;

          if (processing && task.get('name') === _this.get('taskName') && sameLocation) {
            _this.set('task', task);

            _this.pollTask();
          }
        });

        _this.set('isLoading', false);
      });
    },
    actions: {
      submit: function submit() {
        var _this2 = this;

        this.set('isLoading', true);
        return Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(this.get('apiPath'))).then(function (response) {
          _this2.set('isLoading', false);

          _this2.set('task', response.data.task);

          _this2.get('taskManager').poll();

          _this2.pollTask();
        }).catch(function () {
          _this2.set('isLoading', false);

          _this2.get('toast').error("Failed to start ".concat(_this2.get('taskName'), "."));
        });
      },
      retry: function retry() {
        var _this3 = this;

        this.set('isLoading', true);
        this.get('taskManager').retry(this.get('task.id')).then(function (task) {
          _this3.set('isLoading', false);

          _this3.set('task', task);

          _this3.pollTask();
        });
      }
    }
  });

  _exports.default = _default;
});