define("herer-web/models/system-status-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // SystemStatusNotice is a value object that's useful
  // for displaying messages to customers.
  //
  // It's used for Incidents 
  var _default = Ember.Object.extend({
    // Used for dismissing notices and information
    id: null,
    // A URL behind a 'More Info' link
    link: null,
    impact: null,
    // Text that shows to customer
    message: '',
    // Dates for maintenance notice.
    start_at: null,
    end_at: null
  });

  _exports.default = _default;
});