define("herer-web/templates/components/product-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PyYKrVxQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"product\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"title\",\"readonly\",\"class\",\"data-test-input\"],[\"text\",[24,[\"productDisplayName\"]],[24,[\"productDisplayName\"]],true,\"form-control\",\"product_name\"]]],false],[7,\"div\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"tabindex\",\"-1\"],[12,\"disabled\",[22,\"disabled\"]],[12,\"class\",\"input-group-addon-fixed btn btn-default\"],[12,\"data-test-action\",\"search\"],[3,\"action\",[[23,0,[]],\"search\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-search\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n          Search\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[22,\"disabled\"]],[12,\"class\",[29,[\"btn btn-block \",[28,\"if\",[[24,[\"hasError\"]],\"btn-danger\",\"btn-default\"],null]]]],[12,\"data-test-action\",\"search\"],[3,\"action\",[[23,0,[]],\"search\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-search\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n      Search for products\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/product-input.hbs"
    }
  });

  _exports.default = _default;
});