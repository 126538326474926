define("herer-web/services/desktop-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasNotification: Ember.computed(function () {
      return window.Notification !== undefined;
    }),
    canNotify: Ember.computed(function () {
      return this.get('hasNotification') && Notification.permission === 'granted';
    }),
    init: function init() {
      this._super();

      if (this.get('hasNotification') && Notification.permission !== 'granted') {
        Notification.requestPermission(function () {});
      }
    },
    notify: function notify(title, message, callback) {
      if (!this.get('canNotify')) {
        return;
      }

      var notification = new Notification(title, {
        icon: '/img/icon_logo.png',
        body: message
      });

      if (callback) {
        notification.onclick = callback;
      }

      return notification;
    }
  });

  _exports.default = _default;
});