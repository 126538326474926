define("herer-web/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatDate = void 0;

  var formatDate = function formatDate(dateString) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!dateString) {
      return '';
    }

    var date = (0, _moment.default)(dateString);

    if (options.showRelative) {
      return date.calendar(null, {
        sameElse: 'M/D/YY h:mma'
      });
    }

    if (options.timezone) {
      date = date.tz(options.timezone);
    }

    if (options.startOf) {
      date = date.startOf(options.startOf);
    } else if (options.endOf) {
      date = date.endOf(options.endOf);
    }

    var dateFormat;

    if (options.date) {
      dateFormat = 'll';
    } else if (options.shortDateTime) {
      dateFormat = 'M/D/YY h:mma';
    } else if (options.dateFormat) {
      dateFormat = options.dateFormat;
    } else {
      dateFormat = 'lll';
    }

    return date.format(dateFormat);
  };

  _exports.formatDate = formatDate;

  var _default = Ember.Helper.helper(function (params, options) {
    return formatDate(params[0], options);
  });

  _exports.default = _default;
});