define("herer-web/controllers/lots/lot/history", ["exports", "herer-web/controllers/packages/package/history"], function (_exports, _history) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _history.default.extend({
    actions: {
      exit: function exit() {
        this.transitionToRoute('lots');
      }
    }
  });

  _exports.default = _default;
});