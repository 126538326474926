define("herer-web/components/reporting-failure-row", ["exports", "herer-web/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reportingFailure: null,
    store: Ember.inject.service(),
    classNames: ['row', 'table-row'],
    failureEvent: Ember.computed('reportingFailure.failureEvent', function () {
      return (0, _humanize.humanize)(this.get('reportingFailure.failureEvent'));
    }),
    actions: {
      showDetails: function showDetails() {
        this.$('.modal').modal();
      },
      hideDetails: function hideDetails() {
        this.$('.modal').modal('hide');
      },
      markResolved: function markResolved() {
        var _this = this;

        this.get('reportingFailure').resolve().then(function () {
          _this.$('.modal').modal('hide');

          _this.refresh();
        });
      }
    }
  });

  _exports.default = _default;
});