define("herer-web/services/report-store", ["exports", "herer-web/pivot-table/table", "herer-web/constants", "lodash/isUndefined", "moment", "herer-web/mixins/reports-comparable"], function (_exports, _table, _constants, _isUndefined, _moment, _reportsComparable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_reportsComparable.default, {
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    fetchReport: function fetchReport(params) {
      var _this = this;

      var http = Ember.get(this, 'http');
      var endpoint = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/sales_report");
      var preparedParams = this.serializeParams(params);

      if (preparedParams.start_date === 'Invalid date' || preparedParams.end_date === 'Invalid date') {
        return {
          message: "Please, select valid start and end dates"
        };
      }

      var url = http.createUrl(endpoint, preparedParams);
      return http.get(url).then(function (response) {
        _this.compareReport('report-store', JSON.parse(JSON.stringify(response.data)), endpoint, preparedParams);

        return {
          table: _this.buildPivotTable(params, response.data)
        };
      }).catch(function (response) {
        var message = response.data ? response.data.messsage : response.statusText;
        return {
          message: "".concat(response.status, ": ").concat(message)
        };
      });
    },
    serializeParams: function serializeParams(params) {
      var column = params.column;
      var measure = params.measure; // report of Sales, aggregated by sales is not valid for Romulan report
      // to avoid deep changes in other reports, change aggregate to valid one when sending params

      if (String(column) === "null" && measure === "sales") {
        measure = 'units';
      }

      return {
        start_date: (0, _moment.default)(params.startDate).format('YYYY-MM-DD'),
        end_date: (0, _moment.default)(params.endDate).format('YYYY-MM-DD'),
        field: _constants.default.validMeasureValues[measure],
        data_column: column,
        data_row: params.report,
        group_by: params.groupBy,
        by_account: params.byAccount,
        location_id: params.locationId,
        use_new_report: params.useNewReport || (this.get('useNewReport') ? 'salesReport' : null)
      };
    },
    buildPivotTable: function buildPivotTable(params, data) {
      var columnName, averageTotal;

      if (data.column_name != null) {
        columnName = data.column_name;
        averageTotal = false;
      } else {
        columnName = ['Number of Sales', 'Total', 'Average Per Sale'];
        averageTotal = true;
      }

      return _table.default.create({
        columnName: columnName,
        rowName: data.row_name,
        valueName: data.value_name,
        sourceData: data.source_data,
        groupBy: data.group_by,
        unit: data.unit_name,
        filters: params,
        showRowTotals: false,
        averageTotal: averageTotal
      });
    },
    exportReport: function exportReport(table, reportExportFilename) {
      var keys = Object.keys(table.get('sourceData.firstObject'));
      var csvContent = [];
      csvContent.push(keys.join(','));
      table.get('sourceData').forEach(function (row) {
        var rowData = [];
        keys.forEach(function (key) {
          rowData.push(row[key]);
        });
        csvContent.push(rowData.join(','));
      });
      var blob = new Blob([csvContent.join('\n')], {
        type: 'text/csv;charset=utf-8;'
      });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, reportExportFilename);
      } else {
        var link = document.createElement('a');

        if (!(0, _isUndefined.default)(link.download)) {
          // Browsers that support the download attr
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', reportExportFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          var uri = encodeURI("data:text/csv;charset=utf-8,".concat(csvContent.join('\n')));
          window.open(uri);
        }
      }

      return false;
    },
    useNewReport: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('romulan-deprecation-new-report-store');
    })
  });

  _exports.default = _default;
});