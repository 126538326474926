define("herer-web/components/packages/close-adjustment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inventoryItem: null,
    tagName: 'tr',
    quantity: Ember.computed('inventoryItem.quantity', function () {
      return this.get('inventoryItem.quantity').times(-1);
    })
  });

  _exports.default = _default;
});