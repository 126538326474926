define("herer-web/adapters/person", ["exports", "herer-web/adapters/application", "herer-web/constants", "ember-inflector"], function (_exports, _application, _constants, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(model_name, id, snapshot) {
      var url = this._super.apply(this, arguments);

      if (snapshot && snapshot.record && Ember.isPresent(snapshot.record.get('type'))) {
        var type = _constants.default.personTypes.find(function (t) {
          return t.value === snapshot.record.get('type');
        });

        return url.replace('people', (0, _emberInflector.pluralize)(type.name.toLowerCase()));
      } else {
        return url;
      }
    }
  });

  _exports.default = _default;
});