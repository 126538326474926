define("herer-web/labels/label_filters", ["exports", "herer-web/constants", "herer-web/labels/oregon_label_filter", "herer-web/labels/michigan_label_filter", "herer-web/labels/default_label_filter"], function (_exports, _constants, _oregon_label_filter, _michigan_label_filter, _default_label_filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    filterSet: {},
    defaultFilter: _default_label_filter.default.create(),
    initialize: function initialize() {
      // register license specific label filter
      this.filterSet[_constants.default.licenseType.oregon] = _oregon_label_filter.default.create();
      this.filterSet[_constants.default.licenseType.oregonOha] = _oregon_label_filter.default.create();
      this.filterSet[_constants.default.licenseType.testOregon] = _oregon_label_filter.default.create();
      this.filterSet[_constants.default.licenseType.michigan] = _michigan_label_filter.default.create();
      return this;
    },
    addFilter: function addFilter(licenseType, aFilter) {
      if (!Ember.isPresent(licenseType) || !Ember.isPresent(this.filterSet[licenseType])) {
        return null;
      }

      this.filterSet[licenseType] = aFilter;
      return aFilter;
    },
    updateFilter: function updateFilter(licenseType, aFilter) {
      if (Ember.isPresent(licenseType)) {
        this.filterSet[licenseType] = aFilter;
      }

      return aFilter;
    },
    removeFilter: function removeFilter(licenseType) {
      if (Ember.isPresent(licenseType)) {
        delete this.filterSet[licenseType];
      }
    },
    filterAvailable: function filterAvailable(licenseType) {
      return Ember.isPresent(licenseType) && Ember.isPresent(this.filterSet[licenseType]);
    },
    getFilter: function getFilter(licenseType) {
      if (Ember.isPresent(licenseType)) {
        var filter = this.filterSet[licenseType];

        if (!filter) {
          filter = this.defaultFilter;
        }

        return filter;
      }

      return this.defaultFilter;
    },
    applyFilter: function applyFilter(licenseType, labelType, data) {
      var filter = this.getFilter(licenseType);

      if (!filter) {
        filter = this.defaultFilter;
      }

      return filter.apply(labelType, data);
    }
  });

  _exports.default = _default;
});