define("herer-web/models/dutchie-menu-groups", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var strainByBrand = '{{strain}} by {{brand}}';
  var productName = '{{name}}';
  var _default = [{
    name: 'Sativa',
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.sativa
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Hybrid',
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.hybrid
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Indica',
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.indica
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Pre-roll',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Pre-Roll'
  }, {
    name: 'Vaporizer',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Concentrate',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Concentrate'
  }, {
    name: 'Edible (Solid)',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Edible (Solid)'
  }, {
    name: 'Edible (Liquid)',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Edible (Liquid)'
  }, {
    name: 'Topicals',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Topical'
  }, {
    name: 'Tincture',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Clone',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Clone'
  }, {
    name: 'Seed',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Accessories',
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }];
  _exports.default = _default;
});