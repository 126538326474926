define("herer-web/components/multiple-choice-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    choices: null,
    onChange: null,
    selectedChoice: null,
    disabled: false,
    actions: {
      selectChoice: function selectChoice(choice) {
        if (this.get('onChange')) {
          return this.get('onChange')(choice);
        }
      }
    }
  });

  _exports.default = _default;
});