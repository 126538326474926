define("herer-web/models/menu-group", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/models/weedmaps-menu-groups", "herer-web/constants", "lodash/find", "lodash/sortBy", "herer-web/utils/validation", "yup"], function (_exports, _model, _emberDataModelFragments, _weedmapsMenuGroups, _constants, _find, _sortBy, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      name: (0, _yup.string)().nullable().required(),
      nameTemplate: (0, _yup.string)().nullable().required().notOneOf(['{{strain}} by {{supplier}}', '{{strain}} {{concentrate_type}} by {{supplier}}', '{{tiered_pricing_model}}: {{strain}} by {{supplier}}'], "name templates with {{supplier}} are no longer supported, please pick another one"),
      priceBy: (0, _yup.number)().nullable().required(),
      testResultsThcPadding: (0, _yup.number)().nullable().notRequired(),
      testResultsCbdPadding: (0, _yup.number)().nullable().notRequired(),
      filters: (0, _yup.array)().transform(function (_value, filters) {
        return filters.filter(function (filter) {
          return !filter.get('required');
        });
      }).min(1)
    }),
    name: (0, _model.attr)('string'),
    priceBy: (0, _model.attr)('number'),
    nameTemplate: (0, _model.attr)('string'),
    testResultsThcPadding: (0, _model.attr)('number'),
    testResultsCbdPadding: (0, _model.attr)('number'),
    minimumQuantity: (0, _model.attr)('number'),
    menuItems: (0, _model.hasMany)('menu-item', {
      async: false
    }),
    filters: _emberDataModelFragments.default.fragmentArray('menu-group-filter', {
      defaultValue: []
    }),
    menuFeed: (0, _model.belongsTo)('menuFeed', {
      async: false
    }),
    hasMenuItemErrors: Ember.computed('menuItems.@each.hasErrors', function () {
      var menuItems = this.get('menuItems');
      return menuItems.reduce(function (haveErrors, menuItem) {
        return haveErrors || menuItem.get('hasErrors');
      }, false);
    }),
    priceByUnit: Ember.computed('priceBy', function () {
      return this.get('priceBy') === _constants.default.menuGroupPriceBy.unit;
    }),
    priceByWeight: Ember.computed('priceBy', function () {
      return this.get('priceBy') === _constants.default.menuGroupPriceBy.weight;
    }),
    priceColumns: Ember.computed('name', 'menuFeed.type', 'menuItems.@each.prices', function () {
      if (!this.get('menuFeed.hasFixedPricedColumns')) {
        return this.priceColumnsFromItems();
      }

      var name = this.get('name');
      var menuGroups = this.get('menuFeed.menuGroupOptions').find(function (menuGroupOption) {
        return menuGroupOption.name === name;
      });
      return menuGroups ? menuGroups.prices : [];
    }),
    priceColumnWidth: Ember.computed('priceColumns', function () {
      return 100 / this.get('priceColumns').length;
    }),
    rollbackAttributes: function rollbackAttributes() {
      this.get('menuItems').forEach(function (menuItem) {
        menuItem.rollbackAttributes();
      });
      this.set('filters', []);

      this._super.apply(this, arguments);
    },
    priceColumnsFromItems: function priceColumnsFromItems() {
      if (this.get('priceByUnit')) {
        return [_weedmapsMenuGroups.each];
      }

      var columns = [];
      this.get('menuItems').forEach(function (menuItem) {
        menuItem.get('prices').forEach(function (menuItemPrice) {
          var priceWeight = menuItemPrice.toWeight();
          var existingWeight = (0, _find.default)(columns, function (weight) {
            return weight.eq(priceWeight);
          });

          if (!existingWeight) {
            columns.push(priceWeight);
          }
        });
      });
      return (0, _sortBy.default)(columns, function (column) {
        return parseFloat(column.to(_constants.default.weightUnit.gram));
      });
    }
  });

  _exports.default = _default;
});