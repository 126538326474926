define("herer-web/models/weedmaps-menu-groups", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.each = void 0;

  var halfGram = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 0.5,
    street: true
  });

  var oneGram = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 1,
    street: true
  });

  var twoGrams = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 2,
    street: true
  });

  var eighthOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 3.5,
    street: true
  });

  var quarterOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 7,
    street: true
  });

  var halfOunce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 14,
    street: true
  });

  var ounce = _weight.default.create({
    unit: _constants.default.weightUnit.gram,
    value: 28,
    street: true
  });

  var each = _weight.default.create({
    unit: _constants.default.weightUnit.unit,
    value: 1,
    street: true
  });

  _exports.each = each;
  var strainByBrand = '{{strain}} by {{brand}}';
  var productName = '{{name}}';
  var _default = [{
    name: 'Indica',
    prices: [oneGram, twoGrams, eighthOunce, quarterOunce, halfOunce, ounce],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.indica
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Sativa',
    prices: [oneGram, twoGrams, eighthOunce, quarterOunce, halfOunce, ounce],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.sativa
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Hybrid',
    prices: [oneGram, twoGrams, eighthOunce, quarterOunce, halfOunce, ounce],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: strainByBrand,
    filters: [{
      key: 'by_flower_type',
      value: _constants.default.flowerType.hybrid
    }],
    productTypeName: 'Flower'
  }, {
    name: 'Edible',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Edible (Solid)'
  }, {
    name: 'Concentrate',
    prices: [halfGram, oneGram, twoGrams],
    priceBy: _constants.default.menuGroupPriceBy.weight,
    nameTemplate: '{{strain}} {{concentrate_type}} by {{brand}}',
    productTypeName: 'Concentrate'
  }, {
    name: 'Drink',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Edible (Liquid)'
  }, {
    name: 'Clone',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Clone'
  }, {
    name: 'Seed',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Tincture',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Gear',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }, {
    name: 'Topicals',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Topical'
  }, {
    name: 'Preroll',
    prices: [each],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName,
    productTypeName: 'Pre-Roll'
  }, {
    name: 'Wax',
    prices: [halfGram, oneGram, twoGrams],
    priceBy: _constants.default.menuGroupPriceBy.unit,
    nameTemplate: productName
  }];
  _exports.default = _default;
});