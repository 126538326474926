define("herer-web/routes/reports/sales-by", ["exports", "herer-web/routes/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({
    sideNavTemplateName: Ember.computed('controller.report', function () {
      var report = this.get('controller.report');

      if (['product', 'brand', 'product_type', 'strain', 'concentrate_type'].includes(report)) {
        return 'best-selling-reports-nav';
      } else if (report === 'patient') {
        return 'medical-sales-reports-nav';
      } else {
        return 'reports-nav';
      }
    })
  });

  _exports.default = _default;
});