define("herer-web/routes/order", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/pin-debit-settings"], function (_exports, _authenticatedFullPage, _pinDebitSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_pinDebitSettings.default, {
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        order: store.findRecord('order', params.id),
        paymentMethods: store.findAll('payment_method'),
        pinDebitSettings: this.findPinDebitSettings(store)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.order);

      controller.set('paymentMethods', model.paymentMethods);
      controller.set('isPanel', false);
      controller.set('pinDebitSettings', model.pinDebitSettings);
    }
  });

  _exports.default = _default;
});