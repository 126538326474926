define("herer-web/mixins/taskable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    task: null,
    taskTimer: null,
    onTaskComplete: null,
    willDestroyElement: function willDestroyElement() {
      this.clearTaskTimer();

      this._super.apply(this, arguments);
    },
    pollTask: function pollTask() {
      var _this = this;

      if (Ember.isPresent(this.get('taskTimer'))) {
        this.clearTaskTimer();
      }

      this.get('store').findRecord('task', this.get('task.id')).then(function (task) {
        _this.set('task', task);

        if (task.get('queued') || task.get('processing')) {
          _this.set('taskTimer', setTimeout(_this.pollTask.bind(_this), 2 * 1000));
        } else {
          _this.onTaskComplete && _this.onTaskComplete();
        }
      });
    },
    clearTaskTimer: function clearTaskTimer() {
      clearTimeout(this.get('taskTimer'));
    }
  });

  _exports.default = _default;
});