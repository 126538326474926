define("herer-web/components/packages/package/history-events/package-history-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    shouldShowNote: false,
    noteText: '',
    actions: {
      showNote: function showNote() {
        this.set('shouldShowNote', true);
      },
      closeNote: function closeNote() {
        this.set('shouldShowNote', false);
      }
    }
  });

  _exports.default = _default;
});