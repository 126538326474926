define("herer-web/routes/index", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('index').set('newHomepageEnabled', false);
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        banners: this.store.findAll('banner')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('banners', model.banners);
    }
  });

  _exports.default = _default;
});