define("herer-web/components/packages/package-merger", ["exports", "herer-web/components/base-modal", "herer-web/constants", "herer-web/mixins/inventory-weights", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/product-selector", "herer-web/mixins/context", "bootbox", "herer-web/utils/validation", "yup"], function (_exports, _baseModal, _constants, _inventoryWeights, _lotNumberFormatter, _productSelector, _context, _bootbox, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var componentMixins = [_inventoryWeights.default, _lotNumberFormatter.default, _productSelector.default, _context.default];

  var _default = _baseModal.default.extend.apply(_baseModal.default, componentMixins.concat([{
    authManager: Ember.inject.service(),
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    packages: [],
    productTypes: [],
    tieredPricingModels: [],
    tags: [],
    taxItems: [],
    errors: [],
    onCancel: null,
    onMerge: null,
    modalClassName: '.package-merger-modal',
    newLotNumber: null,
    newSku: null,
    newPackageCost: null,
    product: null,
    isConfirming: false,
    isMerging: false,
    unitBasedFilter: false,
    weightBasedFilter: false,
    zeroCostPackageError: 'This package has a cost per unit of $0.00. ' + 'If this is incorrect, go to Incoming Inventory and change the cost on the manifest.',
    validations: (0, _validation.validations)({
      isMetrc: (0, _yup.bool)(),
      newLotNumber: (0, _yup.string)().nullable().when('isMetrc', {
        is: true,
        then: (0, _yup.string)().length(24, 'is the wrong length (should be 24 characters)').required(),
        otherwise: (0, _yup.string)().nullable()
      }),
      newSku: (0, _yup.string)().nullable().required(),
      product: (0, _yup.string)().nullable().required(),
      newPackageCost: (0, _yup.number)().nullable().required().moreThan(0)
    }),
    isMetrc: Ember.computed('currentLocation', function () {
      return this.get('featureManager').can('metrc');
    }),
    applicableProductTypes: Ember.computed('productTypes', function () {
      return this.get('productTypes').filter(function (productType) {
        return productType.get('is_marijuana') === true;
      });
    }),
    estimatedNewPackageCost: Ember.computed('packages', function () {
      var packages = this.get('packages');
      var estimatedCost = 0.0;

      if (Ember.isEmpty(packages)) {
        return String(estimatedCost);
      }

      if (packages.every(function (aPackage) {
        return aPackage.get('costPerUnit') > 0;
      })) {
        packages.forEach(function (aPackage) {
          estimatedCost += aPackage.get('costPerUnit');
        });
        return Math.round(estimatedCost / packages.length);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newPackageCost', this.get('estimatedNewPackageCost'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        modal.modal();
      }
    },
    showErrorModal: function showErrorModal() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to save this merge.\n      If you still have this issue, chat Support.");
    },
    buildParams: function buildParams() {
      return {
        from: this.get('packages').map(function (p) {
          return p.get('id');
        }).uniq(),
        to: {
          lot_number: this.get('newLotNumber'),
          sku: this.get('newSku'),
          product_id: this.get('product.id'),
          purchase_price: this.get('newPackageCost')
        }
      };
    },
    actions: {
      mergePackages: function mergePackages() {
        var _this = this;

        this.set('isMerging', true);
        return Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/packages/merge"), this.buildParams()).then(function () {
          _this.onMerge && _this.onMerge();
        }).catch(function (response) {
          _this.set('errors', response.data.errors);
        }).finally(function () {
          _this.set('isMerging', false);
        });
      },
      verifyAndConfirm: function verifyAndConfirm() {
        var _this2 = this;

        this.get('validations').validate().then(function () {
          _this2.set('isConfirming', true);
        }).catch(function () {});
      },
      unconfirm: function unconfirm() {
        this.set('errors', []);
        this.set('isConfirming', false);
      },
      selectProduct: function selectProduct(product) {
        this.set('product', product);
      },
      setPackageCost: function setPackageCost(value) {
        this.set('newPackageCost', value);
      },
      showProductSelector: function showProductSelector() {
        var isUnitBased = this.get('packages').map(function (aPackage) {
          return aPackage.get('quantity.isUnit');
        }).uniq()[0];
        this.set('unitBasedFilter', isUnitBased);
        this.set('weightBasedFilter', !isUnitBased);
        this.showProductSelector();
      }
    }
  }]));

  _exports.default = _default;
});