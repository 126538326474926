define("herer-web/utils/zpl-builder", ["exports", "herer-web/constants", "herer-web/models/mpc-barcode", "herer-web/helpers/format-percent", "herer-web/services/feature-manager", "herer-web/models/weight", "herer-web/helpers/format-unit", "big.js", "moment"], function (_exports, _constants, _mpcBarcode, _formatPercent, _featureManager, _weight, _formatUnit, _big, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    location: null,
    product: null,
    inventoryItem: null,
    quantity: null,
    sku: null,
    weight: null,
    labelPreference: null,
    receiptPreference: null,
    filter: null,
    isMedical: false,
    // 0.877 is a ratio of molecular masses, specifically that of THC divided
    // by that of THCA. If you multiply the amount of THCA by 0.877 and add the
    // amount of already “active” THC, you find the maximum amount of THC
    // remaining after complete decarboxylation. THCA is about 87.7% THC and
    // 12.3% CO2 by mass.
    thcaMassRatio: 0.877,
    setFilter: function setFilter(filter) {
      this.filter = filter;
      return this;
    },
    setMedical: function setMedical() {
      this.isMedical = true;
      return this;
    },
    applyFilter: function applyFilter(rawData, filter, labelType) {
      if (Ember.isPresent(filter)) {
        var filteredData = filter.apply(labelType, rawData);
        return filteredData;
      }

      return rawData;
    },
    applyFilterToTemplate: function applyFilterToTemplate(templateZpl, values, labelType, fieldNumberToNameMapping) {
      if (Ember.isPresent(this.filter)) {
        // apply template layout filter if defined
        if (typeof this.filter.transformTemplate === 'function') {
          var zplFiltered = this.filter.transformTemplate(labelType, templateZpl, values, fieldNumberToNameMapping);
          return zplFiltered;
        }
      }

      return templateZpl;
    },
    displayInMg: function displayInMg() {
      return (0, _featureManager.hasFeature)(this.get('location'), 'mg_test_results_on_labels');
    },
    displayInMgAndPercent: function displayInMgAndPercent() {
      return (0, _featureManager.hasFeature)(this.get('location'), 'mg_percent_test_results_on_labels');
    },
    formatDecimal: function formatDecimal(number) {
      var displayInMg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      displayInMg = Ember.isPresent(displayInMg) ? displayInMg : this.displayInMg();
      var multiplier = displayInMg ? 1000 : 100;
      return new _big.default(number || 0).times(multiplier).toFixed();
    },
    formatTestResultsRange: function formatTestResultsRange(min, max) {
      if (this.displayInMgAndPercent()) {
        return this.formatTestResultsRangeMgAndPercent(min, max);
      }

      min = this.formatDecimal(min, this.displayInMg());

      if (min < 0) {
        min = 0;
      }

      if (Ember.isPresent(max)) {
        return "".concat(min, "-").concat(this.formatDecimal(max));
      } else {
        return min;
      }
    },
    formatTestResultsRangeMgAndPercent: function formatTestResultsRangeMgAndPercent(min, max) {
      var minMgPerGram = this.formatDecimal(min, true);
      var minPercent = this.formatDecimal(min, false);

      if (minMgPerGram < 0) {
        minMgPerGram = 0;
      }

      if (minPercent < 0) {
        minPercent = 0;
      }

      if (!Ember.isPresent(max)) {
        return "".concat(minMgPerGram, " mg/g (").concat(minPercent, "%)");
      }

      var maxMgPerGram = this.formatDecimal(max, true);
      var maxPercent = this.formatDecimal(max, false);
      return "".concat(minMgPerGram, " mg/g (").concat(minPercent, "%)-").concat(maxMgPerGram, " mg/g (").concat(maxPercent, "%)");
    },
    formatTestResultsTotal: function formatTestResultsTotal(value, total, weightInGrams) {
      if (value || !total) {
        return this.formatTestResultsRange(value, null);
      }

      if ((this.displayInMg() || this.displayInMgAndPercent()) && weightInGrams) {
        var mgPerGramValue = total.get('value') / weightInGrams;
        var percentValue = mgPerGramValue / 10;
        var perGramDisplay = "".concat(mgPerGramValue, " mg/g (").concat(percentValue, "%)");
        return this.displayInMg() ? mgPerGramValue.toFixed() : perGramDisplay;
      }

      return total.get('value').toFixed();
    },
    formatWeight: function formatWeight(weight) {
      var showBothWeights = (0, _featureManager.hasFeature)(this.get('location'), 'print_grams_and_ounces_on_labels');
      var ouncesFirst = (0, _featureManager.hasFeature)(this.get('location'), 'print_ounces_before_grams_on_labels');

      if (showBothWeights && weight.unit === _constants.default.weightUnit.gram) {
        var weightInOz = weight.to(_constants.default.weightUnit.ounce);

        if (ouncesFirst) {
          return "".concat(weightInOz.toString(), " (").concat(weight.toString(), ")");
        } else {
          return "".concat(weight.toString(), " (").concat(weightInOz.toString(), ")");
        }
      }

      return weight.toString();
    },
    buildWeightFieldValues: function buildWeightFieldValues() {
      var location = this.get('location');
      var product = this.get('product');
      var inventoryItem = this.get('inventoryItem');
      var weight = this.get('weight') || product && product.get('weight');
      var sku = this.get('sku');
      var labelPreference = this.get('labelPreference');
      var additionalMaterials = inventoryItem.get('additional_materials');
      var receiptPreference = this.get('receiptPreference');
      var packageDate = inventoryItem.get('packaged_date') || (0, _moment.default)();
      var values = {
        packaged_date: this.formatDate(packageDate),
        sku: sku,
        store_website: receiptPreference ? receiptPreference.get('website') : '',
        certificate_of_analysis_url_short: inventoryItem.certificate_of_analysis_url_short
      };

      if (weight) {
        values.weight = this.formatWeight(weight);

        if (additionalMaterials && additionalMaterials.length) {
          var net_weight = weight.plus(this.sumAdditionalMaterials(additionalMaterials.map(function (material) {
            return _weight.default.create(material.weight);
          })));
          values.net_weight = this.formatWeight(net_weight);
        } else {
          values.net_weight = values.weight;
        }

        weight = weight.to(_constants.default.weightUnit.gram).get('value');
      }

      var mpcBarcode = _mpcBarcode.default.create({
        weight: weight,
        packageId: inventoryItem.get('lot_number'),
        productCode: sku
      });

      values.mpc_code = mpcBarcode.toString();

      if (labelPreference) {
        values.retailer_license_number = labelPreference.get('storeId');
        values.store_name = labelPreference.get('storeName');
        values.store_phone = labelPreference.get('phone');
        values.label_id = labelPreference.get('labelId');
      }

      if (location) {
        values.store_email = location.get('primary_email');
        values.store_address = this.formatStoreAddress(location);

        if (Ember.isEmpty(values.retailer_license_number)) {
          values.retailer_license_number = location.get('license_number');
        }

        if (Ember.isEmpty(values.store_name)) {
          values.store_name = location.get('name');
        }
      }

      if (product) {
        values.productname = product.get('name');
        values.strain = product.get('strain.name');

        var flowerType = _constants.default.flowerTypes.find(function (ft) {
          return ft.value === product.get('flower_type');
        });

        if (Ember.isPresent(flowerType)) {
          values.flower_type = flowerType.name;
        }
      }

      if (inventoryItem) {
        values.package_id = inventoryItem.get('lot_number');
        values.cultivator_license_number = inventoryItem.get('cultivator_license_number');
        values.additional_cultivator_license_number = inventoryItem.get('additional_cultivator_license_number');
        values.producer_name = inventoryItem.get('producer_name');
        values.test_lab_name = inventoryItem.get('test_lab_name');
        values.test_batch_number = inventoryItem.get('test_batch_number');
        values.test_analysis_dates = inventoryItem.get('test_analysis_dates');
        values.ingredients_text = inventoryItem.get('ingredients_text');
        values.pesticides_text = inventoryItem.get('pesticides_text');
        values.packager_name = inventoryItem.get('packager_name');
        values.packager_license_number = inventoryItem.get('packager_license_number');
        values.harvest_date = this.formatDate(inventoryItem.get('harvest_date'));
        values.expiration_date = this.formatDate(inventoryItem.get('expiration_date'));
        values.use_by_date = this.formatDate(inventoryItem.get('use_by_date'));
        values.product_type = inventoryItem.get('product.product_type.name');
        values.traceable_type = inventoryItem.get('product.product_type.traceability_type');

        if (inventoryItem.get('test_results_terpenoid_profile')) {
          var terpenoidProfile = inventoryItem.get('test_results_terpenoid_profile');
          values.terpenoid_profile = this.formatTerpenoidValues(terpenoidProfile);
          values.terpenoid_sum = this.formatTerpenoidTotal(terpenoidProfile);
        } // Formats thc, thca, cbd, cbn, etc


        this.addTestResults(values, inventoryItem, weight);
        values.harvest_lot_number = inventoryItem.get('harvest_lot_number');
      }

      return values;
    },
    addTestResults: function addTestResults(values, inventoryItem, weightInGrams) {
      var _this = this;

      var traceabilityType = inventoryItem.get('product.product_type.traceability_type');
      var isMarijuana = traceabilityType === _constants.default.traceabilityType.usableMarijuana;
      var cannabanoids = ['thc', 'cbd', 'cbn', 'cbc', 'cbg', 'thcv'];
      cannabanoids.forEach(function (cannabanoid) {
        var testResult = inventoryItem.get("test_results_".concat(cannabanoid));

        if (isMarijuana) {
          values[cannabanoid] = _this.formatTestResultsRange(testResult, inventoryItem.get("test_results_max_".concat(cannabanoid)));
        } else {
          values[cannabanoid] = _this.formatTestResultsTotal(testResult, inventoryItem.get("test_results_total_".concat(cannabanoid)), weightInGrams);
        }
      }); // THCA/CBDA doesn't have a range for some reason

      values.thca = this.formatTestResultsTotal(inventoryItem.get('test_results_thca'), inventoryItem.get('test_results_total_thca'), weightInGrams);
      values.cbda = this.formatTestResultsTotal(inventoryItem.get('test_results_cbda'), inventoryItem.get('test_results_total_cbda'), weightInGrams);
      values.combined_total_thc_thca = this.formatTestResultsTotal(new _big.default(inventoryItem.get('test_results_thca') || 0).times(this.thcaMassRatio).plus(new _big.default(inventoryItem.get('test_results_thc') || 0)));
    },
    sumAdditionalMaterials: function sumAdditionalMaterials(additionalMaterials) {
      return additionalMaterials.reduce(function (total, weight) {
        return total.plus(weight);
      });
    },
    buildSkuFieldValues: function buildSkuFieldValues() {
      var inventoryItem = this.get('inventoryItem');
      var location = this.get('location');
      var biotrackUbi = location.get('biotrack_ubi');
      var hasUbi = Ember.isPresent(biotrackUbi);
      var licenseNumber = hasUbi ? biotrackUbi : location.get('license_number');
      var storeName = this.get('labelPreference.storeName') || location.get('name');
      var values = {
        package_id: this.formatPackageId(inventoryItem.get('lot_number')),
        price: this.formatPrice(inventoryItem),
        product_name: inventoryItem.get('product.name'),
        retailer_license_number_label: hasUbi ? 'UBI:' : '',
        retailer_license_number: licenseNumber,
        sku: inventoryItem.get('sku'),
        store_name: storeName,
        date_received: this.formatDate(inventoryItem.get('received_at'))
      };
      return values;
    },
    formatPrice: function formatPrice(inventoryItem) {
      // only return an empty string if this flag is explicitly false
      // if it's null, we default it to true
      if (this.get('labelPreference.priceOnSkuLabel') === false) {
        return '';
      }

      var product = inventoryItem.get('product');
      var printPrice = product.get('tier_price');
      return Ember.isPresent(printPrice) ? "$".concat((0, _formatUnit.formatCents)(printPrice)) : '';
    },
    formatPackageId: function formatPackageId(lotNumber) {
      if (Ember.isBlank(lotNumber)) {
        return '';
      }

      return (0, _featureManager.hasFeature)(this.get('location'), 'lots') ? "Lot: ".concat(lotNumber) : "Package: ".concat(lotNumber);
    },
    buildValuesZpl: function buildValuesZpl(zpl, filename, labelType) {
      var fieldNumberToNameMapping = this.extractFieldsFromZpl(zpl);
      var values;

      if (labelType === _constants.default.labelType.weight) {
        values = this.buildWeightFieldValues();
      } else {
        values = this.buildSkuFieldValues();
      }

      values['isMedical'] = this.isMedical;
      var templateZpl = this.applyFilterToTemplate(zpl, values, labelType, fieldNumberToNameMapping); // apply any license specific transformation to the dataset

      values = this.applyFilter(values, this.filter, labelType);
      var newZpl = Object.keys(fieldNumberToNameMapping).map(function (fieldNumber) {
        var field = fieldNumberToNameMapping[fieldNumber];
        var value = values[field] ? values[field] : '';

        if (['mpc_code', 'certificate_of_analysis_url_short'].includes(field)) {
          value = "MA,".concat(value);
        } // added ^FH\ to allow the use of '\' in character escaped sequence


        return "^FN".concat(fieldNumber, "^FH\\^FD").concat(value, "^FS");
      }).join('\n');
      newZpl = ["^XA".concat(templateZpl), newZpl, "^PQ".concat(this.get('quantity'), ",0,1,Y"), '^XZ'].join('\n');
      return newZpl;
    },
    extractFieldsFromZpl: function extractFieldsFromZpl(zpl) {
      var matches = zpl.match(/\^FN\d{1,2}[^"]*"[a-z_]+"\^FS/g);
      var fieldNumberToName = {};
      Ember.A(matches).forEach(function (match) {
        var parts = match.split('"');
        var fieldNumber = parts[0].replace('^FN', '');
        var fieldName = parts[1];
        fieldNumberToName[fieldNumber] = fieldName;
      });
      return fieldNumberToName;
    },
    formatTerpenoidValues: function formatTerpenoidValues(terpenoidProfile) {
      var terps = [];
      var showPercentage = (0, _featureManager.hasFeature)(this.get('location'), 'terpenoid_profile_as_percent');
      Object.keys(terpenoidProfile).forEach(function (key) {
        var terpene = _constants.default.terpenes.findBy('value', key);

        var terp = "".concat(terpene.printableName, ": ").concat(terpenoidProfile[key], " mg/g");

        if (showPercentage) {
          terp += " (".concat((0, _formatPercent.formatPercent)(terpenoidProfile[key] / 1000), ")");
        }

        terps.push(terp);
      });
      return terps.join(', ');
    },
    formatTerpenoidTotal: function formatTerpenoidTotal(terpenoidProfile) {
      var showPercentage = (0, _featureManager.hasFeature)(this.get('location'), 'terpenoid_profile_as_percent');
      var terpenoidSum = this.sumTerpenoidValues(terpenoidProfile);
      return "".concat(terpenoidSum, " mg/g (").concat((0, _formatPercent.formatPercent)(terpenoidSum / 1000), ")");
    },
    sumTerpenoidValues: function sumTerpenoidValues(terpenoidProfile) {
      return Object.keys(terpenoidProfile).reduce(function (sum, key) {
        return sum + terpenoidProfile[key];
      }, 0);
    },
    formatDate: function formatDate(date) {
      if (!date) {
        return;
      }

      return (0, _moment.default)(date).format('MM/DD/YYYY');
    },
    formatStoreAddress: function formatStoreAddress(location) {
      var street = [location.get('street'), location.get('city'), location.get('state')];
      return "".concat(street.join(', '), " ").concat(location.get('zip_code'));
    }
  });

  _exports.default = _default;
});