define("herer-web/templates/components/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EASdcNwA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-data\"],[10,\"data-test-selector\",\"no_data\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"/img/sad-pug.jpg\"],[10,\"class\",\"img-responsive\"],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    There's no data available.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/no-data.hbs"
    }
  });

  _exports.default = _default;
});