define("herer-web/templates/components/packages/package-reconciler/reconciling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+h8dpxU5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"data-test-text\",\"modal_title\"],[8],[0,\"\\n    Reconciling, please wait…\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"action-view clearfix\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"greenbits-logo\"],[8],[9],[0,\"\\n    \"],[1,[22,\"animation-request\"],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"metrc-logo\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 text-left\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-default text-left\"],[12,\"data-test-action\",\"cancel\"],[3,\"action\",[[23,0,[]],[24,[\"onCancel\"]]]],[8],[0,\"\\n        Continue\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-8 timeout-warning\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showTimeoutWarning\"]]],null,{\"statements\":[[0,\"        Metrc is taking awhile to respond to us.\"],[7,\"br\",true],[8],[9],[0,\"\\n        This could take up to 30 seconds.\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package-reconciler/reconciling.hbs"
    }
  });

  _exports.default = _default;
});