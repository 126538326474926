define("herer-web/templates/state-link/auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+WnVqoA/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"custom\",\"data-test-ux\"],[true,\"state_link_auto_header\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-7\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        State Link\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"traceability-status\"],false],[0,\"\\n\"],[1,[28,\"traceability-notifications\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/state-link/auto.hbs"
    }
  });

  _exports.default = _default;
});