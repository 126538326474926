define("herer-web/mixins/pin-debit-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findPinDebitSettings: function findPinDebitSettings(store) {
      return store.findAll('pin-debit-setting').then(function (results) {
        return results.get('firstObject');
      });
    }
  });

  _exports.default = _default;
});