define("herer-web/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TooltipComponent = Ember.Component.extend({
    initTooltip: function initTooltip() {
      var $toolTip = this.$('[data-toggle="tooltip"]');

      if ($toolTip && $toolTip.length) {
        $toolTip.tooltip({
          container: this.containerElement
        });
      }
    },
    didInsertElement: function didInsertElement() {
      return this.initTooltip();
    }
  });
  var _default = TooltipComponent;
  _exports.default = _default;
});