define("herer-web/serializers/discount-preset", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(type, data, key) {
      if (data.calculation_type === 0) {
        data.calculation_value = Math.round(data.calculation_value * 100);
      }

      return this._super(type, data, key);
    },
    serialize: function serialize(record, options) {
      var json = this._super(record, options);

      if (json.calculation_type === 0) {
        json.calculation_value /= 100;
      }

      return json;
    }
  });

  _exports.default = _default;
});