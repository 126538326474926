define("herer-web/components/packages/package/package-drawer", ["exports", "herer-web/mixins/lot-number-formatter"], function (_exports, _lotNumberFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_lotNumberFormatter.default, {
    tagName: '',
    package: null,
    showSlideOutDrawer: false,
    onAttemptedToHideDrawer: null,
    afterHideDrawer: null,
    formattedLotNumber: Ember.computed('package.lotNumber', function () {
      return this.formatLotNumber(this.get('package.lotNumber'));
    })
  });

  _exports.default = _default;
});