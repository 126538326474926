define("herer-web/templates/components/input-tooltip-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WUoHj1aN",
    "block": "{\"symbols\":[\"&default\",\"@content\",\"@enabled\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"input-tooltip-wrapper\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/input-tooltip-wrapper.hbs"
    }
  });

  _exports.default = _default;
});