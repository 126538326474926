define("herer-web/templates/lots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8VID6a85",
    "block": "{\"symbols\":[],\"statements\":[[4,\"pop-over\",null,null,{\"statements\":[[4,\"page-header\",null,[[\"header\",\"data-test-ux\"],[\"Lots\",\"lots_header\"]],{\"statements\":[[4,\"page-header-actions\",null,null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"btn-group\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canSync\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"task-progress-button\",null,[[\"apiPath\",\"taskName\",\"title\",\"icon\",\"message\",\"onTaskComplete\"],[\"packages/sync\",\"Quantity Sync\",\"Sync Quantities from MJ Traceability\",\"fa-refresh\",\"Syncing lot quantities from MJ Traceability.\",[28,\"action\",[[23,0,[]],\"reload\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"btn-group pull-right\"],[8],[0,\"\\n        \"],[1,[28,\"export-button\",null,[[\"lotOrPackage\",\"lotNumberLabel\",\"lotOrPackageTitle\",\"exportProductList\",\"exportInventoryItemList\",\"exportPackageList\",\"exportLlxList\"],[[24,[\"lotOrPackage\"]],[24,[\"lotNumberLabel\"]],[24,[\"lotOrPackageTitle\"]],[28,\"action\",[[23,0,[]],\"exportProductList\"],null],[28,\"action\",[[23,0,[]],\"exportInventoryItemList\"],null],[28,\"action\",[[23,0,[]],\"exportPackageList\"],null],[28,\"action\",[[23,0,[]],\"handleExportLlxList\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"packages/package-list\",null,[[\"packages\",\"onRowClick\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"openDrawer\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/lots.hbs"
    }
  });

  _exports.default = _default;
});