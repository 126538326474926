define("herer-web/components/discount-conditions", ["exports", "herer-web/constants", "herer-web/mixins/context"], function (_exports, _constants, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function discountConditionValue(discountConditions, key) {
    return discountConditions.reduce(function (prev, condition) {
      if (condition.get('key') === key) {
        var value = condition.get('value');
        return Ember.isPresent(value) ? value : condition.get('values');
      } else {
        return prev;
      }
    }, '');
  }

  var _default = Ember.Component.extend(_context.default, {
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    store: Ember.inject.service(),
    discountPlan: null,
    productTypes: [],
    allTags: [],
    availableDiscountConditions: Ember.computed('discountPlan', 'productTypes', 'allTags', function () {
      var discountConditions = this.get('discountPlan.conditions');
      var conditionNames = discountConditions.mapBy('key');
      var defaultConditions = [{
        label: 'Brand',
        type: 'select',
        query: 'brand',
        prompt: 'Please select a brand',
        options: this.get('brands'),
        optionLabel: function optionLabel(option) {
          return option.get('name');
        },
        optionValue: function optionValue(option) {
          return option.get('name');
        },
        defaultFilter: conditionNames.includes('brand'),
        defaultValue: discountConditionValue(discountConditions, 'brand')
      }, {
        label: 'Date',
        type: 'date',
        query: 'date',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('date'),
        defaultValue: discountConditionValue(discountConditions, 'date')
      }, {
        label: 'Is Marijuana',
        type: 'select',
        query: 'is_marijuana',
        prompt: 'Is this only applicable to marijuana products?',
        options: [{
          label: 'Yes',
          value: true
        }, {
          label: 'No',
          value: false
        }],
        optionLabel: function optionLabel(option) {
          return option.label;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: conditionNames.includes('is_marijuana'),
        defaultValue: discountConditionValue(discountConditions, 'is_marijuana')
      }, {
        label: 'Item Weight',
        type: 'weight',
        query: 'weight',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('weight'),
        defaultValue: discountConditionValue(discountConditions, 'weight')
      }, {
        label: 'Product Type',
        type: 'select',
        query: 'product_type',
        prompt: 'What product type is this applicable to?',
        options: this.get('productTypes'),
        optionLabel: function optionLabel(option) {
          return option.get('name');
        },
        optionValue: function optionValue(option) {
          return option.get('id');
        },
        defaultFilter: conditionNames.includes('product_type'),
        defaultValue: discountConditionValue(discountConditions, 'product_type')
      }, {
        label: 'Quantity',
        type: 'numeric',
        query: 'quantity',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('quantity'),
        defaultValue: discountConditionValue(discountConditions, 'quantity')
      }, {
        label: 'Sell Price',
        type: 'money',
        query: 'price',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('price'),
        defaultValue: discountConditionValue(discountConditions, 'price')
      }, {
        label: 'Tags',
        type: 'tag',
        query: 'tags',
        allTags: this.get('allTags'),
        defaultFilter: conditionNames.includes('tags'),
        defaultValue: discountConditionValue(discountConditions, 'tags')
      }, {
        label: 'Time',
        type: 'time',
        query: 'time',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('time'),
        defaultValue: discountConditionValue(discountConditions, 'time')
      }, {
        label: 'Total Weight',
        type: 'weight',
        query: 'total_weight',
        subquery: 'amount',
        defaultFilter: conditionNames.includes('total_weight'),
        defaultValue: discountConditionValue(discountConditions, 'total_weight')
      }];
      defaultConditions.push({
        label: 'Recurrence',
        type: 'select',
        query: 'recurring_dow',
        prompt: 'On which day of the week should this discount repeat?',
        // iOS Weekday units are 1-indexed
        options: [{
          label: 'Sunday',
          value: 1
        }, {
          label: 'Monday',
          value: 2
        }, {
          label: 'Tuesday',
          value: 3
        }, {
          label: 'Wednesday',
          value: 4
        }, {
          label: 'Thursday',
          value: 5
        }, {
          label: 'Friday',
          value: 6
        }, {
          label: 'Saturday',
          value: 7
        }],
        optionLabel: function optionLabel(option) {
          return option.label;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: conditionNames.includes('recurring_dow'),
        defaultValue: discountConditionValue(discountConditions, 'recurring_dow')
      });

      if (this.get('currentLocation.medical') && this.get('featureManager').can('medical-membership')) {
        defaultConditions.push({
          label: 'Is Member',
          type: 'select',
          query: 'is_member',
          prompt: 'Is this only applicable to medical members?',
          options: [{
            label: 'Yes',
            value: true
          }, {
            label: 'No',
            value: false
          }],
          optionLabel: function optionLabel(option) {
            return option.label;
          },
          optionValue: function optionValue(option) {
            return option.value;
          },
          defaultFilter: conditionNames.includes('is_member'),
          defaultValue: discountConditionValue(discountConditions, 'is_member')
        });
      }

      if (this.get('launchDarkly').can('order-sync')) {
        defaultConditions.push({
          label: 'dutchie Online Order',
          type: 'select',
          query: 'is_online_order',
          prompt: 'Is this only applicable to orders placed online?',
          options: [{
            label: 'Yes',
            value: true
          }, {
            label: 'No',
            value: false
          }],
          optionLabel: function optionLabel(option) {
            return option.label;
          },
          optionValue: function optionValue(option) {
            return option.value;
          },
          defaultFilter: conditionNames.includes('is_online_order'),
          defaultValue: discountConditionValue(discountConditions, 'is_online_order')
        });
      }

      if (this.get('launchDarkly').can('bw-person-group')) {
        defaultConditions.push({
          label: 'Person Group',
          type: 'select',
          query: 'person_group',
          prompt: 'What person group is this applicable to?',
          options: this.get('store').findAll('personGroup'),
          optionLabel: function optionLabel(option) {
            return option.get('name');
          },
          optionValue: function optionValue(option) {
            return option.get('id');
          },
          defaultFilter: conditionNames.includes('person_group'),
          defaultValue: discountConditionValue(discountConditions, 'person_group')
        });
      } else {
        defaultConditions.push({
          label: 'Customer Type',
          type: 'select',
          query: 'customer_type',
          prompt: 'What customer type is this applicable to?',
          options: _constants.default.discountCustomerTypeValues,
          optionLabel: function optionLabel(option) {
            return option.name;
          },
          optionValue: function optionValue(option) {
            return option.value;
          },
          defaultFilter: conditionNames.includes('customer_type'),
          defaultValue: discountConditionValue(discountConditions, 'customer_type')
        });
      }

      return defaultConditions;
    }),
    actions: {
      conditionParamsChanged: function conditionParamsChanged(conditionParams) {
        var conditions = Object.keys(conditionParams).map(function (key) {
          var conditionParam = {
            key: key.toString()
          };
          var value = conditionParams[key];

          if (_typeof(value) === 'object') {
            conditionParam.values = [value.amount, value.comparator];
          } else {
            conditionParam.value = value;
          }

          return conditionParam;
        });
        this.onDiscountConditionChange(conditions);
      },
      conditionValuesChanged: function conditionValuesChanged() {// NOOP
      }
    }
  });

  _exports.default = _default;
});