define("herer-web/mixins/colorable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ColorableMixin = Ember.Mixin.create({
    launchDarkly: Ember.inject.service(),
    color: (0, _model.attr)('number', {
      defaultValue: 7
    }),
    colorClass: Ember.computed('color', function () {
      // if you update the colors here,
      // be sure to update the app/components/color-picker.js
      // correspondingly
      if (this.get('launchDarkly').can('cpc-new-brand-colors')) {
        switch (this.get('color')) {
          case 0:
            return 'diesel-green-900';

          case 1:
            return 'diesel-green-600';

          case 2:
            return 'diesel-green-300';

          case 3:
            return 'cerulean-900';

          case 4:
            return 'cerulean-600';

          case 5:
            return 'onyx-600';

          case 6:
            return 'golden-goat-900';

          case 7:
            return 'wine-900';

          case 8:
            return 'wine-600';

          case 9:
            return 'grapes-900';

          case 10:
            return 'grapes-600';

          case 11:
            return 'grapes-300';
        }
      } else {
        switch (this.get('color')) {
          case 0:
            return 'dark-green';

          case 1:
            return 'medium-green';

          case 2:
            return 'green';

          case 3:
            return 'light-green';

          case 4:
            return 'dark-gray';

          case 5:
            return 'gray';

          case 6:
            return 'light-gray';

          case 7:
            return 'blue';

          case 8:
            return 'cyan';

          case 9:
            return 'orange';

          case 10:
            return 'yellow';

          case 11:
            return 'magenta';
        }
      }
    })
  });
  var _default = ColorableMixin;
  _exports.default = _default;
});