define("herer-web/serializers/product", ["exports", "@ember-data/serializer/rest", "active-model-adapter", "moment"], function (_exports, _rest, _activeModelAdapter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      weight: {
        embedded: 'always'
      },
      image_url: {
        embedded: 'always'
      },
      inventory_item_places: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.products) {
        payload.products.forEach(function (product) {
          _this.normalizeProduct(product);
        });
      } else if (payload.product) {
        this.normalizeProduct(payload.product);
      }

      return this._super.apply(this, arguments);
    },
    normalizeProduct: function normalizeProduct(product) {
      var lastImage = product.images && product.images.length && product.images[product.images.length - 1];

      if (lastImage) {
        product.image_id = lastImage.id;
        product.image_url = lastImage.url;
        product.image_created_at = (0, _moment.default)(lastImage.created_at).format('YYYY-MM-DD');
        product.image_file_name = lastImage.image_file_name;
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      var record = snapshot.record;
      var encodedImageData = record.get('croppedPhoto');
      var imageId = record.get('image_id');
      var imageFileName = record.get('image_file_name');
      var imageAttributes = Ember.isPresent(imageId) ? {
        id: imageId
      } : {};

      if (Ember.isPresent(encodedImageData)) {
        imageAttributes.image_file_name = imageFileName;
        imageAttributes.image = encodedImageData;
      }

      json.images_attributes = Object.keys(imageAttributes).length ? [imageAttributes] : [];
      delete json.cropped_photo;
      delete json.raw_photo;
      return json;
    }
  });

  _exports.default = _default;
});