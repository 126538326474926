define("herer-web/components/untracked-migration-export", ["exports", "herer-web/components/other-report"], function (_exports, _otherReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _otherReport.default.extend({
    header: 'Untracked Migration Export',
    message: 'This export is during the migration to Metrc.',
    endPoint: 'reports/untracked_migration_export'
  });

  _exports.default = _default;
});