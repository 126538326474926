define("herer-web/components/bubble-chart", ["exports", "herer-web/mixins/resize-handler", "lodash/throttle", "lodash/flatten"], function (_exports, _resizeHandler, _throttle, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resizeHandler.default, {
    report: null,
    onResize: Ember.computed(function () {
      var func = function func() {
        return this.draw();
      };

      return $.proxy(func, this);
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      return this.draw();
    },
    draw: Ember.observer('report', (0, _throttle.default)(function () {
      var chart = this.get('chart');
      var width = this.$().width();
      var height = Math.round(width * 0.289);
      chart.width(width).height(height);
      var data = this.get('report');
      var group = data.get('groups')[0];
      var values = (0, _flatten.default)(group.get('content').map(function (row) {
        return row.get('cells').map(function (c) {
          return parseFloat(c.get('value'));
        });
      }));
      var min = d3.min(values);
      var max = d3.max(values);
      var colorScale = d3.scale.linear().domain([min, max]).range(['#CCFF99', '#009900']);
      chart.columns(function () {
        return data.get('columns');
      });
      chart.radiusScale.domain([min, max]);
      chart.colorScale(colorScale);
      return chart.draw(group);
    }, 100)),
    chart: Ember.computed(function () {
      var chart = d3.select(this.$()[0]).insert('svg').chart('BubbleMatrix').color(function (d) {
        return d;
      }).size(function (d) {
        return d;
      }).rows(function (d) {
        return d.content;
      }).rowKey(function (d) {
        return d.header;
      }).rowHeader(function (d) {
        return d.header;
      }).rowData(function (d) {
        return d.cells.map(function (c) {
          return parseFloat(c.value);
        });
      });
      return chart;
    })
  });

  _exports.default = _default;
});