define("herer-web/components/currency-input", ["exports", "herer-web/helpers/format-unit", "big.js", "lodash/isNumber"], function (_exports, _formatUnit, _big, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.TextField.extend({
    currency: null,
    onUpdate: null,
    focused: false,
    keyPressRegex: /[\d\.]/g,
    keyPressLimitedCharacters: ['.'],
    init: function init() {
      this._super();

      this._formatCurrency();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (!this.get('focused')) {
        this._formatCurrency();
      }
    },
    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);

      this._formatCurrency();
    },
    keyPress: function keyPress(event) {
      var character = String.fromCharCode(event.which);
      var regex = this.get('keyPressRegex');
      var allowed = regex.test(character);

      if (!allowed) {
        return false;
      }

      var value = this.get('value');

      var _iterator = _createForOfIteratorHelper(this.get('keyPressLimitedCharacters')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var limitedCharacter = _step.value;

          if (character === limitedCharacter && value && value.includes(limitedCharacter)) {
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return true;
    },
    _value: null,
    value: Ember.computed('_value', {
      get: function get() {
        return this.get('_value');
      },
      set: function set(key, value) {
        var newValue = value === '.' ? '0.' : value;
        this.set('_value', newValue);

        var newCurrency = this._parseBig(newValue);

        if (newCurrency) {
          newCurrency = Math.round(newCurrency.times(100));
        }

        this.onUpdate && this.onUpdate(newCurrency);
        return newValue;
      }
    }),
    _parseBig: function _parseBig(value) {
      try {
        if (Ember.isEmpty(value)) {
          return null;
        }

        return new _big.default(value.match(this.get('keyPressRegex')).join(''));
      } catch (error) {
        if (error.name === 'BigError' && error.message === 'NaN') {
          return null;
        } else {
          throw error;
        }
      }
    },
    _formatCurrency: function _formatCurrency() {
      var currency = this.get('currency');

      if ((0, _isNumber.default)(currency)) {
        this.set('_value', (0, _formatUnit.formatCents)(currency));
      }
    }
  });

  _exports.default = _default;
});