define("herer-web/components/permissions-list", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var groupedPermissions = [{
    name: 'Make Sales',
    selector: 'make_sales',
    description: 'These permissions allow a user to work in a shift and ring up sales.',
    beta: false,
    permissions: [{
      value: 'create_sales',
      beta: false
    }, {
      value: 'skip_sales_warning',
      beta: false
    }, {
      value: 'skip_sales_below_zero_warning',
      beta: false
    }, {
      value: 'view_products',
      beta: false
    }, {
      value: 'start_shift',
      beta: false
    }, {
      value: 'update_shift',
      beta: false
    }, {
      value: 'manually_add_item',
      beta: false
    }, {
      value: 'manually_verify_age',
      beta: false
    }, {
      value: 'create_employee_sales',
      beta: false
    }, {
      name: 'Create deliveries',
      value: 'create_delivery_manifests',
      beta: false,
      licenseFeature: 'delivery_manifests delivery'
    }]
  }, {
    name: 'Alter Sales',
    selector: 'alter_sales',
    description: 'These permissions allow a user to discount, void, or accept returns of sales.',
    beta: false,
    permissions: [{
      value: 'apply_discounts',
      beta: false
    }, {
      value: 'create_returns',
      beta: false
    }, {
      value: 'create_voids',
      beta: false
    }, {
      value: 'create_employee_returns',
      beta: false
    }, {
      value: 'create_employee_voids',
      beta: false
    }]
  }, {
    name: 'Manage Shifts',
    selector: 'manage_shifts',
    description: 'These permissions allow a user to edit sales and manage paid in and out.',
    beta: false,
    permissions: [{
      value: 'update_sales',
      beta: false
    }, {
      value: 'administer_shifts',
      beta: false
    }, {
      value: 'create_paid_in_and_out',
      beta: false
    }, {
      value: 'update_paid_in_and_out',
      beta: false
    }, {
      value: 'open_drawer',
      beta: false
    }, {
      value: 'view_shift_report',
      beta: false
    }]
  }, {
    name: 'Analyze Sales',
    selector: 'analyze_sales',
    description: 'These permissions allow a user to view sales reports.',
    beta: false,
    permissions: [{
      value: 'view_sales_by_reports',
      beta: false
    }, {
      value: 'view_sales_summary_report',
      beta: false
    }, {
      value: 'analyze_sales_on_home_page',
      beta: false
    }]
  }, {
    name: 'Access Backoffice',
    selector: 'access_backoffice',
    description: 'This permission dictates whether a user can access the Backoffice.',
    beta: false,
    permissions: [{
      value: 'access_backoffice',
      beta: false
    }]
  }, {
    name: 'Manage Inventory',
    selector: 'manage_inventory',
    description: 'These permissions allow a user to receive, return, and edit inventory.',
    beta: false,
    permissions: [{
      value: 'receive_inventory',
      beta: false
    }, {
      value: 'return_inventory',
      beta: false
    }, {
      value: 'audit_inventory',
      beta: false
    }, {
      value: 'adjust_inventory',
      beta: false
    }, {
      value: 'update_inventory',
      beta: false
    }, {
      value: 'view_packages',
      beta: false
    }, {
      value: 'view_package_history_events',
      beta: false
    }, {
      value: 'update_packages',
      beta: false
    }, {
      value: 'create_products',
      beta: false
    }, {
      value: 'update_products',
      beta: false
    }, {
      value: 'destroy_products',
      beta: false
    }, {
      value: 'view_product_types',
      beta: false
    }, {
      value: 'create_product_types',
      beta: false
    }, {
      value: 'update_product_types',
      beta: false
    }, {
      value: 'destroy_product_types',
      beta: false
    }, {
      value: 'view_brands',
      beta: false
    }, {
      value: 'create_brands',
      beta: false
    }, {
      value: 'update_brands',
      beta: false
    }, {
      value: 'destroy_brands',
      beta: false
    }, {
      value: 'view_strains',
      beta: false
    }, {
      value: 'create_strains',
      beta: false
    }, {
      value: 'update_strains',
      beta: false
    }, {
      value: 'destroy_strains',
      beta: false
    }, {
      value: 'view_suppliers',
      beta: false
    }, {
      value: 'create_suppliers',
      beta: false
    }, {
      value: 'update_suppliers',
      beta: false
    }, {
      value: 'destroy_suppliers',
      beta: false
    }, {
      value: 'manage_places',
      beta: false,
      showOn: 'currentLocation.places_enabled'
    }, {
      value: 'view_places',
      beta: false,
      showOn: 'currentLocation.places_enabled'
    }, {
      value: 'manage_account_product_templates',
      beta: false,
      name: 'Manage Account Wide Products',
      hide: true
    }]
  }, {
    name: 'Manage Pricing Tiers',
    selector: 'manage_pricing_tiers',
    description: 'These permissions allow a user to create and edit pricing tiers.',
    beta: false,
    feature: 'weight-based-inventory',
    permissions: [{
      value: 'view_tiered_pricing_models',
      beta: false
    }, {
      value: 'create_tiered_pricing_models',
      beta: false
    }, {
      value: 'update_tiered_pricing_models',
      beta: false
    }, {
      value: 'destroy_tiered_pricing_models',
      beta: false
    }]
  }, {
    name: 'Manage Roles',
    selector: 'manage_roles',
    description: 'These permissions allow a user to create, edit, and remove roles.',
    beta: false,
    permissions: [{
      value: 'view_roles',
      beta: false
    }, {
      value: 'create_roles',
      beta: false
    }, {
      value: 'update_roles',
      beta: false
    }, {
      value: 'destroy_roles',
      beta: false
    }]
  }, {
    name: 'Manage Taxes',
    selector: 'manage_taxes',
    description: 'These permissions allow a user to create and edit taxes.',
    beta: false,
    permissions: [{
      value: 'view_tax_items',
      beta: false
    }, {
      value: 'create_tax_items',
      beta: false
    }, {
      value: 'update_tax_items',
      beta: false
    }, {
      value: 'view_tax_reports',
      beta: false
    }]
  }, {
    name: 'Manage Devices',
    selector: 'manage_devices',
    description: 'These permissions allow a user to create, edit, and remove devices.',
    beta: false,
    permissions: [{
      value: 'create_devices',
      beta: false
    }, {
      value: 'update_devices',
      beta: false
    }, {
      value: 'destroy_devices',
      beta: false
    }]
  }, {
    name: 'Manage Users',
    selector: 'manage_users',
    description: 'These permissions allow a user to create, edit, and remove users.',
    beta: false,
    permissions: [{
      value: 'create_users',
      beta: false
    }, {
      value: 'update_users',
      beta: false
    }, {
      value: 'destroy_users',
      beta: false
    }, {
      value: 'view_user_pin',
      beta: false
    }, {
      value: 'reset_user_pin',
      beta: false
    }]
  }, {
    name: 'Manage Store Settings',
    selector: 'manage_store_settings',
    description: 'These permissions allow a user to update location settings.',
    beta: false,
    permissions: [{
      value: 'update_company_settings',
      beta: false
    }, {
      value: 'view_company_settings',
      beta: false
    }, {
      value: 'view_payment_methods',
      beta: false
    }, {
      value: 'update_payment_methods',
      beta: false
    }, {
      value: 'edit_license_number',
      beta: false
    }, {
      value: 'export_greenbits_data_to_leaflogix',
      beta: false
    }, {
      value: 'manage_pin_debit',
      beta: false
    }]
  }, {
    name: 'Manage Customers',
    selector: 'manage_customers',
    description: 'These permissions allow a user to view and edit customers.',
    beta: false,
    feature: 'recreational',
    permissions: [{
      value: 'view_customers',
      beta: false
    }, {
      value: 'update_customers',
      beta: false
    }, {
      value: 'destroy_customers',
      beta: false
    }, {
      value: 'person_group_updates_for_employees'
    }]
  }, {
    name: 'Manage Patients',
    selector: 'manage_patients',
    description: 'These permissions allow a user to update patients.',
    feature: 'medical',
    beta: false,
    permissions: [{
      value: 'view_patients',
      beta: false
    }, {
      value: 'create_patients',
      beta: false
    }, {
      value: 'update_patients',
      beta: false
    }]
  }, {
    name: 'Manage Employees',
    selector: 'manage_employees',
    description: 'These permissions allow a user to create, edit, and remove employees.',
    beta: false,
    permissions: [{
      value: 'create_employees',
      beta: false
    }, {
      value: 'update_employees',
      beta: false
    }, {
      value: 'view_employees',
      beta: false
    }, {
      value: 'destroy_employees',
      beta: false
    }]
  }, {
    name: 'Manage Discounts',
    selector: 'manage_discounts',
    description: 'These permissions allow a user to create and edit discount plans.',
    beta: false,
    permissions: [{
      value: 'view_discount_plans',
      beta: false
    }, {
      value: 'create_discount_plans',
      beta: false
    }, {
      value: 'update_discount_plans',
      beta: false
    }]
  }, {
    name: 'Manage Menu Feeds',
    selector: 'manage_menu_feeds',
    description: 'These permissions allow a user to create, edit, and remove menu feeds.',
    beta: false,
    permissions: [{
      value: 'create_menu_feeds',
      beta: false
    }, {
      value: 'update_menu_feeds',
      beta: false
    }, {
      value: 'view_menu_feeds',
      beta: false
    }, {
      value: 'destroy_menu_feeds',
      beta: false
    }]
  }, {
    name: 'Manage Loyalty Program, Rewards, and Points',
    selector: 'manage_loyalty_program_rewards_and_points',
    description: 'These permissions allow a user to manage all aspects of the loyalty program.',
    beta: false,
    permissions: [{
      value: 'create_loyalty_rewards',
      beta: false
    }, {
      value: 'update_loyalty_rewards',
      beta: false
    }, {
      value: 'view_loyalty_rewards',
      beta: false
    }, {
      value: 'destroy_loyalty_rewards',
      beta: false
    }, {
      value: 'adjust_loyalty_points',
      beta: false
    }, {
      value: 'update_loyalty_program',
      beta: false
    }]
  }, {
    name: 'Manage Traceability',
    selector: 'manage_traceability',
    description: 'This permission allows this role to receive notifications' + ' about traceability reporting failures',
    beta: false,
    featureFlag: 'manage-traceability-permission',
    permissions: [{
      value: 'manage_traceability',
      beta: false,
      featureFlag: 'manage-traceability-permission'
    }]
  }];

  function createPermissionData(permission, permissions) {
    var permissionData = Ember.Object.create(permission);

    if (Ember.isEmpty(permissionData.name)) {
      permissionData.set('name', permission.value.replace(/_/g, ' ').capitalize());
    } else {
      permissionData.set('name', permission.name);
    }

    permissionData.set('checked', permissions.indexOf(permission.value) > -1);
    return permissionData;
  }

  function createGroupData(group, userPermissions, context) {
    var groupData = Ember.Object.create(group);
    var hasPermissionInGroup = false;
    var haveAllPermissionsInGroup = true;
    var permissionsData = groupData.get('permissions').reduce(function (permissions, permissionInGroup) {
      if (permissionInGroup.showOn && !context.get(permissionInGroup.showOn)) {
        return permissions;
      }

      var permissionData = createPermissionData(permissionInGroup, userPermissions);
      var checked = permissionData.get('checked');
      hasPermissionInGroup = hasPermissionInGroup || checked;
      haveAllPermissionsInGroup = haveAllPermissionsInGroup && checked;
      return [].concat(_toConsumableArray(permissions), [permissionData]);
    }, []);
    groupData.set('permissionsData', permissionsData);
    groupData.set('indeterminate', false);

    if (haveAllPermissionsInGroup) {
      groupData.set('checked', true);
      groupData.set('showPermissions', false);
    } else if (hasPermissionInGroup) {
      groupData.set('checked', false);
      groupData.set('indeterminate', true);
      groupData.set('showPermissions', true);
    }

    return groupData;
  }

  var _default = Ember.Component.extend(_context.default, {
    permissions: null,
    onPermissionsChange: null,
    disabled: false,
    groupedPermissionsData: Ember.computed('permissions', function () {
      var _this = this;

      var permissions = this.get('permissions');
      permissions = Ember.isPresent(permissions) ? permissions : [];
      return groupedPermissions.map(function (group) {
        return createGroupData(group, permissions, _this);
      });
    }),
    actions: {
      checkGroup: function checkGroup(groupData, checked) {
        var permissions = this.get('permissions');
        var groupPermissions = groupData.get('permissionsData');
        var newPermissions;

        if (checked) {
          newPermissions = permissions.concat(groupPermissions.map(function (p) {
            return p.get('value');
          }));
        } else {
          newPermissions = permissions.reject(function (permission) {
            return groupPermissions.any(function (gp) {
              return gp.get('value') === permission;
            });
          });
        }

        this.onPermissionsChange && this.onPermissionsChange(newPermissions);
      },
      checkPermission: function checkPermission(permissionData, checked) {
        var permissions = this.get('permissions');
        var permissionValue = permissionData.get('value');
        var newPermissions;

        if (checked) {
          newPermissions = permissions.concat(permissionValue);
        } else {
          newPermissions = permissions.reject(function (permission) {
            return permission === permissionValue;
          });
        }

        this.onPermissionsChange && this.onPermissionsChange(newPermissions);
      },
      toggleShowPermissions: function toggleShowPermissions(groupData) {
        groupData.set('showPermissions', !groupData.get('showPermissions'));
      }
    }
  });

  _exports.default = _default;
});