define("herer-web/models/person", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/utils/dates-intervals", "moment"], function (_exports, _model, _constants, _datesIntervals, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    account_id: (0, _model.attr)('string'),
    address_1: (0, _model.attr)('string'),
    address_2: (0, _model.attr)('string'),
    birth_date: (0, _model.attr)('day'),
    caregiver_first_name: (0, _model.attr)('string'),
    caregiver_id_number: (0, _model.attr)('string'),
    caregiver_last_name: (0, _model.attr)('string'),
    carry_limit: (0, _model.attr)('weight'),
    city: (0, _model.attr)('string'),
    driver_license_expiration_date: (0, _model.attr)('day'),
    driver_license_number: (0, _model.attr)('string'),
    driver_license_state: (0, _model.attr)('string'),
    driver_license_zip_code: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    expiration_date: (0, _model.attr)('day'),
    first_name: (0, _model.attr)('string'),
    gender: (0, _model.attr)('string'),
    hire_date: (0, _model.attr)('day'),
    id_number: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    location_id: (0, _model.attr)('string'),
    loyalty_points: (0, _model.attr)('number'),
    loyalty_rewards_identifier: (0, _model.attr)('string'),
    membership_date: (0, _model.attr)('day'),
    notes: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    physician_name: (0, _model.attr)('string'),
    plant_count: (0, _model.attr)('number'),
    queued_at: (0, _model.attr)('date'),
    referral_source: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    type: (0, _model.attr)('number'),
    zip_code: (0, _model.attr)('string'),
    is_deleted: (0, _model.attr)('boolean'),
    fileAttachments: (0, _model.hasMany)('file-attachment', {
      async: false
    }),
    checkIns: (0, _model.hasMany)('check-in', {
      async: true
    }),
    personGroups: (0, _model.hasMany)('person-group', {
      async: true
    }),
    orders: (0, _model.hasMany)('order', {
      async: false
    }),
    launchDarkly: Ember.inject.service(),
    loyaltyPoints: Ember.computed.alias('loyalty_points'),
    pinPersonGroups: function pinPersonGroups() {
      this.set('_pinnedPersonGroups', this.get('personGroups').toArray());
    },
    isSavingAttachments: Ember.computed('fileAttachments.@each.isSaving', function () {
      var isSaving = this.get('fileAttachments').reduce(function (prev, fileAttachment) {
        return prev || fileAttachment.get('isSaving');
      }, false);
      return isSaving;
    }),
    isSaving: Ember.computed('isSavingAttachments', 'currentState', function () {
      return this.get('_internalModel.currentState.isSaving') || this.get('isSavingAttachments');
    }),
    isDirty: function isDirty() {
      return this.hasDirtyAttributes || this.fileAttachments.any(function (file) {
        return file.hasDirtyAttributes;
      });
    },
    name: Ember.computed('first_name', 'last_name', 'email', 'phone', 'is_deleted', function () {
      var nameComponents = [];

      if (Ember.isPresent(this.get('first_name')) || Ember.isPresent(this.get('last_name'))) {
        nameComponents.push(this.get('first_name'), this.get('last_name'));
      } else if (Ember.isPresent(this.get('email'))) {
        nameComponents.push(this.get('email'));
      } else {
        nameComponents.push(this.get('phone'));
      }

      if (this.get('is_deleted')) {
        nameComponents.push('(deleted)');
      }

      return nameComponents.filter(Boolean).join(' ');
    }),
    lastNameFirstName: Ember.computed('first_name', 'last_name', function () {
      var names = [];

      if (Ember.isPresent(this.get('last_name'))) {
        names.push(this.get('last_name'));
      }

      if (Ember.isPresent(this.get('first_name'))) {
        names.push(this.get('first_name'));
      }

      return names.join(', ');
    }),
    emailPhone: Ember.computed('email', 'phone', function () {
      var parts = [];

      if (Ember.isPresent(this.get('email'))) {
        parts.push(this.get('email'));
      }

      if (Ember.isPresent(this.get('phone'))) {
        parts.push(this.get('phone'));
      }

      return parts.join(', ');
    }),
    isPatient: Ember.computed('type', 'personGroups.@each.medical', 'launchDarkly.isReady', function () {
      if (this.get('launchDarkly').can('bw-person-group')) {
        return this.get('personGroups').any(function (group) {
          return group.get('medical');
        });
      } else {
        return this.get('type') === _constants.default.personType.patient;
      }
    }),
    isCustomer: Ember.computed('type', function () {
      return this.get('type') === _constants.default.personType.customer;
    }),
    isEmployee: Ember.computed('type', function () {
      return this.get('type') === _constants.default.personType.employee;
    }),
    isMember: Ember.computed.notEmpty('membership_date'),
    typeString: Ember.computed('type', 'personGroups', function () {
      if (this.get('personGroups.length')) {
        return this.get('personGroups').objectAt(0).get('name');
      }

      switch (this.get('type')) {
        case _constants.default.personType.employee:
          return 'Employee';

        case _constants.default.personType.patient:
          return 'Patient';

        default:
          return 'Customer';
      }
    }),
    modelTypeString: Ember.computed('isPatient', 'typeString', function () {
      var isPatient = this.get('isPatient');
      var modelType = this.get('typeString').toLowerCase();

      if (isPatient) {
        return 'patient';
      } else {
        return modelType;
      }
    }),
    isUnderage: Ember.computed('type', 'birth_date', function () {
      var birthDate = this.get('birth_date');

      if (Ember.isEmpty(birthDate)) {
        return false;
      }

      var age = (0, _moment.default)().diff(birthDate, 'years');
      return this.get('type') === _constants.default.personType.patient ? age < 18 : age < 21;
    }),
    hasExpiredLicense: Ember.computed('driver_license_expiration_date', function () {
      return (0, _datesIntervals.isExpired)(this.get('driver_license_expiration_date'));
    }),
    isPastExpirationDate: Ember.computed('expiration_date', function () {
      return (0, _datesIntervals.isExpired)(this.get('expiration_date'));
    }),
    hasReferralSourceOther: Ember.computed('referral_source', function () {
      var referralSource = this.get('referral_source');
      return referralSource && (_constants.default.referralSourceOptions.indexOf(referralSource) === -1 || referralSource === 'Other' || referralSource === 'Select a referral source');
    }),
    totalValueSpent: Ember.computed('orders.@each.total', function () {
      return this.get('orders').sum('total');
    }),
    caregiverName: Ember.computed('caregiver_id_number', 'caregiver_first_name', 'caregiver_last_name', function () {
      var idNumber = this.get('caregiver_id_number');

      if (Ember.isPresent(idNumber)) {
        idNumber = "(".concat(idNumber, ")");
      }

      return [this.get('caregiver_first_name'), this.get('caregiver_last_name'), idNumber].filter(Boolean).join(' ');
    }),
    rollbackAttributes: function rollbackAttributes() {
      if (this.get('isNew') && Ember.isPresent(this.get('_pinnedPersonGroups'))) {
        this.set('personGroups', this.get('_pinnedPersonGroups'));
      }

      this.get('fileAttachments').toArray().forEach(function (fileAttachment) {
        if (!fileAttachment.get('isDestroyed') && !fileAttachment.get('isDestroying')) {
          fileAttachment.rollbackAttributes();
        }

        return;
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});