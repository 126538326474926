define("herer-web/routes/packages/package", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    resetScroll: false,
    model: function model(params) {
      return this.get('store').findRecord('package', params.package_id);
    }
  });

  _exports.default = _default;
});