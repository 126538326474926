define("herer-web/components/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    header: null,
    subheader: null,
    accountWide: false,
    custom: false,
    width: 7
  });

  _exports.default = _default;
});