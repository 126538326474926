define("herer-web/routes/authenticated-full-page", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    renderTemplate: function renderTemplate() {
      this.render('full-page-layout');
      this.render(this.get('templateName') || this.get('routeName'), {
        into: 'full-page-layout',
        outlet: 'content'
      });
    }
  });

  _exports.default = _default;
});