define("herer-web/components/packages/package-reconciler/find-discrepancies", ["exports", "herer-web/mixins/package-reconciliation/request-modal"], function (_exports, _requestModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_requestModal.default, {});

  _exports.default = _default;
});