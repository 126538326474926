define("herer-web/routes/return-manifest-new", ["exports", "ember-uuid/utils/uuid-generator", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route", "herer-web/constants", "moment"], function (_exports, _uuidGenerator, _authenticatedFullPage, _alertUnsavedRoute, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var returnManifestType = _constants.default.returnManifestType;

  var _default = _authenticatedFullPage.default.extend(_alertUnsavedRoute.default, {
    permissionKey: 'return_inventory',
    resetController: function resetController(controller) {
      controller.set('lotNumber', '');
      controller.set('addLotError', '');
      controller.set('addLotDisabled', false);
    },
    model: function model() {
      var store = this.get('store');
      var manifest = store.createRecord('return_manifest', {
        id: _uuidGenerator.default.v4(),
        departure_date: (0, _moment.default)().add(1, 'hour').toDate(),
        return_manifest_type: returnManifestType.pickup
      });
      manifest.employee = store.createFragment('driver');
      manifest.vehicle = store.createFragment('vehicle');
      return manifest;
    }
  });

  _exports.default = _default;
});