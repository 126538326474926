define("herer-web/routes/weekly-sales", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/pivot-table/table", "herer-web/constants", "moment"], function (_exports, _authenticatedFullPage, _table, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    http: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        return _this.get('permissionsManager').verify('view_sales_by_reports');
      });
    },
    actions: {
      refresh: function refresh() {
        return this.refresh();
      }
    },
    validMeasureValues: Ember.computed(function () {
      return Ember.merge({
        transactions: 'transactions',
        'average sale': 'avg_total'
      }, _constants.default.validMeasureValues);
    }),
    model: function model(params) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = Ember.get(_this2, 'http');
        var report_params = {
          start_week: (0, _moment.default)(params.startWeek).format('YYYY-MM-DD'),
          end_week: (0, _moment.default)(params.endWeek).format('YYYY-MM-DD'),
          value: _this2.get('validMeasureValues')[params.measure]
        };
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/weekly_sales"), report_params);
        return http.get(url).then(function (response) {
          var data = response.data;

          var table = _table.default.create({
            columnName: data.column_name,
            rowName: data.row_name,
            valueName: data.value_name,
            sourceData: data.source_data,
            unit: data.unit_name,
            showRowTotals: true,
            filters: params
          });

          return resolve({
            table: table
          });
        }).catch(function (response) {
          var error = response.data && response.data.error;
          return reject({
            message: "".concat(response.status, ": ").concat(error)
          });
        });
      });
    }
  });

  _exports.default = _default;
});