define("herer-web/mixins/slide-out-drawer-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activeTab: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.activateTab(this.get('activeTab'));
    },
    activateTab: function activateTab(tabName) {
      if (tabName === null) {
        tabName = 'details';
      }

      var $currentElement = $(this.element);
      $currentElement.find('.tab-content').hide();
      $currentElement.find('.tab').removeClass('active');
      $currentElement.find(".".concat(tabName, "-tab")).addClass('active');
      $currentElement.find(".".concat(tabName, "-tab-content")).show();
      this.set('activeTab', tabName);
    }
  });

  _exports.default = _default;
});