define("herer-web/components/report-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    table: null,
    sortBy: null,
    sortDirection: null,
    tableHeadingWidth: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var sortBy = this.get('sortBy');
      var sortDirection = this.get('sortDirection');

      if (Ember.isPresent(sortBy)) {
        this.get('table').updateSort(sortBy, sortDirection);
      }
    },
    actions: {
      sortBy: function sortBy(column) {
        this.set('sortDirection', this.get('sortDirection') === 'desc' ? 'asc' : 'desc');
        this.set('sortBy', column);
      }
    }
  });

  _exports.default = _default;
});