define("herer-web/components/packages/package-splitter", ["exports", "herer-web/mixins/context", "herer-web/constants", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/inventory-weights", "bootbox", "herer-web/components/base-modal", "yup", "herer-web/utils/validation"], function (_exports, _context, _constants, _lotNumberFormatter, _inventoryWeights, _bootbox, _baseModal, _yup, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var metrcPackageRegEx = new RegExp('^(1[Aa][0-9a-fA-F]{22}|[Aa][0-9a-fA-F]{23}|[ABCDEF][0-9a-fA-F]{23})$');

  var _default = _baseModal.default.extend(_context.default, _lotNumberFormatter.default, _inventoryWeights.default, {
    authManager: Ember.inject.service(),
    featureManager: Ember.inject.service('feature-manager'),
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    modalClassName: '.package-splitter-modal',
    lotNumber: Ember.computed.readOnly('inventoryItem.lot_number'),
    validations: (0, _validation.validations)({
      isAsyncSplitLot: (0, _yup.bool)(),
      isMetrc: (0, _yup.bool)(),
      lotNumber: (0, _yup.string)().nullable().required(),
      quantity: (0, _yup.number)().nullable().transform(function (_value, quantity) {
        return Number(quantity.get('value'));
      }).moreThan(0, 'must be greater than 0').required(),
      newLotNumber: (0, _yup.string)().nullable().when('isAsyncSplitLot', {
        is: true,
        then: (0, _yup.string)().nullable().required(),
        otherwise: (0, _yup.string)().nullable()
      }).when('isMetrc', {
        is: true,
        then: (0, _yup.string)().required().matches(metrcPackageRegEx, 'is not valid').length(24, 'is the wrong length (should be 24 characters)'),
        otherwise: (0, _yup.string)().nullable()
      })
    }),
    stateCredential: null,
    package: null,
    onCancel: null,
    onSplit: null,
    inventoryItem: null,
    quantity: null,
    newLotNumber: null,
    errors: null,
    isSaving: false,
    isConfirming: false,
    isDone: false,
    isMSL: Ember.computed.alias('currentLocation.user_managed_compliance'),
    isAsyncSplitLot: Ember.computed('currentLocation', function () {
      return this.get('isMSL') || this.get('featureManager').can('async-split-lot');
    }),
    isMetrc: Ember.computed('currentLocation', function () {
      return this.get('featureManager').can('metrc');
    }),
    remainingQuantity: Ember.computed('quantity', 'inventoryItem.quantity', function () {
      return this.get('inventoryItem.quantity').minus(this.get('quantity'));
    }),
    onlyOneInventoryItem: Ember.computed('package.inventoryItems', function () {
      return Ember.isEmpty(this.get('package')) || this.get('package.inventoryItems.length') === 1;
    }),
    availableMetrcTagLink: Ember.computed('currentLocation', 'stateCredential', function () {
      var stateCredential = this.get('stateCredential'); // Handle a location not having any state credentials. We'll return null here and conditionally
      // display the link if we have a state credential.

      if (!stateCredential) {
        return null;
      }

      var currentLocation = this.get('currentLocation');
      var baseURL = stateCredential.getTraceabilityProviderURL(currentLocation);
      var licenseNumber = stateCredential.get('license_number');
      return "".concat(baseURL, "/industry/").concat(licenseNumber, "/admin/tags");
    }),
    splitLink: Ember.computed('currentLocation', function () {
      var visible = this.get('isMSL');
      var link;

      if (this.get('isMetrc')) {
        link = {
          visible: visible,
          text: 'Split the package in Metrc',
          logo: 'img/metrc_logo.png',
          logoAlt: 'Metrc logo'
        };
      } else {
        link = {
          visible: false
        };
      }

      return link;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        this.resetProperties();
        modal.modal({
          backdrop: 'static'
        });
      }
    },
    showErrorModal: function showErrorModal() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to save this split.\n      If you still have this issue, chat Support.");
    },
    resetProperties: function resetProperties() {
      this.set('isConfirming', false);
      this.set('isDone', false);
      this.set('newLotNumber', null);
      this.set('errors', []);

      if (Ember.isPresent(this.get('package'))) {
        this.set('inventoryItem', this.get('package.inventoryItems.firstObject'));
      }

      this.updateQuantity();
      this.validations.resetErrors();
    },
    updateQuantity: function updateQuantity() {
      var quantity = this.get('inventoryItem.quantity');

      if (quantity) {
        this.set('quantity', quantity.copy());
      }
    },
    actions: {
      verifyAndConfirm: function verifyAndConfirm() {
        var _this = this;

        this.get("validations").validate().then(function () {
          _this.set('isConfirming', true);
        }).catch(function () {});
      },
      unconfirm: function unconfirm() {
        this.set('isConfirming', false);
      },
      saveSplit: function saveSplit() {
        var _this2 = this;

        this.set('errors', []);
        this.set('isSaving', true);
        var url = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/inventory_items/split");
        var data = {
          from: {
            inventory_item_id: this.get('inventoryItem.id'),
            quantity: {
              value: this.get('quantity.value'),
              unit: this.get('quantity.unit')
            }
          },
          to: {
            lot_number: this.get('newLotNumber')
          }
        };
        return Ember.get(this, 'http').post(url, data).then(function () {
          // In MSL we're really done once this post succeeds, since we don't do reporting
          if (_this2.get('isMSL')) {
            _this2.onSplit();
          } else {
            _this2.set('isDone', true);
          }
        }).catch(function (response) {
          _this2.set('errors', [response.data.error]);
        }).finally(function () {
          _this2.set('isSaving', false);
        });
      },
      changeInventoryItem: function changeInventoryItem(inventoryItem) {
        this.set('inventoryItem', inventoryItem);
        this.updateQuantity();
      },
      onDone: function onDone() {
        this.onSplit();
      }
    }
  });

  _exports.default = _default;
});