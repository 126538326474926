define("herer-web/serializers/customer", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      fileAttachments: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.customers) {
        payload.customers.forEach(function (customer) {
          _this.normalizeCustomer(customer);
        });
      } else {
        this.normalizeCustomer(payload.customer);
      }

      return this._super.apply(this, arguments);
    },
    normalizeCustomer: function normalizeCustomer(customer) {
      ['first_name', 'last_name', 'phone', 'email'].forEach(function (attribute) {
        if (customer[attribute] === null) {
          customer[attribute] = '';
        }
      });
    }
  });

  _exports.default = _default;
});