define("herer-web/models/inventory-item-place", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    quantity: (0, _model.attr)('weight'),
    sellable_quantity: (0, _model.attr)('weight'),
    unlocked_quantity: (0, _model.attr)('weight'),
    inventory_status: (0, _model.attr)('number'),
    place: (0, _model.belongsTo)('place', {
      async: false
    }),
    inventory_item: (0, _model.belongsTo)('inventory-item', {
      async: false,
      embedded: 'always'
    }),
    name: Ember.computed.alias('place.name'),
    isForSale: Ember.computed('inventory_status', function () {
      return this.get('inventory_status') === _constants.default.placeInventoryStatus.forSale;
    }),
    isLocked: Ember.computed('inventory_status', function () {
      return this.get('inventory_status') === _constants.default.placeInventoryStatus.locked;
    }),
    hasNonZeroQuantity: Ember.computed('quantity', function () {
      return this.get('quantity.value') !== 0;
    }),
    hasUnlockedPositiveQuantity: Ember.computed('unlocked_quantity', 'isLocked', function () {
      return !this.get('isLocked') && this.get('unlocked_quantity.value') > 0;
    })
  });

  _exports.default = _default;
});