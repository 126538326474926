define("herer-web/utils/people", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canCheckIn = canCheckIn;
  _exports.personTypeMatchesLocation = personTypeMatchesLocation;
  _exports.availablePersonTypes = availablePersonTypes;
  _exports.availableCustomerGroups = availableCustomerGroups;
  _exports.savePerson = savePerson;
  _exports.disabledCheckInReason = disabledCheckInReason;

  function hasExpiredPatientId(person) {
    return person.get('isPatient') && person.get('isPastExpirationDate');
  }

  function canCheckIn(location, person, featureManager) {
    if (!person || !location.get('queue')) {
      return false;
    }

    if (person.get('isUnderage') && location.get('verify_age')) {
      return false;
    }

    if (person.get('isNew') || person.get('hasExpiredLicense') || hasExpiredPatientId(person) || person.get('hasDirtyAttributes')) {
      return false;
    }

    return personTypeMatchesLocation(location, person, featureManager);
  }

  function disabledCheckInReason(location, person, featureManager) {
    var personType = person.get('typeString');

    if (person.get('isNew') || person.get('hasDirtyAttributes')) {
      return "".concat(personType, " has not been saved.");
    } else if (!location.get('queue')) {
      return 'Customer queue has not been enabled for this location.';
    } else if (person.get('isUnderage') && location.get('verify_age')) {
      return "".concat(personType, " is underage.");
    } else if (person.get('hasExpiredLicense')) {
      return "".concat(personType, " has an expired ID.");
    } else if (hasExpiredPatientId(person)) {
      return "".concat(personType, " has an expired medical license.");
    } else if (!personTypeMatchesLocation(location, person, featureManager)) {
      switch (person.get('type')) {
        case _constants.default.personType.patient:
          return 'Medical patients cannot currently be checked in to this recreational store.';

        case _constants.default.personType.customer:
          return 'Recreational customers cannot check into this medical dispensary.';

        case _constants.default.personType.employee:
          return 'Employee sampling is allowed under this business license.';
      }
    } else {
      return '';
    }
  } // Remove this code once person type has been removed from the api


  function personTypeMatchesLocation(location, person, featureManager) {
    if (!person) {
      return false;
    }

    switch (person.get('type')) {
      case _constants.default.personType.patient:
        return location.get('medical');

      case _constants.default.personType.customer:
        return location.get('recreational');

      case _constants.default.personType.employee:
        return featureManager.can('employee-sampling');
    }
  } // Remove this code once person type has been removed from the api


  function availablePersonTypes(location, person, featureManager) {
    var personTypes = _constants.default.personTypes;

    if (!personTypeMatchesLocation(location, person, featureManager)) {
      return personTypes;
    }

    if (!featureManager.can('employee-sampling')) {
      personTypes = personTypes.reject(function (t) {
        return t.value === _constants.default.personType.employee;
      });
    }

    if (!location.get('medical')) {
      personTypes = personTypes.reject(function (t) {
        return t.value === _constants.default.personType.patient;
      });
    }

    if (!location.get('recreational')) {
      personTypes = personTypes.reject(function (t) {
        return t.value === _constants.default.personType.customer;
      });
    }

    return personTypes;
  }

  function availableCustomerGroups(location, availablePersonGroups) {
    var personGroups = availablePersonGroups;
    personGroups = personGroups.map(function (personGroup) {
      return {
        name: personGroup.get('name'),
        value: personGroup.get('id'),
        medical: personGroup.get('medical')
      };
    });
    var isRec = location.get('recreational');
    var isMed = location.get('medical');

    if (isRec && isMed) {
      return personGroups;
    } else if (isMed) {
      return personGroups.filterBy('medical');
    } else if (isRec) {
      return personGroups.rejectBy('medical');
    }
  }

  function savePerson(person) {
    return person.save().then(function (person) {
      var promises = person.get('fileAttachments').map(function (fileAttachment) {
        if (!fileAttachment.get('isNew')) {
          return Ember.RSVP.resolve();
        }

        var type = _constants.default.personTypes.find(function (t) {
          return t.value === person.get('type');
        });

        fileAttachment.set('attachableId', person.get('id'));
        fileAttachment.set('attachableType', type.name);
        return fileAttachment.save();
      });
      return Ember.RSVP.all(promises);
    }).then(function () {
      return person;
    });
  }
});