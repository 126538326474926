define("herer-web/services/task-manager", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    store: Ember.inject.service(),
    pushManager: Ember.inject.service(),
    downloader: Ember.inject.service(),
    desktopNotifier: Ember.inject.service(),
    toast: Ember.inject.service(),
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    queued: 0,
    processing: 0,
    success: 0,
    error: 0,
    claimed: 0,
    timer: null,
    pollingInterval: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('ENG-45166-polling-tasks-interval', 60);
    }),
    reset: function reset() {
      var _this = this;

      this.poll();
      this.get('pushManager').bind('task-success', function (data) {
        _this.poll();

        var hasFile = Ember.isPresent(data.result.url);
        var title = "".concat(data.name, " ").concat(hasFile ? 'ready' : 'complete', "!");
        var message = hasFile ? 'Click here to download.' : '';

        var clickHandler = function clickHandler() {
          if (hasFile) {
            _this.get('downloader').downloadFile(data.result.url);

            _this.claim(data.id);
          }
        };

        _this.notify('success', title, message, clickHandler);
      });
      this.get('pushManager').bind('task-error', function (data) {
        _this.poll();

        var title = "".concat(data.name, " failed!");
        var message = 'Something went wrong when processing this task.';

        _this.notify('error', title, message);
      });
    },
    poll: function poll() {
      var _this2 = this;

      if (Ember.isPresent(this.get('timer'))) {
        clearTimeout(this.get('timer'));
      }

      if (Ember.isEmpty(this.get('authManager.currentUser'))) {
        this.schedule(this.pollingInterval);
        return;
      }

      Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/tasks/stats")).then(function (response) {
        var data = response.data;

        _this2.set('queued', data[0]);

        _this2.set('processing', data[1]);

        _this2.set('success', data[2]);

        _this2.set('error', data[3]);

        _this2.set('claimed', data[4]);
      }).finally(function () {
        _this2.schedule(_this2.pollingInterval);
      });
    },
    schedule: function schedule(seconds) {
      this.set('timer', setTimeout(this.poll.bind(this), seconds * 1000));
    },
    notify: function notify(type, title, message, clickHandler) {
      if (this.get('desktopNotifier.canNotify')) {
        this.get('desktopNotifier').notify(title, message, clickHandler);
      } else {
        this.get('toast')[type](message, title, {
          onclick: clickHandler
        });
      }
    },
    claim: function claim(taskId) {
      var _this3 = this;

      var task = this.get('store').peekRecord('task', taskId);

      if (Ember.isPresent(task)) {
        task.set('status', _constants.default.taskStatus.claimed);
        task.save().then(function () {
          _this3.poll();
        });
      }
    },
    claimAll: function claimAll() {
      var _this4 = this;

      return Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/tasks/claim")).then(function () {
        _this4.poll();
      });
    },
    retry: function retry(taskId) {
      var _this5 = this;

      return Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/tasks/").concat(taskId, "/retry")).then(function (response) {
        _this5.poll();

        return response.data.task;
      });
    },
    email: function email(taskId) {
      var _this6 = this;

      return Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/tasks/").concat(taskId, "/email")).then(function () {
        _this6.claim(taskId);

        _this6.notify('success', 'Email Successful', 'You will receive an email shortly.');
      }).catch(function () {
        _this6.notify('error', 'Email Failed', 'Failed to email task result.');
      });
    }
  });

  _exports.default = _default;
});