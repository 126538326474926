define("herer-web/components/location-status", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.computed.alias('authManager.currentLocation'),
    billingNumber: _constants.default.billingNumber,
    actions: {
      dismissBillingBanner: function dismissBillingBanner() {
        $('#billingBanner').hide();
      }
    }
  });

  _exports.default = _default;
});