define("herer-web/components/gb-button/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2HW/JcO/",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn-link gb-button-link\"],[13,1],[12,\"type\",\"button\"],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/gb-button/link/template.hbs"
    }
  });

  _exports.default = _default;
});