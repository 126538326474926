define("herer-web/components/product-ring-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants"], function (_exports, _dimpleChart, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    onMouseOver: null,
    onMouseOut: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.$().height(260);
    },
    customizeChart: function customizeChart(chart) {
      var _this = this;

      chart.setBounds('10%', '10%', '80%', '80%');
      chart.defaultColors = _constants.default.chartColors;
      chart.addMeasureAxis('p', 'value');
      var series = chart.addSeries('name', dimple.plot.pie);
      series.innerRadius = '50%';
      series.addEventHandler('mouseover', function (e) {
        _this.onMouseOver(e.seriesValue[0], e.selectedShape.attr('fill'));
      });
      series.addEventHandler('mouseout', function (e) {
        _this.onMouseOut(e.seriesValue[0]);
      });
      return chart;
    }
  });

  _exports.default = _default;
});