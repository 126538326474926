define("herer-web/adapters/package-history-event", ["exports", "herer-web/adapters/application", "herer-web/constants"], function (_exports, _application, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(model_name, id, snapshot, requestType, query) {
      if (query.packageId) {
        return "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/packages/").concat(query.packageId, "/history_events");
      } else {
        return this._super.apply(this, arguments);
      }
    },
    sortQueryParams: function sortQueryParams(params) {
      var newParams = Ember.copy(params, true);
      delete newParams.packageId;
      return newParams;
    }
  });

  _exports.default = _default;
});