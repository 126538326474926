define("herer-web/controllers/loyalty-program", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _alertUnsavedController, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    showDisabled: false,
    loyaltyProgram: null,
    loyaltyRewards: [],
    allTags: [],
    productTypes: [],
    roles: [],
    model: Ember.computed.alias('loyaltyRewards'),
    loyaltyProgramTypeValues: _constants.default.loyaltyProgramTypeValues,
    loyaltyRewardCalculationTypeValues: Ember.computed(function () {
      return _constants.default.discountCalculationTypeValues.slice(1);
    }),
    booleanTypes: _constants.default.booleanTypes,
    editingLoyaltyProgram: false,
    filteredLoyaltyRewards: Ember.computed('showDisabled', 'loyaltyRewards.@each.{enabled,loyaltyPoints,isNew}', function () {
      var loyaltyRewards = this.get('loyaltyRewards');
      var showDisabled = this.get('showDisabled');
      loyaltyRewards = loyaltyRewards.filter(function (loyaltyReward) {
        var hasLoyaltyPoints = Ember.isPresent(loyaltyReward.get('loyaltyPoints'));
        var show = loyaltyReward.get('enabled') || showDisabled;
        return hasLoyaltyPoints && show;
      });
      return loyaltyRewards.toArray().sort(function (a, b) {
        var result = Ember.compare(b.get('isNew'), a.get('isNew'));

        if (result !== 0) {
          return result;
        }

        return Ember.compare(a.get('loyaltyPoints'), b.get('loyaltyPoints'));
      });
    }),
    buildPointsCondition: function buildPointsCondition(value) {
      return this.get('store').createRecord('discount-condition', {
        key: 'points',
        values: [value, '>=']
      });
    },
    actions: {
      updateLoyaltyRewardConditions: function updateLoyaltyRewardConditions(loyaltyReward, conditions) {
        var store = this.get('store');
        conditions = conditions.map(function (condition) {
          return store.createRecord('discount-condition', condition);
        });
        conditions.push(this.buildPointsCondition(loyaltyReward.get('loyaltyPoints')));

        if (loyaltyReward.shouldUpdateConditions(conditions)) {
          loyaltyReward.set('conditions', conditions);
        }
      },
      showDisabled: function showDisabled() {
        this.set('showDisabled', true);
      },
      hideDisabled: function hideDisabled() {
        this.set('showDisabled', false);
      },
      setCalculationValue: function setCalculationValue(loyaltyReward, value) {
        loyaltyReward.set('calculationValue', value);
      },
      onCreate: function onCreate(newLoyaltyReward) {
        newLoyaltyReward.set('enabled', true);
        newLoyaltyReward.set('autoApply', false);
        newLoyaltyReward.set('roundToNearestDollar', false);
        newLoyaltyReward.set('accessibleRoleIds', []);
        newLoyaltyReward.set('conditions', [this.buildPointsCondition(0)]);
        newLoyaltyReward.set('isLoyaltyProgram', true);
      },
      toggleRole: function toggleRole(loyaltyReward, roleId, checked) {
        var accessibleRoleIds = loyaltyReward.get('accessibleRoleIds');

        if (Ember.isPresent(accessibleRoleIds)) {
          accessibleRoleIds = accessibleRoleIds.slice();
        } else {
          accessibleRoleIds = [];
        }

        if (checked) {
          accessibleRoleIds.push(roleId);
          accessibleRoleIds = accessibleRoleIds.uniq();
        } else {
          accessibleRoleIds = accessibleRoleIds.reject(function (id) {
            return id === roleId;
          });
        }

        if (loyaltyReward.shouldUpdateAccessibleRoleIds(accessibleRoleIds)) {
          loyaltyReward.set('accessibleRoleIds', accessibleRoleIds);
        }
      },
      updateEnabled: function updateEnabled(loyaltyReward, enabled) {
        loyaltyReward.set('enabled', !enabled);
      },
      updateLoyaltyPoints: function updateLoyaltyPoints(loyaltyReward, value) {
        var conditions = loyaltyReward.get('conditions').reject(function (c) {
          return c.get('key') === 'points';
        });
        conditions.push(this.buildPointsCondition(value));
        loyaltyReward.set('conditions', conditions);
      },
      saveLoyaltyProgram: function saveLoyaltyProgram(loyaltyProgram) {
        var _this = this;

        loyaltyProgram.validations.validate().then(function () {
          return loyaltyProgram.save();
        }).then(function () {
          _this.set('editingLoyaltyProgram', false);
        }).catch(function () {});
      },
      cancelLoyaltyProgramEdit: function cancelLoyaltyProgramEdit(loyaltyProgram) {
        loyaltyProgram.rollbackAttributes();
        this.set('editingLoyaltyProgram', false);
      },
      editLoyaltyProgram: function editLoyaltyProgram() {
        this.set('editingLoyaltyProgram', true);
      },
      updateRoundToNearestDollar: function updateRoundToNearestDollar(roundToNearestDollar, loyaltyReward) {
        loyaltyReward.set('roundToNearestDollar', roundToNearestDollar);
      }
    }
  });

  _exports.default = _default;
});