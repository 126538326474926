define("herer-web/services/permissions-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    verify: function verify(permissionValue) {
      if (this.verifyForced(permissionValue)) {
        return Ember.RSVP.resolve();
      }

      var unauthorizedError = new Error('User does not have permission to access this.');
      unauthorizedError.status = 403;
      return Ember.RSVP.reject(unauthorizedError);
    },
    verifyForced: function verifyForced(permissionValue) {
      var currentUserId = this.get('authManager.currentUser.id');
      var ownerId = this.get('authManager.currentAccount.owner_id');

      if (currentUserId === ownerId) {
        return true;
      }

      var permissions = this.get('authManager.currentRole.permissions');

      if (Ember.isEmpty(permissions)) {
        return false;
      }

      if (Ember.isArray(permissionValue)) {
        return permissionValue.every(function (key) {
          return permissions.indexOf(key) > -1;
        });
      } else {
        return permissions.any(function (item) {
          return item === permissionValue;
        });
      }
    }
  });

  _exports.default = _default;
});