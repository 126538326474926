define("herer-web/templates/components/packages/package-reconciler/in-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zuKJpjLW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"data-test-text\",\"modal_title\"],[8],[0,\"\\n    Package details are in sync\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"After checking with Metrc, it appears that quantity and status of this package are just as we expect them to\\n    be.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Have a good day!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"data-test-action\",\"cancel\"],[3,\"action\",[[23,0,[]],[24,[\"onCancel\"]]]],[8],[0,\"\\n    Continue\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package-reconciler/in-sync.hbs"
    }
  });

  _exports.default = _default;
});