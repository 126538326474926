define("herer-web/mixins/tax-calculator", ["exports", "herer-web/constants", "big.js"], function (_exports, _constants, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    taxItems: [],
    productTypeId: null,
    productTypeTaxItems: [],
    sellPriceType: _constants.default.sellPriceTypes.standard,
    basePrice: 0,
    inclusivePrice: 0,
    exclusivePrice: 0,
    sellPrice: 0,
    launchDarkly: Ember.inject.service(),
    isStandardSellPriceType: Ember.computed.equal('sellPriceType', _constants.default.sellPriceTypes.standard),
    isMedicalSellPriceType: Ember.computed.not('isStandardSellPriceType'),
    inclusiveTaxRate: Ember.computed('taxItems', 'productTypeId', function () {
      return this.taxRate({
        inclusive: true
      });
    }),
    exclusiveTaxRate: Ember.computed('taxItems', 'productTypeId', function () {
      return this.taxRate();
    }),
    compoundTaxRate: Ember.computed('taxItems', 'productTypeId', function () {
      return this.taxRate({
        compound: true
      });
    }),
    isTaxExcludedFromProductType: function isTaxExcludedFromProductType(taxItem) {
      var location = this.get('authManager.currentLocation');
      var isMedical = this.get('isMedicalSellPriceType');
      var productTypeId = this.get('productTypeId');
      var usePersonGroups = this.get('launchDarkly').can('bw-person-group');

      if (usePersonGroups) {
        var applicableTaxConfigurations = taxItem.get('applicable_tax_configurations').toArray().filterBy('product_type_id', productTypeId); // BRAIN-1464 Conditional behavior. If rec is false, then we avoid this filter
        // which has the effect of revealing the Price with Inclusive Taxes input field
        // if taxes apply.

        if (location.get('recreational')) {
          applicableTaxConfigurations = applicableTaxConfigurations.filterBy('person_group.medical', isMedical);
        }

        return applicableTaxConfigurations.every(function (a) {
          return a.get('excluded');
        });
      } else {
        var applicableProductType = taxItem.get('applicable_product_types').findBy('product_type_id', productTypeId);

        if (Ember.isEmpty(applicableProductType)) {
          return false;
        }

        return isMedical && applicableProductType.get('exclude_patients');
      }
    },
    validTaxItem: function validTaxItem(taxItem, inclusive) {
      var effective_schedule = taxItem.get('effective_schedule');

      if (Ember.isEmpty(effective_schedule)) {
        return false;
      }

      var shouldUsePersonGroups = this.get('launchDarkly').can('bw-person-group');

      var productTypeTaxItems = this._applicablePTTIs();

      if (!shouldUsePersonGroups && Ember.isEmpty(productTypeTaxItems)) {
        return false;
      }

      var isExcluded = this.isTaxExcludedFromProductType(taxItem);

      if (isExcluded) {
        return false;
      }

      var inclusiveMatch = effective_schedule.get('inclusive') === inclusive;
      return inclusiveMatch && (shouldUsePersonGroups || productTypeTaxItems.includes(taxItem));
    },
    _applicablePTTIs: function _applicablePTTIs() {
      var _this = this;

      var isStandard = this.get('isStandardSellPriceType');
      return (this.get('productTypeTaxItems') || []).filter(function (taxItem) {
        if (isStandard) {
          return true;
        }

        return !_this.isTaxExcludedFromProductType(taxItem);
      });
    },
    taxRate: function taxRate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$inclusive = _ref.inclusive,
          inclusive = _ref$inclusive === void 0 ? false : _ref$inclusive,
          _ref$compound = _ref.compound,
          compound = _ref$compound === void 0 ? false : _ref$compound;

      var taxItems = this.findTaxItems({
        inclusive: inclusive,
        compound: compound
      });
      var rates = taxItems.mapBy('rate');
      return rates.reduce(function (acc, curr) {
        var currentRate = new _big.default(curr || 0);

        if (compound) {
          // https://greenbits.slack.com/archives/C9UGA21L0/p1544644890311300?thread_ts=1544583996.304600&cid=C9UGA21L0
          return currentRate.plus(currentRate.times(acc)).plus(acc);
        } else {
          return acc.plus(currentRate);
        }
      }, new _big.default(0));
    },
    findTaxItems: function findTaxItems() {
      var _this2 = this;

      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$inclusive = _ref2.inclusive,
          inclusive = _ref2$inclusive === void 0 ? false : _ref2$inclusive,
          _ref2$compound = _ref2.compound,
          compound = _ref2$compound === void 0 ? false : _ref2$compound;

      return this.get('taxItems').filterBy('compounding', compound).filter(function (taxItem) {
        return _this2.validTaxItem(taxItem, inclusive);
      }).sortBy('rank');
    },
    _toMoney: function _toMoney(big) {
      return parseFloat(big.toFixed(_constants.default.basePricePrecision));
    },
    updatePrices: function updatePrices(base) {
      var basePrice = new _big.default(base || 0);
      var inclusivePrice = basePrice.times(new _big.default(1).plus(this.get('inclusiveTaxRate')));
      var exclusiveTotal = basePrice.times(this.get('exclusiveTaxRate'));
      var compoundedTotal = basePrice.times(this.get('compoundTaxRate'));
      var exclusivePrice = inclusivePrice.plus(exclusiveTotal).plus(compoundedTotal);

      var shouldHandleSellPriceChange = this.sellPrice !== this._toMoney(inclusivePrice);

      if (basePrice === null) {
        this.set('basePrice', null);
        this.set('inclusivePrice', null);
        this.set('exclusivePrice', null);
        this.set('sellPrice', null);
      } else {
        this.set('basePrice', this._toMoney(basePrice));
        this.set('inclusivePrice', this._toMoney(inclusivePrice));
        this.set('exclusivePrice', this._toMoney(exclusivePrice));
        this.set('sellPrice', this._toMoney(inclusivePrice));
      }

      if (shouldHandleSellPriceChange) {
        this.onSellPriceChanged();
      }
    },
    setBasePrice: function setBasePrice(value) {
      this.updatePrices(new _big.default(value || 0));
    },
    setInclusivePrice: function setInclusivePrice(value) {
      var inclusiveTaxRate = this.get('inclusiveTaxRate');
      var basePrice = new _big.default(value || 0).div(new _big.default(1).plus(inclusiveTaxRate));
      this.updatePrices(basePrice);
    },
    setExclusivePrice: function setExclusivePrice(value) {
      var exclusivePrice = new _big.default(value || 0);
      var one = new _big.default(1);
      var inclusiveTaxRatePlusOne = this.get('inclusiveTaxRate').plus(one);
      var exclusiveTaxRate = this.get('exclusiveTaxRate');
      var compoundTaxRate = this.get('compoundTaxRate'); // Calculate the base price from the exclusive price. Takes into account both exclusive taxes
      // collected on the base price and exclusive taxes collected without discounts.
      //
      // Want a proof? Fine.
      //
      // Knowns:
      // inclusiveTotal = basePrice * inclusiveRate
      // compoundRate = compoundBaseRate + compoundedUndiscountedRate (sorted)
      // exclusiveRate = exclusiveBaseRate + exclusiveUndiscountedRate
      // exclusiveTotal = basePrice * (exclusiveRate + compoundRate)
      //
      // Calculate total tax rate, then algebra:
      // exclusivePrice = BP * (1 + IR + ER + CR)
      // BP = EP / (1 + IR + ER + CR)

      var denominator = inclusiveTaxRatePlusOne.plus(exclusiveTaxRate).plus(compoundTaxRate);
      var basePrice = exclusivePrice.div(denominator);
      this.updatePrices(basePrice);
    }
  });

  _exports.default = _default;
});