define("herer-web/templates/reports/sales-by-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ImXXz1h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"sales-report\",null,[[\"title\",\"report\",\"reportTypes\",\"interval\",\"startDate\",\"endDate\",\"sortBy\",\"sortDirection\",\"table\",\"onRefreshClick\",\"onExportClick\",\"onIntervalChange\",\"data-test-group\"],[\"Sales by Time\",[24,[\"report\"]],[24,[\"reportTypes\"]],[24,[\"interval\"]],[24,[\"startDate\"]],[24,[\"endDate\"]],[24,[\"sortBy\"]],[24,[\"sortDirection\"]],[24,[\"table\"]],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"refresh\"],null]],null],[28,\"action\",[[23,0,[]],\"exportReport\"],null],[28,\"action\",[[23,0,[]],\"updateDatesFromInterval\"],null],\"sales_by_time_report\"]],{\"statements\":[[0,\"  \"],[1,[28,\"sales-by-time-chart\",null,[[\"data\",\"data-test-group\"],[[24,[\"model\",\"table\"]],\"sales_by_time_chart\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/reports/sales-by-time.hbs"
    }
  });

  _exports.default = _default;
});