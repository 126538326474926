define("herer-web/templates/qs-reports/manifests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gh2QBCjj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Manifest Report\",[28,\"component\",[\"qs-reports/manifest-details-subheader\"],null],\"10\",\"manifest_report_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"ManifestDetailsSummaryReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ManifestDetailsSummaryReport\",\"manifest_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/manifests.hbs"
    }
  });

  _exports.default = _default;
});