define("herer-web/services/inventory-item-status-updater", ["exports", "herer-web/constants", "@greenbits/permafrost-js"], function (_exports, _constants, _permafrostJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setStatuses: function setStatuses(oldProductStatus, newProductStatus, inventoryItems) {
      var updater = new _permafrostJs.default.InventoryItemStatusUpdater(_constants.default);
      var inventoryItemStatusesToChange = updater.inventoryItemStatusesToChange(oldProductStatus, newProductStatus);
      var newInventoryStatus = updater.mapToInventoryItemStatus(newProductStatus);
      return inventoryItems.map(function (inventoryItem) {
        if (inventoryItemStatusesToChange.includes(inventoryItem.get('status'))) {
          inventoryItem.set('status', newInventoryStatus);
        }

        return inventoryItem;
      });
    }
  });

  _exports.default = _default;
});