define("herer-web/components/test-results", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    featureManager: Ember.inject.service(),
    inventoryItem: null,
    readOnlyTestResults: Ember.computed('currentLocation', function () {
      var featureManager = this.get('featureManager'); // Test results should be read only if we pull them from the state system. The exception is
      // licenses that can manually add traceable inventory should be able to edit these test
      // results since not all inventory comes from the state system.

      if (featureManager.can('can-edit-test-results')) {
        return false;
      }

      return featureManager.can('state-provided-test-results') && !featureManager.can('create-traceable-mj-manifest');
    }),
    actions: {
      setTerpenoid: function setTerpenoid(value) {
        this.set('inventoryItem.test_results_terpenoid_profile', value);
      }
    }
  });

  _exports.default = _default;
});