define("herer-web/templates/components/app-version-reloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5FoCtWiU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showReloader\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"app-reloader\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-warning btn-sm\"],[3,\"action\",[[23,0,[]],\"reload\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-refresh\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n        Reload\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      There is a new version of the Back Office available. Please reload the page.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/app-version-reloader.hbs"
    }
  });

  _exports.default = _default;
});