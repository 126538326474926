define("herer-web/components/customer-queue", ["exports", "herer-web/mixins/queueable"], function (_exports, _queueable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_queueable.default, {
    store: Ember.inject.service(),
    queueManager: Ember.inject.service(),
    queue: Ember.computed.alias('queueManager.queue'),
    isLoading: Ember.computed.or('isFetchingQueue', 'queueManager.dequeueTaskIsLoading'),
    isFetchingQueue: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('queueManager').fetchQueue().then(function () {
        _this.set('isFetchingQueue', false);
      });
    }
  });

  _exports.default = _default;
});