define("herer-web/components/packages/package-reconciler/reconcile-confirmation", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    traceabilityStatus: null,
    sourceOfTruth: null,
    package: null,
    onReconcile: null,
    onCancel: null,
    adjustments: null,
    remoteQuantity: Ember.computed('traceabilityStatus', function () {
      return _weight.default.create(this.traceabilityStatus.remote.quantity);
    }),
    localQuantity: Ember.computed('traceabilityStatus', function () {
      return _weight.default.create(this.traceabilityStatus.local.quantity);
    }),
    remoteQuantityAdjustment: Ember.computed('localQuantity', 'remoteQuantity', function () {
      if (this.remoteQuantity !== null && this.localQuantity !== null) {
        return this.get('localQuantity').minus(this.get('remoteQuantity'));
      }
    }),
    localQuantityAdjustment: Ember.computed('localQuantity', 'remoteQuantity', function () {
      if (this.remoteQuantity !== null && this.localQuantity !== null) {
        return this.get('remoteQuantity').minus(this.get('localQuantity'));
      }
    }),
    remoteStatus: Ember.computed('traceabilityStatus', function () {
      return this.getStatusName(this.traceabilityStatus.remote.status);
    }),
    localStatus: Ember.computed('traceabilityStatus', function () {
      return this.getStatusName(this.traceabilityStatus.local.status);
    }),
    sourceIsRemote: Ember.computed('sourceOfTruth', function () {
      return this.sourceOfTruth === 'remote';
    }),
    sourceIsLocal: Ember.computed('sourceOfTruth', function () {
      return this.sourceOfTruth === 'local';
    }),
    statusesEqual: Ember.computed('remoteStatus', 'localStatus', function () {
      return this.get('remoteStatus') === this.get('localStatus');
    }),
    getStatusName: function getStatusName(status) {
      var statusData = _constants.default.packageStatuses.findBy('value', status);

      return statusData.name;
    }
  });

  _exports.default = _default;
});