define("herer-web/components/quick-audit-save-modal", ["exports", "herer-web/components/base-modal", "herer-web/mixins/table-sorts"], function (_exports, _baseModal, _tableSorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    audit: null,
    show: false,
    modalClassName: '.save-quick-audit',
    showModal: Ember.computed.and('show', 'audit.inventory_audit_results.length'),
    columns: [{
      prompt: 'Product Type',
      class: 'col-md-2'
    }, {
      prompt: 'Name',
      class: 'col-md-2'
    }, {
      prompt: 'SKU/Package ID',
      class: 'col-md-3'
    }, {
      prompt: 'Counted',
      class: 'col-md-2'
    }, {
      prompt: 'Adjustment',
      class: 'col-md-2'
    }, {
      prompt: '',
      class: 'col-md-1'
    }],
    init: function init() {
      this._super();

      this.set('columns', (0, _tableSorts.decorateHeaders)(this.get('columns')));
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var modalElement = this.$('.modal.fade');

      if (this.get('showModal')) {
        modalElement.modal();
      } else {
        modalElement.modal('hide');
      }
    },
    actions: {
      saveDraft: function saveDraft() {
        this.onSaveDraft();
      },
      saveAdjustment: function saveAdjustment() {
        this.onSaveAdjustment();
      }
    }
  });

  _exports.default = _default;
});