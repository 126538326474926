define("herer-web/models/tiered-pricing-model", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _model, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var isPersisted = function isPersisted(object) {
    return Ember.isPresent(object) && !object.get('isNew');
  };

  function sortedTiersForType(type, pricingTiers) {
    var matchingTiers = pricingTiers.filter(function (tier) {
      return tier.get('priceConditionType') === type;
    });
    return matchingTiers.sort(function (a, b) {
      return a.get('quantity').cmp(b.get('quantity'));
    });
  }

  var extractChangedAttributes = function extractChangedAttributes(tier) {
    return Object.entries(tier.changedAttributes(), []);
  }; // Remove ignorance of *quantity* after Weight is more stable and doesn't pollute changed attributes


  var hasChangedAttributesExceptQuantity = function hasChangedAttributesExceptQuantity(attributes) {
    return attributes.any(function (attribute) {
      return attribute[0] === 'quantity' ? false : true;
    });
  };

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      name: (0, _yup.string)().nullable().required()
    }),
    name: (0, _model.attr)('string'),
    pricingTiers: (0, _model.hasMany)('pricing-tier', {
      async: false
    }),
    hasDirtyAttributes: Ember.computed('dirtyType', 'hasDirtyPricingTiers', function () {
      return this.get('hasDirtyPricingTiers') || Ember.isPresent(this.get('dirtyType'));
    }),
    hasDirtyPricingTiers: Ember.computed('pricingTiers.@each.{hasDirtyAttributes}', function () {
      return this.get('pricingTiers').filter(isPersisted).map(extractChangedAttributes).any(hasChangedAttributesExceptQuantity);
    }),
    sortedStandardTiers: Ember.computed('pricingTiers.@each.{quantityValue,price,priceConditionType}', function () {
      var pricingTiers = this.get('pricingTiers');
      return sortedTiersForType(_constants.default.priceConditionType.standard, pricingTiers);
    }),
    lowestStandardTier: Ember.computed.readOnly('sortedStandardTiers.firstObject'),
    sortedPatientTiers: Ember.computed('pricingTiers.@each.{quantityValue,price,priceConditionType}', function () {
      var pricingTiers = this.get('pricingTiers');
      return sortedTiersForType(_constants.default.priceConditionType.patient, pricingTiers);
    }),
    hasPatientTiers: Ember.computed.notEmpty('sortedPatientTiers'),
    sortedMemberTiers: Ember.computed('pricingTiers.@each.{quantityValue,price,priceConditionType}', function () {
      var pricingTiers = this.get('pricingTiers');
      return sortedTiersForType(_constants.default.priceConditionType.member, pricingTiers);
    }),
    hasMemberTiers: Ember.computed.notEmpty('sortedMemberTiers'),
    weighHeavyWeight: Ember.computed({
      get: function get() {
        var firstTier = this.get('pricingTiers.firstObject');

        if (Ember.isEmpty(firstTier)) {
          return null;
        }

        var quantity = firstTier.get('quantity');
        var quantityMax = firstTier.get('quantityMax');

        if (Ember.isEmpty(quantityMax)) {
          return null;
        }

        return quantityMax.minus(quantity);
      },
      set: function set(key, value) {
        this.updateQuantityMax(value);
        return value;
      }
    }),
    updateQuantityMax: function updateQuantityMax(weighHeavyWeight) {
      this.get('pricingTiers').forEach(function (pricingTier) {
        if (Ember.isPresent(weighHeavyWeight)) {
          pricingTier.set('quantityMax', pricingTier.get('quantity').plus(weighHeavyWeight));
        } else {
          pricingTier.set('quantityMax', null);
        }
      });
    },
    rollbackAttributes: function rollbackAttributes() {
      this.get('pricingTiers').filter(isPersisted).filter(function (tier) {
        return tier.get('hasDirtyAttributes');
      }).forEach(function (tier) {
        return tier.rollbackAttributes();
      });
      return this._super.apply(this, arguments);
    },
    validate: function validate() {
      var childrenValidations = this.get('pricingTiers').map(function (pricingTier) {
        return pricingTier.validations.validate();
      });
      return Promise.all([this.validations.validate()].concat(_toConsumableArray(childrenValidations)));
    }
  });

  _exports.default = _default;
});