define("herer-web/templates/components/text-counter-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwFCehw7",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"textarea\",null,[[\"value\",\"id\",\"maxlength\",\"readonly\",\"class\",\"data-test-input\"],[[24,[\"value\"]],[24,[\"id\"]],[24,[\"maxLength\"]],[24,[\"readonly\"]],[24,[\"class\"]],\"text_counter_textarea\"]]],false],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"text-count-wrapper \",[22,\"counterErrorClass\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"countDown\"]]],null,{\"statements\":[[0,\"      Remaining:\\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[22,\"remainingCharsCount\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Character Count:\\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[22,\"charsCount\"],false],[0,\"\\n      \"],[9],[0,\"\\n      / \"],[1,[22,\"max\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"displayError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error-text\"],[8],[0,\"\\n      \"],[1,[22,\"maximumErrorText\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/text-counter-textarea.hbs"
    }
  });

  _exports.default = _default;
});