define("herer-web/models/inventory-move", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/constants", "herer-web/models/weight"], function (_exports, _model, _emberDataModelFragments, _constants, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    product: (0, _model.belongsTo)('product'),
    inventoryItem: (0, _model.belongsTo)('inventory_item', {
      async: false
    }),
    fromPlace: (0, _model.attr)('place'),
    toPlace: (0, _model.attr)('place'),
    quantity: (0, _model.attr)('weight'),
    http: Ember.inject.service(),
    indivisibleUnits: [_constants.default.weightUnit.unit, _constants.default.weightUnit.milligram],
    slider: Ember.computed('inventoryItem', 'quantity.unit', function () {
      var step = "1";

      if (this.get('quantity') && this.currentUnitIsDivisible(this.get('quantity'))) {
        step = "0.25";
      }

      var maxQuantity = parseFloat(this.get('maxPlaceQuantity.value'));
      var parsedStep = parseFloat(step);
      maxQuantity = Math.ceil(maxQuantity / parsedStep) * parsedStep;
      return {
        min: 0,
        max: maxQuantity,
        value: this.get('quantity.value'),
        step: step
      };
    }),
    maxPlaceQuantity: Ember.computed('inventoryItem', 'fromPlace', 'quantity.unit', function () {
      var fromPlace = this.get('fromPlace');

      if (Ember.isPresent(fromPlace) && Ember.isPresent(this.get('quantity'))) {
        var fromIip = this.get('inventoryItem.inventory_item_places').find(function (iip) {
          return iip.get('place.id') === fromPlace.get('id');
        });
        return fromIip.get('unlocked_quantity').to(this.get('quantity.unit'));
      } else {
        return this.get('inventoryItem.unlocked_quantity').zero();
      }
    }),
    currentUnitIsDivisible: function currentUnitIsDivisible(quantity) {
      var currentUnit = quantity.get('unit');
      return Ember.isPresent(currentUnit) && !this.indivisibleUnits.includes(currentUnit);
    },
    anyErrors: Ember.computed('toPlaceError', 'fromPlaceError', 'quantityError', function () {
      return this.get('toPlaceError') || this.get('fromPlaceError') || this.get('quantityError');
    }),
    quantityError: Ember.computed('quantity', 'quantity.value', 'maxPlaceQuantity', function () {
      var desiredQuantity = parseFloat(this.get('quantity'));
      var fromIipQuantity = parseFloat(this.get('maxPlaceQuantity'));

      if (desiredQuantity <= 0) {
        return ['Moving quantity must be higher than 0'];
      } else if (desiredQuantity > fromIipQuantity) {
        return ['Moving quantity cannot be higher than place its coming from'];
      }

      return null;
    }),
    toPlaceError: Ember.computed('toPlace', 'fromPlace', function () {
      var toPlaceId = this.get('toPlace.id');
      var fromPlaceId = this.get('fromPlace.id');

      if (!toPlaceId) {
        return ['To place cannot be blank'];
      } else if (toPlaceId == fromPlaceId) {
        return ['To place cannot be same as the from place'];
      }
    }),
    fromPlaceError: Ember.computed('fromPlace', function () {
      var fromPlace = this.get('fromPlace');

      if (!fromPlace.get('id')) {
        return ['From place cannot be blank'];
      }
    }),
    unlockedPlacesWithPositiveQuantity: Ember.computed('inventoryItem.unlockedPlacesWithPositiveQuantity', function () {
      var inventoryItem = this.get('inventoryItem');

      if (Ember.isBlank(inventoryItem)) {
        return [];
      }

      return inventoryItem.get('unlockedInventoryItemPlacesWithPositiveQuantity').toArray().map(function (iip) {
        return iip.get('place');
      });
    }),
    save: function save() {
      var url = "".concat(_constants.default.apiHost, "/api/v1/inventory_items/").concat(this.get('inventoryItem.id'), "/move");
      return Ember.get(this, 'http').post(url, {
        inventory_move: {
          from_place_id: this.get('fromPlace.id'),
          to_place_id: this.get('toPlace.id'),
          quantity: {
            value: this.get('quantity.value'),
            unit: this.get('quantity.unit')
          }
        }
      });
    }
  });

  _exports.default = _default;
});