define("herer-web/components/device-row", ["exports", "herer-web/constants", "bootbox", "lodash/isArray", "lodash/isObject", "herer-web/utils/humanize"], function (_exports, _constants, _bootbox, _isArray, _isObject, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    classNames: ['row', 'table-row', 'device-row'],
    isEditing: false,
    device: null,
    onRelink: null,
    canEditDeviceTransactionType: Ember.computed('device', function () {
      var featureManager = this.get('featureManager');
      return featureManager.can('recreational') && featureManager.can('medical') && this.get('device.isRegister');
    }),
    deviceTransactionTypes: _constants.default.deviceTransactionTypes,
    deviceTransactionTypeName: Ember.computed('device.device_transaction_type', function () {
      var deviceTransactionTypes = this.get('deviceTransactionTypes');
      var deviceTransactionType = this.get('device.device_transaction_type');
      return deviceTransactionTypes.find(function (type) {
        return type.value === deviceTransactionType;
      }).name;
    }),
    buildHardwareProfileHTML: function buildHardwareProfileHTML(object) {
      var dl = document.createElement('dl');
      dl.className = 'dl-horizontal hardware-profile';

      for (var prop in object) {
        var dd = document.createElement('dd');
        var value = object[prop];

        if ((0, _isArray.default)(value)) {
          dd.className = 'dd-no-indent';

          var _iterator = _createForOfIteratorHelper(value),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var v = _step.value;
              var div = document.createElement('div');
              div.className = 'clearfix';
              dd.appendChild(div);
              dd.append(this.buildHardwareProfileHTML(v));
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else if ((0, _isObject.default)(value)) {
          var _div = document.createElement('div');

          _div.className = 'clearfix';
          dd.appendChild(_div);
          dd.appendChild(this.buildHardwareProfileHTML(value));
        } else {
          dd.textContent = value;
        }

        var textDiv = document.createElement('dt');
        textDiv.textContent = (0, _humanize.humanize)(prop);
        dl.appendChild(textDiv);
        dl.appendChild(dd);
      }

      return dl;
    },
    buildPaymentTerminalHTML: function buildPaymentTerminalHTML(serialNumber) {
      var dl = document.createElement('dl');
      dl.className = 'dl-horizontal hardware-profile';
      var dt = document.createElement('dt');
      dt.textContent = 'Payment Terminal S/N';
      var dd = document.createElement('dd');
      dd.textContent = serialNumber;
      dl.appendChild(dt);
      dl.appendChild(dd);
      return dl;
    },
    actions: {
      edit: function edit() {
        this.set('isEditing', true);
      },
      save: function save() {
        var _this = this;

        this.get('device').save().then(function () {
          _this.get('toast').success('Successfully updated the device.');

          _this.set('isEditing', false);
        }).catch(function () {
          _this.get('toast').error('There was a problem saving the device.');
        });
      },
      cancel: function cancel() {
        this.set('isEditing', false);
        this.get('device').rollbackAttributes();
      },
      relink: function relink() {
        this.onRelink(this.get('device'));
      },
      viewHardwareProfile: function viewHardwareProfile() {
        var profile = this.get('device.hardware_profile');

        if (Ember.isEmpty(profile)) {
          _bootbox.default.alert('No hardware profile available');

          return;
        }

        var html = this.buildHardwareProfileHTML(profile).outerHTML;
        var paymentTerminal = this.get('device.payment_terminal');

        if (Ember.isPresent(paymentTerminal)) {
          var serialNumber = this.get('device.payment_terminal.processor_portal_identifier');
          html += this.buildPaymentTerminalHTML(serialNumber).outerHTML;
        }

        _bootbox.default.dialog({
          title: 'Hardware Profile',
          message: html
        });
      }
    }
  });

  _exports.default = _default;
});