define("herer-web/models/vehicle", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/utils/validation", "yup"], function (_exports, _model, _emberDataModelFragments, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    vin: (0, _model.attr)('string'),
    color: (0, _model.attr)('string'),
    plate: (0, _model.attr)('string'),
    make: (0, _model.attr)('string'),
    model: (0, _model.attr)('string'),
    year: (0, _model.attr)('number'),
    validations: (0, _validation.validations)({
      vin: (0, _yup.string)().required(),
      color: (0, _yup.string)().required(),
      plate: (0, _yup.string)().required(),
      make: (0, _yup.string)().required(),
      model: (0, _yup.string)().required(),
      year: (0, _yup.string)().required()
    })
  });

  _exports.default = _default;
});