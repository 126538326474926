define("herer-web/components/traceability-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stateLinkStatusUpdater: Ember.inject.service(),
    isLoading: Ember.computed.alias('stateLinkStatusUpdater.isLoading'),
    lastIncomingInventorySyncInWords: Ember.computed('stateLinkStatusUpdater.lastIncomingInventorySyncInWords', function () {
      return this.get('stateLinkStatusUpdater.lastIncomingInventorySyncInWords').capitalize();
    }),
    actionsToReport: Ember.computed.alias('stateLinkStatusUpdater.actionsToReport'),
    actionsReportedLastDay: Ember.computed.alias('stateLinkStatusUpdater.actionsReportedLastDay'),
    resolvableReportingErrors: Ember.computed.alias('stateLinkStatusUpdater.resolvableReportingErrors')
  });

  _exports.default = _default;
});