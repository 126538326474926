define("herer-web/templates/imports/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0jK01Wc2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n  Import task created.\\n\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"data-test-action\",\"route\"],[\"visit_tasks_section\",\"tasks\"]],{\"statements\":[[0,\"  Visit tasks section\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/imports/confirmation.hbs"
    }
  });

  _exports.default = _default;
});