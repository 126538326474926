define("herer-web/models/loyalty-point-transaction", ["exports", "@ember-data/model", "herer-web/utils/validation", "yup"], function (_exports, _model, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      loyaltyPoints: (0, _yup.number)().nullable().required(),
      person_id: (0, _yup.string)().nullable().required(),
      note: (0, _yup.string)().nullable().required()
    }),
    note: (0, _model.attr)('string'),
    person_id: (0, _model.attr)('string'),
    loyaltyPoints: (0, _model.attr)('number')
  });

  _exports.default = _default;
});