define("herer-web/templates/components/weekly-sales-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P8W6hf1c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-heading panel-heading-medium-green\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"pull-right\",\"weekly-sales\"]],{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"fa fa-arrow-right\"],[8],[9],[0,\"\\n        \"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"fa fa-usd header\"],[8],[9],[0,\"\\n        Revenue from Sales\\n        \"],[7,\"span\",true],[10,\"class\",\"count\"],[8],[0,\"\\n          Week of \"],[1,[22,\"week\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body padded\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canShowChart\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"bubble-chart\",null,[[\"report\"],[[24,[\"table\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/weekly-sales-report.hbs"
    }
  });

  _exports.default = _default;
});