define("herer-web/initializers/system-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  var initialize = function initialize(application) {
    application.inject('route', 'systemStatus', 'service:system-status');
    application.inject('controller', 'systemStatus', 'service:system-status');
  };

  _exports.initialize = initialize;
  var SystemStatusInitializer = {
    name: 'system-status',
    initialize: initialize
  };
  var _default = SystemStatusInitializer;
  _exports.default = _default;
});