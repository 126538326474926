define("herer-web/components/save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    prompt: 'Save',
    cancelPrompt: 'Cancel',
    disabled: false,
    isSaving: false,
    btnClass: 'btn-success',
    saveIcon: 'fa-check',
    cancelIcon: '',
    buttonDisabled: Ember.computed('isSaving', 'disabled', function () {
      return this.get('isSaving') || this.get('disabled');
    }),
    saveIconDynamicClass: Ember.computed('isSaving', function () {
      var isSaving = this.get('isSaving');

      if (isSaving) {
        return 'fa-circle-o-notch fa-spin';
      } else {
        return this.get('saveIcon');
      }
    }),
    actions: {
      save: function save() {
        return this.onSave && this.onSave();
      },
      cancel: function cancel() {
        return this.onCancel && this.onCancel();
      }
    }
  });

  _exports.default = _default;
});