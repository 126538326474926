define("herer-web/mixins/taggable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    tags: (0, _model.attr)('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  _exports.default = _default;
});