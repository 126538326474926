define("herer-web/templates/components/user-info-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMrG31kL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"col-sm-\",[28,\"if\",[[28,\"has-permission\",[\"view_user_pin\"],null],\"2\",\"3\"],null]]]],[10,\"data-test-value\",\"last_name\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\",\"hasAccessibleLocations\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"user\",\"last_name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"tool-tip\",null,null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"data-toggle\",\"tooltip\"],[10,\"data-container\",\"body\"],[10,\"data-placement\",\"bottom\"],[10,\"data-template\",\"<div class='tooltip'><div class='tooltip-arrow'></div><div class='tooltip-inner no-accessible-locations'></div></div>\"],[10,\"data-original-title\",\"This user was restored and has no Accessible Locations assigned.\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-warning text-muted\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ellipsis\"],[8],[1,[24,[\"user\",\"last_name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 ellipsis\"],[10,\"data-test-value\",\"first_name\"],[8],[0,\"\\n  \"],[1,[24,[\"user\",\"first_name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 ellipsis\"],[10,\"data-test-value\",\"username\"],[8],[0,\"\\n  \"],[1,[24,[\"user\",\"username\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 ellipsis\"],[10,\"data-test-value\",\"role_name\"],[8],[0,\"\\n  \"],[1,[24,[\"role\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"has-permission\",[\"view_user_pin\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-sm-1 ellipsis\"],[10,\"data-test-value\",\"pin\"],[8],[0,\"\\n    \"],[1,[24,[\"user\",\"pin\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/user-info-row.hbs"
    }
  });

  _exports.default = _default;
});