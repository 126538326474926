define("herer-web/templates/inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ffzY0EeA",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"inventory-form\",null,[[\"adjust\",\"move\",\"convert\",\"emailSettings\",\"inventoryItem\",\"labelPreference\",\"onCancel\",\"onSave\",\"productTypes\",\"split\",\"tags\",\"taxItems\",\"tieredPricingModels\",\"viewManifest\",\"placesEnabled\"],[[28,\"action\",[[23,0,[]],\"adjust\"],null],[28,\"action\",[[23,0,[]],\"move\"],null],[28,\"action\",[[23,0,[]],\"convert\"],null],[24,[\"currentUser\",\"email_settings\"]],[24,[\"model\"]],[24,[\"labelPreference\"]],[28,\"route-action\",[\"cancel\"],null],[28,\"action\",[[23,0,[]],\"onSave\"],null],[24,[\"productTypes\"]],[28,\"action\",[[23,0,[]],\"split\"],null],[24,[\"tags\"]],[24,[\"taxItems\"]],[24,[\"tieredPricingModels\"]],[28,\"action\",[[23,0,[]],\"viewManifest\"],null],[24,[\"placesEnabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"outlet\",[\"adjustment\"],null],false],[0,\"\\n\"],[1,[28,\"outlet\",[\"inventory-move\"],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isPackageSplitterOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"packages/package-splitter\",null,[[\"inventoryItem\",\"onCancel\",\"onSplit\"],[[24,[\"itemToSplit\"]],[28,\"action\",[[23,0,[]],\"onCancelSplit\"],null],[28,\"action\",[[23,0,[]],\"onSplit\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/inventory.hbs"
    }
  });

  _exports.default = _default;
});