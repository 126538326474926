define("herer-web/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var ITEMS_PER_ROW = 6;

  var _default = Ember.Component.extend({
    tagName: '',
    launchDarkly: Ember.inject.service(),
    selectedColor: Ember.computed('color', function () {
      return this.hexForColor(this.get('color'));
    }),
    palette: Ember.computed(function () {
      var _this = this;

      var hexedColors = _toConsumableArray(Array(ITEMS_PER_ROW * 2).keys()).map(function (num) {
        return _this.hexForColor(num);
      });

      return [hexedColors.slice(0, ITEMS_PER_ROW), hexedColors.slice(ITEMS_PER_ROW)];
    }),
    colors: Ember.computed('colors', function () {
      if (this.get('launchDarkly').can('cpc-new-brand-colors')) {
        return [[0, '#005737'], [1, '#1b946f'], [2, '#58bfa0'], [3, '#224c95'], [4, '#3b8cd9'], [5, '#808080'], [6, '#956b22'], [7, '#800845'], [8, '#ce1655'], [9, '#312295'], [10, '#5e3cb5'], [11, '#9679d0']];
      } else {
        return [[0, '#005737'], [1, '#1b946f'], [2, '#58bfa0'], [3, '#b6e3d5'], [4, '#2b2522'], [5, '#6c6c6c'], [6, '#c8c8c8'], [7, '#3b8cd9'], [8, '#72b8ed'], [9, '#fe7616'], [10, '#fcd971'], [11, '#ce1655']];
      }
    }),
    hexForColor: function hexForColor(color) {
      return this.colorMapForValue(color, true);
    },
    colorForHex: function colorForHex(hex) {
      return this.colorMapForValue(hex, false);
    },
    colorMapForValue: function colorMapForValue(value, isColor) {
      var colorMap = this.get('colors').find(function (colorMap) {
        return colorMap[isColor ? 0 : 1] === value;
      });
      return colorMap[isColor ? 1 : 0];
    },
    actions: {
      changeColor: function changeColor(color) {
        this.onChange && this.onChange(this.colorForHex(color));
      }
    }
  });

  _exports.default = _default;
});