define("herer-web/models/inventory-audit-result", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    actual: (0, _model.attr)('number'),
    adjustment: (0, _model.attr)('number'),
    counted: (0, _model.attr)('number'),
    sku: (0, _model.attr)('string'),
    lot_number: (0, _model.attr)('string'),
    received_at: (0, _model.attr)('date'),
    unit: (0, _model.attr)('number'),
    unprocessable_reason: (0, _model.attr)('number'),
    inventory_audit: (0, _model.belongsTo)('inventory-audit', {
      async: false
    }),
    inventory_item: (0, _model.belongsTo)('inventory-item', {
      async: true
    }),
    product: (0, _model.belongsTo)('product', {
      async: true
    }),
    product_id: (0, _model.attr)('string'),
    unprocessable: Ember.computed.notEmpty('unprocessable_reason'),
    unprocessable_transferred: Ember.computed('unprocessable_reason', function () {
      return this.get('unprocessable_reason') === _constants.default.unprocessableReason.transferredOut;
    }),
    unprocessable_outside_filters: Ember.computed('unprocessable_reason', function () {
      return this.get('unprocessable_reason') === _constants.default.unprocessableReason.outsideFilters;
    }),
    unprocessable_not_found: Ember.computed('unprocessable_reason', function () {
      return this.get('unprocessable_reason') === _constants.default.unprocessableReason.notFound;
    })
  });

  _exports.default = _default;
});