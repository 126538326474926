define("herer-web/components/places-toggle-modal", ["exports", "herer-web/mixins/taskable", "herer-web/components/base-modal", "herer-web/constants", "herer-web/mixins/context"], function (_exports, _taskable, _baseModal, _constants, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend(_taskable.default, _context.default, {
    http: Ember.inject.service(),
    toast: Ember.inject.service(),
    cookies: Ember.inject.service(),
    alreadyConfirmed: false,
    needsConfirmation: Ember.computed.not('alreadyConfirmed'),
    error: null,
    onFinish: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('cookies').getCookie('placesConversionTaskInProgress')) {
        this.set('alreadyConfirmed', true);
        this.pollExistingJob();
      }
    },
    hide: function hide() {
      this.set('show', false);
    },
    pollExistingJob: function pollExistingJob() {
      var _this = this;

      var task = JSON.parse(this.get('cookies').getCookie('placesConversionTaskInProgress'));
      this.set('task', task);

      var taskRequest = function taskRequest() {
        return _this.get('http').get("".concat(_constants.default.apiHost, "/api/v2/tasks/").concat(task.id));
      };

      this.fetchTimeout(taskRequest).then(function (response) {
        _this.pollTask();
      }).catch(function (error) {
        _this.handlePollError(error);
      });
    },
    handlePollError: function handlePollError(error) {
      this.hide();
      this.set('alreadyConfirmed', false);
      this.get('cookies').removeCookie('placesConversionTaskInProgress');
      var errorMessage;

      if (error.data && error.data.errors) {
        errorMessage = new Intl.ListFormat().format(error.data.errors);
      } else {
        errorMessage = error.statusText;
      }

      this.get('toast').error("Issue During Conversion: ".concat(errorMessage), '', {
        timeOut: 0,
        extendedTimeOut: 0,
        closeButton: true
      });
    },
    createInventoryItemPlacesTask: function createInventoryItemPlacesTask() {
      var _this2 = this;

      var uuid = this.get('currentLocation.id');

      var enablePlaces = function enablePlaces() {
        return _this2.get('http').post("".concat(_constants.default.apiHost, "/api/v2/locations/").concat(uuid, "/enable_places"));
      };

      this.fetchTimeout(enablePlaces).then(function (response) {
        _this2.set('task', response.data.task);

        _this2.get('cookies').setCookie('placesConversionTaskInProgress', response.data.task);

        _this2.pollTask();
      }).catch(function (error) {
        _this2.handlePollError(error);
      });
    },
    fetchTimeout: function fetchTimeout(request) {
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30000;
      var timeout_err = {
        ok: false,
        status: 408
      };
      return new Promise(function (resolve, reject) {
        request().then(resolve, reject);
        setTimeout(reject.bind(null, timeout_err), timeout);
      });
    },
    onTaskComplete: function onTaskComplete() {
      this.hide();
      this.get('toast').success("Conversion of Inventory to Places Succesful!");
      this.get('cookies').removeCookie('placesConversionTaskInProgress');
      this.onFinish();
    },
    actions: {
      confirm: function confirm() {
        this.set('alreadyConfirmed', true);
        this.createInventoryItemPlacesTask();
      },
      hide: function hide() {
        this.hide();
      }
    }
  });

  _exports.default = _default;
});