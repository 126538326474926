define("herer-web/components/dimple-chart", ["exports", "herer-web/mixins/resize"], function (_exports, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resize.default, Ember.Evented, {
    classNames: ['dimple-chart'],
    drawDuration: 1000,
    _chart: null,
    data: null,
    dimple: dimple,
    d3: d3,
    customizeChart: function customizeChart() {},
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.updateChart();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chartModel = new this.dimple.chart(this.get('svg'), this.get('data'));

      this._customizeChart(chartModel);

      this.customizeChart(chartModel);
      this.set('_chart', chartModel);
    },
    chart: Ember.computed('_data', 'svg', function () {
      var chart = this.get('_chart');
      var svg = this.get('svg');
      var data = this.get('_data');

      if (!(svg && data)) {
        return;
      }

      chart.data = data;
      return chart;
    }),
    svg: Ember.computed(function () {
      return this.dimple.newSvg('#' + this.$().attr('id'));
    }),
    remap: function remap(data) {
      return data;
    },
    _data: Ember.computed('data', function () {
      return this.remap(this.get('data'));
    }),
    _customizeChart: function _customizeChart(chart) {
      return chart.setBounds('10%', '10%', '80%', '80%');
    },
    updateChart: function updateChart(noDataUpdate) {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.doDraw(this.get('chart'), noDataUpdate);
      });
    },
    doDraw: function doDraw(chart, noDataUpdate) {
      var svg, _ref;

      if (!((svg = chart != null ? (_ref = chart.svg) != null ? _ref[0] : void 0 : void 0) && !Ember.isEmpty(chart.data))) {
        return;
      }

      chart.draw(this.get('drawDuration', noDataUpdate));
    },
    onResizeEnd: function onResizeEnd() {
      return this.updateChart(true);
    }
  });

  _exports.default = _default;
});