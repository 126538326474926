define("herer-web/mixins/infinite-list", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/infinite-scroll", "herer-web/mixins/table-sorts", "lodash/union"], function (_exports, _pageable, _infiniteScroll, _tableSorts, _union) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Mixin;

  var mixins = [_pageable.default, _infiniteScroll.default, _tableSorts.default];

  var _default = (_Ember$Mixin = Ember.Mixin).create.apply(_Ember$Mixin, mixins.concat([{
    store: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    isClickable: true,
    isModal: false,
    decoratedColumns: Ember.computed('columns', 'model.sortBy', 'model.sortDirection', 'isModal', function () {
      return this.updateSorts(this.get('columns'));
    }),
    list: Ember.computed('model', 'newModel', function () {
      if (this.get('newModel')) {
        return (0, _union.default)([this.get('newModel')], this.get('model').toArray());
      } else {
        return this.get('model');
      }
    }),
    sortChanged: Ember.observer('model.sortBy', 'model.sortDirection', function () {
      this.sortChange && this.sortChange(this.get('model.sortBy'), this.get('model.sortDirection'));
    }),
    newModelDidChange: Ember.observer('newModel', function () {
      if (this.get('newModel')) {
        return;
      }

      this.get('model').fetchObjects();
    }),
    deletedModelDidChange: Ember.observer('deletedModel', function () {
      this.get('model').fetchObjects();
    }),
    countChanged: Ember.observer('model.total', function () {
      this.countChange && this.countChange(this.get('model.total'));
    }),
    actions: {
      afterFetch: function afterFetch() {// noop, override
      },
      selectModel: function selectModel(model) {
        this.didSelectModel(model);
      },
      newModelsaved: function newModelsaved() {
        this.didSelectModel(this.get('newModel'));
        this.set('newModel', null);
      },
      newModelDeleted: function newModelDeleted() {
        this.set('newModel', null);
      }
    },
    didSelectModel: function didSelectModel(model) {
      if (this.get('isClickable')) {
        this.send('selectModel', model);
      }
    }
  }]));

  _exports.default = _default;
});