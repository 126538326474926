define("herer-web/controllers/sales-summary-report", ["exports", "herer-web/mixins/context", "moment", "herer-web/mixins/pin-debit-settings", "ember-data"], function (_exports, _context, _moment, _pinDebitSettings, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _pinDebitSettings.default, {
    queryParams: ['startDate', 'endDate'],
    startDate: (0, _moment.default)().format('YYYY-MM-DD'),
    endDate: (0, _moment.default)().format('YYYY-MM-DD'),
    launchDarkly: Ember.inject.service(),
    store: Ember.inject.service(),
    pinDebitEnabled: Ember.computed(function () {
      var store = this.get('store');
      return _emberData.default.PromiseObject.create({
        promise: this.findPinDebitSettings(store).then(function (res) {
          return res.get('enabled');
        })
      });
    }),
    actions: {
      changeDate: function changeDate(property, date) {
        this.set(property, date);
      }
    }
  });

  _exports.default = _default;
});