define("herer-web/helpers/location-can-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.locationCanUse = locationCanUse;
  _exports.default = void 0;

  function locationCanUse(featureManager, feature) {
    var allowed = function allowed(aFeature) {
      return featureManager.can(aFeature);
    };

    return feature.split(' ').some(allowed);
  }

  var _default = Ember.Helper.extend({
    featureManager: Ember.inject.service(),
    compute: function compute(params) {
      var feature = params[0];

      if (Ember.isEmpty(feature)) {
        return true;
      }

      return locationCanUse(this.get('featureManager'), feature);
    }
  });

  _exports.default = _default;
});