define("herer-web/routes/sessions/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authManager: Ember.inject.service(),
    activate: function activate() {
      var _this = this;

      this.get('authManager').reset().then(function () {
        _this.transitionTo('sessions.new');
      });
    }
  });

  _exports.default = _default;
});