define("herer-web/routes/discount-plans", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'marketing-nav',
    mixpanelEvent: 'DiscountPlanList.show',
    permissionKey: 'view_discount_plans',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        discountPlans: store.findAll('discount_plan'),
        productTypes: store.findAll('productType'),
        tags: store.findAll('tag'),
        roles: store.findAll('role'),
        brands: store.findAllInBatches('brand')
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.discountPlans);

      controller.set('allTags', hash.tags);
      controller.set('productTypes', hash.productTypes.sortBy('name'));
      controller.set('roles', hash.roles);
      controller.set('brands', hash.brands);
    }
  });

  _exports.default = _default;
});