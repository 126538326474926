define("herer-web/components/crud-list-form-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    modelName: null,
    modelIconClass: null,
    onSave: null,
    onCancel: null,
    onDelete: null,
    showSaveMessage: false,
    showErrorMessage: false,
    hasCustomTitle: false,
    hasHamburgerMenu: false,
    disabled: false,
    canDelete: false,
    enabled: Ember.computed.not('disabled'),
    customErrorMessage: null,
    hasModel: Ember.computed.notEmpty('model'),
    showHamburgerMenu: Ember.computed('model.isNew', 'canDelete', 'hasHamburgerMenu', function () {
      var modelIsNew = this.get('model.isNew');
      var canDelete = this.get('canDelete');
      return !modelIsNew && (canDelete || this.get('hasHamburgerMenu'));
    }),
    lowercaseModelName: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (Ember.isEmpty(modelName)) {
        return;
      }

      return modelName.toLowerCase();
    }),
    errorMessage: Ember.computed(function () {
      var customErrorMessage = this.get('customErrorMessage');

      if (customErrorMessage) {
        return customErrorMessage;
      }

      return "There was a problem saving the ".concat(this.get('lowercaseModelName'));
    }),
    actions: {
      save: function save() {
        this.onSave();
      },
      cancel: function cancel() {
        this.onCancel();
      },
      delete: function _delete() {
        if (this.get('canDelete')) {
          this.onDelete();
        }
      },
      restore: function restore() {
        if (this.get('canDelete')) {
          this.onRestore();
        }
      }
    }
  });

  _exports.default = _default;
});