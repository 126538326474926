define("herer-web/mixins/alert-unsaved-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    additionalDirtyCheckResult: false,
    showUnsavedAlert: Ember.computed('model.hasDirtyAttributes', 'model.@each.hasDirtyAttributes', 'additionalDirtyCheckResult', function () {
      var model = this.get('model');
      var additionalDirtyCheckResult = this.get('additionalDirtyCheckResult');
      var isDirty;

      if (Ember.isArray(model)) {
        isDirty = model.any(function (item) {
          return item.get('hasDirtyAttributes');
        });
      } else {
        isDirty = model.get('hasDirtyAttributes');
      }

      return isDirty || additionalDirtyCheckResult;
    })
  });

  _exports.default = _default;
});