define("herer-web/templates/components/qs-reports/manifest-details-subheader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKk83FXU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Shows you information about received inventory, like the amount spent on inventory, grouped by \"],[5,\"sku-as-barcode\",[],[[],[]]],[0,\".\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"a\",true],[10,\"href\",\"https://support.dutchie.com/hc/en-us/articles/12886994842003-Inventory-on-Hand-Analyze-your-inventory-with-Actionable-Insights\"],[10,\"target\",\"_blank\"],[8],[0,\"Learn more\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/qs-reports/manifest-details-subheader.hbs"
    }
  });

  _exports.default = _default;
});