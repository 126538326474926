define("herer-web/templates/components/web-store-install", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkpnJ0ol",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[11,\"href\",[22,\"webstoreUrl\"]],[10,\"class\",\"btn btn-default install\"],[10,\"data-test-action\",\"install_label_printing_extension\"],[8],[0,\"\\n  Install Label Printing Extension\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/web-store-install.hbs"
    }
  });

  _exports.default = _default;
});