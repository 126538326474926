define("herer-web/services/label-printer", ["exports", "herer-web/constants", "herer-web/config/environment", "herer-web/utils/zpl-builder", "herer-web/utils/semver-sort", "herer-web/labels/label_filters", "herer-web/utils/error-reporter", "logrocket", "axios"], function (_exports, _constants, _environment, _zplBuilder, _semverSort, _label_filters, _errorReporter, _logrocket, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DieselTonicTemplatePath = 'E:';
  var LabelTemplatesUrl = 'https://s3.amazonaws.com/gb-label-templates';

  var labelFilters = _label_filters.default.create().initialize();

  function stateAbbreviation(location) {
    var licenseType = location.get('license_type');

    var match = _constants.default.licenseTypes.find(function (license) {
      return license.value === licenseType;
    });

    return Ember.isPresent(match) ? match.labelAbbreviation : null;
  }

  function applicableLocation(location) {
    if (Ember.isBlank(location)) {
      return false;
    }

    return Ember.isPresent(stateAbbreviation(location));
  }

  function filenameForCustomTemplate(type) {
    return DieselTonicTemplatePath + type + '.ZPL';
  }

  function extractMetadataFromFilename(filename) {
    var match = filename.match(/^((\w+-)*)(\d+).ZPL$/);
    return {
      filename: "".concat(DieselTonicTemplatePath).concat(filename),
      prefix: match[1],
      version: parseInt(match[3])
    };
  }

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    http: Ember.inject.service(),
    config: Ember.inject.service(),
    minVersion: '0.2.1',
    minElectronVersion: '0.0.1',
    foundVersion: null,
    templates: {},
    logoFingerprint: null,
    timer: null,
    launchDarkly: Ember.inject.service(),
    init: function init() {
      this._super();

      this.ping().catch(function () {});
      this.pollForNewTemplates();

      if (this.get('canUseElectronPrinter')) {
        _logrocket.default.init('zg2tcu/greenbits-backoffice', {
          release: this.get('config').getConfig('currentRevision')
        });

        _logrocket.default.identify(this.get('authManager.currentUser.id'), {
          username: this.get('authManager.currentUser.username'),
          location_id: this.get('authManager.currentLocation.id'),
          location_name: this.get('authManager.currentLocation.name'),
          account_name: this.get('authManager.currentAccount.name'),
          email: this.get('authManager.currentUser.email'),
          name: this.get('authManager.currentUser.name')
        });
      }
    },
    acceptableVersion: Ember.computed('minVersion', 'foundVersion', function () {
      var minVersion = this.get('minVersion');
      var foundVersion = this.get('foundVersion');

      if (!Ember.isPresent(foundVersion)) {
        return false;
      }

      return (0, _semverSort.default)([minVersion, foundVersion])[0] === minVersion;
    }),
    acceptableElectronVersion: Ember.computed('minElectronVersion', 'electronVersion', function () {
      var minVersion = this.get('minElectronVersion');
      var foundVersion = this.get('electronVersion');

      if (!Ember.isPresent(foundVersion)) {
        return false;
      }

      return (0, _semverSort.default)([minVersion, foundVersion])[0] === minVersion;
    }),
    isChrome: Ember.computed(function () {
      return typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined';
    }),
    canPrint: Ember.computed.or('canPrintWithExtension', 'canPrintWithElectron'),
    canPrintWithExtension: Ember.computed('isChrome', 'acceptableVersion', function () {
      return this.get('isChrome') && this.get('acceptableVersion');
    }),
    canUseElectronPrinter: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('electron-printer-connector', false);
    }),
    canPrintWithElectron: Ember.computed.and('canUseElectronPrinter', 'acceptableElectronVersion'),
    canPrintCustomLabels: function canPrintCustomLabels(labelType) {
      var location = this.get('authManager.currentLocation');
      var launchDarkly = this.get('launchDarkly');
      var isSkuLabel = labelType === _constants.default.labelType.sku;
      var isUnregulated = location.get('isUnregulated');
      var hasFeature = launchDarkly.can('custom-sku-label');
      return !isSkuLabel || isUnregulated || hasFeature;
    },
    canUseBetaLabels: function canUseBetaLabels() {
      return this.get('launchDarkly').can('beta-labels', false);
    },
    cleanupCachedTemplates: function cleanupCachedTemplates(filenames) {
      var newMetadata = {};
      filenames.forEach(function (filename) {
        var metadata = extractMetadataFromFilename(filename);
        newMetadata[metadata.prefix] = metadata;
      });
      var cachedTemplates = this.get('templates');
      Object.keys(cachedTemplates).forEach(function (prefix) {
        if (Ember.isEmpty(newMetadata[prefix])) {
          return;
        }

        if (cachedTemplates[prefix].version < newMetadata[prefix].version) {
          delete cachedTemplates[prefix];
        }
      });
    },
    pollForNewTemplates: function pollForNewTemplates() {
      var _this = this;

      if (Ember.isPresent(this.get('timer'))) {
        clearTimeout(this.get('timer'));
      }

      var location = this.get('authManager.currentLocation');

      if (Ember.isBlank(location)) {
        this.schedule(300);
        return;
      }

      return this.fetchStateZplIndex(location).then(function (filenames) {
        _this.cleanupCachedTemplates(filenames);
      }).catch(function () {}).finally(function () {
        _this.schedule(300);
      });
    },
    schedule: function schedule(seconds) {
      this.set('timer', setTimeout(this.pollForNewTemplates.bind(this), seconds * 1000));
    },
    printSkuLabel: function printSkuLabel(data) {
      return this.printLabel(_constants.default.labelType.sku, data);
    },
    printWeightLabel: function printWeightLabel(data) {
      return this.printLabel(_constants.default.labelType.weight, data);
    },
    printLabel: function printLabel(labelType, data) {
      var _this2 = this;

      var location = this.get('authManager.currentLocation');
      var customFilename = filenameForCustomTemplate(labelType);
      data['isMedical'] = this.isMedical(location, data.inventoryItem);
      data.location = location;
      return this.fetchLogo(data.labelPreference).then(function () {
        if (_this2.canPrintCustomLabels(labelType)) {
          return _this2.fetchCustomZpl(customFilename);
        } else {
          return;
        }
      }).then(function (zpl) {
        if (zpl) {
          return {
            custom: true,
            zpl: zpl,
            filename: customFilename
          };
        }

        var medical = _this2.isMedical(location, data.inventoryItem);

        return _this2.fetchZpl(location, labelType, medical);
      }).then(function (templateData) {
        // If we don't have any template data here, that means we failed to fetch an applicable
        // template for some reason. At this point, we can't continue with printing the label.
        if (!templateData) {
          return Ember.RSVP.reject();
        }

        var licenseType = location.get('license_type');

        var builder = _zplBuilder.default.create(data); // set license specific filter to further transform datapoints to be used in label


        builder.setFilter(labelFilters.getFilter(licenseType));
        var builtZpl = builder.buildValuesZpl(templateData.zpl, templateData.filename, labelType);
        return _this2.sendCommand('print', [builtZpl, false]);
      });
    },
    isMedical: function isMedical(location, inventoryItem) {
      // If the location sells both recreational and medical inventory, delegate to the inventory
      // item to determine whether the medical template should be used.
      if (location.get('medical') && location.get('recreational')) {
        return inventoryItem.get('medical');
      } // The location at this point is either medical OR recreational, not both. So we can just use
      // the medical flag for the location here.


      return location.get('medical');
    },
    showDisplay: function showDisplay() {
      var channel;

      if (this.get('canPrintWithElectron')) {
        channel = 'electron';
      } else if (this.get('canPrintWithExtension')) {
        channel = 'extension';
      }

      if (!channel) {
        return;
      }

      return this.sendCommand('showDisplay', null, channel);
    },
    ping: function ping() {
      var _this3 = this;

      return Ember.RSVP.Promise.race([{
        channel: 'extension',
        versionVariable: 'foundVersion',
        versionCheck: 'acceptableVersion'
      }, {
        channel: 'electron',
        versionVariable: 'electronVersion',
        versionCheck: 'acceptableElectronVersion'
      }].map(function (_ref) {
        var channel = _ref.channel,
            versionVariable = _ref.versionVariable,
            versionCheck = _ref.versionCheck;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (channel === 'extension' && !_this3.get('isChrome')) {
            return reject(new Error('Chrome browser is required'));
          }

          _this3.sendCommand('version', null, channel).then(function (version) {
            _this3.set(versionVariable, version);

            _this3.get(versionCheck) ? resolve() : reject();
          }).catch(reject);
        });
      }));
    },
    sendCommand: function sendCommand(command, args, channel) {
      if (!channel) {
        channel = this.get('canPrintWithElectron') ? 'electron' : 'extension';
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var message = {
          invoke: command,
          args: args
        };

        if (channel === 'electron') {
          _axios.default.post('http://localhost:8090/call', message, {
            timeout: 60 * 1000
          }).then(function (response) {
            resolve(response.data.returnValue);
          }).catch(function (error) {
            var errorMessage;

            if (error.response) {
              // Server responded with a status code that falls out of the range of 2xx
              errorMessage = error.response.data.message;
            } else if (error.code === 'ECONNABORTED') {
              // Axios timeout
              errorMessage = 'axios timeout';
            } else if (error.request) {
              // The request was made but no response was received
              errorMessage = error.message;
            } else {
              // Unhandled error
              errorMessage = 'gb printer error';
            }

            _logrocket.default.captureMessage(errorMessage, {
              extra: {
                error: error
              }
            });

            reject(errorMessage);
          });
        } else {
          chrome.runtime.sendMessage(_environment.default.APP.labelPrinterAppId, message, function (response) {
            if (Ember.isPresent(response)) {
              if (Ember.isPresent(response.error)) {
                reject(response.error);
              } else {
                resolve(response.result);
              }
            } else {
              reject(new Error('Chrome extension not found'));
            }
          });
        }
      });
    },
    baseZplUrl: function baseZplUrl(location) {
      var environmentSuffix;

      if (_environment.default.environment === 'development') {
        environmentSuffix = '-dev';
      } else if (this.canUseBetaLabels()) {
        environmentSuffix = '-beta';
      } else {
        environmentSuffix = '';
      }

      return "".concat(LabelTemplatesUrl).concat(environmentSuffix, "/").concat(stateAbbreviation(location));
    },
    fetchStateZplIndex: function fetchStateZplIndex(location) {
      if (!applicableLocation(location)) {
        return Ember.RSVP.reject('Cannot fetch template index for location');
      }

      var url = this.baseZplUrl(location) + "/".concat(stateAbbreviation(location), "-TEMPLATES-V2.txt?_t=").concat(new Date().getTime());
      return Ember.get(this, 'http').get(url, {
        Authorization: ''
      }).then(function (response) {
        return response.text();
      }).then(function (text) {
        return text.split(/\n/).reject(function (l) {
          return Ember.isBlank(l);
        });
      });
    },
    storeZplInPrinter: function storeZplInPrinter(zpl) {
      this.sendCommand('print', [zpl, false]);
    },
    fetchPrinterStoredZpl: function fetchPrinterStoredZpl(filename) {
      var zpl = "^XA^HF".concat(filename, "^XZ");
      return this.sendCommand('print', [zpl, true]);
    },
    fetchLogo: function fetchLogo(labelPreference) {
      var _this4 = this;

      if (Ember.isEmpty(labelPreference)) {
        return Ember.RSVP.resolve();
      }

      if (this.get('logoFingerprint') === labelPreference.get('logoFingerprint')) {
        return Ember.RSVP.resolve();
      }

      return Ember.get(this, 'http').get(labelPreference.get('logoUrl'), {
        Authorization: ''
      }).then(function (response) {
        return response.text().then(function (logoZpl) {
          _this4.set('logoFingerprint', labelPreference.get('logoFingerprint'));

          return _this4.sendCommand('print', [logoZpl, true]);
        });
      });
    },
    fetchCustomZpl: function fetchCustomZpl(filename) {
      return this.fetchPrinterStoredZpl(filename).then(function (response) {
        if (response) {
          // CPC-369: S3 provided GBtemplates do not have format start/end markers. Custom templates do. Strip them
          // from custom templates, because we'll add them back in once we've interpolated all the necessary values.
          // Custom templates are also prefixed with the file name, so strip that else as well.
          //
          // E.G. with a file named WEIGHT.zpl
          // ^XA^DFWEIGHT,
          // ... ZPL format code we care about
          // ^XZ
          var startMarker = /(^\^XA\^DF.*,)/;
          var endMarker = /(\^XZ[\r\n ]*$)/;
          return response.replace(startMarker, '').replace(endMarker, '');
        } else {
          return null;
        }
      });
    },
    fetchZpl: function fetchZpl(location, type, medical) {
      var _this5 = this;

      if (!applicableLocation(location)) {
        return Ember.RSVP.reject('Cannot fetch template for location');
      }

      var prefix = "".concat(type, "-").concat(stateAbbreviation(location), "-");
      prefix = prefix + (medical ? 'M-' : 'R-');
      var cachedTemplates = this.get('templates');

      if (cachedTemplates[prefix]) {
        return this.fetchPrinterStoredZpl(cachedTemplates[prefix].filename).then(function (response) {
          if (response) {
            return cachedTemplates[prefix];
          } else {
            _this5.storeZplInPrinter(cachedTemplates[prefix].zpl);

            return cachedTemplates[prefix];
          }
        });
      }

      var metadata;
      return this.fetchStateZplIndex(location).then(function (filenames) {
        var filename = filenames.find(function (z) {
          return z.match(prefix);
        }); // If there are no matching file names, that means there is no template in the list that we
        // can use. This could be because the location is misconfigured (ie medical only license
        // that has recreational=true) or we are missing a template in the list.

        if (!filename) {
          console.error('Expected to find a template with prefix', prefix);
          return Ember.RSVP.reject();
        }

        metadata = extractMetadataFromFilename(filename);
        var url = _this5.baseZplUrl(location) + '/' + filename;
        return Ember.get(_this5, 'http').get(url, {
          Authorization: ''
        }).then(function (result) {
          return result.text();
        }).then(function (zpl) {
          cachedTemplates[metadata.prefix] = metadata;
          metadata.zpl = zpl;
          metadata.version = 1;

          _this5.storeZplInPrinter(metadata.zpl);

          return metadata;
        }).catch(function () {});
      });
    }
  });

  _exports.default = _default;
});