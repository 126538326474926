define("herer-web/mixins/confirm-dialog", ["exports", "bootbox"], function (_exports, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    confirmChange: function confirmChange(message, confirmedCallback, cancelledCallback) {
      return new Promise(function (resolve) {
        _bootbox.default.confirm(Ember.String.htmlSafe(message).toString(), function (confirmed) {
          if (confirmed) {
            resolve(confirmedCallback());
          } else {
            resolve(cancelledCallback());
          }
        });
      });
    }
  });

  _exports.default = _default;
});