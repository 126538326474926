define("herer-web/controllers/menu-feeds/new", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context"], function (_exports, _alertUnsavedController, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    additionalDirtyCheckResult: Ember.computed.alias('model.hasDirtyMenuGroups')
  });

  _exports.default = _default;
});