define("herer-web/templates/reports/budtender-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fl/AzmdI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"sales-report\",null,[[\"title\",\"interval\",\"startDate\",\"endDate\",\"sortBy\",\"sortDirection\",\"table\",\"onRefreshClick\",\"onExportClick\",\"onIntervalChange\",\"data-test-group\"],[\"Budtender Performance Snapshot\",[24,[\"interval\"]],[24,[\"startDate\"]],[24,[\"endDate\"]],[24,[\"sortBy\"]],[24,[\"sortDirection\"]],[24,[\"table\"]],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"refresh\"],null]],null],[28,\"action\",[[23,0,[]],\"exportReport\"],null],[28,\"action\",[[23,0,[]],\"updateDatesFromInterval\"],null],\"budtender_performance_report\"]],{\"statements\":[[0,\"  \"],[1,[28,\"budtender-performance-chart\",null,[[\"data\",\"data-test-group\"],[[24,[\"table\"]],\"budtender_performance_chart\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/reports/budtender-performance.hbs"
    }
  });

  _exports.default = _default;
});