define("herer-web/controllers/untracked-migrations", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var onlyTrackedLicenseTypes = _constants.default.licenseTypes.filter(function (lt) {
    return lt.value !== _constants.default.licenseType.unregulated;
  }).filter(function (lt) {
    return !lt.name.toLowerCase().includes('untracked');
  });

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    http: Ember.inject.service(),
    licenseOptions: onlyTrackedLicenseTypes,
    locationName: null,
    allMigrations: null,
    hasValidStateCredentials: false,
    isPartiallyMigrated: false,
    // Ensure tag errors are distinct
    tagErrors: Ember.computed('model.errors.tags', function () {
      var tagErrors = this.get('model.errors.tags');
      return tagErrors && _toConsumableArray(new Set(tagErrors.map(function (error) {
        return error.message;
      })));
    }),
    isSubmitDisabled: Ember.computed('locationName', function () {
      return !Ember.isPresent(this.get('locationName'));
    }),
    needsCredentials: Ember.computed('model.errors.license_number', 'model.errors.api_key', 'model.errors.state_credentials', 'hasValidStateCredentials', function () {
      return !this.get('hasValidStateCredentials') || this.get('model.errors.license_number') || this.get('model.errors.api_key') || this.get('model.errors.state_credentials');
    }),
    resetLocation: function resetLocation() {
      this.set('locationName', null);
    },
    setLocation: function setLocation(location) {
      this.set('locationName', location.name);
      this.set('hasValidStateCredentials', location.has_state_credentials);
    },
    scheduleMigration: function scheduleMigration() {
      var _this = this;

      return this.get('model').save().then(function () {
        _this.get('toast').success('Successfully scheduled this migration');

        _this.set('model', _this.get('store').createRecord('untracked_migration', {}));

        _this.send('refresh');

        _this.resetLocation();
      });
    },
    actions: {
      onDryRunChange: function onDryRunChange() {
        var dryRun = this.get('model.dry_run');
        this.set('model.take_inventory_backup', !dryRun);
      },
      onAvailableTagsUpload: function onAvailableTagsUpload(event) {
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.addEventListener('load', function () {
          var b64 = reader.result.split(',')[1];
          this.set('model.available_tags', b64);
        }.bind(this), false);
        reader.readAsDataURL(file);
      },
      onCancel: function onCancel() {
        this.send('refresh');
      },
      create: function create() {
        if (!this.get('isPartiallyMigrated')) {
          this.set('model.launch_date', this.get('model.start_time'));
        }

        this.scheduleMigration();
      },
      setLocation: function setLocation() {
        var _this2 = this;

        var params = {
          migration: {
            location_id: this.get('model.location_id')
          }
        };
        Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/untracked_migrations/location"), params).then(function (response) {
          return _this2.setLocation(response);
        }).catch(function () {
          return _this2.resetLocation();
        });
      },
      setStartTime: function setStartTime(date) {
        this.model.set("start_time", date);
      },
      setLaunchDate: function setLaunchDate(date) {
        this.model.set("launch_date", date);
      }
    }
  });

  _exports.default = _default;
});