define("herer-web/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CY7ryaRa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container outlet-container\"],[10,\"data-test-container\",\"error\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 text-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"error\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-exclamation-triangle\"],[10,\"data-test-ux\",\"exclamation_triangle\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"\\n        Sorry, something went wrong.\\n      \"],[9],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        If you still have this issue, chat Support.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/error.hbs"
    }
  });

  _exports.default = _default;
});