define("herer-web/templates/payment-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2NZUn/UU",
    "block": "{\"symbols\":[\"paymentMethod\",\"index\"],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"data-test-ux\"],[\"Payment Methods\",\"Configure which payment methods are accepted at this store.\",\"payment_methods_header\"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-horizontal\"],[10,\"data-test-container\",\"payment_methods_form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row section\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-7\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-10 col-sm-push-1 col-md-11 col-md-push-1\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"payment-method-toggle\",null,[[\"paymentMethod\",\"data-test-item\",\"data-test-index\"],[[23,1,[]],\"payment_method\",[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/payment-methods.hbs"
    }
  });

  _exports.default = _default;
});