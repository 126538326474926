define("herer-web/components/sku-as-barcode", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    autoPlacement: true,
    tagName: '',
    label: null,
    tooltipText: Ember.computed(function () {
      return Ember.String.htmlSafe(_constants.default.barcodeTooltip);
    })
  });

  _exports.default = _default;
});