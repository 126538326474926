define("herer-web/models/traceability-notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _model.attr)('string'),
    lot_number: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    unreported_sales_amount: (0, _model.attr)('weight'),
    unreported_sales_count: (0, _model.attr)('number'),
    metadata: (0, _model.attr)('raw')
  });

  _exports.default = _default;
});