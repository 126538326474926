define("herer-web/templates/components/product-inventory-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkGk/qpa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasInventory\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"items\",\"adjustedItems\"],[[24,[\"items\"]],[24,[\"adjustedItems\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showProductIfNoInventory\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row table-row product\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"class\",[29,[\"label label-ellipsis \",[24,[\"product\",\"product_type\",\"colorClass\"]]]]],[8],[0,\"\\n        \"],[1,[24,[\"product\",\"product_type\",\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"title\",[24,[\"product\",\"name\"]]],[10,\"class\",\"col-md-2 ellipsis\"],[8],[0,\"\\n      \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n    \"],[9],[4,\"if\",[[24,[\"hasLoaded\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-3 text-muted\"],[8],[0,\"\\n        \"],[7,\"em\",true],[8],[0,\"\\n          No Inventory found\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-md-8 text-center text-muted\"],[8],[0,\"\\n        \"],[7,\"em\",true],[8],[0,\"\\n          Loading inventory\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/product-inventory-list.hbs"
    }
  });

  _exports.default = _default;
});