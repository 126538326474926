define("herer-web/routes/taxes", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'settings-nav',
    permissionKey: 'view_tax_items',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        taxItems: store.findAll('tax-item'),
        personGroups: store.findAll('personGroup'),
        productTypes: store.findAll('product-type')
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render('tax-item', {
        into: 'taxes',
        outlet: 'tax-item-form',
        controller: 'tax-item'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.taxItems);

      controller.set('productTypes', model.productTypes);
      controller.set('personGroups', model.personGroups);
    }
  });

  _exports.default = _default;
});