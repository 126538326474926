define("herer-web/templates/components/line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z+kGsTSO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-sm-6 double-line\"],[10,\"data-test-value\",\"name_and_weight\"],[8],[0,\"\\n  \"],[1,[24,[\"lineItem\",\"inventory_item\",\"product\",\"name\"]],false],[4,\"if\",[[24,[\"lineItem\",\"weight\"]]],null,{\"statements\":[[0,\"\\n    (\"],[1,[24,[\"lineItem\",\"weight\"]],false],[0,\"g)\\n  \"]],\"parameters\":[]},null],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[10,\"data-test-value\",\"sku\"],[8],[0,\"\\n    \"],[1,[24,[\"lineItem\",\"sku\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 text-muted text-right\"],[10,\"data-test-value\",\"quantity\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showQuantity\"]]],null,{\"statements\":[[0,\"    x \"],[1,[24,[\"lineItem\",\"quantity\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 text-right\"],[10,\"data-test-value\",\"subtotal\"],[8],[0,\"\\n  \"],[1,[28,\"format-unit\",[[24,[\"lineItem\",\"subtotal\"]]],[[\"unit\"],[\"dollar\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/line-item.hbs"
    }
  });

  _exports.default = _default;
});