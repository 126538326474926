define("herer-web/routes/queue", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    sideNavTemplateName: 'customers-nav',
    mixpanelEvent: 'CustomerQueueList.show',
    modelName: 'person',
    sortBy: 'name',
    sortDirection: 'asc'
  });

  _exports.default = _default;
});