define("herer-web/pivot-table/table", ["exports", "herer-web/pivot-table/cell", "herer-web/pivot-table/group", "herer-web/pivot-table/total-row", "big.js"], function (_exports, _cell, _group, _totalRow, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    columnName: null,
    rowName: null,
    valueName: null,
    groupBy: null,
    sortBy: null,
    sortAscending: true,
    unit: 'dollar',
    showRowTotals: true,
    averageTotal: false,
    sourceData: [],
    init: function init() {
      this._super();

      if (this.get('sourceData').length) {
        return this.generateRows();
      }
    },
    updateSort: function updateSort(sortBy, sortDirection) {
      var _this = this;

      var changeOrder = sortBy === this.get('sortBy');
      this.set('sortBy', sortBy);

      if (!this.get('sortAscending') && sortDirection === 'asc') {
        this.set('sortAscending', true);
      } else if (this.get('sortAscending') && sortDirection === 'desc') {
        this.set('sortAscending', false);
      } else {
        this.set('sortAscending', changeOrder ? !this.get('sortAscending') : true);
      }

      return function () {
        var result = [];

        for (var _i = 0, _Array$from = Array.from(_this.get('groups')); _i < _Array$from.length; _i++) {
          var group = _Array$from[_i];
          group.set('sortBy', _this.get('sortBy'));
          result.push(group.set('sortAscending', _this.get('sortAscending')));
        }

        return result;
      }();
    },
    columns: Ember.computed('multiValue', 'columnName', 'sourceData.[]', function () {
      if (this.get('multiValue')) {
        return this.get('columnName');
      } else if (this.get('columnName') != null) {
        return this.get('sourceData').mapBy(this.get('columnName')).uniq();
      } else {
        return ['Total'];
      }
    }),
    headings: Ember.computed('sortBy', 'sortAscending', 'columns', 'columns.[]', function () {
      var _this2 = this;

      return this.get('columns').map(function (column) {
        return {
          name: column,
          sorted: column === _this2.get('sortBy'),
          ascending: _this2.get('sortAscending')
        };
      });
    }),
    units: Ember.computed('multiValue', 'unit', 'columns.[]', function () {
      var _this3 = this;

      if (this.get('multiValue')) {
        return this.get('unit');
      } else {
        return this.get('columns').map(function () {
          return _this3.get('unit');
        });
      }
    }),
    singleColumn: Ember.computed('columnName', function () {
      return this.get('columnName') === null;
    }),
    groups: Ember.computed('groupBy', 'sourceData.[]', function () {
      var _this4 = this;

      if (this.get('groupBy')) {
        return this.get('sourceData').mapBy(this.get('groupBy')).uniq().map(function (header) {
          return _group.default.create({
            header: header,
            columns: _this4.get('columns'),
            units: _this4.get('units'),
            averageTotal: _this4.get('averageTotal'),
            sortBy: _this4.get('sortBy'),
            sortAscending: _this4.get('sortAscending')
          });
        });
      } else {
        return [_group.default.create({
          columns: this.get('columns'),
          units: this.get('units'),
          averageTotal: this.get('averageTotal'),
          sortBy: this.get('sortBy'),
          sortAscending: this.get('sortAscending')
        })];
      }
    }),
    empty: Ember.computed('groups.[]', function () {
      return this.get('groups').every(function (group) {
        return group.get('empty');
      });
    }),
    totalRow: Ember.computed('averageTotal', 'groups.[]', function () {
      var totals = _totalRow.default.create({
        header: 'Total',
        averageTotal: this.get('averageTotal')
      });

      totals.addGroups(this.get('groups'));
      return totals;
    }),
    multiValue: Ember.computed('valueName', function () {
      return this.get('valueName') instanceof Array;
    }),
    valueNames: Ember.computed('multiValue', 'valueName', function () {
      if (this.get('multiValue')) {
        return this.get('valueName');
      } else {
        return [this.get('valueName')];
      }
    }),
    generateRows: Ember.observer('sourceData', function () {
      var _this5 = this;

      return this.get('sourceData').forEach(function (item) {
        var rowName = item[_this5.get('rowName')];

        var groupName = item[_this5.get('groupBy')];

        return _this5.get('valueNames').forEach(function (valueName, index) {
          var columnName = _this5.columnFor(item, index);

          var unit = _this5.unitFor(item, index);

          var value = new _big.default(item[valueName] || 0);

          var cell = _cell.default.create({
            group: groupName,
            column: columnName,
            row: rowName,
            value: value,
            unit: unit
          });

          var group = _this5.groupFor(groupName);

          var row = group.rowFor(cell);
          return row.add(cell);
        });
      });
    }),
    columnFor: function columnFor(item, index) {
      if (this.get('multiValue')) {
        return this.get('columnName')[index];
      } else if (this.get('singleColumn')) {
        return 'Total';
      } else {
        return item[this.get('columnName')];
      }
    },
    unitFor: function unitFor(item, index) {
      if (this.get('multiValue')) {
        return this.get('unit')[index] || 'dollar';
      } else {
        return this.get('unit');
      }
    },
    groupFor: function groupFor(header) {
      if (this.get('groupBy')) {
        return this.get('groups').findBy('header', header);
      } else {
        return this.get('groups')[0];
      }
    }
  });

  _exports.default = _default;
});