define("herer-web/controllers/menu-feeds", ["exports", "herer-web/mixins/context", "bootbox", "herer-web/mixins/pageable", "herer-web/constants"], function (_exports, _context, _bootbox, _pageable, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _pageable.default, {
    leaflyAuthManager: Ember.inject.service(),
    filters: Ember.computed(function () {
      var intTypes = Object.values(_constants.default.menuFeedType).filter(function (el) {
        return el !== _constants.default.menuFeedType.marketing;
      });
      return {
        by_types: intTypes
      };
    }),
    validatePublish: function validatePublish(menuFeed) {
      if (menuFeed.get('published')) {
        return Ember.RSVP.resolve();
      }

      var missingProperties = [];
      var missingApiKey = Ember.isEmpty(menuFeed.get('apiKey'));

      if (menuFeed.get('isWeedmaps') && missingApiKey) {
        missingProperties.push('API key');
      }

      if (menuFeed.get('isWebhook') && Ember.isEmpty(menuFeed.get('url')) && missingApiKey) {
        missingProperties.push('webhook url');
      }

      if (Ember.isEmpty(missingProperties)) {
        return Ember.RSVP.resolve();
      }

      return Ember.RSVP.reject(missingProperties);
    },
    showMissingProperties: function showMissingProperties(missingProperties) {
      var message = "You need to set this menu feed's ".concat(missingProperties.join(' and ')) + ' before you can publish it.';

      _bootbox.default.alert({
        message: Ember.Handlebars.Utils.escapeExpression(message)
      });
    },
    showLeaflyAuthorizationRequest: function showLeaflyAuthorizationRequest(menuFeed) {
      var _this = this;

      var message = 'In order to publish your menu to Leafly, you must first ' + 'authorize access to your Leafly account. This process will delete all ' + 'items on your existing Leafly menu and only take a couple minutes to ' + 'complete. Do you want to continue?';
      return _bootbox.default.dialog({
        message: message,
        buttons: {
          continue: {
            label: 'Confirm',
            className: 'btn-warning',
            callback: function callback() {
              _this.showConfirmLeaflyAuthorizationRequest(menuFeed);
            }
          },
          abort: {
            label: 'Cancel',
            className: 'btn-default'
          }
        }
      });
    },
    showConfirmLeaflyAuthorizationRequest: function showConfirmLeaflyAuthorizationRequest(menuFeed) {
      var _this2 = this;

      var message = 'We will redirect you to Leafly for Greenbits menu ' + 'publishing authorization.';
      return _bootbox.default.dialog({
        message: message,
        buttons: {
          continue: {
            label: 'Authorize',
            className: 'btn-primary',
            callback: function callback() {
              _this2.get('leaflyAuthManager').authorize(menuFeed);
            }
          },
          abort: {
            label: 'Cancel',
            className: 'btn-default'
          }
        }
      });
    },
    actions: {
      togglePublish: function togglePublish(menuFeed) {
        var _this3 = this;

        var isLeafly = menuFeed.get('isLeafly');
        var published = menuFeed.get('published');
        menuFeed.reload().then(function () {
          return _this3.validatePublish(menuFeed);
        }).then(function () {
          if (isLeafly && !published) {
            _this3.showLeaflyAuthorizationRequest(menuFeed);

            return;
          }

          menuFeed.set('published', !published);
          menuFeed.save();
        }).catch(function (missingProperties) {
          _this3.showMissingProperties(missingProperties);
        });
      }
    }
  });

  _exports.default = _default;
});