define("herer-web/templates/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0yV0dpXQ",
    "block": "{\"symbols\":[\"crud\"],\"statements\":[[4,\"crud-list-form\",null,[[\"models\",\"header\",\"subheader\",\"accountWide\",\"modelName\",\"modelIconClass\",\"canUpdate\",\"canCreate\",\"canDelete\",\"onCreate\",\"data-test-container\"],[[24,[\"sortedRoles\"]],\"Roles\",\"Roles dictate what permissions users have.\",true,\"Role\",\"fa-unlock-alt\",[28,\"has-permission\",[\"update_roles\"],null],[28,\"has-permission\",[\"create_roles\"],null],[28,\"has-permission\",[\"destroy_roles\"],null],[28,\"action\",[[23,0,[]],\"onCreate\"],null],\"roles\"]],{\"statements\":[[4,\"if\",[[23,1,[\"rowSection\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[\"model\",\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"formSection\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"role-form\",null,[[\"role\",\"disabled\"],[[23,1,[\"model\"]],[28,\"not\",[[28,\"has-permission\",[\"update_roles\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/roles.hbs"
    }
  });

  _exports.default = _default;
});