define("herer-web/helpers/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    return function (element) {
      return element.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    };
  });

  _exports.default = _default;
});