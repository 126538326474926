define("herer-web/components/inventory-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ch6rjRGT",
    "block": "{\"symbols\":[\"@auditProductList\"],\"statements\":[[4,\"if\",[[23,0,[\"showAuditButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-audit\"],[12,\"data-test-action\",\"products_audit_product_list\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-cubes\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n      \"],[1,[23,0,[\"auditBtnText\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/inventory-options/template.hbs"
    }
  });

  _exports.default = _default;
});