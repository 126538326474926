define("herer-web/components/product/test-results-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EdZKO4U0",
    "block": "{\"symbols\":[\"@number\",\"@dataTestId\",\"@readonly\",\"@disabled\",\"@onUpdate\",\"@name\",\"@isPercent\",\"@unitAbbreviation\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n    \"],[1,[23,6,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"    \"],[5,\"gb-percent-input\",[[12,\"class\",\"form-control test-result-input\"],[12,\"data-test-input\",[23,2,[]]],[12,\"readonly\",[23,3,[]]],[12,\"disabled\",[23,4,[]]]],[[\"@number\",\"@onUpdate\"],[[23,1,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"number-input\",[[12,\"data-test-input\",[23,2,[]]]],[[\"@number\",\"@class\",\"@readonly\",\"@disabled\",\"@onUpdate\"],[[23,1,[]],\"form-control test-result-input\",[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"div\",true],[11,\"data-test-ux\",[23,0,[\"abbreviationTestId\"]]],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n    \"],[1,[23,8,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/product/test-results-input/template.hbs"
    }
  });

  _exports.default = _default;
});