define("herer-web/templates/components/pricing-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LSayv8xK",
    "block": "{\"symbols\":[\"@pricingTier\",\"@inputPrefix\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"form-group \",[28,\"if\",[[23,1,[\"validations\",\"fieldErrors\",\"price\"]],\"has-error\"],null]]]],[10,\"data-test-group\",\"pricing_tier\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label col-xs-3\"],[10,\"data-test-text\",\"label\"],[8],[0,\"\\n    \"],[1,[23,1,[\"quantity\",\"formatted\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-9\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n        $\\n      \"],[9],[0,\"\\n      \"],[5,\"currency-input\",[[12,\"data-test-input\",[28,\"concat\",[[23,2,[]],\"_\",[23,1,[\"quantity\",\"valueParameterized\"]],\"_\",[23,1,[\"quantity\",\"unitParameterized\"]]],null]]],[[\"@currency\",\"@onUpdate\",\"@placeholder\",\"@class\"],[[23,1,[\"price\"]],[28,\"fn\",[[23,0,[\"setPrice\"]]],null],\"0.00\",\"form-control\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"error-messages\",[],[[\"@errors\"],[[23,1,[\"validations\",\"fieldErrors\",\"price\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/pricing-tier.hbs"
    }
  });

  _exports.default = _default;
});