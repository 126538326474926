define("herer-web/templates/strains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P+ijMxi6",
    "block": "{\"symbols\":[\"crud\"],\"statements\":[[4,\"crud-list-form\",null,[[\"models\",\"header\",\"modelName\",\"modelIconClass\",\"data-test-group\",\"canUpdate\",\"canCreate\",\"canDelete\",\"onLoadMore\"],[[24,[\"model\"]],\"Strains\",\"Strain\",\"fa-tree\",\"strains\",[28,\"has-permission\",[\"update_strains\"],null],[28,\"has-permission\",[\"create_strains\"],null],[28,\"has-permission\",[\"destroy_strains\"],null],[28,\"action\",[[23,0,[]],\"loadMore\"],null]]],{\"statements\":[[4,\"if\",[[23,1,[\"formSection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-section\"],[8],[0,\"\\n          \"],[7,\"div\",true],[11,\"class\",[29,[\"form-group \",[28,\"if\",[[23,1,[\"model\",\"errors\",\"name\"]],\"has-error\"],null]]]],[10,\"data-test-group\",\"name\"],[8],[0,\"\\n            \"],[7,\"label\",true],[10,\"class\",\"control-label col-sm-4\"],[8],[0,\"\\n              Name\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[0,\"\\n              \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"readonly\",\"class\",\"data-test-input\"],[\"text\",[23,1,[\"model\",\"name\"]],[28,\"is-readonly\",[[28,\"not\",[[28,\"has-permission\",[\"update_strains\"],null]],null]],null],\"form-control\",\"name\"]]],false],[1,[28,\"error-messages\",null,[[\"errors\"],[[23,1,[\"model\",\"errors\",\"name\"]]]]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"rowSection\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/strains.hbs"
    }
  });

  _exports.default = _default;
});