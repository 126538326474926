define("herer-web/routes/location", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'settings-nav',
    permissionKey: 'view_company_settings',
    model: function model() {
      return this.get('authManager.currentLocation');
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor('location');
      controller.set('successMessage', '');
    }
  });

  _exports.default = _default;
});