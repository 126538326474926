define("herer-web/mixins/traceability-types", ["exports", "herer-web/mixins/context", "herer-web/utils/humanize", "lodash/startCase"], function (_exports, _context, _humanize, _startCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function fixSpecialName(name) {
    if (name === 'Shake Trim') {
      return 'Shake/Trim';
    }

    return name;
  }

  var _default = Ember.Mixin.create(_context.default, {
    traceabilityTypes: Ember.computed('currentLocation', function () {
      var traceableTypes = this.get('currentLocation.license.traceable_types');
      var listForSelect = [];

      for (var typeKey in traceableTypes) {
        var name = (0, _startCase.default)((0, _humanize.humanize)(typeKey));
        name = fixSpecialName(name);
        listForSelect.push({
          name: name,
          value: traceableTypes[typeKey]
        });
      }

      return listForSelect;
    })
  });

  _exports.default = _default;
});