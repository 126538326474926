define("herer-web/instance-initializers/bugsnag", ["exports", "herer-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var IGNORED_ERRORS = ['TransitionAborted'];

  function notifyBugsnag(error) {
    var skipError = IGNORED_ERRORS.includes(error === null || error === void 0 ? void 0 : error.name);

    if (_environment.default.APP.enableBugsnag && window.bugsnagClient && error && !skipError) {
      var message = error.message;

      if (Ember.isEmpty(message)) {
        message = error.description;
      }

      window.bugsnagClient.notify(error, {
        groupingHash: message
      });
    }
  }

  function logToConsole(error) {
    if (error) {
      console.error(error);
    }
  }

  function initialize(container) {
    if (_environment.default.APP.enableErrorCatching) {
      Ember.onerror = function (error) {
        notifyBugsnag(error);
        logToConsole(error);
        container.lookup('router:main').send('error', error);
      };

      Ember.RSVP.on('error', function (error) {
        notifyBugsnag(error);
        logToConsole(error);
        container.lookup('router:main').send('error', error);
      });
    }
  }

  var _default = {
    name: 'bugsnag',
    initialize: initialize
  };
  _exports.default = _default;
});