define("herer-web/controllers/inventory-move", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/inventory-weights", "herer-web/mixins/context"], function (_exports, _alertUnsavedController, _inventoryWeights, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller;

  var controllerMixin = [_alertUnsavedController.default, _context.default, _inventoryWeights.default];

  var _default = (_Ember$Controller = Ember.Controller).extend.apply(_Ember$Controller, controllerMixin.concat([{
    isConfirming: false,
    parentController: null,
    places: [],
    _hasValidated: false,
    _qtyHasValidated: false,
    toast: Ember.inject.service(),
    isSaving: false,
    itemUOM: Ember.computed.alias('model.inventoryItem.quantity.unit'),
    product: Ember.computed.alias('model.inventoryItem.product'),
    unlockedPlaces: Ember.computed.filterBy('places', 'isLocked', false),
    validate: function validate() {
      this.set('_hasValidated', true);
      this.set('_qtyHasValidated', true);
      return !this.get('model.anyErrors');
    },
    toPlaceError: Ember.computed('_hasValidated', 'model.toPlaceError', function () {
      return this.get('_hasValidated') ? this.get('model.toPlaceError') : null;
    }),
    fromPlaceError: Ember.computed('_hasValidated', 'model.fromPlaceError', function () {
      return this.get('_hasValidated') ? this.get('model.fromPlaceError') : null;
    }),
    quantityError: Ember.computed('_qtyHasValidated', 'model.quantity.value', function () {
      return this.get('_qtyHasValidated') ? this.get('model.quantityError') : null;
    }),
    modal: Ember.computed('model', function () {
      return $('#inventory-move-modal');
    }),
    unlockedPlacesWithPositiveQuantity: Ember.computed.alias('model.unlockedPlacesWithPositiveQuantity'),
    onInventoryItemChanged: function onInventoryItemChanged(inventoryItem) {
      this.set('isConfirming', false);
      this.set('_hasValidated', false);
      this.set('_qtyHasValidated', false);
      var modelFragment = this.get('store').createFragment('inventory-move', {
        inventoryItem: inventoryItem,
        quantity: inventoryItem.get('quantity').zero(),
        product: this.get('product'),
        toPlace: null
      });
      var fromPlace = modelFragment.get('unlockedPlacesWithPositiveQuantity').findBy('name', 'Back Stock') || modelFragment.get('unlockedPlacesWithPositiveQuantity.firstObject');
      Ember.set(modelFragment, 'fromPlace', fromPlace);
      Ember.set(modelFragment, 'quantity', modelFragment.get('maxPlaceQuantity').copy());
      this.set('model', modelFragment);
      this.get('modal').on('shown.bs.modal', function () {
        $('.quantity-input').focus();
        return $('.quantity-input').select();
      });
      this.get('modal').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    hideModal: function hideModal() {
      this.get('modal').modal('hide');
    },
    handleSuccess: function handleSuccess() {
      this.hideModal();
      this.get('toast').success("".concat(this.get('model.quantity'), " successfully moved from ").concat(this.get('model.fromPlace.name'), "\n            to ").concat(this.get('model.toPlace.name'), "."));
      this.get('product').reload();
      this.get('model.inventoryItem').reload();
      this.set('model', null);
    },
    handleError: function handleError(e) {
      console.error(e);
      this.get('toast').error("Attempt to move ".concat(this.get('model.quantity'), " from ").concat(this.get('model.fromPlace.name'), "\n            to ").concat(this.get('model.toPlace.name'), " failed!"));
      this.set('isConfirming', false);
    },
    setQuantity: function setQuantity(quantity) {
      this.set('model.quantity.value', quantity);
      this.set('_qtyHasValidated', true);
    },
    actions: {
      confirmInventoryMove: function confirmInventoryMove() {
        if (!this.validate()) {
          return;
        } else {
          this.set('isConfirming', true);
        }
      },
      unconfirmInventoryMove: function unconfirmInventoryMove() {
        return this.set('isConfirming', false);
      },
      saveInventoryMove: function saveInventoryMove() {
        var _this = this;

        Ember.set(this, "isSaving", true);
        this.get('model').save().then(function () {
          Ember.set(_this, "isSaving", false);

          _this.handleSuccess();
        }).catch(function (e) {
          Ember.set(_this, "isSaving", false);

          _this.handleError(e);
        });
      },
      cancelInventoryMove: function cancelInventoryMove() {
        this.get('model', null);
        this.hideModal();
      },
      handleTextInput: function handleTextInput(quantity) {
        this.setQuantity(quantity);
      },
      handleSlider: function handleSlider(quantity) {
        var maxQuantity = Number(this.get('model.maxPlaceQuantity.value'));

        if (Number(quantity) > maxQuantity) {
          quantity = maxQuantity;
        }

        this.setQuantity(quantity);
      },
      setInventoryToPlace: function setInventoryToPlace(place) {
        Ember.set(this.model, "toPlace", place);
      },
      setInventoryFromPlace: function setInventoryFromPlace(place) {
        var model = this.model;
        Ember.set(model, 'toPlace', null);
        Ember.set(model, 'fromPlace', place);
        Ember.set(model, 'quantity', model.maxPlaceQuantity.copy());
      },
      setInventoryItem: function setInventoryItem(inventoryItem) {
        var model = this.model;
        Ember.set(model, 'inventoryItem', inventoryItem);
        var startingPlace = this.unlockedPlacesWithPositiveQuantity.firstObject;
        Ember.set(model, 'toPlace', null);
        Ember.set(model, 'fromPlace', startingPlace);
        Ember.set(model, 'quantity', model.maxPlaceQuantity.copy());
      }
    }
  }]));

  _exports.default = _default;
});