define("herer-web/routes/qs-reports/monthly-tax-report", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/constants", "moment", "herer-web/mixins/taskable"], function (_exports, _authenticatedFullPage, _constants, _moment, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_taskable.default, {
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    sideNavTemplateName: 'reports-nav',
    permissionKey: 'view_tax_reports',
    modelName: 'monthly-tax-report',
    controllerName: 'monthly-tax-report',
    templateName: 'monthly-tax-report',
    model: function model(params) {
      return Ember.RSVP.hash({
        taxes: this.getTaxReport(params),
        stateCredentials: this.get('store').findAll('state-credential')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.taxes);

      controller.set('stateCredential', model.stateCredentials.get('firstObject'));
    },
    onTaskComplete: function onTaskComplete() {
      var controller = this.controllerFor('monthly-tax-report');
      controller.set('isLoading', false);

      if (this.get('task').get('error')) {
        this.rejectTaskCompleted('Something went wrong, please try again');
      } else {
        var task = this.get('task');
        var results = JSON.parse(task.options.results);
        this.resolveTaskCompleted(results.report);
      }
    },
    getTaxReport: function getTaxReport(params) {
      var _this = this;

      return this.get('store').findAll('monthly_tax_submission').then(function (monthly_tax_submissions) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('launchDarkly').waitForReady().then(function () {
            var controller = _this.controllerFor('monthly-tax-report');

            controller.set('isLoading', true);
            var http = Ember.get(_this, 'http');
            var year = params.year;
            var month = parseInt(params.month);

            var timezone = _this.get('authManager.currentLocation.timezone');

            var taxReportBackgroundMode = _this.get('launchDarkly').can('build-tax-report-in-background', false);

            var report_params = {
              start_date: (0, _moment.default)([year, month - 1]).startOf('month').tz(timezone, true).toISOString(),
              end_date: (0, _moment.default)([year, month - 1]).endOf('month').tz(timezone, true).toISOString()
            };
            var monthly_tax_submission = monthly_tax_submissions.toArray().find(function (tax_submission) {
              return tax_submission.get('year') === year && tax_submission.get('month') === month;
            });
            var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/tax"), report_params);
            http.get(url).then(function (response) {
              if (taxReportBackgroundMode) {
                _this.set('task', response.data.task);

                _this.pollTask();

                _this.resolveTaskCompleted = resolve;
                _this.rejectTaskCompleted = reject;
              } else {
                var data = response.data;
                data.report.submission = monthly_tax_submission;
                controller.set('isLoading', false);
                resolve(data.report);
              }
            }).catch(function (response) {
              reject(new Error("".concat(response.status, ": ").concat(response.data.error)));
            });
          });
        });
      });
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    }
  });

  _exports.default = _default;
});