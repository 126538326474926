define("herer-web/routes/qs-reports/shifts", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedFullPage, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_pageableRoute.default, {
    modelName: 'shift',
    templateName: 'shifts',
    controllerName: 'shifts',
    queryParams: {
      status: {
        refreshModel: false
      },
      register: {
        refreshModel: false
      },
      receipt_id: {
        refreshModel: false
      },
      employee: {
        refreshModel: false
      }
    } //renderTemplate: function() {
    //  this.render('shifts');
    //}

  });

  _exports.default = _default;
});