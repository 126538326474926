define("herer-web/templates/components/inventory-item-status-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y1sYaPH8",
    "block": "{\"symbols\":[\"xs\",\"status\",\"index\"],\"statements\":[[5,\"x-select\",[],[[\"@value\",\"@disabled\",\"@onChange\",\"@data-test-input\",\"@class\"],[[22,\"displayStatus\"],[22,\"statusIsLocked\"],[28,\"action\",[[23,0,[]],[24,[\"onStatusChange\"]]],null],\"inventory_item_status_select\",\"form-control\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"displayStatuses\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"option\"]],[],[[\"@value\",\"@data-test-item\",\"@data-test-index\"],[[23,2,[\"value\"]],\"status\",[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/inventory-item-status-select.hbs"
    }
  });

  _exports.default = _default;
});