define("herer-web/mixins/adjustment-reasons", ["exports", "herer-web/constants", "lodash/map"], function (_exports, _constants, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.maxAdjustmentNoteLength = void 0;
  var maxAdjustmentNoteLength = 255;
  _exports.maxAdjustmentNoteLength = maxAdjustmentNoteLength;
  var AdjustmentReasonsMixin = Ember.Mixin.create({
    maxAdjustmentNoteLength: maxAdjustmentNoteLength,
    adjustmentReasons: Ember.computed('authManager.currentLocation.license.adjustment_reasons', function () {
      var adjustmentReasons = this.get('authManager.currentLocation.license.adjustment_reasons');

      if (Ember.isArray(adjustmentReasons)) {
        return adjustmentReasons;
      } // after API changes are made to respond with full adjustment reasons, remove this


      return (0, _map.default)(adjustmentReasons, function (value) {
        return {
          name: _constants.default.adjustmentReasonNames[value],
          value: value
        };
      });
    })
  });
  var _default = AdjustmentReasonsMixin;
  _exports.default = _default;
});