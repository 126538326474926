define("herer-web/models/reportable-ccrs-sale", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Leaf Data
    licenseNumber: (0, _model.attr)('string'),
    soldToLicenseNumber: (0, _model.attr)('string'),
    inventoryExternalIdentifier: (0, _model.attr)('string'),
    plantExternalIdentifier: (0, _model.attr)('string'),
    saleType: (0, _model.attr)('string'),
    saleDate: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    discount: (0, _model.attr)('number'),
    salesTax: (0, _model.attr)('number'),
    otherTax: (0, _model.attr)('number'),
    saleExternalIdentifier: (0, _model.attr)('string'),
    saleDetailExternalIdentifier: (0, _model.attr)('string'),
    createdBy: (0, _model.attr)('string'),
    createdDate: (0, _model.attr)('string'),
    updatedBy: (0, _model.attr)('string'),
    updatedDate: (0, _model.attr)('string'),
    operation: (0, _model.attr)('string')
  });

  _exports.default = _default;
});