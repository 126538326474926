define("herer-web/templates/side-nav-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Caog4zFO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid outlet-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row layout-row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-nav\"],[10,\"data-test-group\",\"nav-links\"],[8],[0,\"\\n      \"],[1,[28,\"outlet\",[\"sideNav\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-nav-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"root\"],[10,\"class\",\"panel\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n          \"],[1,[28,\"system-status-banners\",null,[[\"systemStatus\"],[[24,[\"systemStatus\"]]]]],false],[0,\"\\n          \"],[1,[28,\"outlet\",[\"rightContent\"],null],false],[0,\"\\n          \"],[1,[28,\"outlet\",[\"modalContent\"],null],false],[0,\"\\n          \"],[1,[28,\"outlet\",[\"drawer\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/side-nav-layout.hbs"
    }
  });

  _exports.default = _default;
});