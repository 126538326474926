define("herer-web/components/traceability-notifications", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ORDER = ['invalid_split_or_merge_tag_notification', 'package_transferred_before_reporting_sale_notification', 'package_closed_before_reporting_sale_notification'];

  var _default = Ember.Component.extend(_context.default, {
    // Passed into component
    model: null,
    hasNotifications: Ember.computed('model.@each', 'model.@each.length', function () {
      var notificationSize = this.get('model.length');
      return notificationSize > 0;
    }),
    groupedNotifications: Ember.computed('model.@each', 'model.@each.length', function () {
      var groups = this.get('model').reduce(function (groups, model) {
        var group = groups.find(function (item) {
          return item.type === model.get('type');
        });

        if (Ember.isPresent(group)) {
          group.items.push(model);
        } else {
          group = {
            type: model.get('type'),
            items: [model]
          };
          groups.push(group);
        }

        return groups;
      }, []);
      return groups.sort(function (a, b) {
        return ORDER.indexOf(a.type) - ORDER.indexOf(b.type);
      });
    })
  });

  _exports.default = _default;
});