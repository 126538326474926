define("herer-web/templates/components/packages/package-adjustment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tuRRcF9/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"data-test-value\",\"sku\"],[8],[0,\"\\n  \"],[1,[24,[\"inventoryItem\",\"sku\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"small\",true],[10,\"data-test-value\",\"product_name\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"inventoryItem\",\"product\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"inventoryItem\",\"product\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      No product.\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"location-can-use\",[\"weight-based-inventory\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[24,[\"inventoryItem\",\"weight\"]],\"input-group\"],null]],[8],[0,\"\\n      \"],[1,[28,\"weight-input\",null,[[\"weight\",\"placeholder\",\"weightUnits\",\"disabled\",\"data-test-input\",\"onUpdate\"],[[24,[\"inventoryItem\",\"quantityOnHand\"]],\"Your current quantity on hand\",[24,[\"weightUnits\"]],[24,[\"inventoryItem\",\"transferred_out\"]],\"quantity_on_hand\",[28,\"action\",[[23,0,[]],\"setQuantityValue\"],null]]]],false],[4,\"if\",[[24,[\"inventoryItem\",\"traceable_amount\"]]],null,{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n          x \"],[1,[24,[\"inventoryItem\",\"traceable_amount\",\"formatted\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"number-input\",null,[[\"number\",\"onUpdate\",\"placeholder\",\"class\",\"data-test-input\"],[[24,[\"inventoryItem\",\"quantityOnHand\",\"value\"]],[28,\"action\",[[23,0,[]],\"setQuantityOnHandValue\"],null],\"Your current quantity on hand\",\"form-control\",\"quantity_on_hand\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package-adjustment.hbs"
    }
  });

  _exports.default = _default;
});