define("herer-web/serializers/patient", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      fileAttachments: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.patients) {
        payload.patients.forEach(function (patient) {
          _this.normalizePatient(patient);
        });
      } else {
        this.normalizePatient(payload.patient);
      }

      return this._super.apply(this, arguments);
    },
    normalizePatient: function normalizePatient(patient) {
      var attrs = ['first_name', 'last_name', 'phone', 'email', 'id_number', 'caregiver_id_number'];
      attrs.forEach(function (attribute) {
        if (patient[attribute] === null) {
          patient[attribute] = '';
        }
      });
    }
  });

  _exports.default = _default;
});