define("herer-web/helpers/unescapedhtml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unescapedhtml = unescapedhtml;
  _exports.default = void 0;

  function unescapedhtml(text) {
    var escapedText = Ember.Handlebars.Utils.escapeExpression(text).replace(/(\r\n|\n|\r)/gm, '<br>');
    return Ember.String.htmlSafe(escapedText);
  }

  var _default = Ember.Helper.helper(unescapedhtml);

  _exports.default = _default;
});