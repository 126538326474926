define("herer-web/components/reorder-report/table", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/infinite-scroll", "herer-web/mixins/product-filters", "herer-web/mixins/table-sorts", "herer-web/models/paged-array", "herer-web/models/weight", "herer-web/constants", "moment"], function (_exports, _pageable, _infiniteScroll, _productFilters, _tableSorts, _pagedArray, _weight, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageable.default, _infiniteScroll.default, _productFilters.default, _tableSorts.default, {
    store: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    http: Ember.inject.service(),
    products: [],
    model: Ember.computed.alias('products'),
    onDatesChanged: null,
    interval: null,
    reportIntervals: [{
      value: 7,
      name: 'Last 7 Days'
    }, {
      value: 14,
      name: 'Last 2 Weeks'
    }, {
      value: 30,
      name: 'Last 30 Days'
    }],
    serializeProducts: function serializeProducts(products) {
      return products.map(function (product) {
        product.quantity = _weight.default.create(product.quantity);
        product.sales = _weight.default.create(product.sales);
        return product;
      });
    },
    init: function init() {
      this._super();

      var http = Ember.get(this, 'http');
      var serialize = this.serializeProducts;

      var pagedArray = _pagedArray.PagedArray.create({
        fetchRemote: function fetchRemote() {
          var _this = this;

          var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/days_sales_inventory"), Ember.get(this, 'params'));
          return http.get(url).then(function (response) {
            _this.set('total', response.data.total);

            return response.data.products;
          }).then(function (products) {
            return serialize(products);
          });
        }
      });

      this.set('products', pagedArray);
      var endDate = (0, _moment.default)().format('YYYY-MM-DD');
      var startDate = (0, _moment.default)().subtract(7, 'd').format('YYYY-MM-DD');
      pagedArray.set('endDate', endDate);
      pagedArray.set('startDate', startDate);
      pagedArray.fetchObjects();
      this.onDatesChanged(startDate, endDate);
    },
    availableSorts: Ember.computed('products.sortBy', 'products.sortDirection', function () {
      var sorts = [{
        prompt: 'Name',
        display: true,
        class: 'col-md-8 hidden-print',
        printClass: 'col-xs-7',
        field: 'name',
        direction: null
      }, {
        prompt: 'Quantity',
        display: true,
        class: 'col-md-2 hidden-print',
        printClass: 'col-xs-2',
        field: 'quantity',
        direction: null
      }];
      return this.updateSorts(sorts);
    }),
    actions: {
      onIntervalChange: function onIntervalChange(days) {
        var endDate = (0, _moment.default)(this.get('products.endDate'));
        this.set('products.startDate', endDate.subtract(days, 'd').format('YYYY-MM-DD'));
        this.onDatesChanged(this.get('products.startDate'), this.get('products.endDate'));
      }
    }
  });

  _exports.default = _default;
});