define("herer-web/utils/measure-time", ["exports", "herer-web/config/environment", "herer-web/utils/mixpanel"], function (_exports, _environment, _mixpanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.endTime = endTime;
  _exports.startTime = startTime;
  var tags = {};

  var timestamp = function timestamp() {
    return Date.now();
  };

  function logDuration(activity, duration) {
    if (!_environment.default.APP.mixpanelApiKey) {
      return console.log(activity, "".concat(duration, "ms"));
    }

    return (0, _mixpanel.mixpanelTrack)(activity, {
      duration: duration
    });
  }

  function endTime(tag) {
    var startTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (!startTime && !tags[tag]) {
      return false;
    } else if (!startTime) {
      startTime = tags[tag];
    }

    var duration = timestamp() - startTime;

    if (logDuration(tag, duration)) {
      delete tags[tag];
    }
  }

  function startTime(tag) {
    tags[tag] = timestamp();
  }
});