define("herer-web/controllers/taxes", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "ember-uuid/utils/uuid-generator", "bootbox"], function (_exports, _alertUnsavedController, _context, _uuidGenerator, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    taxItemController: Ember.inject.controller('tax-item'),
    launchDarkly: Ember.inject.service(),
    sortProperties: ['rank', 'name'],
    productTypes: [],
    showDisabled: false,
    showPreview: false,
    isCompoundTaxEnabled: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('bw-compound-tax-redux');
    }),
    addTaxItem: function addTaxItem(taxItem) {
      taxItem.set('id', _uuidGenerator.default.v4());
      var store = this.get('store');
      var schedule = store.createRecord('tax_item_schedule', {
        id: _uuidGenerator.default.v4(),
        effective_start: new Date(),
        inclusive: true
      });
      taxItem.get('tax_item_schedules').pushObject(schedule);
      this.set('taxItemController.model', taxItem);
      this.get('taxItemController').modelDidChange();
    },
    baseTaxes: Ember.computed('model.@each.isNew', 'model.@each.compounding', 'model.@each.hasEffectiveSchedule', 'showDisabled', function () {
      var model = this.get('model').filterBy('compounding', false).sortBy('hasEffectiveSchedule').reverse();
      return this.filterDisabledTaxes(model);
    }),
    exclusiveBaseTaxes: Ember.computed('baseTaxes', function () {
      return this.get('baseTaxes').filterBy('effective_schedule.inclusive', false);
    }),
    compoundTaxes: Ember.computed('model.@each.isNew', 'model.@each.compounding', 'model.@each.hasEffectiveSchedule', 'showDisabled', function () {
      var model = this.get('model').filterBy('compounding', true).sortBy('rank');
      return this.filterDisabledTaxes(model);
    }),
    shouldAllowPreview: Ember.computed('exclusiveBaseTaxes', 'compoundTaxes', 'launchDarkly', function () {
      var hasPersonGroups = this.get('launchDarkly').can('bw-person-group');
      var baseTaxes = this.get('exclusiveBaseTaxes');
      var compoundTaxes = this.get('compoundTaxes');
      return hasPersonGroups && (Ember.isPresent(baseTaxes) || Ember.isPresent(compoundTaxes));
    }),
    showWarning: function showWarning() {
      return new Ember.RSVP.Promise(function (resolve) {
        var message = "<strong>Wait!</strong> Adding a tax adds a new, completely separate tax\n        from your existing tax items. If you only want to update the rate or inclusivity of an\n        existing tax, edit the tax and click <strong>Add Schedule</strong>.";

        _bootbox.default.dialog({
          message: message,
          buttons: {
            cancel: {
              label: 'Cancel',
              className: 'btn-default'
            },
            help: {
              label: 'Help me!',
              className: 'btn-primary',
              callback: function callback() {
                window.location = 'https://support.dutchie.com/hc/en-us/articles/12886574385939-Set-up-Taxes';
              }
            },
            success: {
              label: 'Continue',
              className: 'btn-success',
              callback: resolve
            }
          }
        });
      });
    },
    filterDisabledTaxes: function filterDisabledTaxes(model) {
      if (this.get('showDisabled')) {
        return model;
      }

      return model.filterBy('hasEffectiveSchedule', true);
    },
    actions: {
      updateCompoundingRanks: function updateCompoundingRanks() {
        this.get('model').forEach(function (model, idx) {
          model.set('rank', idx);
          model.save();
        });
      },
      beforeCreate: function beforeCreate(create) {
        this.showWarning().then(create);
      },
      editTaxItem: function editTaxItem(taxItem) {
        this.set('taxItemController.model', taxItem);
        this.get('taxItemController').modelDidChange();
      },
      cancelTaxEdit: function cancelTaxEdit() {
        this.get('taxItemController').send('cancel');
      },
      saveTaxItem: function saveTaxItem(success, reject) {
        this.get('taxItemController').send('confirmSave', success, reject);
      },
      addTaxItem: function addTaxItem(taxItem) {
        this.addTaxItem(taxItem);
      },
      showDisabled: function showDisabled() {
        this.set('showDisabled', true);
      },
      hideDisabled: function hideDisabled() {
        this.set('showDisabled', false);
      },
      showPreview: function showPreview() {
        this.set('showPreview', true);
      },
      hidePreview: function hidePreview() {
        this.set('showPreview', false);
      },
      showConfirmationAlert: function showConfirmationAlert(callbacks) {
        this.send("confirmTransition", callbacks);
      }
    }
  });

  _exports.default = _default;
});