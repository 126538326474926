define("herer-web/components/typeahead-input", ["exports", "ember-inflector", "herer-web/constants"], function (_exports, _emberInflector, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MODELS_WITHOUT_CACHE = Object.freeze(['vendor']);
  var AutocompleteLimit = 30;

  var _default = Ember.TextField.extend({
    http: Ember.inject.service(),
    bloodhound: null,
    source: [],
    sourceNames: Ember.computed.mapBy('source', 'name'),
    modelName: null,
    pluralModelName: Ember.computed('modelName', function () {
      return (0, _emberInflector.pluralize)(this.get('modelName'));
    }),
    init: function init() {
      this.initBloodhound();

      this._super.apply(this, arguments);
    },
    initBloodhound: function initBloodhound() {
      var modelName = this.get('pluralModelName');
      var remote;

      if (Ember.isPresent(modelName)) {
        var headers = Ember.get(this, 'http').getHeaders();
        remote = {
          url: "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(modelName, "?limit=").concat(AutocompleteLimit, "&by_name=%QUERY%"),
          transform: function transform(response) {
            return response[modelName].map(function (item) {
              return item.name;
            });
          },
          wildcard: '%QUERY%',
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY%', query);
            settings.headers = headers;
            return settings;
          },
          cache: !MODELS_WITHOUT_CACHE.includes(this.modelName)
        };
      }

      this.set('bloodhound', new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.get('sourceNames'),
        remote: remote
      }));
    },
    didInsertElement: function didInsertElement() {
      this._super();

      this.$().typeahead({
        hint: true,
        highlight: true,
        minLength: 1
      }, {
        name: 'source',
        source: this.get('bloodhound')
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.loadData();
    },
    loadData: function loadData() {
      var bloodhound = this.get('bloodhound');
      bloodhound.clear();
      bloodhound.local = this.get('sourceNames');
      bloodhound.initialize(true);
    }
  });

  _exports.default = _default;
});