define("herer-web/components/quicksight-report-menu-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // This prevents Ember from wrapping the component with a DIV tag
    // which prevents the menu from showing in our menubar.
    tagName: '',
    launchDarkly: Ember.inject.service()
  });

  _exports.default = _default;
});