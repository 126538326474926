define("herer-web/components/packages/destroy-scheduler", ["exports", "herer-web/components/base-modal", "herer-web/constants", "lodash/find", "herer-web/utils/validation", "yup"], function (_exports, _baseModal, _constants, _find, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var packageDestroyReasonsAll = _constants.default.packageDestroyReasonsAll,
      packageDestroyReasonsLeaf = _constants.default.packageDestroyReasonsLeaf;

  var _default = _baseModal.default.extend({
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    isLeaf: Ember.computed(function () {
      return this.get('featureManager').can('leaf_data');
    }),
    modalClassName: '.destroy-scheduler-modal',
    note: null,
    reason: null,
    reasons: Ember.computed('launchDarkly.isReady', function () {
      if (this.get('isLeaf') && this.get('launchDarkly').can('enable-leaf-1-37-5-changes', true)) {
        return packageDestroyReasonsLeaf;
      } else {
        return packageDestroyReasonsAll;
      }
    }),
    validations: (0, _validation.validations)({
      reason: (0, _yup.string)().nullable().required(),
      note: (0, _yup.string)().nullable().required()
    }),
    reasonName: Ember.computed('reason', function () {
      var _this = this;

      var destroyReason = (0, _find.default)(this.get('reasons'), function (destroyReason) {
        return destroyReason.value === _this.get('reason');
      });

      if (Ember.isPresent(destroyReason)) {
        return destroyReason.name;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        this.resetProperties();
        modal.modal({
          backdrop: 'static'
        });
      }
    },
    resetProperties: function resetProperties() {
      this.set('note', null);
      this.set('reason', null);
      this.set('validations.fieldErrors.note', null);
      this.set('validations.fieldErrors.reason', null);
    },
    actions: {
      verifyAndConfirm: function verifyAndConfirm() {
        var _this2 = this;

        this.get("validations").validate().then(function () {
          _this2.set('isConfirming', true);
        }).catch(function () {});
      },
      unconfirm: function unconfirm() {
        this.set('isConfirming', false);
      },
      scheduleDestroy: function scheduleDestroy() {
        this.onConfirm(this.get('package'), {
          reason: this.get('reason'),
          note: this.get('note')
        });
      }
    }
  });

  _exports.default = _default;
});