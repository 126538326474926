define("herer-web/templates/qs-reports/profit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOvrzI0F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Product Profitability\",\"The Product Profitability shows you a comparison of your sales alongside the wholesale cost so you can decide what to stock and how to price Products.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886960819603-Sales-Activity-Profitability-Make-Better-Business-Decisions-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"10\",\"profit_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"ProfitReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"ProfitReport\",\"profit_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/profit.hbs"
    }
  });

  _exports.default = _default;
});