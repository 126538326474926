define("herer-web/mixins/clean-body-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Route mixin used to remove all classes from the body element on route
   * change.
   */
  var CleanBodyClasses = Ember.Mixin.create({
    activate: function activate() {
      this._super();

      document.body.className = '';
    }
  });
  var _default = CleanBodyClasses;
  _exports.default = _default;
});