define("herer-web/utils/get-active-state-credential", ["exports", "herer-web/utils/sort-credentials"], function (_exports, _sortCredentials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getActiveStateCredential = function getActiveStateCredential(store) {
    return store.query('state-credential', {
      by_active: true
    }).then(function (credentials) {
      if (Ember.isPresent(credentials)) {
        var sortedCredentials = credentials.toArray().sort(_sortCredentials.default);
        return sortedCredentials.get('firstObject');
      }

      return null;
    });
  };

  var _default = getActiveStateCredential;
  _exports.default = _default;
});