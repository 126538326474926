define("herer-web/components/nav-state-link", ["exports", "herer-web/components/nav-link", "herer-web/templates/components/nav-link"], function (_exports, _navLink, _navLink2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Override of NavLink so we can call attention to ourselves
  // if the location has resolvable problems.
  var _default = _navLink.default.extend({
    layout: _navLink2.default,
    stateLinkStatusUpdater: Ember.inject.service(),
    hasErrors: Ember.computed('stateLinkStatusUpdater.resolvableReportingErrors', function () {
      var numErrors = this.get('stateLinkStatusUpdater.resolvableReportingErrors');
      return numErrors > 0;
    })
  });

  _exports.default = _default;
});