define("herer-web/templates/components/packages/package/package-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mxRAXTih",
    "block": "{\"symbols\":[\"drawer\"],\"statements\":[[4,\"packages/package/package-drawer\",null,[[\"package\",\"showSlideOutDrawer\",\"onAttemptedToHideDrawer\",\"afterHideDrawer\",\"activeTab\"],[[24,[\"package\"]],[24,[\"showSlideOutDrawer\"]],[28,\"action\",[[23,0,[]],\"attemptedToHideDrawer\"],null],[28,\"action\",[[23,0,[]],\"afterHideDrawer\"],null],\"history\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"panel-body table history-events\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content container-fluid\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row filters\"],[10,\"data-test-container\",\"package_filters\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"fluid-container col-md-10\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[10,\"class\",\"cap-header\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-filter\"],[8],[9],[0,\"\\n            Filters\\n          \"],[9],[0,\"\\n          \"],[1,[28,\"table-filters\",null,[[\"availableFilters\",\"filterParamsChanged\",\"filterValuesChanged\",\"notifier\"],[[24,[\"availableFilters\"]],[28,\"action\",[[23,0,[]],\"filterParamsChanged\"],null],[28,\"action\",[[23,0,[]],\"filterValuesChanged\"],null],[23,0,[]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"infinite-scroll\",null,[[\"loadMoreAction\"],[[28,\"action\",[[23,0,[]],\"loadMore\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"packages/package/history-events/events-table\",null,[[\"historyEvents\",\"isLoading\",\"isLoadingMore\"],[[24,[\"model\"]],[24,[\"isLoading\"]],[24,[\"isLoadingMore\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package/package-history.hbs"
    }
  });

  _exports.default = _default;
});