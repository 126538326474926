define("herer-web/components/product-inventory-list", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-inventory-list'],
    store: Ember.inject.service(),
    auditResults: null,
    product: null,
    hasLoaded: false,
    reloadInventory: false,
    excludeInventoryIds: [],
    showProductIfNoInventory: true,
    hasInventory: Ember.computed.gt('items.length', 0),
    sortBy: ['quantity.sortValue:desc'],
    sortedItems: Ember.computed.sort('items', 'sortBy'),
    items: Ember.computed('filteredItems', 'excludeInventoryIds.[]', function () {
      var excludeInventory = this.get('excludeInventoryIds') || [];
      return this.get('filteredItems').filter(function (item) {
        return !excludeInventory.includes(item.id);
      });
    }),
    filteredItems: Ember.computed('productInventoryItems', function () {
      return this.get('productInventoryItems').filter(function (item) {
        return item.get('status') !== _constants.default.inventoryItemStatus.archived && !item.get('transferred_out');
      });
    }),
    productInventoryItems: Ember.computed('inventoryItems', 'product.id', function () {
      if (this.get('inventoryItems')) {
        return this.get('inventoryItems').filterBy('product.id', this.get('product.id'));
      }

      return [];
    }),
    adjustedItems: Ember.computed('auditResults', 'sortedItems', function () {
      var adjustments = this.get('auditResults');
      var inventoryItems = this.get('sortedItems').map(function (item) {
        var counted = null;

        if (Ember.isPresent(adjustments)) {
          var adjustment = adjustments.findBy('inventory_item.id', item.id);

          if (Ember.isPresent(adjustment)) {
            counted = adjustment.get('counted');
          }
        }

        return {
          inventory_item: item,
          counted: counted
        };
      });
      return inventoryItems.filter(Boolean);
    })
  });

  _exports.default = _default;
});