define("herer-web/controllers/qs-reports/sales-by", ["exports", "herer-web/controllers/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({
    title: Ember.computed('report', 'column', 'groupBy', function () {
      var report = this.get('report');
      var column = this.get('column');
      var groupBy = this.get('groupBy');
      var title = 'Report Builder Classic';

      if (report === 'discount_plan' && column === 'account' && groupBy === 'employee') {
        title = 'Discounts by Budtender';
      } else if (report === 'employee' && column === 'brand' && Ember.isEmpty(groupBy)) {
        title = 'Budtender Sales by Brand';
      } else if (report === 'employee' && column === 'product_type' && Ember.isEmpty(groupBy)) {
        title = 'Budtender Sales by Product Type';
      } else if (report === 'employee' && column === 'weekday' && Ember.isEmpty(groupBy)) {
        title = 'Budtender Daily Performance';
      } else if (report === 'patient' && Ember.isEmpty(column) && groupBy === 'brand') {
        title = 'Medical Sales by Brand';
      } else if (report === 'patient' && Ember.isEmpty(column) && groupBy === 'product_type') {
        title = 'Medical Sales by Product Type';
      } else if (report === 'patient' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Medical Sales';
      } else if (report === 'customer' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Recreational Sales';
      } else if (report === 'brand' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Best-selling Brands';
      } else if (report === 'product_type' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Best-selling Product Types';
      } else if (report === 'discount_plan' && column === 'account' && Ember.isEmpty(groupBy)) {
        title = 'Best Performing Discounts';
      } else if (report === 'strain' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Best-selling Strains';
      } else if (report === 'concentrate_type' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Best-selling Concentrate Types';
      } else if (report === 'product' && Ember.isEmpty(column) && Ember.isEmpty(groupBy)) {
        title = 'Best-selling Products';
      }

      return title;
    }),
    reportExportFilename: Ember.computed('report', function () {
      return "".concat(this.get('title'), ".csv").underscore();
    })
  });

  _exports.default = _default;
});