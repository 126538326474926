define("herer-web/components/payment-method-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    paymentMethod: null,
    canUpdateActive: Ember.computed('paymentMethod.paymentType', function () {
      var permissionsManager = this.get('permissionsManager');
      return this.get('paymentMethod.paymentType') !== 0 && permissionsManager.verifyForced('update_payment_methods');
    }),
    actions: {
      updateActive: function updateActive(active) {
        var _this = this;

        var paymentMethod = this.get('paymentMethod');
        var paymentMethodName = Ember.Handlebars.Utils.escapeExpression(paymentMethod.get('name'));
        paymentMethod.set('active', active);
        paymentMethod.save().then(function () {
          var action = active ? 'enabled' : 'disabled';

          _this.get('toast').success("Successfully ".concat(action, " ").concat(paymentMethodName, "."));
        }).catch(function () {
          _this.get('toast').error("There was a problem saving ".concat(paymentMethodName, "."));
        });
      }
    }
  });

  _exports.default = _default;
});