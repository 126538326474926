define("herer-web/components/qs-report", ["exports", "herer-web/mixins/context", "herer-web/constants", "amazon-quicksight-embedding-sdk"], function (_exports, _context, _constants, _amazonQuicksightEmbeddingSdk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    http: Ember.inject.service(),
    reportName: null,
    expirationTimer: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Record the start time here


      this.displayReport();
    },
    displayReport: function displayReport() {
      var _this = this;

      var containerDiv = document.getElementById('dashboardContainer');
      containerDiv.innerHTML = '';
      var loadingMessage = document.getElementById('loadingMessage');
      Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/account_reports/").concat(this.reportName, "/embeddable_url")).then(function (response) {
        loadingMessage.style.display = 'none';
        var data = response.data;
        _this.expirationTimer = Ember.run.later(_this, _this.displayReport, new Date(data.account_report.expiration) - new Date());
        var options = {
          url: data.account_report.embeddable_url + '&printEnabled=false',
          container: containerDiv,
          parameters: {
            country: 'United States'
          },
          scrolling: 'yes',
          height: 'AutoFit',
          loadingHeight: '700px'
        };

        var dashboard = _amazonQuicksightEmbeddingSdk.default.embedDashboard(options);

        dashboard.on('SHOW_MODAL_EVENT', function () {
          window.scrollTo({
            top: 0
          });
        });
        var div = document.createElement('div');
        div.innerHTML = '&nbsp;';
        containerDiv.prepend(div);
      }).catch(function (response) {
        console.error('Error Loading the URL from back office. Status: ' + response.status);
      });
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.expirationTimer);
    }
  });

  _exports.default = _default;
});