define("herer-web/routes/imports", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    model: function model() {
      return this.get('store').findAll('personGroup');
    },
    setupController: function setupController(controller, model) {
      this._super(controller);

      controller.set('personGroups', model);
    }
  });

  _exports.default = _default;
});