define("herer-web/templates/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nIrYz9KM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-section\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"is_deleted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"Deleted at: \"],[1,[28,\"format-date\",[[24,[\"model\",\"deleted_at\"]]],null],false],[9],[0,\"\\n        \"],[7,\"hr\",true],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"model\",\"hasAccessibleLocations\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-8 no-accessible-locations-warning-wrapper\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-warning\"],[8],[9],[0,\"\\n           This user has no Accessible Locations assigned.\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[5,\"user-form\",[],[[\"@user\",\"@roles\",\"@proxiedLocations\",\"@checkAllLocations\",\"@disableEmployeeAPIKeyInputs\",\"@showEmployeeAPIKey\"],[[22,\"model\"],[22,\"proxiedRoles\"],[22,\"proxiedLocations\"],[22,\"checkAllLocations\"],[22,\"disableInputs\"],[22,\"showEmployeeAPIKey\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/user.hbs"
    }
  });

  _exports.default = _default;
});