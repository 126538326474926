define("herer-web/helpers/update-responsive-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function updateResponsiveTable() {
    return function () {
      var _window$ZurbTables;

      return (_window$ZurbTables = window.ZurbTables) === null || _window$ZurbTables === void 0 ? void 0 : _window$ZurbTables.updateTables();
    };
  });

  _exports.default = _default;
});