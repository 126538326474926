define("herer-web/models/pin-debit-setting", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    enabled: (0, _model.attr)('boolean'),
    allow_tipping: (0, _model.attr)('boolean'),
    mock_strategy: (0, _model.attr)('string'),
    merchant_id: (0, _model.attr)('string'),
    fee_ceiling: (0, _model.attr)('number'),
    receipt_line_item_name: (0, _model.attr)('string'),
    timeout_seconds: (0, _model.attr)('number'),
    tip_percentage_tier_1: (0, _model.attr)('number'),
    tip_percentage_tier_2: (0, _model.attr)('number'),
    tip_percentage_tier_3: (0, _model.attr)('number'),
    tip_percentage_tier_4: (0, _model.attr)('number')
  });

  _exports.default = _default;
});