define("herer-web/initializers/permissions-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'permissionsManager', 'service:permissions-manager');
    application.inject('controller', 'permissionsManager', 'service:permissions-manager');
    application.inject('component', 'permissionsManager', 'service:permissions-manager');
  }

  var _default = {
    name: 'permissions-manager',
    after: 'auth-manager',
    initialize: initialize
  };
  _exports.default = _default;
});