define("herer-web/controllers/reports/budtender-performance", ["exports", "herer-web/controllers/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({
    reportExportFilename: 'budtender_performance.csv',
    report: 'employee'
  });

  _exports.default = _default;
});