define("herer-web/templates/components/invalid-split-or-merge-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uMykNu0i",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[0,\"\\n          Splits or Merges\\n        \"],[9],[0,\"\\n        on these Packages failed to report\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://support.dutchie.com/hc/en-us/articles/12886821249043-How-to-Fix-Failed-Splits-and-Merges\"],[10,\"target\",\"_blank\"],[10,\"data-test-link\",\"help\"],[8],[0,\"\\n        Learn how to fix it…\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row header-row table-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"\\n          Failed Destination Package\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-4 hidden-sm hidden-xs\"],[8],[0,\"\\n          Source Package(s)\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-5 hidden-sm hidden-xs\"],[8],[0,\"\\n          New Package ID\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notification\",\"items\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"invalid-split-or-merge-traceability-notification\",null,[[\"notification\",\"data-test-item\"],[[23,1,[]],\"notification\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/invalid-split-or-merge-notification.hbs"
    }
  });

  _exports.default = _default;
});