define("herer-web/templates/components/weekly-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8uQ78c9F",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"date-picker\",null,[[\"startDate\",\"endDate\",\"disabledDates\",\"onStartDateChange\",\"onEndDateChange\",\"endMinDate\",\"startMaxDate\",\"endMaxDate\",\"showAdditionalButtons\",\"startDateTitle\",\"endDateTitle\"],[[24,[\"startWeek\"]],[24,[\"endWeek\"]],[24,[\"disabledDates\"]],[24,[\"onStartDateChange\"]],[24,[\"onEndDateChange\"]],[24,[\"endMinDate\"]],[24,[\"startMaxDate\"]],[24,[\"endMaxDate\"]],false,\"Start Week\",\"End Week\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/weekly-date-picker.hbs"
    }
  });

  _exports.default = _default;
});