define("herer-web/utils/sell-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sellPricePrefix = sellPricePrefix;
  _exports.sellPriceProperty = sellPriceProperty;

  function sellPricePrefix(sellPriceType) {
    var productPropertyPrefix = sellPriceType;

    if (sellPriceType === 'standard') {
      return '';
    }

    return productPropertyPrefix + '_';
  }

  function sellPriceProperty(property, sellPriceType) {
    var productPropertyPrefix = sellPriceType;

    if (sellPriceType === 'standard') {
      return property;
    }

    return "".concat(productPropertyPrefix, "_").concat(property);
  }
});