define("herer-web/mixins/resize", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResizeMixin = Ember.Mixin.create({
    resizeDelay: 250,
    resizing: false,
    onResizeStart: function onResizeStart() {},
    onResizeEnd: function onResizeEnd() {},
    onResize: function onResize() {},
    debounceResizeEnd: function debounceResizeEnd() {
      if (this.isDestroyed) {
        return;
      }

      this.set('resizing', false);
      return this.onResizeEnd();
    },
    resizeHandler: Ember.computed(function () {
      return _jquery.default.proxy(this.handleWindowResize, this);
    }),
    handleWindowResize: function handleWindowResize(event) {
      if (!this.get('resizing')) {
        this.set('resizing', true);

        if (typeof this.onResizeStart === "function") {
          this.onResizeStart(event);
        }
      }

      if (this.onResize !== null) {
        Ember.run.throttle(this, this.onResize, event, this.get("resizeDelay"));
      }

      if (this.onResizeEnd !== null) {
        return Ember.run.debounce(this, this.debounceResizeEnd, event, this.get("resizeDelay"));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();

      return (0, _jquery.default)(window).on('resize.' + Ember.guidFor(this), this.get("resizeHandler"));
    },
    willDestroy: function willDestroy() {
      (0, _jquery.default)(window).off('resize.' + Ember.guidFor(this), this.get("resizeHandler"));
      return this._super();
    }
  });
  var _default = ResizeMixin;
  _exports.default = _default;
});