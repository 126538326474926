define("herer-web/routes/monthly-tax-report", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/constants", "moment"], function (_exports, _authenticatedSideNav, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    http: Ember.inject.service(),
    sideNavTemplateName: 'reports-nav',
    permissionKey: 'view_tax_reports',
    model: function model(params) {
      return Ember.RSVP.hash({
        taxes: this.getTaxReport(params),
        stateCredentials: this.get('store').findAll('state-credential')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.taxes);

      controller.set('stateCredential', model.stateCredentials.get('firstObject'));
    },
    getTaxReport: function getTaxReport(params) {
      var _this = this;

      return this.get('store').findAll('monthly_tax_submission').then(function (monthly_tax_submissions) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var http = Ember.get(_this, 'http');
          var year = params.year;
          var month = parseInt(params.month);

          var timezone = _this.get('authManager.currentLocation.timezone');

          var report_params = {
            start_date: (0, _moment.default)([year, month - 1]).startOf('month').tz(timezone, true).toISOString(),
            end_date: (0, _moment.default)([year, month - 1]).endOf('month').tz(timezone, true).toISOString()
          };
          var monthly_tax_submission = monthly_tax_submissions.toArray().find(function (tax_submission) {
            return tax_submission.get('year') === year && tax_submission.get('month') === month;
          });
          var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/tax"), report_params);
          http.get(url).then(function (response) {
            var data = response.data;
            data.report.submission = monthly_tax_submission;
            resolve(data.report);
          }).catch(function (response) {
            reject(new Error("".concat(response.status, ": ").concat(response.data.error)));
          });
        });
      });
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    }
  });

  _exports.default = _default;
});