define("herer-web/templates/components/untracked-migration-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Zh9qwzA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row table-row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n    \"],[1,[28,\"format-date\",[[24,[\"migration\",\"start_time\"]]],[[\"shortDateTime\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n    \"],[1,[24,[\"migration\",\"location\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n    \"],[1,[22,\"licenseTypeName\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n    \"],[1,[24,[\"migration\",\"run_type\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[8],[0,\"\\n    \"],[1,[28,\"format-date\",[[24,[\"migration\",\"task\",\"completed_at\"]]],[[\"shortDateTime\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-1\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"migration\",\"queued\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-sm\"],[3,\"action\",[[23,0,[]],\"cancel\",[24,[\"migration\",\"task\",\"uuid\"]]]],[8],[0,\"\\n        Cancel\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"migration\",\"processing\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-refresh fa-spin text-primary\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[24,[\"migration\",\"status\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/untracked-migration-row.hbs"
    }
  });

  _exports.default = _default;
});