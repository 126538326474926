define("herer-web/models/package", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)('number'),
    lotNumber: (0, _model.attr)('string'),
    lastStatusUpdateAt: (0, _model.attr)('date'),
    quantity: (0, _model.attr)('weight'),
    inventoryItems: (0, _model.hasMany)('inventory-item', {
      async: false
    }),
    products: (0, _model.hasMany)('product', {
      async: false
    }),
    packageHistoryEvents: (0, _model.hasMany)('package-history-event', {
      async: true
    }),
    statusName: Ember.computed('status', function () {
      var status = this.get('status');

      if (!Ember.isPresent(status)) {
        return;
      }

      return _constants.default.packageStatuses[status].name;
    }),
    costPerUnit: Ember.computed('inventoryItems.firstObject', function () {
      var CPU = this.get('inventoryItems.firstObject.purchase_price');

      if (!Ember.isPresent(CPU)) {
        return 0;
      }

      return CPU;
    }),
    isStatusActive: Ember.computed.equal('status', _constants.default.packageStatus.active),
    isStatusOnHold: Ember.computed.equal('status', _constants.default.packageStatus.on_hold),
    isStatusQuarantined: Ember.computed.equal('status', _constants.default.packageStatus.quarantined),
    isStatusScheduledForDestruction: Ember.computed.equal('status', _constants.default.packageStatus.destruction_scheduled),
    isStatusDestroyed: Ember.computed.equal('status', _constants.default.packageStatus.destroyed),
    isStatusClosed: Ember.computed.equal('status', _constants.default.packageStatus.closed)
  });

  _exports.default = _default;
});