define("herer-web/mixins/titan-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    titan: Ember.inject.service(),
    http: Ember.inject.service(),
    titanEmail: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchTitanEmail();
    },
    fetchTitanEmail: function fetchTitanEmail() {
      var _this = this;

      var titan = this.get('titan');
      titan.ping().then(function (result) {
        if (Ember.isPresent(result)) {
          titan.email().then(function (email) {
            _this.get('http').setHeaders({
              'X-GB-Titan-Email': email
            });

            _this.set('titanEmail', email);
          }).catch(function () {});
        }
      }).catch(function () {});
    }
  });

  _exports.default = _default;
});