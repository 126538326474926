define("herer-web/serializers/tiered-pricing-model", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      pricingTiers: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});