define("herer-web/components/text-counter-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    remainingCharsCount: Ember.computed('value', 'max', function () {
      var value = this.get('value');
      var max = this.get('max');

      if (value) {
        return Number(max) - value.length;
      } else {
        return max;
      }
    }),
    charsCount: Ember.computed("value", function () {
      return this.get('value') ? this.get('value').length : 0;
    }),
    maxLength: Ember.computed('stopAtMax', 'max', function () {
      return this.get('stopAtMax') ? Number(this.get('max')) : null;
    }),
    counterErrorClass: Ember.computed('displayError', function () {
      return this.get('displayError') ? 'error-message' : '';
    }),
    displayError: Ember.computed('remainingCharsCount', function () {
      return this.get('remainingCharsCount') < 0;
    })
  });

  _exports.default = _default;
});