define("herer-web/templates/components/slide-out-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5L380V4t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"data-test-group\",\"slide_out_drawer\"],[10,\"class\",\"table-details-panel\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"backdrop\"],[3,\"action\",[[23,0,[]],\"attemptedToHideDrawer\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"placesEnabled\"]],\"drawer-content-wide\",\"drawer-content\"],null]]]],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"attemptedToHideDrawer\",\"showDrawer\",\"hideDrawer\"],[[28,\"action\",[[23,0,[]],\"attemptedToHideDrawer\"],null],[28,\"action\",[[23,0,[]],\"showDrawer\"],null],[28,\"action\",[[23,0,[]],\"hideDrawer\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/slide-out-drawer.hbs"
    }
  });

  _exports.default = _default;
});