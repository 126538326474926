define("herer-web/models/menu-group-filter", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/utils/validation", "yup"], function (_exports, _model, _emberDataModelFragments, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    validations: (0, _validation.validations)({
      key: (0, _yup.string)().nullable().required()
    }),
    key: (0, _model.attr)('string'),
    value: (0, _model.attr)(),
    values: (0, _model.attr)()
  });

  _exports.default = _default;
});