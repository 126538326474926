define("herer-web/services/push-manager", ["exports", "pusher-js", "herer-web/config/environment"], function (_exports, _pusherJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    locationId: null,
    pusher: null,
    channel: null,
    init: function init() {
      this._super();

      this.set('pusher', new _pusherJs.default(_environment.default.APP.pusherApiKey));
    },
    subscribe: function subscribe(locationId) {
      if (this.get('locationId') === locationId) {
        return false;
      }

      this.set('locationId', locationId);
      var channel = this.get('pusher').subscribe("company.".concat(locationId));
      this.set('channel', channel);
      return true;
    },
    unsubscribe: function unsubscribe(locationId) {
      this.get('pusher').unsubscribe("company.".concat(locationId));
      this.set('channel', null);
    },
    bind: function bind(event, callback) {
      var channel = this.get('channel');

      if (Ember.isPresent(channel)) {
        channel.bind(event, callback);
      }
    },
    unbind: function unbind(event, callback) {
      var channel = this.get('channel');

      if (Ember.isPresent(channel)) {
        channel.unbind(event, callback);
      }
    }
  });

  _exports.default = _default;
});