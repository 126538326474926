define("herer-web/routes/loyalty-program", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'marketing-nav',
    mixpanelEvent: 'LoyaltyProgramList.show',
    permissionKey: 'view_loyalty_rewards',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        loyaltyProgram: store.findAll('loyalty-program').then(function (loyaltyPrograms) {
          var loyaltyProgram = loyaltyPrograms.get('firstObject');

          if (Ember.isEmpty(loyaltyProgram)) {
            loyaltyProgram = store.createRecord('loyalty-program');
          }

          return loyaltyProgram;
        }),
        loyaltyRewards: store.findAll('discount-plan'),
        productTypes: store.findAll('productType'),
        tags: store.findAll('tag'),
        roles: store.findAll('role'),
        brands: store.findAllInBatches('brand')
      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('loyaltyProgram', hash.loyaltyProgram);
      controller.set('loyaltyRewards', hash.loyaltyRewards);
      controller.set('allTags', hash.tags);
      controller.set('productTypes', hash.productTypes.sortBy('name'));
      controller.set('roles', hash.roles);
      controller.set('brands', hash.brands);

      if (hash.loyaltyProgram.get('isNew')) {
        controller.set('editingLoyaltyProgram', true);
      }
    }
  });

  _exports.default = _default;
});