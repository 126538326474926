define("herer-web/templates/components/dutchie-cashless-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHLDoTx8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dutchie-cashless-banner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Fully integrated payments from Dutchie\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"copy\"],[8],[0,\"Reduce errors and serve more customers with safe, secure, and fully integrated debit payments.\"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"class\",\"cta\"],[10,\"href\",\"https://business.dutchie.com/in-store-debit\"],[10,\"target\",\"_blank\"],[8],[0,\"Learn more\"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"close-btn\"],[3,\"action\",[[23,0,[]],\"closeBanner\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/dutchie-cashless-banner.hbs"
    }
  });

  _exports.default = _default;
});