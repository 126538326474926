define("herer-web/components/task-row", ["exports", "herer-web/mixins/taskable", "herer-web/helpers/format-date", "lodash/endsWith", "herer-web/utils/humanize"], function (_exports, _taskable, _formatDate, _endsWith, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_taskable.default, {
    downloader: Ember.inject.service(),
    classNames: ['row', 'table-row'],
    classNameBindings: ['task.success:bg-success', 'task.error:bg-danger'],
    timer: null,
    task: null,
    accountHasMoreThanOneLocation: false,
    formattedOptions: Ember.computed('task.options', function () {
      var options = this.get('task.options'); // We use 'Report' internally to pass which report
      // to run from BackgroundTask

      var optionsToHideFromDisplay = ['filters', 'report'];
      optionsToHideFromDisplay.forEach(function (badKey) {
        delete options[badKey];
      });
      var parts = [];

      for (var key in options) {
        var value = options[key];

        if ((0, _endsWith.default)(key, 'date')) {
          value = (0, _formatDate.formatDate)(value, {
            shortDateTime: true
          });
        }

        if (key === 'filters') {
          value = Object.keys(options[key]).map(_humanize.humanize).join(', ');
        }

        parts.push("".concat((0, _humanize.humanize)(key), ": ").concat(value));
      }

      return parts.join(' ');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var task = this.get('task');

      if (task.get('queued') || task.get('processing')) {
        this.pollTask();
      }
    },
    actions: {
      download: function download() {
        this.get('downloader').downloadFile(this.get('task.result.url'));
        this.get('taskManager').claim(this.get('task.id'));
      },
      email: function email() {
        this.get('taskManager').email(this.get('task.id'));
      },
      retry: function retry() {
        var _this = this;

        this.get('taskManager').retry(this.get('task.id')).then(function (task) {
          _this.set('task', task);

          _this.pollTask();
        });
      }
    }
  });

  _exports.default = _default;
});