define("herer-web/serializers/user", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      var record = snapshot.record;

      if (!Ember.isEmpty(record.get('password'))) {
        json.password = record.get('password');
      }

      if (!Ember.isEmpty(record.get('current_password'))) {
        json.current_password = record.get('current_password');
      }

      json.role_id = record.get('role_id');
      return json;
    }
  });

  _exports.default = _default;
});