define("herer-web/serializers/state-credential", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      var record = snapshot.record;

      if (!Ember.isEmpty(record.get('password'))) {
        json.password = record.get('password');
      }

      if (!Ember.isEmpty(record.get('api_key'))) {
        json.api_key = record.get('api_key');
      }

      return json;
    }
  });

  _exports.default = _default;
});