define("herer-web/templates/components/protected-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v1IK0wQQ",
    "block": "{\"symbols\":[\"@name\",\"@protectedValue\",\"@autocomplete\",\"@disabled\",\"@onChange\",\"@onFocusOut\"],\"statements\":[[7,\"input\",false],[12,\"name\",[23,1,[]]],[12,\"value\",[23,2,[]]],[12,\"autocomplete\",[23,3,[]]],[12,\"disabled\",[23,4,[]]],[12,\"placeholder\",\"•••••\"],[12,\"class\",\"form-control\"],[12,\"type\",\"password\"],[3,\"on\",[\"input\",[23,5,[]]]],[3,\"on\",[\"focusout\",[23,6,[]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/protected-field.hbs"
    }
  });

  _exports.default = _default;
});