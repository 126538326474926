define("herer-web/controllers/entries", ["exports", "herer-web/mixins/context", "herer-web/mixins/pin-debit-settings", "ember-data"], function (_exports, _context, _pinDebitSettings, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _pinDebitSettings.default, {
    launchDarkly: Ember.inject.service(),
    queryParams: ['shiftId', 'paymentType'],
    shiftId: null,
    paymentType: null,
    store: Ember.inject.service(),
    empty: Ember.computed.empty('model'),
    pinDebitEnabled: Ember.computed(function () {
      var store = this.get('store');
      return _emberData.default.PromiseObject.create({
        promise: this.findPinDebitSettings(store).then(function (res) {
          return res.get('enabled');
        })
      });
    })
  });

  _exports.default = _default;
});