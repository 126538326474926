define("herer-web/controllers/packages/package/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    package: null,
    users: null,
    filters: null,
    actions: {
      exit: function exit() {
        this.transitionToRoute('packages');
      }
    }
  });

  _exports.default = _default;
});