define("herer-web/components/entry-row", ["exports", "herer-web/mixins/context", "big.js", "ember-inflector"], function (_exports, _context, _big, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    launchDarkly: Ember.inject.service(),
    tagName: 'tr',
    objectRoute: Ember.computed(function () {
      return (0, _emberInflector.singularize)(this.get('entry.link_object_type'));
    }),
    transactionTypeClass: Ember.computed('entry.transaction_type', function () {
      return this.get('entry.transaction_type').toLowerCase();
    }),
    total: Ember.computed('entry.total', function () {
      return new _big.default(this.get('entry.total') || 0);
    }),
    tipTotal: Ember.computed('entry.tip_total', function () {
      var tipTotal = this.get('entry.tip_total') || 0;
      return new _big.default(tipTotal);
    }),
    feeCeiling: Ember.computed('entry.fee_ceiling', function () {
      return new _big.default(this.get('entry.fee_ceiling') || 0);
    })
  });

  _exports.default = _default;
});