define("herer-web/controllers/tasks", ["exports", "herer-web/mixins/context", "herer-web/mixins/pageable"], function (_exports, _context, _pageable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _pageable.default, {
    accountHasMoreThanOneLocation: Ember.computed('sortedLocations', function () {
      return this.get('sortedLocations').length > 1;
    }),
    actions: {
      claimAll: function claimAll() {
        var _this = this;

        this.get('taskManager').claimAll().then(function () {
          _this.get('model').fetchObjects();
        });
      }
    }
  });

  _exports.default = _default;
});