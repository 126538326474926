define("herer-web/helpers/adjustment-reason", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    authManager: Ember.inject.service(),
    compute: function compute(params) {
      var value = params[0];

      if (Ember.isBlank(value)) {
        return null;
      }

      var adjustmentReasons = this.get('authManager.currentLocation.license.adjustment_reasons');

      if (Ember.isArray(adjustmentReasons)) {
        return adjustmentReasons.find(function (reason) {
          return reason.value === value;
        }).name;
      }

      return _constants.default.adjustmentReasonNames[value];
    }
  });

  _exports.default = _default;
});