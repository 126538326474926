define("herer-web/helpers/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function preventDefault() {
    return function (event) {
      event.preventDefault();
    };
  });

  _exports.default = _default;
});