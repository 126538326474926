define("herer-web/routes/remote-manifest", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    controllerName: 'manifest',
    templateName: 'manifest',
    model: function model(params) {
      return this.get('store').findRecord('remote_manifest', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('remoteManifest', model);
      controller.set('pendingManifest', false);
    }
  });

  _exports.default = _default;
});