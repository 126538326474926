define("herer-web/models/inventory-item", ["exports", "@ember-data/model", "herer-web/mixins/test-results", "herer-web/constants", "moment", "herer-web/helpers/format-percent", "herer-web/utils/sample-type-string"], function (_exports, _model, _testResults, _constants, _moment, _formatPercent, _sampleTypeString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_testResults.default, {
    sku: (0, _model.attr)('string'),
    lot_number: (0, _model.attr)('string'),
    product: (0, _model.belongsTo)('product', {
      async: false
    }),
    purchase_price: (0, _model.attr)('number'),
    quantity: (0, _model.attr)('weight'),
    last_received_at: (0, _model.attr)('date'),
    received_at: (0, _model.attr)('date'),
    sample_type: (0, _model.attr)('number'),
    sample: (0, _model.attr)('boolean'),
    sell_type: (0, _model.attr)('number'),
    status: (0, _model.attr)('number'),
    status_reason: (0, _model.attr)('number'),
    manifest_lot_status: (0, _model.attr)('number'),
    traceable_amount: (0, _model.attr)('weight'),
    traceable: (0, _model.attr)('boolean'),
    transferred_out: (0, _model.attr)('boolean'),
    weight: (0, _model.attr)('weight'),
    additional_materials: (0, _model.attr)(),
    external_inventory_id: (0, _model.attr)('string'),
    cultivator_license_number: (0, _model.attr)('string'),
    additional_cultivator_license_number: (0, _model.attr)('string'),
    harvest_date: (0, _model.attr)('day'),
    harvest_lot_number: (0, _model.attr)('string'),
    ingredients_text: (0, _model.attr)('string'),
    pesticides_text: (0, _model.attr)('string'),
    producer_name: (0, _model.attr)('string'),
    test_analysis_dates: (0, _model.attr)('string'),
    test_batch_number: (0, _model.attr)('string'),
    test_lab_name: (0, _model.attr)('string'),
    certificate_of_analysis_url: (0, _model.attr)('string'),
    certificate_of_analysis_url_short: (0, _model.attr)('string'),
    certificate_of_analysis_expire_date: (0, _model.attr)('day'),
    expiration_date: (0, _model.attr)('day'),
    use_by_date: (0, _model.attr)('day'),
    medical_subtype: (0, _model.attr)('number'),
    medical: (0, _model.attr)('boolean'),
    packaged_date: (0, _model.attr)('day'),
    packager_name: (0, _model.attr)('string'),
    packager_license_number: (0, _model.attr)('string'),
    pre_mixed_weight: (0, _model.attr)('weight'),
    process_disclosure: (0, _model.attr)('string'),
    unsellablePopoverMessage: "We've automatically marked this item as unsellable. It's possible\n                             that this is a sample or it has been transferred out. By law, you are\n                             not allowed to sell these items.",
    maxIngredientsTextLength: 400,
    inventory_item_places: (0, _model.hasMany)('inventory_item_place', {
      async: false,
      embedded: 'always'
    }),
    inventoryItemPlacesWithNonZeroQuantity: Ember.computed.filterBy("inventory_item_places", "hasNonZeroQuantity", true),
    onlyLockedInventoryItemPlaces: Ember.computed.filterBy("inventory_item_places", "isLocked", true),
    // starting set for lozenges
    inventoryItemPlacesWithNonZeroQuantityOrLocked: Ember.computed.union('inventoryItemPlacesWithNonZeroQuantity', 'onlyLockedInventoryItemPlaces'),
    // populates fromPlace pulldown on inventory move form
    unlockedInventoryItemPlacesWithPositiveQuantity: Ember.computed.filterBy("inventory_item_places", "hasUnlockedPositiveQuantity", true),
    // determines whether to show Move option
    hasPositiveQuantityInUnlockedPlaces: Ember.computed.bool('unlockedInventoryItemPlacesWithPositiveQuantity.length'),
    locked: Ember.computed('sell_type', function () {
      return this.sellTypeEquals('locked');
    }),
    sellable: Ember.computed('sell_type', function () {
      return this.sellTypeEquals('sellable');
    }),
    unsellable: Ember.computed('sell_type', function () {
      return this.sellTypeEquals('unsellable');
    }),
    sampleTypeString: Ember.computed('sample_type', function () {
      return (0, _sampleTypeString.sampleTypeString)(this.get('sample_type'));
    }),
    receivedAgo: Ember.computed('received_at', function () {
      if (this.get('received_at')) {
        return (0, _moment.default)(this.get('received_at')).fromNow().capitalize();
      }
    }),
    invalidIngredientsTextLength: Ember.computed('ingredients_text', function () {
      if (Ember.isBlank(this.get('ingredients_text'))) {
        return false;
      }

      return this.get('ingredients_text').length > this.get('maxIngredientsTextLength');
    }),
    wholesaleCostUpdated: Ember.computed('purchase_price', function () {
      return this.changedAttributes().purchase_price;
    }),
    caluclatedNewPrice: Ember.computed('purchase_price', function () {
      var input_purchase_price = this.get('purchase_price');
      var traceable_amount = this.get('traceable_amount._value') || 1;
      var single_unit_price = parseInt(input_purchase_price / traceable_amount);
      var resulting_price = single_unit_price * traceable_amount;
      return {
        'input_purchase_price': input_purchase_price,
        'resulting_price': resulting_price
      };
    }),
    lotNumberMatchesSku: Ember.computed('lot_number', 'sku', function () {
      var lotNumber = this.get('lot_number');
      return Ember.isPresent(lotNumber) && lotNumber === this.get('sku');
    }),
    sellTypeEquals: function sellTypeEquals(sellTypeName) {
      return this.get('sell_type') === _constants.default.inventoryItemSellType[sellTypeName];
    },

    /**
     * Formats test result for selected cannabinoid
     * if it is not set then it returns empty string, otherwise returns result with unit and upcased cannabinoid
     * for flowers it returns % result, example: 10% THC
     * for others it is mg, example: 10mg CBD
     * if it has range result, they are separated with " - ", example: 10% - 50% THC
     * @param {String} cannabinoid
     * @param {Boolean} locationCanUseRange
     * @return {String}
     */
    formattedTestResultFor: function formattedTestResultFor(cannabinoid, locationCanUseRange) {
      var _this = this;

      var isFlower = this.get('isFlower');
      var isRange = isFlower && locationCanUseRange;
      var testType = isFlower ? '' : 'total_';
      var maxResult = isRange ? this.get("test_results_max_".concat(cannabinoid)) : null;
      var testResult = this.get("test_results_".concat(testType).concat(cannabinoid));

      if (!testResult && !maxResult) {
        return '';
      }

      var formatter = isFlower ? function (result) {
        return (0, _formatPercent.formatPercent)(result);
      } : function (result) {
        return "".concat(parseFloat(result.value), " ").concat(_this.get('testResultsUnitAbbreviation'));
      }; // use Boolean constructor to drop empty results

      return [testResult, maxResult].filter(Boolean).map(formatter).join(' - ').concat(" ".concat(cannabinoid.toUpperCase()));
    },
    isMarijuana: Ember.computed.alias('product.product_type.is_marijuana'),
    isFlower: Ember.computed.alias('product.product_type.isFlower'),
    testResultsUnitAbbreviation: Ember.computed.alias('product.product_type.inventoryTestResultsUnitAbbreviation')
  });

  _exports.default = _default;
});