define("herer-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mk9aX/5M",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"flashMessage\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"flash-message-box\",null,[[\"flashMessage\",\"flashAlertClass\",\"onClose\"],[[24,[\"flashMessage\"]],[24,[\"flashAlertClass\"]],[28,\"action\",[[23,0,[]],\"closeFlashView\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"currentUser\"]],[24,[\"showNavigation\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"navigation-bar\",null,[[\"currentRole\",\"currentLocation\",\"currentUser\",\"locations\",\"logout\",\"goToHomepage\"],[[24,[\"currentRole\"]],[24,[\"currentLocation\"]],[24,[\"currentUser\"]],[24,[\"locations\"]],[28,\"route-action\",[\"logout\"],null],[28,\"route-action\",[\"goToHomepage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"],[1,[22,\"customer-care-info\"],false],[0,\"\\n\"],[1,[28,\"outlet\",[\"error-dialog\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/application.hbs"
    }
  });

  _exports.default = _default;
});