define("herer-web/templates/components/text-counter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4e3uFdU5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"maxlength\",\"class\",\"id\",\"value\",\"readonly\"],[[24,[\"type\"]],[24,[\"max\"]],[24,[\"class\"]],[24,[\"id\"]],[24,[\"value\"]],[24,[\"readonly\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-count-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    Remaining:\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[22,\"remainingCharsCount\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/text-counter-input.hbs"
    }
  });

  _exports.default = _default;
});