define("herer-web/services/scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var whitespaceSeparatorKeys = ['KeyJ', 'KeyM', 'Digit6', 'Enter'];

  var _default = Ember.Service.extend({
    // Used to log information about each keydown event, in case we need to do some debugging with
    // strange scanner output.
    logKeyDown: function logKeyDown(event) {
      console.info({
        keyPressed: event.code,
        modifierKeys: {
          ctrl: event.ctrlKey,
          atl: event.altKey,
          shift: event.shiftKey,
          meta: event.metaKey // CMD on OSX, Windows key on Windows

        }
      });
    },
    shouldReplaceCharacter: function shouldReplaceCharacter(ctrlKey, code) {
      if (code === 'NumLock') {
        return true; // Symbol DS-9308
      }

      if (ctrlKey && whitespaceSeparatorKeys.includes(code)) {
        return true; // Symbol DS-9208
      }

      return false;
    },
    // The scanners try to insert a whitespace separator characters using ctrl + J, ctrl + M, or
    // ctrl + 6. These keystrokes do not input any text into the text area, so here we capture them
    // and manually add a New Line (\n) character to the input in their stead.
    onKeyDown: function onKeyDown(event) {
      this.logKeyDown(event);

      if (this.shouldReplaceCharacter(event.ctrlKey, event.code)) {
        event.preventDefault();
        var scannerOutput = event.target.value;
        event.target.value = "".concat(scannerOutput, "\n");
      }
    }
  });

  _exports.default = _default;
});