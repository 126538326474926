define("herer-web/helpers/format-comparator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var comparator = params[0];

      switch (comparator) {
        case '<':
          return 'Less than';

        case '<=':
          return 'Less than or equal to';

        case '=':
          return 'Equal to';

        case '>=':
          return 'Greater than or equal to';

        case '>':
          return 'Greater than';

        case 'between':
          return 'Between';

        default:
          return '';
      }
    }
  });

  _exports.default = _default;
});