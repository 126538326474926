define("herer-web/routes/users", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/models/paged-array"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    queryParamManager: Ember.inject.service(),
    sideNavTemplateName: 'settings-nav',
    model: function model() {
      var queryParams = this.get('queryParamManager').get(['deleted', 'by_query']);
      var filters = {
        with_deleted: +(queryParams === null || queryParams === void 0 ? void 0 : queryParams.deleted) || 0
      };

      if (Ember.isPresent(queryParams.by_query)) {
        filters['by_query'] = encodeURIComponent(queryParams.by_query);
      }

      var usersPagedArray = _pagedArray.PagedArray.create({
        store: this.get('store'),
        model: 'user',
        filters: filters
      });

      return Ember.RSVP.hash({
        users: usersPagedArray.fetchObjects(),
        roles: this.get('store').findAll('role', {
          reload: true
        }),
        locations: this.get('store').findAll('location', {
          reload: true
        })
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render('user', {
        into: 'users',
        outlet: 'user-form',
        controller: 'user'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.users);
      controller.set('roles', model.roles);
      controller.set('locations', model.locations);
    }
  });

  _exports.default = _default;
});