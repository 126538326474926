define("herer-web/components/account-reports/sales-by-location-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants", "lodash/groupBy"], function (_exports, _dimpleChart, _constants, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    timeRange: null,
    timeInterval: null,
    customFormat: null,
    timeFormat: Ember.computed('customFormat', 'timeInterval', function () {
      var formats = {
        month: ['%B', '%b'],
        month_year: ['%Y-%-m', '%b'],
        hour: ['%-I%p', '%-I%p'],
        day: ['%Y-%m-%d', '%m/%d'],
        week: ['%Y-%m-%d', '%m/%d']
      };
      var format = formats[this.get('timeInterval')];
      var customFormat = this.get('customFormat');

      if (Ember.isPresent(customFormat)) {
        format[1] = customFormat;
      }

      return format;
    }),
    formattedTimeInterval: Ember.computed('timeInterval', function () {
      return this.get('timeInterval').split('_')[0].capitalize();
    }),
    width: Ember.computed(function () {
      return this.$().width();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var height = Math.round(this.get('width') * 0.3);
      this.$().height(height);
    },
    remap: function remap(data) {
      var _this = this;

      var newData = [];
      data && data.forEach(function (locationData) {
        locationData.sourceData.forEach(function (row, index) {
          newData.push(_this._configureRowForSeries(row, index));
        });
      });
      return newData;
    },
    customizeChart: function customizeChart(chart) {
      var width = this.get('width');
      var w = (width - 320) / (1110 - 320);
      var iX = d3.interpolateNumber(15, 5);
      var iY = d3.interpolateNumber(10, 5);
      var iWidth = d3.interpolateNumber(80, 90);
      var iHeight = d3.interpolateNumber(70, 75);
      var iLegend = d3.interpolateNumber(70, 25);
      var iTicks = d3.interpolateNumber(3, 10);
      chart.setBounds("".concat(iX(w), "%"), "".concat(iY(w), "%"), "".concat(iWidth(w), "%"), "".concat(iHeight(w), "%"));
      chart.addLegend("".concat(95 - iLegend(w), "%"), 0, "".concat(iLegend(w), "%"), 20, 'right');
      chart.ease = 'bounce';
      chart.defaultColors = _constants.default.chartColors;
      var y = chart.addMeasureAxis('y', 'Sales');
      y.showGridlines = false;
      y.title = '';
      y.fontSize = '12px';
      y.ticks = parseInt(iTicks(w));
      y.tickFormat = '$,.2s';
      var format = this.get('timeFormat');
      var x = chart.addTimeAxis('x', this.get('formattedTimeInterval'), format[0], format[1]);
      x.showGridlines = false;
      x.title = '';
      x.fontSize = '12px';

      if (this.get('timeRange') === '30-days') {
        x.timeInterval = 4;
      }

      var grouped = (0, _groupBy.default)(this.get('_data'), function (row) {
        return row.Series;
      });
      Object.keys(grouped).forEach(function (location) {
        var series = chart.addSeries('Series', dimple.plot.area);
        series.data = grouped[location];
        series.interpolation = 'cardinal';
        series.lineMarkers = true;
      });
      return chart;
    },
    _configureRowForSeries: function _configureRowForSeries(row, index) {
      row[this.get('formattedTimeInterval')] = row[this.get('timeInterval')];
      row.Series = row.location;
      row.index = index;
      row.Sales = row.sales;
      return row;
    }
  });

  _exports.default = _default;
});