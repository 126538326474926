define("herer-web/components/infinite-scroll", ["exports", "herer-web/mixins/infinite-scroll"], function (_exports, _infiniteScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infiniteScroll.default, {
    loadMoreAction: null,
    actions: {
      loadMore: function loadMore() {
        return this.loadMoreAction();
      }
    }
  });

  _exports.default = _default;
});