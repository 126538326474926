define("herer-web/routes/activity", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route", "herer-web/models/paged-array", "herer-web/mixins/pin-debit-settings"], function (_exports, _authenticatedFullPage, _alertUnsavedRoute, _pagedArray, _pinDebitSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_alertUnsavedRoute.default, _pinDebitSettings.default, {
    modelName: 'order',
    mixpanelEvent: 'Activity.show',
    model: function model() {
      var store = this.get('store');

      var pagedArray = _pagedArray.TimePagedArray.create({
        store: store,
        model: this.get('modelName')
      });

      return Ember.RSVP.hash({
        orders: pagedArray.fetchObjects(),
        paymentMethods: store.findAll('payment_method'),
        pinDebitSettings: this.findPinDebitSettings(store)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.orders);

      model.orders.set('filters', controller.get('filters'));
      controller.set('paymentMethods', model.paymentMethods);
      controller.set('pinDebitSettings', model.pinDebitSettings);
    },
    renderTemplate: function renderTemplate() {
      this._super();

      this.render('order', {
        into: 'activity',
        outlet: 'order_panel',
        controller: 'order'
      });
    }
  });

  _exports.default = _default;
});