define("herer-web/controllers/lots", ["exports", "herer-web/mixins/context", "herer-web/mixins/export", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/titan-email", "herer-web/mixins/quantity-sync"], function (_exports, _context, _export, _lotNumberFormatter, _titanEmail, _quantitySync) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller;

  var mixins = [_context.default, _export.default, _lotNumberFormatter.default, _titanEmail.default, _quantitySync.default];

  var _default = (_Ember$Controller = Ember.Controller).extend.apply(_Ember$Controller, mixins.concat([{
    featureManager: Ember.inject.service(),
    actions: {
      reload: function reload() {
        this.get('model').fetchObjects();
      },
      exportProductList: function exportProductList() {
        this.exportProductList();
      },
      exportPackageList: function exportPackageList() {
        this.exportPackageList();
      },
      exportInventoryItemList: function exportInventoryItemList() {
        this.exportInventoryItemList();
      },
      handleExportLlxList: function handleExportLlxList() {
        this.exportLlxList();
      },
      openDrawer: function openDrawer(aPackage) {
        this.transitionToRoute('lots.lot.history', aPackage.get('id'));
      }
    }
  }]));

  _exports.default = _default;
});