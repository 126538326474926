define("herer-web/controllers/shift", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "bootbox", "lodash/isNumber"], function (_exports, _alertUnsavedController, _context, _bootbox, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    launchDarkly: Ember.inject.service(),
    report: null,
    isEditing: false,
    missingClosingAmounts: false,
    showEmployeeName: Ember.computed.and('currentLocation.employee_tracking', 'model.employee_name'),
    endable: Ember.computed('model.closed_at', 'model.locked', function () {
      return Ember.isPresent(this.get('model.closed_at')) && !this.get('model.locked');
    }),
    overOrUnder: Ember.computed('overUnder', function () {
      if (this.get('overUnder') > 0) {
        return 'Over';
      } else if (this.get('overUnder') < 0) {
        return 'Short';
      } else {
        return 'Balanced';
      }
    }),
    overUnder: Ember.computed('report.{expected_ending_cash,starting_cash}', 'model.{close_drawer_amount,close_drop_amount,open_amount}', function () {
      var openAmount = this.number(this.get('model.open_amount'));
      var open_difference = this.get('report.starting_cash') - openAmount;
      var closeDrawerAmount = this.number(this.get('model.close_drawer_amount'));
      var closeDropAmount = this.number(this.get('model.close_drop_amount'));
      var expectedEndingCash = this.get('report.expected_ending_cash');
      var close_difference = closeDrawerAmount + closeDropAmount - expectedEndingCash;
      return open_difference + close_difference;
    }),
    showCurrentAmount: Ember.computed('model.{missingClosingAmounts,closed_at}', function () {
      return !Ember.isPresent(this.get('model.closed_at')) || this.get('model.missingClosingAmounts');
    }),
    canEdit: Ember.computed('model.closed_at', 'isEditing', function () {
      return Ember.isPresent(this.get('model.closed_at')) && !this.get('isEditing');
    }),
    cantClose: Ember.computed.or('model.closed_at', 'isEditing'),
    number: function number(value) {
      return (0, _isNumber.default)(value) ? value : 0;
    },
    actions: {
      saveShift: function saveShift() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.set('isEditing', false);

          _this.send('refresh');
        }).catch(function () {
          _bootbox.default.alert("Sorry, something went wrong when trying to update this shift.\n          If you still have this issue, chat Support.");
        });
      },
      edit: function edit() {
        this.set('isEditing', true);
        return false;
      },
      cancelEdit: function cancelEdit() {
        this.get('model').rollbackAttributes();
        this.set('isEditing', false);
      }
    }
  });

  _exports.default = _default;
});