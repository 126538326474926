define("herer-web/transforms/weight", ["exports", "@ember-data/serializer/transform", "herer-web/models/weight"], function (_exports, _transform, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!Ember.isPresent(serialized)) {
        return null;
      }

      return _weight.default.create({
        value: serialized.value,
        unit: serialized.unit
      });
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isPresent(deserialized)) {
        return null;
      }

      return {
        value: deserialized.get('value'),
        unit: deserialized.get('unit')
      };
    }
  });

  _exports.default = _default;
});