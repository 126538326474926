define("herer-web/utils/sample-type-string", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sampleTypeString = sampleTypeString;

  function sampleTypeString(sampleType) {
    var type = _constants.default.sampleTypes.find(function (type) {
      return type.value === sampleType;
    });

    return Ember.isPresent(type) ? type.name : '';
  }
});