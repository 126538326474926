define("herer-web/routes/packages/reconcile", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    templateName: 'packages/reconcile',
    renderTemplate: function renderTemplate() {
      this._super();

      return this.render({
        into: 'side-nav-layout',
        outlet: 'modalContent'
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('packages');
      }
    }
  });

  _exports.default = _default;
});