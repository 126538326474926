define("herer-web/controllers/label-printer", ["exports", "herer-web/config/environment", "herer-web/constants"], function (_exports, _environment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Environment = _environment.default.APP;

  var _default = Ember.Controller.extend({
    labelPrinter: Ember.inject.service(),
    webstoreUrl: Ember.computed(function () {
      return Environment.webstoreUrl;
    }),
    isChrome: Ember.computed.alias('labelPrinter.isChrome'),
    foundVersion: Ember.computed.alias('labelPrinter.foundVersion'),
    acceptableVersion: Ember.computed.alias('labelPrinter.acceptableVersion'),
    canUseElectronPrinter: Ember.computed.alias('labelPrinter.canUseElectronPrinter'),
    canPrintWithElectron: Ember.computed.alias('labelPrinter.canPrintWithElectron'),
    electronAppMacDownloadUrl: null,
    electronAppWindowsDownloadUrl: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.getElectronAppUrls();
    },
    getElectronAppUrls: function getElectronAppUrls() {
      var _this = this;

      fetch("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/printers_manager/downloads")).then(function (response) {
        return response.json();
      }).then(function (_ref) {
        var mac = _ref.mac,
            windows = _ref.windows;

        _this.set('electronAppMacDownloadUrl', mac);

        _this.set('electronAppWindowsDownloadUrl', windows);
      });
    },
    showInstallAppStep: Ember.computed('isChrome', 'showUpdateStep', function () {
      return this.get('isChrome') && !this.get('showUpdateStep');
    }),
    showUpdateStep: Ember.computed('isChrome', 'foundVersion', 'acceptableVersion', function () {
      return this.get('isChrome') && this.get('foundVersion') && !this.get('acceptableVersion');
    }),
    showSuccessStep: Ember.computed('isChrome', 'acceptableVersion', function () {
      return this.get('isChrome') && this.get('acceptableVersion');
    }),
    actions: {
      didInstall: function didInstall() {
        return this.get('labelPrinter').ping();
      },
      showDisplay: function showDisplay() {
        return this.get('labelPrinter').showDisplay();
      }
    }
  });

  _exports.default = _default;
});