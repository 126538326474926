define("herer-web/templates/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCn1knRT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"input-group file-input-wrapper \",[28,\"if\",[[24,[\"errorMessage\"]],\"has-error\"],null]]]],[12,\"data-test-action\",\"choose_file\"],[3,\"action\",[[23,0,[]],\"chooseFile\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"name\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-control\"],[10,\"data-test_value\",\"file_name\"],[8],[0,\"\\n      \"],[1,[22,\"name\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-control select-image\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"prompt\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"prompt\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Select a file from your computer...\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-file-upload\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"name\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-remove-file\"],[12,\"data-test-action\",\"remove_file\"],[3,\"action\",[[23,0,[]],\"removeFile\"],[[\"bubbles\"],[false]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"disabled\",\"class\",\"accept\"],[\"file\",[24,[\"disabled\"]],\"hidden-file-input\",[24,[\"acceptableFileTypes\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-danger\"],[8],[0,\"\\n    \"],[1,[22,\"errorMessage\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/file-input.hbs"
    }
  });

  _exports.default = _default;
});