define("herer-web/routes/return-manifests", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    sideNavTemplateName: 'inventory-nav',
    modelName: 'return-manifest',
    permissionKey: 'return_inventory'
  });

  _exports.default = _default;
});