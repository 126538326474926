define("herer-web/pivot-table/cell", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    group: null,
    column: null,
    row: null,
    unit: null,
    value: new _big.default(0)
  });

  _exports.default = _default;
});