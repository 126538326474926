define("herer-web/adapters/orders-statistic", ["exports", "herer-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super();

      return "".concat(url, "/people/").concat(query.personId, "/orders_statistic");
    }
  });

  _exports.default = _default;
});