define("herer-web/components/inventory-item-status-select", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allowAll: false,
    onStatusChange: null,
    selectedStatus: null,
    statusReason: null,
    displayStatus: null,
    hasStatusReason: Ember.computed.notEmpty('statusReason'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('statusIsLocked')) {
        return this.set('displayStatus', _constants.default.inventoryItemStatus.archived);
      }

      return this.set('displayStatus', this.get('selectedStatus'));
    },
    displayStatuses: Ember.computed('statuses', function () {
      // Have to deep dupe to prevent modifying original objects
      var displayStatuses = JSON.parse(JSON.stringify(_constants.default.inventoryItemStatuses)).rejectBy('value', _constants.default.inventoryItemStatus.locked);

      if (this.get('statusIsLocked')) {
        var archivedStatus = displayStatuses.findBy('value', _constants.default.inventoryItemStatus.archived);
        var lockedDescription = 'Locked';

        if (this.get('hasStatusReason')) {
          var statusReason = _constants.default.inventoryItemStatusReasons.findBy('value', this.get('statusReason'));

          lockedDescription = "".concat(lockedDescription, ", ").concat(statusReason.name);
        }

        archivedStatus.name = "Archived - ".concat(lockedDescription);
      }

      if (this.get('allowAll')) {
        displayStatuses.unshift({
          name: 'All',
          value: undefined
        });
      }

      return displayStatuses;
    }),
    statusIsLocked: Ember.computed('selectedStatus', function () {
      return this.get('selectedStatus') === _constants.default.inventoryItemStatus.locked;
    })
  });

  _exports.default = _default;
});