define("herer-web/components/untracked-migration-row", ["exports", "herer-web/constants", "herer-web/mixins/context"], function (_exports, _constants, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    http: Ember.inject.service(),
    migration: null,
    licenseTypeName: Ember.computed('migration.license_type', function () {
      var licenseType = this.get('migration.license_type');
      return _constants.default.licenseTypes.find(function (item) {
        return item.value === licenseType;
      }).name;
    }),
    cancel: function cancel(taskId) {
      var _this = this;

      Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/tasks/").concat(taskId, "/cancel")).finally(function () {
        return _this.onCancel();
      });
    },
    actions: {
      cancel: function cancel(taskId) {
        this.cancel(taskId);
      }
    }
  });

  _exports.default = _default;
});