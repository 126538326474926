define("herer-web/components/missing-thc-notification-modal", ["exports", "herer-web/components/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    modalClassName: '.missing-thc-notification-modal',
    receiptId: null,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (Ember.isEmpty(modal)) {
        return;
      }

      if (this.get('show')) {
        modal.modal();
      } else {
        modal.modal('hide');
      }
    },
    actions: {
      onClick: function onClick() {
        this.onConfirm && this.onConfirm();
      }
    }
  });

  _exports.default = _default;
});