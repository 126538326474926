define("herer-web/components/file-attachments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    model: null,
    modelName: null,
    onCreate: null,
    onDelete: null,
    _modelFileAttachments: Ember.computed('model.fileAttachments.@each.isNew', function () {
      if (Ember.isPresent(this.get('model.fileAttachments'))) {
        return this.get('model.fileAttachments');
      }

      return [];
    }),
    newAttachments: Ember.computed('_modelFileAttachments', function () {
      return this.get('_modelFileAttachments').filterBy('isNew');
    }),
    hasNewAttachments: Ember.computed('newAttachments', function () {
      return this.get('newAttachments').length !== 0;
    }),
    existingAttachments: Ember.computed('_modelFileAttachments', function () {
      return this.get('_modelFileAttachments').rejectBy('isNew');
    })
  });

  _exports.default = _default;
});