define("herer-web/components/table-filters", ["exports", "herer-web/models/filter-value", "herer-web/models/weight", "lodash/difference", "moment"], function (_exports, _filterValue, _weight, _difference, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function parseDate(value) {
    return (0, _moment.default)(value).format('YYYY-MM-DD');
  }

  function parseTime(value) {
    var parts = value.split(':');
    return (0, _moment.default)({
      hour: parts[0],
      minutes: parts[1]
    }).format();
  }

  var _default = Ember.Component.extend({
    queryParamManager: Ember.inject.service(),
    classNames: ['table-filters'],
    availableFilters: [],
    filterParamsChanged: null,
    filterValuesChanged: null,
    filterValues: [],
    _filterParams: {},
    disabled: false,
    defaultFilterLabel: 'Filter by',
    removeFilterLabel: 'Remove filter',
    enableQueryString: false,
    paramDelim: '|',
    init: function init() {
      this._super();

      this.initFilters();
    },
    initFilters: function initFilters() {
      var _this = this;

      var filterValues = [];
      var availableFilters = this.get('availableFilters');
      var queryParams = this.get('queryParamManager').get(availableFilters.mapBy('query'));
      availableFilters.forEach(function (availableFilter) {
        var filterValue;
        var isDefault = availableFilter.defaultFilter;

        if (isDefault) {
          filterValue = availableFilter.defaultValue;
        }

        var queryParam = queryParams[availableFilter.query];

        if (_this.get('enableQueryString') && Ember.isPresent(queryParam)) {
          filterValue = _this.parseQueryParam(availableFilter, queryParam);
        }

        if (typeof filterValue !== 'undefined' || isDefault) {
          filterValues.push(_this.createFilter(availableFilter, filterValue));
        }
      });

      if (filterValues.length < this.get('availableFilters.length')) {
        filterValues.push(_filterValue.default.create());
      }

      this.set('filterValues', filterValues);
      this.valueChanged();
      this.handleFilterValuesChange();
      this.setQueryParams(availableFilters, this.get('_filterParams'));
    },
    createFilter: function createFilter(filter) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var filterObj = {
        filter: filter,
        value: value
      };

      if (Ember.isArray(value)) {
        if (typeof value[0] === 'number') {
          filterObj.value = value[0];
        } else {
          this.decorateFilterObj(filterObj, value);
        }

        filterObj.comparator = value[1];
      } else {
        filterObj.value = value;
      }

      return _filterValue.default.create(filterObj);
    },
    // creates a secondValue on filterObj if available
    decorateFilterObj: function decorateFilterObj(filterObj) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var values = value[0].split(',');
      filterObj.value = this.formatFilterValue(filterObj.filter, values[0]);

      if (values.length === 2) {
        filterObj.secondValue = this.formatFilterValue(filterObj.filter, values[1]);
      }
    },
    // Numeric IDs, true/false or string uuids
    getSelectBoxParser: function getSelectBoxParser(subtype) {
      if (subtype === 'uuid') {
        return decodeURIComponent;
      } else if (subtype === 'bool') {
        return function (value) {
          return !!value;
        };
      } else {
        return parseFloat;
      }
    },
    // Ensure value is the right type since it may be coming from the query string
    formatFilterValue: function formatFilterValue(filter, value) {
      var parser = parseFloat;

      switch (filter.type) {
        case 'text':
          parser = decodeURIComponent;
          break;

        case 'date':
          parser = parseDate;
          break;

        case 'time':
          parser = parseTime;
          break;

        case 'weight':
          parser = _weight.parseWeight;
          break;

        case 'select':
          parser = this.getSelectBoxParser(filter.subtype);
          break;
      } // catch the parsing error and squelch it, otherwise any page that has a
      // filter with an invalid filter value won't be able to open


      var safeValue = '';

      try {
        safeValue = parser(value);
      } catch (err) {
        console.error(err);
      }

      return safeValue;
    },
    stringifyFilterValue: function stringifyFilterValue(filterValue) {
      var isString = typeof filterValue === 'string';

      if (isString) {
        return filterValue;
      }

      if (filterValue.comparator) {
        var comparator = filterValue.comparator,
            restObj = _objectWithoutProperties(filterValue, ["comparator"]);

        var params = Object.values(restObj);
        params.push(comparator);
        return params.join(this.get('paramDelim'));
      } else {
        return JSON.stringify(filterValue);
      }
    },
    parseQueryParam: function parseQueryParam(filter, stringified_param) {
      if (filter.type === 'money' || filter.type === 'numeric') {
        return stringified_param.split(this.get('paramDelim'));
      } else {
        return this.formatFilterValue(filter, stringified_param);
      }
    },
    setQueryParams: function setQueryParams(availableFilters, filterParams) {
      var _this2 = this;

      if (!this.get('enableQueryString')) {
        return;
      }

      var queryParams = {};
      availableFilters.mapBy('query').forEach(function (query) {
        var param;

        if (filterParams[query] !== undefined) {
          param = _this2.stringifyFilterValue(filterParams[query]);
        }

        queryParams[query] = param;
      });
      this.get('queryParamManager').set(queryParams);
    },
    unusedFilters: Ember.computed('availableFilters', 'filterValues.@each.filter', function () {
      var usedFilters = this.get('filterValues').mapBy('filter');
      return (0, _difference.default)(this.get('availableFilters'), usedFilters);
    }),
    actions: {
      addFilterValue: function addFilterValue() {
        this.addFilterValue();
      },
      removeFilterValue: function removeFilterValue(filterValue) {
        this.get('filterValues').removeObject(filterValue);

        if (!Ember.isEmpty(filterValue.get('value'))) {
          this.valueChanged();
        }

        this.addFilterValue();
      },
      valueChanged: function valueChanged() {
        this.valueChanged();
      }
    },
    valueChanged: function valueChanged() {
      var filterParams = {};
      this.get('filterValues').forEach(function (filterValue) {
        if (filterValue.get('filter') !== null && !Ember.isEmpty(filterValue.get('queryValue'))) {
          filterParams[filterValue.get('filter.query')] = filterValue.get('queryValue');
        }
      });
      this.set('_filterParams', filterParams);
      this.setQueryParams(this.get('availableFilters'), filterParams);
      this.handleFilterParamsChange();
    },
    addFilterValue: function addFilterValue() {
      if (this.get('filterValues.lastObject.filter') && this.get('unusedFilters.length')) {
        this.get('filterValues').pushObject(_filterValue.default.create());
        this.handleFilterValuesChange();
      }
    },
    handleFilterValuesChange: function handleFilterValuesChange() {
      this.filterValuesChanged && this.filterValuesChanged(this.get('filterValues'));
    },
    handleFilterParamsChange: function handleFilterParamsChange() {
      this.filterParamsChanged && this.filterParamsChanged(this.get('_filterParams'));
    }
  });

  _exports.default = _default;
});