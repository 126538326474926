define("herer-web/templates/account-sales-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u8u4h8Yv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\"],[\"Account Sales Summary\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12 fluid-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel table\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"row table-row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n              \"],[1,[22,\"account-sales-summary\"],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/account-sales-summary.hbs"
    }
  });

  _exports.default = _default;
});