define("herer-web/initializers/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  var initialize = function initialize() {
    $.ajaxSetup({
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      cache: false
    });
  };

  _exports.initialize = initialize;
  var AjaxInitializer = {
    name: 'ajax',
    initialize: initialize
  };
  var _default = AjaxInitializer;
  _exports.default = _default;
});