define("herer-web/models/weight", ["exports", "herer-web/constants", "big.js", "lodash/isNaN", "lodash/isUndefined", "lodash/trim", "ember-copy"], function (_exports, _constants, _big, _isNaN, _isUndefined, _trim, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.parseWeight = void 0;
  var Weight = Ember.Object.extend(_emberCopy.Copyable, {});
  var precision = 5;
  Weight.reopen({
    street: false,
    unit: null,
    _value: null,
    // Use this for any calculations internally or externally.
    // It will always provide a safe numeric (Big) value
    // even if internal _value is null.
    value: Ember.computed('_value', {
      get: function get() {
        return this.safeBigValue(this.get('_value'));
      },
      set: function set(key, value) {
        // Allow us to set null values
        this.set('_value', value); // This can get cached by Ember if _value doesn't change.
        // Ran into issues passing the value argument straight through,
        // so best to return consistent value with output of get(), even though
        // we store the raw material (possibly null) internally.

        return this.safeBigValue(value);
      }
    }),
    safeBigValue: function safeBigValue(value) {
      var cleanValue = value || 0;

      if (Ember.isPresent(value) && typeof value === 'string') {
        cleanValue = parseFloat(value.replace(/[^\d\.-]/g, ''), 10);

        if (Ember.isEmpty(cleanValue) || (0, _isNaN.default)(cleanValue)) {
          cleanValue = 0;
        }
      }

      return new _big.default(cleanValue);
    },
    // Property specifically for weight-input component that
    // allows for null/blank values displayed in the UI.
    textInputValue: Ember.computed('value', {
      get: function get() {
        return this.get('_value');
      },
      set: function set(key, value) {
        this.set('value', value);
        return value;
      }
    }),
    UNIT_ABBREVIATION: _constants.default.weightUnitAbbreviations,
    UNIT_LABEL: _constants.default.weightUnitLabels,
    UNIT_LABEL_SHORT: _constants.default.weightUnitShortLabels,
    UNIT_NAME: _constants.default.weightUnitNames,
    GRAMS_PER_OUNCE: Ember.computed('street', function () {
      return this.get('street') ? new _big.default('28') : new _big.default('28.34952');
    }),
    GRAMS_PER_POUND: Ember.computed('street', function () {
      return this.get('street') ? new _big.default('448') : new _big.default('453.59237');
    }),
    OUNCES_PER_POUND: new _big.default('16'),
    MILLIGRAMS_PER_GRAM: new _big.default('1000'),
    KILOGRAMS_PER_GRAM: new _big.default('0.001'),
    IDENTITY: new _big.default('1'),
    MILLIGRAMS_PER_OUNCE: Ember.computed(function () {
      return this.get('GRAMS_PER_OUNCE').times(this.get('MILLIGRAMS_PER_GRAM'));
    }),
    KILOGRAMS_PER_OUNCE: Ember.computed(function () {
      return this.get('GRAMS_PER_OUNCE').times(this.get('KILOGRAMS_PER_GRAM'));
    }),
    MILLIGRAMS_PER_POUND: Ember.computed(function () {
      return this.get('GRAMS_PER_POUND').times(this.get('MILLIGRAMS_PER_GRAM'));
    }),
    KILOGRAMS_PER_POUND: Ember.computed(function () {
      return this.get('GRAMS_PER_POUND').times(this.get('KILOGRAMS_PER_GRAM'));
    }),
    MILLIGRAMS_PER_KILOGRAM: Ember.computed(function () {
      return this.get('MILLIGRAMS_PER_GRAM').pow(2);
    }),
    CONVERSIONS: Ember.computed('street', function () {
      var conversions = {};
      var unitConversions = {};
      unitConversions[_constants.default.weightUnit.unit] = ['times', this.get('IDENTITY')];
      var gramConversions = {};
      gramConversions[_constants.default.weightUnit.gram] = ['times', this.get('IDENTITY')];
      gramConversions[_constants.default.weightUnit.ounce] = ['div', this.get('GRAMS_PER_OUNCE')];
      gramConversions[_constants.default.weightUnit.fluidOunce] = ['div', this.get('GRAMS_PER_OUNCE')];
      gramConversions[_constants.default.weightUnit.pound] = ['div', this.get('GRAMS_PER_POUND')];
      gramConversions[_constants.default.weightUnit.milligram] = ['times', this.get('MILLIGRAMS_PER_GRAM')];
      gramConversions[_constants.default.weightUnit.kilogram] = ['times', this.get('KILOGRAMS_PER_GRAM')];
      var ounceConversions = {};
      ounceConversions[_constants.default.weightUnit.gram] = ['times', this.get('GRAMS_PER_OUNCE')];
      ounceConversions[_constants.default.weightUnit.ounce] = ['times', this.get('IDENTITY')];
      ounceConversions[_constants.default.weightUnit.fluidOunce] = ['times', this.get('IDENTITY')];
      ounceConversions[_constants.default.weightUnit.pound] = ['div', this.get('OUNCES_PER_POUND')];
      ounceConversions[_constants.default.weightUnit.milligram] = ['times', this.get('MILLIGRAMS_PER_OUNCE')];
      ounceConversions[_constants.default.weightUnit.kilogram] = ['times', this.get('KILOGRAMS_PER_OUNCE')];
      var poundConversions = {};
      poundConversions[_constants.default.weightUnit.gram] = ['times', this.get('GRAMS_PER_POUND')];
      poundConversions[_constants.default.weightUnit.ounce] = ['times', this.get('OUNCES_PER_POUND')];
      poundConversions[_constants.default.weightUnit.fluidOunce] = ['times', this.get('OUNCES_PER_POUND')];
      poundConversions[_constants.default.weightUnit.pound] = ['times', this.get('IDENTITY')];
      poundConversions[_constants.default.weightUnit.milligram] = ['times', this.get('MILLIGRAMS_PER_POUND')];
      poundConversions[_constants.default.weightUnit.kilogram] = ['times', this.get('KILOGRAMS_PER_POUND')];
      var milligramConversions = {};
      milligramConversions[_constants.default.weightUnit.gram] = ['div', this.get('MILLIGRAMS_PER_GRAM')];
      milligramConversions[_constants.default.weightUnit.ounce] = ['div', this.get('MILLIGRAMS_PER_OUNCE')];
      milligramConversions[_constants.default.weightUnit.fluidOunce] = ['div', this.get('MILLIGRAMS_PER_OUNCE')];
      milligramConversions[_constants.default.weightUnit.pound] = ['div', this.get('MILLIGRAMS_PER_POUND')];
      milligramConversions[_constants.default.weightUnit.milligram] = ['times', this.get('IDENTITY')];
      milligramConversions[_constants.default.weightUnit.kilogram] = ['div', this.get('MILLIGRAMS_PER_KILOGRAM')];
      var kilogramConversions = {};
      kilogramConversions[_constants.default.weightUnit.gram] = ['div', this.get('KILOGRAMS_PER_GRAM')];
      kilogramConversions[_constants.default.weightUnit.ounce] = ['div', this.get('KILOGRAMS_PER_OUNCE')];
      kilogramConversions[_constants.default.weightUnit.fluidOunce] = ['div', this.get('KILOGRAMS_PER_OUNCE')];
      kilogramConversions[_constants.default.weightUnit.pound] = ['div', this.get('KILOGRAMS_PER_POUND')];
      kilogramConversions[_constants.default.weightUnit.milligram] = ['times', this.get('MILLIGRAMS_PER_KILOGRAM')];
      kilogramConversions[_constants.default.weightUnit.kilogram] = ['times', this.get('IDENTITY')];
      var fluidOunceConversions = {};
      fluidOunceConversions[_constants.default.weightUnit.gram] = ['times', this.get('GRAMS_PER_OUNCE')];
      fluidOunceConversions[_constants.default.weightUnit.ounce] = ['times', this.get('IDENTITY')];
      fluidOunceConversions[_constants.default.weightUnit.fluidOunce] = ['times', this.get('IDENTITY')];
      fluidOunceConversions[_constants.default.weightUnit.pound] = ['div', this.get('OUNCES_PER_POUND')];
      fluidOunceConversions[_constants.default.weightUnit.milligram] = ['times', this.get('MILLIGRAMS_PER_OUNCE')];
      fluidOunceConversions[_constants.default.weightUnit.kilogram] = ['times', this.get('KILOGRAMS_PER_OUNCE')];
      conversions[_constants.default.weightUnit.unit] = unitConversions;
      conversions[_constants.default.weightUnit.gram] = gramConversions;
      conversions[_constants.default.weightUnit.ounce] = ounceConversions;
      conversions[_constants.default.weightUnit.pound] = poundConversions;
      conversions[_constants.default.weightUnit.milligram] = milligramConversions;
      conversions[_constants.default.weightUnit.kilogram] = kilogramConversions;
      conversions[_constants.default.weightUnit.fluidOunce] = fluidOunceConversions;
      return conversions;
    }),
    formatted: Ember.computed.alias('string'),
    unitParameterized: Ember.computed('unit', function () {
      return this.get('UNIT_ABBREVIATION')[this.get('unit')];
    }),
    valueParameterized: Ember.computed('value', function () {
      return this.get('value').toString().replace(/\./g, '-');
    }),
    string: Ember.computed('unit', 'value', function () {
      return this.toString();
    }),
    unitString: Ember.computed('unit', function () {
      return this.get('UNIT_ABBREVIATION')[this.get('unit')];
    }),
    unitLabel: Ember.computed('unit', function () {
      return this.get('UNIT_LABEL')[this.get('unit')];
    }),
    unitLabelShort: Ember.computed('unit', function () {
      return this.get('UNIT_LABEL_SHORT')[this.get('unit')];
    }),
    unitName: Ember.computed('unit', function () {
      return this.get('UNIT_NAME')[this.get('unit')];
    }),
    isUnit: Ember.computed('unit', function () {
      return this.get('unit') === _constants.default.weightUnit.unit;
    }),
    copy: function copy() {
      return Weight.create({
        value: this.get('value'),
        unit: this.get('unit'),
        street: this.get('street')
      });
    },
    minus: function minus(other) {
      other = other.to(this.get('unit'));
      var newValue = this.get('value').minus(other.get('value'));
      return Weight.create({
        value: newValue,
        unit: this.get('unit')
      });
    },
    plus: function plus(other) {
      other = other.to(this.get('unit'));
      var newValue = this.get('value').plus(other.get('value'));
      return Weight.create({
        value: newValue,
        unit: this.get('unit')
      });
    },
    times: function times(number) {
      var newValue = this.get('value').times(number);
      return Weight.create({
        value: newValue,
        unit: this.get('unit')
      });
    },
    div: function div(number) {
      var newValue = this.get('value').div(number);
      return Weight.create({
        value: newValue,
        unit: this.get('unit')
      });
    },
    eq: function eq(other) {
      return this.cmp(other) === 0;
    },
    lt: function lt(other) {
      return this.cmp(other) < 0;
    },
    lte: function lte(other) {
      return this.cmp(other) <= 0;
    },
    gt: function gt(other) {
      return this.cmp(other) > 0;
    },
    gte: function gte(other) {
      return this.cmp(other) >= 0;
    },
    to: function to(toUnit) {
      var fromUnit = this.get('unit');

      if (fromUnit === toUnit) {
        return this;
      }

      var conversion = this._conversion(fromUnit, toUnit);

      var operator = conversion[0];
      var conversionValue = conversion[1];
      var newValue = this.get('value')[operator](conversionValue);
      return Weight.create({
        value: newValue,
        unit: toUnit
      });
    },
    toGram: function toGram() {
      return this.to(_constants.default.weightUnit.gram);
    },
    toString: function toString() {
      var value = this.get('value').toFixed(precision);
      var intValue = parseInt(value);
      var floatValue = parseFloat(value);
      var valueForString = intValue === floatValue ? intValue : floatValue;
      return (0, _trim.default)([valueForString, this.get('UNIT_ABBREVIATION')[this.get('unit')]].join(' '));
    },
    round: function round(precision) {
      var copy = this.copy();
      copy.set('value', this.get('value').round(precision));
      return copy;
    },
    floor: function floor() {
      var copy = this.copy();
      copy.set('value', new _big.default(parseInt(this.get('value'))));
      return copy;
    },
    isWeight: Ember.computed('isUnit', function () {
      return !this.get('isUnit');
    }),
    isZero: Ember.computed('value', function () {
      return parseFloat(this.get('value')) === 0;
    }),
    greaterThanZero: Ember.computed('value', function () {
      return this.get('value').gt(0);
    }),
    sortValue: Ember.computed('value', 'isWeight', function () {
      var value = this.get('value');

      if (this.get('isWeight')) {
        value = this.to(_constants.default.weightUnit.gram).get('value');
      }

      return parseInt(value.toFixed(precision));
    }),
    cmp: function cmp(other) {
      if (this.get('street') !== other.get('street')) {
        throw 'Cannot compare street and standard weights';
      }

      var converted = other.to(this.get('unit'));
      return this.get('value').cmp(converted.get('value'));
    },
    zero: function zero() {
      return Weight.create({
        value: 0,
        unit: this.get('unit')
      });
    },
    // Private
    _conversion: function _conversion(from, to) {
      var conversion = this.get('CONVERSIONS')[from][to];

      if (!Ember.isPresent(conversion)) {
        var unitNames = this.get('UNIT_NAME');
        var errorMessage = "No conversion found from ".concat(unitNames[from], " to ").concat(unitNames[to], ".");
        var errorDescription = ' The Unit of Measure for the Products you are reassigning do' + ' not match. Select Products that match to continue.';
        throw new Error(errorMessage + errorDescription);
      }

      return conversion;
    }
  });

  var parseWeight = function parseWeight(string) {
    var unit, value;
    var trimmed = (0, _trim.default)(string); // One character before the part of the string containing the unit
    // Why not just split? Because it makes it more difficult to parse units with spaces

    var unitStart = trimmed.indexOf(' ');

    if (unitStart < 0) {
      unit = _constants.default.weightUnit.unit;
      value = trimmed;
    } else {
      value = trimmed.slice(0, unitStart);
      var abbreviation = trimmed.slice(unitStart + 1);
      unit = _constants.default.weightUnitAbbreviationAliases[abbreviation.toLowerCase()];
    }

    if ((0, _isUndefined.default)(value) || (0, _isUndefined.default)(unit)) {
      throw 'Invalid weight';
    }

    return Weight.create({
      value: value,
      unit: unit
    });
  };

  _exports.parseWeight = parseWeight;
  var _default = Weight;
  _exports.default = _default;
});