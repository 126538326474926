define("herer-web/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JkyG3sfI",
    "block": "{\"symbols\":[\"@showAdditionalButtons\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"date-picker\"],[10,\"class\",\"row\"],[10,\"data-test-group\",\"date_picker\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"usePlaceholders\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"date-picker-title\"],[8],[0,\"\\n        \"],[1,[22,\"startDateTitle\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"date-input\",null,[[\"date\",\"placeholder\",\"editable\",\"disabledDates\",\"min\",\"max\",\"ccrs\",\"testSelector\",\"onChange\",\"showAdditionalButtons\"],[[24,[\"startDate\"]],[24,[\"startDatePlaceholder\"]],true,[24,[\"disabledDates\"]],[24,[\"startMinDate\"]],[24,[\"startMaxDate\"]],[24,[\"ccrs\"]],\"start_date\",[28,\"action\",[[23,0,[]],\"changeDate\",[24,[\"onStartDateChange\"]]],null],[23,1,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"usePlaceholders\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"date-picker-title\"],[8],[0,\"\\n        \"],[1,[22,\"endDateTitle\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"date-input\",null,[[\"date\",\"placeholder\",\"editable\",\"disabledDates\",\"min\",\"max\",\"ccrs\",\"testSelector\",\"onChange\",\"showAdditionalButtons\"],[[24,[\"endDate\"]],[24,[\"endDatePlaceholder\"]],true,[24,[\"disabledDates\"]],[24,[\"endMinDate\"]],[24,[\"endMaxDate\"]],[24,[\"ccrs\"]],\"end_date\",[28,\"action\",[[23,0,[]],\"changeDate\",[24,[\"onEndDateChange\"]]],null],[23,1,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/date-picker.hbs"
    }
  });

  _exports.default = _default;
});