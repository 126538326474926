define("herer-web/templates/medical-sales-reports-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O5akavax",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"has-permission\",[\"view_sales_by_reports\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"currentLocation\",\"medical\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"side-nav-header\"],[8],[0,\"\\n      Medical Sales & Spending\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"nav nav-pills nav-stacked\"],[8],[0,\"\\n      \"],[1,[28,\"medical-sales-by-links\",null,[[\"currentLocation\"],[[24,[\"currentLocation\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/medical-sales-reports-nav.hbs"
    }
  });

  _exports.default = _default;
});