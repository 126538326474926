define("herer-web/components/menu-feeds/feed-preview-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    menuFeed: null,
    showModal: Ember.computed.bool('menuFeed'),
    actions: {
      loadMore: function loadMore() {// NOOP
      }
    }
  });

  _exports.default = _default;
});