define("herer-web/routes/drawer-adjustments", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/models/paged-array"], function (_exports, _authenticatedFullPage, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    modelName: 'drawer-adjustment',
    launchDarkly: Ember.inject.service(),
    model: function model() {
      var store = this.get('store');
      return _pagedArray.TimePagedArray.create({
        store: store,
        model: this.get('modelName')
      });
    },
    setupController: function setupController(controller, model) {
      this.store.findRecord('shift', controller.shiftId).then(function (shift) {
        controller.set('shift', shift);
        controller.shiftChanged();
      });

      this._super(controller, model);

      model.set('filters', controller.get('filters'));
    }
  });

  _exports.default = _default;
});