define("herer-web/components/tax-item-schedule", ["exports", "herer-web/constants", "moment"], function (_exports, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    schedule: null,
    booleanTypes: _constants.default.booleanTypes,
    launchDarkly: Ember.inject.service(),
    inclusiveOptions: [{
      value: true,
      name: 'Yes (Inclusive tax)'
    }, {
      value: false,
      name: 'No (Exclusive tax)'
    }],
    calculateWithSubtotalOptions: [{
      value: false,
      name: 'Discounted'
    }, {
      value: true,
      name: 'Undiscounted'
    }],
    withInclusiveFix: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('bw-cws-inclusive-fix');
    }),
    effectiveStartIsDirty: Ember.computed('schedule.effective_start', function () {
      return Ember.isPresent(this.get('schedule').changedAttributes().effective_start);
    }),
    effectiveEndIsDirty: Ember.computed('schedule.effective_end', function () {
      return Ember.isPresent(this.get('schedule').changedAttributes().effective_end);
    }),
    disableCalculateWithSubtotal: Ember.computed.or('fieldDisabled', 'schedule.inclusive'),
    canEdit: Ember.computed('schedule.isNew', 'schedule.effective_start', 'effectiveStartIsDirty', function () {
      return this.get('schedule.isNew') || this.get('effectiveStartIsDirty') || this.get('schedule.effective_start') >= (0, _moment.default)().toDate();
    }),
    canEditEnd: Ember.computed('schedule.isNew', 'schedule.effective_end', 'effectiveEndIsDirty', function () {
      return this.get('canEdit') || this.get('effectiveEndIsDirty') || !Ember.isPresent(this.get('schedule.effective_end')) || Ember.isPresent(this.get('schedule.effective_end')) && this.get('schedule.effective_end') >= (0, _moment.default)().toDate();
    }),
    fieldDisabled: Ember.computed('launchDarkly.isReady', 'canEdit', function () {
      return !this.get('canEdit');
    }),
    endFieldDisabled: Ember.computed('launchDarkly.isReady', 'canEditEnd', function () {
      return !this.get('canEditEnd');
    }),
    scheduleExclusive: Ember.computed.not('schedule.inclusive'),
    actions: {
      remove: function remove() {
        this.onRemove(this.get('schedule'));
      },
      updateInclusive: function updateInclusive(inclusive) {
        this.set('schedule.inclusive', inclusive);

        if (inclusive === true && this.get('withInclusiveFix')) {
          this.send('updateCalculateWithSubtotal', false);
        }
      },
      setRate: function setRate(value) {
        this.set('schedule.rate', value);
      },
      updateCalculateWithSubtotal: function updateCalculateWithSubtotal(calculateWithSubtotal) {
        this.set('schedule.calculate_with_subtotal', calculateWithSubtotal);
      },
      setField: function setField(field, value) {
        this.get('schedule').set(field, value);
      }
    }
  });

  _exports.default = _default;
});