define("herer-web/mixins/deferred-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // scriptSrc: String is a required parameter of the inheritor
    scriptIsLoading: false,
    scriptHasLoaded: false,
    loadScript: function loadScript() {
      var _this = this;

      var elementAttributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.get('scriptHasLoaded')) {
        return Ember.RSVP.resolve(true);
      }

      this.set('scriptIsLoading', true);
      var el = document.createElement('script');
      var head = document.head || document.getElementsByTagName('head')[0];
      el.src = this.get('scriptSrc');
      Object.keys(elementAttributes).forEach(function (attr) {
        el.setAttribute(attr, elementAttributes[attr]);
      });
      return new Ember.RSVP.Promise(function (resolve) {
        el.addEventListener('load', function () {
          _this.set('scriptHasLoaded', true);

          _this.set('scriptIsLoading', false);

          resolve(true);
        }, false);
        head.insertBefore(el, head.firstChild);
      });
    }
  });

  _exports.default = _default;
});