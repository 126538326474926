define("herer-web/routes/state-link/manual-ccrs", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route", "herer-web/utils/get-active-state-credential", "moment"], function (_exports, _authenticatedSideNav, _pageableRoute, _getActiveStateCredential, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    sideNavTemplateName: 'inventory-nav',
    launchDarkly: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        if (!_this.get('authManager.currentLocation.user_managed_compliance')) {
          _this.replaceWith('state-link.auto');
        }

        if (!(_this.get('authManager.currentLocation.isWashington') && _this.get('launchDarkly').can('enable-ccrs'))) {
          _this.replaceWith('state-link.manual');
        }
      });
    },
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        stateCredential: (0, _getActiveStateCredential.default)(store)
      });
    },
    setupController: function setupController(controller, stateCredential) {
      var start_date = controller.start_date,
          end_date = controller.end_date;

      if (start_date && end_date) {
        if ((0, _moment.default)(start_date) <= (0, _moment.default)(end_date)) {
          controller.set('startDate', start_date);
          controller.set('endDate', end_date);
          controller.set('startMaxDate', end_date);
        } else {
          controller.set('startDate', end_date);
          controller.set('endDate', end_date);
          controller.set('startMaxDate', end_date);
        }
      } else {
        controller.set('startDate', (0, _moment.default)().subtract(7, 'd'));
        controller.set('endDate', (0, _moment.default)());
      }

      controller.set('stateCredential', stateCredential);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('startDate', undefined);
        controller.set('endDate', undefined);
        controller.set('report', undefined);
      }
    }
  });

  _exports.default = _default;
});