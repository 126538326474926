define("herer-web/templates/qs-reports/customer-intelligence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BwvO1+Ko",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Customer Intelligence\",\"Shows you more insights on your customer’s behaviors. <br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887055281811-Customer-Intelligence-Understand-Customer-Behavior-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"customer_intelligence_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"CustomerIntelligenceReport\",\"customer_intelligence_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/customer-intelligence.hbs"
    }
  });

  _exports.default = _default;
});