define("herer-web/services/query-param-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    parseQueryString: function parseQueryString() {
      return new window.URLSearchParams(window.location.search);
    },
    cleanParam: function cleanParam(params, key) {
      if (Ember.isEmpty(params) || Ember.isEmpty(key)) {
        return;
      }

      var param = params.get(key);

      if (Ember.isEmpty(param)) {
        return;
      }

      return decodeURIComponent(param);
    },
    get: function get() {
      var _this = this;

      var whitelist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var params = this.parseQueryString();
      var cleanedParams = {};
      whitelist.forEach(function (key) {
        var value = _this.cleanParam(params, key);

        if (Ember.isPresent(value)) {
          cleanedParams[key] = value;
        }
      });
      return cleanedParams;
    },
    set: function set(newParams) {
      var params = this.parseQueryString();
      Object.keys(newParams).forEach(function (key) {
        if (Ember.isPresent(newParams[key])) {
          params.set(key, newParams[key]);
        } else {
          params.delete(key);
        }
      });
      var url = window.location.pathname;
      var searchQuery = params.toString();

      if (searchQuery) {
        url += "?".concat(searchQuery);
      }

      window.history.replaceState({}, '', url);
    }
  });

  _exports.default = _default;
});