define("herer-web/serializers/discount-plan", ["exports", "@ember-data/serializer/rest", "active-model-adapter", "herer-web/constants"], function (_exports, _rest, _activeModelAdapter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      conditions: {
        embedded: 'always'
      }
    },
    normalize: function normalize(type, data, key) {
      if (data.calculation_type === _constants.default.discountCalculationType.percent) {
        data.calculation_value = Math.round(data.calculation_value * 100);
      }

      return this._super(type, data, key);
    },
    serialize: function serialize(record, options) {
      var json = this._super(record, options);

      if (json.calculation_type === _constants.default.discountCalculationType.percent) {
        json.calculation_value /= 100;
      }

      return json;
    }
  });

  _exports.default = _default;
});