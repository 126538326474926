define("herer-web/models/customer", ["exports", "@ember-data/model", "herer-web/models/person"], function (_exports, _model, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    location_id: (0, _model.attr)('string'),
    account_id: (0, _model.attr)('string'),
    id_number: (0, _model.attr)('string'),
    isPatient: false,
    isCustomer: true,
    isEmployee: false
  });

  _exports.default = _default;
});