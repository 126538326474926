define("herer-web/routes/reporting-failures", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedFullPage, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_pageableRoute.default, {
    modelName: 'reporting_failure'
  });

  _exports.default = _default;
});