define("herer-web/models/reporting-failure", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    lotNumber: (0, _model.attr)('string'),
    failedAt: (0, _model.attr)('date'),
    resolvedAt: (0, _model.attr)('date'),
    reportableType: (0, _model.attr)('string'),
    failureEvent: (0, _model.attr)('string'),
    failureEventInfo: (0, _model.attr)('string'),
    responseBody: (0, _model.attr)('string'),
    responseCode: (0, _model.attr)('string'),
    tags: (0, _model.attr)(),
    resolve: function resolve() {
      this.set('resolvedAt', new Date());
      return this.save();
    }
  });

  _exports.default = _default;
});