define("herer-web/controllers/roles", ["exports", "herer-web/mixins/context", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/pageable"], function (_exports, _context, _alertUnsavedController, _pageable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, _pageable.default, {
    rolesSorting: ['name'],
    sortedRoles: Ember.computed.sort('model', 'rolesSorting'),
    showSaveMessage: false,
    showErrorMessage: false,
    actions: {
      onCreate: function onCreate(newRole) {
        newRole.set('permissions', []);
      }
    }
  });

  _exports.default = _default;
});