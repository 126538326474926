define("herer-web/templates/components/id-detection/id-detection-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yWQgBnf/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"id-detection-input\"],[10,\"data-test-container\",\"id_detection_input\"],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"value\",[22,\"value\"]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"data-test-input\",\"quick_filter\"],[10,\"class\",\"form-control search-field\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasBothIdDetectionMethods\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ikons\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"title\",\"Id Scanner\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[10,\"data-test-action\",\"id_scanner\"],[10,\"class\",\"fgb-scanner fa fa-scanner\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"title\",\"Id Swipe\"],[11,\"class\",[29,[\"fa fa-id-card \",[22,\"statusClass\"]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasOnlySwipe\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ikon\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"title\",\"Id Swipe\"],[11,\"class\",[29,[\"fa fa-id-card \",[22,\"statusClass\"]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasOnlyScan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ikon\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"title\",\"Id Scanner\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[10,\"data-test-action\",\"id_scanner\"],[10,\"class\",\"fgb-scanner fa fa-scanner\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/id-detection/id-detection-input.hbs"
    }
  });

  _exports.default = _default;
});