define("herer-web/components/packages/package-list", ["exports", "herer-web/mixins/context", "herer-web/mixins/pageable", "herer-web/mixins/table-sorts", "herer-web/mixins/infinite-scroll", "herer-web/mixins/lot-number-formatter", "herer-web/constants", "bootbox"], function (_exports, _context, _pageable, _tableSorts, _infiniteScroll, _lotNumberFormatter, _constants, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var mixins = [_context.default, _pageable.default, _infiniteScroll.default, _lotNumberFormatter.default, _tableSorts.default];
  var packageActions = Object.freeze({
    'close': 'close' // NOTE: These actions can be uncommented and refactored in when
    // those functions are touched again by future work
    // 'reopen': 'reopen',
    // 'commitDestroy': 'commit_destroy',
    // 'revertDestroy': 'revert_destroy',
    // 'scheduleDestroy': 'schedule_destroy'

  });

  var updatePackageSuccessMessages = _defineProperty({}, packageActions.close, 'Package close successful.');

  var _default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    toast: Ember.inject.service(),
    http: Ember.inject.service(),
    sortTable: 'packages',
    packageToSchedule: null,
    packageToClose: null,
    packageToReopen: null,
    packageToAdjust: null,
    packageToSplit: null,
    packageToReconcile: null,
    packageStatuses: _constants.default.packageStatuses,
    isSaving: false,
    stateCredential: null,
    packages: [],
    selectedPackages: [],
    errorMessages: null,
    model: Ember.computed.alias('packages'),
    onSelectedPackagesChange: null,
    onMergeSelectedPackages: null,
    isPackageAdjusterOpen: false,
    isPackageCloserOpen: false,
    isPackageSplitterOpen: false,
    isPackageReopenerOpen: false,
    isPackageDestroySchedulerOpen: false,
    cannotMergeSelectedPackages: Ember.computed('selectedPackages.length', function () {
      return this.get('selectedPackages').length < 2;
    }),
    availableFilters: Ember.computed(function () {
      return [{
        label: 'Status',
        type: 'select',
        query: 'by_status',
        options: this.get('packageStatuses'),
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: "All ".concat(this.get('lotOrPackage'), "s"),
        defaultFilter: true
      }, {
        label: this.get('lotNumberLabel'),
        type: 'text',
        query: 'by_lot_numbers',
        defaultFilter: true,
        tooltipText: 'Enter Package ID\'s, separated with a comma or space. ' + 'You can enter the entire ID or only part of it.',
        placeholder: 'Ex: 0001, 00002'
      }];
    }),
    availableSorts: Ember.computed('packages.sortBy', 'packages.sortDirection', 'showCheckboxes', function () {
      var availableSorts = [{
        prompt: 'Status',
        class: 'col-md-2',
        field: 'status',
        direction: null
      }, {
        prompt: this.get('lotNumberLabel'),
        class: this.get('showCheckboxes') ? 'col-md-3' : 'col-md-4',
        field: 'lot_number',
        direction: null
      }, {
        prompt: 'Quantity',
        class: 'col-md-2',
        field: 'quantity_value',
        direction: null
      }];
      return this.updateSorts(availableSorts);
    }),
    showCheckboxes: Ember.computed(function () {
      return this.get('featureManager').can('package-merge');
    }),
    init: function init() {
      this._super();

      this.set('selectedPackages', []);
    },
    updatePackageStatus: function updatePackageStatus(aPackage, action, params) {
      var _this = this;

      var http = Ember.get(this, 'http');
      this.set('isSaving', true);
      this.set('errorMessages', null);
      var errorMessages = null;
      return http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/packages/").concat(aPackage.get('id'), "/").concat(action), params).then(function () {
        aPackage.reload();

        _this.set('packageToSchedule', null);

        _this.set('packageToClose', null);

        _this.set('packageToReopen', null);

        _this.set('isPackageCloserOpen', false);

        _this.set('isPackageReopenerOpen', false);
      }).catch(function (response) {
        var responseJSON = response.data;
        var responseText = response.statusText;
        errorMessages = responseJSON ? responseJSON.errors : [responseText];

        _this.set('errorMessages', errorMessages);

        if (_this.isTransferredOutReopenFailure(action, errorMessages[0]) || _this.isTransferredOutCloseFailure(action, errorMessages[0])) {
          aPackage.reload();
        }
      }).finally(function () {
        _this.set('isSaving', false);

        if (!errorMessages && updatePackageSuccessMessages[action]) {
          _this.get('toast').success(updatePackageSuccessMessages[action]);
        }
      });
    },
    isTransferredOutReopenFailure: function isTransferredOutReopenFailure(action, errorMessage) {
      if (action !== 'reopen') {
        return false;
      }

      var message = errorMessage['message'] ? errorMessage['message'] : errorMessage;
      return message.includes('not found in Metrc');
    },
    isTransferredOutCloseFailure: function isTransferredOutCloseFailure(action, errorMessage) {
      if (action !== 'close') {
        return false;
      }

      var message = errorMessage['message'] ? errorMessage['message'] : errorMessage;
      return message.includes('is transferred out in Metrc');
    },
    hideSplitter: function hideSplitter() {
      this.set('packageToSplit', null);
      this.set('isPackageSplitterOpen', false);
    },
    actions: {
      filterParamsChanged: function filterParamsChanged(filterParams) {
        if (filterParams.by_lot_numbers) {
          filterParams.by_lot_numbers = filterParams.by_lot_numbers.replace(/\s+/g, ',').split(',').filter(function (ln) {
            return ln.length > 0;
          }).join(',');
        }

        this.set('packages.filters', filterParams);
      },
      filterValuesChanged: function filterValuesChanged() {},
      adjust: function adjust(aPackage) {
        this.set('packageToAdjust', aPackage);
        this.set('isPackageAdjusterOpen', true);
      },
      hideAdjuster: function hideAdjuster() {
        this.set('packageToAdjust', null);
        this.set('isPackageAdjusterOpen', false);
      },
      reconcile: function reconcile(aPackage) {
        this.set('packageToReconcile', aPackage);
      },
      hideReconciler: function hideReconciler() {
        this.set('packageToReconcile', null);
      },
      unscheduleDestroy: function unscheduleDestroy(aPackage) {
        var _this2 = this;

        var lotOrPackage = this.get('lotOrPackage');
        var message = "Are you sure you want to cancel the destruction of this ".concat(lotOrPackage, "?");

        _bootbox.default.confirm(message, function (result) {
          if (result) {
            _this2.updatePackageStatus(aPackage, 'revert_destroy');
          }
        });
      },
      showDestroyScheduler: function showDestroyScheduler(aPackage) {
        this.set('packageToSchedule', aPackage);
        this.set('isPackageDestroySchedulerOpen', true);
      },
      hideDestroyScheduler: function hideDestroyScheduler() {
        this.set('packageToSchedule', null);
        this.set('isPackageDestroySchedulerOpen', false);
      },
      scheduleDestroy: function scheduleDestroy(aPackage, params) {
        this.updatePackageStatus(aPackage, 'schedule_destroy', params);
        this.set('isPackageDestroySchedulerOpen', false);
      },
      commitDestroy: function commitDestroy(aPackage) {
        var _this3 = this;

        var message = "Are you sure you want to destroy this ".concat(this.get('lotOrPackage'), "?");

        _bootbox.default.confirm(Ember.Handlebars.Utils.escapeExpression(message), function (result) {
          if (result) {
            _this3.updatePackageStatus(aPackage, 'commit_destroy');
          }
        });
      },
      hideCloser: function hideCloser() {
        if (Ember.isEmpty(this.get('packageToClose'))) {
          return;
        }

        this.set('errorMessages', null);
        this.set('packageToClose', null);
        this.set('isPackageCloserOpen', false);
      },
      showPackageCloser: function showPackageCloser(aPackage) {
        this.set('packageToClose', aPackage);
        this.set('isPackageCloserOpen', true);
      },
      closePackage: function closePackage(aPackage, params) {
        this.updatePackageStatus(aPackage, packageActions.close, params);
      },
      hideReopener: function hideReopener() {
        if (Ember.isEmpty(this.get('packageToReopen'))) {
          return;
        }

        this.set('errorMessages', null);
        this.set('packageToReopen', null);
        this.set('isPackageReopenerOpen', false);
      },
      showPackageReopener: function showPackageReopener(aPackage) {
        this.set('packageToReopen', aPackage);
        this.set('isPackageReopenerOpen', true);
      },
      reopenPackage: function reopenPackage(aPackage) {
        this.updatePackageStatus(aPackage, 'reopen');
      },
      selectPackage: function selectPackage(pkg) {
        var selectedPackages = this.get('selectedPackages');
        selectedPackages.pushObject(pkg);
        this.onSelectedPackagesChange && this.onSelectedPackagesChange(selectedPackages);
      },
      deselectPackage: function deselectPackage(pkg) {
        var selectedPackages = this.get('selectedPackages');
        selectedPackages.removeObject(pkg);
        this.onSelectedPackagesChange && this.onSelectedPackagesChange(selectedPackages);
      },
      split: function split(aPackage) {
        this.set('packageToSplit', aPackage);
        this.set('isPackageSplitterOpen', true);
      },
      onSplit: function onSplit() {
        this.get('model').fetchObjects();
        this.hideSplitter();
      },
      onCancelSplit: function onCancelSplit() {
        this.hideSplitter();
      }
    }
  }]));

  _exports.default = _default;
});