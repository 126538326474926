define("herer-web/components/lot-row", ["exports", "herer-web/mixins/context", "herer-web/mixins/inventory-weights", "herer-web/mixins/location-features", "herer-web/constants", "big.js"], function (_exports, _context, _inventoryWeights, _locationFeatures, _constants, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, _inventoryWeights.default, _locationFeatures.default, {
    init: function init() {
      this._super();

      if (this.get('supportsCCRS') && this.get('pendingManifest') && this.get('lot.quantity.value') == 0) {
        this.set('lot.quantity.value', this.get('lot.expected_quantity'));
      }
    },
    classNames: ['row', 'remote-lot'],
    classNameBindings: ['done', 'uneditable'],
    store: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    traceable: false,
    traceableManifest: false,
    manifestEditable: true,
    pendingManifest: false,
    removeLot: null,
    showProductSelector: null,
    lot: null,
    changeSku: false,
    showMoreDataEntry: false,
    placesEnabled: Ember.computed.alias('currentLocation.places_enabled'),
    nonTraceableManifest: Ember.computed.not('traceableManifest'),
    pendingTraceableManifest: Ember.computed.and('pendingManifest', 'traceableManifest'),
    pendingNonTraceableManifest: Ember.computed.and('pendingManifest', 'nonTraceableManifest'),
    canRemoveLot: Ember.computed('lot.isNew', 'nonTraceableManifest', function () {
      return this.get('lot.isNew') && this.get('nonTraceableManifest');
    }),
    isWeight: Ember.computed.not('lot.quantity.isUnit'),
    quantityMismatch: Ember.computed('lot.quantity.value', function () {
      return Ember.isPresent(this.get('lot.quantity')) && Ember.isPresent(this.get('lot.expected_quantity')) && !this.get('lot.expected_quantity').eq(this.get('lot.quantity'));
    }),
    quantityIsZero: Ember.computed('lot.quantity.value', function () {
      return Ember.isEmpty(this.get('lot.quantity')) || new _big.default(0).eq(this.get('lot.quantity.value'));
    }),
    disableQuantity: Ember.computed('canEditQuantity', 'lot.{isNew,hasLotNumber,product}', function () {
      var notNewMjInventoryLotAdjustment = !this.get('lot.isNew') && this.get('lot.hasLotNumber');

      if (this.get('nonTraceableManifest')) {
        return this.get('lot.product.product_type.is_marijuana') && notNewMjInventoryLotAdjustment;
      } else {
        return !this.get('canEditQuantity');
      }
    }),
    canEditQuantity: Ember.computed.or('pendingManifest', 'nonTraceableManifest'),
    canEditWeight: Ember.computed.and('supportsCCRS', 'pendingManifest'),
    canEditInventoryType: Ember.computed('isManualMjLot', 'lot.isNew', 'supportsCCRS', function () {
      return this.get('isManualMjLot') && this.get('lot.isNew') || this.get('supportsCCRS') && this.get('pendingManifest') && !this.get('lot.inventory_type_string');
    }),
    canEditSampleType: Ember.computed.alias('lot.inventory_item.isMarijuana'),
    canEditMedical: Ember.computed('currentLocation.medical', 'isManualMjLot', 'lot.product.product_type.is_marijuana', function () {
      return this.get('currentLocation.medical') && (this.get('isManualMjLot') || this.get('lot.product.product_type.is_marijuana'));
    }),
    resetMedical: Ember.observer('canEditMedical', function () {
      if (!this.get('canEditMedical')) {
        this.set('lot.medical', false);
      }
    }),
    editable: Ember.computed('lot.newInventory', 'manifestEditable', 'quantityIsZero', 'canEditQuantity', function () {
      if (this.get('isTransferredOut')) {
        return false;
      }

      var manifestEditable = this.get('manifestEditable');
      var canEditQuantity = this.get('canEditQuantity');
      return this.get('lot.newInventory') && manifestEditable && (this.get('hasInventoryItem') || !this.get('quantityIsZero') || canEditQuantity);
    }),
    uneditable: Ember.computed.not('editable'),
    done: Ember.computed('lot.{product,purchase_price,sku}', 'quantityIsZero', function () {
      return Ember.isPresent(this.get('lot.product')) && !this.get('quantityIsZero') && Ember.isPresent(this.get('lot.purchase_price')) && Ember.isPresent(this.get('lot.sku'));
    }),
    disableSkuInput: Ember.computed('canEditSku', function () {
      return !this.get('canEditSku') || this.get('searchingForSku');
    }),
    canEditSku: Ember.computed('changeSku', 'lot.{lot_number,sku}', 'nonTraceableManifest', function () {
      return this.get('changeSku') || this.get('lot.lot_number') !== this.get('lot.sku') || this.get('nonTraceableManifest');
    }),
    itemLabel: Ember.computed('lot.quantity.unit', function () {
      return _constants.default.weightUnitNames[this.get('lot.quantity.unit')];
    }),
    useNumberInput: Ember.computed('traceable', 'nonTraceableManifest', function () {
      return this.get('pendingTraceableManifest') || this.get('nonTraceableManifest') && this.get('traceable');
    }),
    setSkuFromLotNumber: Ember.observer('currentLocation', 'lot.{lot_number,hasMjProduct,isNew}', 'nonTraceableManifest', function () {
      var defaultToLotNumberForSku = this.get('nonTraceableManifest') && this.get('currentLocation.isWashington') && this.get('lot.isNew') && this.get('lot.hasMjProduct');

      if (defaultToLotNumberForSku && !this.get('supportsCCRS')) {
        this.set('lot.sku', this.get('lot.lot_number'));
      }
    }),
    canEditLotNumber: Ember.computed('currentLocation', 'isManualMjLot', 'lot.isNew', function () {
      var featureManager = this.get('featureManager');

      if (featureManager.get('traceability') || featureManager.get('create-untraceable-mj-manifest')) {
        return false;
      }

      return this.get('isManualMjLot') && this.get('lot.isNew') && featureManager.can('create-traceable-mj-manifest');
    }),
    isManualMjLot: Ember.computed('currentLocation', 'lot.hasMjProduct', 'nonTraceableManifest', function () {
      var featureManager = this.get('featureManager');
      var missingRemoteLot = Ember.isEmpty(this.get('lot.remote_lot'));
      var hasFeature = !featureManager.can('traceability') || featureManager.can('create-traceable-mj-manifest') || featureManager.can('create-untraceable-mj-manifest');
      return missingRemoteLot && hasFeature && this.get('lot.hasMjProduct') && this.get('nonTraceableManifest');
    }),
    isTransferredOut: Ember.computed.readOnly('lot.inventory_transferred_out'),
    hasProduct: Ember.computed.notEmpty('lot.product'),
    readOnlyTestResults: Ember.computed('currentLocation', function () {
      var featureManager = this.get('featureManager'); // Test results should be read only if we pull them from the state system. The exception is
      // licenses that can manually add traceable inventory should be able to edit these test
      // results since not all inventory comes from the state system.

      if (featureManager.can('can-edit-test-results')) {
        return false;
      }

      return featureManager.can('state-provided-test-results') && !featureManager.can('create-traceable-mj-manifest');
    }),
    isFlowerProduct: Ember.computed('lot.product.product_type.traceability_type', function () {
      var traceabilityType = this.get('lot.product.product_type.traceability_type');
      return traceabilityType === _constants.default.traceabilityType.usableMarijuana;
    }),
    supportsCCRS: Ember.computed(function () {
      return this.get('currentLocation.isWashington') && this.get('launchDarkly').can('enable-ccrs');
    }),
    allowUseByDateField: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('eng-61070-metrc-use-by-date');
    }),
    showTests: Ember.computed.alias('lot.product.product_type.isTraceable'),
    actions: {
      setSku: function setSku(sku) {
        var lot = this.lot;
        Ember.set(lot, 'sku', sku);
      },
      showSkuError: function showSkuError(skuTaken) {
        var reuseSku = this.get('launchDarkly').can('allow-barcode-sku-reuse-in-for-all-cannabis-products', false);

        if (skuTaken && !reuseSku) {
          this.get('lot.errors').add('sku', 'has already been taken');
        }
      },
      changeSku: function changeSku() {
        this.set('changeSku', true);
        return false;
      },
      removeLot: function removeLot(lot) {
        this.removeLot(lot);
      },
      showProductSelector: function showProductSelector(lot) {
        this.showProductSelector(lot);
      },
      setSampleType: function setSampleType(sampleType) {
        if (Ember.isEmpty(sampleType)) {
          sampleType = _constants.default.sampleType.none;
        }

        var isSample = sampleType !== _constants.default.sampleType.none;
        this.set('lot.sample', isSample);
        this.set('lot.sample_type', sampleType);
      },
      toggleMoreDataEntry: function toggleMoreDataEntry() {
        this.set('showMoreDataEntry', !this.get('showMoreDataEntry'));
      }
    }
  });

  _exports.default = _default;
});