define("herer-web/templates/components/device-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yfqxV7Dx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showWarning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"id\",\"device-check-message\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"role\",\"alert\"],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"aria-label\",\"Close\"],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],\"closeBanner\"]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n              ×\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"Please update your Registers\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          We noticed that your Registers are not on a current version.\\n          Please update them soon or they will stop working.\\n        \"],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"href\",\"https://www.greenbits.com/terms-of-service/\"],[8],[0,\"\\n            Greenbits Terms of Service\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"href\",\"https://support.dutchie.com/hc/en-us/articles/12886332509331-Update-your-Register\"],[8],[0,\"\\n            How to Update your Register Software\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/device-check.hbs"
    }
  });

  _exports.default = _default;
});