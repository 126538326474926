define("herer-web/serializers/return-manifest", ["exports", "active-model-adapter", "moment"], function (_exports, _activeModelAdapter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      employee: {
        dob_day: false,
        dob_month: false,
        dob_year: false
      }
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (json.employee) {
        json.employee.dob = snapshot.record.get('employee.dob');
      }

      return json;
    },
    normalize: function normalize(_class, data) {
      if (data.employee && data.employee.dob) {
        var dob = (0, _moment.default)(data.employee.dob);
        data.employee.dob_month = dob.month();
        data.employee.dob_day = dob.day();
        data.employee.dob_year = dob.year();
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});