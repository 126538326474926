define("herer-web/models/location", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/utils/color", "moment"], function (_exports, _model, _constants, _color, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isLicenseType = function isLicenseType(licenseTypeName) {
    var onlyTracked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return Ember.computed('license_type', function () {
      var licenseType = this.get('license_type');
      var testMargin = 1000;
      var mappedType = _constants.default.licenseType[licenseTypeName]; // First check for tracked license type.

      if (licenseType === mappedType) {
        return true; // Check for test_* licenses, that are the usual types incremented by 1000 (testMargin var)
        // listed here: https://github.com/greenbits/permafrost-js/blob/master/src/constant.js#L464-L466
      } else if (licenseType > testMargin && licenseType - testMargin === mappedType) {
        return true;
      } // If we're only checking for tracked license types, return false now.


      if (onlyTracked) {
        return false;
      } // Check for untracked license type.


      return licenseType === _constants.default.licenseType["".concat(licenseTypeName, "Untracked")];
    });
  };

  var _default = _model.default.extend({
    activated_at: (0, _model.attr)('date'),
    active: (0, _model.attr)('boolean'),
    active_at: (0, _model.attr)('date'),
    auto_close_inventory: (0, _model.attr)('boolean'),
    auto_logout: (0, _model.attr)('boolean'),
    auto_print_receipts: (0, _model.attr)('boolean'),
    below_cost_warning: (0, _model.attr)('boolean'),
    biotrack_ubi: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    coachability: (0, _model.attr)('number'),
    combine_pricing_tiers: (0, _model.attr)('boolean'),
    combine_pricing_tiers_strategy: (0, _model.attr)('number'),
    created_at: (0, _model.attr)('date'),
    customer_contact_phone_number: (0, _model.attr)('string'),
    default_currency: (0, _model.attr)('string'),
    default_person_group: (0, _model.attr)('string'),
    default_for_sale_place: (0, _model.attr)('string'),
    device_limit: (0, _model.attr)('number'),
    devices_used: (0, _model.attr)('number'),
    employee_tracking: (0, _model.attr)('boolean'),
    users: (0, _model.attr)(),
    user_managed_compliance: (0, _model.attr)('boolean'),
    last_imported_manifests_at: (0, _model.attr)('date'),
    license: (0, _model.attr)(),
    license_number: (0, _model.attr)('string'),
    license_type: (0, _model.attr)('number'),
    medical: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    pending_cancellation: (0, _model.attr)('boolean'),
    personGroups: (0, _model.hasMany)('person-group', {
      async: true
    }),
    payments_enabled: (0, _model.attr)('boolean'),
    places: (0, _model.hasMany)('place', {
      async: true
    }),
    places_enabled: (0, _model.attr)('boolean'),
    primary_email: (0, _model.attr)('string'),
    queue: (0, _model.attr)('boolean'),
    recreational: (0, _model.attr)('boolean'),
    require_customer: (0, _model.attr)('boolean'),
    require_referral_source: (0, _model.attr)('boolean'),
    salesforce_id: (0, _model.attr)('string', {
      readOnly: true
    }),
    session_timeout: (0, _model.attr)('number'),
    shared_tills: (0, _model.attr)('boolean'),
    skip_tax_period_validation: (0, _model.attr)('boolean'),
    state: (0, _model.attr)('string'),
    street: (0, _model.attr)('string'),
    suspended_on: (0, _model.attr)('day'),
    cease_on_suspension: (0, _model.attr)('boolean'),
    technical_aptitude: (0, _model.attr)('number'),
    test_account: (0, _model.attr)('boolean'),
    timezone: (0, _model.attr)('string'),
    verify_age: (0, _model.attr)('boolean'),
    zip_code: (0, _model.attr)('string'),
    test_results_display: (0, _model.attr)('number'),
    weigh_station_enabled: (0, _model.attr)('boolean'),
    billing_email: (0, _model.attr)('string'),
    billing_first_name: (0, _model.attr)('string'),
    billing_last_name: (0, _model.attr)('string'),
    billing_cc_emails: (0, _model.attr)('string'),
    billing_phone_number: (0, _model.attr)('string'),
    billing_frequency: (0, _model.attr)('number', {
      readOnly: true
    }),
    billing_account_number: (0, _model.attr)('string', {
      readOnly: true
    }),
    licenseTypeName: Ember.computed('license_type', function () {
      var _this = this;

      var license = _constants.default.licenseTypes.find(function (item) {
        return item.value === _this.get('license_type');
      });

      return license ? license.name : '';
    }),
    isAlaska: isLicenseType('alaska'),
    isArizona: isLicenseType('arizona'),
    isCalifornia: isLicenseType('california', false),
    isCanada: Ember.computed('license_type', function () {
      var canadaProvinces = ['alberta', 'britishColumbia', 'manitoba', 'newBrunswick', 'newfoundlandAndLabrador', 'northwestTerritories', 'novaScotia', 'nunavut', 'ontario', 'princeEdwardIsland', 'quebec', 'saskatchewan', 'yukon'];
      var licenseType = this.get('license_type');
      return canadaProvinces.some(function (licenseTypeName) {
        return licenseType === _constants.default.licenseType[licenseTypeName];
      });
    }),
    isColorado: isLicenseType('colorado'),
    isGlass: isLicenseType('glass'),
    isHawaii: isLicenseType('hawaii'),
    isMassachusetts: isLicenseType('massachusetts'),
    isMichigan: isLicenseType('michigan'),
    isMontana: isLicenseType('montana'),
    isNevada: isLicenseType('nevada', false),
    isNewMexico: isLicenseType('newMexico'),
    isOregon: isLicenseType('oregon'),
    isOregonOha: isLicenseType('oregonOha'),
    isUnregulated: isLicenseType('unregulated'),
    isWashington: isLicenseType('washington'),
    isBritishColumbia: isLicenseType('britishColumbia'),
    isOklahoma: isLicenseType('oklahoma', false),
    pendingSuspension: Ember.computed('suspended_on', function () {
      var suspendedOn = this.get('suspended_on');
      return Ember.isPresent(suspendedOn) && (0, _moment.default)(suspendedOn).startOf('day') >= (0, _moment.default)().startOf('day');
    }),
    isSuspended: Ember.computed('suspended_on', function () {
      var suspendedOn = this.get('suspended_on');
      return Ember.isPresent(suspendedOn) && (0, _moment.default)(suspendedOn).startOf('day') <= (0, _moment.default)().startOf('day');
    }),
    tags: Ember.computed('billing_cc_emails', {
      get: function get() {
        if (Ember.isPresent(this.get('billing_cc_emails'))) {
          return this.get('billing_cc_emails').split(',');
        } else {
          return [];
        }
      },
      set: function set(key, value) {
        this.set('billing_cc_emails', value.join(','));
        return value;
      }
    }),
    currentStrategy: Ember.computed('shared_tills', 'employee_tracking', function () {
      var sharedTills = this.get('shared_tills');
      var employeeTracking = this.get('employee_tracking');
      var strategy = 'shared-tills-no-cashier';

      if (!sharedTills && employeeTracking) {
        strategy = 'one-till';
      } else if (sharedTills && employeeTracking) {
        strategy = 'shared-tills-cashier';
      }

      return strategy;
    }),
    medicalOnly: Ember.computed('recreational', 'medical', function () {
      return this.get('medical') && !this.get('recreational');
    }),
    color: Ember.computed('name', function () {
      // Repeatable random color
      //return getHexColorFromString(this.get('name'));
      // Less random color
      return (0, _color.getSchemeColorFromString)(this.get('name'));
    }),
    stateName: Ember.computed('state', function () {
      var state = this.get('state');

      if (Ember.isEmpty(state)) {
        return '';
      } else {
        return _constants.default.statesProvinces.findBy('abbreviation', state).name;
      }
    }),
    stateAbbreviation: Ember.computed('state', function () {
      var state = this.get('state');
      return Ember.isPresent(state) ? state.toLowerCase() : null;
    }),
    transactionLimit: function transactionLimit(traceabilityType, medical) {
      var license = this.get('license');
      var key = medical ? 'medical_limits' : 'recreational_limits';
      return license[key][traceabilityType];
    },
    transactionPotencyLimit: function transactionPotencyLimit(traceabilityType, medical) {
      var license = this.get('license');
      var key = medical ? 'medical_potency_limits' : 'recreational_potency_limits';
      return license[key][traceabilityType];
    },
    pendoized: Ember.computed('id', function () {
      return {
        id: this.get('id'),
        location_name: this.get('name'),
        location_city: this.get('city'),
        location_state: this.get('state'),
        location_zip: this.get('zip_code'),
        location_created_at: this.get('created_at'),
        location_active: this.get('active'),
        location_active_at: this.get('active_at'),
        location_license: this.get('license_type'),
        location_recreational: this.get('recreational'),
        location_medical: this.get('medical'),
        location_suspended_on_date: this.get('suspended_on'),
        location_pending_cancellation: this.get('pending_cancellation'),
        location_last_manifest: this.get('last_imported_manifests_at'),
        location_coachability: this.get('coachability'),
        location_technical_aptitude: this.get('technical_aptitude'),
        location_payments: this.get('payments_enabled'),
        location_user_managed_compliance: this.get('user_managed_compliance')
      };
    }),
    defaultForSalePlace: Ember.computed('places', function () {
      if (this.get('places_enabled')) {
        return this.get('places').findBy('inventory_status', 1);
      }
    }),
    productTestResultsEditable: Ember.computed('test_results_display', function () {
      return this.get('test_results_display') === 0;
    })
  });

  _exports.default = _default;
});