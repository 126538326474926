define("herer-web/mixins/alert-unsaved-route", ["exports", "bootbox"], function (_exports, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    confirmedUnsaved: false,
    actions: {
      transitionWithoutAlert: function transitionWithoutAlert() {
        this.set('confirmedUnsaved', true);
        this.transitionTo.apply(this, arguments);
      },
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.get('confirmedUnsaved')) {
          this.set('confirmedUnsaved', false);
          return true;
        }

        if (this.get('controller.showUnsavedAlert')) {
          transition.abort();
          this.send('confirmTransition', {
            success: function success() {
              var model = _this.get('controller.model');

              if (Ember.isPresent(model)) {
                if (Ember.isArray(model)) {
                  model.forEach(function (item) {
                    if (item.get('hasDirtyAttributes')) {
                      item.rollbackAttributes();
                    }
                  });
                } else {
                  model.rollbackAttributes();
                }
              }

              transition.retry();
            }
          });
          return false;
        } else {
          return true;
        }
      },
      confirmTransition: function confirmTransition(callbacks) {
        var _this2 = this;

        _bootbox.default.dialog({
          message: 'There are unsaved changes on this page. Are you sure you want to leave?',
          buttons: {
            continue: {
              label: 'Yes, continue anyway',
              className: 'btn-default',
              callback: function callback() {
                _this2.set('confirmedUnsaved', true);

                if (callbacks.success) {
                  callbacks.success();
                }
              }
            },
            abort: {
              label: 'No, stay here',
              className: 'btn-primary',
              callback: callbacks.failure
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});