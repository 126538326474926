define("herer-web/components/return-manifest-row", ["exports", "herer-web/constants", "bootbox"], function (_exports, _constants, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    classNames: ['row', 'table-row'],
    isTransferring: false,
    isDestroyingManifest: false,
    returnManifest: null,
    isMarkingCompleteAndAuditing: false,
    isMarkingComplete: false,
    isUploadingToCCRS: false,
    showMarkCompleteButtons: Ember.computed('authManager.currentLocation.isWashington', 'returnManifest.transferred_at', 'launchDarkly.isReady', function () {
      return this.get('authManager.currentLocation.isWashington') && Ember.isEmpty(this.get('returnManifest.transferred_at')) && this.get('launchDarkly').can('eng-23284-allow-manual-return-manifest-transfer');
    }),
    pdfLink: Ember.computed('returnManifest.id', function () {
      var id = this.get('returnManifest.id');
      return "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/return_manifests/").concat(id, ".pdf");
    }),
    wciaLink: Ember.computed('returnManifest.id', function () {
      var id = this.get('returnManifest.id');
      return "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/return_manifests/").concat(id, ".json");
    }),
    isPreLeafReturn: Ember.computed('returnManifest.manifest_id', function () {
      var manifestId = this.get('returnManifest.manifest_id');
      return !manifestId.match(/^WA.+\..+/);
    }),
    contactSupportMessage: function contactSupportMessage() {
      return 'If you still have this issue, chat Support.';
    },
    destroyReturnManifest: function destroyReturnManifest() {
      var _this = this;

      this.set('isDestroyingManifest', true);
      var model = this.get('returnManifest');
      model.destroyRecord().then(function () {
        _this.onDestroy(model);
      }).catch(function () {
        _this.set('isDestroyingManifest', false);

        var message = "Sorry, something went wrong when trying to delete this manifest. " + _this.contactSupportMessage();

        _bootbox.default.alert(message);
      });
    },
    transferReturnManifest: function transferReturnManifest() {
      var _this2 = this;

      var indicator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'isTransferring';
      var endpoint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'transfer';
      var setAsTransfered = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var isTransferableEndpoint = ['transfer', 'mark_transferred', 'upload_to_ccrs'].includes(endpoint);

      if (!isTransferableEndpoint) {
        return false;
      }

      var id = this.get('returnManifest.id');
      var url = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/return_manifests/").concat(id, "/").concat(endpoint);
      this.set(indicator, true);
      return Ember.get(this, 'http').post(url).then(function () {
        _this2.set(indicator, false);

        if (setAsTransfered) {
          _this2.set('returnManifest.transferred_at', new Date());
        }

        _bootbox.default.alert("Action completed successfully!");
      }).catch(function (response) {
        var _response$data;

        var message = "Sorry, something went wrong when trying to transfer this manifest. ".concat(_this2.contactSupportMessage());

        if ((response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.errors) === 'missing phone number') {
          message = 'Customer contact phone number required to submit to CCRS, please set it in Location Settings.';
        }

        _bootbox.default.alert(message);
      }).finally(function () {
        _this2.set(indicator, false);
      });
    },
    showPreLeafMessage: function showPreLeafMessage() {
      var message = "This manifest was submitted before the Leaf Data Systems integration. Please\n      void this manifest and resubmit it.<br><br>" + this.contactSupportMessage();

      _bootbox.default.alert(message);
    },
    showConfirmation: function showConfirmation(options) {
      if (!options) {
        return false;
      }

      _bootbox.default.dialog({
        message: options.message,
        buttons: {
          cancel: {
            label: 'Cancel',
            className: 'btn-default'
          },
          confirm: {
            label: options.button,
            className: 'btn-success',
            callback: options.callback
          }
        }
      });
    },
    supportsCCRS: Ember.computed('authManager.currentLocation', 'launchDarkly', function () {
      return this.get('authManager.currentLocation.isWashington') && this.get('launchDarkly').can('enable-ccrs');
    }),
    actions: {
      destroy: function destroy() {
        var _this3 = this;

        _bootbox.default.dialog({
          message: 'Are you sure you want to delete this return manifest?',
          buttons: {
            cancel: {
              label: 'Cancel',
              className: 'btn-default'
            },
            destroy: {
              label: 'Delete Return Manifest',
              className: 'btn-danger',
              callback: function callback() {
                _this3.destroyReturnManifest();
              }
            }
          }
        });
      },
      transfer: function transfer() {
        if (this.get('isPreLeafReturn')) {
          this.showPreLeafMessage();
        } else {
          this.transferReturnManifest();
        }
      },
      markCompleteAndAudit: function markCompleteAndAudit() {
        var _this4 = this;

        return this.showConfirmation({
          message: 'Are you sure you want to mark this return manifest complete and audit?',
          button: 'Mark Return Manifest Complete & Audit',
          callback: function callback() {
            _this4.transferReturnManifest('isMarkingCompleteAndAuditing');
          }
        });
      },
      markComplete: function markComplete() {
        var _this5 = this;

        return this.showConfirmation({
          message: 'Are you sure you want to mark this return manifest complete?',
          button: 'Mark Return Manifest Complete',
          callback: function callback() {
            _this5.transferReturnManifest('isMarkingComplete', 'mark_transferred');
          }
        });
      },
      uploadManifestToCCRS: function uploadManifestToCCRS() {
        var _this6 = this;

        return this.showConfirmation({
          message: 'Are you sure you want to upload return manifest to CCRS?',
          button: 'Upload return manifest to CCRS',
          callback: function callback() {
            _this6.transferReturnManifest('isUploadingToCCRS', 'upload_to_ccrs', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});