define("herer-web/serializers/person", ["exports", "@ember-data/serializer/rest", "active-model-adapter", "herer-web/constants"], function (_exports, _rest, _activeModelAdapter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      fileAttachments: {
        embedded: 'always'
      },
      personGroups: {
        serialize: 'ids'
      },
      orders: {
        serialize: 'ids'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.people) {
        payload.people.forEach(function (person) {
          _this.normalizePerson(person);
        });
      } else {
        this.normalizePerson(Object.values(payload)[0]);
      }

      return this._super.apply(this, arguments);
    },
    normalizePerson: function normalizePerson(person) {
      var attrs = ['first_name', 'last_name', 'phone', 'email', 'id_number', 'caregiver_id_number'];

      if (person) {
        attrs.forEach(function (attribute) {
          if (person[attribute] === null) {
            person[attribute] = '';
          }
        });
      }
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var type = _constants.default.personTypes.find(function (t) {
        return t.value === snapshot.record.get('type');
      });

      var serialized = this.serialize(snapshot, options);
      serialized.id = snapshot.record.get('id');
      hash[type.name.toLowerCase()] = serialized;
    }
  });

  _exports.default = _default;
});