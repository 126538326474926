define("herer-web/controllers/discount-plans", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _alertUnsavedController, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    showDisabled: false,
    allTags: [],
    productTypes: [],
    roles: [],
    discountCalculationTypeValues: _constants.default.discountCalculationTypeValues,
    booleanTypes: _constants.default.booleanTypes,
    filteredDiscountPlans: Ember.computed('showDisabled', 'model.@each.enabled', 'model.@each.isNew', function () {
      var model = this.get('model');
      var showDisabled = this.get('showDisabled');
      model = model.filter(function (discountPlan) {
        var hasLoyaltyPoints = Ember.isPresent(discountPlan.get('loyaltyPoints'));
        var show = discountPlan.get('enabled') || showDisabled;
        return !hasLoyaltyPoints && show;
      });
      return model.toArray().sort(function (a, b) {
        var result = Ember.compare(b.get('isNew'), a.get('isNew'));

        if (result !== 0) {
          return result;
        }

        return Ember.compare(a.get('name').toLowerCase(), b.get('name').toLowerCase());
      });
    }),
    actions: {
      updateDiscountConditions: function updateDiscountConditions(discountPlan, conditions) {
        var store = this.get('store');
        conditions = conditions.map(function (condition) {
          return store.createRecord('discount-condition', condition);
        });

        if (discountPlan.shouldUpdateConditions(conditions)) {
          discountPlan.set('conditions', conditions);
        }
      },
      showDisabled: function showDisabled() {
        this.set('showDisabled', true);
      },
      hideDisabled: function hideDisabled() {
        this.set('showDisabled', false);
      },
      setCalculationValue: function setCalculationValue(discountPlan, value) {
        discountPlan.set('calculationValue', value);
      },
      onCreate: function onCreate(newDiscountPlan) {
        newDiscountPlan.set('enabled', true);
        newDiscountPlan.set('autoApply', false);
        newDiscountPlan.set('roundToNearestDollar', false);
        newDiscountPlan.set('accessibleRoleIds', []);
        newDiscountPlan.set('calculationValue', 0);
      },
      toggleRole: function toggleRole(discountPlan, roleId, checked) {
        var accessibleRoleIds = discountPlan.get('accessibleRoleIds');

        if (Ember.isPresent(accessibleRoleIds)) {
          accessibleRoleIds = accessibleRoleIds.slice();
        } else {
          accessibleRoleIds = [];
        }

        if (checked) {
          accessibleRoleIds.push(roleId);
          accessibleRoleIds = accessibleRoleIds.uniq();
        } else {
          accessibleRoleIds = accessibleRoleIds.reject(function (id) {
            return id === roleId;
          });
        }

        if (discountPlan.shouldUpdateAccessibleRoleIds(accessibleRoleIds)) {
          discountPlan.set('accessibleRoleIds', accessibleRoleIds);
        }
      },
      updateEnabled: function updateEnabled(discountPlan, archived) {
        discountPlan.set('enabled', !archived);
      },
      updateAutoApply: function updateAutoApply(autoApply, discountPlan) {
        discountPlan.set('autoApply', autoApply);
      },
      updateRoundToNearestDollar: function updateRoundToNearestDollar(roundToNearestDollar, discountPlan) {
        discountPlan.set('roundToNearestDollar', roundToNearestDollar);
      }
    }
  });

  _exports.default = _default;
});