define("herer-web/controllers/state-link/manual-ccrs", ["exports", "herer-web/mixins/export", "herer-web/mixins/pageable", "moment", "herer-web/constants"], function (_exports, _export2, _pageable, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // For filtering/filename
  var exportDateFormat = 'YYYY-MM-DD';
  var ccrsStatusDateFormat = 'MMMM Do YYYY, h:mm:ss a';
  var ccrsStatusDateParamsFormat = 'YYYY-MM-DD HH:mm';

  var _default = Ember.Controller.extend(_pageable.default, _export2.default, {
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    regenerateFiles: false,
    startDate: (0, _moment.default)().subtract(7, 'd').format(exportDateFormat),
    endDate: (0, _moment.default)().format(exportDateFormat),
    startMinDate: (0, _moment.default)('2021-07-01').format(exportDateFormat),
    startMaxDate: (0, _moment.default)().format(exportDateFormat),
    ccrsStatusesStartMinDate: (0, _moment.default)('2021-07-01').format(exportDateFormat),
    ccrsStatusesStartMaxDate: (0, _moment.default)().format(exportDateFormat),
    endMaxDate: (0, _moment.default)().format(exportDateFormat),
    queryParams: ['start_date', 'end_date'],
    autoCCRSEnabled: Ember.computed(function () {
      return this.get('launchDarkly').can('eng-18468-ccrs-automatic-uploader', false);
    }),
    uploadDisabled: Ember.computed(function () {
      return Ember.isEmpty(this.stateCredential.stateCredential.get('license_number'));
    }),
    ccrsStatusesStartDate: (0, _moment.default)().subtract(14, 'd').format(exportDateFormat),
    ccrsStatusesEndDate: (0, _moment.default)().format(exportDateFormat),
    ccrsStatuses: Ember.computed('ccrsStatusesStartDate', 'ccrsStatusesEndDate', function () {
      var timezone = this.get('authManager.currentLocation.timezone');
      var start = (0, _moment.default)(this.get('ccrsStatusesStartDate')).startOf('day').tz(timezone, true).format(ccrsStatusDateParamsFormat);
      var end = (0, _moment.default)(this.get('ccrsStatusesEndDate')).endOf('day').tz(timezone, true).format(ccrsStatusDateParamsFormat);
      var promise = new Promise(function (resolve, reject) {
        return this.get('http').get("".concat(_constants.default.apiHost, "/api/v2/state_link/ccrs_statuses?from_date=").concat(start, "&to_date=").concat(end)).then(function (resp) {
          resolve(resp.data.map(function (ccrsStatus) {
            ccrsStatus.UploadDate = (0, _moment.default)(ccrsStatus.UploadDate).format(ccrsStatusDateFormat);
            ccrsStatus.Success = ccrsStatus.Success ? 'Uploaded' : 'Failed';
            ccrsStatus.CcrsFileName = ccrsStatus.FileName.substring(ccrsStatus.FileName.indexOf('_') + 1);
            return ccrsStatus;
          }));
        }).catch(function (err) {
          reject(err);
        });
      }.bind(this));
      return Ember.ArrayProxy.extend(Ember.PromiseProxyMixin).create({
        promise: promise
      });
    }),
    exportParams: function exportParams() {
      return {
        start_date: (0, _moment.default)(this.startDate).format(exportDateFormat),
        end_date: (0, _moment.default)(this.endDate).format(exportDateFormat),
        regenerate_files: this.regenerateFiles
      };
    },
    actions: {
      updateRegenerateFiles: function updateRegenerateFiles(enabled) {
        this.set('regenerateFiles', enabled);
      },
      setStartDateFilterValue: function setStartDateFilterValue(value) {
        this.set('startDate', value);
        var formattedStartDate = (0, _moment.default)(this.startDate).format(exportDateFormat);
        var formattedEndDate = (0, _moment.default)(this.endDate).format(exportDateFormat);
        this.replaceRoute({
          queryParams: {
            start_date: formattedStartDate,
            end_date: formattedEndDate
          }
        });
      },
      setEndDateFilterValue: function setEndDateFilterValue(value) {
        this.set('endDate', value);
        this.set('startMaxDate', value);
        var formattedStartDate = (0, _moment.default)(this.startDate).format(exportDateFormat);
        var formattedEndDate = (0, _moment.default)(this.endDate).format(exportDateFormat);
        this.replaceRoute({
          queryParams: {
            start_date: formattedStartDate,
            end_date: formattedEndDate
          }
        });
      },
      setCcrsStartDateFilterValue: function setCcrsStartDateFilterValue(value) {
        this.set('ccrsStatusesStartDate', value);
      },
      setCcrsEndDateFilterValue: function setCcrsEndDateFilterValue(value) {
        this.set('ccrsStatusesEndDate', value);
      },
      export: function _export() {
        this.exportCcrsReports(this.exportParams());
      },
      upload: function upload() {
        this.exportCcrsReports(_objectSpread(_objectSpread({}, this.exportParams()), {}, {
          upload: true
        }));
      }
    }
  });

  _exports.default = _default;
});