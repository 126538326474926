define("herer-web/templates/components/crud-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "trRUMggW",
    "block": "{\"symbols\":[\"&attrs\",\"@model\",\"@onEdit\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"row table-row crud-list-row \",[28,\"if\",[[23,0,[\"isSelected\"]],\"selected\"],null]]]],[13,1],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]],[23,2,[]]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"isDirty\"]],\"col-xs-8\",\"col-xs-12\"],null],\" \",[28,\"if\",[[23,0,[\"isDisabled\"]],\"text-muted\"],null]]]],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isDirty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-xs-4 text-right\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"label label-default\"],[8],[0,\"\\n        Unsaved\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/crud-list-row.hbs"
    }
  });

  _exports.default = _default;
});