define("herer-web/components/test-results-item", ["exports", "herer-web/constants", "herer-web/models/weight"], function (_exports, _constants, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featureManager: Ember.inject.service(),
    property: null,
    inventoryItem: null,
    fieldLabel: null,
    showLabel: true,
    isReadOnly: false,
    isRange: false,
    isTotal: false,
    errors: Ember.computed.alias('inventoryItem.errors.[]'),
    displayLabel: Ember.computed('property', 'fieldLabel', function () {
      if (this.get('fieldLabel') || !this.get('isTotal')) {
        return this.get('fieldLabel');
      }

      return "Total ".concat(this.get('property').toUpperCase());
    }),
    // For ranges and totals we wrap the test result in a form-group div
    isRangeOrTotal: Ember.computed('isRange', 'isTotal', function () {
      return this.get('isRange') || this.get('isTotal');
    }),
    testResultErrors: Ember.computed('errors', function () {
      var errors = [];
      errors = errors.concat(this.get("errors.test_results_".concat(this.get('property'))));

      if (this.get('isRange')) {
        errors = errors.concat(this.get("errors.test_results_max_".concat(this.get('property'))));
      }

      return errors.filter(Boolean);
    }),
    testResultType: Ember.computed('property', 'isDirty', function () {
      var testResultType = "test_results_";

      if (this.get('isTotal')) {
        testResultType += "total_".concat(this.get('property'));
      } else {
        testResultType += this.get('property');
      }

      return testResultType;
    }),
    testResultTypeMin: Ember.computed('testResultType', function () {
      return "".concat(this.get('testResultType'), "_min");
    }),
    testResultTypeMax: Ember.computed('testResultType', function () {
      return "".concat(this.get('testResultType'), "_max");
    }),
    // We need the manual isDirty flag since we're using a computed property to grab
    // the current value and if we rely on hasDirtyAttributes it won't recompute
    // the second update between saves
    testResult: Ember.computed('testResultType', function () {
      this.set('isDirty', false);
      var resultTypePath = "inventoryItem.".concat(this.get('testResultType'));

      if (this.get('isTotal')) {
        return this.get("".concat(resultTypePath, ".value"));
      }

      return this.get(resultTypePath);
    }),
    testResultMax: Ember.computed('property', 'isDirty', function () {
      this.set('isDirty', false);
      return this.get("inventoryItem.test_results_max_".concat(this.get('property')));
    }),
    testResultsUnitAbbreviation: Ember.computed.alias('inventoryItem.testResultsUnitAbbreviation'),
    actions: {
      updateTestResult: function updateTestResult(isMax, value) {
        var testResultType = 'test_results_';

        if (this.get('isTotal')) {
          testResultType += 'total_';
          value = _weight.default.create({
            value: value,
            unit: _constants.default.weightUnit.milligram
          });
        } else if (this.get('isRange') && isMax) {
          testResultType += 'max_';
        }

        testResultType += this.get('property');
        this.set('isDirty', true);
        this.set("inventoryItem.".concat(testResultType), value);
      }
    }
  });

  _exports.default = _default;
});