define("herer-web/models/manifest", ["exports", "@ember-data/model", "herer-web/models/weight", "herer-web/constants"], function (_exports, _model, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    remote_manifest_id: (0, _model.attr)('string'),
    vendor: (0, _model.attr)('string'),
    transfer_date: (0, _model.attr)('date'),
    shipment_id: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    charged_on: (0, _model.attr)('date'),
    manifest_type: (0, _model.attr)('number'),
    markup_rate: (0, _model.attr)('number'),
    excise_tax: (0, _model.attr)('number'),
    lot_adjustments: (0, _model.hasMany)('lot_adjustment', {
      async: false
    }),
    // Used by manual mj manifest
    vendor_license_number: (0, _model.attr)('string'),
    lots: Ember.computed.alias('lot_adjustments'),
    firstLotAdjustment: Ember.computed('lot_adjustments', function () {
      return this.get('lot_adjustments.firstObject');
    }),
    status: Ember.computed(function () {
      return _constants.default.remoteManifestStatus.accepted;
    }),
    pending: false,
    accepted: true,
    rejected: false,
    voided: false,
    hasMjLots: Ember.computed('lots.@each.hasMjProduct', function () {
      return this.get('lots').find(function (l) {
        return l.get('hasMjProduct');
      });
    }),
    fillWithRemoteManifest: function fillWithRemoteManifest(remoteManifest, store, useVendorSku) {
      var _this = this;

      this.set('remote_manifest_id', remoteManifest.get('id'));
      this.set('vendor', remoteManifest.get('shipping_trade_name'));
      this.set('vendor_license_number', remoteManifest.get('shipping_license_number'));
      this.set('transfer_date', remoteManifest.get('transfer_date'));
      this.set('shipment_id', remoteManifest.get('shipment_id'));
      this.set('manifest_type', _constants.default.manifestType.newInventory);
      remoteManifest.get('remote_lots').forEach(function (remoteLot) {
        var ext_sku = remoteLot.get('external_sku') || remoteLot.get('sku');
        var sku = useVendorSku ? ext_sku : remoteLot.get('sku');
        var inventory_item = store.createRecord('inventory-item', {
          test_results_thc: remoteLot.get('test_results_thc'),
          test_results_cbd: remoteLot.get('test_results_cbd'),
          test_results_cbn: remoteLot.get('test_results_cbn'),
          test_results_thca: remoteLot.get('test_results_thca'),
          test_results_cbda: remoteLot.get('test_results_cbda'),
          test_results_terpenoid_profile: remoteLot.get('test_results_terpenoid_profile'),
          external_inventory_id: remoteLot.get('external_inventory_id'),
          certificate_of_analysis_url: remoteLot.get('certificate_of_analysis_url')
        });
        var lotAdjustment = store.createRecord('lot_adjustment', {
          remote_lot: remoteLot,
          adjustment_type: _constants.default.lotAdjustmentType.newInventory,
          sku: sku,
          lot_number: remoteLot.get('sku'),
          weight: remoteLot.get('usable_weight'),
          quantity: _weight.default.create({
            value: null,
            unit: remoteLot.get('quantity.unit')
          }),
          sample: remoteLot.get('is_sample'),
          sample_type: remoteLot.get('sample_type'),
          medical: remoteLot.get('is_medical'),
          inventory_item: inventory_item,
          purchase_price: remoteLot.get('purchase_price')
        });

        _this.get('lot_adjustments').pushObject(lotAdjustment);
      });
    }
  });

  _exports.default = _default;
});