define("herer-web/components/x-select", ["exports", "emberx-select/components/x-select"], function (_exports, _xSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _xSelect.default.extend({
    __setDefaultValues: function __setDefaultValues() {
      // for some reason XSelect has changed its behavior to call onChange
      // everytime an initial value is undefined or null.
      // this override *should* not introduce any misbehaviors for us
      // it's fairly safe to do so.
      // in future, we should migrate to ember-power-select
      if (!this.get('value')) {
        return;
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});