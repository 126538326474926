define("herer-web/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2eCpJGM5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container outlet-container\"],[10,\"data-test-container\",\"four_oh_four\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 text-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"four-oh-four\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[10,\"data-test-ux\",\"question_circle\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"\\n        Sorry, we can't find the page you're looking for.\\n      \"],[9],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        If you still have this issue, chat Support.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/four-oh-four.hbs"
    }
  });

  _exports.default = _default;
});