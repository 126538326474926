define("herer-web/components/system-status", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    systemStatus: Ember.inject.service(),
    testMessageStatus: 'Investigating',
    testMessageName: '(CA) This is a test for California only',
    actions: {
      dismissNoticeBanner: function dismissNoticeBanner(id) {
        this.get('systemStatus').dismissStatusBanner(id, 'notice');
      },
      dismissMaintenanceBanner: function dismissMaintenanceBanner(id) {
        this.get('systemStatus').dismissStatusBanner(id, 'maintenance');
      },
      // For debugging and testing System Status Notices
      testIncident: function testIncident() {
        var systemStatusService = this.get('systemStatus');
        var messages = [{
          id: 'test-incident-id-000',
          resolved_at: null,
          status: this.get('testMessageStatus'),
          name: this.get('testMessageName'),
          impact: 'Impact',
          link: 'http://www.greenbits.com/'
        }];
        systemStatusService.updateIncidents(messages);
      },
      testMaintenance: function testMaintenance() {
        var systemStatusService = this.get('systemStatus');
        var messages = [{
          id: 'test-message-id-000',
          resolved_at: null,
          status: this.get('testMessageStatus'),
          name: this.get('testMessageName'),
          shortlink: 'http://www.greenbits.com',
          scheduled_for: Date.now(),
          scheduled_until: Date.now() + 60 * 1000 * 24
        }];
        systemStatusService.updateMaintenance(messages);
      }
    }
  });

  _exports.default = _default;
});