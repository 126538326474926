define("herer-web/components/web-store-install", ["exports", "herer-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    webstoreUrl: _environment.default.APP.webstoreUrl
  });

  _exports.default = _default;
});