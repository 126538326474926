define("herer-web/controllers/reports/sales-by-time", ["exports", "herer-web/controllers/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({
    reportExportFilename: 'sales_by_time.csv',
    report: 'day',
    reportTypes: [{
      value: 'day',
      name: 'Day'
    }, {
      value: 'week',
      name: 'Week'
    }, {
      value: 'month_year',
      name: 'Month'
    }, {
      value: 'quarter_year',
      name: 'Quarter'
    }, {
      value: 'year',
      name: 'Year'
    }]
  });

  _exports.default = _default;
});