define("herer-web/components/manifest/lot-row/lot-number", ["exports", "herer-web/mixins/lot-number-formatter"], function (_exports, _lotNumberFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_lotNumberFormatter.default, {
    lot: null,
    canEditLotNumber: false,
    supportsCCRS: false
  });

  _exports.default = _default;
});