define("herer-web/templates/qs-reports/discount-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gnSvLSe1",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Discount Performance\",\"Shows you more insights on the performance of your deals.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887024068883-Discount-Performance-Analyze-the-Performance-of-Your-Discounts-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"discount_performance_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"DiscountReport\",\"discount_performance_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/discount-performance.hbs"
    }
  });

  _exports.default = _default;
});