define("herer-web/templates/components/state-link-graphs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dx7djaB/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[22,\"stateName\"],false],[0,\" Traceability System Health\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"response-time-graph\"],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n    \"],[7,\"iframe\",true],[11,\"src\",[22,\"dashboardGraphEmbedUrlResponseTime\"]],[11,\"width\",[22,\"graphTwoDayWidth\"]],[10,\"height\",\"250\"],[10,\"frameborder\",\"0\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"current-response-graph\"],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n    \"],[7,\"iframe\",true],[11,\"src\",[22,\"dashboardGraphEmbedUrlCurrent\"]],[11,\"width\",[22,\"graphCurrentWidth\"]],[10,\"height\",\"230\"],[10,\"frameborder\",\"0\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/state-link-graphs.hbs"
    }
  });

  _exports.default = _default;
});