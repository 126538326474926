define("herer-web/components/device-form", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    device: null,
    deviceTypes: _constants.default.deviceTypes
  });

  _exports.default = _default;
});