define("herer-web/mixins/export", ["exports", "herer-web/constants", "bootbox", "herer-web/mixins/taskable"], function (_exports, _constants, _bootbox, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_taskable.default, {
    http: Ember.inject.service(),
    toast: Ember.inject.service(),
    exportProductList: function exportProductList(productFilters) {
      this.exportList('Product', 'products', 'product list', productFilters);
    },
    exportInventoryItemList: function exportInventoryItemList(productFilters) {
      this.exportList('InventoryItem', 'inventory_items', 'inventory item list', productFilters);
    },
    exportLlxList: function exportLlxList() {
      var _this = this;

      var controlName = "exportLlxListDisabled";
      this.set(controlName, true);
      Promise.all([this.exportLeafLogixXlsx(), this.exportLeafLogixSalesCsv()]).finally(function () {
        _this.set(controlName, false);
      });
    },
    exportLeafLogixXlsx: function exportLeafLogixXlsx() {
      var _this2 = this;

      var http = Ember.get(this, 'http');
      var taskName = 'Greenbits to dutchie PoS Data Export';
      return http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/llx/export")).then(function () {
        _this2.showExportSuccessModal(taskName);
      }).catch(function () {
        _this2.showExportFailedModal('exportLeafLogixXlsx', taskName);
      });
    },
    exportLeafLogixSalesCsv: function exportLeafLogixSalesCsv() {
      var _this3 = this;

      var http = Ember.get(this, 'http');
      var taskName = 'Greenbits to dutchie PoS Sales Export';
      return http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/llx/sales_export")).then(function () {
        _this3.showExportSuccessModal(taskName);
      }).catch(function () {
        _this3.showExportFailedModal('exportLeafLogixSalesCsv', taskName);
      });
    },
    exportPackageList: function exportPackageList() {
      if (!this.get('featureManager').can('lots')) {
        this.exportList('Package', 'packages', 'package list');
      } else {
        this.exportList('Package', 'packages', 'lots list');
      }
    },
    exportCcrsReports: function exportCcrsReports(params) {
      var _this4 = this;

      var http = Ember.get(this, 'http');
      var controlName = 'ccrsExportListDisabled';
      var callbackName = 'exportCcrsReports';
      this.set(controlName, true);
      http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/ccrs_reports/export"), params).then(function () {
        _this4.set(controlName, false);

        if (params.upload) {
          _this4.showExportSuccessModal('', 'CCRS Report Export are being uploaded to CCRS system');
        } else {
          _this4.showExportSuccessModal('', 'CCRS Report Export are being processed. They will be available to download in the Task section');
        }
      }).catch(function () {
        _this4.set(controlName, false);

        _this4.showExportFailedModal(callbackName, 'CCRS Report');
      });
    },
    exportList: function exportList(listType, urlName, itemName) {
      var _this5 = this;

      var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var taskJob = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var http = Ember.get(this, 'http');
      var controlName = "export".concat(listType, "ListDisabled");
      var callbackName = "export".concat(listType, "List");
      this.set(controlName, true);

      if (!Ember.isEmpty(this.get('titanEmail'))) {
        params.email_to = this.get('titanEmail');
      }

      http.post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(urlName, "/export"), params).then(function () {
        _this5.set(controlName, false);

        _this5.showExportSuccessModal(itemName);
      }).catch(function () {
        _this5.set(controlName, false);

        _this5.showExportFailedModal(callbackName, itemName);
      });
    },
    showExportSuccessModal: function showExportSuccessModal(itemName, fullMessage) {
      _bootbox.default.dialog({
        message: fullMessage || "You should receive an email shortly with your exported ".concat(itemName, "."),
        buttons: {
          cancel: {
            label: 'OK'
          }
        }
      });
    },
    showExportFailedModal: function showExportFailedModal(callbackName, itemName) {
      var _this6 = this;

      _bootbox.default.dialog({
        message: "Sorry, something went wrong when trying to export your ".concat(itemName, "."),
        buttons: {
          cancel: {
            label: 'Cancel'
          },
          tryAgain: {
            label: 'Try Again',
            className: 'btn-primary',
            callback: function callback() {
              _this6.get(callbackName);
            }
          }
        }
      });
    }
  });

  _exports.default = _default;
});