define("herer-web/components/date-input", ["exports", "shortcut-buttons-flatpickr", "moment"], function (_exports, _shortcutButtonsFlatpickr, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MASK_DATE_FORMATS = {
    'Y-m-d': 'yyyy-mm-dd',
    'm/d/Y': 'mm/dd/yyyy'
  };
  var MOMENT_DATE_FORMATS = {
    'Y-m-d': 'YYYY-MM-DD',
    'm/d/Y': 'MM/DD/YYYY'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    flatpickFormat: 'Y-m-d',
    showAdditionalButtons: true,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'inputMaskOption', {
        inputFormat: this.inputMaskFormat,
        outputFormat: this.inputMaskFormat,
        clearIncomplete: true,
        max: this.maxDate // inputmask library has a bug masking the date when minimum date is
        // provided and date format is Y-m-d
        // min: this.minDate

      });
      Ember.set(this, 'randomId', Ember.guidFor(this));
    },
    maxDate: Ember.computed('dateFormat', 'max', function () {
      if (this.max) {
        return (0, _moment.default)(this.max).format(MOMENT_DATE_FORMATS[this.dateFormat]);
      }

      return null;
    }),
    minDate: Ember.computed('dateFormat', 'min', function () {
      if (this.min) {
        return (0, _moment.default)(this.min).format(MOMENT_DATE_FORMATS[this.dateFormat]);
      }

      return null;
    }),
    inputMaskFormat: Ember.computed('dateFormat', function () {
      return MASK_DATE_FORMATS[this.dateFormat];
    }),
    dateFormat: Ember.computed.or('format', 'flatpickFormat'),
    _value: Ember.computed('stringDate', {
      get: function get() {
        return this.stringDate;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    plugins: Ember.computed("showAdditionalButtons", function () {
      var _this = this;

      return [(0, _shortcutButtonsFlatpickr.default)({
        button: [this.showAdditionalButtons && {
          label: 'Today'
        }].filter(Boolean),
        onClick: function onClick(index, fp) {
          var timestampWithoutHours = new Date().setHours(0, 0, 0);

          switch (index) {
            case 0:
              _this.changeDate(new Date(timestampWithoutHours));

              fp.close();
              break;
          }
        }
      })];
    }),
    datesToDisable: Ember.computed('disabledDates', function () {
      var disabledDates = this.get('disabledDates');
      return disabledDates ? disabledDates : [];
    }),
    displayDate: Ember.computed('date', function () {
      var date = this.date;

      if (!date) {
        return null;
      }

      if (_moment.default.isMoment(date)) {
        return date.toDate();
      }

      return date;
    }),
    stringDate: Ember.computed('date', 'dateFormat', function () {
      var date = this.date;

      if (!date) {
        return undefined;
      }

      return (0, _moment.default)(date).format(MOMENT_DATE_FORMATS[this.dateFormat]);
    }),
    actions: {
      changeDate: function changeDate(dates, dateString) {
        if (dateString === "") {
          return this.changeDate(dateString);
        }

        this.changeDate(dates[0]);
      },
      initializePicker: function initializePicker(_selectedDates, _dateString, flatpickrInstance) {
        Ember.set(this, 'flatpickrInstance', flatpickrInstance);
      },
      openPicker: function openPicker(e) {
        this.flatpickrInstance.open(e, document.querySelector("#".concat(this.randomId)));
      },
      updateInputMask: function updateInputMask(value) {
        if (value === '') {
          this.flatpickrInstance.clear();
        }

        if (this.ccrs != true) {
          Ember.set(this, '_value', value);
        }
      },
      handleBlur: function handleBlur(event) {
        return this.handleBlur(event);
      },
      handleEnter: function handleEnter(event) {
        if (event.keyCode === 13) {
          this.handleBlur(event);
          this.flatpickrInstance.close();
        }
      }
    },
    handleBlur: function handleBlur(event) {
      if (this.isCalendarElement(event)) {
        return;
      }

      if (this._value && this.isValidDate(this._value)) {
        this.changeDate(this._value);
      } else {
        Ember.set(this, '_value', this.stringDate);
      }
    },
    changeDate: function changeDate(date) {
      this.onChange && this.onChange(date);
    },
    isValidDate: function isValidDate(dateString) {
      return (0, _moment.default)(dateString, MOMENT_DATE_FORMATS[this.dateFormat], true).isValid();
    },
    isCalendarElement: function isCalendarElement(event) {
      var _event$relatedTarget, _event$relatedTarget$;

      return (_event$relatedTarget = event.relatedTarget) === null || _event$relatedTarget === void 0 ? void 0 : (_event$relatedTarget$ = _event$relatedTarget.className) === null || _event$relatedTarget$ === void 0 ? void 0 : _event$relatedTarget$.includes('flatpickr-day');
    }
  });

  _exports.default = _default;
});