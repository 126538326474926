define("herer-web/controllers/account-suspended", ["exports", "herer-web/constants", "herer-web/mixins/context"], function (_exports, _constants, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    authManager: Ember.inject.service(),
    billingNumber: _constants.default.billingNumber,
    accountSuspended: Ember.computed.readOnly('currentLocation.isSuspended'),
    accountCeasedOnSuspension: Ember.computed.and('accountSuspended', 'currentLocation.cease_on_suspension'),
    actions: {
      goHome: function goHome() {
        this.transitionToRoute('/');
      }
    }
  });

  _exports.default = _default;
});