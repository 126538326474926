define("herer-web/helpers/format-unit", ["exports", "underscore.string/pad", "underscore.string/insert", "underscore.string/numberFormat", "big.js", "lodash/isNaN"], function (_exports, _pad, _insert, _numberFormat, _big, _isNaN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatCents = _exports.formatUnit = void 0;

  var formatCents = function formatCents(cents) {
    cents = parseInt(parseFloat(cents).toFixed());
    cents = (0, _pad.default)(cents, 2, '0');
    cents = (0, _insert.default)(cents, -2, '.');
    return (0, _numberFormat.default)(parseFloat(cents), 2);
  };

  _exports.formatCents = formatCents;

  var formatUnit = function formatUnit(value) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (value == null) {
      return;
    }

    value = value[0];

    if ((0, _isNaN.default)(value)) {
      return;
    }

    var suffix = '';

    if (options.unit !== 'dollar' && options.unit !== 'unit') {
      suffix = options.unit;
    }

    if (options.unit === 'dollar') {
      var isNegative = parseFloat(value, 10) < 0;
      var isZero = value === 0;

      if (options.dollars) {
        if (isNegative) {
          value = value.times(-1);
        }

        value = parseInt(value.times(100).toFixed());
        value = (0, _pad.default)(value, 2, '0');
        value = (0, _insert.default)(value, -2, '.');
        value = (0, _numberFormat.default)(new _big.default(value), 2);
      } else {
        if (isNegative) {
          value *= -1;
        }

        value = formatCents(value);
      }

      if (isNegative) {
        value = "($".concat(value, ")");
      } else {
        value = "$".concat(value);
      }

      if (options.colors) {
        var classes = ['currency'];

        if (isNegative) {
          classes.push('negative');
        } else {
          classes.push('positive');
        }

        if (isZero && !options.noMute) {
          classes.push('text-muted');
        }

        value = "<span class='".concat(classes.join(' '), "'>").concat(value, "</span>");
      }
    } else if (options.unit === 'gram' || value % 1 !== 0) {
      value = (0, _numberFormat.default)(parseFloat(value), 2);
    }

    return new Ember.String.htmlSafe("".concat(value, " ").concat(suffix));
  };

  _exports.formatUnit = formatUnit;
  var FormatUnitHelper = Ember.Helper.helper(formatUnit);
  var _default = FormatUnitHelper;
  _exports.default = _default;
});