define("herer-web/models/id-card", ["exports", "aamva", "herer-web/constants", "herer-web/utils/id-parsers", "moment", "herer-web/components/id-detection/input-reader"], function (_exports, _aamva, _constants, _idParsers, _moment, _inputReader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  var validatedFields = ['birth_date', 'driver_license_number', 'driver_license_expiration_date', 'expiration_date', 'id_number'];

  var _default = Ember.Object.extend({
    init: function init() {
      var parsedData = this.parsedArizonaPatientCard() || this.parsedDriversLicenseCard() || this.parsedRealId();
      this.set('parsedData', parsedData);
      this.setProperties(parsedData);
    },
    birth_date: undefined,
    driver_license_expiration_date: undefined,
    driver_license_number: undefined,
    driver_license_state: undefined,
    driver_license_zip_code: undefined,
    email: undefined,
    first_name: undefined,
    id_number: undefined,
    last_name: undefined,
    parsedData: undefined,
    rawData: undefined,
    type: undefined,
    hasID: Ember.computed.or('driver_license_number', 'id_number'),
    hasNoIDId: Ember.computed.not('hasID'),
    errors: (_Ember = Ember).computed.apply(_Ember, validatedFields.concat([function () {
      var errors = [];

      if (this.get('isParseError')) {
        var parseError = new _inputReader.CardParseError();
        errors.push(parseError);
      }

      if (this.get('isUnderageCustomer')) {
        var ageValidationError = new _inputReader.CardValidationError();
        ageValidationError.viewData = {
          header: 'Deny Customer Entry',
          subtitle: 'This Customer is Underage',
          messageBody: 'State law prohibits sales to this person.',
          title: 'Invalid ID'
        };
        errors.push(ageValidationError);
      }

      if (this.get('isExpired')) {
        var expirationError = new _inputReader.CardValidationError();
        expirationError.viewData = {
          header: 'Deny Customer Entry',
          subtitle: 'This ID Has Expired',
          messageBody: 'State laws prohibit sales to this person.',
          title: 'Invalid ID'
        };
        errors.push(expirationError);
      }

      return errors;
    }])),
    hasErrors: Ember.computed('errors', function () {
      return this.get('errors.length');
    }),
    isExpired: Ember.computed('driver_license_expiration_date', 'expiration_date', function () {
      var expirationDate = this.get('driver_license_expiration_date') || this.get('expiration_date');

      if (!expirationDate) {
        return;
      }

      return (0, _moment.default)(expirationDate).diff((0, _moment.default)()) < 0;
    }),
    isParseError: Ember.computed('driver_license_number', 'id_number', 'parsedData', function () {
      return !this.get('parsedData') && !(this.get('driver_license_number') || this.get('id_number'));
    }),
    isUnderageCustomer: Ember.computed('birth_date', 'id_number', function () {
      var birthDate = this.get('birth_date');
      var isNotPatient = !this.get('id_number');
      var isNotOver21 = (0, _moment.default)().diff(birthDate, 'years') < 21;
      return birthDate && isNotPatient && isNotOver21;
    }),
    evaluateAamvaFunction: function evaluateAamvaFunction(parsedData, funcName) {
      var value;

      try {
        value = parsedData[funcName]();
      } catch (e) {
        value = undefined;
      }

      return value;
    },
    mapAamvaLibraryResultToPersonModel: function mapAamvaLibraryResultToPersonModel(parsedData) {
      return {
        first_name: this.evaluateAamvaFunction(parsedData, 'name').first,
        last_name: this.evaluateAamvaFunction(parsedData, 'name').last,
        email: parsedData.email ? parsedData.email : undefined,
        birth_date: this.evaluateAamvaFunction(parsedData, 'birthday'),
        driver_license_number: this.evaluateAamvaFunction(parsedData, 'id'),
        driver_license_expiration_date: this.evaluateAamvaFunction(parsedData, 'expiration_date'),
        driver_license_state: parsedData.state ? parsedData.state : undefined,
        driver_license_zip_code: parsedData.postal_code ? parsedData.postal_code.trim() : undefined
      };
    },
    parsedArizonaPatientCard: function parsedArizonaPatientCard() {
      var arizonaPatientCard;
      var arizonaIdLength = 20;
      var parsedIdIndex = 2;
      var parsedLength = 3;
      var badCharacters = /[%\?]/g;
      var stripped = this.get('rawData').replace(badCharacters, '');
      var split = stripped.split(',');

      if (split.length === parsedLength && split[parsedIdIndex].trim().length === arizonaIdLength) {
        arizonaPatientCard = {
          first_name: split[0],
          last_name: split[1],
          id_number: split[parsedIdIndex].trim(),
          type: _constants.default.personType.patient
        };
      }

      return arizonaPatientCard;
    },
    parsedRealId: function parsedRealId() {
      var raw = this.get('rawData');

      if (raw.slice(0, 1) !== '%') {
        return null;
      }

      var stripped = raw.substring(3);
      var state = raw.substring(1, 3);
      var stateMap = _idParsers.default[state] || _idParsers.default['CA'];
      var split = stripped.split(stateMap.delim);
      var fields = {};
      split.forEach(function (item, index) {
        fields = Object.assign(fields, stateMap.parts[index](item));
      });
      return Object.assign(fields, {
        driver_license_state: state
      });
    },
    parsedDriversLicenseCard: function parsedDriversLicenseCard() {
      var parsedData = _aamva.default.stripe(this.get('rawData'));

      if (parsedData) {
        var mappedObject = this.mapAamvaLibraryResultToPersonModel(parsedData);

        if (mappedObject.driver_license_number) {
          return mappedObject;
        }
      }
    }
  });

  _exports.default = _default;
});