define("herer-web/routes/state-link/index", ["exports", "herer-web/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    washingtonLocation: Ember.computed.alias('authManager.currentLocation.isWashington'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        var locationUsesManagedCompliance = _this.get('authManager.currentLocation.user_managed_compliance');

        var featureManager = _this.get('featureManager');

        var permissionsManager = _this.get('permissionsManager');

        if (!featureManager.can('package-management') || !permissionsManager.verifyForced('view_packages')) {
          _this.replaceWith('products');

          return;
        }

        if (locationUsesManagedCompliance) {
          console.log('UMC');

          if (_this.get('washingtonLocation') && _this.get('launchDarkly').can('enable-ccrs')) {
            _this.replaceWith('state-link.manual-ccrs');
          } else {
            _this.replaceWith('state-link.manual');
          }
        } else {
          console.log('Auto');

          _this.replaceWith('state-link.auto');
        }
      });
    }
  });

  _exports.default = _default;
});