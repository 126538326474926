define("herer-web/models/receipt-preference", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ReceiptPreference;
  ReceiptPreference = _model.default.extend({
    store_name: (0, _model.attr)('string'),
    show_address: (0, _model.attr)('boolean'),
    phone: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    website: (0, _model.attr)('string'),
    extra_text: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    logo_content_type: (0, _model.attr)('string'),
    logo_file_name: (0, _model.attr)('string'),
    logo_url: (0, _model.attr)('string'),
    logo_fingerprint: (0, _model.attr)('string'),
    logo_or_url: Ember.computed('logo', 'logo_url', function () {
      return this.get('logo') || this.get('logo_url');
    }),
    qr_code: (0, _model.attr)('string'),
    qr_code_content_type: (0, _model.attr)('string'),
    qr_code_file_name: (0, _model.attr)('string'),
    qr_code_url: (0, _model.attr)('string'),
    qr_code_fingerprint: (0, _model.attr)('string'),
    qr_code_or_url: Ember.computed('qr_code', 'qr_code_url', function () {
      return this.get('qr_code') || this.get('qr_code_url');
    }),
    show_budtender_name: (0, _model.attr)('boolean'),
    show_tip_total: (0, _model.attr)('boolean'),
    show_loyalty_points: (0, _model.attr)('boolean')
  });
  var _default = ReceiptPreference;
  _exports.default = _default;
});