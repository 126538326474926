define("herer-web/routes/pin-debit", ["exports", "herer-web/routes/authenticated-side-nav"], function (_exports, _authenticatedSideNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    sideNavTemplateName: 'settings-nav',
    model: function model() {
      var _this = this;

      return new Promise(function (resolve) {
        return _this.get('store').findAll('pin-debit-setting').then(function (results) {
          var settings = results.get('firstObject');
          return resolve(settings);
        });
      });
    }
  });

  _exports.default = _default;
});