define("herer-web/templates/components/sku-as-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmmk3g70",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"barcode-tooltip\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,0,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Barcode (SKU)\\n\"]],\"parameters\":[]}],[0,\"  \"],[5,\"bs-tooltip\",[],[[\"@renderInPlace\",\"@autoPlacement\"],[true,[23,0,[\"autoPlacement\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"tooltipText\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/sku-as-barcode.hbs"
    }
  });

  _exports.default = _default;
});