define("herer-web/services/zendesk", ["exports", "herer-web/config/environment", "herer-web/constants", "herer-web/mixins/deferred-script", "herer-web/utils/error-reporter"], function (_exports, _environment, _constants, _deferredScript, _errorReporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ZENDESK_TOKEN_COOKIE_KEY = 'zendeskToken';
  var Environment = _environment.default.APP;
  var zendeskScriptAttributes = {
    id: 'ze-snippet'
  };

  var _default = Ember.Service.extend(_deferredScript.default, {
    authManager: Ember.inject.service(),
    http: Ember.inject.service(),
    cookies: Ember.inject.service(),
    scriptSrc: 'https://static.zdassets.com/ekr/snippet.js?key=ffeeab13-1fe5-4896-9223-6d3b19d9b497',
    _regenerateToken: function _regenerateToken() {
      var http = Ember.get(this, 'http');
      var cookies = Ember.get(this, 'cookies');
      return http.get("".concat(_constants.default.apiHost, "/sso/zendesk")).then(function (response) {
        var data = response.data;
        cookies.setCookie(ZENDESK_TOKEN_COOKIE_KEY, data.token, {
          expires: 1
        });
        return data.token;
      }).catch(function (error) {
        var metadata = {
          title: 'Zendesk SSO Error'
        };
        (0, _errorReporter.errorReporter)(error, metadata);
      });
    },
    _setIdentity: function _setIdentity() {
      var email = this.get('authManager.currentUser.email');
      var organization = this.get('authManager.currentAccount.id');
      var user = this.get('authManager.currentUser.name');
      this.loadScript(zendeskScriptAttributes).then(function () {
        window.zE(function () {
          window.zE.identify({
            name: user,
            email: email,
            organization: organization
          });
        });
      });
    },
    getToken: function getToken() {
      var token = Ember.get(this, 'cookies').getCookie(ZENDESK_TOKEN_COOKIE_KEY);

      if (token) {
        return Ember.RSVP.resolve(token);
      } else {
        return this._regenerateToken();
      }
    },
    accessUrl: function accessUrl() {
      return this.getToken().then(function (token) {
        return "".concat(Environment.zendeskUrl, "/access/jwt?jwt=").concat(token);
      });
    },
    shutdown: function shutdown() {
      Ember.get(this, 'cookies').removeCookie(ZENDESK_TOKEN_COOKIE_KEY);
      this.hide();
    },
    show: function show() {
      this._fireOnZe('show');
    },
    hide: function hide() {
      this._fireOnZe('hide');
    },
    onPageChange: function onPageChange() {
      this.loadScript(zendeskScriptAttributes).then(function () {
        window.zE('webWidget', 'helpCenter:setSuggestions', {
          url: true
        });
      });
    },
    _fireOnZe: function _fireOnZe(funcName) {
      this.loadScript(zendeskScriptAttributes).then(function () {
        window.zE(function () {
          return window.zE[funcName]();
        });
      });
    },
    activate: function activate() {
      var _this = this;

      this.loadScript(zendeskScriptAttributes).then(this.getToken.bind(this)).then(function (token) {
        window.zESettings = {
          webWidget: {
            authenticate: {
              jwt: token
            }
          }
        };

        _this._setIdentity();

        _this.show();
      });
    }
  });

  _exports.default = _default;
});