define("herer-web/models/payment-method", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    allowsChange: (0, _model.attr)('boolean'),
    paymentType: (0, _model.attr)('number')
  });

  _exports.default = _default;
});