define("herer-web/components/sales-report", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var reportIntervals = _constants.default.reportIntervals;

  var _default = Ember.Component.extend({
    title: '',
    showMeasure: true,
    showBlock: true,
    interval: null,
    onRefreshClick: null,
    onExportClick: null,
    onIntervalChange: null,
    reportIntervals: reportIntervals,
    showInterval: Ember.computed.bool("interval"),
    dateColumnWidthClass: Ember.computed('columns', 'groupBys', 'validMeasures', function () {
      var columns = 11;

      if (this.get('reportTypes')) {
        columns -= 2;
      }

      if (this.get('columns')) {
        columns -= 2;
      }

      if (this.get('groupBys')) {
        columns -= 2;
      }

      if (this.get('validMeasures')) {
        columns -= 2;
      }

      return "col-md-".concat(columns);
    }),
    actions: {
      hideBlock: function hideBlock() {
        this.set('showBlock', false);
      },
      showBlock: function showBlock() {
        this.set('showBlock', true);
      },
      toggleInterval: function toggleInterval() {
        var showInterval = this.showInterval;
        var nextDateInterval = showInterval ? null : 'today';
        this.onIntervalChange && this.onIntervalChange(nextDateInterval);
      },
      changeDate: function changeDate(property, date) {
        this.set(property, date);
      }
    }
  });

  _exports.default = _default;
});