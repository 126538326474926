define("herer-web/components/account-reports/sales-by-location", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    loading: false,
    failed: false,
    canShowChart: Ember.computed('loading', 'failed', function () {
      return !this.get('loading') && !this.get('failed');
    }),
    onResize: Ember.computed(function () {
      var _this = this;

      var func = function func() {
        _this._setHeight();
      };

      $.proxy(func, this);
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      this.onLoadReport();
      this.set('timer', setInterval(function () {
        _this2.onLoadReport(true);
      }, 30000));
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('timer'));
      this.set('timer', null);
    },
    actions: {
      changeInterval: function changeInterval(interval) {
        var groupBys = {
          '12-months': 'month_year',
          '30-days': 'day',
          '7-days': 'day',
          'today': 'day'
        };
        this.onIntervalChange(interval);
        this.onGroupByChange(groupBys[interval]);
        this.onLoadReport();
      }
    }
  });

  _exports.default = _default;
});