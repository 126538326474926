define("herer-web/models/lot-adjustment", ["exports", "@ember-data/model", "herer-web/mixins/lot-number-formatter", "herer-web/constants", "herer-web/utils/sample-type-string"], function (_exports, _model, _lotNumberFormatter, _constants, _sampleTypeString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_lotNumberFormatter.default, {
    quantity: (0, _model.attr)('weight'),
    adjustment_type: (0, _model.attr)('number'),
    adjustment_reason: (0, _model.attr)('number'),
    adjustment_reason_id: (0, _model.attr)('number'),
    note: (0, _model.attr)('string'),
    purchase_price: (0, _model.attr)('number'),
    paid_excise_tax: (0, _model.attr)('number'),
    inventory_item_id: (0, _model.attr)('string'),
    sku: (0, _model.attr)('string'),
    lot_number: (0, _model.attr)('string'),
    weight: (0, _model.attr)('weight'),
    medical: (0, _model.attr)('boolean'),
    has_sales: (0, _model.attr)('boolean'),
    has_divergent_inventory: (0, _model.attr)('boolean'),
    inventory_transferred_out: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    // Used by manual mj manifest
    inventory_type: (0, _model.attr)('number'),
    sample_type: (0, _model.attr)('number'),
    sample: (0, _model.attr)('boolean'),
    inventory_item: (0, _model.belongsTo)('inventory_item', {
      async: false,
      embedded: 'always'
    }),
    remote_lot: (0, _model.belongsTo)('remote_lot', {
      async: false
    }),
    product: (0, _model.belongsTo)('product', {
      async: false
    }),
    place: (0, _model.belongsTo)('place', {
      async: false
    }),
    expected_quantity: Ember.computed.readOnly('remote_lot.quantity'),
    name: Ember.computed.readOnly('remote_lot.name'),
    strain: Ember.computed.readOnly('remote_lot.strain'),
    description: Ember.computed.readOnly('remote_lot.description'),
    test_results_thc: Ember.computed.readOnly('remote_lot.test_results_thc'),
    test_results_cbd: Ember.computed.readOnly('remote_lot.test_results_cbd'),
    is_sample: Ember.computed.readOnly('sample'),
    sampleTypeString: Ember.computed('sample_type', function () {
      return (0, _sampleTypeString.sampleTypeString)(this.get('sample_type'));
    }),
    inventory_type_string: Ember.computed('remote_lot.inventory_type_string', 'inventory_type', function () {
      var inventoryTypeString = this.get('remote_lot.inventory_type_string');
      var inventoryType = this.get('inventory_type');

      if (Ember.isPresent(inventoryTypeString)) {
        return inventoryTypeString;
      } else if (Ember.isEmpty(inventoryType)) {
        return;
      } else {
        return _constants.default.inventoryItemTypes.find(function (type) {
          return type.value === inventoryType;
        }).name;
      }
    }),
    newInventory: Ember.computed('adjustment_type', function () {
      return this.get('adjustment_type') === _constants.default.lotAdjustmentType.newInventory;
    }),
    lotNumberFormatted: Ember.computed('lot_number', function () {
      if (!Ember.isPresent(this.get('lot_number'))) {
        return;
      }

      return this.formatLotNumber(this.get('lot_number'));
    }),
    quantityValue: Ember.computed.readOnly('quantity.value'),
    quantityUnit: Ember.computed.readOnly('quantity.unit'),
    expectedQuantityValue: Ember.computed.readOnly('expected_quantity.value'),
    hasLotNumber: Ember.computed.notEmpty('lot_number'),
    hasMjProduct: Ember.computed('product', 'product.product_type.is_marijuana', function () {
      var product = this.get('product');
      return Ember.isPresent(product) && product.get('product_type.is_marijuana');
    })
  });

  _exports.default = _default;
});