define("herer-web/pivot-table/row", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    header: null,
    cells: null,
    init: function init() {
      this._super();

      return this.set('cells', Ember.A());
    },
    total: Ember.computed('cells.[]', 'reducer', function () {
      return this.get('cells').reduce(this.get('reducer').bind(this), new _big.default(0));
    }),
    add: function add(cell) {
      var index = this.indexOfCellForColumn(cell.column);

      if (index > -1) {
        return this.get('cells')[index] = cell;
      } else {
        return this.get('cells').push(cell);
      }
    },
    indexOfCellForColumn: function indexOfCellForColumn(column) {
      var index = -1;
      this.get('cells').find(function (cell, i) {
        if (cell.column === column) {
          index = i;
          return true;
        }
      });
      return index;
    },
    reducer: function reducer(previous, cell) {
      if (this.valueForCell(cell) != null) {
        return previous.plus(this.valueForCell(cell));
      } else {
        return previous;
      }
    },
    valueForCell: function valueForCell(cell) {
      return cell.value;
    },
    unknownProperty: function unknownProperty(key) {
      var idx = this.indexOfCellForColumn(key);

      if (this.get('cells').length && idx > -1) {
        return this.get('cells')[idx].value;
      }
    }
  });

  _exports.default = _default;
});