define("herer-web/models/payment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    total: (0, _model.attr)('number'),
    paymentType: (0, _model.attr)('number'),
    paymentMethod: (0, _model.belongsTo)('payment_method', {
      async: true
    }),
    paymentMethodId: Ember.computed('paymentMethod.id', {
      get: function get() {
        return this.get('paymentMethod.id');
      },
      set: function set() {
        return this.get('paymentMethod.id');
      }
    })
  });

  _exports.default = _default;
});