define("herer-web/templates/convert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZEtujc7y",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"lot-converter\",null,[[\"inventoryItem\",\"taxItems\",\"productTypes\",\"tieredPricingModels\",\"tags\",\"labelPreference\",\"emailSettings\",\"onFinish\"],[[24,[\"model\",\"inventoryItem\"]],[24,[\"model\",\"taxItems\"]],[24,[\"model\",\"productTypes\"]],[24,[\"model\",\"tieredPricingModels\"]],[24,[\"model\",\"tags\"]],[24,[\"model\",\"labelPreference\"]],[24,[\"currentUser\",\"email_settings\"]],[28,\"route-action\",[\"transitionToProduct\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/convert.hbs"
    }
  });

  _exports.default = _default;
});