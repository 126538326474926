define("herer-web/routes/account", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'settingsNav',
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        user: this.get('authManager.currentUser'),
        locations: store.findAll('location')
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('locations', model.locations);
      controller.set('roles', model.roles);

      this._super(controller, model.user);

      controller.refreshModel();
    },
    deactivate: function deactivate() {
      var controller = this.get('controller');
      var model = controller.get('model');
      model.set('current_password', null);
      controller.set('successMessage', null);
      controller.set('model', null);
    }
  });

  _exports.default = _default;
});