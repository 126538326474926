define("herer-web/mixins/table-sorts", ["exports", "herer-web/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decorateHeaders = decorateHeaders;
  _exports.default = void 0;

  function decorateHeaders(headers) {
    return headers.reduce(function (acc, header) {
      // ember keeps taking over column and making it an ember object
      var copy = Object.assign({}, header);

      if (copy.display !== false) {
        copy.display = true;
      }

      if (!copy.prompt) {
        copy.prompt = (0, _humanize.humanize)(copy.field);
      }

      if (copy.field && typeof copy.sortable === 'undefined') {
        copy.sortable = true;
      }

      return acc.concat(copy);
    }, []);
  }

  var _default = Ember.Mixin.create({
    sortTable: 'products',
    tablePrefix: Ember.computed('sortTable', function () {
      var prefix = this.get('sortTable') || '';

      if (prefix) {
        prefix = prefix + '.';
      }

      return prefix;
    }),
    updateSorts: function updateSorts(sorts) {
      var prefix = this.get('tablePrefix');
      var sortBy = this.get("".concat(prefix, "sortBy"));
      var sortDirection = this.get("".concat(prefix, "sortDirection"));
      sorts = decorateHeaders(sorts);
      sorts.forEach(function (sort) {
        if (Ember.isEmpty(sortBy)) {
          sort.direction = null;
        } else if (sort.field === sortBy) {
          sort.direction = sortDirection;
        }
      });
      return sorts;
    },
    actions: {
      onSortChange: function onSortChange(field, direction) {
        var prefix = this.get('tablePrefix');
        this.set("".concat(prefix, "sortBy"), field);
        this.set("".concat(prefix, "sortDirection"), direction);
      }
    }
  });

  _exports.default = _default;
});