define("herer-web/routes/untracked-migrations", ["exports", "herer-web/mixins/titan-email", "herer-web/config/environment", "herer-web/routes/authenticated-full-page", "herer-web/models/paged-array"], function (_exports, _titanEmail, _environment, _authenticatedFullPage, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_titanEmail.default, {
    modelName: 'untracked-migration',
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        if (!Ember.isPresent(_this.get('titanEmail')) && _environment.default.environment === 'production') {
          _this.transitionTo('unauthorized');
        }
      });
    },
    model: function model() {
      var store = this.get('store');

      var pagedArray = _pagedArray.TimePagedArray.create({
        store: store,
        model: this.get('modelName')
      });

      return Ember.RSVP.hash({
        allMigrations: pagedArray.fetchObjects(),
        migration: this.get('store').createRecord(this.get('modelName'), {})
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      controller.set('model', hash.migration);
      controller.set('allMigrations', hash.allMigrations);
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});