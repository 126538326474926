define("herer-web/components/text-counter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    remainingCharsCount: Ember.computed('value', 'max', function () {
      return Number(this.get('max')) - (this.get('value.length') || 0);
    })
  });

  _exports.default = _default;
});