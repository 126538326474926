define("herer-web/components/inventory-audit/save-adjustments", ["exports", "herer-web/mixins/context", "herer-web/mixins/adjustment-reasons"], function (_exports, _context, _adjustmentReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_adjustmentReasons.default, _context.default, {
    featureManager: Ember.inject.service(),
    audit: null,
    canEdit: true,
    onSaveDraft: null,
    onSaveAdjustment: null,
    isSavingAdjustment: false,
    canSaveAdjustment: Ember.computed.and('canEdit', 'hasValidAudit'),
    savePrompt: Ember.computed('currentLocation', function () {
      var featureManager = this.get('featureManager');

      if (featureManager.can('traceability')) {
        return 'Save Adjustments & Report Changes To State';
      } else {
        return 'Save Adjustments';
      }
    }),
    hasValidAudit: Ember.computed('audit.adjustment_type', 'audit.adjustment_reason', 'audit.inventory_audit_results', function () {
      var audit = this.get('audit');

      if (Ember.isEmpty(audit)) {
        return false;
      }

      var adjustmentReason = audit.get('adjustment_reason');
      return Ember.isPresent(audit.get('adjustment_type')) && Ember.isPresent(adjustmentReason) && adjustmentReason.length <= this.get('maxAdjustmentNoteLength') && Ember.isPresent(audit.get('inventory_audit_results'));
    })
  });

  _exports.default = _default;
});