define("herer-web/components/tax-item-dropzone", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    draggedElement: null,
    models: [],
    dragStart: function dragStart(e) {
      this.set('draggedElement', e.target.parentElement);
    },
    dragOver: function dragOver(e) {
      e.preventDefault();
      var target = e.target;
      var isSortable = target && target.draggable;

      if (!isSortable) {
        return;
      }

      var draggedElement = this.get('draggedElement');
      var sortableEmberEl = target.parentElement;

      if (sortableEmberEl === draggedElement) {
        return;
      }

      var dropZone = this.get('element').firstChild;
      draggedElement.firstChild.classList.add('-dragging');

      if (draggedElement.nextSibling === sortableEmberEl) {
        dropZone.insertBefore(draggedElement, sortableEmberEl.nextSibling);
      } else {
        dropZone.insertBefore(draggedElement, sortableEmberEl);
      }
    },
    dragEnd: function dragEnd(e) {
      e.preventDefault();
      this.get('draggedElement').firstChild.classList.remove('-dragging');
      this.set('draggedElement', null);
      var nodes = this.get('element').firstChild.children;

      for (var i = 0; i < nodes.length; i++) {
        var model = this.get('models').findBy('id', nodes[i].firstChild.getAttribute('data-uuid'));
        model.set('rank', i);
        model.save();
      }
    },
    drop: function drop(e) {
      e.preventDefault();
      return false;
    }
  });

  _exports.default = _default;
});