define("herer-web/mixins/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResetScroll = Ember.Mixin.create({
    resetScroll: true,
    activate: function activate() {
      this._super();

      if (this.resetScroll) {
        window.scrollTo(0, 0);
      }
    }
  });
  var _default = ResetScroll;
  _exports.default = _default;
});