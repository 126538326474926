define("herer-web/models/label-preference", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    launchDarkly: Ember.inject.service(),
    storeName: (0, _model.attr)('string'),
    storeId: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    logoContentType: (0, _model.attr)('string'),
    logoFileName: (0, _model.attr)('string'),
    logoUrl: (0, _model.attr)('string'),
    logoFingerprint: (0, _model.attr)('string'),
    labelId: (0, _model.attr)('string'),
    autoPrintDateLabel: (0, _model.attr)('boolean'),
    printPatientLabels: (0, _model.attr)('boolean'),
    printPackageLabels: (0, _model.attr)('boolean'),
    priceOnSkuLabel: (0, _model.attr)('boolean'),
    autoPrintWeightBasedInventory: (0, _model.attr)('boolean'),
    logoOrUrl: Ember.computed('logo', 'logoUrl', function () {
      var logo = this.get('logo');

      if (Ember.isPresent(logo)) {
        return logo;
      }

      return this.get('logoUrl');
    }),
    displayAutoPrintWeightBasedInventory: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('label_preferences_serializer_include_auto_print');
    })
  });

  _exports.default = _default;
});