define("herer-web/models/menu-feed", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/models/weedmaps-menu-groups", "herer-web/models/leafly-menu-groups", "herer-web/models/dutchie-menu-groups", "herer-web/utils/validation", "yup"], function (_exports, _model, _constants, _weedmapsMenuGroups, _leaflyMenuGroups, _dutchieMenuGroups, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      name: (0, _yup.string)().required().nullable(),
      type: (0, _yup.number)().required(),
      url: (0, _yup.string)().url().nullable(),
      isWeedmaps: (0, _yup.bool)(),
      apiKey: (0, _yup.string)().when("isWeedmaps", {
        is: true,
        then: (0, _yup.string)().required('Please enter an API key').nullable(),
        otherwise: (0, _yup.string)().nullable()
      })
    }),
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('number'),
    apiKey: (0, _model.attr)('string'),
    refreshToken: (0, _model.attr)('string'),
    secretKey: (0, _model.attr)('string'),
    storeId: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    published: (0, _model.attr)('boolean'),
    lastPublishedAt: (0, _model.attr)('date'),
    menuGroups: (0, _model.hasMany)('menu-group', {
      async: false
    }),
    hasDirtyMenuGroups: Ember.computed('menuGroups.[]', function () {
      return this.get('menuGroups').any(function (menuGroup) {
        return menuGroup.get('hasDirtyAttributes');
      });
    }),
    rollbackAttributes: function rollbackAttributes() {
      this.get('menuGroups').forEach(function (menuGroup) {
        if (Ember.isPresent(menuGroup)) {
          menuGroup.rollbackAttributes();
        }
      });

      this._super.apply(this, arguments);
    },
    menuGroupOptions: Ember.computed('type', function () {
      if (this.get('isLeafly')) {
        return _leaflyMenuGroups.default;
      } else if (this.get('isDutchie')) {
        return _dutchieMenuGroups.default;
      } else {
        return _weedmapsMenuGroups.default;
      }
    }),
    hasMenuItemErrors: Ember.computed('menuGroups.@each.hasMenuItemErrors', function () {
      var menuGroups = this.get('menuGroups');
      return menuGroups.reduce(function (haveErrors, menuGroup) {
        return haveErrors || menuGroup.get('hasMenuItemErrors');
      }, false);
    }),
    validateWeedmapsApiKey: function validateWeedmapsApiKey() {
      var _this = this;

      var apiKey = this.get('apiKey');

      if (!apiKey) {
        return;
      }

      this.validations.removeError('apiKey');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return fetch("https://api.weedmaps.com/api/web/v1/integrator_menu_items/".concat(apiKey)).then(function (response) {
          if (response.status !== 200) {
            _this.validations.addError('apiKey', ["Weedmaps doesn't recognize this API key.\n                Please make sure it's entered correctly and not expired and try again."]);

            reject();
          } else {
            _this.validations.addError('apiKey', null);

            resolve();
          }
        });
      });
    },
    disablePreview: Ember.computed('type', 'isSaving', function () {
      return this.get('isSaving') || Ember.isEmpty(this.get('type'));
    }),
    needsAttention: Ember.computed('apiKey', function () {
      return this.get('isWeedmaps') && Ember.isEmpty(this.get('apiKey'));
    }),
    isWebhook: Ember.computed('type', function () {
      return this.get('type') === _constants.default.menuFeedType.webhook;
    }),
    isWeedmaps: Ember.computed('type', function () {
      return this.get('type') === _constants.default.menuFeedType.weedmaps;
    }),
    isLeafly: Ember.computed('type', function () {
      return this.get('type') === _constants.default.menuFeedType.leafly;
    }),
    isDutchie: Ember.computed('type', function () {
      return this.get('type') === _constants.default.menuFeedType.dutchie;
    }),
    hasFixedPricedColumns: Ember.computed('isWeedmaps', 'isLeafly', function () {
      return this.get('isWeedmaps') || this.get('isLeafly');
    })
  });

  _exports.default = _default;
});