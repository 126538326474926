define("herer-web/models/tax-item", ["exports", "@ember-data/model", "ember-data-model-fragments", "lodash/find", "moment"], function (_exports, _model, _emberDataModelFragments, _find, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    mj_only: (0, _model.attr)('boolean'),
    compounding: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    rank: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    metrc_tax_type: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tax_item_schedules: (0, _model.hasMany)('tax_item_schedule', {
      async: false,
      embedded: 'always'
    }),
    applicable_tax_configurations: _emberDataModelFragments.default.fragmentArray('applicable_tax_configuration'),
    applicable_product_types: _emberDataModelFragments.default.fragmentArray('applicable_product_type'),
    effective_schedule: Ember.computed('tax_item_schedules.@each.effective_start', 'tax_item_schedules.@each.effective_end', function () {
      var now = (0, _moment.default)().toDate();
      return (0, _find.default)(this.get('tax_item_schedules').toArray(), function (schedule) {
        var startwindow = schedule.get('effective_start') <= now;
        var mEnd = (0, _moment.default)(schedule.get('effective_end')).endOf('Day');
        var endwindow = !Ember.isPresent(schedule.get('effective_end')) || mEnd >= now;
        return startwindow && endwindow;
      });
    }),
    hasEffectiveSchedule: Ember.computed.bool('effective_schedule'),
    rate: Ember.computed.alias('effective_schedule.rate'),
    inclusive: Ember.computed.alias('effective_schedule.inclusive'),
    hasDirtyAttributes: Ember.computed('dirtyType', 'tax_item_schedules.@each.hasDirtyAttributes', function () {
      var hasDirtySchedules = this.get('tax_item_schedules').any(function (schedule) {
        return schedule.get('hasDirtyAttributes');
      });
      return hasDirtySchedules || Ember.isPresent(this.get('dirtyType'));
    }),
    isDirty: function isDirty() {
      return this.hasDirtyAttributes;
    }
  });

  _exports.default = _default;
});