define("herer-web/services/launch-darkly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    cookies: Ember.inject.service(),
    config: Ember.inject.service(),
    isReady: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('ready', function () {
        _this.set('isReady', true);
      });
    },
    launchDarklyUser: Ember.computed('authManager.currentUser', 'authManager.currentLocation', 'authManager.currentAccount', 'authManager.sortedLocations', function () {
      var account = this.get('authManager.currentAccount');
      return {
        key: account.get('id'),
        firstName: account.get('name'),
        lastName: ' ',
        custom: {
          accountNumber: account.get('account_number'),
          numberOfLocations: this.get('authManager.sortedLocations.count'),
          licenseType: this.get('authManager.currentLocation.licenseTypeName')
        }
      };
    }),
    client: Ember.computed('launchDarklyUser', function () {
      var _this2 = this;

      this.set('isReady', false);
      var client = LDClient.initialize(this.get('config').getConfig('APP.ldClientSideId'), this.get('launchDarklyUser'));
      client.on('change', function () {
        return;
      });
      client.on('initialized', function () {
        return _this2.set('isReady', true);
      });
      return client;
    }),
    on: function on(event, fn) {
      return this.get('client').on(event, fn);
    },
    // Will not be accurate unless launch darkly is already ready
    can: function can(feature, defaultValue) {
      var testOverride = this.testOverride(feature);

      if (typeof testOverride !== 'undefined') {
        return testOverride === 'true';
      } else {
        return this.get('client').variation(feature, defaultValue);
      }
    },
    // Will wait until launch darkly is ready before checking for the feature flag
    // Use this in all possible cases
    verify: function verify(feature, defaultValue) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.waitForReady().then(function () {
          if (_this3.can(feature, defaultValue)) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    waitForReady: function waitForReady() {
      var _this4 = this;

      var client = this.get('client');
      return new Ember.RSVP.Promise(function (resolve) {
        if (_this4.get('isReady')) {
          resolve();
        }

        client.on('ready', function () {
          resolve();
        });
        client.on('error', function () {
          resolve();
        });
      });
    },
    testOverride: function testOverride(feature) {
      return this.get("cookies").getCookie("ldTestOverride-".concat(feature));
    }
  });

  _exports.default = _default;
});