define("herer-web/routes/tax-liability", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/pivot-table/table", "herer-web/constants", "moment"], function (_exports, _authenticatedSideNav, _table, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    http: Ember.inject.service(),
    sideNavTemplateName: 'reportsNav',
    actions: {
      refresh: function refresh() {
        return this.refresh();
      }
    },
    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = Ember.get(_this, 'http');
        var report_params = {
          start_date: (0, _moment.default)(params.startDate).format('YYYY-MM-DD'),
          end_date: (0, _moment.default)(params.endDate).format('YYYY-MM-DD')
        };
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/tax_liability"), report_params);
        return http.get(url).then(function (response) {
          var data = response.data;

          var table = _table.default.create({
            columnName: null,
            rowName: data.row_name,
            valueName: data.value_name,
            sourceData: data.source_data,
            groupBy: data.column_name,
            showRowTotals: false
          });

          return resolve({
            table: table
          });
        }).catch(function (response) {
          return reject({
            message: "".concat(response.status, ": ").concat(response.data.error)
          });
        });
      });
    }
  });

  _exports.default = _default;
});