define("herer-web/routes/packages/package/history", ["exports", "herer-web/routes/authenticated", "herer-web/mixins/pageable-route"], function (_exports, _authenticated, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend(_pageableRoute.default, {
    resetScroll: false,
    modelName: 'package-history-event',
    renderTemplate: function renderTemplate() {
      this._super();

      return this.render({
        into: 'side-nav-layout',
        outlet: 'drawer'
      });
    },
    parentModel: function parentModel() {
      return this.modelFor('packages/package');
    },
    model: function model() {
      var aPackage = this.parentModel();
      return Ember.RSVP.hash({
        packageHistoryEvents: this._super(),
        package: aPackage,
        users: this.get('store').findAll('user', {
          reload: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      model.packageHistoryEvents.set('defaultParams', {
        packageId: model.package.id
      });
      controller.set('package', model.package);
      controller.set('users', model.users);

      this._super(controller, model.packageHistoryEvents);
    }
  });

  _exports.default = _default;
});