define("herer-web/helpers/transaction-limit", ["exports", "herer-web/mixins/context", "herer-web/models/weight"], function (_exports, _context, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_context.default, {
    authManager: Ember.inject.service(),
    compute: function compute(params) {
      var traceabilityType = params[0];
      var medical = params[1];
      var currentLocation = this.get('currentLocation');
      var limitsPresent = false;
      var limit = currentLocation.transactionLimit(traceabilityType, medical);

      if (!Ember.isEmpty(limit)) {
        limit = _weight.default.create(limit);
        limitsPresent = true;
      }

      var potencyLimit = currentLocation.transactionPotencyLimit(traceabilityType, medical);

      if (!Ember.isEmpty(potencyLimit)) {
        potencyLimit = _weight.default.create(potencyLimit);
        limitsPresent = true;
      }

      return {
        present: limitsPresent,
        limit: limit,
        potencyLimit: potencyLimit
      };
    }
  });

  _exports.default = _default;
});