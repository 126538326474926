define("herer-web/components/locations/location-form/component", ["exports", "herer-web/services/feature-manager", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _featureManager, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fields = ['primary_email', 'name', 'street', 'city', 'state', 'zip_code', 'customer_contact_phone_number'];

  var getValue = function getValue(event) {
    return event.target.value;
  };

  var _default = Ember.Component.extend({
    validations: (0, _validation.validations)({
      primary_email: (0, _yup.string)().nullable().required().email(),
      name: (0, _yup.string)().matches(new RegExp(/^[^,]*$/i), 'Location name cannot contain a comma').required(),
      street: (0, _yup.string)().required(),
      city: (0, _yup.string)().required(),
      state: (0, _yup.string)().oneOf(_constants.default.statesProvinces.mapBy('abbreviation'), 'must be a valid state'),
      zip_code: (0, _yup.string)().matches(new RegExp(/^\d{5}(?:[-\s]\d{4})?$/i), 'must be valid zip code'),
      customer_contact_phone_number: (0, _yup.string)().when('$model', {
        is: function is(model) {
          return (0, _featureManager.hasFeature)(model.get('location'), 'metrc_delivery');
        },
        then: (0, _yup.string)().nullable().required(),
        otherwise: (0, _yup.string)().nullable()
      })
    }),
    isSaveDisabled: Ember.computed.empty('name'),
    statesProvinces: _constants.default.statesProvinces,
    didInsertElement: function didInsertElement() {
      var _Ember;

      this._super.apply(this, arguments);

      Ember.setProperties(this, (_Ember = Ember).getProperties.apply(_Ember, [this.get('model')].concat(fields)));
    },
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        this.get("validations").validate().then(function () {
          var _Ember2;

          Ember.setProperties(model, (_Ember2 = Ember).getProperties.apply(_Ember2, [_this].concat(fields)));

          _this.get('validations').resetErrors();

          _this.onSave(model);
        }).catch(console.log);
      },
      setPrimaryEmail: function setPrimaryEmail(event) {
        this.set("primary_email", getValue(event));
      },
      setName: function setName(event) {
        this.set("name", getValue(event));
      },
      setStreet: function setStreet(event) {
        this.set("street", getValue(event));
      },
      setCity: function setCity(event) {
        this.set("city", getValue(event));
      },
      setState: function setState(value) {
        this.set("state", value);
      },
      setZipCode: function setZipCode(event) {
        this.set("zip_code", getValue(event));
      },
      setPhoneNumber: function setPhoneNumber(event) {
        this.set("customer_contact_phone_number", getValue(event));
      }
    }
  });

  _exports.default = _default;
});