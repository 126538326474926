define("herer-web/routes/receipt", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/mixins/pin-debit-settings", "herer-web/mixins/receipt-preference"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pinDebitSettings, _receiptPreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, _pinDebitSettings.default, _receiptPreference.default, {
    sideNavTemplateName: 'settingsNav',
    permissionKey: 'view_company_settings',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        receiptPreference: this.findReceiptPreference(store),
        pinDebitSettings: this.findPinDebitSettings(store)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.receiptPreference);

      controller.set('pinDebitSettings', model.pinDebitSettings);
      controller.set('successMessage', '');
    }
  });

  _exports.default = _default;
});