define("herer-web/components/terpenoid-profile", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var terpenes = _constants.default.terpenes;

  function normalizeTerpenoidProfile(terpenoidProfileHash) {
    var proxy = [];
    terpenes.forEach(function (t) {
      var key = t.value;
      var name = t.name;
      var value = terpenoidProfileHash[key];
      proxy.push(Ember.Object.create({
        key: key,
        name: name,
        value: value,
        dataTestKey: "terpenoid_profile_".concat(key, "_value")
      }));
    });
    return proxy;
  }

  function serializeTerpenoidProfile(terpenoidProfileArray) {
    var result = {};
    terpenoidProfileArray.forEach(function (t) {
      var value = t.get('value');

      if (Ember.isPresent(value)) {
        result[t.key] = parseFloat(value);
      }
    });
    return result;
  }

  var _default = Ember.Component.extend({
    onUpdate: null,
    terpenoidProfileProxy: Ember.computed('terpenoidProfile', function () {
      var terpenoidProfile = this.terpenoidProfile || {};
      return normalizeTerpenoidProfile(terpenoidProfile);
    }),
    availableTerpenes: Ember.computed('terpenoidProfileProxy', function () {
      var proxy = this.get('terpenoidProfileProxy');
      return proxy.reject(function (t) {
        return Ember.isPresent(t.get('value'));
      });
    }),
    existingTerpenes: Ember.computed('terpenoidProfileProxy.@each.value', function () {
      var proxy = this.get('terpenoidProfileProxy');
      return proxy.filter(function (t) {
        return Ember.isPresent(t.get('value'));
      });
    }),
    invokeUpdateCallback: function invokeUpdateCallback() {
      var proxy = this.get('terpenoidProfileProxy');
      this.onUpdate(serializeTerpenoidProfile(proxy));
    },
    actions: {
      invokeUpdateCallback: function invokeUpdateCallback() {
        this.invokeUpdateCallback();
      },
      selectedTerpene: function selectedTerpene(key) {
        var proxy = this.get('terpenoidProfileProxy');
        var terpene = proxy.find(function (t) {
          return t.get('key') === key;
        });
        terpene.set('value', 0);
        this.invokeUpdateCallback();
      },
      updateTerpene: function updateTerpene(key, value) {
        var proxy = this.get('terpenoidProfileProxy');
        var terpene = proxy.find(function (t) {
          return t.key === key;
        });

        if (Ember.isEmpty(value)) {
          value = 0;
        }

        terpene.set('value', value);
      },
      deleteTerpene: function deleteTerpene(key) {
        var proxy = this.get('terpenoidProfileProxy');
        var terpene = proxy.find(function (t) {
          return t.get('key') === key;
        });
        terpene.set('value', null);
        this.invokeUpdateCallback();
      }
    }
  });

  _exports.default = _default;
});