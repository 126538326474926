define("herer-web/templates/components/flash-message-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Omw6gHxn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"flash-message\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"alert \",[22,\"flashAlertClass\"],\" alert-dismissible\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[22,\"flashMessage\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flash-actions\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn dismiss\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"onClose\"]]]],[8],[0,\"\\n        OK\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/flash-message-box.hbs"
    }
  });

  _exports.default = _default;
});