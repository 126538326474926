define("herer-web/components/people-merger", ["exports", "herer-web/constants", "herer-web/mixins/pageable", "herer-web/models/paged-array", "lodash/reject"], function (_exports, _constants, _pageable, _pagedArray, _reject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageable.default, {
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    componentIsVisible: false,
    onClose: null,
    fromPerson: null,
    toPerson: null,
    merged: false,
    searchModal: null,
    mergeModal: null,
    onMergeSuccess: null,
    errorMessage: null,
    people: Ember.computed('fromPerson.modelTypeString', function () {
      var store = this.get('store');
      var model = this.get('fromPerson.modelTypeString');
      return _pagedArray.PagedArray.create({
        store: store,
        model: model
      });
    }),
    typeString: Ember.computed('fromPerson', function () {
      var fromPerson = this.get('fromPerson');
      return fromPerson ? fromPerson.get('typeString') : 'person';
    }),
    filteredPeople: Ember.computed('fromPerson.{id,typeString}', 'people.objects.[]', function () {
      var fromId = this.get('fromPerson.id');
      var fromType = this.get('fromPerson.typeString');
      return (0, _reject.default)(this.get('people.objects'), function (person) {
        return person.get('id') === fromId || person.get('typeString') !== fromType;
      });
    }),
    searchText: null,
    personIcon: Ember.computed('fromPerson.type', function () {
      switch (this.get('fromPerson.type')) {
        case _constants.default.personType.patient:
          return 'fa-user-md';

        case _constants.default.personType.employee:
          return 'fa-user-secret';

        default:
          return 'fa-user';
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var searchModal = $('#people-merge-search-modal');
      this.set('searchModal', searchModal);
      var mergeModal = $('#people-merge-modal');
      this.set('mergeModal', mergeModal);
      searchModal.on('hidden.bs.modal', function () {
        _this.set('searchText', null);

        _this.get('people').updateObjects([], false);

        _this.onClose();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('componentIsVisible')) {
        this.showSearchModal();
      } else {
        this.hideSearchModal();
      }
    },
    resetState: function resetState() {
      this.set('searchText', null);
      this.set('fromPerson', null);
      this.set('toPerson', null);
    },
    resetMergeState: function resetMergeState() {
      this.set('merged', false);
      this.set('errorMessage', null);
      this.resetMergeButton();
    },
    showSearchModal: function showSearchModal() {
      this.showModal('searchModal');
    },
    hideSearchModal: function hideSearchModal() {
      this.hideModal('searchModal');
    },
    showConfirmMergeModal: function showConfirmMergeModal(toPerson) {
      this.resetMergeState();
      this.set('toPerson', toPerson);
      this.hideSearchModal();
      this.showModal('mergeModal');
    },
    showModal: function showModal(modalElementProp) {
      this.toggleModalVisibility(modalElementProp);
    },
    hideModal: function hideModal(modalElementProp) {
      this.toggleModalVisibility(modalElementProp, true);
    },
    toggleModalVisibility: function toggleModalVisibility(modalElementProp) {
      var shouldHide = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var modalElement = this.get(modalElementProp);

      if (Ember.isEmpty(modalElement)) {
        return;
      }

      modalElement.modal(shouldHide ? 'hide' : undefined);
    },
    showMergeButtonWorking: function showMergeButtonWorking() {
      var mergeButton = $('#peopleMergeButton');
      mergeButton.text('Merging...');
      mergeButton.prop('disabled', 'disabled');
    },
    resetMergeButton: function resetMergeButton() {
      var mergeButton = $('#peopleMergeButton');
      mergeButton.text('Merge');
      mergeButton.prop('disabled', '');
    },
    actions: {
      merge: function merge(toPerson) {
        var _this2 = this;

        this.showMergeButtonWorking();
        var fromPerson = this.get('fromPerson');
        Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/people/merge"), {
          from_person_id: fromPerson.get('id'),
          to_person_id: toPerson.get('id')
        }).then(function () {
          _this2.set('merged', true);

          Ember.run.later(function () {
            _this2.hideModal('searchModal');

            _this2.hideModal('mergeModal');

            _this2.set('merged', false);

            _this2.resetState();

            _this2.onMergeSuccess(fromPerson, toPerson);
          }, 1500);
        }).catch(function (response) {
          _this2.resetMergeState();

          _this2.set('errorMessage', response.data.error);
        });
      },
      showConfirmMergeModal: function showConfirmMergeModal(toPerson) {
        this.showConfirmMergeModal(toPerson);
      },
      cancelConfirmMerge: function cancelConfirmMerge() {
        this.resetMergeState();
        this.showModal('searchModal');
        this.hideModal('mergeModal');
      },
      updateFilters: function updateFilters(value) {
        Ember.run.debounce(this, this.updateFilters, value, 400);
      }
    },
    updateFilters: function updateFilters(value) {
      this.set('searchText', value);

      if (Ember.isPresent(this.get('searchText'))) {
        var filters = {};
        filters['by_query'] = this.get('searchText');
        this.set('people.filters', filters);
      } else {
        this.get('people').updateObjects([], false);
      }
    }
  });

  _exports.default = _default;
});