define("herer-web/controllers/state-link/manual", ["exports", "herer-web/mixins/pageable", "herer-web/constants", "moment"], function (_exports, _pageable, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _columnsByProvider, _columnsByProvider2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var wrap = true; // Just to make ellipsis show in the front so the last 4 digits of an id are always visible.

  var rtl = true;
  var reportsByName = {
    'reportable_sale': {
      label: 'Sales (new)',
      value: 'reportable_sale',
      filename: 'sales_new',
      linkTo: 'order',
      columnsByProvider: (_columnsByProvider = {}, _defineProperty(_columnsByProvider, _constants.default.stateCredentialType.metrc, ['salesDate', 'customerType', 'patientNumber', 'caregiverNumber', 'packageLabel', 'quantityValue', 'unitOfMeasure', 'total']), _defineProperty(_columnsByProvider, _constants.default.stateCredentialType.leafData, ['externalId', 'soldAt', 'status', 'type', 'patientNumber', 'caregiverNumber', 'employeeLicenseNumber', 'itemQty', 'total', 'globalInventoryId']), _defineProperty(_columnsByProvider, _constants.default.stateCredentialType.biotrack, []), _columnsByProvider),
      columns: {
        caregiverNumber: {
          label: 'Caregiver Num'
        },
        customerType: {
          label: 'Customer Type'
        },
        employeeLicenseNumber: {
          label: 'Employee License Num',
          rtl: rtl
        },
        externalId: {
          label: 'External ID',
          rtl: rtl
        },
        itemQty: {
          label: 'Item Quantity'
        },
        packageLabel: {
          label: 'Package Label'
        },
        globalInventoryId: {
          label: 'Global Inventory ID',
          rtl: rtl
        },
        patientNumber: {
          label: 'Patient Num'
        },
        quantityValue: {
          label: 'Quantity'
        },
        salesDate: {
          label: 'Sales Date'
        },
        soldAt: {
          label: 'Sold At'
        },
        status: {
          label: 'Status'
        },
        total: {
          label: 'Total'
        },
        type: {
          label: 'Type'
        },
        unitOfMeasure: {
          label: 'Unit of Measure'
        }
      }
    },
    'reportable_adjustment': {
      label: 'Package Adjustments',
      value: 'reportable_adjustment',
      filename: 'package_adjustments',
      linkTo: null,
      columnsByProvider: (_columnsByProvider2 = {}, _defineProperty(_columnsByProvider2, _constants.default.stateCredentialType.metrc, ['packageLabel', 'adjustmentQuantity', 'adjustmentUnitOFMeasure', 'reason', 'optionalNote', 'adjustmentDate', 'employeeLicenseNumber']), _defineProperty(_columnsByProvider2, _constants.default.stateCredentialType.leafData, ['globalInventoryId', 'adjustmentQuantity', 'adjustmentUnitOfMeasure', 'reason', 'optionalNote', 'externalId', 'adjustedAt']), _defineProperty(_columnsByProvider2, _constants.default.stateCredentialType.biotrack, []), _columnsByProvider2),
      columns: {
        adjustedAt: {
          label: 'Adjusted At'
        },
        adjustmentDate: {
          label: 'Adjustment Date'
        },
        adjustmentQuantity: {
          label: 'Quantity'
        },
        adjustmentUnitOfMeasure: {
          label: 'Unit of Measure'
        },
        employeeLicenseNumber: {
          label: 'Employee Lic. No',
          rtl: rtl
        },
        externalId: {
          label: 'External ID',
          rtl: rtl
        },
        optionalNote: {
          label: 'Note',
          wrap: wrap
        },
        packageLabel: {
          label: 'Package Label'
        },
        globalInventoryId: {
          label: 'Global Inventory ID',
          rtl: rtl
        },
        reason: {
          label: 'Reason'
        }
      }
    }
  };
  var reports = [reportsByName['reportable_sale'], reportsByName['reportable_adjustment']]; // For filtering/filename

  var dateFormat = 'YYYY-MM-DD';

  var _default = Ember.Controller.extend(_pageable.default, {
    http: Ember.inject.service(),
    salesDate: new Date(),
    today: new Date(),
    reports: reports,
    queryParams: ['day', 'report'],
    selectedReport: Ember.computed('model.model', function () {
      return reportsByName[this.get('model.model')] || reports[0];
    }),
    rawValue: function rawValue(colInfo, rowData) {
      return rowData.get(colInfo);
    },
    columns: Ember.computed('stateCredential.type', 'model.model', function () {
      var stateCredentialType = this.get('stateCredential.type');
      var sr = this.get('selectedReport');
      var allColumns = sr.columns;
      var columnsByProvider = sr.columnsByProvider[stateCredentialType];
      return columnsByProvider.map(function (c) {
        return Object.assign({
          value: c
        }, allColumns[c]);
      });
    }),
    actions: {
      handleRowClick: function handleRowClick(route, uuid) {
        this.transitionToRoute(route, uuid);
      },
      setDateFilterValue: function setDateFilterValue(value) {
        this.set('salesDate', value);
        var formattedDate = (0, _moment.default)(this.salesDate).format(dateFormat);
        this.replaceRoute({
          queryParams: {
            day: formattedDate
          }
        });
        this.set('model.day', formattedDate);
      },
      setSelectedReport: function setSelectedReport(_ref) {
        var value = _ref.value;
        this.replaceRoute({
          queryParams: {
            report: value
          }
        });
        this.get('model').set('model', value);
      },
      export: function _export() {
        var sr = this.get('selectedReport');
        var formattedDay = (0, _moment.default)(this.salesDate).format(dateFormat);
        return Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(sr.value, "s.csv?day=").concat(formattedDay)).then(function (result) {
          var filename;
          var headers = result.headers;
          var fileNameHeader = headers.map['content-disposition'];
          var fileNameRegex = /filename="([\w-_]+.csv)"/;

          if (fileNameRegex.test(fileNameHeader)) {
            filename = fileNameHeader.match(fileNameRegex)[1];
          } else {
            filename = "".concat(formattedDay, "_").concat(sr.filename, ".csv");
          }

          return Ember.RSVP.hash({
            filename: filename,
            blob: result.blob()
          });
        }).then(function (data) {
          var fileBlob = new Blob([data.blob], {
            type: 'text/csv'
          });
          var objectUrl = window.URL.createObjectURL(fileBlob);
          var anchor = document.getElementById('state-link-download');
          anchor.href = objectUrl;
          anchor.download = data.filename;
          anchor.click();
        });
      }
    }
  });

  _exports.default = _default;
});