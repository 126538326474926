define("herer-web/templates/qs-reports/inventory-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T6j94wHs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Inventory Value Report\",\"Shows you the total value of your inventory for the date you select.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12886994842003-Inventory-on-Hand-Analyze-your-inventory-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"10\",\"inventory_value_header\"]],{\"statements\":[[0,\"  \"],[1,[28,\"qs-report-data-refresh\",null,[[\"reportName\",\"showFlashMessage\"],[\"InventoryValueReport\",[28,\"route-action\",[\"showFlashMessage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"InventoryValueReport\",\"inventory_value_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/inventory-value.hbs"
    }
  });

  _exports.default = _default;
});