define("herer-web/utils/id-parsers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /*
      %<2_chars><city>^fname(&middle)&lname^address1^?;<6_chars_unidentified>02<7_chars_license>=<YYMM_expiration><YYYYMMDD_DOB>?
    */
    CA: {
      delim: '^',
      parts: {
        0: function _(city) {
          return {
            city: city
          };
        },
        1: function _(name) {
          var nameParts = name.split('$');

          if (!nameParts.length) {
            return {
              first_name: name
            };
          }

          return {
            first_name: nameParts.length > 1 ? nameParts[1] : null,
            last_name: nameParts[0]
          };
        },
        2: function _(address1) {
          return {
            address1: address1
          };
        },
        3: function _(raw) {
          // Most fragile portion. Once we get a second real ID we should compare
          // parts[0] <6_chars_unidentified><2_chars_alpha_transform><7_chars_license_num>
          // parts[1] <YYMM_expiration><YYYYMMDD_DOB>
          var stripped = raw.substring(raw.indexOf('?') + 1, raw.lastIndexOf('?'));
          var parts = stripped.split('=');
          var num = parseFloat(parts[0].substring(7, 9));
          var dln_alpha = String.fromCharCode(num + 97 - 1).toUpperCase();
          var dln_num = parts[0].substr(9);
          return {
            driver_license_number: "".concat(dln_alpha).concat(dln_num)
          };
        }
      }
    }
  };
  _exports.default = _default;
});