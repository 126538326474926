define("herer-web/serializers/inventory-item", ["exports", "@ember-data/serializer/rest", "active-model-adapter", "moment"], function (_exports, _rest, _activeModelAdapter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      product: {
        serialize: 'ids',
        deserialize: 'records'
      },
      inventory_item_places: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var inventory_items = payload.inventory_items,
          inventory_item = payload.inventory_item;

      if (inventory_items) {
        inventory_items.forEach(this.normalizeInventoryItem);
      }

      if (inventory_item) {
        this.normalizeInventoryItem(inventory_item);
      }

      return this._super.apply(this, arguments);
    },
    normalizeInventoryItem: function normalizeInventoryItem(inventoryItem) {
      if (Ember.isPresent(inventoryItem.harvest_date)) {
        inventoryItem.harvest_date = (0, _moment.default)(inventoryItem.harvest_date).startOf('day').format();
      }
    }
  });

  _exports.default = _default;
});