define("herer-web/components/id-detection/error-modal", ["exports", "herer-web/components/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    error: null,
    modalClassName: '.card-swipe-modal',
    errorHeader: Ember.computed('error', function () {
      var error = this.get('error');
      var errorHeader;

      if (error) {
        errorHeader = Ember.get(error, 'viewData.header');
      }

      return errorHeader;
    }),
    errorMessage: Ember.computed('error', function () {
      var error = this.get('error');
      var errorMessage;

      if (error) {
        errorMessage = Ember.get(error, 'viewData.messageBody');
      }

      return errorMessage;
    }),
    errorSubtitle: Ember.computed('error', function () {
      var error = this.get('error');
      var errorSubtitle;

      if (error) {
        errorSubtitle = Ember.get(error, 'viewData.subtitle');
      }

      return errorSubtitle;
    }),
    errorTitle: Ember.computed('error', function () {
      var error = this.get('error');
      var errorTitle;

      if (error) {
        errorTitle = Ember.get(error, 'viewData.title');
      }

      return errorTitle;
    }),
    showBarcode: Ember.computed('error', function () {
      var error = this.get('error');
      var showBarcode;

      if (error) {
        showBarcode = Ember.get(error, 'viewData.showBarcode');
      }

      return showBarcode;
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (Ember.isEmpty(modal)) {
        return;
      }

      var error = this.get('error');

      if (Ember.isPresent(error)) {
        modal.modal({
          backdrop: 'static'
        });
      } else {
        modal.modal('hide');
      }
    }
  });

  _exports.default = _default;
});