define("herer-web/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w5lGWpKl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"successMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 errors\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"alert alert-success\"],[10,\"data-test-text\",\"success_message\"],[8],[0,\"\\n        \"],[1,[22,\"successMessage\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"data-test-ux\"],[\"Account Settings\",\"These are your personal account settings.\",\"account_settings_header\"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form form-horizontal\"],[8],[0,\"\\n  \"],[5,\"user-form\",[],[[\"@user\",\"@proxiedLocations\",\"@checkAllLocations\",\"@disableEmployeeAPIKeyInputs\",\"@showEmployeeAPIKey\"],[[22,\"model\"],[22,\"proxiedLocations\"],[22,\"checkAllLocations\"],[22,\"disableInputs\"],[22,\"showEmployeeAPIKey\"]]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row form-save\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-7 col-sm-push-4\"],[8],[0,\"\\n          \"],[1,[28,\"save-button\",null,[[\"onSave\",\"isSaving\",\"disabled\",\"prompt\"],[[28,\"action\",[[23,0,[]],\"saveUser\"],null],[24,[\"model\",\"isSaving\"]],[24,[\"cannotSaveUser\"]],\"Save Account Settings\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/account.hbs"
    }
  });

  _exports.default = _default;
});