define("herer-web/mixins/inventory-printing", ["exports", "herer-web/constants", "herer-web/utils/error-reporter", "bootbox"], function (_exports, _constants, _errorReporter, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PRINTER_ERROR_MAPPING = {
    'no printer selected': 'No printer selected. Please connect a printer, select it as the default, then try again.',
    'no printers found': 'No printers found. Please connect a printer and try again.',
    'Network Error': 'Could not connect to Greenbits Label Printing App. Please make sure the app is running, refresh this page and try again.',
    'axios timeout': 'Connection to the printer timed out. Please make sure it is connected and available on the designated port, refresh this page and try again.',
    'timeout exceeded': 'Connection to the printer timed out. Please make sure it is connected and available on the designated port, refresh this page and try again.',
    'gb printer error': 'Communication with the Greenbits Printer App failed'
  };

  var _default = Ember.Mixin.create({
    printable: null,
    isPrinting: false,
    labelPrinter: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    router: Ember.inject.service(),
    canPrint: Ember.computed.alias('labelPrinter.canPrint'),
    printDisabled: Ember.computed.not('canPrint'),
    init: function init() {
      this._super();

      this.get('labelPrinter').ping().catch(function () {}); // This mixin will now accept a `printable`, which is used to create the label. It still
      // defaults to inventoryItem, so nothing changes for where this is being used in most places.
      // But now other objects that have similar properties to an inventory item can be passed in as
      // `printable` in order to print a label for them. As of now, only printing a barcode for a
      // lot-adjustment has been tested as a valid "printable".

      if (!this.get('printable')) {
        var inventoryItem = this.get('inventoryItem');
        this.set('printable', inventoryItem);
      }
    },
    canPrintUnitWeightLabel: Ember.computed('printable.quantity', 'printable.product.product_type', function () {
      var printable = this.get('printable');
      var isUnit = printable.get('quantity.isUnit');
      var traceabilityType = printable.get('product.product_type.traceability_type');
      var isFlower = traceabilityType === _constants.default.traceabilityType.usableMarijuana || traceabilityType === _constants.default.traceabilityType.shakeTrim;
      var isHemp = traceabilityType === _constants.default.traceabilityType.hemp;
      var canPrintPackageLabels = this.get('featureManager').can('package-labels');
      return isUnit && (isFlower || isHemp) && canPrintPackageLabels;
    }),
    handlePrintingError: function handlePrintingError(error) {
      if (PRINTER_ERROR_MAPPING.hasOwnProperty(error)) {
        _bootbox.default.alert(PRINTER_ERROR_MAPPING[error]);
      } else {
        var metadata = {
          title: 'LabelPrintError'
        };
        (0, _errorReporter.errorReporter)(error, metadata);
        var message = 'Sorry, something went wrong when trying to print your labels. If you still have this issue, chat Support.';

        if (error) {
          message += "<br/><br/>Error details:<br/><code>".concat(error, "</code>");
        }

        _bootbox.default.alert(message);
      }
    },
    promptForLabelQuantity: function promptForLabelQuantity(_callback, defaultQty) {
      if (!defaultQty) {
        defaultQty = this.get('printable.quantity');
      }

      if (defaultQty.get('isWeight')) {
        defaultQty = 1;
      }

      _bootbox.default.prompt({
        title: 'How many labels do you want to print?',
        value: defaultQty,
        callback: function callback(quantity) {
          if (!Ember.isPresent(quantity)) {
            return;
          }

          var parsedQuantity = parseInt(quantity, 10);

          if (isNaN(parsedQuantity)) {
            return;
          }

          _callback(parsedQuantity);
        }
      });
    },
    printBarcode: function printBarcode(quantity) {
      var _this = this;

      this.set('isPrinting', true);
      this.get('labelPrinter').printSkuLabel({
        inventoryItem: this.get('printable'),
        labelPreference: this.get('labelPreference'),
        quantity: quantity
      }).then(function () {
        _this.set('isPrinting', false);

        _bootbox.default.alert('Labels sent to printer');
      }).catch(function (e) {
        _this.set('isPrinting', false);

        _this.handlePrintingError(e);
      });
    },
    printUnitWeightLabel: function printUnitWeightLabel(quantity) {
      var _this2 = this;

      var printable = this.get('printable');
      this.set('isPrinting', true);
      this.get('labelPrinter').printWeightLabel({
        labelPreference: this.get('labelPreference'),
        receiptPreference: this.get('receiptPreference'),
        product: printable.get('product'),
        inventoryItem: printable,
        quantity: quantity,
        sku: printable.get('sku'),
        weight: printable.get('weight')
      }).then(function () {
        _this2.set('isPrinting', false);

        _bootbox.default.alert('Labels sent to printer');
      }).catch(function (e) {
        _this2.set('isPrinting', false);

        _this2.handlePrintingError(e);
      });
    },
    showPrinterCTA: function showPrinterCTA() {
      var _this3 = this;

      var goToPrinterSetup = function goToPrinterSetup() {
        // Since we're in a component mixin this was less hacky than trying to
        // bubble up the action all the way into whichever controller we're in
        _this3.router.transitionTo('label-printer');
      };

      _bootbox.default.dialog({
        message: 'Printer not found. Would you like to set it up now?',
        buttons: {
          cancel: {
            label: 'Cancel'
          },
          ok: {
            label: 'OK',
            callback: goToPrinterSetup
          }
        }
      });
    },
    actions: {
      convert: function convert() {
        this.convertAction && this.convertAction(this.get('inventoryItem'));
      },
      printBarcode: function printBarcode() {
        var _this4 = this;

        if (this.get('printDisabled')) {
          return this.showPrinterCTA();
        }

        this.promptForLabelQuantity(function (quantity) {
          _this4.printBarcode(quantity);
        });
      },
      printUnitWeightLabel: function printUnitWeightLabel() {
        var _this5 = this;

        if (this.get('printDisabled')) {
          return this.showPrinterCTA();
        }

        this.promptForLabelQuantity(function (quantity) {
          _this5.printUnitWeightLabel(quantity);
        });
      }
    }
  });

  _exports.default = _default;
});