define("herer-web/controllers/reporting-failures", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/lot-number-formatter"], function (_exports, _pageable, _lotNumberFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageable.default, _lotNumberFormatter.default, {
    actions: {
      refresh: function refresh() {
        this.fetchObjects();
      }
    }
  });

  _exports.default = _default;
});