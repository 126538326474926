define("herer-web/models/discount", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    total: (0, _model.attr)('number'),
    discount_type: (0, _model.attr)('number'),
    percent: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    discount_plan: (0, _model.belongsTo)('discount_plan', {
      async: true
    })
  });

  _exports.default = _default;
});