define("herer-web/mixins/reportable", ["exports", "herer-web/mixins/taskable", "bootbox"], function (_exports, _taskable, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_taskable.default, {
    isSubmitDisabled: false,
    reportExportFailed: function reportExportFailed() {
      return _bootbox.default.alert("Sorry, something went wrong when trying to run your report. " + "If you still have this issue, chat Support.");
    },
    triggerTaskAndPoll: function triggerTaskAndPoll(url) {
      var _this = this;

      var http = this.get('http');
      this.set('isSubmitDisabled', true);
      return http.get(url).then(function (response) {
        _this.set('isSubmitDisabled', false);

        _this.set('task', response.data.task);

        _this.get('taskManager').poll();

        _this.pollTask();
      }).catch(function () {
        _this.set('isSubmitDisabled', false);

        return _this.reportExportFailed();
      });
    }
  });

  _exports.default = _default;
});