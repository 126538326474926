define("herer-web/components/product-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    launchDarkly: Ember.inject.service(),
    product: null,
    onSearch: null,
    hasError: false,
    disabled: false,
    productDisplayName: Ember.computed('product', function () {
      var product = this.get('product');
      return product.name;
    }),
    actions: {
      search: function search() {
        this.onSearch();
      }
    }
  });

  _exports.default = _default;
});