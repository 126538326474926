define("herer-web/mixins/receipt-preference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findReceiptPreference: function findReceiptPreference(store) {
      return store.findAll('receipt-preference').then(function (receiptPreference) {
        return receiptPreference.get('firstObject');
      });
    }
  });

  _exports.default = _default;
});