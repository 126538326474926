define("herer-web/models/pricing-tier", ["exports", "@ember-data/model", "herer-web/utils/validation", "yup"], function (_exports, _model, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      price: (0, _yup.number)().required(),
      quantity: (0, _yup.number)().required()
    }),
    price: (0, _model.attr)('number'),
    priceConditionType: (0, _model.attr)('number'),
    quantity: (0, _model.attr)('weight'),
    quantityMax: (0, _model.attr)('weight'),
    quantityValue: Ember.computed.readOnly('quantity.value')
  });

  _exports.default = _default;
});