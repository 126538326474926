define("herer-web/controllers/application", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    redirectAfterLogin: null,
    showReloader: false,
    flashMessage: null,
    flashAlertClass: 'alert-success',
    _flashLastTimer: null,
    showNavigation: Ember.computed.not('currentLocation.isSuspended'),
    locations: Ember.computed('currentUser', 'currentLocation', function () {
      var hasCurrentUser = Ember.isPresent(this.get('currentUser'));
      var hasCurrentLocation = Ember.isPresent(this.get('currentLocation'));

      if (hasCurrentUser && hasCurrentLocation) {
        return this.get('sortedLocations');
      }
    }),
    showFlashView: function showFlashView(str) {
      var alertClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'alert-success';
      this.set('flashMessage', str);
      this.set('flashAlertClass', alertClass);

      if (this._flashLastTimer) {
        Ember.run.cancel(this._flashLastTimer);
      }

      this._flashLastTimer = Ember.run.later(this, function () {
        this._flashLastTimer = null;
        this.set('flashMessage', null);
      }, 5000);
    },
    actions: {
      closeFlashView: function closeFlashView() {
        this.set('flashMessage', null);
      }
    }
  });

  _exports.default = _default;
});