define("herer-web/components/crud-list-form", ["exports", "herer-web/mixins/infinite-scroll", "herer-web/mixins/slide-out-drawer", "herer-web/utils/mixpanel", "bootbox", "lodash/union", "lodash/isFunction"], function (_exports, _infiniteScroll, _slideOutDrawer, _mixpanel, _bootbox, _union, _isFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_infiniteScroll.default, _slideOutDrawer.default, {
    store: Ember.inject.service(),
    header: null,
    subheader: null,
    accountWide: false,
    models: null,
    modelName: null,
    modelDisplayName: Ember.computed.oneWay('modelName'),
    modelIconClass: null,
    disableEdit: false,
    isLoadingMore: Ember.computed.alias('models.isLoadingMore'),
    lowercaseModelName: Ember.computed('modelName', function () {
      return this.get('modelName').toLowerCase();
    }),
    lowercaseModelDisplayName: Ember.computed('modelDisplayName', function () {
      var modelDisplayName = this.get('modelDisplayName');

      if (Ember.isPresent(modelDisplayName)) {
        modelDisplayName = modelDisplayName.toLowerCase();
      }

      return modelDisplayName;
    }),
    customSave: null,
    customRowSection: null,
    onLoadMore: null,
    beforeCreate: null,
    onCreate: null,
    onEdit: null,
    onCancel: null,
    canCreate: false,
    canDelete: false,
    canUpdate: false,
    select: null,
    showErrorMessage: false,
    showSaveMessage: false,
    hasHamburgerMenu: false,
    stayOpenOnSave: false,
    activeRowStatusAttribute: null,
    modelList: Ember.computed('models.[]', 'selected.isNew', function () {
      if (this.get('selected.isNew') && Ember.isPresent(this.get('models'))) {
        return (0, _union.default)([this.get('selected')], this.get('models').toArray());
      }

      return this.get('models');
    }),
    mergedErrors: Ember.computed('selected.validations.errors', 'selected.errors.[]', function () {
      var validationErrors = this.selected.get('validations.errors') || {};
      var remoteErrors = this.selected.get('errors') ? this.selected.get('errors').toArray() : [];
      var normalizedValidationErrors = Object.entries(validationErrors).reduce(function (acc, _ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            messages = _ref2[1];

        var normalizedError = {
          attribute: key,
          messages: messages
        };
        acc.push(normalizedError);
        return acc;
      }, []);
      var combinedErrors = [].concat(_toConsumableArray(remoteErrors), _toConsumableArray(normalizedValidationErrors));
      return combinedErrors.reduce(function (acc, error) {
        var key = error.attribute;
        var messages = [error.message, error.messages].filter(Boolean);

        if (acc[key]) {
          acc[key] = [].concat(_toConsumableArray(acc[key]), _toConsumableArray(messages));
        } else {
          acc[key] = messages;
        }

        return acc;
      }, {});
    }),
    resetMessages: function resetMessages() {
      this.set('showSaveMessage', false);
      this.set('showErrorMessage', false);
    },
    deselect: function deselect() {
      this.hideDrawer();
      this.set('selected', null);
      this.resetMessages();
    },
    save: function save(model) {
      var _this = this;

      var customSave = this.get('customSave');
      var promise;

      if (Ember.isPresent(customSave)) {
        if (['tax-item', 'user'].includes(this.modelName)) {
          promise = new Ember.RSVP.Promise(function (resolve, reject) {
            customSave(resolve, reject);
          });
        } else {
          promise = customSave(model);
        }
      } else {
        promise = model.save();
      }

      return promise.then(function (model) {
        _this.set('showSaveMessage', true);

        model.rollbackAttributes();

        var models = _this.get('models');

        if ((0, _isFunction.default)(models.fetchObjects)) {
          models.fetchObjects();
        }

        _this.set('showSaveMessage', false);

        if (!_this.get('stayOpenOnSave')) {
          _this.unselect();
        }
      });
    },
    unselect: function unselect() {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.deselect();
      }, 750);
    },
    dependentModel: function dependentModel(errorMessage) {
      if (!errorMessage.includes('Cannot destroy')) {
        return;
      }

      return errorMessage.split('with ')[1];
    },
    trackEvent: function trackEvent(eventName) {
      (0, _mixpanel.mixpanelTrack)("".concat(this.get('modelName'), ".").concat(eventName));
    },
    create: function create() {
      this.trackEvent('create');
      this.resetMessages();
      var newModel = this.get('store').createRecord(this.get('lowercaseModelName'));
      this.onCreate && this.onCreate(newModel);
      this.set('selected', newModel);
      this.showDrawer();
    },
    cancel: function cancel(model) {
      this.deselect();

      if (model) {
        // Cancel can be called twice in the event of closing from the cancel button, as it's
        // also specified as the afterHideDrawer action to catch implicit cancels.
        //
        // This prevents an error calling cancel with no selected model.
        if (model.get('isNew') && !model.get('isSaving')) {
          model.deleteRecord();
        } else {
          model.rollbackAttributes();
        }

        if ((0, _isFunction.default)(model.get('validations.resetErrors'))) {
          model.validations.resetErrors();
        }

        this.onCancel && this.onCancel(model);
      }
    },
    actions: {
      loadMore: function loadMore() {
        this.onLoadMore && this.onLoadMore();
      },
      create: function create() {
        if (this.get('beforeCreate')) {
          return this.beforeCreate(this.create.bind(this));
        }

        this.create();
      },
      edit: function edit(model, disableEdit) {
        if (!disableEdit) {
          this.trackEvent('edit');
          this.resetMessages();
          this.onEdit && this.onEdit(model);
          this.set('selected', model);
          this.showDrawer();
        }
      },
      save: function save(model) {
        var _this3 = this;

        this.trackEvent('save');
        this.resetMessages();
        var validations = model.get('validations');
        var hasValidations = Boolean(validations || model.validate);
        var promise;

        if (hasValidations && validations.canValidate) {
          var validationPromise = model.validate ? model.validate() : validations.validate();
          promise = validationPromise.then(function () {
            return _this3.save(model);
          });
        } else {
          promise = this.save(model);
        }

        return promise.catch(function (e) {
          console.error(e);

          _this3.set('showErrorMessage', true);
        });
      },
      handleCancel: function handleCancel(model) {
        var _this4 = this;

        this.trackEvent('cancel');

        if (this.showConfirmationAlert && model.get('hasDirtyAttributes')) {
          return this.showConfirmationAlert({
            success: function success() {
              return _this4.cancel(model);
            }
          });
        } else {
          this.cancel(model);
        }
      },
      delete: function _delete(model) {
        var _this5 = this;

        this.trackEvent('delete');
        var name = model.get('name');
        var message = "Are you sure you want to delete \"".concat(name, "\"?");
        return _bootbox.default.confirm(Ember.Handlebars.Utils.escapeExpression(message), function (result) {
          if (result) {
            model["".concat(model.isRestorable ? 'softDelete' : 'destroy', "Record")]().then(function () {
              _this5.set('selected', null);

              _this5.resetMessages(); // PagedArray - remove to make it disappear from the UI.


              _this5.get('models').removeObject(model);

              _this5.hideDrawer();

              var deleted = _this5.modelName === 'user' ? name : _this5.modelDisplayName;

              _this5.toast.success("".concat(deleted, " was successfully deleted."), '', {
                closeButton: true
              });
            }).catch(function (e) {
              console.error(e);
              model.rollbackAttributes();
              var message = "There was a problem deleting \"".concat(name, "\".");

              if (Ember.isPresent(e.errors) && Ember.isPresent(e.errors.base)) {
                var dependentModel = _this5.dependentModel(e.errors.base[0]);

                if (Ember.isPresent(dependentModel)) {
                  message = "Cannot delete \"".concat(name, "\" because it currently has\n                  ").concat(dependentModel, " associated with it.");

                  if (dependentModel === 'products') {
                    message += " Update all products for this ".concat(_this5.get('lowercaseModelDisplayName'), "\n                    and try again.");
                  }
                } else {
                  message = e.errors.base[0];
                }
              }

              _bootbox.default.alert(Ember.Handlebars.Utils.escapeExpression(message));
            });
          }
        });
      },
      restore: function restore(model) {
        var _this6 = this;

        this.trackEvent('restore');
        var name = model.get('name');
        var message = "Are you sure you want to restore \"".concat(name, "\"?");
        return _bootbox.default.confirm(Ember.Handlebars.Utils.escapeExpression(message), function (result) {
          if (result) {
            model.set('is_deleted', false);
            model.set('deleted_at', null);
            model.save().then(function () {
              _this6.set('selected', null);

              _this6.resetMessages();

              _this6.hideDrawer();

              var restored = _this6.modelName === 'user' ? name : _this6.modelDisplayName;
              var success = "".concat(restored, " was successfully restored.");

              if (_this6.modelName === 'user') {
                success += 'Please update Accessible Locations to ensure permissions are correct.';
              }

              _this6.toast.success(success, '', {
                closeButton: true
              });
            }).catch(function (e) {
              console.error(e);
              model.rollbackAttributes();
              var message = "There was a problem restoring \"".concat(name, "\".");

              _bootbox.default.alert(Ember.Handlebars.Utils.escapeExpression(message));
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});