define("herer-web/templates/components/qs-report-data-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FNukk91O",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header-actions\",null,[[\"width\"],[\"2\"]],{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"data-test-action\",\"refresh_data\"],[12,\"disabled\",[22,\"inProgress\"]],[3,\"action\",[[23,0,[]],\"refreshReportData\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-sync-alt \",[28,\"if\",[[24,[\"inProgress\"]],\"fa-spin\"],null]]]],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"inProgress\"]]],null,{\"statements\":[[0,\"        Refreshing Data…\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Refresh Data\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/qs-report-data-refresh.hbs"
    }
  });

  _exports.default = _default;
});