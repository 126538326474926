define("herer-web/controllers/return-manifests", ["exports", "herer-web/mixins/pageable"], function (_exports, _pageable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageable.default);

  _exports.default = _default;
});