define("herer-web/components/packages/reconcile", ["exports", "herer-web/components/base-modal", "herer-web/constants", "herer-web/mixins/taskable"], function (_exports, _baseModal, _constants, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TASK_TIMEOUT = 30000;
  var PAGE_SIZE = 1000;

  var _default = _baseModal.default.extend(_taskable.default, {
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    tasksTimers: undefined,
    reconciliationErrors: undefined,
    modalClassName: '.reconcile-with-metric',
    packages: undefined,
    packagesTraceabilityStatuses: undefined,
    isLoading: true,
    traceabilityWaitElapsed: false,
    noPackagesQuantityDiff: Ember.computed.equal('notSynchronizedCount', 0),
    notSynchronizedCount: 0,
    reconciliationsDone: 0,
    totalToReconcile: 0,
    canReconcile: Ember.computed.gt('totalToReconcile', 0),
    stepName: 'list',
    progressBarWidth: Ember.computed('reconciliationPercentageComplete', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('reconciliationPercentageComplete'), "%;"));
    }),
    reconciliationPercentageComplete: Ember.computed('reconciliationsDone', 'totalToReconcile', function () {
      if (!this.get('totalToReconcile')) {
        return 0;
      }

      return this.get('reconciliationsDone') / this.get('totalToReconcile') * 100;
    }),
    // if we detect that there are some packages already that should be synced, then display table
    // check is on packages/metrc-reconciler/row level, therefore DOM element must be present for request to be made
    fullySynchronized: Ember.computed('noPackagesQuantityDiff', 'isLoading', 'traceabilityWaitElapsed', function () {
      return !this.get('isLoading') && this.get('noPackagesQuantityDiff') && this.get('traceabilityWaitElapsed');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.packagesTraceabilityStatuses = this.packagesTraceabilityStatuses || {};
      this.tasksTimers = this.tasksTimers || {};
      this.reconciliationErrors = this.reconciliationErrors || [];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        modal.modal({
          backdrop: 'static'
        });
      }

      this.fetchPackages();
    },
    clearTasksTimers: function clearTasksTimers() {
      clearTimeout(this.get('taskTimer'));
    },
    willDestroyElement: function willDestroyElement() {
      this.clearTasksTimers();

      this._super.apply(this, arguments);
    },
    isClosed: function isClosed() {
      // package fetch might take long time to finish, in case user closes modal, before it finishes
      // do not set it to avoid modifying destroyed component
      return this.get('isDestroyed') || this.get('isDestroying');
    },
    fetchPackages: function fetchPackages() {
      var _this = this;

      if (this.isClosed()) {
        return;
      }

      this.set('isLoading', true); // ember does not watch on object entries length, it has to be set manually

      this.set('totalToReconcile', 0);
      this.set('notSynchronizedCount', 0);
      this.set('reconciliationErrors', []);
      this.get('store').query('package', {
        by_status: 0,
        limit: this.get('pageSize')
      }).then(function (packages) {
        // long running query, if modal has been closed before it finished fetch, reject to skip
        if (_this.isClosed()) {
          return Promise.reject();
        }

        _this.set('packages', packages);

        _this.set('isLoading', false);

        _this.set('traceabilityWaitElapsed', false);
      }).then(function () {
        // traceability check might take some time, in this period we might falsy display sync message
        // give it some time for at least couple checks and the turn semaphore on
        setTimeout(_this.flipSemaphore.bind(_this), 3 * 1000);
      });
    },
    pageSize: Ember.computed('launchDarkly.isReady', function () {
      var ffPageSize = this.get('launchDarkly').can('reconcile-with-metric-modal-batch-size', PAGE_SIZE);
      return Number(ffPageSize) || PAGE_SIZE;
    }),
    flipSemaphore: function flipSemaphore() {
      this.set('traceabilityWaitElapsed', true);
    },
    createReconciliationTask: function createReconciliationTask(uuid, _ref) {
      var _this2 = this;

      var sourceOfTruth = _ref.sourceOfTruth,
          traceabilityStatus = _ref.traceabilityStatus;

      var payload = _objectSpread(_objectSpread({}, traceabilityStatus), {}, {
        source_of_truth: sourceOfTruth
      });

      return function () {
        return _this2.get('http').post("".concat(_constants.default.apiHost, "/api/v2/packages/").concat(uuid, "/reconcile"), payload);
      };
    },
    fetchTimeout: function fetchTimeout(request) {
      return new Promise(function (resolve, reject) {
        request().then(resolve, reject);
        setTimeout(reject.bind(null, {
          ok: false,
          status: 408
        }), TASK_TIMEOUT);
      });
    },
    dispatchTasks: function dispatchTasks() {
      var _this3 = this;

      var statuses = Object.entries(this.get('packagesTraceabilityStatuses'));
      statuses.forEach(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            uuid = _ref3[0],
            payload = _ref3[1];

        var request = _this3.createReconciliationTask(uuid, payload);

        _this3.fetchTimeout(request).then(function (response) {
          return _this3.pollReconciliationTask(uuid, response.data.task);
        });
      });
    },
    onTaskComplete: function onTaskComplete(packageUuid, reconciliationTask) {
      var _this4 = this;

      this.incrementProperty('reconciliationsDone');

      if (reconciliationTask.get('error')) {
        var message = reconciliationTask.get('result.error');
        this.get('store').findRecord('package', packageUuid).then(function (foundPackage) {
          _this4.set('reconciliationErrors', [].concat(_toConsumableArray(_this4.get('reconciliationErrors')), [{
            message: message,
            lotNumber: foundPackage.get('lotNumber')
          }]));
        });
        this.set('stepName', 'error');
      }

      if (this.get('reconciliationPercentageComplete') === 100) {
        this.markAsFinished();
      }
    },
    pollReconciliationTask: function pollReconciliationTask(packageUuid, reconciliationTask) {
      var _this5 = this;

      if (this.isClosed()) {
        return this.clearTasksTimers();
      }

      this.get('store').findRecord('task', reconciliationTask.id).then(function (task) {
        if (task.get('queued') || task.get('processing')) {
          _this5.pushTimer(reconciliationTask, setTimeout(_this5.pollReconciliationTask.bind(_this5, packageUuid, reconciliationTask), 2 * 1000));
        } else {
          return _this5.onTaskComplete(packageUuid, task);
        }
      });
    },
    pushTimer: function pushTimer(reconciliationTask, timeoutID) {
      var id = reconciliationTask.id;
      var timers = this.get('tasksTimers');
      this.removeTaskTimer(reconciliationTask);
      timers[id] = timeoutID;
      this.set('tasksTimers', timers);
    },
    removeTaskTimer: function removeTaskTimer(reconciliationTask) {
      var id = reconciliationTask.id;
      var timers = this.get('tasksTimers');

      if (Ember.isPresent(timers[id])) {
        clearTimeout(timers[id]);
      }
    },
    markAsFinished: function markAsFinished() {
      // reset traceability so old state is not remembered
      this.set('packagesTraceabilityStatuses', {});

      if (this.get('stepName') !== 'error') {
        // do not transition back to list if something failed
        this.set('stepName', 'list');
        this.fetchPackages();
      }
    },
    actions: {
      triggerReconcile: function triggerReconcile() {
        this.set('stepName', 'reconciliation');
        this.set('reconciliationsDone', 0);
        this.dispatchTasks();
      },
      onReconcile: function onReconcile(_ref4) {
        var uuid = _ref4.uuid,
            sourceOfTruth = _ref4.sourceOfTruth,
            traceabilityStatus = _ref4.traceabilityStatus;
        var statuses = this.get('packagesTraceabilityStatuses');

        if (sourceOfTruth) {
          statuses[uuid] = {
            sourceOfTruth: sourceOfTruth,
            traceabilityStatus: traceabilityStatus
          };
        } else {
          delete statuses[uuid];
        }

        this.set('packagesTraceabilityStatuses', statuses); // ember does not watch on object entries length, it has to be set manually

        this.set('totalToReconcile', Object.keys(statuses).length);
      },
      onTraceabilityCheck: function onTraceabilityCheck() {
        this.incrementProperty('notSynchronizedCount');
      }
    }
  });

  _exports.default = _default;
});