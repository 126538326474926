define("herer-web/adapters/application", ["exports", "@ember-data/adapter/error", "active-model-adapter", "herer-web/constants"], function (_exports, _error2, _activeModelAdapter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    host: _constants.default.apiHost,
    namespace: "api/".concat(_constants.default.apiVersion),
    http: Ember.inject.service(),
    headers: Ember.computed('http.headers', {
      get: function get() {
        return Ember.get(this, 'http').getHeaders();
      }
    }),
    shouldReloadAll: function shouldReloadAll(_store, snapshotRecordArray) {
      return snapshotRecordArray.length === 0;
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if ([401, 403, 500, 502].includes(status)) {
        var error = new Error(payload);
        error.status = status;
        return error;
      } else if (this.isInvalid(status, headers, payload)) {
        var errors = (0, _error2.errorsHashToArray)(payload.errors);

        var _error = new _error2.InvalidError(errors);

        _error.status = status;
        return _error;
      } else {
        var response = this._super.apply(this, arguments);

        if (response instanceof Error) {
          response.status = status;
        }

        return this.handleBeta(headers, requestData, function () {
          return response;
        });
      }
    },
    // Asset reloading for canary releases.
    // CompanyId bearing Responses marked with X-GB-Beta are to be taken to *beta.greenbits.com
    // otherwise you should be on the stable release at *secure.greenbits.com
    handleBeta: function handleBeta(headers, requestData, cb) {
      var forceHost = function forceHost(to) {
        var from = to == 'beta' ? 'secure' : 'beta';
        var currentHost = window.location.href; // Skip a redirect to the login page

        if (currentHost.includes('sessions')) {
          return;
        }

        if (currentHost.includes("".concat(from, "."))) {
          window.location.href = currentHost.replace("".concat(from, "."), "".concat(to, "."));
        }
      };

      var isSendingLocation = !!this.headers['X-GB-CompanyId'];
      var responseContentType = String(headers['content-type']).toUpperCase();
      var isAjaxResponse = !!responseContentType.match(/json/i);
      var requestMethod = requestData.method.toUpperCase();
      var isGetRequest = requestMethod === 'GET';

      if (isGetRequest && isSendingLocation && isAjaxResponse) {
        var betaHeader = String(headers['x-gb-beta']).toUpperCase();
        var isBetaResponse = betaHeader == 'TRUE';

        if (isBetaResponse) {
          forceHost('beta');
        } else {
          forceHost('secure');
        }
      }

      return cb();
    }
  });

  _exports.default = _default;
});