define("herer-web/routes/qs-reports/sales-summary-report", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/models/sales-report", "herer-web/constants", "moment", "herer-web/mixins/reports-comparable"], function (_exports, _authenticatedFullPage, _salesReport, _constants, _moment, _reportsComparable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_reportsComparable.default, {
    http: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    permissionKey: 'view_sales_summary_report',
    modelName: 'sales-summary-report',
    controllerName: 'sales-summary-report',
    templateName: 'sales-summary-report',
    model: function model(params) {
      var _this = this;

      if (params.startDate === 'now') {
        params.startDate = null;
      }

      if (params.interval) {
        params.interval = null;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = Ember.get(_this, 'http');

        var timezone = _this.get('authManager.currentLocation.timezone');

        var report_params = {
          start_date: (0, _moment.default)(params.startDate).startOf('day').tz(timezone, true).toISOString(),
          end_date: (0, _moment.default)(params.endDate).endOf('day').tz(timezone, true).toISOString(),
          use_new_report: _this.get('launchDarkly').can('romulan-migration-todays-sales-report') ? 'salesReport' : null
        };
        var endpoint = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/sales_summary");
        var url = http.createUrl(endpoint, report_params);
        http.get(url).then(function (response) {
          _this.compareReport('sales-summary', JSON.parse(JSON.stringify(response.data)), endpoint, report_params);

          resolve(_salesReport.default.create(response.data.report));
        }).catch(function (response) {
          reject(new Error("".concat(response.status, ": ").concat(response.data.error)));
        });
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});