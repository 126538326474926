define("herer-web/routes/brands", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, _pageableRoute.default, {
    sideNavTemplateName: 'inventory-nav',
    modelName: 'brand',
    sortBy: 'name',
    sortDirection: 'asc',
    permissionKey: 'view_brands'
  });

  _exports.default = _default;
});