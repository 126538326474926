define("herer-web/initializers/moment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  var initialize = function initialize() {
    _moment.default.updateLocale('en', {
      week: {
        dow: 1
      }
    });
  };

  _exports.initialize = initialize;
  var MomentInitializer = {
    name: 'moment',
    initialize: initialize
  };
  var _default = MomentInitializer;
  _exports.default = _default;
});