define("herer-web/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P2mSxUku",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sk-double-bounce\"],[10,\"data-test-ux\",\"spinner\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-child sk-double-bounce1\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-child sk-double-bounce2\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});