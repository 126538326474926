define("herer-web/controllers/sessions/new", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context"], function (_exports, _alertUnsavedController, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    errors: [],
    disabled: false,
    applicationController: Ember.inject.controller('application'),
    actions: {
      login: function login() {
        var _this = this;

        this.set('disabled', true);
        this.set('errors', []);
        var username = this.get('model.username');
        var password = this.get('model.password');
        this.get('authManager').authenticateWithPassword(username, password).then(function () {
          var applicationController = _this.get('applicationController');

          var redirect = applicationController.get('redirectAfterLogin');
          var params = redirect && redirect.params ? redirect.params : null;
          var route = redirect && redirect.routeName ? redirect.routeName : 'index';

          if (route === 'sessions.new') {
            route = 'index';
          }

          _this.set('disabled', false);

          _this.transitionToRoute(route, {
            queryParams: params
          });

          applicationController.set('redirectAfterLogin', null);
        }).catch(function (error) {
          console.log(error);

          _this.get('errors').pushObject(error.message);

          _this.set('disabled', false);
        });
      },
      setUsername: function setUsername(_ref) {
        var value = _ref.target.value;
        Ember.set(this, "model.username", value);
      },
      setPassword: function setPassword(_ref2) {
        var value = _ref2.target.value;
        Ember.set(this, "model.password", value);
      }
    }
  });

  _exports.default = _default;
});