define("herer-web/components/traceability-notification-row", ["exports", "herer-web/constants", "herer-web/mixins/context", "herer-web/utils/validation", "yup"], function (_exports, _constants, _context, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var metrcPackageRegEx = new RegExp('^(1[Aa][0-9a-fA-F]{22}|[Aa][0-9a-fA-F]{23}|[ABCDEF][0-9a-fA-F]{23})$');

  var _default = Ember.Component.extend(_context.default, {
    toast: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    http: Ember.inject.service(),
    classNames: ['row', 'table-row'],
    isEditing: false,
    validations: (0, _validation.validations)({
      isMetrc: (0, _yup.bool)(),
      newLotNumber: (0, _yup.string)().nullable().when('isMetrc', {
        is: true,
        then: (0, _yup.string)().length(24).matches(metrcPackageRegEx, 'is not valid').required(),
        otherwise: (0, _yup.string)().nullable()
      })
    }),
    showIgnoreModal: false,
    notification: null,
    newLotNumber: null,
    isSaving: false,
    successMessage: 'Successfully reassigned the unreported sales.',
    ignoreSuccessMessage: 'Successfully skipped the unreported event.',
    errors: [],
    isMetrc: Ember.computed('currentLocation', function () {
      return this.get('featureManager').can('metrc');
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        this.get("validations").validate().then(function () {
          var notification = _this.get('notification');

          var rootPath = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/traceability_notifications");
          var fullPath = "".concat(rootPath, "/").concat(notification.get('id'), "/run_resolver");

          _this.set('isSaving', true);

          Ember.get(_this, 'http').post(fullPath, {
            new_lot_number: _this.get('newLotNumber')
          }).then(function () {
            _this.set('isSaving', false);

            notification.unloadRecord();

            _this.set('notification', null);

            _this.get('toast').success(_this.get('successMessage'));
          }).catch(function (response) {
            var message = response.data ? response.data.message : response.statusText;

            _this.set('isSaving', false);

            _this.set('errors', [message]);
          });
        }).catch(function (e) {
          return console.log(e);
        });
      },
      ignore: function ignore() {
        this.set('showIgnoreModal', true);
      },
      closeIgnoreModal: function closeIgnoreModal() {
        this.set('showIgnoreModal', false);
      },
      confirmIgnoreModal: function confirmIgnoreModal() {
        var _this2 = this;

        var notification = this.get('notification');
        var rootPath = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/traceability_notifications");
        var fullPath = "".concat(rootPath, "/").concat(notification.get('id'), "/ignore");
        this.set('isSaving', true);
        this.set('showIgnoreModal', false);
        Ember.get(this, 'http').post(fullPath).then(function () {
          notification.unloadRecord();

          _this2.set('isSaving', false);

          _this2.set('notification', null);

          _this2.get('toast').success(_this2.get('ignoreSuccessMessage'));
        }).catch(function (response) {
          _this2.set('isSaving', false);

          var message = response.data ? response.data.message : response.statusText;

          _this2.set('errors', [message]);
        });
      }
    }
  });

  _exports.default = _default;
});