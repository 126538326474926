define("herer-web/models/applicable-tax-configuration", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    product_type_id: (0, _model.attr)('string'),
    excluded: (0, _model.attr)('boolean'),
    person_group_id: (0, _model.attr)('string'),
    // Associations not supported
    // https://github.com/lytics/ember-data-model-fragments#relationships-to-models
    // must be eager loaded in the route first
    person_group: Ember.computed('person_group_id', function () {
      return this.get('store').peekRecord('person_group', this.get('person_group_id'));
    })
  });

  _exports.default = _default;
});