define("herer-web/templates/components/discount-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qaXPgOnv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-sm-8 col-sm-offset-1 col-xs-6\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"fromSameLocation\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"discount\",\"discount_plan\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"discount\",\"discount_plan\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"discountType\"],false],[4,\"if\",[[24,[\"discount\",\"percent\"]]],null,{\"statements\":[[0,\"\\n      (\"],[1,[28,\"format-percent\",[[24,[\"discount\",\"percent\"]]],null],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[4,\"if\",[[24,[\"discount\",\"note\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"em\",true],[8],[0,\"\\n      Note: \"],[1,[24,[\"discount\",\"note\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-sm-3 col-xs-6 text-right\"],[8],[0,\"\\n  \"],[1,[28,\"format-unit\",[[24,[\"displayTotal\"]]],[[\"unit\"],[\"dollar\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/discount-row.hbs"
    }
  });

  _exports.default = _default;
});