define("herer-web/routes/shift", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/mixins/alert-unsaved-route", "herer-web/models/sales-report", "herer-web/constants", "herer-web/mixins/pin-debit-settings"], function (_exports, _authenticatedFullPage, _alertUnsavedRoute, _salesReport, _constants, _pinDebitSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend(_alertUnsavedRoute.default, _pinDebitSettings.default, {
    http: Ember.inject.service(),
    model: function model(params) {
      var store = this.get('store');
      return Ember.RSVP.hash({
        report: this.fetchZReport(params.id),
        shift: this.get('store').findRecord('shift', params.id),
        pinDebitSettings: this.findPinDebitSettings(store)
      });
    },
    fetchZReport: function fetchZReport(shiftId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = Ember.get(_this, 'http');
        var report_params = {
          filters: {
            shift_id: shiftId
          }
        };
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/shift_report"), report_params);
        return http.get(url).then(function (response) {
          return resolve(_salesReport.default.create(response.data.report));
        }).catch(function (response) {
          return reject({
            message: "".concat(response.status, ": ").concat(response.data.message)
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.shift);

      controller.set('report', model.report);
      controller.set('missingClosingAmounts', model.shift.get('missingClosingAmounts'));
      controller.set('pinDebitSettings', model.pinDebitSettings);
      return controller.set('isEditing', model.shift.get('missingClosingAmounts'));
    },
    actions: {
      refresh: function refresh() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});