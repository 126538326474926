define("herer-web/routes/packages", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route", "herer-web/utils/get-active-state-credential"], function (_exports, _authenticatedSideNav, _pageableRoute, _getActiveStateCredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    featureManager: Ember.inject.service(),
    sideNavTemplateName: 'inventory-nav',
    modelName: 'package',
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        return _this.get('permissionsManager').verify('view_packages');
      }).then(function () {
        if (_this.get('featureManager').can('lots')) {
          return _this.transitionTo('lots');
        }
      });
    },
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        packages: this._super(),
        productTypes: store.findAll('product_type'),
        stateCredential: (0, _getActiveStateCredential.default)(store),
        taxItems: store.findAll('tax_item'),
        tags: store.findAll('tag'),
        tieredPricingModels: store.findAll('tiered-pricing-model')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.packages);

      controller.set('taxItems', model.taxItems);
      controller.set('productTypes', model.productTypes);
      controller.set('tags', model.tags);
      controller.set('tieredPricingModels', model.tieredPricingModels);
      controller.set('pendingManifest', false);
      controller.set('stateCredential', model.stateCredential);
    }
  });

  _exports.default = _default;
});