define("herer-web/routes/menu-feeds", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/mixins/pageable-route", "bootbox"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pageableRoute, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, _pageableRoute.default, {
    leaflyAuthManager: Ember.inject.service(),
    sideNavTemplateName: 'marketing-nav',
    mixpanelEvent: 'MenuFeedList.show',
    permissionKey: 'view_menu_feeds',
    modelName: 'menu-feed',
    transitionToList: function transitionToList() {
      this.send('transitionWithoutAlert', 'menu-feeds');
      this.refresh();
    },
    actions: {
      save: function save(model) {
        var _this = this;

        model.save().then(function () {
          _this.transitionToList();
        }).catch(function () {
          _bootbox.default.alert({
            message: 'Sorry, something went wrong when trying to save the menu feed. ' + 'Please check the form for errors. If the problem persists, ' + 'please reach out to us.'
          });
        });
      },
      cancel: function cancel(model) {
        model.rollbackAttributes();
        this.send('transitionWithoutAlert', 'menu-feeds');
      },
      destroy: function destroy(model) {
        model.destroyRecord();
        this.transitionToList();
      }
    }
  });

  _exports.default = _default;
});