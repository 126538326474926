define("herer-web/models/place", ["exports", "@ember-data/model", "herer-web/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    inventory_status: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    description: (0, _model.attr)('string'),
    products_count: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    displayStatus: Ember.computed('inventory_status', function () {
      var status = this.get('inventory_status');
      return _constants.default.placeDisplayStatusByStatus[status];
    }),
    productCountText: Ember.computed('products_count', function () {
      var count = this.get('products_count');
      var countText = count === 1 ? "".concat(count, " Product") : "".concat(count, " Products");
      return countText;
    }),
    productPlaceLink: Ember.computed('id', function () {
      var id = this.get('id');
      return "/products/?by_place=".concat(id);
    }),
    isLocked: Ember.computed('inventory_status', function () {
      return this.get('inventory_status') === _constants.default.placeInventoryStatus.locked;
    })
  });

  _exports.default = _default;
});