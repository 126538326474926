define("herer-web/routes/places", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route", "herer-web/mixins/context"], function (_exports, _authenticatedSideNav, _pageableRoute, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, _context.default, {
    sideNavTemplateName: 'settings-nav',
    modelName: 'place',
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        return _this.get('launchDarkly').waitForReady().then(function () {
          var canManagePlaces = _this.get('permissionsManager').verifyForced('manage_places');

          if (!canManagePlaces) {
            _this.transitionTo('four-oh-four', 'not-found');
          }
        });
      });
    },
    model: function model() {
      return this.get('store').findAll('place');
    },
    actions: {
      refreshAfterPlacesEnabled: function refreshAfterPlacesEnabled() {
        this.get('currentLocation').set('places_enabled', true);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});