define("herer-web/components/person-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showFeeCeiling: Ember.computed('order.fee_ceiling', function () {
      var feeCeiling = this.get('order.fee_ceiling');
      return Ember.isPresent(feeCeiling) && feeCeiling > 0;
    })
  });

  _exports.default = _default;
});