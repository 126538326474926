define("herer-web/models/product-type", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/mixins/metrc-categories", "herer-web/mixins/colorable"], function (_exports, _model, _constants, _metrcCategories, _colorable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_colorable.default, _metrcCategories.default, {
    name: (0, _model.attr)('string'),
    can_edit_weight: (0, _model.attr)('boolean'),
    can_edit_strain: (0, _model.attr)('boolean'),
    can_edit_test_results: (0, _model.attr)('boolean'),
    can_edit_flower_type: (0, _model.attr)('boolean'),
    can_edit_concentrate_type: (0, _model.attr)('boolean'),
    is_marijuana: (0, _model.attr)('boolean'),
    metrc_category_id: (0, _model.attr)('number'),
    traceability_type: (0, _model.attr)('number'),
    tax_items: (0, _model.hasMany)('tax_item', {
      async: true
    }),
    test_results_unit: (0, _model.attr)('number'),
    par_value: (0, _model.attr)('number'),
    // Dynamically set Transaction Limit Type when !is_marijuana
    // to possibly prevent reporting / validation
    // errors down the line.
    isMarijuanaChanged: function isMarijuanaChanged() {
      if (!this.get('is_marijuana')) {
        this.set('traceability_type', _constants.default.traceabilityType.none);
        this.set('metrc_category_id', null);
      }
    },
    isPercent: Ember.computed.equal('test_results_unit', _constants.default.productTestResultsUnitTypes.percent),
    isTraceable: Ember.computed('traceability_type', function () {
      return this.get('traceability_type') !== _constants.default.traceabilityType.none;
    }),
    isConvertible: Ember.computed('traceability_type', function () {
      var _GB$traceabilityType = _constants.default.traceabilityType,
          concentrate = _GB$traceabilityType.concentrate,
          marijuanaExtractForInhalation = _GB$traceabilityType.marijuanaExtractForInhalation,
          inhaled_extract = _GB$traceabilityType.inhaled_extract,
          ingested_extract = _GB$traceabilityType.ingested_extract;
      return [concentrate, marijuanaExtractForInhalation, inhaled_extract, ingested_extract].includes(this.get('traceability_type'));
    }),
    isFlower: Ember.computed.equal('traceability_type', _constants.default.traceabilityType.usableMarijuana),
    testResultsUnitAbbreviation: Ember.computed('test_results_unit', function () {
      return _constants.default.productTestResultsUnitAbbreviations[this.get('test_results_unit')];
    }),
    // for extracts and concentrates test results unit for InventoryItem is different than for Product
    inventoryTestResultsUnitAbbreviation: Ember.computed('traceability_type', function () {
      var _GB$traceabilityType2 = _constants.default.traceabilityType,
          marijuanaExtractForInhalation = _GB$traceabilityType2.marijuanaExtractForInhalation,
          concentrate = _GB$traceabilityType2.concentrate,
          combined_flavored_product = _GB$traceabilityType2.combined_flavored_product,
          low_dose_liquid_edible = _GB$traceabilityType2.low_dose_liquid_edible;

      if ([marijuanaExtractForInhalation, concentrate, combined_flavored_product, low_dose_liquid_edible].includes(this.get('traceability_type'))) {
        return 'mg';
      }

      return this.get('testResultsUnitAbbreviation');
    })
  });

  _exports.default = _default;
});