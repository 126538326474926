define("herer-web/mixins/slide-out-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    showSlideOutDrawer: false,
    showDrawer: function showDrawer() {
      this.set('showSlideOutDrawer', true);
    },
    hideDrawer: function hideDrawer() {
      this.set('showSlideOutDrawer', false);
    },
    actions: {
      attemptedToHideDrawer: function attemptedToHideDrawer() {
        // Placeholder, overide for custom behavior.
        this.hideDrawer();
      },
      afterHideDrawer: function afterHideDrawer() {// Placeholder, overide for custom behavior.
      }
    }
  });

  _exports.default = _default;
});