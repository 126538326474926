define("herer-web/templates/components/print-barcode-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HpmLCN2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"aria-haspopup\",\"true\"],[12,\"title\",\"Print Barcode\"],[12,\"class\",\"print-barcode-button btn btn-default form-control\"],[12,\"data-test-action\",\"print_barcode\"],[3,\"action\",[[23,0,[]],\"printBarcode\"]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-barcode\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/print-barcode-button.hbs"
    }
  });

  _exports.default = _default;
});