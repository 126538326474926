define("herer-web/controllers/shifts", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _pageable, _alertUnsavedController, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageable.default, _alertUnsavedController.default, _context.default, {
    queryParams: ['status', 'register', 'receipt_id', 'employee'],
    employee: '',
    receipt_id: '',
    register: '',
    status: null,
    filters: Ember.computed('employee', 'register', 'status', 'receipt_id', function () {
      var filters = {
        by_employee_name: this.get('employee'),
        by_device_name: this.get('register'),
        by_receipt_id: this.get('receipt_id')
      };

      switch (this.get('status')) {
        case _constants.default.shiftStatus.opened:
          filters.opened = true;
          break;

        case _constants.default.shiftStatus.closed:
          filters.closed = true;
          break;

        case _constants.default.shiftStatus.missing:
          filters.closed = true;
          filters.missing_amounts = true;
          break;
      }

      return filters;
    }),
    statuses: _constants.default.shiftStatuses,
    actions: {
      viewShift: function viewShift(shift) {
        this.transitionToRoute('shift', shift.get('id'));
      }
    }
  });

  _exports.default = _default;
});