define("herer-web/mixins/package-reconciliation/request-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    tagName: '',
    showTimeoutWarning: false,
    warningTimer: null,
    onCancel: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.startTimer();
    },
    startTimer: function startTimer() {
      var _this = this;

      var timeout = Ember.run.later(this, function () {
        _this.set('showTimeoutWarning', true);
      }, 5000);
      this.set('warningTimer', timeout);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('warningTimer'));

      this._super();
    }
  });

  _exports.default = _default;
});