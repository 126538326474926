define("herer-web/models/loyalty-program", ["exports", "@ember-data/model", "herer-web/constants", "herer-web/utils/validation", "yup"], function (_exports, _model, _constants, _validation, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    validations: (0, _validation.validations)({
      loyaltyProgramType: (0, _yup.number)().nullable().required(),
      loyaltyPointsPer: (0, _yup.number)().nullable().required()
    }),
    loyaltyProgramType: (0, _model.attr)('number'),
    loyaltyPointsPer: (0, _model.attr)('number'),
    loyaltyProgramTypeName: Ember.computed('loyaltyProgramType', function () {
      var programType = this.get('loyaltyProgramType');

      var type = _constants.default.loyaltyProgramTypeValues.find(function (c) {
        return c.value === programType;
      });

      return type.name;
    }),
    loyaltyProgramTypeString: Ember.computed('loyaltyProgramType', 'loyaltyPointsPer', function () {
      var suffix;

      if (this.get('loyaltyProgramType') === _constants.default.loyaltyProgramType.pointsPerDollar) {
        suffix = 'per dollar';
      } else {
        suffix = 'per order';
      }

      var points = this.get('loyaltyPointsPer');
      var prefix = points === 1 ? 'point' : 'points';
      return "".concat(points, " ").concat(prefix, " ").concat(suffix);
    })
  });

  _exports.default = _default;
});