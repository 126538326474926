define("herer-web/components/person-form", ["exports", "herer-web/mixins/context", "herer-web/constants", "herer-web/mixins/slide-out-drawer-tabs", "herer-web/mixins/infinite-scroll", "herer-web/mixins/reportable", "herer-web/models/paged-array", "herer-web/utils/people", "ember-uuid/utils/uuid-generator", "bootbox", "herer-web/mixins/pin-debit-settings"], function (_exports, _context, _constants, _slideOutDrawerTabs, _infiniteScroll, _reportable, _pagedArray, _people, _uuidGenerator, _bootbox, _pinDebitSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, _slideOutDrawerTabs.default, _infiniteScroll.default, _reportable.default, _pinDebitSettings.default, {
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    http: Ember.inject.service(),
    store: Ember.inject.service(),
    pinDebitSettings: {},
    scrollElement: '.history-tab-content',
    purchaseWarningHasBeenShown: false,
    person: null,
    orderStatsLoading: true,
    orders: Ember.A([]),
    personGroups: [],
    disabled: false,
    statesProvinces: _constants.default.statesProvinces,
    referralSourceOptions: _constants.default.referralSourceOptions,
    totalOrdersCount: 0,
    totalOrdersAmount: 0.0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('transactionHistoryTabVisible')) {
        this.startLoadingTransactionHistoryTab();
      }

      var store = this.get('store');
      this.findPinDebitSettings(store).then(function (res) {
        _this.set('pinDebitSettings', res);
      });
      Ember.run.next(function () {
        if (Ember.isEmpty(_this.get('person.personGroups')) && !Ember.isEmpty(_this.get('personGroups'))) {
          _this.updatePersonGroups(_this.get('defaultPersonGroupId'));
        }
      });
    },
    pinDebitEnabled: Ember.computed('pinDebitSettings', function () {
      return this.get('pinDebitSettings.enabled');
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments); // If applicable — Show 24 hour "looping" warning and allow
      // receptionist to see history of orders immediately after clicking on person.
      //
      // Will only show once.


      if (this.get('showPurchaseWarning')) {
        this.set('purchaseWarningHasBeenShown', true);
        this.activateTab('history');
      }
    },
    maxBirthdayDate: Ember.computed(function () {
      return new Date();
    }),
    transactionHistoryTabVisible: Ember.computed('person.isNew', function () {
      var personIsNew = this.get('person.isNew');
      var historyTabFeatureFlagOn = this.get('launchDarkly').can('customer-transaction-history');
      return !personIsNew && historyTabFeatureFlagOn;
    }),
    showPurchaseWarning: Ember.computed('transactionHistoryTabVisible', 'hasPurchasedWithinTwentyFourHours', 'purchaseWarningHasBeenShown', function () {
      return this.get('transactionHistoryTabVisible') && this.get('hasPurchasedWithinTwentyFourHours') && !this.get('purchaseWarningHasBeenShown');
    }),
    hasPurchasedWithinTwentyFourHours: Ember.computed('orders.@each.happenedWithinOneDay', function () {
      var orders = this.get('orders');
      return orders.isAny('happenedWithinOneDay', true);
    }),
    availablePersonTypes: Ember.computed('currentLocation.{medical,recreational}', 'selected', function () {
      return (0, _people.availablePersonTypes)(this.get('currentLocation'), this.get('person'), this.get('featureManager'));
    }),
    ordersAreLoaded: Ember.computed('orders.isLoading', 'orders.isLoadingMore', 'orderStatsLoading', function () {
      var ordersAreLoading = this.get('orders.isLoading');
      var infiniteScrollIsLoading = this.get('orders.isLoadingMore');
      var ordersStatisticIsLoading = this.get('orderStatsLoading');
      return (!ordersAreLoading || infiniteScrollIsLoading) && !ordersStatisticIsLoading;
    }),
    hasNoOrders: Ember.computed('orders.length', 'ordersAreLoaded', function () {
      return this.get('ordersAreLoaded') && this.get('orders.length') === 0;
    }),
    showOrderLocation: Ember.computed('sortedLocations', function () {
      return this.get('sortedLocations').length > 1;
    }),
    selectedPersonGroup: Ember.computed('person', 'person.personGroups', 'person.personGroups.[]', function () {
      var person = this.get('person');

      if (!person) {
        return;
      }

      var personGroups = person.get('personGroups');

      if (!personGroups) {
        return;
      } // Currently we are grabbing the first item because were
      // simulating existing behavior. In the future this will
      // be a has many type relationship.


      if (personGroups.objectAt(0)) {
        return personGroups.objectAt(0).get('id');
      } else {
        return this.get('defaultPersonGroupId');
      }
    }),
    defaultPersonGroupId: Ember.computed.alias('currentLocation.default_person_group'),
    isMed: Ember.computed.alias('currentLocation.medical'),
    isRec: Ember.computed.alias('currentLocation.recreational'),
    isBothMedAndRec: Ember.computed.and('isMed', 'isRec'),
    isNotMedNorRec: Ember.computed('isMed', 'isRec', function () {
      return !this.get('isMed') && !this.get('isRec');
    }),
    availablePersonGroups: Ember.computed('personGroups', 'person', function () {
      var personGroups = this.get('personGroups');

      if (Ember.isEmpty(personGroups) || this.get('isNotMedNorRec')) {
        return [];
      }

      personGroups = personGroups.map(function (personGroup) {
        return {
          name: personGroup.get('name'),
          value: personGroup.get('id'),
          medical: personGroup.get('medical')
        };
      });

      if (this.get('isBothMedAndRec')) {
        return personGroups;
      } else if (this.get('isMed')) {
        return personGroups.filterBy('medical');
      } else if (this.get('isRec')) {
        return personGroups.rejectBy('medical');
      }
    }),
    isMemberChoice: Ember.computed.reads('person.isMember'),
    // TODO: add check against update_file_attachments permission when that exists
    canModifyAttachments: Ember.computed('person.modelTypeString', function () {
      var personType = this.get('person.modelTypeString');
      var permissionsManager = this.get('permissionsManager');
      return permissionsManager.verifyForced('update_' + personType + 's');
    }),
    canChangePersonGroup: Ember.computed('person.{isNew,type}', function () {
      var person = this.get('person');

      if (person.get('isNew')) {
        return true;
      }

      if (this.get('permissionsManager').verifyForced('person_group_updates_for_employees')) {
        if (person.get('isEmployee')) {
          return true;
        } else {
          return !!person.changedAttributes().type;
        }
      }

      return false;
    }),
    referralSource: Ember.computed('person.referral_source', function () {
      var currentReferralSource = this.get('person.referral_source');

      if (this.referralSourceOptions.indexOf(currentReferralSource) !== -1) {
        return currentReferralSource;
      } else if (currentReferralSource && currentReferralSource.length > 0) {
        return 'Other';
      }

      return 'Select a referral source';
    }),
    referralSourceOther: Ember.computed('person.referralSource', function () {
      var currentReferralSource = this.get('person.referral_source');
      var isOther = currentReferralSource && this.referralSourceOptions.indexOf(currentReferralSource) === -1 && currentReferralSource.length > 0;

      if (isOther) {
        return currentReferralSource;
      }

      return '';
    }),
    updatePersonGroups: function updatePersonGroups(id) {
      var personGroup = this.get('personGroups').findBy('id', id);

      switch (personGroup.get('name')) {
        case 'Employee':
          this.set('person.type', _constants.default.personType.employee);
          break;

        case 'Customer':
          this.set('person.type', _constants.default.personType.customer);
          break;

        default:
          this.set('person.type', _constants.default.personType.patient);
          break;
      } // Currently we are setting a new array because were simulating
      // existing behavior. In the future person groups will be a has
      // many type relationship.


      this.set('person.personGroups', [personGroup]);
    },
    startLoadingTransactionHistoryTab: function startLoadingTransactionHistoryTab() {
      var _this2 = this;

      var orders = _pagedArray.TimePagedArray.create({
        store: this.get('store'),
        model: 'order',
        defaultParams: {
          by_person_id: this.get('person.id'),
          by_account_scope: true
        },
        sortBy: 'charged_on',
        sortDirection: 'desc'
      });

      this.set('orders', orders);
      this.get('orders').fetchObjects();
      this.get('store').queryRecord('orders-statistic', {
        personId: this.get('person.id')
      }).then(function (orderStats) {
        if (!(_this2.get('isDestroyed') || _this2.get('isDestroying'))) {
          _this2.set('totalOrdersAmount', orderStats.get('totalOrdersAmount'));

          _this2.set('totalOrdersCount', orderStats.get('totalOrdersCount'));

          _this2.set('orderStatsLoading', false);
        }
      });
    },
    actions: {
      activateTab: function activateTab(tabName) {
        this.activateTab(tabName);
      },
      loadMore: function loadMore() {
        this.get('orders').loadMore();
      },
      createFileAttachment: function createFileAttachment(person) {
        var fileAttachments = person.get('fileAttachments');
        fileAttachments.createRecord({
          id: _uuidGenerator.default.v4()
        });
      },
      deleteFileAttachment: function deleteFileAttachment(person, fileAttachment) {
        if (fileAttachment.get('isNew')) {
          fileAttachment.rollbackAttributes();
          return;
        }

        var message = "Are you sure you want to delete ".concat(fileAttachment.get('fileFileName'), "?");

        _bootbox.default.confirm(Ember.Handlebars.Utils.escapeExpression(message), function (result) {
          if (!result) {
            return;
          }

          var fileAttachments = person.get('fileAttachments');
          fileAttachments.removeObject(fileAttachment);
          fileAttachment.destroyRecord();
        });
      },
      downloadFileAttachment: function downloadFileAttachment(person, fileAttachment) {
        var baseUrl = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/file_attachments");
        return Ember.get(this, 'http').get("".concat(baseUrl, "/").concat(fileAttachment.get('id'), "/download")).then(function (response) {
          if (Ember.isPresent(response.data)) {
            window.open(response.data.url);
          } else {
            return Ember.RSVP.reject();
          }
        }).catch(function () {
          _bootbox.default.alert("Sorry, we could not generate a download URL. Please try again. If\n          the problem persists, please get in contact with us.");
        });
      },
      setField: function setField(field, value) {
        this.get('person').set(field, value);
      },
      setMembershipDate: function setMembershipDate(person, isMember) {
        var currentlyMember = person.get('isMember');

        if (!currentlyMember && isMember) {
          person.set('membership_date', new Date());
        } else if (currentlyMember && !isMember) {
          person.set('membership_date', null);
          person.set('plant_count', 0);
        }
      },
      updatePersonGroups: function updatePersonGroups(id) {
        this.updatePersonGroups(id);
      },
      setReferralSource: function setReferralSource(person, value) {
        person.set('referral_source', value);
      },
      setReferralSourceOther: function setReferralSourceOther(person, value) {
        if (person.hasReferralSourceOther) {
          person.set('referral_source', this.referralSourceOther);
        }
      },
      submitReport: function submitReport() {
        var http = this.get('http');
        var customerId = this.get('person.id');
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/people/").concat(customerId, "/loyalty_point_audit"));
        return this.triggerTaskAndPoll(url);
      }
    }
  });

  _exports.default = _default;
});