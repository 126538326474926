define("herer-web/helpers/can-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    launchDarkly: Ember.inject.service(),
    ldReady: Ember.observer('launchDarkly.isReady', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      var feature = params[0];
      var variation, defaultValue;

      if (params.length > 2) {
        variation = params[1];
        defaultValue = params[2];
      } else {
        variation = true;
        defaultValue = params[1];
      }

      if (Ember.isEmpty(feature)) {
        return true;
      }

      return this.get('launchDarkly').can(feature, defaultValue) === variation;
    }
  });

  _exports.default = _default;
});