define("herer-web/templates/components/multiple-choice-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "us6tjT36",
    "block": "{\"symbols\":[\"choice\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"choices\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"radio\"],[10,\"data-test-item\",\"choice\"],[11,\"data-test-index\",[23,2,[]]],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[7,\"input\",false],[12,\"checked\",[28,\"eq\",[[24,[\"selectedChoice\"]],[23,1,[\"value\"]]],null]],[12,\"disabled\",[22,\"disabled\"]],[12,\"value\",[23,1,[\"title\"]]],[12,\"type\",\"radio\"],[3,\"action\",[[23,0,[]],\"selectChoice\",[23,1,[\"value\"]]]],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"beta\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"label label-warning\"],[8],[0,\"\\n          BETA\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n           \\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"b\",true],[10,\"data-test-text\",\"title\"],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"premium\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h5\",true],[10,\"class\",\"text-warning\"],[8],[0,\"\\n          This may be a Premium feature upon release.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"p\",true],[10,\"data-test-text\",\"message\"],[8],[0,\"\\n        \"],[1,[23,1,[\"message\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/multiple-choice-setting.hbs"
    }
  });

  _exports.default = _default;
});