define("herer-web/models/reportable-sale", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Leaf Data
    employeeLicenseNumber: (0, _model.attr)('string'),
    externalId: (0, _model.attr)('string'),
    globalInventoryId: Ember.computed.alias('packageLabel'),
    itemQty: (0, _model.attr)('number'),
    soldAt: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    // Metrc
    customerType: (0, _model.attr)('string'),
    quantityValue: (0, _model.attr)('string'),
    salesDate: (0, _model.attr)('string'),
    unitOfMeasure: (0, _model.attr)('string'),
    // Shared
    caregiverNumber: (0, _model.attr)('string'),
    orderUuid: (0, _model.attr)('string'),
    packageLabel: (0, _model.attr)('string'),
    patientNumber: (0, _model.attr)('string'),
    total: (0, _model.attr)('number'),
    uuid: Ember.computed.alias('orderUuid')
  });

  _exports.default = _default;
});