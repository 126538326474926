define("herer-web/utils/sort-credentials", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sortCredentials = function sortCredentials(credA, credB) {
    var credAActiveAt = (0, _moment.default)(credA.get('active_at'));
    var credBActiveAt = (0, _moment.default)(credB.get('active_at')); // Latest active-at first.

    if (credAActiveAt > credBActiveAt) {
      return -1;
    } else if (credAActiveAt < credBActiveAt) {
      return 1;
    }

    return 0;
  };

  var _default = sortCredentials;
  _exports.default = _default;
});