define("herer-web/models/patient", ["exports", "herer-web/models/person"], function (_exports, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    isPatient: true,
    isCustomer: false,
    isEmployee: false
  });

  _exports.default = _default;
});