define("herer-web/templates/components/discount-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgOO0c+1",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"table-filters\",null,[[\"availableFilters\",\"filterParamsChanged\",\"filterValuesChanged\",\"notifier\",\"disabled\",\"defaultFilterLabel\",\"removeFilterLabel\",\"class\"],[[24,[\"availableDiscountConditions\"]],[28,\"action\",[[23,0,[]],\"conditionParamsChanged\"],null],[28,\"action\",[[23,0,[]],\"conditionValuesChanged\"],null],[23,0,[]],[24,[\"disabled\"]],\"Add condition\",\"Remove condition\",\"discount-conditions\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/discount-conditions.hbs"
    }
  });

  _exports.default = _default;
});