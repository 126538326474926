define("herer-web/mixins/unobservable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UnobservableMixin = Ember.Mixin.create({
    setUpObservers: function setUpObservers() {
      var _this = this;

      this.observeProperties.forEach(function (property) {
        _this.addObserver(property, _this, "".concat(property, "DidChange"));
      });
    },
    setWithoutObservers: function setWithoutObservers(key, value) {
      var _this2 = this;

      this.runWithoutObservers(key, function () {
        return _this2.set(key, value);
      });
    },
    runWithoutObservers: function runWithoutObservers(key, fn) {
      this.removeObserver(key, this, "".concat(key, "DidChange"));

      if (typeof fn === 'function') {
        fn();
      }

      this.addObserver(key, this, "".concat(key, "DidChange"));
    }
  });
  var _default = UnobservableMixin;
  _exports.default = _default;
});