define("herer-web/routes/lots", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route"], function (_exports, _authenticatedSideNav, _pageableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    featureManager: Ember.inject.service(),
    sideNavTemplateName: 'inventory-nav',
    modelName: 'package',
    beforeModel: function beforeModel() {
      var _this = this;

      return this._super().then(function () {
        return _this.get('permissionsManager').verify('view_packages');
      }).then(function () {
        if (!_this.get('featureManager').can('lots')) {
          return _this.transitionTo('packages');
        }
      });
    }
  });

  _exports.default = _default;
});