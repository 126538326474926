define("herer-web/services/feature-manager", ["exports", "lodash/snakeCase"], function (_exports, _snakeCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasFeature = hasFeature;
  _exports.default = void 0;

  function hasFeature(location, feature) {
    if (!location) {
      return false;
    }

    var features = location.get('license.features');

    if (Ember.isEmpty(features)) {
      return false;
    }

    return features.includes((0, _snakeCase.default)(feature));
  }

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    can: function can(feature) {
      return hasFeature(this.get('authManager.currentLocation'), feature);
    }
  });

  _exports.default = _default;
});