define("herer-web/components/tax-item-row", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    canDrag: false,
    taxItem: null,
    isSelected: Ember.computed.equal('taxItem', 'selected'),
    attributeBindings: Ember.computed('canDrag', function () {
      return this.get('canDrag') ? ['draggable', 'data-uuid'] : null;
    }),
    actions: {
      sendEditTaxItem: function sendEditTaxItem() {
        this.onSelect && this.onSelect(this.get('taxItem'));
      }
    }
  });

  _exports.default = _default;
});