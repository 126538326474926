define("herer-web/models/return-lot", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    lot_number: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number')
  });

  _exports.default = _default;
});