define("herer-web/controllers/activity", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/mixins/pageable", "herer-web/mixins/slide-out-drawer", "herer-web/mixins/titan-email", "herer-web/constants", "herer-web/mixins/export", "bootbox", "lodash/clone", "moment"], function (_exports, _alertUnsavedController, _context, _pageable, _slideOutDrawer, _titanEmail, _constants, _export2, _bootbox, _clone, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller;

  var controllerMixins = [_pageable.default, _slideOutDrawer.default, _alertUnsavedController.default, _context.default, _titanEmail.default, _export2.default];

  var _default = (_Ember$Controller = Ember.Controller).extend.apply(_Ember$Controller, controllerMixins.concat([{
    http: Ember.inject.service(),
    queryParams: ['startDate', 'endDate', 'receiptId'],
    startDate: null,
    endDate: null,
    receiptId: null,
    orderController: Ember.inject.controller('order'),
    isExporting: false,
    orderTypeFilter: null,
    paymentMethods: [],
    launchDarkly: Ember.inject.service(),
    showUnsavedAlert: Ember.computed.alias('orderController.model.hasDirtyAttributes'),
    orderTypes: Ember.computed(function () {
      return _constants.default.orderTypes.filter(function (orderType) {
        return orderType.value !== _constants.default.orderType['refund'];
      });
    }),
    _filters: {},
    availableFilters: Ember.computed('orderTypes', 'startDate', 'endDate', 'receiptId', function () {
      var filters = [{
        label: 'Order Type',
        type: 'select',
        subtype: 'uuid',
        query: 'by_order_type',
        options: this.get('orderTypes'),
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All order types',
        defaultFilter: true
      }, {
        label: 'Receipt ID',
        type: 'text',
        query: 'by_receipt_id',
        defaultFilter: true,
        defaultValue: this.get('receiptId')
      }, {
        label: 'Start Date',
        type: 'single_date',
        query: 'by_start_date',
        defaultFilter: true,
        defaultValue: this.get('startDate')
      }, {
        label: 'End Date',
        type: 'single_date',
        query: 'by_end_date',
        defaultFilter: true,
        defaultValue: this.get('endDate')
      }, {
        label: 'Employee',
        placeholder: 'Search by employee name, username or email',
        query: 'by_employee',
        type: 'text'
      }, {
        label: 'Total',
        type: 'money',
        query: 'by_total',
        subquery: 'total'
      }, {
        label: 'Shift ID',
        type: 'text',
        query: 'by_shift_receipt_id'
      }, {
        label: 'Payment Type',
        type: 'select',
        query: 'by_payment_type',
        options: _constants.default.orderPaymentTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All payment types'
      }, {
        label: 'Delivery',
        type: 'select',
        query: 'by_delivery',
        options: _constants.default.orderDeliveryTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All Orders'
      }];

      if (this.get('launchDarkly').can('bw-customer-activity-filter')) {
        filters.splice(3, 0, {
          label: 'Customer',
          placeholder: 'Search by customer name, ID, phone, or email',
          query: 'by_customer',
          type: 'text'
        });
      }

      return filters;
    }),
    exportActivity: function exportActivity() {
      var _this = this;

      var startDate = this.get('startDate');
      var endDate = this.get('endDate');

      if (!Ember.isPresent(startDate) || !Ember.isPresent(endDate)) {
        _bootbox.default.alert('You must select a date range to export.');

        return;
      }

      if ((0, _moment.default)(startDate).add(1, 'month') < (0, _moment.default)(endDate)) {
        _bootbox.default.alert('You can only export 1 month at a time.');

        return;
      }

      this.set('isExporting', true);
      var params = this.get('_filters');

      if (!Ember.isEmpty(this.get('titanEmail'))) {
        params.email_to = this.get('titanEmail');
      }

      Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/orders/export"), params).then(function () {
        _this.set('isExporting', false);

        _this.showExportSuccessModal('activity');
      }).catch(function () {
        _this.set('isExporting', false);

        _this.showExportFailedModal('exportActivity', 'activity');
      });
    },
    _setNull: function _setNull(name, value) {
      if (Ember.isPresent(value)) {
        this.set(name, value);
      } else {
        this.set(name, null);
      }
    },
    actions: {
      selectOrder: function selectOrder(order) {
        this.set('orderController.parentController', this);
        this.set('orderController.paymentMethods', this.get('paymentMethods'));
        this.set('orderController.model', order);
        this.set('orderController.isPanel', true);
        this.set('orderController.pinDebitSettings', this.get('pinDebitSettings'));
        this.showDrawer();
      },
      export: function _export() {
        this.exportActivity();
        return false;
      },
      filterParamsChanged: function filterParamsChanged(filterParams) {
        var timezone = this.get('authManager.currentLocation.timezone');
        var filters = (0, _clone.default)(filterParams);
        var startDate = filters.by_start_date;

        if (Ember.isPresent(startDate)) {
          startDate = (0, _moment.default)(startDate, 'YYYY-MM-DD').startOf('day').tz(timezone, true).toISOString();
          filters.by_start_date = startDate;
        }

        this._setNull('startDate', startDate);

        var endDate = filters.by_end_date;

        if (Ember.isPresent(endDate)) {
          endDate = (0, _moment.default)(endDate, 'YYYY-MM-DD').endOf('day').tz(timezone, true).toISOString();
          filters.by_end_date = endDate;
        }

        this._setNull('endDate', endDate);

        this._setNull('receiptId', filterParams.by_receipt_id);

        this.set('_filters', filters);
        this.set('model.filters', filters);
      },
      filterValuesChanged: function filterValuesChanged() {},
      afterHideDrawer: function afterHideDrawer() {
        var orderController = this.get('orderController');
        orderController.set('isEditing', false);

        if (orderController.get('model.hasDirtyAttributes')) {
          orderController.send('cancelEdit');
        }
      }
    }
  }]));

  _exports.default = _default;
});