define("herer-web/templates/marketing-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9XYucv/g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"side-nav-header\"],[8],[0,\"\\n  Marketing\\n\"],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"nav nav-pills nav-stacked\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"has-permission\",[\"view_menu_feeds\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"icon\"],[\"menu-feeds\",\"Menu Feeds\",\"fa-newspaper\"]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[4,\"if\",[[28,\"has-permission\",[\"view_discount_plans\"],null]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"icon\"],[\"discount-plans\",\"Discount Plans\",\"fa-gift\"]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[4,\"if\",[[28,\"has-permission\",[\"view_loyalty_rewards\"],null]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"icon\"],[\"loyalty-program\",\"Loyalty Program\",\"fa-trophy\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/marketing-nav.hbs"
    }
  });

  _exports.default = _default;
});