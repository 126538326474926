define("herer-web/models/line-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    quantity: (0, _model.attr)('weight'),
    price: (0, _model.attr)('number'),
    subtotal: (0, _model.attr)('number'),
    sku: (0, _model.attr)('string'),
    weight: (0, _model.attr)('number'),
    unit_of_measure: (0, _model.attr)('number'),
    order: (0, _model.belongsTo)('order', {
      async: true
    }),
    tax_breakdowns: (0, _model.hasMany)('tax_breakdown', {
      async: false
    }),
    tax_exemptions: (0, _model.hasMany)('tax_exemption', {
      async: false
    }),
    discounts: (0, _model.hasMany)('discount', {
      async: false
    }),
    inventory_item: (0, _model.belongsTo)('inventory_item', {
      async: true
    }),
    tax: Ember.computed('tax_breakdowns', function () {
      return this.get('tax_breakdowns').sum('total');
    }),
    exemptTax: Ember.computed('tax_exemptions', function () {
      return this.get('tax_exemptions').sum('total');
    }),
    exclusiveTax: Ember.computed('tax_breakdowns', 'tax_breakdowns.@each.inclusive', function () {
      return this.get('tax_breakdowns').filterBy('inclusive', false).reduce(function (sum, item) {
        return sum + item.get('total');
      }, 0);
    }),
    discount: Ember.computed('discounts', function () {
      return this.get('discounts').sum('total');
    }),
    hasInclusiveTaxes: Ember.computed('tax_breakdowns.@each.inclusive', function () {
      return this.get('tax_breakdowns').any(function (taxBreakdown) {
        return taxBreakdown.get('inclusive');
      });
    })
  });

  _exports.default = _default;
});