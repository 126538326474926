define("herer-web/components/radio-button-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    value: null,
    groupValue: null,
    onChange: null,
    attributeBindings: ['checked', 'disabled', 'name', 'type', 'value'],
    checked: Ember.computed('groupValue', 'value', function () {
      return this.get('value') === this.get('groupValue');
    }),
    change: function change() {
      var value = this.get('value');
      var groupValue = this.get('groupValue');

      if (groupValue !== value) {
        this.onChange(value);
      }
    }
  });

  _exports.default = _default;
});