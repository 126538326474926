define("herer-web/components/inventory-value/table", ["exports", "herer-web/mixins/pageable", "herer-web/mixins/infinite-scroll", "herer-web/mixins/product-filters", "herer-web/mixins/table-sorts", "herer-web/models/paged-array"], function (_exports, _pageable, _infiniteScroll, _productFilters, _tableSorts, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageable.default, _infiniteScroll.default, _productFilters.default, _tableSorts.default, {
    store: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    products: [],
    model: Ember.computed.alias('products'),
    init: function init() {
      this._super();

      var pagedArray = _pagedArray.PagedArray.create({
        store: this.get('store'),
        model: 'product'
      });

      this.set('products', pagedArray);
      pagedArray.fetchObjects();
    },
    availableSorts: Ember.computed('products.sortBy', 'products.sortDirection', function () {
      var sorts = [{
        prompt: 'Product Type',
        display: true,
        class: 'col-md-2',
        printClass: 'col-xs-2',
        field: 'product_type',
        direction: null
      }, {
        prompt: 'Name',
        display: true,
        class: 'col-md-4',
        printClass: 'col-xs-4',
        field: 'name',
        direction: null
      }, {
        prompt: 'Quantity',
        display: true,
        class: 'col-md-2',
        printClass: 'col-xs-2',
        field: 'quantity',
        direction: null
      }];
      return this.updateSorts(sorts);
    })
  });

  _exports.default = _default;
});