define("herer-web/services/leafly-auth-manager", ["exports", "jquery", "herer-web/config/environment", "herer-web/constants", "ember-uuid/utils/uuid-generator"], function (_exports, _jquery, _environment, _constants, _uuidGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    http: Ember.inject.service(),
    storageNameSpace: 'GB-Leafly-Auth-Manager',
    redirectUri: Ember.computed(function () {
      return [window.location.origin, 'auth', 'leafly'].join('/');
    }),
    generateBaseParams: function generateBaseParams() {
      return {
        client_id: _environment.default.APP.leaflyClientId,
        redirect_uri: this.get('redirectUri'),
        scope: 'leafly.partner.menu.read leafly.partner.menu.write offline_access',
        response_type: 'code',
        state: _uuidGenerator.default.v4()
      };
    },
    authorize: function authorize(menuFeed) {
      var baseParams = this.generateBaseParams();

      var queryParams = _jquery.default.param(baseParams);

      var leaflyUrl = 'https://identity.leafly.com/connect/authorize';
      this.persist({
        menuFeedId: menuFeed.get('id'),
        nonce: baseParams.state
      });
      return window.location.replace("".concat(leaflyUrl, "?").concat(queryParams));
    },
    fetchAccessToken: function fetchAccessToken(params) {
      var _this = this;

      var http = Ember.get(this, 'http');
      var store = Ember.get(this, 'store');
      var data = this.load();

      if (data.nonce !== params.state) {
        return Ember.RSVP.reject();
      }

      var url = http.createUrl("".concat(_constants.default.apiHost, "/auth/leafly"), {
        code: params.code,
        redirect_uri: this.get('redirectUri')
      });
      return Ember.RSVP.hash({
        menuFeed: store.findRecord('menu-feed', data.menuFeedId, {
          reload: true
        }),
        tokenData: http.get(url).then(function (response) {
          return response.data;
        })
      }).then(function (hash) {
        _this.reset();

        return hash;
      });
    },
    persist: function persist(data) {
      var storageNameSpace = this.get('storageNameSpace');
      localStorage.setItem("".concat(storageNameSpace, "-menuFeedId"), data.menuFeedId);
      localStorage.setItem("".concat(storageNameSpace, "-nonce"), data.nonce);
    },
    reset: function reset() {
      var storageNameSpace = this.get('storageNameSpace');
      localStorage.removeItem("".concat(storageNameSpace, "-menuFeedId"));
      localStorage.removeItem("".concat(storageNameSpace, "-nonce"));
    },
    load: function load() {
      var storageNameSpace = this.get('storageNameSpace');
      return {
        menuFeedId: localStorage.getItem("".concat(storageNameSpace, "-menuFeedId")),
        nonce: localStorage.getItem("".concat(storageNameSpace, "-nonce"))
      };
    }
  });

  _exports.default = _default;
});