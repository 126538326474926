define("herer-web/components/number-input", ["exports", "lodash/isNaN", "underscore.string/numberFormat"], function (_exports, _isNaN, _numberFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.TextField.extend({
    number: null,
    onUpdate: null,
    min: null,
    isDecimal: false,
    numberOfDecimals: 2,
    focused: false,
    init: function init() {
      this._super();

      this._formatNumber();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (!this.get('focused')) {
        this._formatNumber();
      }
    },
    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);

      this._formatNumber();
    },
    keyPressRegex: Ember.computed('isDecimal', function () {
      return this.get('isDecimal') ? /[\-\d\,\.]/ : /[\-\d\,]/;
    }),
    keyPressLimitedCharacters: ['.', ',', '-'],
    keyPress: function keyPress(event) {
      var character = String.fromCharCode(event.which);
      var regex = this.get('keyPressRegex');
      var allowed = regex.test(character);

      if (!allowed) {
        return false;
      }

      var value = this.get('value');

      var _iterator = _createForOfIteratorHelper(this.get('keyPressLimitedCharacters')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var limitedCharacter = _step.value;

          if (character === limitedCharacter && value && value.includes(limitedCharacter)) {
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return true;
    },
    _value: null,
    value: Ember.computed('_value', {
      get: function get() {
        return this.get('_value');
      },
      set: function set(key, value) {
        this.set('_value', value);
        this.onUpdate && this.onUpdate(this._cleanValue(value));
        return value;
      }
    }),
    _cleanValue: function _cleanValue(value) {
      var cleanValue;

      if (this.get('isDecimal')) {
        cleanValue = parseFloat(value.replace(/[^\d\-\.]/g, ''), 10);
      } else {
        cleanValue = parseInt(value.replace(/[^\d\-]/g, ''), 10);
      }

      if ((0, _isNaN.default)(cleanValue)) {
        return null;
      }

      return cleanValue;
    },
    _formatNumber: function _formatNumber() {
      var number = this.get('number');
      var min = this.get('min');

      if (Ember.isPresent(number)) {
        if (typeof number === 'string') {
          number = parseFloat(number);
        }

        number = (0, _numberFormat.default)(number, this.get('isDecimal') ? this.get('numberOfDecimals') : 0);
      }

      if (Ember.isPresent(min)) number = Math.max(min, number);
      this.set('_value', number);
    }
  });

  _exports.default = _default;
});