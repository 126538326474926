define("herer-web/components/sales-by-time-chart", ["exports", "herer-web/components/dimple-chart", "herer-web/constants", "lodash/capitalize"], function (_exports, _dimpleChart, _constants, _capitalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dimpleChart.default.extend({
    rowName: null,
    x: null,
    niceRowName: Ember.computed('rowName', function () {
      return (0, _capitalize.default)(this.get('rowName'));
    }),
    width: Ember.computed(function () {
      return this.$().width();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var height = Math.round(this.get('width') * 0.5);
      this.$().height(height);
    },
    remap: function remap(data) {
      var _this = this;

      var newData = [];
      this.set('rowName', data.rowName);
      data.sourceData.forEach(function (row, index) {
        newData.push(_this._configureRowForSeries(row, index));
      });
      return newData;
    },
    customizeChart: function customizeChart(chart) {
      var width = this.get('width');
      var w = (width - 320) / (1110 - 320);
      var iX = d3.interpolateNumber(15, 5);
      var iY = d3.interpolateNumber(10, 5);
      var iWidth = d3.interpolateNumber(80, 90);
      var iHeight = d3.interpolateNumber(70, 75);
      var iLegend = d3.interpolateNumber(70, 25);
      var iTicks = d3.interpolateNumber(3, 10);
      chart.setBounds("".concat(iX(w), "%"), "".concat(iY(w), "%"), "".concat(iWidth(w), "%"), "".concat(iHeight(w), "%"));
      chart.addLegend("".concat(95 - iLegend(w), "%"), 0, "".concat(iLegend(w), "%"), 20, 'right');
      chart.ease = 'bounce';
      chart.defaultColors = _constants.default.chartColors;
      var y = chart.addMeasureAxis('y', 'Sales');
      y.showGridlines = false;
      y.title = '';
      y.fontSize = '12px';
      y.ticks = parseInt(iTicks(w));
      y.tickFormat = '$,.2s';
      var x = chart.addCategoryAxis('x', 'By');
      x.addOrderRule('Sort');
      x.showGridlines = false;
      x.title = '';
      x.fontSize = '12px';
      x.ticks = 10;
      var series = chart.addSeries(null, dimple.plot.bar);
      this.set('series', series);
      return chart;
    },
    _configureRowForSeries: function _configureRowForSeries(row, index) {
      row.index = index;
      row.Sales = row.sales;
      row.By = row[this.get('rowName')];
      row.Sort = row.charged_on_date_id || row[this.get('rowName')];
      return row;
    }
  });

  _exports.default = _default;
});