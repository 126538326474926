define("herer-web/templates/components/sku-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGOfEme9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[10,\"data-test-group\",\"sku_input\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"maxlength\",\"readonly\",\"focus-out\",\"focus-in\",\"class\",\"data-test-input\",\"input\"],[\"text\",[24,[\"sku\"]],255,[28,\"is-readonly\",[[24,[\"disableInput\"]]],null],\"skuFocusOut\",\"skuFocusIn\",\"form-control\",\"sku\",[28,\"action\",[[23,0,[]],\"convertToUpperCase\"],null]]]],false],[7,\"div\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"disabled\",[22,\"isGeneratingSku\"]],[12,\"tabindex\",\"-1\"],[12,\"class\",\"input-group-addon-fixed btn btn-default btn-generate-sku\"],[12,\"data-test-action\",\"generate_sku\"],[3,\"action\",[[23,0,[]],\"generateSku\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isGeneratingSku\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle-o-notch fa-spin\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-barcode\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      Create\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/sku-input.hbs"
    }
  });

  _exports.default = _default;
});