define("herer-web/components/state-link-graphs", ["exports", "herer-web/mixins/context", "lodash/debounce"], function (_exports, _context, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Embeds powered by DataDog 'State Link Graphs' dashboard
  // https://app.datadoghq.com/dashboard/s93-cbc-ntg?from_ts=1595547398999&live=true&to_ts=1595720198999
  // These don't match our UI styling, but this was the fastest way to embed.
  var DASHBOARD_URLS = {
    alaska: {
      current: "https://app.datadoghq.com/graph/embed?token=a8d939dbe69f3debacdeaeecf5db71677492b76addd792d8ff72f54b78bbae8f&height=200&legend=false",
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=0783fbbd107ff8bcca6a1e120c2ea3b4b67e8c63328568512713d311e81539b2&height=300&legend=false'
    },
    california: {
      current: 'https://app.datadoghq.com/graph/embed?token=10b0cb55b9fe56d8c987811c34734b4029cf5e8078915332576e968ef064343f&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=9a83f518d6917ed94c470e41ab8817a2d3e0a52be45cc81ab28bc43c2c5c02b5&height=300&legend=false'
    },
    colorado: {
      current: 'https://app.datadoghq.com/graph/embed?token=53213d232dc7bfdac5cc7f57cea5ff13c807e6f44d8051d97b60ec03c8bc1216&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=d10b4ef767f9b1fe3b39e08ba7729953e65e471b3c8e63c93da044b5a5eb57ab&height=300&legend=false'
    },
    maryland: {
      current: 'https://app.datadoghq.com/graph/embed?token=5298bc816786f1bb84d6a0e53ee7cf5b9b8dac30d4de2f1b2949179434c88c18&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=5dd300a20acc97d38ac7eb707e0d7c764c0c4c90a151903f637c8b6269dd8fd7&height=300&legend=false'
    },
    massachusetts: {
      current: 'https://app.datadoghq.com/graph/embed?token=7841b1e779066cc372b9afed8d5eb892dc91e381438dd9faa31cb4d97f2c2d29&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=ef187074d6dd9cd681d99c0f1b2ba3e9ecf03d5edefdfc628f6dc5bf3a4c2d32&height=300&legend=false'
    },
    michigan: {
      current: 'https://app.datadoghq.com/graph/embed?token=455ccc96a64aadbd6f13bb8cbd7999841c76d834b228ea62dc08917b91b96644&height=300&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=721b9150faba86c57a5312befe99f3aa68c6a4e86f77686d1b41c9bb9acda521&height=300&legend=false'
    },
    montana: {
      current: 'https://app.datadoghq.com/graph/embed?token=86c88223be05e1fb250f716578f350b8df5c93f8abc72ae6430082e31601e0eb&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=d2dcf8bd2bdc7459dd34ac10617e41fb6a6beda99380e15ef39c2dc6612942ed&height=300&legend=false'
    },
    nevada: {
      current: 'https://app.datadoghq.com/graph/embed?token=3feeed57c721c153e424dc1b3d4063b2d641bb621f22a0ff5b7e1aba088e68c8&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=8dc8b9d5b785dc4ad7e0059cc7e96443ca5d57504924db84ba8d1170a1cccba0&height=300&legend=false'
    },
    oregon: {
      current: 'https://app.datadoghq.com/graph/embed?token=f065bdcd02cbb877ebf472c759ae0f5620d262819adb3f791c6008d925a08410&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=3009737860efdb8340b3547aac671cd149d1e13251e8ece249e6299e6a910867&height=300&legend=false'
    },
    washington: {
      current: 'https://app.datadoghq.com/graph/embed?token=6053ed55fadf6f65c1b6ed797b716ba99a333e061eb8945696e303f97e8871ab&height=200&legend=false',
      twoDayResponseTime: 'https://app.datadoghq.com/graph/embed?token=ea5aff59adf6a1deb48a01e3aaaec7cf1537786cf59563fe545151ca0f20a4dd&height=300&legend=false'
    }
  };

  var _default = Ember.Component.extend(_context.default, {
    graphCurrentWidth: 400,
    graphTwoDayWidth: 600,
    didInsertElement: function didInsertElement() {
      this._super();

      this.set('resizeHandler', this.get('handleResize').bind(this));
      $(window).on('resize', (0, _debounce.default)(this.get('resizeHandler'), 100));
      this.handleResize();
    },
    // Resize Datadog graphs to fill containers
    handleResize: function handleResize() {
      var currentResponseContainer = this.$('#current-response-graph');
      var pastTwoDayContainer = this.$('#response-time-graph');
      var marginAdjustment = 25;
      var currentGraphWidth = currentResponseContainer.innerWidth() - marginAdjustment;
      var twoDayGraphWidth = pastTwoDayContainer.innerWidth() - marginAdjustment;
      this.set('graphCurrentWidth', currentGraphWidth);
      this.set('graphTwoDayWidth', twoDayGraphWidth);
    },
    stateName: Ember.computed.alias('currentLocation.stateName'),
    dashboardGraphEmbedUrlCurrent: Ember.computed('currentLocation', 'graphCurrentWidth', function () {
      var stateName = this.get('stateName').toLowerCase();
      var graphWidth = this.get('graphCurrentWidth');
      return DASHBOARD_URLS[stateName]['current'] + '&width=' + graphWidth;
    }),
    dashboardGraphEmbedUrlResponseTime: Ember.computed('currentLocation', 'graphTwoDayWidth', function () {
      var stateName = this.get('stateName').toLowerCase();
      var graphWidth = this.get('graphTwoDayWidth');
      return DASHBOARD_URLS[stateName]['twoDayResponseTime'] + '&width=' + graphWidth;
    })
  });

  _exports.default = _default;
});