define("herer-web/routes/application", ["exports", "herer-web/utils/semver-sort"], function (_exports, _semverSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    labelPrinter: Ember.inject.service(),
    titan: Ember.inject.service(),
    pushManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    systemStatus: Ember.inject.service(),
    zendesk: Ember.inject.service(),
    authManager: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('zendesk').hide();
      this.get('authManager').set('onAuthenticate', function () {
        _this.get('launchDarkly').on('ready', function () {
          _this.get('zendesk').activate();

          if (_this.get('pushManager').subscribe(_this.get('authManager.currentLocation.id'))) {
            _this.get('taskManager').reset();
          }
        });
      });
    },
    latestRegisterVersionAndBeta: function latestRegisterVersionAndBeta() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this2.get('store').findAll('device').then(function (devices) {
          var versions = Ember.A((0, _semverSort.default)(devices.mapBy('version')));
          var beta = devices.any(function (device) {
            return device.get('beta');
          });
          resolve(versions.get('lastObject'), beta);
        });
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();

      return this.render('error-dialog', {
        into: 'application',
        outlet: 'error-dialog',
        controller: 'error-dialog'
      });
    },
    logout: function logout() {
      this.transitionTo('sessions.destroy');
    },
    actions: {
      error: function error(_error, transition) {
        var status = _error.status,
            message = _error.message,
            disconnected = _error.disconnected,
            stack = _error.stack,
            isAdapterError = _error.isAdapterError; // TODO: Are we logging these on a server somewhere?

        console.error(_error);
        console.error(transition);

        if (_error) {
          // WA-607 & WA-624: flatpickr & other components in the "Side drawer"
          // will throw errors as they are being rendered/torn down
          // when the side drawer slides in/out. Something to do with
          // component lifecycle
          var noisyErrors = message === 'TransitionAborted' || _error instanceof TypeError;

          if (noisyErrors) {
            return;
          }
        }

        if (_error && transition) {
          if (status === 401) {
            return this.logout();
          } else if (status === 403) {
            return this.transitionTo('unauthorized');
          } else {
            return this.transitionTo('error');
          }
        } // CE-6: Do not show error modal for intermittent 500s which users cannot recover from.
        // It raises unnecessary concerns for the customers.


        if (_error && [500, 502].includes(status)) {
          return;
        }

        var displayable = message || stack && stack.trim();

        if ((!this.get('systemStatus.hasActiveOutage') || disconnected || displayable) && !isAdapterError) {
          return this.controllerFor('error-dialog').send('showDialog', _error);
        }
      },
      logout: function logout() {
        this.logout();
      },
      goToHomepage: function goToHomepage() {
        if (this.router.currentRouteName === 'index') {
          return this.refresh();
        } else {
          return this.transitionTo('index');
        }
      },
      showLabelPrinterDisplay: function showLabelPrinterDisplay() {
        var _this3 = this;

        return this.get('labelPrinter').ping().then(function () {
          return _this3.get('labelPrinter').showDisplay();
        }).catch(function () {
          return _this3.transitionTo('label-printer');
        });
      },
      showFlashMessage: function showFlashMessage(msg) {
        var alertClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'alert-success';
        this.controller.showFlashView(msg, alertClass);
      }
    }
  });

  _exports.default = _default;
});