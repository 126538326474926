define("herer-web/services/titan", ["exports", "herer-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    enabled: false,
    isChrome: Ember.computed(function () {
      /* global chrome */
      return typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined';
    }),
    init: function init() {
      var _this = this;

      this._super();

      this.ping().then(function () {
        _this.set('enabled', true);
      }).catch(function () {
        _this.set('enabled', false);
      });
    },
    sendCommand: function sendCommand(command) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this2.get('isChrome')) {
          var message = {
            command: command
          };
          chrome.runtime.sendMessage(_environment.default.APP.titanAppId, message, function (response) {
            if (Ember.isPresent(response)) {
              if (Ember.isPresent(response.error)) {
                reject(response.error);
              } else {
                resolve(response.result);
              }
            } else {
              reject();
            }
          });
        }
      });
    },
    ping: function ping() {
      if (this.get('isChrome')) {
        return this.sendCommand('ping');
      } else {
        return Ember.RSVP.reject();
      }
    },
    email: function email() {
      return this.sendCommand('cookie');
    }
  });

  _exports.default = _default;
});