define("herer-web/models/filter-value", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    value: null,
    secondValue: null,
    comparator: null,
    betweenComparator: Ember.computed.equal('comparator', 'between'),
    acceptedComparators: ['<', '<=', '=', '>=', '>', 'between'],
    isTextFilter: Ember.computed.equal('filter.type', 'text'),
    isSelectFilter: Ember.computed.equal('filter.type', 'select'),
    isTagFilter: Ember.computed.equal('filter.type', 'tag'),
    isNumericFilter: Ember.computed.equal('filter.type', 'numeric'),
    isPercentFilter: Ember.computed.readOnly('filter.percent'),
    isMoneyFilter: Ember.computed.equal('filter.type', 'money'),
    isDateFilter: Ember.computed.or('isSingleDateFilter', 'isDateRangeFilter'),
    isSingleDateFilter: Ember.computed.equal('filter.type', 'single_date'),
    isDateRangeFilter: Ember.computed.equal('filter.type', 'date'),
    isTimeFilter: Ember.computed.equal('filter.type', 'time'),
    isWeightFilter: Ember.computed.equal('filter.type', 'weight'),
    isComparatorFilter: Ember.computed.or('isNumericFilter', 'isMoneyFilter', 'isDateRangeFilter', 'isTimeFilter', 'isWeightFilter'),
    _filter: null,
    filter: Ember.computed('_filter', {
      get: function get() {
        return this.get('_filter');
      },
      set: function set(key, value) {
        this.set('_filter', value);
        var comparableTypes = ['numeric', 'money', 'date', 'time', 'weight'];
        var comparable = comparableTypes.includes(value.type);

        if (value && comparable) {
          if (Ember.isPresent(value.acceptedComparators)) {
            this.set('acceptedComparators', value.acceptedComparators);
            this.set('comparator', value.acceptedComparators[0]);
          } else {
            this.set('comparator', '=');
          }
        } else {
          this.set('comparator', null);
        }

        return value;
      }
    }),
    queryValue: Ember.computed(function () {
      var value = this.get('value');
      value = this.formatIfTime(value);
      value = this.formatIfDate(value);
      value = this.formatIfWeight(value);

      if (Ember.isPresent(value) && this.get('isComparatorFilter')) {
        var comparatorValue = {
          comparator: this.get('comparator')
        };

        if (this.get('betweenComparator')) {
          var secondValue = this.get('secondValue');

          if (Ember.isPresent(secondValue)) {
            secondValue = this.formatIfTime(secondValue);
            secondValue = this.formatIfDate(secondValue);
            secondValue = this.formatIfWeight(secondValue);
            value = [value, secondValue].join(',');
          } else {
            return null;
          }
        }

        comparatorValue[this.get('filter.subquery')] = value;
        return comparatorValue;
      } else {
        return value;
      }
    }).volatile(),
    formatIfDate: function formatIfDate(value) {
      if (this.get('isDateFilter') && Ember.isPresent(value)) {
        return (0, _moment.default)(value).format('YYYY-MM-DD');
      } else {
        return value;
      }
    },
    formatIfTime: function formatIfTime(value) {
      if (this.get('isTimeFilter')) {
        return (0, _moment.default)(value).format('HH:mm');
      } else {
        return value;
      }
    },
    formatIfWeight: function formatIfWeight(value) {
      if (this.get('isWeightFilter') && Ember.isPresent(value)) {
        return value.toString();
      } else {
        return value;
      }
    },
    tags: Ember.computed('value', {
      get: function get() {
        var value = this.get('value');

        if (!value) {
          value = '';
        }

        return Ember.isEmpty(value) ? [] : value.split(',');
      },
      set: function set(key, value) {
        this.set('value', value.join(','));
        return value;
      }
    })
  });

  _exports.default = _default;
});