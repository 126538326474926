define("herer-web/mixins/queueable", ["exports", "herer-web/mixins/context", "bootbox"], function (_exports, _context, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_context.default, {
    queueManager: Ember.inject.service(),
    toast: Ember.inject.service(),
    enqueueSuccessMessage: '',
    enqueueSuccessSubMessage: 'Person was added to the queue.',
    dequeueTaskIsLoading: Ember.computed.reads('queueManager.dequeueTaskIsLoading'),
    enqueuePerson: function enqueuePerson(person) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        if (person.get('isPatient') && person.get('isPastExpirationDate')) {
          _bootbox.default.alert('This person has an expired ID.');

          return resolve();
        }

        _this.get('queueManager').enqueuePerson(person).then(function () {
          _this.get('toast').success(_this.get('enqueueSuccessMessage'), _this.get('enqueueSuccessSubMessage'), {
            onclick: function onclick() {
              _this.transitionToRoute('queue');
            }
          });
        }).catch(function (response) {
          var error = response.data && response.data.error;

          if (error) {
            _this.get('toast').error(error);
          }
        }).finally(function () {
          resolve();
        });
      });
    },
    dequeue: function dequeue(checkIn) {
      this.get('queueManager').get('dequeueTask').perform(checkIn);
    },
    actions: {
      enqueuePerson: function enqueuePerson(person) {
        this.enqueuePerson(person);
      },
      dequeue: function dequeue(checkIn) {
        this.dequeue(checkIn);
      }
    }
  });

  _exports.default = _default;
});