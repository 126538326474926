define("herer-web/routes/quick-audit", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/alert-unsaved-route", "herer-web/models/paged-array"], function (_exports, _authenticatedSideNav, _alertUnsavedRoute, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_alertUnsavedRoute.default, {
    sideNavTemplateName: 'inventory-nav',
    mixpanelEvent: 'QuickAudit.show',
    permissionKey: 'audit_inventory',
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        products: _pagedArray.PagedArray.create({
          store: store,
          model: 'product'
        }),
        productTypes: store.findAll('product-type'),
        places: store.findAll('place')
      });
    },
    setupController: function setupController(controller, model) {
      var products = model.products,
          productTypes = model.productTypes,
          places = model.places;
      controller.set('model', products);
      controller.set('productTypes', productTypes);
      controller.set('places', places);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var controller = this.controllerFor('quick-audit');
      controller.get('printTask').cancelAll();
      controller.get('model.fetchObjectsTask').cancelAll();
      controller.set('isPrinting', false);
    }
  });

  _exports.default = _default;
});