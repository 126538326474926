define("herer-web/controllers/transaction-limits", ["exports", "herer-web/mixins/traceability-types", "herer-web/mixins/alert-unsaved-controller", "herer-web/constants", "herer-web/mixins/pageable"], function (_exports, _traceabilityTypes, _alertUnsavedController, _constants, _pageable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_traceabilityTypes.default, _alertUnsavedController.default, _pageable.default, {
    weightUnits: _constants.default.weightUnits,
    sortedModel: Ember.computed('model.@each.{medical,traceabilityType}', function () {
      return this.get('model').sortBy('medical', 'traceabilityType');
    })
  });

  _exports.default = _default;
});