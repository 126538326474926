define("herer-web/components/drawer-adjustment-row", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['drawer-adjustment-row'],
    tagName: 'tr',
    editable: false,
    isEditing: false,
    editableTypes: [{
      name: 'Paid In',
      value: _constants.default.drawerAdjustmentType.in
    }, {
      name: 'Paid Out',
      value: _constants.default.drawerAdjustmentType.out
    }],
    drawerAdjustment: null,
    onSave: null,
    noteCharacterLimit: 255,
    remainingCharacters: Ember.computed('drawerAdjustment.note', function () {
      var noteLength = this.get('drawerAdjustment.note.length');
      return this.get('noteCharacterLimit') - noteLength;
    }),
    typeString: Ember.computed('drawerAdjustment.drawer_adjustment_type', function () {
      switch (this.get('drawerAdjustment.drawer_adjustment_type')) {
        case _constants.default.drawerAdjustmentType.in:
          return 'Paid In';

        case _constants.default.drawerAdjustmentType.out:
          return 'Paid Out';

        case _constants.default.drawerAdjustmentType.under:
          return 'Drawer Under';

        case _constants.default.drawerAdjustmentType.over:
          return 'Drawer Over';

        case _constants.default.drawerAdjustmentType.closeDrop:
          return 'Close Drop';

        case _constants.default.drawerAdjustmentType.closeDrawer:
          return 'Left in Drawer';
      }
    }),
    actions: {
      edit: function edit() {
        this.set('isEditing', true);
      },
      cancel: function cancel() {
        this.set('isEditing', false);
        this.get('drawerAdjustment').rollbackAttributes();
      },
      save: function save() {
        var _this = this;

        this.set('isSaving', true);
        var changedAttributes = this.get('drawerAdjustment').changedAttributes();
        var shouldReload = Ember.isPresent(changedAttributes.drawer_adjustment_type);
        this.get('drawerAdjustment').save().then(function () {
          _this.set('isSaving', false);

          _this.set('isEditing', false);

          if (shouldReload) {
            _this.onSave();
          }
        }).catch(function () {
          _this.set('isSaving', false);
        });
      },
      setAmount: function setAmount(amount) {
        this.set('drawerAdjustment.amount', amount);
      },
      setType: function setType(type) {
        this.set('drawerAdjustment.drawer_adjustment_type', type);
      }
    }
  });

  _exports.default = _default;
});