define("herer-web/components/packages/package/package-history", ["exports", "herer-web/mixins/slide-out-drawer", "herer-web/mixins/infinite-scroll", "herer-web/mixins/pageable", "herer-web/constants"], function (_exports, _slideOutDrawer, _infiniteScroll, _pageable, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_slideOutDrawer.default, _infiniteScroll.default, _pageable.default, {
    tagName: '',
    store: Ember.inject.service(),
    package: null,
    users: null,
    model: null,
    scrollElement: '.drawer-content',
    onExit: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.showDrawer();
    },
    userOptions: Ember.computed('users', function () {
      var options = this.get('users').map(function (user) {
        return {
          label: user.get('name'),
          value: user.id
        };
      });
      options.unshift({
        label: _constants.default.defaultPackageHistoryUser,
        value: 'null'
      });
      return options;
    }),
    availableFilters: Ember.computed('userOptions', function () {
      return [{
        label: 'Event',
        type: 'select',
        query: 'by_event_type',
        options: _constants.default.packageHistoryEvents,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All Events',
        defaultFilter: true
      }, {
        label: 'User',
        type: 'select',
        query: 'by_user_uuid',
        options: this.get('userOptions'),
        optionLabel: function optionLabel(option) {
          return option.label;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        defaultFilter: true,
        prompt: 'All Users'
      }];
    }),
    actions: {
      afterHideDrawer: function afterHideDrawer() {
        this.onExit();
      },
      filterParamsChanged: function filterParamsChanged(filterParams) {
        this.set('model.filters', filterParams);
      },
      filterValuesChanged: function filterValuesChanged() {}
    }
  });

  _exports.default = _default;
});