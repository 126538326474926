define("herer-web/templates/components/radio-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N1q1ucH6",
    "block": "{\"symbols\":[\"option\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"radio \",[28,\"if\",[[24,[\"inline\"]],\"inline\"],null]]]],[10,\"data-test-item\",\"option\"],[11,\"data-test-index\",[23,2,[]]],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"radio-button-input\",null,[[\"value\",\"groupValue\",\"onChange\",\"disabled\"],[[23,1,[\"value\"]],[24,[\"groupValue\"]],[28,\"action\",[[23,0,[]],\"sendChangeAction\"],null],[24,[\"disabled\"]]]]],false],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/radio-setting.hbs"
    }
  });

  _exports.default = _default;
});