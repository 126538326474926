define("herer-web/utils/date-parsing", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.browserTimezone = _exports.dateToString = _exports.stringToDate = void 0;

  /**
   * Converts a date represented as a string into a native Javascript Date object. Uses MomentJS to do
   * the heavy lifting.
   * @param {string} string Date represented as a string
   * @param {string} expectedFormat Format the date string is in. Ex. 'MM-DD-YYYY'
   * @param {string} [options.timezone] Custom timezone name for the returned date. Default's to the
   *                                    default set on moment.
   * @param {boolean} [options.validate] Option for validating the string date before returning. If
   *                                     true with an invalid date, this will return `null`.
   * @returns {Date}
   */
  var stringToDate = function stringToDate(string, expectedFormat) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var validate = options.validate;
    var timezone = options.timezone || _moment.default.defaultZone.name;

    var momentDate = _moment.default.tz(string, expectedFormat, timezone);

    var date = momentDate.toDate();

    if (validate) {
      return momentDate.isValid() ? date : null;
    }

    return date;
  };
  /**
   * Converts a native Javascript Date object into a string representation of said date.
   * @param {Date} date Date object to be converted
   * @param {string} format The format of the outputted string. Ex. 'MM-DD-YYYY'
   * @param {string} [options.timezone] Timezone name for the initial date to be validated in. This is
   *                                    important if the timezone where this is being called (usually
   *                                    the browser) diverges from the default timezone set on moment.
   * @returns {string}
   */


  _exports.stringToDate = stringToDate;

  var dateToString = function dateToString(date, format) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var timezone = options.timezone || _moment.default.defaultZone.name;
    return _moment.default.tz(date, timezone).format(format);
  };
  /**
   * Gives the browser timezone name that can be used my Moment to scope a time to a specific zone.
   *
   * @returns {string} Timezone name. Example: "America/Los_Angeles"
   */


  _exports.dateToString = dateToString;

  var browserTimezone = function browserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  _exports.browserTimezone = browserTimezone;
});