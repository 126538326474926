define("herer-web/utils/mixpanel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mixpanelTrack = mixpanelTrack;
  var events = {
    'QuickAudit.show': 'Inventory QuickAudit show',
    'ProductList.show': 'Inventory ProductList Show',
    'ProductModalDetails.show': 'Inventory ProductModalDetails Show',
    'ProductModalPricing.show': 'Inventory ProductModalPricing Show',
    'ProductModalInventory.show': 'Inventory ProductModalInventory Show',
    'ProductModal.TapSaveBtn': 'Inventory ProductModal TapSaveBtn',
    'ProductModal.TapCancelBtn': 'Inventory ProductModal TapCancelBtn',
    'ProductModal.TapDeleteBtn': 'Inventory ProductModal TapDeleteBtn',
    'ProductTypes.show': 'Inventory ProductTypes Show',
    'ProductTypes.TapAddProductTypeBtn': 'Inventory ProductTypes TapAddProductTypeBtn',
    'ProductTypeEdit.TapSaveBtn': 'Inventory ProductTypeEdit TapSaveBtn',
    'ProductTypeEdit.TapDeleteBtn': 'Inventory ProductTypeEdit TapDeleteBtn',
    'ProductTypeEdit.show': 'Inventory ProductTypeEdit Show',
    'ProductTypeEdit.TapCancelBtn': 'Inventory ProductTypeEdit TapCancelBtn',
    'Activity.show': 'Sales Activity Show',
    'MenuFeedList.show': 'Marketing MenuFeedList Show',
    'DiscountPlanList.show': 'Marketing DiscountPlanList Show',
    'LoyaltyProgramList.show': 'Marketing LoyaltyProgramList Show',
    'CustomerList.show': 'Marketing CustomerList Show',
    'CustomerQueueList.show': 'Marketing CustomerQueueList Show',
    'InputReader.cardSwipeSuccessHandler': 'CardReader InputReader cardSwipeSuccessHandler',
    // performance events not tied to any page
    'AppStart': 'Performance AppStart',
    'Authentication': 'Performance Authentication'
  };

  function mixpanelTrack(eventKey) {
    var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (!events[eventKey]) {
      console.log("Mixpanel Event undefined - ".concat(eventKey));
      return;
    }

    if (!window.mixpanel) {
      console.log("Mixpanel - ".concat(eventKey), payload);
      return;
    }

    window.mixpanel.track(events[eventKey], payload);
    return true;
  }
});