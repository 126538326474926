define("herer-web/serializers/tax-item-schedule", ["exports", "@ember-data/serializer/rest", "active-model-adapter", "moment"], function (_exports, _rest, _activeModelAdapter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    serialize: function serialize(record, options) {
      var json = this._super(record, options); // this will always set the effective schedule dates
      // to the locations default timezone


      if (json.effective_start !== null) {
        json.effective_start = (0, _moment.default)(json.effective_start).startOf('Day').toISOString();
      }

      if (json.effective_end !== null) {
        json.effective_end = (0, _moment.default)(json.effective_end).endOf('Day').toISOString();
      }

      return json;
    }
  });

  _exports.default = _default;
});