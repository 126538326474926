define("herer-web/adapters/inventory-audit", ["exports", "herer-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    export: function _export(model) {
      var url = "".concat(this.buildURL('inventory-audit', model.id), "/export");
      return this.ajax(url, "POST", {
        data: {}
      });
    }
  });

  _exports.default = _default;
});