define("herer-web/templates/components/account-reports/daily-customers/location-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D77YkxWd",
    "block": "{\"symbols\":[\"customer\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"location row\"],[10,\"data-test-group\",\"location\"],[11,\"data-test-index\",[22,\"index\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"style\",[22,\"styles\"]],[10,\"class\",\"title col-md-3\"],[10,\"data-test-text\",\"location_name\"],[8],[0,\"\\n    \"],[1,[24,[\"location\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"people col md-9\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pull-right count\"],[10,\"data-test-text\",\"customer_count\"],[8],[0,\"\\n      \"],[1,[24,[\"location\",\"customers\",\"length\"]],false],[0,\"\\n    \"],[9],[0,\"\\n     \\n\"],[4,\"each\",[[24,[\"location\",\"customers\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-user\"],[10,\"data-test-ux\",\"user\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/account-reports/daily-customers/location-row.hbs"
    }
  });

  _exports.default = _default;
});