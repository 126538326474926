define("herer-web/components/status-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TjHI344i",
    "block": "{\"symbols\":[\"xs\",\"status\",\"index\",\"@selectedStatus\",\"@onStatusChange\",\"@statuses\"],\"statements\":[[5,\"x-select\",[],[[\"@value\",\"@onChange\",\"@data-test-input\",\"@class\"],[[23,4,[]],[28,\"fn\",[[23,5,[]]],null],\"status_select\",\"form-control\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"option\"]],[],[[\"@value\",\"@data-test-item\",\"@data-test-index\"],[[23,2,[\"value\"]],\"status\",[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/status-select/template.hbs"
    }
  });

  _exports.default = _default;
});