define("herer-web/components/gb-time-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7XMeaem",
    "block": "{\"symbols\":[\"@datetime\",\"@disabled\",\"@onChange\",\"@testSelector\"],\"statements\":[[5,\"datetime-input\",[],[[\"@datetime\",\"@dateFormat\",\"@noCalendar\",\"@disabled\",\"@onChange\",\"@testSelector\",\"@isTimeInput\"],[[23,1,[]],\"G:i K\",true,[23,2,[]],[23,3,[]],[23,4,[]],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/components/gb-time-input/template.hbs"
    }
  });

  _exports.default = _default;
});