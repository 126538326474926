define("herer-web/helpers/traceability-type-name", ["exports", "herer-web/mixins/context", "herer-web/mixins/traceability-types"], function (_exports, _context, _traceabilityTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_context.default, _traceabilityTypes.default, {
    authManager: Ember.inject.service(),
    compute: function compute(params) {
      var type = this.get('traceabilityTypes').find(function (type) {
        return type.value === params[0];
      });
      return type ? type.name : '';
    }
  });

  _exports.default = _default;
});