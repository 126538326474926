define("herer-web/components/quick-audit-row", ["exports", "herer-web/models/weight", "herer-web/constants"], function (_exports, _weight, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'table-row', 'product'],
    countedValue: null,
    numberOfDecimals: 2,
    isBlindCount: false,
    product: Ember.computed.alias('inventoryItem.product'),
    isWeight: Ember.computed.not('inventoryItem.quantity.isUnit'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (Ember.isPresent(this.get('value'))) {
        this._setCountedValue(this.get('value'));
      }
    },
    _setCountedValue: function _setCountedValue(value) {
      var countedValue = _weight.default.create({
        value: value,
        unit: this.get('inventoryItem.quantity.unit')
      });

      this.set('countedValue', countedValue.round(this.get('numberOfDecimals')));
      return countedValue;
    },
    weightLabel: Ember.computed('inventoryItem.quantity.unit', function () {
      var shortLabel = _constants.default.weightUnitShortLabels[this.get('inventoryItem.quantity.unit')];

      if (shortLabel === 'ea') {
        return 'units';
      }

      return shortLabel;
    }),
    showLotNumber: Ember.computed.not('inventoryItem.lotNumberMatchesSku'),
    adjustmentTo: Ember.computed('inventoryItem.quantity', 'countedValue', function () {
      var countedValue = this.get('countedValue');

      if (Ember.isEmpty(countedValue) || countedValue.eq(this.get('inventoryItem.quantity'))) {
        return '—';
      }

      var delta = countedValue.minus(this.get('inventoryItem.quantity'));
      var plusIndicator = delta.get('greaterThanZero') ? '+' : '';
      return "".concat(plusIndicator).concat(delta.get('value'), " ").concat(this.get('weightLabel'));
    }),
    actions: {
      setCountedValue: function setCountedValue() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

        if (value === null) {
          return;
        }

        var numberOfDecimals = this.get('numberOfDecimals');

        var countedValue = this._setCountedValue(value);

        var adjustmentValue = countedValue.minus(this.get('inventoryItem.quantity')).round(numberOfDecimals);
        var fragmentData = {
          inventory_item: this.get('inventoryItem'),
          adjustment: adjustmentValue,
          counted: value,
          product: this.get('product')
        };
        this.updateAudit(fragmentData);
      }
    }
  });

  _exports.default = _default;
});