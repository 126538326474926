define("herer-web/models/remote-lot", ["exports", "@ember-data/model", "herer-web/mixins/lot-number-formatter", "herer-web/mixins/test-results", "herer-web/constants", "herer-web/utils/sample-type-string"], function (_exports, _model, _lotNumberFormatter, _testResults, _constants, _sampleTypeString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_testResults.default, _lotNumberFormatter.default, {
    sku: (0, _model.attr)('string'),
    external_sku: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('weight'),
    inventory_type: (0, _model.attr)('number'),
    inventory_type_string: (0, _model.attr)('string'),
    strain: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    usable_weight: (0, _model.attr)('weight'),
    is_sample: (0, _model.attr)('boolean'),
    is_medical: (0, _model.attr)('boolean'),
    sample_type: (0, _model.attr)('number'),
    purchase_price: (0, _model.attr)('number'),
    external_inventory_id: (0, _model.attr)('string'),
    certificate_of_analysis_url: (0, _model.attr)('string'),
    expected_quantity: Ember.computed.alias('quantity'),
    newInventory: true,
    // TODO: this won't work in colorado
    product_type: Ember.computed('inventory_type', function () {
      switch (this.get('inventory_type')) {
        case 22:
          return _constants.default.traceabilityType.solidMarijuanaInfusedEdible;

        case 23:
          return _constants.default.traceabilityType.liquidMarijuanaInfusedEdible;

        case 24:
          return _constants.default.traceabilityType.marijuanaExtractForInhalation;

        case 28:
          return _constants.default.traceabilityType.usableMarijuana;

        case 26:
          return _constants.default.traceabilityType.sample;

        default:
          return _constants.default.traceabilityType.other;
      }
    }),
    lotNumberFormatted: Ember.computed('sku', function () {
      return this.formatLotNumber(this.get('sku'));
    }),
    sampleTypeString: Ember.computed('sample_type', function () {
      return (0, _sampleTypeString.sampleTypeString)(this.get('sample_type'));
    })
  });

  _exports.default = _default;
});