define("herer-web/controllers/places", ["exports", "herer-web/mixins/context", "herer-web/mixins/table-sorts", "ember-uuid/utils/uuid-generator"], function (_exports, _context, _tableSorts, _uuidGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {
    cookies: Ember.inject.service(),
    showManagePlaces: false,
    showPlacesModal: false,
    dynamicPlacesEnabled: false,
    model: null,
    sortBy: ['name'],
    sortedPlaces: Ember.computed.sort('model', 'sortBy'),
    columns: [{
      prompt: 'Place Name',
      class: 'col-md-4'
    }, {
      prompt: 'Inventory Status',
      class: 'col-md-5'
    }, {
      prompt: 'Quantity',
      class: 'col-md-3'
    }],
    placesEnabled: Ember.computed.alias('currentLocation.places_enabled'),
    enabledModels: Ember.computed('sortedPlaces', 'placesEnabled', function () {
      if (!this.get('placesEnabled')) {
        return [];
      }

      return this.get('sortedPlaces');
    }),
    init: function init() {
      this._super();

      if (this.get('placesEnabled')) {
        this.get('cookies').removeCookie('placesConversionTaskInProgress');
      }

      this.get('cookies').getCookie('placesConversionTaskInProgress') && this.set('showPlacesModal', true);
      this.set('columns', (0, _tableSorts.decorateHeaders)(this.get('columns')));
    },
    useNewOrCreate: function useNewOrCreate(model) {
      var store = this.get('store');
      var existingNewRecord = store.peekAll(model).findBy('isNew', true);
      return existingNewRecord || store.createRecord(model, {
        id: _uuidGenerator.default.v4()
      });
    },
    actions: {
      attemptedToHideDrawer: function attemptedToHideDrawer() {
        this.set('showManagePlaces', false);
      },
      saveSettings: function saveSettings() {// nothing to manage yet
      },
      addPlace: function addPlace() {
        this.useNewOrCreate('place');
      },
      toggleManagePlaces: function toggleManagePlaces() {
        this.set('showManagePlaces', !this.get('showManagePlaces'));
      },
      togglePlacesModal: function togglePlacesModal() {
        this.set('showPlacesModal', !this.get('showPlacesModal'));
      }
    }
  });

  _exports.default = _default;
});