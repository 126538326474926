define("herer-web/adapters/inventory-item", ["exports", "herer-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data: data
      });
    }
  });

  _exports.default = _default;
});