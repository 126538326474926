define("herer-web/pivot-table/total-row", ["exports", "herer-web/pivot-table/cell", "herer-web/pivot-table/row", "big.js"], function (_exports, _cell, _row, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _row.default.extend({
    averageTotal: false,
    addRows: function addRows(rows) {
      var _this = this;

      rows.forEach(function (row) {
        // by default reports have three columns, extended reports have online sales included
        var extended = row.cells.length === 6;
        row.cells.forEach(function (rowCell, i) {
          var cell = _this.get('cells')[i];

          if (!cell) {
            cell = _cell.default.create({
              unit: rowCell.unit
            });
            _this.get('cells')[i] = cell;
          }

          var value;

          if (_this.get('averageTotal') && (i === row.cells.length - 1 || extended && i === row.cells.length - 2)) {
            var cellsIndexes = [0, 1];

            if (extended) {
              // for extended reports, average has to be calculated twice from different values
              cellsIndexes = i === row.cells.length - 2 ? [0, 2] : [1, 3];
            }

            var cell0 = _this.get('cells')[cellsIndexes[0]];

            var cell1 = _this.get('cells')[cellsIndexes[1]];

            if (cell0.get('value').eq(0)) {
              value = new _big.default(0);
            } else {
              value = cell1.get('value').div(cell0.get('value'));
            }
          } else if (rowCell.value) {
            value = cell.get('value').plus(rowCell.value);
          }

          cell.set('value', value);
        });
      });
    },
    addGroups: function addGroups(groups) {
      var _this2 = this;

      groups.forEach(function (group) {
        _this2.addRows(group.content);
      });
    }
  });

  _exports.default = _default;
});