define("herer-web/templates/components/id-detection/scan-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0TrI9xOe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"id-detection-input\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"data-test-id\",\"class\"],[\"text\",[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"data-test-id\"]],\"form-control search-field\"]]],false],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"title\",\"Magnetic Stripe\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[10,\"class\",\"fgb\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"/img/scanner-icon.svg\"],[10,\"width\",\"20\"],[10,\"height\",\"20\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/id-detection/scan-input.hbs"
    }
  });

  _exports.default = _default;
});