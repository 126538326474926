define("herer-web/controllers/users", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/mixins/pageable", "herer-web/mixins/infinite-scroll"], function (_exports, _alertUnsavedController, _context, _pageable, _infiniteScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _pageable.default, _infiniteScroll.default, _context.default, {
    queryParamManager: Ember.inject.service(),
    availableFilters: Ember.A([{
      placeholder: 'Search users by first name, last name, username or email',
      query: 'by_query',
      type: 'text',
      defaultFilter: true,
      forbidRemove: true
    }]),
    userController: Ember.inject.controller('user'),
    withDeleted: 0,
    byQuery: null,
    users: Ember.A([]),
    model: Ember.computed.alias('users'),
    addUser: function addUser(user) {
      this.set('userController.model', user);
      this.get('userController').modelDidChange();
    },
    actions: {
      editUser: function editUser(user) {
        this.set('userController.model', user);
        this.get('userController').modelDidChange();
      },
      saveUser: function saveUser(resolve, reject) {
        this.get('userController').send('save', resolve, reject);
      },
      addUser: function addUser(user) {
        this.addUser(user);
      },
      cancelUserEdit: function cancelUserEdit() {
        this.get('userController').send('cancel');
      },
      removeUser: function removeUser(user) {
        this.get('model').removeObject(user);
      },
      showDeleted: function showDeleted() {
        this.set('withDeleted', 1);
        this.get('queryParamManager').set({
          deleted: 1
        });
        var newFilters = {
          with_deleted: 1
        };

        if (this.get('byQuery')) {
          newFilters['by_query'] = this.get('byQuery');
        }

        this.set('filters', newFilters);
      },
      hideDeleted: function hideDeleted() {
        this.set('withDeleted', 0);
        this.get('queryParamManager').set({
          deleted: 0
        });
        var newFilters = {
          with_deleted: 0
        };

        if (this.get('byQuery')) {
          newFilters['by_query'] = this.get('byQuery');
        }

        this.set('filters', newFilters);
      },
      showConfirmationAlert: function showConfirmationAlert(callbacks) {
        this.send('confirmTransition', callbacks);
      },
      filterParamsChanged: function filterParamsChanged(filterParams) {
        this.set('byQuery', filterParams.by_query);
        var newFilters = {
          with_deleted: this.get('withDeleted')
        };

        if (Ember.isPresent(filterParams.by_query)) {
          newFilters['by_query'] = decodeURIComponent(filterParams.by_query);
        }

        this.set('filters', newFilters);
      },
      filterValuesChanged: function filterValuesChanged() {}
    }
  });

  _exports.default = _default;
});