define("herer-web/templates/components/nav-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aJPTn3Tn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"queryParamsObj\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"data-test-link\",\"route\",\"model\"],[[24,[\"dataTestId\"]],[24,[\"route\"]],[24,[\"queryParamsObj\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"nav-link/inner\",null,[[\"route\",\"icon\",\"label\",\"hasErrors\",\"textPrefix\"],[[24,[\"route\"]],[24,[\"icon\"]],[24,[\"label\"]],[24,[\"hasErrors\"]],[24,[\"textPrefix\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"data-test-link\",\"route\"],[[24,[\"dataTestId\"]],[24,[\"route\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"nav-link/inner\",null,[[\"route\",\"icon\",\"label\",\"hasErrors\",\"textPrefix\"],[[24,[\"route\"]],[24,[\"icon\"]],[24,[\"label\"]],[24,[\"hasErrors\"]],[24,[\"textPrefix\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/nav-link.hbs"
    }
  });

  _exports.default = _default;
});