define("herer-web/components/account-sales-summary", ["exports", "herer-web/mixins/context", "herer-web/pivot-table/table", "herer-web/utils/dates-intervals", "big.js", "moment"], function (_exports, _context, _table, _datesIntervals, _big, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_context.default, {
    reportStore: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super();

      this.loadReports();
    },
    loadReports: function loadReports() {
      var _this = this;

      var reportStore = this.get('reportStore');
      reportStore.fetchReport(this.buildReportParams('today')).then(function (report) {
        _this.set('todayTable', report.table);

        _this.set('todayLoading', false);
      });
      reportStore.fetchReport(this.buildReportParams('yesterday')).then(function (report) {
        _this.set('yesterdayTable', report.table);

        _this.set('yesterdayLoading', false);
      });

      if (this.get('accountSalesReportCombined')) {
        reportStore.fetchReport(this.buildReportParams('month', null, true)).then(function (report) {
          _this.set('monthTable', report.table);

          _this.set('monthLoading', false);
        });
      } else {
        Ember.RSVP.all(this.get('sortedLocations').map(function (location) {
          return reportStore.fetchReport(_this.buildReportParams('month', location.get('id')));
        })).then(function (locationReports) {
          var _ref;

          var firstTable = locationReports[0].table;
          var locationsData = locationReports.map(function (report) {
            return report.table.get('sourceData');
          });

          var sourceData = (_ref = []).concat.apply(_ref, _toConsumableArray(locationsData));

          var monthTable = _table.default.create({
            columnName: firstTable.get('columnName') || ['Number of Sales', 'Total', 'Average Per Sale'],
            rowName: firstTable.get('rowName'),
            valueName: firstTable.get('valueName'),
            sourceData: sourceData,
            groupBy: firstTable.get('groupBy'),
            unit: firstTable.get('unit'),
            filters: firstTable.get('filters'),
            showRowTotals: false,
            averageTotal: true
          });

          _this.set('monthTable', monthTable);

          _this.set('monthLoading', false);
        });
      }
    },
    buildReportParams: function buildReportParams(interval, locationId, byAccount) {
      var dates = (0, _datesIntervals.datesFromInterval)(interval);
      return {
        startDate: dates.startDate.format('YYYY-MM-DD'),
        endDate: dates.endDate.format('YYYY-MM-DD'),
        measure: 'dollars',
        report: 'location',
        byAccount: interval !== 'month' || byAccount || false,
        locationId: locationId,
        useNewReport: 'accountSalesSummary'
      };
    },
    determinePrefixes: function determinePrefixes(data) {
      var prefixes = [];

      if (data.sales) {
        prefixes.push('');
      } else {
        if (data.in_store_sales) {
          prefixes.push('in_store_');
        }

        if (data.online_sales) {
          prefixes.push('online_');
        }
      }

      return prefixes;
    },
    todayLoading: true,
    todaySortBy: null,
    todaySortDirection: null,
    todayTable: null,
    yesterdayLoading: true,
    yesterdaySortBy: null,
    yesterdaySortDirection: null,
    yesterdayTable: null,
    monthLoading: true,
    monthSortBy: null,
    monthSortDirection: null,
    monthTable: null,
    dayInMonth: (0, _moment.default)().date(),
    daysInMonth: (0, _moment.default)().daysInMonth(),
    daysRemainingInMonth: (0, _moment.default)().daysInMonth() - (0, _moment.default)().date(),
    dailyAverageSortBy: null,
    dailyAverageSortDirection: null,
    dailyAverageTable: Ember.computed('monthTable', function () {
      var _this2 = this;

      var monthTable = this.get('monthTable');

      if (!Ember.isPresent(monthTable)) {
        return null;
      }

      var dayInMonth = this.get('dayInMonth');
      var sourceData = monthTable.get('sourceData');
      var averageData = sourceData.map(function (data) {
        var prefixes = _this2.determinePrefixes(data);

        var avgData = {
          location: data.location
        };
        prefixes.forEach(function (prefix) {
          var sales = new _big.default(data["".concat(prefix, "sales")]).div(dayInMonth);
          var transactions = new _big.default(data["".concat(prefix, "transactions")]).div(dayInMonth).round();
          avgData["".concat(prefix, "sales")] = sales.toString();
          avgData["".concat(prefix, "transactions")] = transactions.toString();
          avgData["".concat(prefix, "average")] = transactions > 0 ? sales.div(transactions).toString() : '0';
        });
        return avgData;
      });

      var dailyAverageTable = _table.default.create({
        columnName: monthTable.get('columnName') || ['Number of Sales', 'Total', 'Average Per Sale'],
        rowName: monthTable.get('rowName'),
        valueName: monthTable.get('valueName'),
        sourceData: averageData,
        groupBy: monthTable.get('groupBy'),
        unit: monthTable.get('unit'),
        filters: monthTable.get('filters'),
        showRowTotals: false,
        averageTotal: true
      });

      return dailyAverageTable;
    }),
    projectedTotalSortBy: null,
    projectedTotalSortDirection: null,
    projectedTotalTable: Ember.computed('monthTable', function () {
      var _this3 = this;

      var monthTable = this.get('monthTable');

      if (!Ember.isPresent(monthTable)) {
        return null;
      }

      var daysRemainingInMonth = this.get('daysRemainingInMonth');
      var dailyAverageTable = this.get('dailyAverageTable');
      var monthTableSourceData = monthTable.get('sourceData');
      var averageData = dailyAverageTable.get('sourceData');
      var projectedData = averageData.map(function (data, idx) {
        var prefixes = _this3.determinePrefixes(data);

        var projData = {
          location: data.location
        };
        prefixes.forEach(function (prefix) {
          var dailyAverageSales = new _big.default(data["".concat(prefix, "sales")]);
          var monthSales = new _big.default(monthTableSourceData[idx]["".concat(prefix, "sales")]);
          var dailyAverageTransactions = new _big.default(data["".concat(prefix, "transactions")]);
          var monthTransactions = new _big.default(monthTableSourceData[idx]["".concat(prefix, "transactions")]);
          var sales = dailyAverageSales.times(daysRemainingInMonth).plus(monthSales);
          var transactions = dailyAverageTransactions.times(daysRemainingInMonth).plus(monthTransactions).round();
          projData["".concat(prefix, "sales")] = sales.toString();
          projData["".concat(prefix, "transactions")] = transactions.toString();
          projData["".concat(prefix, "average")] = transactions > 0 ? sales.div(transactions).toString() : '0';
        });
        return projData;
      });

      var projectedTotalTable = _table.default.create({
        columnName: dailyAverageTable.get('columnName') || ['Number of Sales', 'Total', 'Average Per Sale'],
        rowName: dailyAverageTable.get('rowName'),
        valueName: dailyAverageTable.get('valueName'),
        sourceData: projectedData,
        groupBy: dailyAverageTable.get('groupBy'),
        unit: dailyAverageTable.get('unit'),
        filters: dailyAverageTable.get('filters'),
        showRowTotals: false,
        averageTotal: true
      });

      return projectedTotalTable;
    }),
    accountSalesReportCombined: Ember.computed(function () {
      return this.get('launchDarkly').can('account-sales-monthly-report-combined', false);
    })
  });

  _exports.default = _default;
});