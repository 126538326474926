define("herer-web/components/print-barcode-button", ["exports", "herer-web/mixins/inventory-printing"], function (_exports, _inventoryPrinting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A button that prints a barcode for the given object
   *
   * This is mostly a wrapper for the InventoryPrinting mixin. It accepts any kind of "printable"
   * object and renders a button with a barcode icon. When clicked, a modal pops up prompting the
   * user to enter the quantity. That number defaults to the quantity of the "printable".
   *
   * @param printable A "printable object"
   * @param {Object} printable.product A product object
   * @param {string} printable.lot_number
   * @param {string} printable.sku Generated numbers used to create the barcode
   * @param {number} printable.quantity The nubmer of units. Default value for modal.
   * @param {Object} [labelPreference] Fetched from the API, label preferences for the account
   */
  var PrintBarcodeButton = Ember.Component.extend(_inventoryPrinting.default, {
    printable: null,
    labelPreference: null
  });
  var _default = PrintBarcodeButton;
  _exports.default = _default;
});