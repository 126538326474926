define("herer-web/controllers/error-dialog", ["exports", "herer-web/constants", "moment"], function (_exports, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authManager: Ember.inject.service(),
    error: null,
    showErrorDetails: false,
    supportNumber: _constants.default.supportNumber,
    timestamp: null,
    canShowErrorDetails: Ember.computed.not('unauthorized'),
    unauthorized: Ember.computed('error', function () {
      var error = this.get('error');
      return typeof error !== 'undefined' && error !== null && error.status === 403;
    }),
    notConnected: Ember.computed.alias('error.disconnected'),
    errorHeader: Ember.computed('unauthorized', function () {
      if (this.get('unauthorized')) {
        return 'Unauthorized';
      } else {
        return 'System Error';
      }
    }),
    errorIcon: Ember.computed('unauthorized', function () {
      if (this.get('unauthorized')) {
        return 'fa-exclamation-circle';
      } else {
        return 'fa-exclamation-triangle';
      }
    }),
    errorMessage: Ember.computed('error', function () {
      var error = this.get('error');
      return error.message || error.description;
    }),
    actions: {
      showDialog: function showDialog(error) {
        if (!this.authManager.currentUser) {
          return;
        }

        this.set('error', error);
        this.set('showErrorDetails', false);
        this.set('timestamp', (0, _moment.default)().toISOString());
        return $('.error-dialog').modal();
      },
      showErrorDetails: function showErrorDetails() {
        this.set('showErrorDetails', true);
        return false;
      }
    }
  });

  _exports.default = _default;
});