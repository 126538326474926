define("herer-web/templates/components/save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hp8gprbJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"onCancel\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[22,\"isSaving\"]],[12,\"data-test-action\",\"cancel\"],[12,\"class\",[29,[\"btn btn-default \",[22,\"extraClass\"]]]],[3,\"action\",[[23,0,[]],\"cancel\"],[[\"on\"],[\"click\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"cancelIcon\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"cancelIcon\"]]]],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n        \"],[1,[22,\"cancelPrompt\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"cancelPrompt\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"button\",false],[12,\"disabled\",[22,\"buttonDisabled\"]],[12,\"data-test-action\",\"save\"],[12,\"data-test-is-saving\",[28,\"if\",[[24,[\"isSaving\"]],\"true\",\"false\"],null]],[12,\"class\",[29,[\"btn \",[22,\"btnClass\"],\" \",[22,\"extraClass\"]]]],[3,\"action\",[[23,0,[]],\"save\"],[[\"on\"],[\"click\"]]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"saveIconDynamicClass\"]]]],[8],[9],[4,\"if\",[[24,[\"prompt\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n      \"],[1,[22,\"prompt\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/save-button.hbs"
    }
  });

  _exports.default = _default;
});