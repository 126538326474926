define("herer-web/labels/oregon_label_filter", ["exports", "herer-web/constants", "herer-web/labels/rules/packager_defaults", "herer-web/labels/utils/common_utils"], function (_exports, _constants, _packager_defaults, _common_utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    apply: function apply(labelType, data) {
      if (Ember.isPresent(data)) {
        var myData = Object.assign({}, data);

        _packager_defaults.default.apply(labelType, myData); // convert test values of 0 to '<LOQ'


        var cbd = myData['cbd'];
        var thc = myData['thc'];
        myData['cbd'] = this._formatPotency(cbd);
        myData['thc'] = this._formatPotency(thc);
        var traceable_type = myData['traceable_type'];
        var product_type = myData['product_type'];

        if (traceable_type === _constants.default.traceabilityType.shakeTrim) {
          myData['usable_marijuana'] = 'Usable Marijuana:';
        } else {
          // blank the field for Usable Marijuana
          myData['usable_marijuana'] = '';
          myData['weight'] = '';
        }

        var label_id = myData['label_id'];

        if (label_id && label_id !== '') {
          myData['label_id_text'] = 'LABEL ID:';
        } else {
          myData['label_id_text'] = '';
          myData['label_id'] = '';
        } // set warnings_text per product type and label type


        if (traceable_type === _constants.default.traceabilityType.hemp) {
          myData['warnings_text'] = 'This product is derived from hemp and could contain THC.' + ' Keep out of reach of children.';
        } else {
          var isMedical = myData['isMedical'];

          if (!isMedical) {
            myData['warnings_text'] = 'For use only by adults 21 and older.  Keep out of reach of' + ' children.  Do not drive a motor vehicle while under the influence of marijuana.';
          } else {
            myData['warnings_text'] = 'For use only by OMMP patients.  Keep out of reach of' + ' children.  Do not drive a motor vehicle while under the influence of marijuana.';
          }
        }

        myData['product_type'] = _common_utils.default.formatCharacters(product_type);
        return myData;
      }

      return data;
    },
    transformTemplate: function transformTemplate(labelType, templateData, labelData, fieldIdNameMapping) {
      var result = templateData;
      var fieldNamesToTransform = ['warnings_text'];
      var fieldIds = [];

      if (Ember.isPresent(templateData)) {
        for (var fieldNumber in fieldIdNameMapping) {
          if (fieldNamesToTransform.includes(fieldIdNameMapping[fieldNumber])) {
            fieldIds.push(fieldNumber);
          }
        }
      }

      if (fieldIds.length > 0) {
        var idsList = fieldIds.join('|');
        var pattern = new RegExp("\\^FN(".concat(idsList, ").*?\\^FD.*?\\^FS"));
        result = templateData.replace(pattern, '');
      }

      return result;
    },
    _formatPotency: function _formatPotency(source) {
      var result = source;

      if (Ember.isPresent(source)) {
        if (source === '0') {
          result = '<LOQ';
        } else {
          result = source + ' %';
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});