define("herer-web/components/menu-feeds/menu-group-inputs", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function menuGroupFilterValue(menuGroupFilters, key) {
    return menuGroupFilters.reduce(function (prev, filter) {
      if (filter.get('key') === key) {
        var value = filter.get('value');
        return Ember.isPresent(value) ? value : filter.get('values');
      } else {
        return prev;
      }
    }, '');
  }

  var _default = Ember.Component.extend({
    menuFeed: null,
    menuGroup: null,
    productTypes: [],
    allTags: [],
    disabled: false,
    webhookIntegrator: null,
    selectedProductType: null,
    menuGroupNames: Ember.computed('webhookIntegrator', 'menuFeed.type', function () {
      if (!Ember.isEmpty(this.get('webhookIntegrator.menuGroups'))) {
        return this.get('webhookIntegrator.menuGroups');
      }

      return this.get('menuFeed.menuGroupOptions').mapBy('name');
    }),
    menuGroupPricesBy: _constants.default.menuGroupPricesBy,
    availableMenuGroupFilters: Ember.computed('productTypes', 'allTags', 'menuGroup.filters', function () {
      var menuGroupFilters = this.get('menuGroup.filters');
      var filterNames = menuGroupFilters.map(function (filter) {
        return filter.get('key');
      });
      return [{
        label: 'Product Type',
        type: 'select',
        query: 'by_product_type',
        options: this.get('productTypes'),
        optionLabel: function optionLabel(option) {
          return option.get('name');
        },
        optionValue: function optionValue(option) {
          return option.get('id');
        },
        prompt: 'All product types',
        defaultFilter: filterNames.includes('by_product_type'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'by_product_type')
      }, {
        label: 'Flower Type',
        type: 'select',
        query: 'by_flower_type',
        options: _constants.default.flowerTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All flower types',
        defaultFilter: filterNames.includes('by_flower_type'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'by_flower_type')
      }, {
        label: 'Concentrate Type',
        type: 'select',
        query: 'by_concentrate_type',
        options: _constants.default.concentrateTypes,
        optionLabel: function optionLabel(option) {
          return option.name;
        },
        optionValue: function optionValue(option) {
          return option.value;
        },
        prompt: 'All concentrate types',
        defaultFilter: filterNames.includes('by_concentrate_type'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'by_concentrate_type')
      }, {
        label: 'Tags',
        type: 'tag',
        query: 'by_tags',
        allTags: this.get('allTags'),
        defaultFilter: filterNames.includes('by_tags'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'by_tags')
      }, {
        label: 'Without Tags',
        type: 'tag',
        query: 'without_tags',
        allTags: this.get('allTags'),
        defaultFilter: filterNames.includes('without_tags'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'without_tags')
      }, {
        label: 'Quantity',
        type: 'numeric',
        query: 'by_quantity',
        subquery: 'quantity',
        defaultFilter: filterNames.includes('by_quantity'),
        defaultValue: menuGroupFilterValue(menuGroupFilters, 'by_quantity')
      }];
    }),
    availableMenuGroupNameTemplates: ['{{strain}}', '{{strain}} by {{brand}}', '{{strain}} {{concentrate_type}} by {{brand}}', '{{tiered_pricing_model}}: {{strain}}', '{{tiered_pricing_model}}: {{strain}} by {{brand}}', '{{name}}'],
    updateSelectedProductType: function updateSelectedProductType(productId) {
      if (productId) {
        var productType = this.get('productTypes').find(function (t) {
          return t.id === productId;
        });
        this.set('selectedProductType', productType);
      }
    },
    actions: {
      filterParamsChanged: function filterParamsChanged(filterParams) {
        this.updateSelectedProductType(filterParams.by_product_type);
        var filters = Object.keys(filterParams).map(function (key) {
          var filterParam = {
            key: key.toString()
          };
          var value = filterParams[key];

          if (_typeof(value) === 'object') {
            filterParam.values = [value.quantity, value.comparator];
          } else {
            filterParam.value = value;
          }

          return filterParam;
        });
        this.onMenuGroupFilterChange(filters);
      },
      filterValuesChanged: function filterValuesChanged() {// NOOP
      },
      setField: function setField(field, value) {
        this.set("menuGroup.".concat(field), value);
      }
    }
  });

  _exports.default = _default;
});