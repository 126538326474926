define("herer-web/routes/account-sales-summary", ["exports", "herer-web/routes/authenticated-full-page"], function (_exports, _authenticatedFullPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    launchDarkly: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        return _this.get('launchDarkly').verify('account-sales-summary', false).then(function () {
          return _this.get('permissionsManager').verify('view_sales_by_reports');
        }).catch(function () {
          _this.transitionTo('four-oh-four', 'not-found');
        });
      });
    }
  });

  _exports.default = _default;
});