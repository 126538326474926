define("herer-web/controllers/inventory", ["exports", "herer-web/mixins/context", "herer-web/helpers/format-unit", "bootbox", "herer-web/mixins/alert-unsaved-controller"], function (_exports, _context, _formatUnit, _bootbox, _alertUnsavedController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _alertUnsavedController.default, {
    itemToSplit: null,
    places: [],
    isPackageSplitterOpen: false,
    adjustmentController: Ember.inject.controller('adjustment'),
    inventoryMoveController: Ember.inject.controller('inventoryMove'),
    placesEnabled: Ember.computed.bool('currentLocation.places_enabled'),
    hideSplitter: function hideSplitter() {
      this.set('itemToSplit', null);
      this.set('isPackageSplitterOpen', false);
    },
    checkErrors: function checkErrors(inventoryItem) {
      var message = '';

      if (inventoryItem.get('wholesaleCostUpdated')) {
        message += "\n      <p>This will update the cost of this SKU on:</p>\n      <ul>\n        <li>Previous sales</li>\n        <li>All reports that include cost</li>\n      </ul>\n      <p>These updates also apply to inventory split or converted from this SKU. </p>\n      ";
        var price_calculations = inventoryItem.get('caluclatedNewPrice');

        if (price_calculations['input_purchase_price'] !== price_calculations['resulting_price']) {
          message += "\n          <p><b>Note: The wholesale cost you entered\n          ($".concat((0, _formatUnit.formatCents)(price_calculations['input_purchase_price']), ") will\n          be rounded to $").concat((0, _formatUnit.formatCents)(price_calculations['resulting_price']), " due to how Greenbits\n          calculates cost per ").concat(inventoryItem.get('quantity.unitName').toLowerCase(), ".</b></p>\n        ");
        }
      }

      if (inventoryItem.get('invalidIngredientsTextLength')) {
        message += "\n        <p>\n          More than 400 characters are in the Ingredients field.\n          These will be printed on physical labels,\n          and may result in overlapping lines of text.\n        </p>\n      ";
      }

      if (message !== '') {
        return message + 'Do you want to save?';
      }

      return false;
    },
    showErrorModal: function showErrorModal(errors) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _bootbox.default.dialog({
          message: errors,
          buttons: {
            cancel: {
              label: 'Cancel',
              className: 'btn-default',
              callback: reject
            },
            confirm: {
              label: 'Save',
              className: 'btn-success confirm-save',
              callback: resolve
            }
          }
        });
      });
    },
    actions: {
      adjust: function adjust(inventoryItem) {
        this.set('adjustmentController.parentController', this);
        this.set('adjustmentController.inventoryItem', inventoryItem);
        this.get('adjustmentController').onInventoryItemChanged();
      },
      move: function move(inventoryItem) {
        this.set('inventoryMoveController.parentController', this);
        this.set('inventoryMoveController.places', this.get('places'));
        this.get('inventoryMoveController').onInventoryItemChanged(inventoryItem);
      },
      convert: function convert() {
        this.transitionToRoute('convert', this.get('model.id'));
      },
      onCancelSplit: function onCancelSplit() {
        this.hideSplitter();
      },
      onSplit: function onSplit() {
        this.get('itemToSplit').reload();
        this.hideSplitter();
      },
      onSave: function onSave(inventoryItem) {
        var _this = this;

        if (this.get('placesEnabled')) {
          inventoryItem.status = null;
          inventoryItem.status_reason = null;
        }

        var foundErrors = this.checkErrors(inventoryItem);

        if (foundErrors) {
          this.showErrorModal(foundErrors).then(function () {
            return _this.send('save', inventoryItem);
          }).catch(function () {
            return null;
          });
        } else {
          return this.send('save', inventoryItem);
        }
      },
      onInventoryItemAdjustment: function onInventoryItemAdjustment() {
        this.get('model').reload();
      },
      onInventoryMove: function onInventoryMove() {
        this.get('model').reload();
      },
      split: function split() {
        this.set('itemToSplit', this.get('model'));
        this.set('isPackageSplitterOpen', true);
      },
      viewManifest: function viewManifest(sku) {
        this.transitionToRoute('manifests', {
          queryParams: {
            sku: sku
          }
        });
      }
    }
  });

  _exports.default = _default;
});