define("herer-web/mixins/accessible-locations", ["exports", "lodash/find"], function (_exports, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    locations: [],
    launchDarkly: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    showEmployeeAPIKey: Ember.computed('launchDarkly.isReady', function () {
      return this.get('featureManager').can('metrc') && this.get('launchDarkly').can('compliance-metrc-employee-api-key');
    }),
    proxiedLocations: Ember.computed.map('locations', function (location) {
      return Ember.ObjectProxy.create({
        content: location,
        checked: false
      });
    }),
    refreshModel: function refreshModel() {
      if (Ember.isEmpty(this.get('model'))) {
        this.resetProxiedLocations();
      } else {
        this.updateProxiedLocations();
      }
    },
    resetProxiedLocations: function resetProxiedLocations() {
      this.get('proxiedLocations').forEach(function (location) {
        location.set('checked', false);
        location.set('api_key', null);
      });
    },
    updateProxiedLocations: function updateProxiedLocations() {
      var model = this.get('model');
      var accessibleLocations = model.get('accessible_location_ids');
      var stateCredentialLocationIds = model.get('state_credentials');
      this.get('proxiedLocations').forEach(function (location) {
        var checkedLocation = (0, _find.default)(accessibleLocations, function (locationId) {
          return locationId === location.get('id');
        });
        var stateCredential = (0, _find.default)(stateCredentialLocationIds, function (locationId) {
          return locationId === location.get('id');
        });
        var api_key = stateCredential && checkedLocation ? 'protected' : '';
        location.set('checked', !!checkedLocation);
        location.set('api_key', api_key);
        return location;
      });
    },
    checkAllLocations: Ember.computed('proxiedLocations.@each.checked', {
      get: function get() {
        return this.get('proxiedLocations').isEvery('checked');
      },
      set: function set(key, value) {
        this.get('proxiedLocations').setEach('checked', value);
        return value;
      }
    }),
    checkedLocations: function checkedLocations() {
      return this.get('proxiedLocations').filterBy('checked', true).mapBy('content.id');
    },
    updateStateCredentials: function updateStateCredentials() {
      var model = this.get('model');
      model.set('accessible_location_ids', this.checkedLocations());
      var state_credentials = [];
      this.get('proxiedLocations').filterBy('checked', true).forEach(function (location) {
        var api_key = location.get('api_key');

        if (api_key !== 'protected') {
          state_credentials.pushObject({
            api_key: location.get('api_key'),
            location_id: location.get('id')
          });
        }
      });
      model.set('state_credentials', state_credentials);
    }
  });

  _exports.default = _default;
});