define("herer-web/templates/components/tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QajR+OGm",
    "block": "{\"symbols\":[\"xs\",\"t\",\"index\"],\"statements\":[[5,\"x-select\",[],[[\"@disabled\",\"@multiple\",\"@data-role\",\"@data-test-group\"],[[22,\"disabled\"],true,\"tagsinput\",\"tag_input\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"option\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"placeholder\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[24,[\"taggable\",\"tags\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"option\"]],[],[[\"@value\",\"@data-test-item\",\"@data-test-index\"],[[23,2,[]],\"tag_row\",[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/tag-input.hbs"
    }
  });

  _exports.default = _default;
});