define("herer-web/templates/components/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PDSKt07h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row gb-page-header\"],[10,\"data-test-group\",\"page_header\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"custom\"]]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-md-\",[22,\"width\"]]]],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"header\"],[10,\"data-test-text\",\"page_header\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"accountWide\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"account-wide-header\"],[8],[0,\"\\n            All Locations\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-right\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[22,\"header\"],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"subheader\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[10,\"class\",\"subheader\"],[10,\"data-test-text\",\"page_subheader\"],[8],[0,\"\\n          \"],[1,[22,\"subheader\"],true],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"helpLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[11,\"href\",[22,\"helpLink\"]],[10,\"target\",\"_blank\"],[10,\"class\",\"header-help-link\"],[10,\"data-test-link\",\"page_help\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-external-link\"],[10,\"data-test-ux\",\"external_link\"],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[10,\"data-test-text\",\"page_help\"],[8],[0,\"\\n            Learn more on Greenbits Help Center.\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/page-header.hbs"
    }
  });

  _exports.default = _default;
});