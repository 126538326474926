define("herer-web/controllers/convert", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, {});

  _exports.default = _default;
});