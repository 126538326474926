define("herer-web/templates/components/scroll-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VeGXFj0M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"scroll-to-top \",[28,\"if\",[[23,0,[\"show\"]],\"scroll-to-top-visible\"],null]]]],[12,\"data-test-ux\",\"scroll_to_top\"],[3,\"on\",[\"click\",[23,0,[\"scrollToTop\"]]]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-arrow-up fa-4x\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/scroll-to-top.hbs"
    }
  });

  _exports.default = _default;
});