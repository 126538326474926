define("herer-web/routes/devices", ["exports", "herer-web/routes/authenticated-side-nav"], function (_exports, _authenticatedSideNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    sideNavTemplateName: 'settings-nav',
    model: function model() {
      return this.get('store').findAll('device');
    }
  });

  _exports.default = _default;
});