define("herer-web/models/order", ["exports", "@ember-data/model", "herer-web/constants", "moment"], function (_exports, _model, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    charged_on: (0, _model.attr)('date'),
    delivered: (0, _model.attr)('boolean'),
    location_name: (0, _model.attr)('string'),
    order_type: (0, _model.attr)('number'),
    original_order_id: (0, _model.attr)('string'),
    payment_type: (0, _model.attr)('number'),
    receipt_id: (0, _model.attr)('string'),
    refund_reason: (0, _model.attr)('string'),
    tendered_amount: (0, _model.attr)('number'),
    tip_total: (0, _model.attr)('number'),
    fee_ceiling: (0, _model.attr)('number'),
    total: (0, _model.attr)('number'),
    via_caregiver: (0, _model.attr)('boolean'),
    line_items: (0, _model.hasMany)('line_item', {
      async: false
    }),
    payments: (0, _model.hasMany)('payment', {
      async: false
    }),
    device: (0, _model.belongsTo)('device', {
      async: true
    }),
    employee: (0, _model.belongsTo)('user', {
      async: true
    }),
    person: (0, _model.belongsTo)('person', {
      async: true
    }),
    shift: (0, _model.belongsTo)('shift', {
      async: true
    }),
    subtotal: Ember.computed('line_items', function () {
      return this.get('line_items').sum('subtotal');
    }),
    discount: Ember.computed('line_items', function () {
      return this.get('line_items').sum('discount');
    }),
    tax: Ember.computed('line_items', 'line_items.@each.tax', function () {
      return this.get('line_items').sum('tax');
    }),
    exemptTax: Ember.computed('line_items', 'line_items.@each.exemptTax', function () {
      return this.get('line_items').sum('exemptTax');
    }),
    exclusiveTax: Ember.computed('line_items', 'line_items.@each.exclusiveTax', function () {
      return this.get('line_items').sum('exclusiveTax');
    }),
    tenderedAmount: Ember.computed('payments', 'payments.@each.total', function () {
      return this.get('payments').sum('total');
    }),
    change: Ember.computed('total', 'tenderedAmount', 'tip_total', 'fee_ceiling', function () {
      return this.get('tenderedAmount') - this.get('total') - this.get('tip_total') - this.get('fee_ceiling');
    }),
    hasInclusiveTaxes: Ember.computed('line_items.@each.hasInclusiveTaxes', function () {
      return this.get('line_items').any(function (lineItem) {
        return lineItem.get('hasInclusiveTaxes');
      });
    }),
    // Useful for showing context to store owners
    // when viewing their customer's transactions to understand
    // if a customer might be over purchase limits.
    happenedWithinOneDay: Ember.computed('charged_on', function () {
      var chargedOn = this.get('charged_on');

      if (Ember.isEmpty(chargedOn)) {
        return false;
      }

      var oneDayAgo = (0, _moment.default)().subtract({
        hours: 24
      });
      return (0, _moment.default)(chargedOn) >= oneDayAgo;
    }),
    orderType: Ember.computed('delivered', 'order_type', function () {
      var delivered = this.get('delivered');
      var orderType = this.get('order_type');

      if (delivered) {
        return 'Delivery';
      }

      if (Ember.isEmpty(orderType)) {
        return;
      }

      return _constants.default.orderTypes[orderType].name;
    }),
    orderTypeClass: Ember.computed('orderType', function () {
      var orderType = this.get('orderType');

      if (Ember.isEmpty(orderType)) {
        return;
      }

      return orderType.toLowerCase();
    }),
    isPinDebitPayment: Ember.computed('fee_ceiling', function () {
      return this.get('fee_ceiling') !== null;
    })
  });

  _exports.default = _default;
});