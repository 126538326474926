define("herer-web/templates/qs-reports/sales-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g0KjWEAg",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"sales-report\",null,[[\"title\",\"report\",\"reportTypes\",\"interval\",\"startDate\",\"endDate\",\"column\",\"columns\",\"groupBy\",\"groupBys\",\"sortBy\",\"sortDirection\",\"measure\",\"validMeasures\",\"table\",\"tableColumn\",\"tableGroupBy\",\"onRefreshClick\",\"onExportClick\",\"onIntervalChange\",\"data-test-group\"],[[24,[\"title\"]],[24,[\"report\"]],[24,[\"reportTypes\"]],[24,[\"interval\"]],[24,[\"startDate\"]],[24,[\"endDate\"]],[24,[\"column\"]],[24,[\"columns\"]],[24,[\"groupBy\"]],[24,[\"groupBys\"]],[24,[\"sortBy\"]],[24,[\"sortDirection\"]],[24,[\"measure\"]],[24,[\"validMeasures\"]],[24,[\"table\"]],[24,[\"tableColumn\"]],[24,[\"tableGroupBy\"]],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"refresh\"],null]],null],[28,\"action\",[[23,0,[]],\"exportReport\"],null],[28,\"action\",[[23,0,[]],\"updateDatesFromInterval\"],null],\"sales_by_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/sales-by.hbs"
    }
  });

  _exports.default = _default;
});