define("herer-web/components/radio-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: null,
    groupValue: null,
    onChange: null,
    disabled: false,
    inline: false,
    object: null,
    actions: {
      sendChangeAction: function sendChangeAction(value) {
        this.onChange(value, this.get('object'));
      }
    }
  });

  _exports.default = _default;
});