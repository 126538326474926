define("herer-web/templates/components/facility-cannot-sell-to-patients-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BAhuBwkF",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        These sales failed to report due to having a patient erroneously assigned\\n      \"],[9],[0,\"\\n      \"],[2,\"a\\n        href=\\\"https://support.dutchie.com/hc/en-us/articles/12886821249043-How-to-Fix-Failed-Splits-and-Merges\\\"\\n        target=\\\"_blank\\\"\\n        data-test-link=\\\"help\\\"\\n      >\\n        Learn how to fix it…\\n      </a\"],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row header-row table-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"\\n          Failed Order\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-3 hidden-sm hidden-xs\"],[8],[0,\"\\n          Patient ID\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notification\",\"items\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"facility-cannot-sell-to-patients-notification-row\",null,[[\"notification\",\"data-test-item\"],[[23,1,[]],\"notification\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/facility-cannot-sell-to-patients-notification.hbs"
    }
  });

  _exports.default = _default;
});