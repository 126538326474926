define("herer-web/components/bill-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showModal: false,
    placeholder: '0.00',
    currency: 0,
    total: 0,
    hundreds: null,
    fifties: null,
    twenties: null,
    tens: null,
    fives: null,
    ones: null,
    quarters: null,
    dimes: null,
    nickels: null,
    pennies: null,
    savedHundreds: null,
    savedFifties: null,
    savedTwenties: null,
    savedTens: null,
    savedFives: null,
    savedOnes: null,
    savedQuarters: null,
    savedDimes: null,
    savedNickels: null,
    savedPennies: null,
    countsDidChange: function () {
      this.beginPropertyChanges();
      this.set('total', 0);
      this.incrementTotal(this.get('hundreds'), 10000);
      this.incrementTotal(this.get('fifties'), 5000);
      this.incrementTotal(this.get('twenties'), 2000);
      this.incrementTotal(this.get('tens'), 1000);
      this.incrementTotal(this.get('fives'), 500);
      this.incrementTotal(this.get('ones'), 100);
      this.incrementTotal(this.get('quarters'), 25);
      this.incrementTotal(this.get('dimes'), 10);
      this.incrementTotal(this.get('nickels'), 5);
      this.incrementTotal(this.get('pennies'), 1);
      this.endPropertyChanges();
    }.observes('hundreds', 'fifties', 'twenties', 'tens', 'fives', 'ones', 'quarters', 'dimes', 'nickels', 'pennies'),
    didInsertElement: function didInsertElement() {
      this.saveCounts();
    },
    incrementTotal: function incrementTotal(count, value) {
      this.set('total', this.get('total') + this.notNull(count) * value);
    },
    notNull: function notNull(property) {
      return property ? property : 0;
    },
    restoreCounts: function restoreCounts() {
      this.set('hundreds', this.get('savedHundreds'));
      this.set('fifties', this.get('savedFifties'));
      this.set('twenties', this.get('savedTwenties'));
      this.set('tens', this.get('savedTens'));
      this.set('fives', this.get('savedFives'));
      this.set('ones', this.get('savedOnes'));
      this.set('quarters', this.get('savedQuarters'));
      this.set('dimes', this.get('savedDimes'));
      this.set('nickels', this.get('savedNickels'));
      this.set('pennies', this.get('savedPennies'));
    },
    saveCounts: function saveCounts() {
      this.set('savedHundreds', this.get('hundreds'));
      this.set('savedFifties', this.get('fifties'));
      this.set('savedTwenties', this.get('twenties'));
      this.set('savedTens', this.get('tens'));
      this.set('savedFives', this.get('fives'));
      this.set('savedOnes', this.get('ones'));
      this.set('savedQuarters', this.get('quarters'));
      this.set('savedDimes', this.get('dimes'));
      this.set('savedNickels', this.get('nickels'));
      this.set('savedPennies', this.get('pennies'));
    },
    actions: {
      showModal: function showModal() {
        this.set('showModal', true);
      },
      onHide: function onHide() {
        this.restoreCounts();
        this.set('showModal', false);
      },
      save: function save() {
        this.saveCounts();
        this.set('currency', this.get('total'));
        this.set('showModal', false);
      },
      setCurrency: function setCurrency(currency) {
        this.set('currency', currency);
      },
      setHundreds: function setHundreds(value) {
        this.set('hundreds', value);
      },
      setFifties: function setFifties(value) {
        this.set('fifties', value);
      },
      setTwenties: function setTwenties(value) {
        this.set('twenties', value);
      },
      setTens: function setTens(value) {
        this.set('tens', value);
      },
      setFives: function setFives(value) {
        this.set('fives', value);
      },
      setOnes: function setOnes(value) {
        this.set('ones', value);
      },
      setQuarters: function setQuarters(value) {
        this.set('quarters', value);
      },
      setDimes: function setDimes(value) {
        this.set('dimes', value);
      },
      setNickels: function setNickels(value) {
        this.set('nickels', value);
      },
      setPennies: function setPennies(value) {
        this.set('pennies', value);
      }
    }
  });

  _exports.default = _default;
});