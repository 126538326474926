define("herer-web/components/nav-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['active'],
    new: false,
    textPrefix: null,
    icon: null,
    hasErrors: false,
    dataTestId: Ember.computed('route', function () {
      return "nav-link-".concat(this.get('route'));
    }),
    linkView: Ember.computed('childViews', function () {
      return this.get('childViews.firstObject');
    }),
    active: Ember.computed('linkView.active', function () {
      var linkView = this.get('linkView');

      if (Ember.isPresent(linkView)) {
        return linkView.get('active');
      }
    })
  });

  _exports.default = _default;
});