define("herer-web/serializers/employee", ["exports", "@ember-data/serializer/rest", "active-model-adapter"], function (_exports, _rest, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      fileAttachments: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (payload.employees) {
        payload.employees.forEach(function (employee) {
          _this.normalizeEmployee(employee);
        });
      } else {
        this.normalizeEmployee(payload.employee);
      }

      return this._super.apply(this, arguments);
    },
    normalizeEmployee: function normalizeEmployee(employee) {
      ['first_name', 'last_name', 'phone', 'email', 'id_number'].forEach(function (attribute) {
        if (employee[attribute] === null) {
          employee[attribute] = '';
        }
      });
    }
  });

  _exports.default = _default;
});