define("herer-web/models/place-quantity", ["exports", "@ember-data/model", "ember-data-model-fragments", "herer-web/constants"], function (_exports, _model, _emberDataModelFragments, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    quantity: (0, _model.attr)('weight'),
    inventory_status: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    isForSale: Ember.computed('inventory_status', function () {
      return this.get('inventory_status') === _constants.default.placeInventoryStatus.forSale;
    }),
    isLocked: Ember.computed('inventory_status', function () {
      return this.get('inventory_status') === _constants.default.placeInventoryStatus.locked;
    }),
    hasNonZeroQuantity: Ember.computed('quantity', function () {
      return this.get('quantity.value') != 0;
    })
  });

  _exports.default = _default;
});