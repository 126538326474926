define("herer-web/components/qs-report-data-refresh", ["exports", "herer-web/constants", "herer-web/helpers/format-date"], function (_exports, _constants, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    http: Ember.inject.service(),
    reportName: null,
    changedAt: null,
    status: null,
    inProgress: Ember.computed('status', function () {
      return ['initialized', 'queued', 'running'].includes(this.status);
    }),
    isCompleted: Ember.computed('status', function () {
      return this.status === 'completed';
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get("inProgress") === true) {
        this.checkRefreshStatus();
      }
    },
    checkRefreshStatus: function checkRefreshStatus() {
      var _this = this;

      this.set('status', 'initialized');
      Ember.get(this, 'http').get("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/account_reports/").concat(this.reportName, "/last_update")).then(function (response) {
        _this.set('status', response.data.status);

        _this.set('changedAt', response.data.changed_at); // Allows us to poll checking refresh status and update
        // customer without having them reload the page.


        var oneMinuteInMilliseconds = 60000;

        if (_this.get('isCompleted') === true) {
          _this.showRefreshCompleteMessage();
        } else {
          setTimeout(function () {
            _this.checkRefreshStatus();
          }, oneMinuteInMilliseconds);
        }
      }).catch(function (e) {
        return _this.handleRefreshFailure(e);
      });
    },
    showRefreshCompleteMessage: function showRefreshCompleteMessage() {
      var refreshDate = (0, _formatDate.formatDate)(this.get('changedAt'));
      var msg = "\n      <h3>Data Refresh Complete</h3>\n      <p>Last refreshed: ".concat(refreshDate, "</p>\n    ");
      this.showFlashMessage(Ember.String.htmlSafe(msg));
    },
    showRefreshMessage: function showRefreshMessage() {
      var msg = "\n      <h3>Refreshing Report Data</h3>\n      <p>This could take some time depending on the amount of data in your account. Please be patient.</p>\n    ";
      this.showFlashMessage(Ember.String.htmlSafe(msg));
    },
    handleRefreshFailure: function handleRefreshFailure(e) {
      console.log("Report Data Refresh Failed");
      console.log(e);
      this.set('status', 'failed');
      var msg = "\n      <h3>Data Refresh Failed</h3>\n      <p>\n        Please try again in a few minutes. If you continue to see this message please contact support.\n      </p>\n    ";
      this.showFlashMessage(Ember.String.htmlSafe(msg), 'alert-warning');
    },
    actions: {
      refreshReportData: function refreshReportData() {
        var _this2 = this;

        this.set('status', 'initialized');
        this.showRefreshMessage();
        Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/account_reports/").concat(this.reportName, "/refresh")).then(function (response) {
          _this2.set('status', response.data.status);

          _this2.set('changedAt', response.data.changed_at);

          _this2.checkRefreshStatus();
        }).catch(function (e) {
          return _this2.handleRefreshFailure(e);
        });
      }
    }
  });

  _exports.default = _default;
});