define("herer-web/routes/manifests", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/label-preference", "herer-web/constants", "herer-web/models/paged-array"], function (_exports, _authenticatedSideNav, _labelPreference, _constants, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_labelPreference.default, {
    sideNavTemplateName: 'inventory-nav',
    featureManager: Ember.inject.service(),
    http: Ember.inject.service(),
    permissionKey: 'receive_inventory',
    queryParams: {
      status: {
        refreshModel: true
      },
      sku: {
        refreshModel: true
      },
      shipment_id: {
        refreshModel: true
      },
      supplier: {
        refreshModel: true
      }
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey) {
      if (urlKey === "status") {
        return value ? value : this.get('defaultStatus');
      }

      return this._super.apply(this, arguments);
    },
    defaultStatus: Ember.computed({
      get: function get() {
        var canAccept = this.get('featureManager').can('accept-manifests');
        var status = canAccept ? _constants.default.remoteManifestStatus.pending : _constants.default.remoteManifestStatus.unassigned;
        return status;
      }
    }).volatile(),
    model: function model(params) {
      var status = this.get('defaultStatus');

      if (params.status) {
        status = parseInt(params.status);
      }

      var searchBySku = Ember.isPresent(params.sku) && params.sku.length > 0;
      var isAccepted = status === _constants.default.remoteManifestStatus.accepted;
      var isUnassigned = status === _constants.default.remoteManifestStatus.unassigned;
      var model;
      var filters = {};

      if (isAccepted || isUnassigned || searchBySku) {
        model = 'manifest';
        filters.by_type = _constants.default.manifestType.newInventory;

        if (searchBySku) {
          filters.by_sku = params.sku;
        }

        if (isUnassigned && !searchBySku) {
          filters.pending = true;
        }
      } else {
        model = 'remote_manifest';
        filters.by_status = status;
      }

      if (Ember.isPresent(params.shipment_id)) {
        filters.by_shipment_id = params.shipment_id;
      }

      if (Ember.isPresent(params.supplier)) {
        filters.by_supplier = params.supplier;
      }

      var pagedArray = _pagedArray.PagedArray.create({
        store: this.get('store'),
        model: model,
        filters: filters,
        sortBy: 'charged_on',
        sortDirection: 'desc',
        limit: 20
      });

      return Ember.RSVP.hash({
        pagedArray: pagedArray.fetchObjects(),
        labelPreference: this.findLabelPreference(this.get('store'))
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.pagedArray);

      controller.set('labelPreference', model.labelPreference);
    }
  });

  _exports.default = _default;
});