define("herer-web/components/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NoDataComponent = Ember.Component.extend();
  var _default = NoDataComponent;
  _exports.default = _default;
});