define("herer-web/controllers/monthly-tax-report", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants", "underscore.string/lpad", "lodash/reduce", "moment"], function (_exports, _alertUnsavedController, _context, _constants, _lpad, _reduce, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    queryParams: ['month', 'year'],
    month: parseInt((0, _moment.default)().subtract(1, 'month').format('M')),
    year: parseInt((0, _moment.default)().subtract(1, 'month').format('YYYY')),
    stateCredential: null,
    isLoading: false,
    storeNameLabel: Ember.computed('currentLocation', function () {
      return this.get('currentLocation.name');
    }),
    canSubmitForWashington: Ember.computed('year', 'month', 'currentLocation', function () {
      var currentMonth = (0, _moment.default)().month() + 1;
      var currentYear = (0, _moment.default)().year();
      var year = this.get('year');
      var month = this.get('month');
      return this.get('currentLocation.isWashington') && (year < currentYear || month < currentMonth && currentYear === year);
    }),
    months: _constants.default.months,
    years: _constants.default.years,
    monthAndYearLabel: Ember.computed('month', 'year', function () {
      return (0, _moment.default)("".concat(this.get('month'), " ").concat(this.get('year')), 'M YYYY').format('MMMM YYYY');
    }),
    monthLabel: Ember.computed('month', 'year', function () {
      return (0, _moment.default)("".concat(this.get('month')), 'M').format('MMMM');
    }),
    nextMonthLabel: Ember.computed('month', 'year', function () {
      return (0, _moment.default)("".concat(this.get('month') + 1), 'M').format('MMMM');
    }),
    mjOnlyTaxes: Ember.computed('model.taxes.[]', function () {
      return this.get('model.taxes').filter(function (taxItem) {
        return taxItem.mj_only;
      });
    }),
    cannabisGrossSales: Ember.computed('mjOnlyTaxes.@each.sales', function () {
      return this.sumMjOnlyTaxes('sales') - this.sumMjOnlyTaxes('after_tax_discounts');
    }),
    owedCannabisTax: Ember.computed('mjOnlyTaxes.@each.owed', function () {
      return this.sumMjOnlyTaxes('owed');
    }),
    payOnlineURL: Ember.computed('owedCannabisTax', 'year', 'month', 'currentLocation', function () {
      var dueDate = (0, _moment.default)("".concat(this.get('year'), "-").concat(this.get('month'), "-20"), 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM-DD');
      var exciseStr = (0, _lpad.default)("".concat(this.get('owedCannabisTax')), 3, '0');
      var dollars = exciseStr.slice(0, -2);
      var cents = exciseStr.slice(-2);
      var amountDue = "".concat(dollars, ".").concat(cents);
      var licenseNumber = this.get('currentLocation.license_number');
      return 'https://bill.paystation.com/wslcbadapter/?' + "productCode=MarijuanaExciseTax&amountDue=".concat(amountDue) + "&dueDate=".concat(dueDate) + '&billerId=EXC&billerGroupId=WSL&disallowLogin=N' + "&LicenseNumber=".concat(licenseNumber);
    }),
    sumMjOnlyTaxes: function sumMjOnlyTaxes(attribute) {
      return (0, _reduce.default)(this.get('mjOnlyTaxes'), function (sum, taxItem) {
        return sum + taxItem[attribute];
      }, 0);
    },
    actions: {
      print: function print() {
        window.print();
      },
      submitMonthlyTax: function submitMonthlyTax() {
        var _this = this;

        if (this.get('model.submission')) {
          return;
        }

        var submission = this.store.createRecord('monthly_tax_submission', {
          month: this.get('month'),
          year: this.get('year'),
          gross_sales: this.get('cannabisGrossSales'),
          excise_tax: this.get('owedCannabisTax'),
          submitted_at: new Date()
        });
        this.set('model.submission', submission);
        submission.save().catch(function () {
          _this.set('model.submission', null);
        });
      }
    }
  });

  _exports.default = _default;
});