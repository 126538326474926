define("herer-web/mixins/resize-handler", ["exports", "lodash/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResizeHandler = Ember.Mixin.create({
    resizeEndDelay: 100,
    resizing: false,
    onResizeStart: function onResizeStart() {},
    onResizeEnd: function onResizeEnd() {},
    onResize: function onResize() {},
    debounceResizeEnd: Ember.computed(function () {
      var func = $.proxy(function (event) {
        if (this.get('isDestroyed')) {
          return;
        }

        this.set('resizing', false);

        if (this.get('onResizeEnd')) {
          this.get('onResizeEnd')(event);
        }
      }, this);
      return (0, _debounce.default)(func, this.get('resizeEndDelay'));
    }),
    resizeHandler: Ember.computed(function () {
      return $.proxy(this.handleWindowResize, this);
    }),
    handleWindowResize: function handleWindowResize(event) {
      if (!this.resizing) {
        this.set('resizing', true);

        if (this.get('onResizeStart')) {
          this.get('onResizeStart')(event);
        }
      }

      if (this.get('onResize')) {
        this.get('onResize')(event);
      }

      return this.get('debounceResizeEnd')(event);
    },
    didInsertElement: function didInsertElement() {
      this._super();

      $(window).on("resize.".concat(Ember.guidFor(this)), this.get('resizeHandler'));
    },
    willDestroy: function willDestroy() {
      $(window).off("resize.".concat(Ember.guidFor(this)), this.get('resizeHandler'));

      this._super();
    }
  });
  var _default = ResizeHandler;
  _exports.default = _default;
});