define("herer-web/templates/components/input-well", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "06j7UPXu",
    "block": "{\"symbols\":[\"@isOpen\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"help-well-container animated \",[28,\"if\",[[23,1,[]],\"well-show\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"well help-well animated\"],[8],[14,2],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/input-well.hbs"
    }
  });

  _exports.default = _default;
});