define("herer-web/components/manifest-row", ["exports", "herer-web/constants", "herer-web/mixins/inventory-printing"], function (_exports, _constants, _inventoryPrinting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_inventoryPrinting.default, (_dec = Ember.computed('lots.@each.{checked,quantity}'), _dec2 = Ember.computed('filterStatus'), (_obj = {
    launchDarkly: Ember.inject.service(),
    showModal: false,
    classNames: ['row', 'table-row'],
    manifest: null,
    filterStatus: null,
    selectedAll: false,
    printUnavailable: true,
    labelPreference: null,
    lots: Ember.computed('manifest.lots', function () {
      return this.manifest.lots.map(function (lot) {
        var _lot$product;

        return {
          checked: false,
          name: ((_lot$product = lot.product) === null || _lot$product === void 0 ? void 0 : _lot$product.name) || lot.name,
          sku: lot.sku,
          record: lot,
          quantity: parseInt(lot.quantity, 10) || 1
        };
      });
    }),
    statusLabelClass: Ember.computed('manifest.status', function () {
      switch (this.get('manifest.status')) {
        case _constants.default.remoteManifestStatus.pending:
          return 'text-primary';

        case _constants.default.remoteManifestStatus.accepted:
          return 'text-success';

        case _constants.default.remoteManifestStatus.rejected:
          return 'text-danger';

        case _constants.default.remoteManifestStatus.voided:
          return 'text-muted';

        default:
          return '';
      }
    }),
    statusLabelIcon: Ember.computed('manifest.status', function () {
      switch (this.get('manifest.status')) {
        case _constants.default.remoteManifestStatus.pending:
          return 'fa-exclamation-circle';

        case _constants.default.remoteManifestStatus.accepted:
          return 'fa-check-circle';

        case _constants.default.remoteManifestStatus.rejected:
          return 'fa-times-circle';

        case _constants.default.remoteManifestStatus.voided:
          return 'fa-minus-circle';

        default:
          return '';
      }
    }),
    click: function click(event) {
      if (event.target.classList.contains('dropdown-action')) return;
      this.viewManifest(this.get('manifest'));
    },
    areSelectedAll: function areSelectedAll() {
      return this.get('lots').every(function (lot) {
        return lot.checked;
      });
    },
    printBarcodes: function printBarcodes() {
      this.set('isPrinting', true);
      var labelPreference = this.get('labelPreference');
      var printableData = [];
      this.get('lots').forEach(function (lot) {
        var quantity = lot.quantity;

        if (lot.checked && quantity > 0) {
          printableData.push({
            inventoryItem: lot.record,
            quantity: quantity,
            labelPreference: labelPreference
          });
        }
      });

      if (printableData.length > 0) {
        bootbox.alert('Labels sent to printer');
        this.runPrint(this.get('labelPrinter'), printableData, 0);
      }

      this.set('showModal', false);
      this.resetModalConfig();
    },
    resetModalConfig: function resetModalConfig() {
      this.set('manifest.lots', this.manifest.lots);
      this.set('selectedAll', false);
      this.set('printUnavailable', this.nothingToPrint());
    },
    runPrint: function runPrint(labelPrinter, printableData, idx) {
      var _this = this;

      if (idx < printableData.length) {
        labelPrinter.printSkuLabel(printableData[idx]).then(function () {}).catch(function (e) {
          console.log(e);

          _this.handlePrintingError(e);
        }).finally(function () {
          _this.runPrint(labelPrinter, printableData, idx + 1);
        });
      } else {
        this.set('isPrinting', false);
      }
    },

    get nothingToPrint() {
      if (this.lots.every(function (lot) {
        return !lot.checked;
      })) return true;
      return !this.lots.every(function (lot) {
        return !lot.checked || lot.checked && lot.quantity > 0;
      });
    },

    get isEnabled() {
      return this.filterStatus === _constants.default.remoteManifestStatus.accepted;
    },

    actions: {
      viewManifest: function viewManifest() {
        this.click();
      },
      toggleSelectAll: function toggleSelectAll() {
        var lots = this.get('lots');
        var value = !this.areSelectedAll();
        lots.forEach(function (lot) {
          return Ember.set(lot, 'checked', value);
        });
        this.set('selectedAll', value);
        this.set('printUnavailable', this.nothingToPrint);
      },
      updateChecked: function updateChecked(sku, idx) {
        var lot = this.get('lots').objectAt(idx);
        if (lot.sku === sku) Ember.set(lot, 'checked', !lot.checked);
        this.set('selectedAll', this.areSelectedAll());
        this.set('printUnavailable', this.nothingToPrint);
      },
      updateQuantity: function updateQuantity(sku, idx, event) {
        var lot = this.get('lots').objectAt(idx);
        if (lot.sku === sku) Ember.set(lot, 'quantity', parseInt(event.target.value));
        this.set('printUnavailable', this.nothingToPrint);
      },
      printBarcodes: function printBarcodes() {
        if (this.get('printDisabled')) {
          return this.showPrinterCTA();
        }

        this.set('showModal', true);
      },
      onHide: function onHide() {
        this.set('showModal', false);
        this.resetModalConfig();
      },
      print: function print() {
        this.printBarcodes();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "nothingToPrint", [_dec], Object.getOwnPropertyDescriptor(_obj, "nothingToPrint"), _obj), _applyDecoratedDescriptor(_obj, "isEnabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isEnabled"), _obj)), _obj)));

  _exports.default = _default;
});