define("herer-web/templates/packages/reconcile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s/pw8jqG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"packages/reconcile\",null,[[\"onCancel\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/packages/reconcile.hbs"
    }
  });

  _exports.default = _default;
});