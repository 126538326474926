define("herer-web/utils/dates-intervals", ["exports", "herer-web/constants", "moment"], function (_exports, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datesFromInterval = datesFromInterval;
  _exports.intervalFromDates = intervalFromDates;
  _exports.isExpired = isExpired;

  function datesFromInterval(interval, date) {
    if (!date) {
      date = (0, _moment.default)();
    }

    var newStartDate;
    var newEndDate;

    switch (interval) {
      case 'yesterday':
        newStartDate = (0, _moment.default)(date).subtract(1, 'd').startOf('day');
        newEndDate = (0, _moment.default)(date).subtract(1, 'd').endOf('day');
        break;

      case 'week':
        newStartDate = (0, _moment.default)(date).startOf('week');
        newEndDate = (0, _moment.default)(date).endOf('week');
        break;

      case 'prev-week':
        newStartDate = (0, _moment.default)(date).subtract(1, 'w').startOf('week');
        newEndDate = (0, _moment.default)(date).subtract(1, 'w').endOf('week');
        break;

      case 'month':
        newStartDate = (0, _moment.default)(date).startOf('month');
        newEndDate = (0, _moment.default)(date).endOf('month');
        break;

      case 'prev-month':
        newStartDate = (0, _moment.default)(date).subtract(1, 'M').startOf('month');
        newEndDate = (0, _moment.default)(date).subtract(1, 'M').endOf('month');
        break;

      case 'quarter':
        newStartDate = (0, _moment.default)(date).startOf('quarter');
        newEndDate = (0, _moment.default)(date).endOf('quarter');
        break;

      case 'prev-quarter':
        newStartDate = (0, _moment.default)(date).subtract(1, 'Q').startOf('quarter');
        newEndDate = (0, _moment.default)(date).subtract(1, 'Q').endOf('quarter');
        break;

      case 'year':
        newStartDate = (0, _moment.default)(date).startOf('year');
        newEndDate = (0, _moment.default)(date).endOf('year');
        break;

      case 'prev-year':
        newStartDate = (0, _moment.default)(date).subtract(1, 'y').startOf('year');
        newEndDate = (0, _moment.default)(date).subtract(1, 'y').endOf('year');
        break;

      case '12-months':
        newStartDate = (0, _moment.default)(date).subtract(11, 'M').startOf('month');
        newEndDate = (0, _moment.default)(date).endOf('month');
        break;

      case '30-days':
        newStartDate = (0, _moment.default)(date).subtract(29, 'd').startOf('day');
        newEndDate = (0, _moment.default)(date).endOf('day');
        break;

      case '7-days':
        newStartDate = (0, _moment.default)(date).subtract(6, 'd').startOf('day');
        newEndDate = (0, _moment.default)(date).endOf('day');
        break;

      default:
        newStartDate = (0, _moment.default)(date).startOf('day');
        newEndDate = (0, _moment.default)(date).endOf('day');
        break;
    }

    return {
      startDate: newStartDate,
      endDate: newEndDate
    };
  }

  function intervalFromDates(startDate, endDate) {
    var intervalData = _constants.default.reportIntervals.find(function (data) {
      var dates = datesFromInterval(data.value);
      return dates.startDate.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD') && dates.endDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD');
    });

    return Ember.isPresent(intervalData) ? intervalData.value : null;
  }

  function isExpired(date) {
    var expirationDate = date;

    if (Ember.isEmpty(expirationDate)) {
      return false;
    }

    var expiration = (0, _moment.default)({
      y: expirationDate.getFullYear(),
      M: expirationDate.getMonth(),
      d: expirationDate.getDate()
    });
    var now = new Date();
    var today = (0, _moment.default)({
      y: now.getFullYear(),
      M: now.getMonth(),
      d: now.getDate()
    });
    return today.isAfter(expiration, 'day');
  }
});