define("herer-web/components/packages/list-row", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    tagName: '',
    launchDarkly: Ember.inject.service(),
    package: null,
    showCheckbox: false,
    onAdjust: null,
    onSplit: null,
    onScheduleDestroy: null,
    onUnscheduleDestroy: null,
    onCommitDestroy: null,
    onClose: null,
    onReopen: null,
    onSelect: null,
    onReconcile: null,
    onClick: null,
    stateCredential: null,
    isSelected: Ember.computed('selectedPackages.[]', 'package', function () {
      return this.get('selectedPackages') && this.get('selectedPackages').includes(this.get('package'));
    }),
    shouldShowMenu: Ember.computed('package.{isStatusActive,isStatusClosed,isStatusScheduledForDestruction}', function () {
      var aPackage = this.get('package'); // Only packages with these statuses have menu options.

      return aPackage.get('isStatusActive') || aPackage.get('isStatusClosed') || aPackage.get('isStatusScheduledForDestruction');
    }),
    supportsCCRS: Ember.computed('currentLocation', function () {
      return this.get('currentLocation.isWashington') && this.get('launchDarkly').can('enable-ccrs');
    }),
    isDestroyable: Ember.computed('currentLocation', 'package.quantity', function () {
      return !this.get('currentLocation.isWashington') && !this.get('package.quantity.isZero');
    }),
    actions: {
      handleClick: function handleClick() {
        return this.onClick(this.get('package'));
      },
      setIsSelected: function setIsSelected(event) {
        event.stopPropagation();

        if (this.get('isSelected')) {
          this.onDeselect(this.get('package'));
        } else {
          this.onSelect(this.get('package'));
        }
      }
    }
  });

  _exports.default = _default;
});