define("herer-web/routes/auth-leafly", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/constants"], function (_exports, _authenticatedFullPage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    queryParams: {
      code: {
        replace: true
      },
      state: {
        replace: true
      }
    },
    http: Ember.inject.service(),
    leaflyAuthManager: Ember.inject.service(),
    model: function model(params) {
      var leaflyAuthManager = this.get('leaflyAuthManager');
      var http = Ember.get(this, 'http');
      return leaflyAuthManager.fetchAccessToken(params).then(function (hash) {
        var menuFeed = hash.menuFeed;
        menuFeed.set('apiKey', hash.tokenData.access_token);
        menuFeed.set('refreshToken', hash.tokenData.refresh_token);
        return menuFeed.save();
      }).then(function (menuFeed) {
        var baseUrl = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/menu_feeds");
        return Ember.RSVP.hash({
          menuFeed: menuFeed,
          stores: http.get("".concat(baseUrl, "/").concat(menuFeed.get('id'), "/leafly_stores")).then(function (response) {
            return response.data;
          })
        });
      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.menuFeed);
      controller.set('leaflyStores', hash.stores.menu_feeds);
    }
  });

  _exports.default = _default;
});