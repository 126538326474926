define("herer-web/controllers/imports", ["exports", "herer-web/constants", "herer-web/mixins/titan-email"], function (_exports, _constants, _titanEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_titanEmail.default, {
    http: Ember.inject.service(),
    file: undefined,
    filename: undefined,
    isLoading: false,
    personGroups: [],
    selectedType: 'Product',
    params: {
      by_created_at: {
        comparator: '<',
        date: '1950-01-01'
      }
    },
    selectedClass: Ember.computed('selectedType', function () {
      if (this.get('selectedType') === 'Product') {
        return 'Product';
      } else if (this.get('selectedType') === 'WaInventoryItem') {
        return 'WaInventoryItem';
      } else {
        return 'Person';
      }
    }),
    exportList: function exportList(selectedKlass, params) {
      var _this = this;

      this.set('exportDisabled', true);

      if (!Ember.isEmpty(this.get('titanEmail'))) {
        params.email_to = this.get('titanEmail');
      }

      Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/").concat(selectedKlass, "/export"), params).then(function () {
        _this.set('exportDisabled', false);

        _this.get('toast').success("We'll email you when the ".concat(selectedKlass, " export is ready."), 'Processing export...');
      }).catch(function () {
        _this.get('toast').error("Failed to export the ".concat(selectedKlass, " list."));

        _this.set('exportDisabled', false);
      });
    },
    actions: {
      exportCustomers: function exportCustomers() {
        this.exportList('people', this.get('params'));
      },
      exportProducts: function exportProducts() {
        this.exportList('products', this.get('params'));
      },
      import: function _import() {
        var _this2 = this;

        this.set('isLoading', true);
        var url = "".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/imports");
        var params = {
          file: this.get('file'),
          filename: this.get('filename'),
          import_type: this.get('selectedType'),
          import_class: this.get('selectedClass')
        };

        if (!Ember.isEmpty(this.get('titanEmail'))) {
          params.email_to = this.get('titanEmail');
        }

        return Ember.get(this, 'http').post(url, params).then(function () {
          _this2.set('isLoading', false);

          _this2.transitionToRoute('imports.confirmation');
        });
      }
    }
  });

  _exports.default = _default;
});