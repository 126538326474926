define("herer-web/templates/components/account-reports/daily-sales/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6x6mJIPf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"location\"],[10,\"data-test-group\",\"location_row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"style\",[22,\"backgroundStyles\"]],[10,\"class\",\"inner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"style\",[22,\"textStyles\"]],[10,\"class\",\"title\"],[10,\"data-test-text\",\"name\"],[8],[0,\"\\n      \"],[1,[24,[\"row\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"canShowChart\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"style\",[22,\"textStyles\"]],[10,\"class\",\"sales\"],[10,\"data-test-text\",\"sales\"],[8],[0,\"\\n        \"],[1,[28,\"format-unit\",[[24,[\"row\",\"sales\"]]],[[\"unit\",\"dollars\"],[\"dollar\",true]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"      \"],[15,\"spinner\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"data-test-text\",\"no_data\"],[8],[0,\"\\n        No data to show.\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "herer-web/templates/components/account-reports/daily-sales/row.hbs"
    }
  });

  _exports.default = _default;
});