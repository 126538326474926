define("herer-web/templates/components/medical-sales-by-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZRs2vY0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"currentLocation\",\"medical\"]],[28,\"has-permission\",[\"view_sales_by_reports\"],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"data-test-link\",\"queryParamsObj\"],[\"reports.sales-by\",\"Medical Sales\",\"medical_sales\",[28,\"query-params\",null,[[\"report\",\"sortBy\",\"sortDirection\",\"column\",\"groupBy\",\"interval\",\"startDate\",\"endDate\",\"refresh\"],[\"patient\",\"Total\",\"desc\",null,null,\"week\",null,null,true]]]]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"data-test-link\",\"queryParamsObj\"],[\"reports.sales-by\",\"Medical Sales by Brand\",\"medical_sales_by_brand\",[28,\"query-params\",null,[[\"report\",\"sortBy\",\"sortDirection\",\"column\",\"groupBy\",\"interval\",\"startDate\",\"endDate\",\"refresh\"],[\"patient\",\"Total\",\"desc\",null,\"brand\",\"week\",null,null,true]]]]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"label\",\"data-test-link\",\"queryParamsObj\"],[\"reports.sales-by\",\"Medical Sales by Product Type\",\"medical_sales_by_product_type\",[28,\"query-params\",null,[[\"report\",\"sortBy\",\"sortDirection\",\"column\",\"groupBy\",\"interval\",\"startDate\",\"endDate\",\"refresh\"],[\"patient\",\"Total\",\"desc\",null,\"product_type\",\"week\",null,null,true]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/medical-sales-by-links.hbs"
    }
  });

  _exports.default = _default;
});