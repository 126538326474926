define("herer-web/models/employee", ["exports", "@ember-data/model", "herer-web/models/person"], function (_exports, _model, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    // NOTE: If refactoring to a unified Person object with multiple types,
    //       consider adding employee_id_number & migrating existing data
    //       since this is the same field used for medical IDs.
    id_number: (0, _model.attr)('string'),
    isPatient: false,
    isCustomer: false,
    isEmployee: true
  });

  _exports.default = _default;
});