define("herer-web/mixins/test-results", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    test_results_thc: (0, _model.attr)('number'),
    test_results_max_thc: (0, _model.attr)('number'),
    test_results_total_thc: (0, _model.attr)('weight'),
    test_results_cbd: (0, _model.attr)('number'),
    test_results_max_cbd: (0, _model.attr)('number'),
    test_results_total_cbd: (0, _model.attr)('weight'),
    test_results_cbn: (0, _model.attr)('number'),
    test_results_max_cbn: (0, _model.attr)('number'),
    test_results_thca: (0, _model.attr)('number'),
    test_results_total_thca: (0, _model.attr)('weight'),
    test_results_cbda: (0, _model.attr)('number'),
    test_results_total_cbda: (0, _model.attr)('weight'),
    test_results_cbg: (0, _model.attr)('number'),
    test_results_max_cbg: (0, _model.attr)('number'),
    test_results_total_cbg: (0, _model.attr)('weight'),
    test_results_cbc: (0, _model.attr)('number'),
    test_results_max_cbc: (0, _model.attr)('number'),
    test_results_total_cbc: (0, _model.attr)('weight'),
    test_results_thcv: (0, _model.attr)('number'),
    test_results_max_thcv: (0, _model.attr)('number'),
    test_results_total_thcv: (0, _model.attr)('weight'),
    test_results_terpenoid_profile: (0, _model.attr)()
  });

  _exports.default = _default;
});