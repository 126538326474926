define("herer-web/components/line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'table-row', 'line-item-row'],
    lineItem: null,
    showQuantity: Ember.computed('lineItem.quantity', function () {
      return !this.get('lineItem.quantity.isUnit') || this.get('lineItem.quantity.value') > 1;
    })
  });

  _exports.default = _default;
});