define("herer-web/templates/menu-feeds/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVGB2eVj",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"menu-feeds/feed-form\",null,[[\"menuFeed\",\"productTypes\",\"allTags\",\"webhookIntegrators\",\"location\",\"disabled\",\"onSave\",\"onCancel\",\"onDestroy\"],[[24,[\"model\"]],[24,[\"productTypes\"]],[24,[\"allTags\"]],[24,[\"webhookIntegrators\"]],[24,[\"currentLocation\"]],[28,\"not\",[[28,\"has-permission\",[\"update_menu_feeds\"],null]],null],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"save\",[24,[\"model\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"cancel\",[24,[\"model\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"destroy\",[24,[\"model\"]]],null]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/menu-feeds/edit.hbs"
    }
  });

  _exports.default = _default;
});