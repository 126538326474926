define("herer-web/components/dutchie-cashless-banner", ["exports", "herer-web/mixins/context", "herer-web/constants"], function (_exports, _context, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    http: Ember.inject.service(),
    toast: Ember.inject.service(),
    dutchieBannerName: 'dutchie-cashless-atm',
    isClosed: false,
    bannerRecord: Ember.computed('currentUser', 'currentLocation', 'banners', function () {
      var _this = this;

      if (!this.get('banners')) {
        return undefined;
      }

      return this.get('banners').find(function (elem) {
        return elem.name === _this.get('dutchieBannerName');
      });
    }),
    isEnabled: Ember.computed('bannerRecord', function () {
      return Boolean(this.get('bannerRecord'));
    }),
    isVisible: Ember.computed('isEnabled', 'isClosed', function () {
      return this.get('isEnabled') && !this.get('isClosed');
    }),
    createBannerOptOut: function createBannerOptOut() {
      var http = this.get('http');
      var bannerId = this.get('bannerRecord.id');
      var url = "".concat(_constants.default.apiHost, "/api/v2/banners/").concat(bannerId, "/banner_opt_outs");
      return http.post(url);
    },
    actions: {
      closeBanner: function closeBanner() {
        var _this2 = this;

        this.createBannerOptOut().then(function () {
          _this2.set('isClosed', true);
        }).catch(function () {
          _this2.get('toast').error('There was a problem saving the banner preferences');
        });
      }
    }
  });

  _exports.default = _default;
});