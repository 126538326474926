define("herer-web/models/monthly-tax-submission", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    month: (0, _model.attr)('number'),
    year: (0, _model.attr)('number'),
    gross_sales: (0, _model.attr)('number'),
    excise_tax: (0, _model.attr)('number'),
    submitted_at: (0, _model.attr)('date')
  });

  _exports.default = _default;
});