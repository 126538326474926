define("herer-web/routes/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    locationId: '',
    model: function model(params) {
      var _this = this;

      this.set('locationId', params.location_id);
      return this.get('authManager').reset().then(function () {
        return _this.newCustomer();
      });
    },
    deactivate: function deactivate() {
      var customer = this.get('controller.model');
      customer.rollbackAttributes();
    },
    newCustomer: function newCustomer() {
      return this.get('store').createRecord('customer', {
        location_id: this.get('locationId')
      });
    },
    actions: {
      reset: function reset() {
        this.controllerFor('register').set('model', this.newCustomer());
      }
    }
  });

  _exports.default = _default;
});