define("herer-web/components/role-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    role: null,
    showSaveMessage: false,
    showErrorMessage: false,
    showDelete: false,
    disabled: false,
    actions: {
      updatePermissions: function updatePermissions(permissions) {
        if (!this.get('role.isDeleted')) {
          this.set('role.permissions', permissions);
        }
      }
    }
  });

  _exports.default = _default;
});