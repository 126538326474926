define("herer-web/templates/traceability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VfKSVG8D",
    "block": "{\"symbols\":[\"proxiedCredential\"],\"statements\":[[5,\"page-header\",[[12,\"data-test-ux\",\"traceability_header\"]],[[\"@custom\"],[true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-7\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[1,[23,0,[\"currentLocation\",\"stateName\"]],false],[0,\" Traceability Settings\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[10,\"data-test-group\",\"credentials_form\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"credentials\"]]],null,{\"statements\":[[4,\"unless\",[[23,0,[\"proxiedCredential\",\"hide\"]]],null,{\"statements\":[[0,\"      \"],[5,\"state-credential-form\",[],[[\"@credential\",\"@activeUntil\"],[[23,1,[\"content\"]],[23,1,[\"activeUntil\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/traceability.hbs"
    }
  });

  _exports.default = _default;
});