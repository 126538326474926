define("herer-web/components/nav-link-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    handleClick: null,
    isExpanded: false,
    classNames: ['dropdown', 'desktop-link'],
    classNameBindings: ['isExpanded:expanded'],
    actions: {
      onClick: function onClick(event) {
        event.stopPropagation();
        event.preventDefault();
        this.handleClick();
      }
    }
  });

  _exports.default = _default;
});