define("herer-web/services/system-status", ["exports", "herer-web/mixins/context", "herer-web/mixins/deferred-script", "herer-web/models/system-status-notice", "lodash/capitalize", "herer-web/utils/humanize"], function (_exports, _context, _deferredScript, _systemStatusNotice, _capitalize, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_context.default, _deferredScript.default, {
    authManager: Ember.inject.service(),
    dismissedStatusNotices: {},
    incidents: [],
    maintenances: [],
    outageModal: null,
    hasActiveOutage: false,
    scriptSrc: 'https://statuspage-production.s3.amazonaws.com/se-v2.js',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadScript().then(function () {
        if (Ember.isPresent(window.StatusPage)) {
          _this.set('statusPage', new window.StatusPage.page({
            page: 'tx57trqvtfdz'
          }));
        }

        _this.poll();
      });
    },
    shouldShowStatusNotice: function shouldShowStatusNotice(statusNotice) {
      var userClickedDismissIcon = this.get('dismissedStatusNotices')[statusNotice.id];
      var problemWasResolved = statusNotice.resolved_at !== null;
      var shouldShowStatusNotice = !problemWasResolved && !userClickedDismissIcon && this.statusNoticeAppliesToCurrentLocation(statusNotice);
      console.log("Should show System Status Notice? ".concat(shouldShowStatusNotice));
      console.log(statusNotice);
      return shouldShowStatusNotice;
    },
    // We look for State abbreviations inside of parentheses
    // to determine if a statusNotice is only relevant to a state or states.
    //
    // Expects text in the format of:
    //  (CA, OR) A notice for California and Oregon
    statusNoticeAppliesToCurrentLocation: function statusNoticeAppliesToCurrentLocation(statusNotice) {
      var re = /\((\w{2}\b(, \w{2}\b)*)\)/gi;
      var match = statusNotice.name.match(re); // No states? Show to everyone.

      if (!match) {
        return true;
      }

      var firstMatch = match[0];
      var currentLocationStateAbbrevation = this.get('currentLocation.stateAbbreviation');

      if (Ember.isEmpty(currentLocationStateAbbrevation)) {
        return false;
      }

      console.log(currentLocationStateAbbrevation);
      var justStates = firstMatch.substring(1, firstMatch.length - 1).split(',');
      var stateFound = justStates.find(function (state) {
        var cleanState = state.trim().toLowerCase();
        var cleanAbbr = currentLocationStateAbbrevation.trim().toLowerCase();
        return cleanState === cleanAbbr;
      });
      return stateFound;
    },
    updateIncidents: function updateIncidents(incidents) {
      var _this2 = this;

      incidents = incidents.map(function (incident) {
        if (!_this2.shouldShowStatusNotice(incident)) {
          return null;
        }

        return _systemStatusNotice.default.create({
          id: incident.id,
          message: "".concat((0, _capitalize.default)(incident.status), ": ").concat(incident.name),
          impact: incident.impact,
          link: incident.shortlink
        });
      });
      return this.set('incidents', incidents.compact());
    },
    updateMaintenance: function updateMaintenance(maintenances) {
      var _this3 = this;

      maintenances = maintenances.map(function (maintenance) {
        if (!_this3.shouldShowStatusNotice(maintenance)) {
          return null;
        }

        return _systemStatusNotice.default.create({
          id: maintenance.id,
          message: "".concat((0, _humanize.humanize)(maintenance.status).toLowerCase(), ": ").concat(maintenance.name),
          impact: maintenance.impact,
          link: maintenance.shortlink,
          start_at: maintenance.scheduled_for,
          end_at: maintenance.scheduled_until
        });
      });
      return this.set('maintenances', maintenances.compact());
    },
    poll: function poll() {
      var _this4 = this;

      if (navigator.onLine) {
        if (this.get('hasActiveOutage')) {
          this.closeModal();
        }

        this.get('statusPage').summary({
          success: function success(data) {
            _this4.updateIncidents(data.incidents);

            _this4.updateMaintenance(data.scheduled_maintenances);
          }
        });
      } else {
        this.noConnection();
      }

      this.timer = this.schedule();
    },
    noConnection: function noConnection() {
      if (!this.get('hasActiveOutage')) {
        this.set('hasActiveOutage', true);
        this.openModal();
      }
    },
    dismissStatusBanner: function dismissStatusBanner(id, statusType) {
      if (statusType === 'notice') {
        this.set('incidents', this.get('incidents').filter(function (incident) {
          return incident.id !== id;
        }));
        this.get('dismissedStatusNotices')[id] = true;
      } else if (statusType === 'maintenance') {
        this.set('maintenances', this.get('maintenances').filter(function (incident) {
          return incident.id !== id;
        }));
        this.get('dismissedStatusNotices')[id] = true;
      }
    },
    closeModal: function closeModal() {
      this.set('hasActiveOutage', false);
      $('.error-dialog').modal('hide');
    },
    openModal: function openModal() {
      Ember.getOwner(this).lookup('route:application').send('error', {
        disconnected: true
      });
    },
    schedule: function schedule() {
      return setTimeout(this.poll.bind(this), 10 * 1000);
    }
  });

  _exports.default = _default;
});