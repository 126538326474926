define("herer-web/routes/labels", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/context", "ember-uuid/utils/uuid-generator"], function (_exports, _authenticatedSideNav, _context, _uuidGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend(_context.default, {
    sideNavTemplateName: 'settings-nav',
    model: function model() {
      var _this = this;

      return this.store.findAll('label-preference').then(function (labelPreferences) {
        var labelPreference = labelPreferences.get('firstObject');

        if (Ember.isEmpty(labelPreference)) {
          labelPreference = _this.get('store').createRecord('label-preference', {
            id: _uuidGenerator.default.v4(),
            storeName: _this.get('currentLocation.name'),
            autoPrintDateLabel: false,
            printPatientLabels: true,
            printPackageLabels: true,
            priceOnSkuLabel: true,
            autoPrintWeightBasedInventory: true
          });
        }

        return labelPreference;
      });
    }
  });

  _exports.default = _default;
});