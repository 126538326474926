define("herer-web/routes/state-link/manual", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/mixins/pageable-route", "herer-web/utils/get-active-state-credential", "moment"], function (_exports, _authenticatedSideNav, _pageableRoute, _getActiveStateCredential, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var reportTypes = ['reportable_sale', 'reportable_adjustment'];

  var _default = _authenticatedSideNav.default.extend(_pageableRoute.default, {
    sideNavTemplateName: 'inventory-nav',
    modelName: 'reportable_sale',
    primaryKey: 'sales_date',
    launchDarkly: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this._super(transition).then(function () {
        if (!_this.get('authManager.currentLocation.user_managed_compliance')) {
          _this.replaceWith('state-link.auto');
        }

        if (_this.get('authManager.currentLocation.isWashington') && _this.get('launchDarkly').can('enable-ccrs')) {
          _this.replaceWith('state-link.manual-ccrs');
        }
      });
    },
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        reportables: this._super(),
        stateCredential: (0, _getActiveStateCredential.default)(store)
      });
    },
    setupController: function setupController(controller, _ref) {
      var reportables = _ref.reportables,
          stateCredential = _ref.stateCredential;
      var day = controller.day,
          report = controller.report;
      reportables.set('day', day);

      if (report != null && reportTypes.includes(report)) {
        reportables.set('model', report);
      }

      controller.set('salesDate', (0, _moment.default)(day).toDate());
      controller.set('stateCredential', stateCredential);
      return this._super(controller, reportables);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('day', undefined);
        controller.set('report', undefined);
      }
    }
  });

  _exports.default = _default;
});