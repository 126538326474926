define("herer-web/helpers/format-transaction-limit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var limits = params[0];
      var formattedLimits = [];

      if (limits.limit) {
        formattedLimits.push(limits.limit.formatted);
      }

      if (limits.potencyLimit) {
        formattedLimits.push(limits.potencyLimit.formatted + ' THC');
      }

      return formattedLimits.join(', ');
    }
  });

  _exports.default = _default;
});