define("herer-web/components/manifest/lot-row/lot-details", ["exports", "herer-web/constants", "herer-web/helpers/format-unit", "lodash/isNumber"], function (_exports, _constants, _formatUnit, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sampleTypes: _constants.default.sampleTypes,
    lot: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var lot = this.get('lot');

      if (Ember.isEmpty(lot) || !lot.get('hasMjProduct')) {
        return;
      }

      if (Ember.isEmpty(lot.get('sample_type')) && this.get('canEditSampleType')) {
        this.setSampleType && this.setSampleType(_constants.default.sampleType.none);
      }

      if (Ember.isEmpty(lot.get('inventory_type')) && this.get('canEditInventoryType')) {
        this.setInventoryType && this.setInventoryType(_constants.default.biotrackInventoryItemType.flower);
      }
    },
    canEditMedical: false,
    canEditWeight: false,
    canEditSampleType: false,
    setSampleType: null,
    canEditInventoryType: false,
    setInventoryType: null,
    canEditLotNumber: false,
    supportsCCRS: false,
    isWALocation: Ember.computed(function () {
      var location = this.get('authManager.currentLocation');
      return location.license_type === 0;
    }),
    inventoryItemTypes: Ember.computed('authManager.currentLocation.isWashington', function () {
      if (this.get('authManager.currentLocation.isWashington')) {
        return _constants.default.inventoryItemTypes;
      } else {
        return _constants.default.biotrackInventoryItemTypes;
      }
    }),
    formattedPurchasePrice: Ember.computed('lot.purchase_price', function () {
      return this._formatCurrency(this.get('lot.purchase_price'));
    }),
    formattedExciseTax: Ember.computed('lot.paid_excise_tax', function () {
      return this._formatCurrency(this.get('lot.paid_excise_tax'));
    }),
    _formatCurrency: function _formatCurrency(currency) {
      if ((0, _isNumber.default)(currency)) {
        return "$".concat((0, _formatUnit.formatCents)(currency));
      }
    },
    actions: {
      selectInventoryType: function selectInventoryType(value) {
        this.setInventoryType && this.setInventoryType(value);
      },
      selectSampleType: function selectSampleType(value) {
        this.setSampleType && this.setSampleType(value);
      }
    }
  });

  _exports.default = _default;
});