define("herer-web/components/order-row", ["exports", "herer-web/mixins/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, {
    classNames: ['row', 'table-row'],
    order: null,
    click: function click() {
      return this.onClick();
    }
  });

  _exports.default = _default;
});