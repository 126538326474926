define("herer-web/templates/components/menu-feeds/feed-preview-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HvNO7v4V",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[4,\"gb-modal\",null,[[\"data-test-modal\",\"open\",\"onHide\"],[\"menu_feed_preview\",[24,[\"showModal\"]],[24,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"header\"]],\"expected `m.header` to be a contextual component but found a string. Did you mean `(component m.header)`? ('herer-web/templates/components/menu-feeds/feed-preview-modal.hbs' @ L6:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"data-test-text\",\"modal_title\"],[8],[0,\"\\n      Menu Feed Preview\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `m.body` to be a contextual component but found a string. Did you mean `(component m.body)`? ('herer-web/templates/components/menu-feeds/feed-preview-modal.hbs' @ L12:C5) \"],null]],[[\"data-test-group\"],[\"modal_body\"]],{\"statements\":[[0,\"    \"],[1,[28,\"menu-feeds/feed-preview\",null,[[\"menuFeed\",\"loading\"],[[24,[\"menuFeed\"]],[24,[\"isLoadingPreview\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"footer\"]],\"expected `m.footer` to be a contextual component but found a string. Did you mean `(component m.footer)`? ('herer-web/templates/components/menu-feeds/feed-preview-modal.hbs' @ L19:C5) \"],null]],[[\"data-test-group\"],[\"modal_footer\"]],{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"data-test-action\",\"ok\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n      OK\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/menu-feeds/feed-preview-modal.hbs"
    }
  });

  _exports.default = _default;
});