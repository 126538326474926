define("herer-web/controllers/user", ["exports", "herer-web/mixins/context", "herer-web/mixins/accessible-locations", "bootbox"], function (_exports, _context, _accessibleLocations, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_context.default, _accessibleLocations.default, {
    featureManager: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    usersController: Ember.inject.controller('users'),
    showEmployeeAPIKey: Ember.computed('launchDarkly.isReady', function () {
      return this.get('featureManager').can('metrc') && this.get('launchDarkly').can('compliance-metrc-employee-api-key');
    }),
    proxiedLocations: Ember.computed.map('usersController.locations', function (location) {
      var id = location.id;
      var isNew = this.get('model.isNew');
      var isDeleted = this.get('model.is_deleted');
      var current = this.get('currentLocation.id');
      var accessible = this.get('model.accessible_location_ids');
      var checked;

      if (isDeleted) {
        checked = false;
      } else if (isNew) {
        checked = id === current;
      } else {
        checked = accessible.includes(id);
      }

      return Ember.ObjectProxy.create({
        content: location,
        checked: checked
      });
    }),
    proxiedRoles: Ember.computed.map('usersController.roles', function (role) {
      return Ember.ObjectProxy.create({
        content: role
      });
    }),
    checkAllLocations: Ember.computed('proxiedLocations.@each.checked', {
      get: function get() {
        return this.get('proxiedLocations').isEvery('checked');
      },
      set: function set(_key, value) {
        this.get('proxiedLocations').setEach('checked', value);
        return value;
      }
    }),
    isSaving: Ember.computed('model', function () {
      return this.get('model.isSaving');
    }),
    checkedLocations: function checkedLocations() {
      return this.get('proxiedLocations').filterBy('checked', true).mapBy('content.id');
    },
    modelDidChange: function modelDidChange() {
      if (this.get('model.is_deleted') || !this.get('model.isNew')) {
        this.checkAccessibleLocations();
      } else {
        this.checkCurrentLocation();
      }
    },
    checkCurrentLocation: function checkCurrentLocation() {
      var id = this.get('currentLocation.id');
      this.get('proxiedLocations').forEach(function (location) {
        location.set('checked', location.get('id') === id);
      });
    },
    checkAccessibleLocations: function checkAccessibleLocations() {
      var ids = this.get('model.accessible_location_ids');
      this.get('proxiedLocations').forEach(function (location) {
        location.set('checked', ids.includes(location.get('id')));
      });
    },
    actions: {
      save: function save(resolve, reject) {
        var _this = this;

        var model = this.get('model');
        model.get('validations').resetErrors();
        model.get('errors').clear();
        this.updateStateCredentials();

        if (!this.checkedLocations().length) {
          _bootbox.default.alert('User must have access to at least one location.');

          return reject;
        }

        return model.validations.validate().then(function () {
          return model.save().then(function (saved) {
            _this.get('store').findAll('user', {
              reload: true
            });

            _this.resetProxiedLocations();

            return resolve(saved);
          }).catch(reject);
        }).catch(reject);
      },
      cancel: function cancel() {
        var model = this.get('model');

        if (model.get('isNew')) {
          model.deleteRecord();
        } else {
          model.rollbackAttributes();
          this.resetProxiedLocations();
        }
      }
    }
  });

  _exports.default = _default;
});