define("herer-web/routes/report-full-page", ["exports", "herer-web/routes/authenticated-full-page", "herer-web/utils/dates-intervals"], function (_exports, _authenticatedFullPage, _datesIntervals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedFullPage.default.extend({
    sideNavTemplateName: 'reports-nav',
    reportStore: Ember.inject.service(),
    permissionKey: 'view_sales_by_reports',
    queryParams: {
      refresh: {
        refreshModel: true
      }
    },
    model: function model(routeParams) {
      if (Ember.isEmpty(routeParams.report)) {
        return;
      }

      if (Ember.isPresent(routeParams.interval)) {
        var dates = (0, _datesIntervals.datesFromInterval)(routeParams.interval);
        routeParams.startDate = dates.startDate.format('YYYY-MM-DD');
        routeParams.endDate = dates.endDate.format('YYYY-MM-DD');
      }

      routeParams.byAccount = this.isLocationReport(routeParams);
      return Ember.RSVP.hash({
        model: this.get('reportStore').fetchReport(routeParams),
        refresh: false,
        interval: routeParams.interval
      });
    },
    isLocationReport: function isLocationReport(routeParams) {
      return ['report', 'row', 'column', 'groupBy'].some(function (key) {
        return routeParams[key] === 'location';
      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.model);
      controller.set('refresh', hash.refresh);
      controller.set('interval', hash.interval);
    },
    resetController: function resetController(controller) {
      controller.resetQueryParams();
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});