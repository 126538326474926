define("herer-web/components/packages/package-reconciler", ["exports", "herer-web/components/base-modal", "herer-web/constants", "herer-web/mixins/taskable"], function (_exports, _baseModal, _constants, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend(_taskable.default, {
    http: Ember.inject.service(),
    modalClassName: '.package-reconcile-modal',
    package: null,
    traceabilityStatus: null,
    sourceOfTruth: null,
    adjustments: null,
    stepName: 'find-discrepancies',
    onClose: null,
    onDismissReconciliation: null,
    fetchTimeout: function fetchTimeout(request) {
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30000;
      var timeout_err = {
        ok: false,
        status: 408
      };
      return new Promise(function (resolve, reject) {
        request().then(resolve, reject);
        setTimeout(reject.bind(null, timeout_err), timeout);
      });
    },
    createReconciliationTask: function createReconciliationTask() {
      var _this = this;

      var uuid = this.get('package').get('id');
      var payload = this.get('traceabilityStatus');
      payload['source_of_truth'] = this.get('sourceOfTruth');
      var adjustments = this.get('adjustments');

      if (adjustments) {
        payload['adjustments'] = adjustments.map(function (adjustment) {
          return {
            inventory_item_id: adjustment.get('id'),
            quantity: {
              value: adjustment.get('adjustmentQuantity.value'),
              unit: adjustment.get('adjustmentQuantity.unit')
            }
          };
        });
      }

      var postRequest = function postRequest() {
        return _this.get('http').post("".concat(_constants.default.apiHost, "/api/v2/packages/").concat(uuid, "/reconcile"), payload);
      };

      this.fetchTimeout(postRequest, 30000).then(function (response) {
        _this.set('task', response.data.task);

        _this.pollTask();
      }).catch(function (error) {
        if (error.status === 408) {
          _this.set('stepName', 'timeout');
        } else {
          _this.set('stepName', 'reconciliation-error');
        }
      });
    },
    getTraceabilityStatus: function getTraceabilityStatus() {
      var _this2 = this;

      var uuid = this.get('package.id');

      var getRequest = function getRequest() {
        return _this2.get('http').get("".concat(_constants.default.apiHost, "/api/v2/packages/").concat(uuid, "/traceability_status"));
      };

      this.fetchTimeout(getRequest, 30000).then(function (response) {
        _this2.handleTraceabilityStatus(response.data);
      }).catch(function (error) {
        if (error.status === 408) {
          _this2.set('stepName', 'timeout');
        } else {
          _this2.set('stepName', 'reconciliation-error');
        }
      });
    },
    onTaskComplete: function onTaskComplete() {
      if (this.get('task').get('error')) {
        this.set('stepName', 'reconciliation-error');
      } else {
        this.get('package').reload();
        this.set('stepName', 'finish');
        this.hideModal();
      }
    },
    getStatusName: function getStatusName(status) {
      var statusData = _constants.default.packageStatuses.findBy('value', status);

      return statusData.name;
    },
    isFindDiscrepanciesStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'find-discrepancies';
    }),
    isReconcilingStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'reconciling';
    }),
    isReconcileStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'reconcile';
    }),
    isReconcileConfirmationStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'reconcile-confirmation';
    }),
    isReconcileDistributeQuantityStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'reconcile-distribute-quantity';
    }),
    isIrreconcilableStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'irreconcilable';
    }),
    isIrreconcilableConvertedStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'irreconcilable-converted';
    }),
    isTraceabilitySynchronizedStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'in-sync';
    }),
    isTimeoutStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'timeout';
    }),
    isReconciliationErrorStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'reconciliation-error';
    }),
    isFinishStep: Ember.computed('stepName', function () {
      return this.get('stepName') === 'finish';
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      this.get('modal').modal({
        backdrop: 'static'
      });
      this.startReconciliation();
    },
    // Override callback from BaseModal which is called on willDestroyElement()
    hideModalOnDestroy: function hideModalOnDestroy() {},
    // Callback is called from BaseModal on 'hide.bs.modal'
    onCancel: function onCancel() {
      if (this.get('isReconcilingStep')) {
        this.onDismissReconciliation();
      } else if (this.get('isFinishStep')) {
        this.onFinish();
      } else {
        this.onClose();
      }
    },
    actions: {
      cancel: function cancel() {
        this.hideModal();
      },
      retry: function retry() {
        this.startReconciliation();
      },
      useLocalAsSource: function useLocalAsSource() {
        this.set('sourceOfTruth', 'local');
        this.set('stepName', 'reconcile-confirmation');
      },
      useRemoteAsSource: function useRemoteAsSource() {
        this.set('sourceOfTruth', 'remote');
        var inventoryItemsCount = this.get('package.inventoryItems').length;

        if (inventoryItemsCount > 1) {
          var traceabilityStatus = this.get('traceabilityStatus');
          var remoteQuantity = traceabilityStatus['remote']['quantity']['value'];
          var localQuantity = traceabilityStatus['local']['quantity']['value'];

          if (remoteQuantity !== localQuantity) {
            this.set('stepName', 'reconcile-distribute-quantity');
            return;
          }
        }

        this.set('stepName', 'reconcile-confirmation');
      },
      confirmQuantityDistribution: function confirmQuantityDistribution(adjustments) {
        this.set('adjustments', adjustments);
        this.set('stepName', 'reconcile-confirmation');
      },
      confirmReconciliation: function confirmReconciliation() {
        this.set('stepName', 'reconciling');
        this.createReconciliationTask();
      }
    },
    handleTraceabilityStatus: function handleTraceabilityStatus(traceabilityStatus) {
      this.set('traceabilityStatus', traceabilityStatus);

      if (this.traceabilityStatus.synchronized) {
        this.set('stepName', 'in-sync');
      } else if (this.traceabilityStatus.problem === null) {
        this.set('stepName', 'reconcile');
      } else if (this.traceabilityStatus.problem === 'connection_to_traceability_system_failed') {
        this.set('stepName', 'timeout');
      } else if (this.traceabilityStatus.problem === 'converted_into_multiple_skus') {
        this.set('stepName', 'irreconcilable-converted');
      } else {
        this.set('stepName', 'irreconcilable');
      }
    },
    startReconciliation: function startReconciliation() {
      this.resetAttributes();
      this.set('stepName', 'find-discrepancies');
      this.getTraceabilityStatus();
    },
    resetAttributes: function resetAttributes() {
      this.set('sourceOfTruth', null);
      this.set('traceabilityStatus', null);
    }
  });

  _exports.default = _default;
});