define("herer-web/models/reportable-adjustment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Leaf
    adjustedAt: (0, _model.attr)('string'),
    externalId: (0, _model.attr)('string'),
    globalInventoryId: Ember.computed.alias('packageLabel'),
    // Metrc
    adjustmentDate: (0, _model.attr)('string'),
    employeeLicenseNumber: (0, _model.attr)('string'),
    // Shared
    adjustmentQuantity: (0, _model.attr)('number'),
    adjustmentUnitOfMeasure: (0, _model.attr)('string'),
    optionalNote: (0, _model.attr)('string'),
    packageLabel: (0, _model.attr)('string'),
    reason: (0, _model.attr)('string')
  });

  _exports.default = _default;
});