define("herer-web/components/packages/package-reopener", ["exports", "herer-web/components/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    modalClassName: '.package-reopen-modal',
    hasFailed: Ember.computed('errorMessage', function () {
      return this.get('errorMessage') != null;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var modal = this.get('modal');

      if (modal) {
        modal.modal({
          backdrop: 'static'
        });
      }
    },
    actions: {
      reopen: function reopen() {
        var aPackage = this.get('package');
        this.onConfirm(aPackage);
      },
      cancel: function cancel() {
        this.onCancel();
      }
    }
  });

  _exports.default = _default;
});