define("herer-web/helpers/packages/status-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      switch (params[0]) {
        case 'Destroyed':
          return 'red';

        case 'Closed':
          return 'orange';

        case 'On Hold':
          return 'gray';

        case 'Quarantined':
          return 'magenta';

        case 'Destruction Scheduled':
          return 'yellow';

        default:
          return 'green';
      }
    }
  });

  _exports.default = _default;
});