define("herer-web/templates/qs-reports/employee-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tn0uZ4xL",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"header\",\"subheader\",\"width\",\"data-test-ux\"],[\"Employee Performance\",\"Shows you more insights on your budtender’s performance.<br/><a href=\\\"https://support.dutchie.com/hc/en-us/articles/12887054606867-Employee-Performance-Track-Budtender-Sales-with-Actionable-Insights\\\" target=\\\"_blank\\\">Learn more</a>\",\"12\",\"employee_performance_header\"]]],false],[0,\"\\n\"],[1,[28,\"qs-report\",null,[[\"reportName\",\"data-test-group\"],[\"EmployeePerformanceReport\",\"employee_performance_report\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/qs-reports/employee-performance.hbs"
    }
  });

  _exports.default = _default;
});