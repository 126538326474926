define("herer-web/initializers/ds-model", ["exports", "@ember-data/model", "lodash/snakeCase"], function (_exports, _model, _snakeCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  var initialize = function initialize() {
    return _model.default.reopen({
      adapterDidInvalidate: function adapterDidInvalidate(errors) {
        var recordErrors = this.get('errors');
        Object.keys(errors).forEach(function (key) {
          var messages = errors[key];
          key = (0, _snakeCase.default)(key);
          recordErrors.add(key, messages);
        });
      },
      addError: function addError(property, message) {
        this.send('becomeDirty');
        this.get('errors').add(property, message);
      }
    });
  };

  _exports.initialize = initialize;
  var DsModelInitializer = {
    name: 'ds-model',
    initialize: initialize
  };
  var _default = DsModelInitializer;
  _exports.default = _default;
});