define("herer-web/templates/inventory-value-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xGuOJ0mZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-header\",null,[[\"header\",\"class\"],[\"Inventory Value Report\",\"hidden-print\"]],{\"statements\":[[4,\"page-header-actions\",null,null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"data-test-action\",\"print\"],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"print\"],null]]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-print\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"btn-text\"],[8],[0,\"\\n        Print\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"inventory-value/table\",null,[[\"productTypes\",\"allTags\",\"data-test-container\"],[[24,[\"productTypes\"]],[24,[\"allTags\"]],\"inventory_table\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/inventory-value-report.hbs"
    }
  });

  _exports.default = _default;
});