define("herer-web/routes/authenticated", ["exports", "herer-web/mixins/reset-scroll", "herer-web/mixins/clean-body-classes", "herer-web/utils/measure-time"], function (_exports, _resetScroll, _cleanBodyClasses, _measureTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, _cleanBodyClasses.default, {
    launchDarkly: Ember.inject.service(),
    canSkipAuthCheck: Ember.computed('authManager.currentUser', 'launchDarkly.isReady', function () {
      if (Ember.isEmpty(this.get('authManager.currentUser'))) {
        return false;
      }

      return this.get('launchDarkly').can('bw-cache-auth');
    }),
    permissionCheck: function permissionCheck() {
      if (this.get('authManager.currentLocation.isSuspended')) {
        this.transitionTo('account-suspended');
        return;
      }

      var permissionManager = this.get('permissionsManager');
      var permissionKey = this.get('permissionKey');
      var allPermissions = ['access_backoffice'];

      if (Ember.isPresent(permissionKey)) {
        allPermissions.push(permissionKey);
      }

      return permissionManager.verify(allPermissions);
    },
    beforeModel: function beforeModel() {
      (0, _measureTime.startTime)('Authentication');
      return this.get('authManager').checkAuthentication(this.get('canSkipAuthCheck')).then(this.permissionCheck.bind(this)).catch(this.resetAuth.bind(this)).finally(function () {
        return (0, _measureTime.endTime)('Authentication');
      });
    },
    resetAuth: function resetAuth() {
      var routeName = this.get('routeName');
      this.controllerFor('application').set('redirectAfterLogin', {
        routeName: routeName,
        params: this.paramsFor(routeName)
      });
      this.get('authManager').reset();
      this.transitionTo('sessions.new');
    }
  });

  _exports.default = _default;
});