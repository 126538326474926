define("herer-web/routes/sales-summary-report", ["exports", "herer-web/routes/authenticated-side-nav", "herer-web/models/sales-report", "herer-web/constants", "moment"], function (_exports, _authenticatedSideNav, _salesReport, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSideNav.default.extend({
    http: Ember.inject.service(),
    sideNavTemplateName: 'reports-nav',
    permissionKey: 'view_sales_summary_report',
    model: function model(params) {
      var _this = this;

      if (params.startDate === 'now') {
        params.startDate = null;
      }

      if (params.interval) {
        params.interval = null;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = Ember.get(_this, 'http');

        var timezone = _this.get('authManager.currentLocation.timezone');

        var report_params = {
          start_date: (0, _moment.default)(params.startDate).startOf('day').tz(timezone, true).toISOString(),
          end_date: (0, _moment.default)(params.endDate).endOf('day').tz(timezone, true).toISOString()
        };
        var url = http.createUrl("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/reports/sales_summary"), report_params);
        http.get(url).then(function (response) {
          resolve(_salesReport.default.create(response.data.report));
        }).catch(function (response) {
          reject(new Error("".concat(response.status, ": ").concat(response.data.error)));
        });
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});