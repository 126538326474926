define("herer-web/templates/components/account-reports/daily-sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x0SR71oF",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"data-test-group\",\"account_reports_daily_sales\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[10,\"data-test-text\",\"header\"],[8],[0,\"\\n        Daily Sales\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"row locations\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"account-reports/daily-sales/row\",null,[[\"row\",\"canShowChart\"],[[23,1,[]],[24,[\"canShowChart\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/account-reports/daily-sales.hbs"
    }
  });

  _exports.default = _default;
});