define("herer-web/transforms/day", ["exports", "@ember-data/serializer/transform", "lodash/isString", "moment"], function (_exports, _transform, _isString, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Force moment/JS Date to not consider timezones when we serialize/deserialize the date string
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!Ember.isPresent(serialized)) {
        return null;
      }

      var date = (0, _moment.default)(serialized);
      return new Date(date.year(), date.month(), date.date());
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isPresent(deserialized)) {
        return null;
      }

      if ((0, _isString.default)(deserialized)) {
        deserialized = new Date(deserialized);
      }

      var date = (0, _moment.default)({
        y: deserialized.getFullYear(),
        M: deserialized.getMonth(),
        d: deserialized.getDate()
      });
      return date.format('YYYY-MM-DD');
    }
  });

  _exports.default = _default;
});