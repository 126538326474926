define("herer-web/templates/components/inventory-value/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cGnlxvpp",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-xs-2\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"label label-ellipsis \",[24,[\"product\",\"product_type\",\"colorClass\"]]]]],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"product_type\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ellipsis col-xs-4 \",[28,\"if\",[[24,[\"product\",\"tags\"]],\"double-line\"],null]]]],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"name\"]],false],[4,\"if\",[[24,[\"product\",\"tags\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"br\",true],[8],[9],[4,\"each\",[[24,[\"product\",\"tags\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"tag\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"onSelectTag\"]]],null],[23,1,[]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-2\"],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"quantity\",\"formatted\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-2\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"price_at_register\"]]],null,{\"statements\":[[0,\"    Priced at Register\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"format-unit\",[[24,[\"product\",\"retailValue\"]]],[[\"unit\"],[\"dollar\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-2\"],[8],[0,\"\\n  \"],[1,[28,\"format-unit\",[[24,[\"product\",\"wholesale_value\"]]],[[\"unit\"],[\"dollar\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/inventory-value/row.hbs"
    }
  });

  _exports.default = _default;
});