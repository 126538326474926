define("herer-web/models/check-in", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    birthDate: (0, _model.attr)('day'),
    queuedAt: (0, _model.attr)('date'),
    dequeuedAt: (0, _model.attr)('date'),
    person: (0, _model.belongsTo)('person', {
      async: false
    })
  });

  _exports.default = _default;
});