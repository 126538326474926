define("herer-web/templates/components/packages/package/history-events/package-history-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ce80TtK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"shouldShowNote\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"note-container\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"aria-label\",\"Close\"],[12,\"class\",\"close pull-left\"],[12,\"data-test-action\",\"close\"],[3,\"action\",[[23,0,[]],\"closeNote\"]],[8],[0,\"\\n\\n      \"],[7,\"i\",true],[10,\"class\",\"fas fa-times-square\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"note-text\"],[8],[0,\"\\n      \"],[1,[22,\"noteText\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"showNote\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"far fa-sticky-note\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/packages/package/history-events/package-history-note.hbs"
    }
  });

  _exports.default = _default;
});