define("herer-web/components/account-sales-summary-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    isLoading: false,
    table: null,
    sortBy: null,
    sortDirection: null,
    first: false,
    dateFormat: 'll'
  });

  _exports.default = _default;
});