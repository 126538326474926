define("herer-web/mixins/lot-number-formatter", ["exports", "lodash/startCase"], function (_exports, _startCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    usesLots: Ember.computed('authManager.currentLocation', function () {
      return this.get('featureManager').can('lots');
    }),
    lotOrPackage: Ember.computed('usesLots', function () {
      return this.get('usesLots') ? 'lot' : 'package';
    }),
    lotOrPackageTitle: Ember.computed('lotOrPackage', function () {
      return (0, _startCase.default)(this.get('lotOrPackage'));
    }),
    lotNumberLabel: Ember.computed('usesLots', function () {
      return this.get('usesLots') ? 'Lot Number' : 'Package ID';
    }),
    formatLotNumber: function formatLotNumber(lotNumber) {
      var chunkSize = 4;
      var formattedString = '';

      for (var i = 0; i < lotNumber.length; i++) {
        if (i % chunkSize !== 0) {
          continue;
        }

        formattedString += lotNumber.substr(i, chunkSize);
        formattedString += ' ';
      }

      return formattedString.trim();
    }
  });

  _exports.default = _default;
});