define("herer-web/mixins/inventory-weights", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    featureManager: Ember.inject.service(),
    unitOfMeasureOptions: Ember.computed(function () {
      var featureManager = this.get('featureManager');
      var weightBasedInventory = featureManager.can('weight-based-inventory');
      var volumeBasedInventory = featureManager.can('volume-based-inventory');

      if (weightBasedInventory) {
        if (volumeBasedInventory) {
          return _constants.default.weightUnits;
        }

        return _constants.default.nonVolumeUnits;
      } else if (volumeBasedInventory) {
        // Just in case
        return _constants.default.onlyVolumeUnits;
      }

      return _constants.default.onlyUnit;
    }),
    onlyWeightUnits: _constants.default.onlyWeightUnits,
    itemUOM: _constants.default.weightUnit.unit,
    weightUnits: Ember.computed('itemUOM', function () {
      var featureManager = this.get('featureManager');
      var volumeBasedInventory = featureManager.can('volume-based-inventory');
      var unit = _constants.default.onlyWeightUnits;

      if (this.get('itemUOM') === _constants.default.weightUnit.unit) {
        unit = _constants.default.onlyUnit;
      } else if (volumeBasedInventory) {
        unit = _constants.default.nonUnit;
      }

      return unit;
    })
  });

  _exports.default = _default;
});