define("herer-web/templates/components/table-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bcUqFsdK",
    "block": "{\"symbols\":[\"filterValue\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"filterValues\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"table-filter\",null,[[\"filterValue\",\"availableFilters\",\"disabled\",\"filterSelectedAction\",\"valueChangedAction\",\"removeFilterAction\",\"defaultLabel\",\"removeLabel\",\"data-test-item\",\"data-test-index\"],[[23,1,[]],[24,[\"unusedFilters\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"addFilterValue\"],null],[28,\"action\",[[23,0,[]],\"valueChanged\"],null],[28,\"action\",[[23,0,[]],\"removeFilterValue\"],null],[24,[\"defaultFilterLabel\"]],[24,[\"removeFilterLabel\"]],\"table_filter\",[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/table-filters.hbs"
    }
  });

  _exports.default = _default;
});