define("herer-web/components/date-picker", ["exports", "herer-web/utils/date-parsing"], function (_exports, _dateParsing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    startDateTitle: 'Start Date',
    endDateTitle: 'End Date',
    usePlaceholders: false,
    startDatePlaceholder: Ember.computed('startDateTitle', 'usePlaceholders', function () {
      return this.get('usePlaceholders') ? this.get('startDateTitle') : '';
    }),
    endDatePlaceholder: Ember.computed('endDateTitle', 'usePlaceholders', function () {
      return this.get('usePlaceholders') ? this.get('endDateTitle') : '';
    }),
    actions: {
      changeDate: function changeDate(onChange, date) {
        var stringDate = date;

        if (date) {
          stringDate = (0, _dateParsing.dateToString)(date, 'YYYY-MM-DD', {
            timezone: (0, _dateParsing.browserTimezone)()
          });
        } else {
          stringDate = null;
        }

        onChange(stringDate);
      }
    }
  });

  _exports.default = _default;
});