define("herer-web/models/reportable-inventory-transfer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Leaf Data
    fromLicenseNumber: (0, _model.attr)('string'),
    toLicenseNumber: (0, _model.attr)('string'),
    fromInventoryExternalIdentifier: (0, _model.attr)('string'),
    toInventoryExternalIdentifier: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    transferDate: (0, _model.attr)('string'),
    adjustmentDate: (0, _model.attr)('string'),
    externalIdentifier: (0, _model.attr)('string'),
    createdBy: (0, _model.attr)('string'),
    createdDate: (0, _model.attr)('string'),
    updatedBy: (0, _model.attr)('string'),
    updatedDate: (0, _model.attr)('string'),
    operation: (0, _model.attr)('string')
  });

  _exports.default = _default;
});