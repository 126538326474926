define("herer-web/controllers/order", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context", "herer-web/constants", "bootbox"], function (_exports, _alertUnsavedController, _context, _constants, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    parentController: null,
    isEditing: false,
    isPanel: false,
    launchDarkly: Ember.inject.service(),
    paymentMethods: [],
    activePaymentMethods: Ember.computed('paymentMethods', 'model.payments', function () {
      var payments = this.get('model.payments');
      return this.get('paymentMethods').filter(function (paymentMethod) {
        var hasPaymentMethod = payments.any(function (payment) {
          return payment.get('paymentMethod.id') === paymentMethod.get('id');
        });
        return paymentMethod.get('active') || hasPaymentMethod;
      });
    }),
    paymentMethodsIndexed: Ember.computed('paymentMethods', function () {
      return this.get('paymentMethods').reduce(function (indexed, pm) {
        indexed[pm.get('paymentType')] = pm.get('name');
        return indexed;
      }, {});
    }),
    paymentType: Ember.computed('model.payment_type', function () {
      var paymentType = this.get('model.payment_type');

      if (!Ember.isPresent(paymentType)) {
        return;
      }

      return this.get('paymentTypes')[paymentType].name;
    }),
    paymentTypes: _constants.default.orderPaymentTypes,
    chargeAction: Ember.computed('model.order_type', function () {
      switch (this.get('model.order_type')) {
        case _constants.default.orderType.sale:
          return 'Charged At';

        case _constants.default.orderType.refund:
          return 'Refunded At';

        case _constants.default.orderType.return:
          return 'Returned At';

        case _constants.default.orderType.void:
          return 'Voided At';
      }
    }),
    fromSameLocation: Ember.computed('model.location_name', 'authManager.currentLocation.name', function () {
      return this.get('model.location_name') === this.get('authManager.currentLocation.name');
    }),
    actions: {
      edit: function edit() {
        this.set('isEditing', true);
        return false;
      },
      cancelEdit: function cancelEdit() {
        this.get('model').rollbackAttributes();
        this.get('model.payments').forEach(function (payment) {
          return payment.rollbackAttributes();
        });
        this.set('isEditing', false);
      },
      save: function save() {
        var _this = this;

        var tenderedAmount = this.get('model.tenderedAmount');
        var total = this.get('model.total');

        if (tenderedAmount < total) {
          _bootbox.default.alert('The tendered amount cannot be less than the total.');

          return;
        }

        var hasChange = this.get('model.change') > 0;
        var paymentMethods = this.get('model.payments').mapBy('paymentMethod');
        var changePaymentMethod = paymentMethods.findBy('allowsChange', true);

        if (hasChange && Ember.isEmpty(changePaymentMethod)) {
          _bootbox.default.alert('The selected payment method does not allow change.');

          return;
        }

        this.get('model').save().then(function () {
          _this.set('isEditing', false);
        }).catch(function () {
          _bootbox.default.alert("Sorry, something went wrong when trying to update this order.\n          If you still have this issue, chat Support.");

          _this.set('isEditing', false);
        });
      },
      setTenderedAmount: function setTenderedAmount(value) {
        this.set('model.tendered_amount', value);
      },
      setPaymentMethod: function setPaymentMethod(payment, paymentMethodId) {
        var paymentMethod = this.get('paymentMethods').findBy('id', paymentMethodId);
        payment.set('paymentMethod', paymentMethod);
      },
      setPaymentTotal: function setPaymentTotal(payment, total) {
        payment.set('total', total);
      },
      hideDrawer: function hideDrawer() {
        var parentController = this.get('parentController');

        if (parentController) {
          parentController.hideDrawer();
        }
      }
    }
  });

  _exports.default = _default;
});