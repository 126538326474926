define("herer-web/components/additional-materials", ["exports", "ember-uuid/utils/uuid-generator", "herer-web/models/weight", "big.js"], function (_exports, _uuidGenerator, _weight, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    materialsProxy: null,
    onUpdate: null,
    newWeight: 0,
    precision: 4,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('materialsProxy', this.get('materials'));
    },
    invokeUpdateCallback: function invokeUpdateCallback() {
      var additionalMaterials = this.get('materialsProxy').map(function (material) {
        var id = material.id,
            name = material.name,
            weight = material.weight;
        return {
          id: id,
          name: name,
          weight: {
            value: parseFloat(weight._value) || weight.value,
            unit: weight.unit
          }
        };
      });
      this.onUpdate(additionalMaterials);
    },
    actions: {
      deleteMaterial: function deleteMaterial(id) {
        var proxy = this.get('materialsProxy');
        var toDelete = proxy.findBy('id', id);
        proxy.removeObject(toDelete);
        this.invokeUpdateCallback();
      },
      addMaterial: function addMaterial() {
        var proxy = this.get('materialsProxy');
        proxy.addObject({
          id: _uuidGenerator.default.v4(),
          name: this.get('name'),
          weight: (0, _weight.parseWeight)("".concat(this.newWeight, " g"))
        });
        this.invokeUpdateCallback();
      },
      setNewWeight: function setNewWeight(quantityValue) {
        var roundedValue = new _big.default(quantityValue || 0).round(this.get('precision'));
        this.set('newWeight', roundedValue);
      }
    }
  });

  _exports.default = _default;
});