define("herer-web/helpers/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    permissionsManager: Ember.inject.service('permissions-manager'),
    compute: function compute(params) {
      var permission = params[0];
      var permissionsManager = this.get('permissionsManager');
      return permissionsManager.verifyForced(permission);
    }
  });

  _exports.default = _default;
});