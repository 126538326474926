define("herer-web/components/image-cropper-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    launchDarkly: Ember.inject.service(),
    image: null,
    cropper: null,
    imageOptions: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var modalElement = this.$('.modal.fade');
      modalElement.on('hidden.bs.modal', function () {
        _this.onClose();
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var modalElement = this.$('.modal.fade');
      var modalBackdrop = document.querySelector('.modal-backdrop.fade.in');

      if (Ember.isPresent(this.get('image'))) {
        modalElement.modal();

        if (modalBackdrop) {
          modalBackdrop.style['z-index'] = 3;
        }
      } else {
        modalElement.modal('hide');

        if (modalBackdrop) {
          modalBackdrop.style['z-index'] = 1040;
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var modalElement = this.$('.modal.fade');
      modalElement.off('hidden.bs.modal');
      modalElement.modal('hide');
    },
    setCroppedImage: function setCroppedImage() {
      var _this2 = this;

      var cropper = this.get('cropper');
      var max = this.get('imageOptions.max');
      var imageTypeMatch = this.get('image').match(/^data:(\w*\/\w*);base/);
      var imageType = imageTypeMatch && imageTypeMatch[1] || 'image/jpeg'; // CroppedImage is a canvas

      var croppedImage = cropper.cropper('getCroppedCanvas');

      if (croppedImage.width > max.width || croppedImage.height > max.height) {
        croppedImage = cropper.cropper('getCroppedCanvas', {
          maxWidth: max.width,
          maxHeight: max.height
        });
      }

      croppedImage = croppedImage.toDataURL(imageType);
      this.getImageDimensions(croppedImage).then(function (dimensions) {
        _this2.validateImageSize(dimensions);

        _this2.setCroppedPhoto(croppedImage);
      });
    },
    getImageDimensions: function getImageDimensions(imageData) {
      return new Ember.RSVP.Promise(function (resolved) {
        var image = new Image();

        image.onload = function () {
          // For base64 encoding, each character takes up 6 bits instead of 4
          // This accounts for the extra buffer
          var imageSizeBytes = 4 * (imageData.length / 3);
          resolved({
            width: image.width,
            height: image.height,
            size: imageSizeBytes
          });
        };

        image.src = imageData;
      });
    },
    validateImageSize: function validateImageSize(_ref) {
      var width = _ref.width,
          height = _ref.height,
          size = _ref.size;

      if (!width || !height || !size) {
        return;
      }

      var _this$get = this.get('imageOptions'),
          min = _this$get.min,
          max = _this$get.max;

      var errorMessage = null;

      if (width < min.width || height < min.height) {
        errorMessage = "Cropped image must be at least ".concat(min.width, " x ").concat(min.height, " px");
      } else if (width > max.width || height > max.height) {
        errorMessage = "Cropped image must be at most ".concat(max.width, " x ").concat(max.height, " px");
      } else if (size > max.size) {
        errorMessage = "Cropped image must be at most ".concat(max.sizeDisplay, " MB");
      }

      this.handleImageUploadError(errorMessage);
    },
    actions: {
      save: function save() {
        this.setCroppedImage();
      },
      cancel: function cancel() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});