define("herer-web/components/lot-converter", ["exports", "herer-web/models/weight", "herer-web/mixins/product-selector", "herer-web/mixins/context", "herer-web/constants", "herer-web/mixins/inventory-printing", "herer-web/mixins/inventory-weights", "bootbox", "herer-web/components/products/utils/sku"], function (_exports, _weight, _productSelector, _context, _constants, _inventoryPrinting, _inventoryWeights, _bootbox, _sku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_context.default, _productSelector.default, _inventoryPrinting.default, _inventoryWeights.default, {
    placesEnabled: Ember.computed.alias('currentLocation.places_enabled'),
    inventoryItem: null,
    taxItems: [],
    productTypes: [],
    tieredPricingModels: [],
    tags: [],
    labelPreference: null,
    receiptPreference: null,
    onFinish: null,
    unitBasedFilter: null,
    weightBasedFilter: null,
    toast: Ember.inject.service(),
    featureManager: Ember.inject.service(),
    labelPrinter: Ember.inject.service(),
    http: Ember.inject.service(),
    store: Ember.inject.service(),
    fromUnits: null,
    toQuantity: null,
    toWeight: null,
    sku: null,
    product: null,
    isSaving: false,
    skuTaken: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('skuProvider', new _sku.default(this.store, this.featureManager));

      if (Ember.isEmpty(this.get('product'))) {
        var weight = _weight.default.create({
          value: null,
          unit: _constants.default.weightUnit.unit
        });

        this.set('toQuantity', weight);
      }
    },
    itemUOM: Ember.computed.alias('inventoryItem.product.unit_of_measure'),
    conversionIsUnitToUnit: Ember.computed('availableQuantity.unit', 'toQuantity.unit', function () {
      return this.get('availableQuantity.isUnit') && this.get('toQuantity.isUnit');
    }),
    conversionIsWeightToUnits: Ember.computed('inventoryItem', 'toQuantity.unit', function () {
      return this.get('featureManager').can('weight-based-inventory') && !this.get('inventoryItem.quantity.isUnit') && this.get('toQuantity.isUnit');
    }),
    conversionIsWeightToWeight: Ember.computed('conversionIsUnitToUnit', 'conversionIsWeightToUnits', function () {
      return !this.get('conversionIsUnitToUnit') && !this.get('conversionIsWeightToUnits');
    }),
    showPrintLabels: Ember.computed('conversionIsWeightToUnits', 'conversionIsWeightToWeight', 'fromProductType', 'toProductType', function () {
      return this.get('conversionIsWeightToUnits') || this.get('conversionIsWeightToWeight') || this.get('conversionIsUnitToUnit') && this.get('fromProductType.traceability_type') === _constants.default.traceabilityType.usableMarijuana && this.get('toProductType.traceability_type') === _constants.default.traceabilityType.usableMarijuana;
    }),
    fromProduct: Ember.computed.alias('inventoryItem.product'),
    fromProductUnit: Ember.computed.alias('inventoryItem.product.unit_of_measure'),
    fromProductType: Ember.computed.alias('inventoryItem.product.productType'),
    toProductUnit: Ember.computed.alias('product.unit_of_measure'),
    toProductType: Ember.computed.alias('product.productType'),
    toQuantityValue: Ember.computed('toQuantity.value', function () {
      return parseInt(this.get('toQuantity.value'));
    }),
    purchasePrice: Ember.computed('inventoryItem', 'toWeight.unit', 'toQuantity.unit', function () {
      var purchase_price = this.get('inventoryItem.purchase_price');
      var quantity = this.get('inventoryItem.quantity'); // The conversionRatio is the ratio from the inventory UOM to the converted UOM.
      // IE: From grams to grams it's 1, from lbs -> grams (453.x)

      var conversionRatio = quantity;

      if (this.get('conversionIsWeightToWeight')) {
        conversionRatio = conversionRatio.to(this.get('toQuantity.unit'));
      } else if (this.get('conversionIsWeightToUnits')) {
        conversionRatio = conversionRatio.to(this.get('toWeight.unit'));
      }

      var individualPrice;

      if (Ember.isEmpty(purchase_price) || purchase_price === 0) {
        individualPrice = 0;
      } else if (this.get('conversionIsUnitToUnit')) {
        individualPrice = purchase_price;
      } else {
        individualPrice = purchase_price * quantity.get('value') / conversionRatio.get('value');
      }

      return individualPrice;
    }),
    costPerFromLabel: Ember.computed('conversionIsUnitToUnit', 'fromUnits', 'toQuantity', function () {
      if (this.get('conversionIsUnitToUnit')) {
        return 'Unit';
      } else if (this.get('conversionIsWeightToWeight')) {
        return this.get('toQuantity.unitLabelShort');
      } else {
        return this.get('toWeight.unitLabelShort');
      }
    }),
    costPerFromLabelShort: Ember.computed('costPerUnitFromLabel', 'fromUnits', 'toWeight.unit', 'toQuantity.unit', function () {
      if (this.get('conversionIsUnitToUnit')) {
        return 'unit';
      } else if (this.get('conversionIsWeightToWeight')) {
        return this.get('toQuantity.unitLabelShort');
      } else {
        return this.get('fromQuantity.unitLabelShort');
      }
    }),
    costPerUnit: Ember.computed('purchasePrice', 'toWeight.{unit,value}', 'fromQuantity.value', 'toQuantity.{value,unit}', function () {
      var purchasePrice = this.get('purchasePrice');

      if (Ember.isEmpty(purchasePrice)) {
        return '0.00';
      }

      var unit;

      if (this.get('conversionIsUnitToUnit')) {
        unit = this.get('fromQuantity.value');
        var toUnitValue = this.get('toQuantity.value');

        if (!toUnitValue.eq(0)) {
          unit = unit.div(toUnitValue);
        }
      } else if (this.get('conversionIsWeightToWeight')) {
        unit = this.get('toQuantity.value');
      } else {
        unit = this.get('toWeight.value');
      }

      return unit.times(purchasePrice).div(100).toFixed(2);
    }),
    manifestUrl: Ember.computed('inventoryItem.sku', function () {
      var sku = this.get('inventoryItem.sku');
      return "/incoming_inventory/?sku=".concat(sku);
    }),
    fromQuantity: Ember.computed('inventoryItem', 'toQuantity.{unit,value}', 'toWeight.{unit,value}', 'fromUnits.{unit,value}', function () {
      if (this.get('conversionIsUnitToUnit')) {
        return this.get('fromUnits');
      }

      if (this.get('availableQuantity.isUnit') || !this.get('toQuantity.isUnit')) {
        return this.get('toQuantity');
      }

      var toUnits = this.get('toQuantity.value');
      var toWeight = this.get('toWeight');
      return toWeight.times(toUnits);
    }),
    availableQuantity: Ember.computed('convertibleQuantity', function () {
      var availableQuantity = this.get('convertibleQuantity');

      if (Ember.isEmpty(availableQuantity)) {
        return;
      }

      if (availableQuantity.get('isUnit')) {
        return availableQuantity;
      }

      var weight = this.get('toQuantity.isUnit') ? this.get('toWeight') : this.get('toQuantity');
      var unit = weight.get('unit');
      return availableQuantity.to(unit);
    }),
    convertibleQuantity: Ember.computed('inventoryItem', 'toWeight.unit', 'toQuantity.unit', function () {
      var inventoryItemQuantity = this.get('inventoryItem.quantity');

      if (!this.get('placesEnabled')) {
        return inventoryItemQuantity;
      }

      var allowedPlaces = ['Back Stock', 'Sales Floor'];
      return this.get('inventoryItem.inventoryItemPlacesWithNonZeroQuantity').filter(function (iip) {
        return allowedPlaces.includes(iip.get('name'));
      }).reduce(function (sum, iip) {
        return sum.plus(iip.get('unlocked_quantity'));
      }, inventoryItemQuantity.zero());
    }),
    notEnoughAvailable: Ember.computed('availableQuantity', 'fromQuantity.{unit,value}', function () {
      if (Ember.isEmpty(this.get('availableQuantity'))) {
        return false;
      }

      return this.get('fromQuantity.value') < 0 || this.get('fromQuantity').gt(this.get('availableQuantity'));
    }),
    isPrinting: false,
    printDisabled: Ember.computed.or('isPrinting', 'saveDisabled'),
    saveDisabled: Ember.computed('product', 'sku', 'toQuantity.isZero', 'fromQuantity.isZero', 'productMismatch', function () {
      return Ember.isEmpty(this.get('product')) || Ember.isEmpty(this.get('sku')) || this.get('fromQuantity.isZero') || this.get('toQuantity.isZero') || this.get('productMismatch') || this.get('notEnoughAvailable');
    }),
    disableMultipleOfUnitToQuantitySelect: Ember.computed.empty('fromUnits.textInputValue'),
    // Valid selections for Unit to Unit conversion.
    // Prevents passing fractional numbers to the API.
    validToQuantityMultiples: Ember.computed('fromUnits.value', function () {
      var traceableAmount = this.get('inventoryItem.traceable_amount');
      var fromValue = this.get('fromUnits.value');

      if (traceableAmount) {
        if (this.get('fromUnits.isUnit') && traceableAmount.get('isUnit')) {
          traceableAmount = traceableAmount.floor();
          fromValue = fromValue.times(traceableAmount.get('value'));
        }
      }

      var multiples = [];

      for (var i = 1; i <= fromValue; i++) {
        if (fromValue % i === 0) {
          var qValue = fromValue / i;
          multiples.push({
            name: "".concat(i, " unit").concat(i > 1 ? 's' : '', " of ").concat(qValue, " each"),
            value: i
          });
        }
      }

      return multiples;
    }),
    productMismatch: Ember.computed('product', 'toQuantity.unit', function () {
      if (Ember.isEmpty(this.get('product'))) {
        return false;
      }

      var unitBasedProduct = this.get('product.unitBased');
      var quantityIsUnit = this.get('toQuantity.isUnit');
      return unitBasedProduct !== quantityIsUnit;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      // Null items are so weight-input box is initially empty
      var empty_weight_unit = _weight.default.create({
        value: null,
        unit: _constants.default.weightUnit.unit
      });

      this.set('fromUnits', empty_weight_unit);
      this.set('toQuantity', empty_weight_unit); // Makes sense to set this for weight > unit conversions so user
      // only has 1 text input box to initially fill out.

      this.set('toWeight', _weight.default.create({
        value: 1,
        unit: _constants.default.weightUnit.gram
      }));
    },
    confirmConvert: function confirmConvert() {
      var message = this.buildConfirmText();
      return new Ember.RSVP.Promise(function (resolve) {
        _bootbox.default.confirm(message, function (result) {
          return result ? resolve() : null;
        });
      });
    },
    buildConfirmText: function buildConfirmText() {
      var fromName = this.get('fromProduct.name');
      var toQty = this.get('toQuantity.value');
      var toUnitText = this.pluralizeUnitText(toQty);
      var fromQty = this.get('fromQuantity');
      var convertText;

      if (this.get('fromQuantity.isUnit')) {
        // From Unit to Unit
        var toUnitCount = fromQty / toQty;
        fromQty += " ".concat(this.pluralizeUnitText(this.get('fromQuantity.value')));
        convertText = "of ".concat(fromName, " into <b>").concat(toQty, " ").concat(toUnitText, " of ").concat(toUnitCount, " each</b>");
      } else {
        convertText = "".concat(fromName, " into ");

        if (this.get('toQuantity.isUnit')) {
          // From Weight to Unit
          convertText += "<b>".concat(toQty, " ").concat(toUnitText, " of ").concat(this.get('toWeight'), "</b>");
        } else {
          // From Weight to Weight
          convertText += "<b>".concat(this.get('toQuantity'), "</b>");
        }
      }

      return "Are you sure you wish to convert\n      <b>".concat(fromQty, "</b> ").concat(convertText, " of ").concat(this.get('product.name'));
    },
    pluralizeUnitText: function pluralizeUnitText(count) {
      return parseInt(count) === 1 ? 'unit' : 'units';
    },
    convert: function convert() {
      var _this = this;

      this.set('isSaving', true);
      Ember.get(this, 'http').post("".concat(_constants.default.apiHost, "/api/").concat(_constants.default.apiVersion, "/inventory_items/convert"), {
        from: {
          inventory_item_id: this.get('inventoryItem.id'),
          quantity: {
            value: this.get('fromQuantity.value').toJSON(),
            unit: this.get('fromQuantity.unit')
          }
        },
        to: {
          product_id: this.get('product.id'),
          sku: this.get('sku'),
          quantity: {
            value: this.get('toQuantity.value').toJSON(),
            unit: this.get('toQuantity.unit')
          }
        }
      }).then(function () {
        var fromQuantity = _this.get('fromQuantity');

        var productName = Ember.Handlebars.Utils.escapeExpression(_this.get('inventoryItem.product.name'));

        _this.get('toast').success("Successfully converted ".concat(fromQuantity, " of ").concat(productName, "."));

        _this.onFinish(_this.get('product'));
      }).catch(function (response) {
        var data = response.data;
        var message;

        if (Ember.isPresent(data) && Ember.isPresent(data.error)) {
          var errorMessage = data.error;
          message = "The conversion failed for the following reason: ".concat(errorMessage, ".");
        } else {
          message = "There was a problem converting this inventory. If the problem persists,\n            please contact support.";
        }

        _this.get('toast').error(message);
      }).finally(function () {
        _this.set('isSaving', false);
      });
    },
    printLabels: function printLabels(quantity) {
      var _this2 = this;

      this.set('isPrinting', true);
      this.get('labelPrinter').printWeightLabel({
        labelPreference: this.get('labelPreference'),
        receiptPreference: this.get('receiptPreference'),
        product: this.get('product'),
        inventoryItem: this.get('inventoryItem'),
        quantity: quantity,
        sku: this.get('sku'),
        weight: this.get('toWeight')
      }).then(function () {
        _this2.set('isPrinting', false);
      }).catch(function (e) {
        _this2.handlePrintingError(e);

        _this2.set('isPrinting', false);
      });
    },
    showSkuError: function showSkuError(skuTaken) {
      this.set('skuTaken', skuTaken);
    },
    actions: {
      setSku: function setSku(sku) {
        this.set('sku', sku);
      },
      showSkuError: function showSkuError(skuTaken) {
        this.showSkuError(skuTaken);
      },
      showProductSelector: function showProductSelector() {
        var isUnitBased = this.get('fromProductUnit') === _constants.default.weightUnit.unit; // if its a unit product, dont show weight based products


        if (isUnitBased) {
          this.set('unitBasedFilter', isUnitBased);
          this.set('weightBasedFilter', !isUnitBased);
          this.set('unitOfMeasureOptions', _constants.default.onlyUnit);
        }

        this.showProductSelector();
      },
      selectProduct: function selectProduct(product) {
        var _this3 = this;

        this.set('product', product);

        if (this.get('sku')) {
          var sku = this.get('sku');
          var productId = this.get('product.id');
          this.skuProvider.find(sku).then(function (inventoryItems) {
            var matchingItems = _this3.skuProvider.filterBySku(inventoryItems, sku, productId, undefined);

            _this3.showSkuError(!Ember.isEmpty(matchingItems));
          });
        }

        if (this.get('conversionIsUnitToUnit')) {
          this.send('updatedFromUnits');
        } else {
          this.updateToQuantity();
        }
      },
      convert: function convert() {
        this.confirmConvert().then(this.convert.bind(this));
      },
      cancel: function cancel() {
        this.onFinish(this.get('fromProduct'));
      },
      printLabels: function printLabels() {
        var defaultQty = this.get('toQuantity');
        this.promptForLabelQuantity(this.printLabels.bind(this), defaultQty);
      },
      updateToQuantityUnits: function updateToQuantityUnits(value) {
        var qWeight = _weight.default.create({
          value: value,
          unit: _constants.default.weightUnit.unit
        });

        this.set('toQuantity', qWeight);
      },
      updatedFromUnits: function updatedFromUnits() {
        var toQty = parseInt(this.get('toQuantity.value'));
        var validToQty = !!this.get('validToQuantityMultiples').find(function (option) {
          return option.value === toQty;
        });

        if (!validToQty || toQty === 0) {
          this.set('toQuantity', _weight.default.create({
            value: 1,
            unit: _constants.default.weightUnit.unit
          }));
        }
      }
    },
    updateToQuantity: function updateToQuantity() {
      var weight = _weight.default.create({
        value: null,
        unit: this.get('toProductUnit')
      });

      this.set('toQuantity', weight);
    }
  });

  _exports.default = _default;
});