define("herer-web/mixins/pageable-route", ["exports", "herer-web/models/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modelName: null,
    sortBy: null,
    sortDirection: null,
    limit: 30,
    model: function model() {
      var pagedArray = _pagedArray.PagedArray.create({
        store: this.get('store'),
        model: this.get('modelName'),
        sortBy: this.get('sortBy'),
        sortDirection: this.get('sortDirection'),
        limit: this.get('limit')
      });

      return pagedArray;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return model.set('filters', controller.get('filters'));
    }
  });

  _exports.default = _default;
});