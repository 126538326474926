define("herer-web/models/applicable-product-type", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    product_type_id: (0, _model.attr)('string'),
    exclude_patients: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});