define("herer-web/controllers/pin-debit", ["exports", "herer-web/mixins/alert-unsaved-controller", "herer-web/mixins/context"], function (_exports, _alertUnsavedController, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertUnsavedController.default, _context.default, {
    toast: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    tipPercentageFields: Object.freeze(['tip_percentage_tier_1', 'tip_percentage_tier_2', 'tip_percentage_tier_3', 'tip_percentage_tier_4']),
    canUseMockStrategy: Ember.computed('launchDarkly.isReady', function () {
      return this.get('launchDarkly').can('eng-51335-stub-pin-debit-client', false);
    }),
    actions: {
      saveSettings: function saveSettings() {
        var _this = this;

        var model = this.get('model');
        model.save().then(function () {
          _this.get('toast').success('PIN Debit settings saved!');
        }).catch(function () {
          _this.get('toast').error('There was a problem saving PIN debit settings.');
        });
      }
    }
  });

  _exports.default = _default;
});