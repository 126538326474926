define("herer-web/services/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    config: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).resolveRegistration('config:environment');
      }
    }),
    getConfig: function getConfig(key) {
      return Ember.get(this, "config.".concat(key));
    }
  });

  _exports.default = _default;
});