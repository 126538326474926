define("herer-web/models/mpc-barcode", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var groupSeparator = '`|';
  var recordSeparator = '*|';
  var endOfTransmission = '@|';
  var codeVersion = '00';
  var messageHeader = '[)>' + recordSeparator;
  var formatEnvelopeHeader = '01' + groupSeparator + codeVersion;

  var _default = Ember.Object.extend({
    productCode: null,
    packageId: null,
    weight: null,
    expirationDate: null,
    field: function field(value) {
      if (Ember.isBlank(value)) {
        value = '';
      }

      if (value instanceof Date) {
        value = (0, _moment.default)(value).format('YYYYMMDD');
      }

      return value.toString() + groupSeparator;
    },
    toString: function toString() {
      var weight = this.get('weight');
      var productCode = this.get('productCode');
      var packageId = this.get('packageId');
      var expirationDate = this.get('expirationDate');
      var data = [messageHeader, formatEnvelopeHeader, this.field(productCode), this.field(packageId), this.field(weight), this.field(expirationDate), recordSeparator, endOfTransmission];
      return data.join('');
    }
  });

  _exports.default = _default;
});