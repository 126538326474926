define("herer-web/components/new-drawer-adjustment-row", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-drawer-adjustment-row'],
    tagName: 'tr',
    editableTypes: [{
      name: 'Paid In',
      value: _constants.default.drawerAdjustmentType.in
    }, {
      name: 'Paid Out',
      value: _constants.default.drawerAdjustmentType.out
    }],
    createDrawerAdjustment: null,
    onCancel: null,
    type: 0,
    amount: 0,
    note: '',
    shift: '',
    noteCharacterLimit: 255,
    openedAt: Ember.computed('shift', function () {
      return this.get('maxDate');
    }),
    minDate: Ember.computed('shift', function () {
      var shift = this.get('shift');
      return shift.get('opened_at');
    }),
    maxDate: Ember.computed('shift', function () {
      var shift = this.get('shift');
      return shift.get('closed_at') || new Date();
    }),
    remainingCharacters: Ember.computed('note', function () {
      var noteLength = this.get('note.length');
      return this.get('noteCharacterLimit') - noteLength;
    }),
    actions: {
      cancel: function cancel() {
        this.onCancel();
      },
      save: function save() {
        var _this = this;

        this.set('isSaving', true);
        var drawerAdjustment = {
          opened_at: this.get('openedAt'),
          shift_id: this.get('shift').get('id'),
          note: this.get('note'),
          amount: this.get('amount'),
          drawer_adjustment_type: this.get('type')
        };
        this.createDrawerAdjustment(drawerAdjustment, function () {
          _this.set('isSaving', false);

          _this.onCancel(true);
        });
      },
      setAmount: function setAmount(amount) {
        this.set('amount', amount);
      },
      setType: function setType(type) {
        this.set('type', type);
      }
    }
  });

  _exports.default = _default;
});