define("herer-web/mixins/pageable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filters: {},
    actions: {
      loadMore: function loadMore() {
        var model = this.get('model');

        if (Ember.isPresent(model)) {
          model.loadMore();
        }
      },
      removeObject: function removeObject(object) {
        var model = this.get('model');

        if (Ember.isPresent(model)) {
          model.removeObject(object);
        }
      }
    },
    isLoading: Ember.computed.alias('model.isLoading'),
    isLoadingMore: Ember.computed.alias('model.isLoadingMore'),
    isNotLoadingMore: Ember.computed.not('model.isLoadingMore'),
    isReloading: Ember.computed.and('isLoading', 'isNotLoadingMore'),
    filtersDidChange: Ember.observer('filters', function () {
      console.info('filtersDidChange');
      this.get('model').set('filters', this.get('filters'));
    }),
    sortDidChange: Ember.observer('sortBy', 'sortDirection', function () {
      this.get('model').set('sortBy', this.get('sortBy'));
      this.get('model').set('sortDirection', this.get('sortDirection'));
    }),
    fetchObjects: function fetchObjects() {
      return this.get('model').fetchObjects();
    }
  });

  _exports.default = _default;
});