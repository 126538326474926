define("herer-web/components/discount-row", ["exports", "herer-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'table-row', 'discount-row', 'text-muted'],
    discount: null,
    fromSameLocation: null,
    displayTotal: Ember.computed('discount.total', function () {
      return this.get('discount.total') * -1;
    }),
    discountType: Ember.computed('discount.discount_type', function () {
      return _constants.default.discountTypes[this.get('discount.discount_type')].name;
    }),
    showNote: Ember.computed('discount.discount_type', 'discount.note', function () {
      return this.get('discount.discount_type') === _constants.default.discountType.item && Ember.isPresent(this.get('discount.note'));
    })
  });

  _exports.default = _default;
});