define("herer-web/templates/components/nav-link-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8oSJ7zz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"href\",\"#\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/nav-link-dropdown.hbs"
    }
  });

  _exports.default = _default;
});