define("herer-web/templates/components/reorder-report/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+P0nZcoZ",
    "block": "{\"symbols\":[\"tag\",\"index\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ellipsis col-xs-7 col-md-8 \",[28,\"if\",[[24,[\"product\",\"tags\"]],\"double-line\"],null]]]],[10,\"data-test-group\",\"tags\"],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"tags\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"product\",\"tags\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"tag\"],[12,\"data-test-action\",\"select_tag\"],[12,\"data-test-index\",[23,2,[]]],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"onSelectTag\"]]],null],[23,1,[]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n       \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-2 col-md-2\"],[10,\"data-test-text\",\"product_quantity\"],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"quantity\",\"formatted\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-1 col-md-1 nowrap\"],[10,\"data-test-text\",\"product_sales\"],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"sales\",\"formatted\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-2 col-md-1\"],[10,\"data-test-text\",\"product_days_left\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"days_left\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"product\",\"days_left\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Unknown\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/components/reorder-report/row.hbs"
    }
  });

  _exports.default = _default;
});