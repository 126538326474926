define("herer-web/templates/packages/package/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hIbY3VvH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"packages/package/package-history\",null,[[\"package\",\"users\",\"model\",\"onExit\"],[[24,[\"package\"]],[24,[\"users\"]],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"exit\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "herer-web/templates/packages/package/history.hbs"
    }
  });

  _exports.default = _default;
});